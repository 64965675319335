import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AlertService } from 'src/app/services/alert.service';
import { AppointmentTypesService } from 'src/app/services/appointment-types.service';

import { AppointmentType } from 'src/app/models/AppointmentType';

@Component({
  selector: 'app-appointment-types-edit',
  templateUrl: './appointment-types-edit.component.html',
  styleUrls: ['./appointment-types-edit.component.scss']
})
export class AppointmentTypesEditComponent implements OnInit {
  public editFormGroup: UntypedFormGroup;
  public submitted: boolean = false;
  public editMode: any = {
    active: false,
    data: null
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private appointmentTypesService: AppointmentTypesService) {
      if (this.route.snapshot.params.appointment_type_id) {
        this.editMode.active = true;
        this.appointmentTypesService.getById(this.route.snapshot.params.appointment_type_id)
          .then((response: AppointmentType) => {
            this.editMode.data = response;
            this.editFormGroup = this.formBuilder.group({
              name: [response.name, null],
              symbol: [response.symbol, null],
              duration: [response.duration, null],
              ressources_count: [response.ressources_count, null],
              ressource_name: [response.ressource_name, null],
              min_delay: [response.min_delay, null]
            });
          })
          .catch((err) => {
            this.alertService.error(err);
          });
      } else {
        this.editFormGroup = this.formBuilder.group({
          name: ['', null],
          symbol: ['', null],
          duration: [0, null],
          ressources_count: [0, null],
          ressource_name: ['', null],
          min_delay: [0, null]
        });
      }
    }

  get f() { return this.editFormGroup.controls; }

  ngOnInit(): void {}

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const appointmentType = {
      ...this.editFormGroup.value
    };
    if (this.editMode) {
      appointmentType.id = this.route.snapshot.params.appointment_type_id;
    }
    this.appointmentTypesService.edit(appointmentType)
      .then((response) => {
        this.router.navigate(['/website/settings/appointment-types']);
        this.alertService.success((this.editMode && this.editMode) ? 'Modification enregistrée avec succès.' : 'Type de rendez-vous enregistré avec succès.');
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

}
