import { Injectable } from '@angular/core';

import 'bootstrap-notify';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class LoyaltyCustomerHistoriesService {
  constructor(private apiService: ApiService) {}

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('loyalty_customer_histories')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllWithPagination(page: string | number, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('loyalty_customer_histories?page=' + page
      + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id: number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('loyalty_customer_histories/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getCompensation(start_date, end_date, adherent_id) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('adherent_id', adherent_id.toString());
      formData.append('start_date', start_date.toString());
      formData.append('end_date', end_date.toString());

      this.apiService.post('loyalty_customer_histories/compensation', formData)
        .then((response) => {
          resolve(response);
        })
        .catch(err=> {
          reject(err);
        })
    })
  }

}
