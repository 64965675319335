import { Component, OnInit } from '@angular/core';

import { AlertService } from 'src/app/services/alert.service';
import { ServicesService } from 'src/app/services/services.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { AuthService } from 'src/app/services/auth.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  public services: Array<any> = null;
  public loadingList: boolean = false;

  constructor(
    private servicesService: ServicesService,
    private alertService: AlertService,
    private authService: AuthService,
    public policiesService: PoliciesService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
    }

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.loadingList = true;
    this.servicesService.getAll()
      .then((response: Array<any>) => {
        this.loadingList = false;
        this.services = response;
      })
      .catch((err) => {
        this.loadingList = false;
        this.alertService.error(err);
      });
  }

  onDelete(serviceId) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce service ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.servicesService.delete(serviceId)
          .then(() => {
            const index = this.services.findIndex(e => e.id === serviceId);
            if (index !== -1) {
              this.services.splice(index, 1);
              this.services = [...this.services];
              this.alertService.success('Service supprimé avec succès.');
            }
          })
          .catch((err) => {
            this.alertService.error(err);
          });
      }
    });
  }

}
