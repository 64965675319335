/* GENERAL */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* MISC */
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { LoginComponent } from './views/login/login.component';
import { ErrorPageComponent } from './views/error-page/error-page.component';
import { AuthGuard } from './helpers/auth.guard';

/* MODULE: USERS MANAGEMENT */
import { UsersComponent } from './views/users/users.component';
import { UsersRoutingModule } from './views/users/users-routing.module';

/* MODULE: ADHERENTS AND AGENCIES MANAGEMENT */
import { GestionApaComponent } from './views/gestion-apa/gestion-apa.component';
import { GestionApaRoutingModule } from './views/gestion-apa/gestion-apa-routing.module';

/* MODULE: MYPROFIL+ */
import { MyprofilplusComponent } from './views/myprofilplus/myprofilplus.component';
import { MyprofilplusRoutingModule } from './views/myprofilplus/myprofilplus-routing.module';

/* MODULE: PROFIL PLUS DIRECT */
import { ProfilplusDirectComponent } from './views/profilplus-direct/profilplus-direct.component';
import { ProfilplusDirectRoutingModule } from './views/profilplus-direct/profilplus-direct-routing.module';

/* MODULE: PROFIL PLUS AGENCES */
import { ProfilplusAgencesComponent } from './views/profilplus-agences/profilplus-agences.component';
import { ProfilplusAgencesRoutingModule } from './views/profilplus-agences/profilplus-agences-routing.module';

/* MODULE : COMMERCIAL ACTIONS */
import { CommercialActionsComponent } from './views/commercial-actions/commercial-actions.component';
import { CommercialActionsRoutingModule } from './views/commercial-actions/commercial-actions-routing.module';

/* MODULE : PROFIL PLUS SITE */
import { ProfilplusSiteComponent } from './views/profilplus-site/profilplus-site.component';
import { ProfilplusSiteRoutingModule } from './views/profilplus-site/profilplus-site-routing.module';
import { NotificiationsComponent } from './views/notificiations/notificiations.component';

/* MODULE: EMAILS & SMS HISTORY */
import { EmailsSmsHistoryComponent } from './views/emails-sms-history/emails-sms-history.component';
import { EmailsSmsHistoryRoutingModule } from './views/emails-sms-history/emails-sms-history-routing.module';
import { MeetingsRoutingModule } from './views/meetings/meetings-routing.module';
import { MeetingsComponent } from './views/meetings/meetings.component';
import { ActivityTrackingRoutingModule } from './views/activity-tracking/activity-tracking-routing';
import { ActivityTrackingComponent } from './views/activity-tracking/activity-tracking.component';

import { JobComponent } from './views/job/job.component';
import { JobRoutingModule } from './views/job/job-routing.module';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'activity-tracking',
    component: ActivityTrackingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'notifications',
    component: NotificiationsComponent,
    canActivate: [AuthGuard]
  },
  /* MODULE: USERS MANAGEMENT */
  {
    path: 'users-management',
    component: UsersComponent,
  },
  /* MODULE: COMMERCIAL ACTION */
  {
    path: 'commercial-actions',
    component: CommercialActionsComponent,
    canActivate: [AuthGuard]
  },
  /* MODULE: ADHERENTS AND COMPANIES MANAGEMENT */
  {
    path: 'adherents-companies-management',
    component: GestionApaComponent
  },
  /* MODULE: WEBSITE */
  {
    path: 'website',
    component: ProfilplusSiteComponent,
    canActivate: [AuthGuard]
  },
  /* MODULE: MYPROFIL+ */
  {
    path: 'myprofilplus',
    component: MyprofilplusComponent,
    canActivate: [AuthGuard],
  },
  /* MODULE: PROFIL PLUS DIRECT */
  {
    path: 'profilplus-direct',
    component: ProfilplusDirectComponent,
    canActivate: [AuthGuard]
  },
  /* MODULE: PROFIL PLUS AGENCES */
  {
    path: 'profilplus-agences',
    component: ProfilplusAgencesComponent,
    canActivate: [AuthGuard]
  },
  /* MODULE: EMAILS / SMS HISTORY */
  {
    path: 'emails-sms-history',
    component: EmailsSmsHistoryComponent,
    canActivate: [AuthGuard]
  },
  /* MODULE: MEETINGS */
  {
    path: 'meetings',
    component: MeetingsComponent,
    canActivate: [AuthGuard]
  },
  /* MODULE: JOB */
  {
    path: 'job',
    component: JobComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: ErrorPageComponent,
  }
];

@NgModule({
  imports: [
    GestionApaRoutingModule,
    CommercialActionsRoutingModule,
    ProfilplusDirectRoutingModule,
    ProfilplusSiteRoutingModule,
    MyprofilplusRoutingModule,
    ProfilplusAgencesRoutingModule,
    UsersRoutingModule,
    EmailsSmsHistoryRoutingModule,
    MeetingsRoutingModule,
    ActivityTrackingRoutingModule,
    JobRoutingModule,
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
