<app-topbar moduleSlug="activity-forms">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/activity-tracking']">Suivi d'activité TC4</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Formulaires de suivi</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row" *ngIf="policiesService.currentUser.policies['activity-form'] && policiesService.currentUser.policies['activity-form'].create">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round"
     [routerLink]="['/activity-tracking/', 'activity-form', 'edit']">
      <i class="material-icons">library_add</i>
      Créer un nouveau formulaire de suivi
    </a>
  </div>
</div>


<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">list</i>
        </div>
        <h4 class="card-title">Formulaires d'activité
        </h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [loadingIndicator]="loadingList"
          >
            <ngx-datatable-column name="ID" prop="id" [width]="100">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ng-container *ngFor="let field of fields">
              <ngx-datatable-column [name]="field.label" [prop]="field.slug" [width]="200">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ field.slug === 'adherent_id' && getAdherentInfos(value) ? getAdherentInfos(value).name : value }}
                </ng-template>
              </ngx-datatable-column>
            </ng-container>

            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="policiesService.currentUser.policies['activity-form'].update"
                [routerLink]="['/activity-tracking', 'activity-form', 'edit', row.id]"
                type="button" class="btn btn-success"
                title="Modifier le formulaire de suivi">
                  <i class="material-icons">edit</i>
                </a>
                <button *ngIf="policiesService.currentUser.policies['activity-form'].delete"
                  type="button" class="btn btn-danger"
                  (click)="onDelete(row.id)" title="Supprimer">
                  <i class="material-icons">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>

          </ngx-datatable>
        </div>

      </div>
    </div>
  </div>
</div>
