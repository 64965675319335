import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

/* HOMEPAGE */
import { GestionApaComponent } from './gestion-apa.component';

/* ADHERENTS */
import { AdherentsComponent } from './adherents/adherents.component';
import { AdherentsEditComponent } from './adherents/adherents-edit/adherents-edit.component';

/* COMPANIES */
import { CompaniesComponent } from './companies/companies.component';
import { CompaniesEditComponent } from './companies/companies-edit/companies-edit.component';

@NgModule({
  imports:
  [
    SharedModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot()
  ],
  declarations: [
    GestionApaComponent,
    AdherentsComponent,
    AdherentsEditComponent,
    CompaniesComponent,
    CompaniesEditComponent
  ],
  providers: []
})
export class GestionApaModule {}
