<app-topbar moduleSlug="myprofilplus">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/myprofilplus']">MyProfil+</a></li>
          <li class="breadcrumb-item active"><a href="javascript:;">Sociétés</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">dvr</i>
        </div>
        <h4 class="card-title">Liste des sociétés <span>({{this.datatable.count}} éléments)</span></h4>
      </div>
      <div class="card-body">
        <app-filter-bar [filters]="filters"
          (changed)="setPage({offset: 0}, null, $event)">
          <p style="margin-top: 10px;" *ngIf="loading">chargement...</p>
        </app-filter-bar>
        <div class="table-responsive">
          <ngx-datatable
              #datatable
              class="material"
              [rows]="items"
              [columnMode]="'standard'"
              [rowHeight]="'auto'"
              [headerHeight]="50"
              [footerHeight]="50"
              [externalPaging]="true"
              [externalSorting]="true"
              [loadingIndicator]="loadingList"
              [reorderable]="false"
              (page)="setPage($event)"
              (sort)="onSort($event)"
          >
            <ngx-datatable-column [resizeable]="false" name="ID" prop="id" [width]="75" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Nom" prop="name" [width]="320" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{value}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Adhérent" prop="adherent_name" [width]="200" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{value}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Nb cartes créées" prop="loyalty_cards.created_count" [width]="200" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{value}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Nb cartes activées" prop="loyalty_cards.activated_count" [width]="200" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{value}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a [routerLink]="[ '/myprofilplus/societies', row.id, 'consultation' ]" class="btn btn-success" title="Consulter">
                  <i class="material-icons">visibility</i>
                </a>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>

        </div>

      </div>
    </div>
  </div>
</div>
