<ng-container *ngIf="!loading; else templateLoading">
  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website/profilplus-forms']">Formulaires Profil Plus</a></li>
          <ng-container  *ngIf="(editMode.active && editMode.data); else templateElse">
            <li class="breadcrumb-item active"><a href="javascript:;">{{ editMode.data.title }}</a></li>
          </ng-container>
          <ng-template #templateElse>
            <li class="breadcrumb-item active"><a href="javascript:;">Créer un formulaire</a></li>
          </ng-template>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <ng-container  *ngIf="editFormGroup">
    <div class="ppp-agency-edition" *ngIf="!editMode.active ||(editMode.active && editMode.data)">
      <form class="form" [formGroup]="editFormGroup" (ngSubmit)="onSubmit()">

        <div class="row mb-5">
          <!-- FORM INFOS -->
          <article class="col-lg-7">
            <section class="card" style="height: 100%;">

              <header class="card-header card-header-rose card-header-icon">
                <div class="card-icon">
                  <i class="material-icons">domain</i>
                </div>
                <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
                  <h4 class="card-title">Édition du formulaire : <strong class="description">{{editMode.data.title}}</strong></h4>
                </ng-container>
                <ng-template #templateElse>
                  <h4 class="card-title">Création d'un formulaire</h4>
                </ng-template>
              </header>

              <div class="card-body">

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.title.errors }">
                  <label for="title" class="col-form-label">Titre</label>
                  <input type="text" class="form-control" name="title" id="title" formControlName="title">
                </p>


                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.slug.errors }">
                  <label for="url" class="col-form-label">Clé unique</label>
                  <input type="text" class="form-control" name="slug" id="slug" formControlName="slug">
                </p>
                <small>Remarque : il s'agit de la clé utilisée dans l'URL du site profilplus.fr pour accéder à la page du formulaire en question.</small>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.agency_mail_subject.errors }">
                  <label for="agency_mail_subject" class="col-form-label">Objet du mail Agence</label>
                  <input type="text" class="form-control"
                    name="agency_mail_subject" id="agency_mail_subject"
                    formControlName="agency_mail_subject">
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.client_mail_subject.errors }">
                  <label for="client_mail_subject" class="col-form-label">Objet du mail Client</label>
                  <input type="text" class="form-control"
                    name="client_mail_subject" id="client_mail_subject"
                    formControlName="client_mail_subject">
                </p>

                <p class="form-group is-required"
                   [ngClass]="{ 'has-danger': submitted && f.start_at.errors }">
                  <label class="col-form-label">Date de début d'affichage</label>
                  <input type="date" required class="form-control" name="start_at" id="start_at" formControlName="start_at">
                  <label class="error" for="start_at" *ngIf="submitted && f.start_at.errors">
                    Champ obligatoire.
                  </label>
                </p>

                <p class="form-group is-required"
                   [ngClass]="{ 'has-danger': submitted && f.end_at.errors }">
                  <label class="col-form-label">Date de fin d'affichage</label>
                  <input type="date" required class="form-control" name="end_at" id="end_at" formControlName="end_at">
                  <label class="error" for="end_at" *ngIf="submitted && f.end_at.errors">
                    Champ obligatoire.
                  </label>
                </p>

                <div
                  style="margin-bottom:20px;"
                  class="form-group form-radio-group is-required"
                  [ngClass]="{ 'has-danger': submitted && f.universe.errors }">
                  <label class="col-form-label">Univers :</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio" name="universe"
                             formControlName="universe" [value]="'b2c'"> B2C VL
                      <span class="circle">
                      <span class="check"></span>
                    </span>
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio" name="universe"
                             formControlName="universe" [value]="'b2b-vl'"> B2B VL
                      <span class="circle">
                      <span class="check"></span>
                    </span>
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio" name="universe"
                             formControlName="universe" [value]="'b2b-pl'"> B2B PL
                      <span class="circle">
                      <span class="check"></span>
                    </span>
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio" name="universe"
                             formControlName="universe" [value]="'b2b-ag'"> B2B AG
                      <span class="circle">
                      <span class="check"></span>
                    </span>
                    </label>
                  </div>
                </div>

                <div
                  style="margin-bottom:20px; display:none;"
                  class="form-group form-radio-group is-required"
                  [ngClass]="{ 'has-danger': submitted && f.form_type.errors }">
                  <label class="col-form-label">Type de formulaire :</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio"
                             name="form_type" id="form_type"
                             formControlName="form_type" value="allopneus"> Allopneus
                      <span class="circle">
                      <span class="check"></span>
                    </span>
                    </label>
                  </div>
                </div>

              </div>
            </section>
          </article>
          <!-- END FORM INFOS -->

          <!-- ADHERENTS / BANNER -->
          <article class="col-lg-5">
            <section class="card">
              <div class="card-body">
                  <h1>Adhérent(s)</h1>
                  <div class="form-group m-0 is-required">
                    <label class="m-0">Adhérent(s) :</label>
                    <br>
                    <ng-container *ngIf="f.adherents.value.length > 0">
                      <div class="bootstrap-tagsinput info-badge">
                        <ng-container *ngFor="let adherent of f.adherents.value; let j = index">
                        <span class="tag badge">{{adherent.name}}
                          <span data-role="remove" (click)="removeAdherent(j)"></span>
                        </span>
                        </ng-container>
                      </div>
                    </ng-container>
                    <app-datalist [items]="adherentsList"
                                  [property]="'name'"
                                  [adherentsagenciespage]="true"
                                  (selected)="addAdherent($event)"
                                  [restOnSelect]="true"
                    >
                    </app-datalist>
                    <p>
                      <small>Remarque : en l'absence d'adhérents, toutes les agences du réseau Profil Plus seront proposées sur le formulaire du site profilplus.fr, sinon seulement les agences du ou des adhérents seront disponibles.</small>
                    </p>
                  </div>

              </div>
            </section>
            <section class="card">
              <div class="card-body">
                  <h1>Bannière</h1>
                  <div class="form-group">
                    <label>Bannière</label>
                    <app-drag-drop-file-input
                      [limit]="1"
                      [forceTypes]="['jpg', 'jpeg', 'png']"
                      appDragDrop
                      [preview]="true"
                      [list]="editMode.active && editMode.data.banner ? [{ name: 'banner', link: editMode.data.banner}] : null"
                      (filesSelected)="onFileSelected($event)"
                      (fileDeleted)="onFileSelected(null)"></app-drag-drop-file-input>
                    <small>Remarques : L'image doit être de format .jpg et .png et avoir une largeur de 1078px. La hauteur est à votre convenance.</small>
                  </div>
              </div>
            </section>
          </article>
          <!-- END ADHERENTS / BANNER -->
        </div>

        <div class="row">
          <!-- WYSIWYG -->
          <article class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <h1>Contenu :</h1>
                <div class="form-group">
                  <label for="content" class="col-form-label">Contenu à afficher au-dessus du formulaire</label>
                  <ckeditor formControlName="top_form_content" [config]="ckEditorConfig"></ckeditor>
                </div>

                <div class="form-group">
                  <label for="content" class="col-form-label">Contenu à afficher en-dessous du formulaire</label>
                  <ckeditor formControlName="bottom_form_content" [config]="ckEditorConfig"></ckeditor>
                </div>
              </div>
            </div>
          </article>
          <!-- END WYSIWYG -->

          <!-- CUSTOM FIELDS -->
          <article class="col-lg-12" *ngIf="f.universe.value !== null">
            <div class="card">
              <div class="card-body">
                <h1>Champs paramétrables :</h1>
                <div class="form-check"*ngFor="let field of customFields">
                  <ng-container *ngIf="checkIfIsDisplayByUniverse(field)">
                    <div class="form-group mb-2">
                      <label class="form-check-label">
                        <input class="form-check-input"
                               type="checkbox"
                               [name]="'checkbox-' + field.slug"
                               [checked]="findField(field.slug) "
                               [value]="field.slug"
                               (change)="onChangeFields($event.target, field.slug)">
                        {{ field.label }}
                        <span class="form-check-sign">
                              <span class="check"></span>
                            </span>
                      </label>
                    </div>
                    <div *ngIf="findField(field.slug)">
                      <label [for]="'checkbox-' + field.slug + '-1'" style="margin-right: 15px;">
                        <input type="radio"
                               [checked]="findField(field.slug).required"
                               [name]="'checkbox-' + field.slug"
                               [id]="'checkbox-' + field.slug + '-1'"
                               (change)="onChangeFieldRequired(true, field.slug)"> Obligatoire
                      </label>
                      <label [for]="'checkbox-' + field.slug + '-2'">
                        <input type="radio"
                               [checked]="!findField(field.slug).required"
                               [name]="'checkbox-' + field.slug"
                               [id]="'checkbox-' + field.slug + '-2'"
                               (change)="onChangeFieldRequired(false, field.slug)"> Facultatif
                      </label>
                      <ng-container *ngIf="field.slug == 'custom_field'">
                        <br>
                        <input [value]="findField(field.slug).label ? findField(field.slug).label : ''" class="form-control"
                               type="text"
                               placeholder="Nom du champ libre"
                               (blur)="onUpdateLabel($event.target.value, field.slug)"
                        />
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </article>
          <!-- END CUSTOM FIELDS -->
        </div>

        <footer class="col-lg-12">
          <button type="submit" [disabled]="editFormGroup.invalid"
                  *ngIf="!loadingService.loading; else templateSubmit"
                  class="ppp-button ppp-button-black btn btn-primary btn-round">
            <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour le</ng-container>
            <ng-template #creationTemplateText>Créer un</ng-template> formulaire
          </button>
          <ng-template #templateSubmit>
            <button disabled="true" class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
              ...
            </button>
          </ng-template>
        </footer>
      </form>
    </div>
  </ng-container>
</ng-container>
<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
