import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { TireModelsService } from 'src/app/services/tireModels.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public loading: boolean = false;
  public editMode: {
    active: boolean,
    data: any
  } = {active: false, data: null};
  public submitted: boolean = false;
  public editFormGroup: UntypedFormGroup;
  public preselectBrand = null;

  public tire_model_photo: string | File = null;

  constructor(
    public enumsService: EnumsService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private tireModelsService: TireModelsService,
    private alertService: AlertService,
    public loadingService: LoadingService) {

      this.enumsService.observable.subscribe({
        complete: () => {
          if (this.route.snapshot.params.id) { // EDIT
            this.loading = true;
            this.tireModelsService.getById(this.route.snapshot.params.id).then((response: any) => {
              this.editMode.data = response;

              this.editMode.data.tire_model_photo = (response.tire_model_photo) ? [{name: 'affichage de votre fichier', link: response.tire_model_photo}] : null;

              if (response.brand.id) {
                this.preselectBrand = this.enumsService.enums.brands.findIndex(b => b.id === response.brand.id)
              }

              this.editFormGroup = this.formBuilder.group({
                name: [response.name, Validators.required],
                title: [response.title, null],
                description: [response.description, null],
                brand_id: [response.brand.id, Validators.required],
                tire_model_photo: [response.tire_model_photo, null],
                video_key  : [response.video_key , null],
                brand_highlight  : [response.brand_highlight , null]
              });
              this.editMode.active = true;
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
              this.alertService.error(err);
            });
          } else {
            this.editFormGroup = this.formBuilder.group({
              name: [null, Validators.required],
              title: [null, null],
              description: [null, null],
              brand_id: [null, Validators.required],
              tire_model_photo: [null, null],
              video_key  : [null, null],
              brand_highlight  : [false , null]
            });

          }
        }
      });
    }

  ngOnInit(): void {
  }

  get f() { return this.editFormGroup.controls; }

  onSelectedBrand($event) {
    this.editFormGroup.patchValue({brand_id: $event.id});
  }

  onFileSelected(files: Array<File>) {
    this.tire_model_photo = files ? files[0] : '';
    if (!files && this.editMode.data) {
      this.editMode.data.tire_model_photo = null
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    const tireModel = {
      ...this.editFormGroup.value,
      tire_model_photo: this.tire_model_photo,
    };

    if (this.editMode) {
      tireModel.id = this.route.snapshot.params.id;
    }
    this.tireModelsService.edit(tireModel).then(() => {
      this.router.navigate(['/website/tires/tire-models']);
      this.alertService.success((this.editMode && this.editMode) ? 'Modification enregistrée avec succès.' : 'Profil de pneus enregistré avec succès.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

}
