import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { CommercialActionsComponent } from './commercial-actions.component';
import { AgreementsComponent } from './agreements/agreements.component';
import { AgreementsEditComponent } from './agreements/agreements-edit/agreements-edit.component';
import { CommercialActionsRoutingModule } from './commercial-actions-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { EditComponent } from './edit/edit.component';
import { ListComponent } from './list/list.component';

import { ShowComponent } from './show/show.component';

@NgModule({
  imports:
  [
    SharedModule,
    CommonModule,
    CommercialActionsRoutingModule,

    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot()
  ],
  declarations: [
    CommercialActionsComponent,
    AgreementsComponent,
    AgreementsEditComponent,
    EditComponent,
    ListComponent,
    ShowComponent
  ],
  providers: []
})
export class CommercialActionsModule {}
