<ng-container *ngIf="!loading; else templateLoading">

  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
          <ng-container  *ngIf="(editMode.active && editMode.data); else templateElse">
            <li class="breadcrumb-item"><a [routerLink]="['/website/special-offers']">Offres spéciales</a></li>
            <li class="breadcrumb-item active"><a href="javascript:;">{{editMode.data.name}}</a></li>
          </ng-container>
          <ng-template #templateElse>
            <li class="breadcrumb-item"><a [routerLink]="['/website/special-offers']">Offres spéciales</a></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Créer une offre spéciale</a></li>
          </ng-template>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <ng-container  *ngIf="editFormGroup">
    <div class="ppp-agency-edition row" *ngIf="!editMode.active ||(editMode.active && editMode.data)">
      <div class="col-lg-12">
        <form class="form" [formGroup]="editFormGroup" (ngSubmit)="onSubmit()">
          <div class="card">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">domain</i>
              </div>
              <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
                <h4 class="card-title">Édition de l'offre spéciale : <strong class="description">{{editMode.data.name}}</strong></h4>
              </ng-container>
              <ng-template #templateElse>
                <h4 class="card-title">Création d'une nouvelle offre spéciale</h4>
              </ng-template>
            </div>

            <div class="card-body">

              <div class="col-lg-4 col-md-12">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.name.errors }">
                  <label for="actions" class="bmd-label-floating">Nom</label>
                  <input type="text" class="form-control" name="name" id="actions" formControlName="name">
                </p>
              </div>

              <div class="col-lg-4 col-md-12">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.slug.errors }">
                  <label for="actions" class="bmd-label-floating">Lien externe</label>
                  <input type="text" class="form-control" name="slug" id="actions" formControlName="slug">
                </p>
              </div>

              <div class="col-lg-4 col-md-12">
                <div class="form-group" [ngClass]="{ 'has-danger': submitted && f.universe.errors }">
                  <label>Univers</label>
                  <select class="selectpicker-vanilla" formControlName="universe">
                    <option value="" disabled>Choisir</option>
                    <option [value]="universLabel.id" *ngFor="let universLabel of enumsService.enums.universes">{{universLabel.label}}</option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-12">
                <div class="form-group" [ngClass]="{ 'has-danger': submitted && f.promo_type.errors }">
                  <label>Type de promo</label>
                  <select class="selectpicker-vanilla" formControlName="promo_type">
                    <option value="" disabled>Choisir</option>
                    <option [value]="promoLabel.id" *ngFor="let promoLabel of enumsService.enums.promo_types">{{promoLabel.label}}</option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-12">
                <div class="form-group" [ngClass]="{ 'has-danger': submitted && f.start_date.errors }">
                  <label class="col-form-label">Applicable à partir du</label>
                  <input type="date" class="form-control datetimepicker" name="start_date" formControlName="start_date">
                </div>
              </div>

              <div class="col-lg-4 col-md-12">
                <div class="form-group" [ngClass]="{ 'has-danger': submitted && f.limit_date.errors }">
                  <label class="col-form-label">Applicable jusqu'au</label>
                  <input type="date" class="form-control datetimepicker" name="limit_date" formControlName="limit_date">
                </div>
              </div>

              <div class="col-lg-4 col-md-12">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.quantity.errors }">
                  <label for="quantity" class="bmd-label-floating">Quantité minimum</label>
                  <input type="number" min="0" max="99999" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" formControlName="quantity">
                </p>
              </div>

              <div class="col-lg-4 col-md-12">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.special_price.errors }">
                  <label for="special_price" class="bmd-label-floating">Prix spécial</label>
                  <input type="number" min="0" max="99999" NumbersOnly [allowDecimals]="true" [allowSign]="false" class="form-control" formControlName="special_price">
                </p>
              </div>

              <div class="col-lg-4 col-md-12">
                <p class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [name]="" formControlName="hide_promo_tag"> Cacher l'étiquette Promo
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </p>
              </div>
              <br>

              <div class="col-lg-4 col-md-12">
                <div class="form-group mb-3">
                  <app-datalist *ngIf="pages"
                    [items]="pages"
                    [preselect]="preselectPages"
                    [property]="'title'"
                    label="Page CMS"
                    (selected)="onSelectPage($event)"
                    (typed)=checkValuePage($event)
                  >
                  </app-datalist>
                </div>
                <div class="d-flex " style="flex-direction: column; justify-content: end; align-items: end;">
                  <a *ngIf="f.page.value" [routerLink]="['/website/pages/edit/', f.page.value]" target="_blank" type="button"
                    class="block" title="Modifier la page">
                    <i class="material-icons">edit</i> Modifier la page
                  </a>
                </div>
              </div>
              <br>

              <div class="col-lg-8 col-md-12">
                <div class="form-group" [ngClass]="{ 'has-danger': submitted && f.message.errors }">
                  <label class="bmd-label-floating">Message</label>
                  <div class="froalaEditor-container">
                    <ckeditor formControlName="message" [config]="ckEditorConfig"></ckeditor>
                  </div>
                </div>
              </div>

              <br>

              <hr>
                <h3>Offres spéciales / Pneus</h3>
              <hr>

              <div class="col-lg-4 col-md-12">
                <div class="form-group">
                  <label>Fichier des EAN de pneus (format .csv)</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="null"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active ? editMode.data.tire_offer_file : null"
                    (filesSelected)="onFileTireOfferFileSelected($event)"
                    (fileDeleted)="onFileTireOfferFileSelected(null)"></app-drag-drop-file-input>
                </div>
              </div>

              <div class="col-lg-4 col-md-12">
                <div class="form-group">
                  <label>Interstitiel affiché sur la liste des pneus</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active ? editMode.data.special_offer_image_d : null"
                    (filesSelected)="onFileSpecialOfferImageDSelected($event)"
                    (fileDeleted)="onFileSpecialOfferImageDSelected(null)"></app-drag-drop-file-input>
                </div>
              </div>

              <div class="col-lg-4 col-md-12">
                <div class="form-group">
                  <label>Macaron affiché sur la liste des pneus et dans les fiches produits</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png', 'gif']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active ? editMode.data.special_offer_image_aa : null"
                    (filesSelected)="onFileSpecialOfferImageAaSelected($event)"
                    (fileDeleted)="onFileSpecialOfferImageAaSelected(null)"></app-drag-drop-file-input>
                </div>
              </div>

              <hr>
                <h3>Offres spéciales / Page d'accueil</h3>
              <hr>

              <div class="col-lg-4 col-md-12">
                <div class="form-group">
                  <label>Image en entête de toutes les pages</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active ? editMode.data.special_offer_image_a : null"
                    (filesSelected)="onFileSpecialOfferImageASelected($event)"
                    (fileDeleted)="onFileSpecialOfferImageASelected(null)"></app-drag-drop-file-input>
                </div>
              </div>

              <hr>

              <div class="col-lg-4 col-md-12">
                <div class="form-group">
                  <label>Image pour les promotions Profil+ Rectangle</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active ? editMode.data.special_offer_image_b : null"
                    (filesSelected)="onFileSpecialOfferImageBSelected($event)"
                    (fileDeleted)="onFileSpecialOfferImageBSelected(null)"></app-drag-drop-file-input>
                </div>
              </div>

              <hr>
                <h3>Offres spéciales / Services</h3>
              <hr>

              <div class="col-lg-4 col-md-12">
                <div class="form-group">
                  <label for="">Service(s) concerné(s) par l'offre spéciale</label><br>
                  <select *ngIf="services" name="" id="" formControlName="services" multiple>
                    <option *ngFor="let service of services" [value]="service.id">{{service.name}}</option>
                  </select>
                </div>
                <small>Remarque : maintenir la touche "Ctrl" pour sélectionner plusieurs services.</small>
              </div>

              <div class="col-lg-4 col-md-12">
                <div class="form-group">
                  <label>Image de l'offre spéciale sur les pages Devis et RDV</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active ? editMode.data.special_offer_image_c : null"
                    (filesSelected)="onFileSpecialOfferImageCSelected($event)"
                    (fileDeleted)="onFileSpecialOfferImageCSelected(null)"></app-drag-drop-file-input>
                </div>
              </div>

              <div class="col-lg-4 col-md-12">
                <div class="form-group">
                  <label for="">Description de l'offre spéciale affichée sur les pages devis et RDV</label>
                  <textarea name="" id="" cols="30" rows="10"
                    formControlName="description_for_offer_of_type_quote_appointment">
                  </textarea>
                </div>
              </div>

              <hr>
                <h3>Offres spéciales / Agences</h3>
              <hr>

              <div class="col-lg-4 col-md-12">
                <div class="form-group">
                  <label>Bannière des fiches agences</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active ? editMode.data.special_offer_image_bb : null"
                    (filesSelected)="onFileSpecialOfferImageBbSelected($event)"
                    (fileDeleted)="onFileSpecialOfferImageBbSelected(null)"></app-drag-drop-file-input>
                </div>
              </div>

              <div class="col-lg-4 col-md-12">
                <div class="form-group">
                  <label for="agency_banner_url" class="bmd-label-floating">Lien URL de la bannière agence</label>
                  <input type="text" class="form-control" name="agency_banner_url" id="agency_banner_url" formControlName="agency_banner_url" >
                </div>
              </div>

              <hr>

              <button type="submit" [disabled]="editFormGroup.invalid" *ngIf="!loadingService.loading; else templateSubmit"
                class="ppp-button ppp-button-black btn btn-primary btn-round">
                <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour l'</ng-container>
                <ng-template #creationTemplateText>Créer une</ng-template> offre spéciale
              </button>
              <ng-template #templateSubmit>
                <button disabled="true" class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                  ...
                </button>
              </ng-template>

            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
