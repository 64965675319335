import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Agency } from 'src/app/models/Agency';
import { Customer } from 'src/app/models/Customer';
import { AlertService } from 'src/app/services/alert.service';
import { CustomersService } from 'src/app/services/customers.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { AuthService } from 'src/app/services/auth.service';
import { PoliciesService } from 'src/app/services/policies.service';

import Swal from 'sweetalert2';
import { LoyaltyCardsService } from 'src/app/services/loyalty-cards.service';
import { LoyaltyCard } from 'src/app/models/LoyaltyCard';
import { VehiculesService } from 'src/app/services/vehicules.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public loadingList: boolean = false;
  public page: any;
  public filters: Array<any> = [];
  public editFormGroup: UntypedFormGroup;
  public creditFormGroup: UntypedFormGroup;
  public debitFormGroup: UntypedFormGroup;
  public editMode: any = {
    active: false,
    data: null,
    is_yassin: false
  };
  public loading: Boolean = false;
  public submitted: Boolean = false;
  public agenciesList: Agency[];
  public preselectAgency: Number;
  public historyList: Array<any> = [];

  public modalConfig = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-danger',
      cancelButton: 'btn btn-info'
    },
    buttonsStyling: false,
    reverseButtons: true,
    focusConfirm: false
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public policiesService: PoliciesService,
    private formBuilder: UntypedFormBuilder,
    private enumsService: EnumsService,
    private alertService: AlertService,
    public loadingService: LoadingService,
    public vehiculesSErvice: VehiculesService,
    private loyaltyCardService: LoyaltyCardsService,
    private customersService: CustomersService,
    public authService: AuthService) {

    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.agenciesList = this.enumsService.enums.agencies;
        this.editMode.is_yassin = authService.currentUserValue.email == "y.elbarnoussi@profilplus.fr"

        if (this.route.snapshot.params.id) { // EDIT
          this.editMode.active = true;
          this.loyaltyCardService.getById(this.route.snapshot.params.id)
            .then((response: LoyaltyCard) => {
              this.editMode.data = response;

              if (response.agency) {
                const index = this.enumsService.enums.agencies.findIndex(a => a.id === response.agency.id);
                if (index !== -1) {
                  this.preselectAgency = this.enumsService.enums.agencies.findIndex(a => a.id === response.agency.id);
                } else {
                  // the agency is not in enums
                  const fakeAgency: Agency = new Agency();
                  fakeAgency.id = response.agency.id;
                  fakeAgency.name = response.agency.name;
                  this.agenciesList.push(fakeAgency)
                  this.preselectAgency = this.agenciesList.length - 1 // preselect agency
                }

              }

              this.editFormGroup = this.formBuilder.group({
                last_name: [response.customer ? response.customer.last_name : '', Validators.required],
                first_name: [response.customer ? response.customer.first_name : '', Validators.required],
                civility: [response.customer ? response.customer.civility : '', Validators.required],
                company: [response.customer ? response.customer.company : '', null],
                agency_id: [response.agency ? response.agency.id : '', Validators.required],
                loyalty_rule_id: [response.customer && response.customer.loyalty_rule ? response.customer.loyalty_rule.id : '', ''],
                numbered_card: [],

                email: [response.customer ? response.customer.email : '', Validators.required],
                mob_phone: [response.customer ? response.customer.mob_phone : '', Validators.required],

                shipping_address_street_name: [response.customer
                  && response.customer.shipping_address ? response.customer.shipping_address.street_name : '', null],
                shipping_address_zipcode: [response.customer
                  && response.customer.shipping_address ? response.customer.shipping_address.zipcode : '', null],
                shipping_address_city: [response.customer
                  && response.customer.shipping_address ? response.customer.shipping_address.city : '', null],
                phone: [response.customer ? response.customer.phone : '', null],
                birthday: [response.customer ? response.customer.birthday : '', null],
                card_number: [response.number, null],

                optin_myprofilplus: [response.customer ? response.customer.optin_myprofilplus : false, null],
              });

              this.creditFormGroup = this.formBuilder.group({
                creditPromotion: [authService.currentUserValue.loyalty_rules ?
                  authService.currentUserValue.loyalty_rules[0].id : null, null],
                creditAmount: ['', Validators.required]
              });

              if (response && response.customer && response.customer.loyalty_rule) {
                this.creditFormGroup.patchValue({
                  creditPromotion: response.customer.loyalty_rule.id
                });
              }

              this.debitFormGroup = this.formBuilder.group({
                debitAmount: ['', Validators.required]
              });

              if (this.authService.currentUserValue.agencies
                && this.authService.currentUserValue.agencies.length > 0) {
                this.creditFormGroup.controls.creditAmount.setValidators([Validators.max(5000), Validators.required])
                this.debitFormGroup.controls.debitAmount.setValidators([Validators.max(5000), Validators.required])
              }

              this.loading = false;

              if (response.customer && response.customer.email) {
                this.editFormGroup.controls['mob_phone'].setValidators(null);
                this.editFormGroup.controls['mob_phone'].updateValueAndValidity({ emitEvent: false });
              }
              if (response.customer && response.customer.mob_phone) {
                this.editFormGroup.controls['email'].setValidators(null);
                this.editFormGroup.controls['email'].updateValueAndValidity({ emitEvent: false });
              }
              this.observeForm();

              // Get LoyaltyCustomerHistory and LoyaltyCardExpiredHistory for this LoyaltyCard
              this.loyaltyCardService.getHistoryById(this.route.snapshot.params.id).then((response: Array<any>) => {
                this.historyList = response;
                this.loyaltyCardService.getExpiredHistoryById(this.route.snapshot.params.id).then((response: Array<any>) => {
                  if (response) {
                    response.forEach(eh => {
                      this.historyList.push({
                        "created_at": eh.expired_at,
                        "data": eh
                      });
                    });
                    this.historyList = this.historyList.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()).reverse();
                  }
                });
              });

            })
            .catch((err) => {
              this.alertService.error(err);
              this.loading = false;
            });
        }
        else { // CREATE
          this.editFormGroup = this.formBuilder.group({
            last_name: ['', Validators.required],
            first_name: ['', Validators.required],
            civility: ['', Validators.required],
            company: [],
            agency_id: ['', Validators.required],
            loyalty_rule_id: [],
            numbered_card: [false],
            email: ['', Validators.required],
            mob_phone: ['', Validators.required],
            shipping_address_street_name: [],
            shipping_address_zipcode: [],
            shipping_address_city: [],
            phone: [],
            birthday: [],
            card_number: [],
            optin_myprofilplus: [false, null],
          });
          this.loading = false;
          this.observeForm();
        }
      }
    });
  }

  ngOnInit(): void {
  }

  get f() {
    return this.editFormGroup.controls;
  }

  get c() {
    return this.creditFormGroup.controls;
  }

  get d() {
    return this.debitFormGroup.controls;
  }

  private observeForm() {
    this.editFormGroup.valueChanges.subscribe(fields => {

      // ON radio change
      if (fields['numbered_card']) {
        this.editFormGroup.controls['card_number'].setValidators(Validators.required);
        this.editFormGroup.controls['card_number'].updateValueAndValidity({ emitEvent: false });
      } else {
        this.editFormGroup.controls['card_number'].setValidators(null);
        this.editFormGroup.controls['card_number'].updateValueAndValidity({ emitEvent: false });
      }

      // EMAIL OR PHONE REQUIERMENT
      if (fields['email']) {
        this.editFormGroup.controls['mob_phone'].setValidators(null);
        this.editFormGroup.controls['mob_phone'].updateValueAndValidity({ emitEvent: false });
      }
      else {
        this.editFormGroup.controls['mob_phone'].setValidators(Validators.required);
        this.editFormGroup.controls['mob_phone'].updateValueAndValidity({ emitEvent: false });
      }
      if (fields['mob_phone']) {
        this.editFormGroup.controls['email'].setValidators(Validators.email);
        this.editFormGroup.controls['email'].updateValueAndValidity({ emitEvent: false });
      }
      else {
        this.editFormGroup.controls['email'].setValidators([Validators.required, Validators.email]);
        this.editFormGroup.controls['email'].updateValueAndValidity({ emitEvent: false });
      }

    })
  }


  onSelectedAgency(selection) {
    this.editFormGroup.patchValue({ agency_id: selection.id });
  }

  onActivateCustomer() {
    this.customersService.activate(this.editMode.data.customer.id)
      .then((response: Customer) => {
        this.alertService.success('La carte de fidélite a été activée avec succès.');
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      })
  }

  /* FORM */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    const customer: Customer = {
      ...this.editFormGroup.value
    };

    // Edit mode
    if (this.editMode.active && this.editMode.data.customer) {
      customer.id = this.editMode.data.customer.id;
    }
    this.customersService.edit(customer).then((response: Customer) => {
      if (this.policiesService.currentUser.policies['myprofilplus-customer'].update) {
        this.router.navigate(['/myprofilplus/customers/edit/' + response.loyalty_card.id])
      } else {
        this.router.navigate(['/myprofilplus/customers'])
      }
      this.alertService.success((this.editMode && this.editMode.active) ? 'Modification enregistrée avec succès.' : 'Carte de fidélité créée avec succès');
    })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

  onSubmitCredit() {
    if (this.creditFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    if (this.creditFormGroup.value.creditAmount >= 1500) {
      this.modalConfig.fire({
        title: 'Le montant du crédit est supérieur à 1500€',
        text: 'Souhaitez-vous confirmer cette action ?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Confirmer'
      }).then((result) => {
        if (result.value) {
          this.loyaltyCardService.credit(this.route.snapshot.params.id, this.creditFormGroup.value.creditAmount, this.creditFormGroup.value.creditPromotion).then((response) => {
            this.alertService.success('Le crédit de ' + this.creditFormGroup.value.debitAmount + '€ a été réalisé avec succès');
            const currentUrl = this.router.url;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate([currentUrl]);
            });
          })
            .catch((err) => {
              this.alertService.error(err);
            });
        }
      });
    } else {
      this.loyaltyCardService.credit(this.route.snapshot.params.id, this.creditFormGroup.value.creditAmount, this.creditFormGroup.value.creditPromotion).then((response) => {
        this.alertService.success('Le crédit de ' + this.creditFormGroup.value.creditAmount + '€ a été réalisé avec succès');
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      })
        .catch((err) => {
          this.alertService.error(err);
        });
    }
  }

  onSubmitDebit() {
    if (this.debitFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    if (this.debitFormGroup.value.debitAmount >= 1500) {
      this.modalConfig.fire({
        title: 'Le montant du débit est supérieur à 1500€',
        text: 'Souhaitez-vous confirmer cette action ?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Confirmer'
      }).then((result) => {
        if (result.value) {
          this.loyaltyCardService.debit(this.route.snapshot.params.id, this.debitFormGroup.value.debitAmount).then((response) => {
            this.alertService.success('Le débit de ' + this.debitFormGroup.value.debitAmount + '€ a été réalisé avec succès');
            const currentUrl = this.router.url;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate([currentUrl]);
            });
          })
            .catch((err) => {
              this.alertService.error(err);
            });
        }
      });
    } else {
      this.loyaltyCardService.debit(this.route.snapshot.params.id, this.debitFormGroup.value.debitAmount).then((response) => {
        this.alertService.success('Le débit de ' + this.debitFormGroup.value.debitAmount + '€ a été réalisé avec succès');
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([currentUrl]);
        });
      })
        .catch((err) => {
          this.alertService.error(err);
        });
    }
  }

  onRenew() {
    this.modalConfig.fire({
      title: "Renouvellement d'encours",
      text: 'Souhaitez-vous confirmer cette action ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.loyaltyCardService.renew(this.route.snapshot.params.id).then((response) => {
          this.alertService.success('Renouvellement enregistré avec succès.');
          const currentUrl = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
        })
          .catch((err) => {
            this.alertService.error(err);
          });
      }
    });
  }

  onDeleteHistory(id) {
    this.loyaltyCardService.deleteHistory(id).then(() => {
      const index = this.historyList.findIndex(x => x.id === id);
      if (index !== -1) {
        this.historyList.splice(index, 1);
      }
      this.alertService.success('L\'historique sélectionné a été supprimé avec succès');
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    })
      .catch((err) => {
        this.alertService.error(err);
      });;
  }
}
