
<app-topbar moduleSlug="profilplus-site">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/' + currentModule.slug]">
            {{currentModule.name}}
          </a>
        </li>
        <li class="breadcrumb-item active">
          <a href="javascript:;">Pages CMS</a>
        </li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row" *ngIf="policiesService.currentUser.policies['page'].create">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round"
     [routerLink]="['/' + currentModule.slug + '/pages/edit']">
      <i class="material-icons">library_add</i>
      Créer une nouvelle page CMS
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">find_in_page</i>
        </div>
        <h4 class="card-title">Liste des pages CMS</h4>
      </div>
      <div class="card-body">
        <app-filter-bar [filters]="filters"
        (changed)="setPage({offset: 0}, null, $event)">
        <p style="margin-top: 10px;" *ngIf="loadingList">chargement...</p>
        </app-filter-bar>

        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [externalPaging]="true"
            [externalSorting]="true"
            [loadingIndicator]="loadingList"
            [reorderable]="false"
            (page)="setPage($event)"
            (sort)="onSort($event)"
          >
            <ngx-datatable-column [resizeable]="false" name="ID" prop="id" [width]="75" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Titre" prop="title" [width]="300" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Univers" prop="universe" [width]="100" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{row.universe.name}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Menu parent" prop="parent_menu" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{row.parent_menu.name}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Titre meta" prop="meta_title" [width]="200" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span [title]="value">{{ value | truncate: 45 }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Description meta" prop="meta_description" [width]="200" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span [title]="value">{{ value | truncate: 45 }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Actions" [width]="300" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="policiesService.currentUser.policies['page'].update"
                    [routerLink]="['/' + currentModule.slug, 'pages' ,'edit', row.id]" type="button" class="btn btn-success" title="Modifier la page CMS">
                  <i class="material-icons">edit</i>
                </a>
                <button *ngIf="policiesService.currentUser.policies['page'].delete"
                    type="button" class="btn btn-danger" (click)="onDelete(row.id)" title="Supprimer">
                  <i class="material-icons">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
