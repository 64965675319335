import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

/* AGENCIES */
import { ProfilplusAgencesComponent } from './profilplus-agences.component';
import { OpenAgenciesComponent } from './agencies/open-agencies/open-agencies.component';
import { ClosedAgenciesComponent } from './agencies/closed-agencies/closed-agencies.component';
import { EditComponent } from './agencies/edit/edit.component';
import { EditSkillsComponent } from './agencies/edit-skills/edit-skills.component';
import { EditServicesComponent } from './agencies/edit-services/edit-services.component';
import { EditCloseDatesComponent } from './agencies/edit-close-dates/edit-close-dates.component';
import { EditWebStoreOpeningTimesComponent } from './agencies/edit-web-store-opening-times/edit-web-store-opening-times.component';
import { EditCorrespondantsComponent } from './agencies/edit-correspondants/edit-correspondants.component';
import { EditCorrespondantComponent } from './agencies/edit-correspondants/edit-correspondant/edit-correspondant.component';
import { EditMailerSlugsComponent } from './agencies/edit-correspondants/edit-mailer-slugs/edit-mailer-slugs.component';
import { SettingsComponent } from './agencies/settings/settings.component';

/* SERVICES */
import { ServicesComponent } from './services/services.component';
import { ServicesEditComponent } from './services/services-edit/services-edit.component';

/* SKILLS */
import { SkillsComponent } from './skills/skills.component';
import { SkillsEditComponent } from './skills/skills-edit/skills-edit.component';

@NgModule({
  imports:
  [
    SharedModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot()
  ],
  declarations: [
    ProfilplusAgencesComponent,
    OpenAgenciesComponent,
    ClosedAgenciesComponent,
    EditComponent,
    EditSkillsComponent,
    EditServicesComponent,
    EditCloseDatesComponent,
    EditWebStoreOpeningTimesComponent,
    EditCorrespondantsComponent,
    EditCorrespondantComponent,
    EditMailerSlugsComponent,
    ServicesComponent,
    ServicesEditComponent,
    SkillsComponent,
    SkillsEditComponent,
    SettingsComponent
  ],
  exports: [
    ProfilplusAgencesComponent
  ],
  providers: []
})
export class ProfilplusAgencesModule {}
