import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { Page } from 'src/app/models/Page';
import { Customer } from 'src/app/models/Customer';

import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { OrdersService } from 'src/app/services/orders.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { CustomersService } from 'src/app/services/customers.service';
import { WorkersService } from 'src/app/services/workers.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  public loading = false;
  public loadingList: boolean = false;

  public items: Array<Page> = [];

  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [
    {
      name: 'Nom du client',
      slug: 'last_name',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Prénom du client',
      slug: 'first_name',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'N° de l\'ordre',
      slug: 'order_number',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Date de début du RDV',
      slug: 'appointment_start_at',
      type: 'date',
      class: 'col-2',
      value: null
    },
    {
      name: 'Date de fin du RDV',
      slug: 'appointment_end_at',
      type: 'date',
      class: 'col-2',
      value: null
    },
    {
      name: 'Date de début de création',
      slug: 'created_at_start_at',
      type: 'date',
      class: 'col-2',
      value: null
    },
    {
      name: 'Date de fin de création',
      slug: 'created_at_end_at',
      type: 'date',
      class: 'col-2',
      value: null
    },
    {
      name: 'Statut',
      slug: 'status',
      selected_emit_property: 'value',
      property: 'name',
      type: 'select',
      class: 'col-1',
      options: [
        {name: 'Ouverte', value: 'Opened'},
        {name: 'En attente', value: 'Pending'},
        {name: 'Validée', value: 'Validated'},
        {name: 'Annulée', value: 'Cancelled'}
      ]
    }
  ];

  constructor(
    private workersService: WorkersService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private ordersService: OrdersService,
    private customersService: CustomersService,
    private enumsService: EnumsService,
    private authService: AuthService,
    public policiesService: PoliciesService,
    private alertService: AlertService) {
    this.policiesService.currentUser = this.authService.currentUserValue;
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.loading = false;
        this.filters.push({
          name: 'Agence',
          slug: 'agency_id',
          selected_emit_property: 'id',
          type: 'autocomplete',
          class: 'col-2',
          on_select_only: true,
          options: this.enumsService.enums.agencies
        });
        this.filters.push({
          name: 'Univers',
          slug: 'universe',
          selected_emit_property: 'id',
          property: 'label',
          type: 'select',
          class: 'col-2',
          options: this.enumsService.enums.universes
        });
      }
    });

  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.router.url.split('/')[3] === 'by-customer-id' && this.router.url.split('/')[4]) {
      this.customersService.getById(this.router.url.split('/')[4])
        .then((customer: Customer) => {
          this.filters[0].value = customer.last_name;
          this.filters[1].value = customer.first_name;

          this.setPage({offset: 0}, null, {first_name: customer.first_name, last_name: customer.last_name});
        });
    }
    else {
      this.setPage({offset: 0});
    }
    this.cd.detectChanges();
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;

    this.ordersService.getAllWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }


  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

  export() {
    this.workersService.startNewWorker('export_orders', this.filtersSelected).then(() => {
      this.alertService.success('Export de la liste des commandes, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

}
