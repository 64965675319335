<app-topbar moduleSlug="profilplus-site">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Avis clients</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round"
      (click)="$event.preventDefault(); export()">
      <i class="material-icons">pie_chart</i>
      Export .CSV
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">star</i>
        </div>
        <h4 class="card-title">Avis clients</h4>
      </div>
      <div class="card-body">
        <app-filter-bar [filters]="filters"
          (changed)="setPage({offset: 0}, null, $event)">
          <p *ngIf="loadingList">chargement...</p>
        </app-filter-bar>
        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [externalPaging]="true"
            [externalSorting]="true"
            [loadingIndicator]="loadingList"
            [reorderable]="false"
            (page)="setPage($event)"
            (sort)="onSort($event)"
            >
              <ngx-datatable-column name="ID" prop="id" [width]="50" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>{{ value }}</ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Utilisateur" prop="reviewer_name" [width]="140" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>{{ value }}</ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Email" prop="email" [width]="200" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>{{ value }}</ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Contenu" prop="content" [width]="460" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>{{ value | truncate: 300 }}</ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Note" prop="rating" [width]="70" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <span class="ppp-reviews-12" *ngIf="value && value <= 2">{{ value }} / 5</span>
                  <span class="ppp-reviews-3" *ngIf="value && value === 3">{{ value }} / 5</span>
                  <span class="ppp-reviews-45" *ngIf="value && value >= 4">{{ value }} / 5</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Porte sur" prop="reviewable_type" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <ng-container *ngIf="value === 'Tire'">
                    <ng-container [ngSwitch]="row.reviewable_tire_universe">
                      <p *ngSwitchCase="'VlTire'">
                        <a target="_blank" class="ppp-link" [routerLink]="['/website/tires/vl' , row.reviewable_id]"
                        *ngIf="policiesService.currentUser.policies['vl-tire'].read">Page du pneu VL</a>
                      </p>
                      <p *ngSwitchCase="'AgTire'">
                        <a target="_blank" class="ppp-link" [routerLink]="['/website/tires/ag' , row.reviewable_id]"
                        *ngIf="policiesService.currentUser.policies['ag-tire'].read">Page du pneu AG</a>
                      </p>
                      <p *ngSwitchCase="'AgUsedTire'">
                        <a target="_blank" class="ppp-link" [routerLink]="['/website/tires/ag_used' , row.reviewable_id]"
                        *ngIf="policiesService.currentUser.policies['ag_used-tire'].read">Page du pneu d'occasion AG</a>
                      </p>
                      <p *ngSwitchCase="'PlTire'">
                        <a target="_blank" class="ppp-link" [routerLink]="['/website/tires/pl' , row.reviewable_id]"
                        *ngIf="policiesService.currentUser.policies['pl-tire'].read">Page du pneu PL</a>
                      </p>
                    </ng-container>

                  </ng-container>
                  <ng-container *ngIf="value === 'Agency'">
                    <a target="_blank" class="ppp-link" [routerLink]="['/profilplus-agences/edit/', row.reviewable_id]"
                      *ngIf="policiesService.currentUser.policies['open-agency']
                             && policiesService.currentUser.policies['open-agency'].update">Agence #{{row.reviewable_id}}</a>
                  </ng-container>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Statut" prop="status" [width]="100" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <ng-container [ngSwitch]="value.id">
                    <ng-container *ngSwitchCase="0">
                      Non modéré
                    </ng-container>
                    <ng-container *ngSwitchCase="1">
                      Modéré
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                      Archivé
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      --
                    </ng-container>
                  </ng-container>
                </ng-template>
              </ngx-datatable-column>


              <ngx-datatable-column name="Date de création" prop="created_at" [width]="100" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>{{ value | date:'EEEE dd MMMM y' }}</ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Actions" [sortable]="false" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a [routerLink]="['/website/customer-reviews/', row.id]" type="button" class="btn btn-success" title="Afficher l'Avis">
                    <i class="material-icons">visibility</i>
                  </a>
                  <a *ngIf="policiesService.currentUser.policies['customer-review'].update"
                     [routerLink]="['/website/customer-reviews/edit/', row.id]"
                     type="button" class="btn btn-success" title="Editer l'avis">
                    <i class="material-icons">edit</i>
                  </a>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
