<app-topbar moduleSlug="myprofilplus">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/myprofilplus']">MyProfil+</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/myprofilplus/loyalty-rules']">Promotions</a></li>
        <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
          <li class="breadcrumb-item active"><a href="javascript:;">Éditer une promotion</a></li>
        </ng-container>
        <ng-template #templateElse>
          <li class="breadcrumb-item active"><a href="javascript:;">Créer une nouvelle promotion</a></li>
        </ng-template>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<ng-container *ngIf="editFormGroup">
  <div class="row" *ngIf="!editMode.active || (editMode.active && editMode.data)">
    <div class="col-lg-12">
      <form class="form" [formGroup]="editFormGroup">
        <div class="card">
          <!-- HEADER -->
          <div class="card-header card-header-rose card-header-icon">
            <div class="card-icon">
              <i class="material-icons">loyalty</i>
            </div>
            <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
              <h4 class="card-title">Édition de la promotion : <strong class="description">{{editMode.data.name}}</strong></h4>
            </ng-container>
            <ng-template #templateElse>
              <h4 class="card-title">Création d'une nouvelle promotion</h4>
            </ng-template>
          </div>
          <!-- MAIN CONTENT -->
          <div class="card-body">

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.name.errors }">
                <label for="name" class="bmd-label-floating">Nom</label>
                <input type="text" class="form-control" id="name" formControlName="name">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.coefficient.errors }">
                <label for="coefficient" class="bmd-label-floating">Coefficient</label>
                <input type="number" min="0" max="99999" step="0.01" NumbersOnly [allowDecimals]="true" [allowSign]="false" class="form-control" id="coefficient" formControlName="coefficient">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.round.errors }">
                <label for="round" class="bmd-label-floating">Arrondi</label>
                <input type="number" min="0" max="99999" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="round" formControlName="round">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.extra_points.errors }">
                <label for="extra_points" class="bmd-label-floating">Extra points</label>
                <input type="number" min="0" max="99999" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="extra_points" formControlName="extra_points">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.start_at.errors }">
                <label class="col-form-label">Date de début</label>
                <div class="form-group">
                  <input type="date" class="form-control datetimepicker" name="start_at" formControlName="start_at">
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.end_at.errors }">
                <label class="col-form-label">Date de fin</label>
                <div class="form-group">
                  <input type="date" class="form-control datetimepicker" name="end_at" formControlName="end_at">
                  <br>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" [name]="" formControlName="active"> Activée ?
                  <span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
              </div>
            </div>
            <br>

            <!-- NAVIGATION LINKS -->
            <a [routerLink]="['/myprofilplus/loyalty-rules']" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
              Retour
            </a>
            <button [disabled]="editFormGroup.invalid"
              class="ppp-button ppp-button-black btn btn-primary btn-round"
              (click)="onSubmit()">
              <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
              <ng-template #creationTemplateText>Créer</ng-template>
            </button>

          </div>

        </div>

      </form>
    </div>
  </div>
</ng-container>
