<ng-container *ngIf="!loading; else templateLoading">
  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
          <li class="breadcrumb-item"><a href="javascript:;">Gestion des pneus</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website/tires/brand-informations']">Informations de marque</a></li>
          <ng-container  *ngIf="(editMode.active && editMode.data); else elseItem">
            <li class="breadcrumb-item active"><a href="javascript:;">Édition d'informations de marque</a></li>
          </ng-container>
          <ng-template #elseItem>
            <li class="breadcrumb-item active"><a href="javascript:;">Créer de nouvelles informations de marque</a></li>
          </ng-template>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">directions_car</i>
          </div>
          <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
            <h4 class="card-title">Édition des informations de marque</h4>
          </ng-container>
          <ng-template #templateElse>
            <h4 class="card-title">Création de nouvelles informations de marque</h4>
          </ng-template>
        </div>

        <div class="card-body" *ngIf="editFormGroup">
          <form class="form" [formGroup]="editFormGroup">

            <div class="col-lg-4 col-md-12">
              <div class="form-group is-required"
                [ngClass]="{ 'has-danger': submitted && f.brand_id.errors }">
                <app-datalist
                  label="Marque"
                  (selected)="onSelectedBrand($event)"
                  [items]="enumsService.enums.brands"
                  [preselect]="preselectBrand"
                  [property]="'name'">
                </app-datalist>
              </div>
            </div>

            <div class="col-lg-4 col-md-12">
              <div class="form-group form-radio-group is-required" [ngClass]="{ 'has-danger': submitted && f.universe.errors }">
                <label class="col-form-label">Univers :</label>
                <div class="form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" name="universe" formControlName="universe" [value]="0"> B2C VL
                    <span class="circle">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" name="universe" formControlName="universe" [value]="1"> B2B VL
                    <span class="circle">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" name="universe" formControlName="universe" [value]="2"> B2B PL
                    <span class="circle">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" name="universe" formControlName="universe" [value]="3"> B2B AG
                    <span class="circle">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" name="universe" formControlName="universe" [value]="4"> B2B GC
                    <span class="circle">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" name="universe" formControlName="universe" [value]="5"> B2B MA
                    <span class="circle">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.video.errors }">
                <label for="video" class="bmd-label-floating">Vidéo</label>
                <input type="text" class="form-control" id="video" formControlName="video">
              </p>
            </div>

            <div class="col-lg-4 col-md-12">
              <div class="form-group">
                <label for="description" class="bmd-label-floating">Description</label>
                <textarea class="form-control" id="description" formControlName="description"></textarea>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="form-group">
                <label for="content" class="bmd-label-floating">Contenu détaillé sur la marque</label>
                <ckeditor formControlName="content" [config]="ckEditorConfig"></ckeditor>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="form-group">
                <label for="content" class="bmd-label-floating">Contenu de l'édito</label>
                <ckeditor formControlName="edito_content" [config]="ckEditorConfig"></ckeditor>
              </div>
            </div>

            <div class="col-lg-12 form-group">
              <label>Image associée à l'édito</label>
              <app-drag-drop-file-input
                [limit]="1"
                [forceTypes]="['jpg', 'jpeg', 'png']"
                appDragDrop
                [preview]="true"
                [list]="editMode.active && editMode.data.edito_image ? editMode.data.edito_image : null"
                (filesSelected)="onFileSelected($event)" (fileDeleted)="onFileSelected(null)"></app-drag-drop-file-input>
            </div>

            <div class="col-lg-12">
              <div class="form-group">
                <label for="edito_start_at" class="bmd-label-floating">Date de début d'affichage de l'édito</label>
                <input type="date" class="form-control" id="edito_start_at" formControlName="edito_start_at">
              </div>
            </div>

            <div class="col-lg-12">
              <div class="form-group">
                <label for="edito_end_at" class="bmd-label-floating">Date de fin d'affichage de l'édito</label>
                <input type="date" class="form-control" id="edito_end_at" formControlName="edito_end_at">
              </div>
            </div>

            <br>

            <div class="col-lg-4 col-md-12">
              <div class="form-group form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" name="is_default" formControlName="is_default"> Défaut ?
                    <span class="form-check-sign"><span class="check"></span></span>
                </label>
              </div>
            </div>

            <a [routerLink]="['/website/tires/brand-informations']" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mt-4 mr-2">
              Retour
            </a>
            <button [disabled]="editFormGroup.invalid" *ngIf="!loadingService.loading; else templateSubmit"
              class="ppp-button ppp-button-black btn btn-primary btn-round mt-4"
              (click)="onSubmit()">
              <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
              <ng-template #creationTemplateText>Créer</ng-template>
            </button>
            <ng-template #templateSubmit>
              <button
                disabled="true"
                class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                ...
              </button>
            </ng-template>
          </form>
        </div>


      </div>
    </div>
  </div>
</ng-container>
<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
