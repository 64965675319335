<div class="custom-datalist">
    <div class="form-group">
      <label *ngIf="!adherentsagenciespage" for="sarch" class="bmd-label-floating">{{label}}</label>
      <input [disabled]="disabled" type="text" class="form-control" name="search"
        autocomplete="off" clickOutside (clickOutside)="showList = false"
        (click)="showList = true" [(ngModel)]="searchField" (input)="onTyped($event)">
        <ul *ngIf="showList">
          <ng-container *ngIf="ajaxQuerying; else templateElse">
            <ng-container *ngIf="items !== null; else templateNoItems">
              <li *ngFor="let item of items"
              (click)="onSelected(item)">
                  {{item["name"]}}
              </li>
            </ng-container>
            <ng-template #templateNoItems>
              <li class="loading-item">
                <app-loading></app-loading>
              </li>
            </ng-template>
          </ng-container>
          <ng-template #templateElse>
            <li *ngFor="let item of items | search:'id,'+property :searchField"
            (click)="onSelected(item)">
                {{item[property]}} <ng-container *ngIf="item.id"><small class="text-secondary"> - #{{item.id}}</small></ng-container>
            </li>
          </ng-template>
        </ul>
    </div>
</div>
