import { Injectable } from '@angular/core';

import 'bootstrap-notify';
import { ApiService } from './api.service';
import { AlertService } from './alert.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class EnumsService {
  private subject: BehaviorSubject<{
    agencies: Array<any>,
    agencies_closed: Array<any>,
    brands: Array<any>,
    highlightCategories: Array<any>,
    companies: Array<any>,
    adherents: Array<any>,
    services: Array<any>,
    skills: Array<any>,
    universes: Array<any>,
    promo_types: Array<any>,
    parent_menus: Array<any>,
    websites: Array<any>,
    tire_data: any,
    tire_models: Array<any>
    pro_companies: Array<any>
    regions: Array<any>
  }>;

  private universes = [
    {id: 0, label: 'B2C VL', slug: 'b2c'},
    {id: 1, label: 'B2B VL', slug: 'vl'},
    {id: 2, label: 'B2B PL', slug: 'pl'},
    {id: 3, label: 'B2B AG', slug: 'ag'},
    {id: 4, label: 'B2B GC', slug: 'gc'},
    {id: 5, label: 'B2B MA', slug: 'ma'},
    {id: 6, label: 'B2B', slug: 'b2b'}
  ];

  private promoTypes = [
    {id: 0, label: 'main'},
    {id: 1, label: 'special'}
  ];

  private parentMenus = [
    {id: 0, label: 'nomenu', module: 'website'},
    {id: 1, label: 'tires', module: 'website'},
    {id: 2, label: 'appointment', module: 'website'},
    {id: 3, label: 'packplus', module: 'website'},
    {id: 4, label: 'services', module: 'website'},
    {id: 5, label: 'advices', module: 'website'},
    {id: 6, label: 'agencies', module: 'website'},
    {id: 7, label: 'engagements', module: 'website'},
    {id: 8, label: 'profilpluspro', module: 'website'},
    {id: 9, label: 'myprofilplus', module: 'website'},
    {id: 10, label: 'legalmentions', module: 'website'},
    {id: 11, label: 'cgu', module: 'website'},
    {id: 12, label: 'company', module: 'website'},
    {id: 13, label: 'recruitment', module: 'website'},
    {id: 14, label: 'faq', module: 'website'},
    {id: 15, label: 'packmobilite', module: 'website'},
    {id: 16, label: 'vl_universe', module: 'website'},
    {id: 17, label: 'pl_universe', module: 'website'},
    {id: 18, label: 'ag_universe', module: 'website'},
    {id: 19, label: 'gc_universe', module: 'website'},
    {id: 20, label: 'ma_universe', module: 'website'},
    {id: 21, label: 'entretien', module: 'website'},
    {id: 22, label: 'plus_profil_plus', module: 'website'},
    {id: 23, label: 'conseils', module: 'website'},
    {id: 24, label: 'actus', module: 'website'},
    {id: 25, label: 'mesures_sanitaires', module: 'website'},
    {id: 26, label: 'recrutement_automobile', module: 'website'},
    {id: 27, label: 'b2b_services', module: 'website'},
    {id: 28, label: 'reparation', module: 'website'},
    {id: 29, label: 'convention', module: 'website'},
    {id: 30, label: 'emploi_reseau_profilplus', module: 'job'},
    {id: 31, label: 'emploi_nos_metiers', module: 'job'},
    {id: 32, label: 'emploi_offres', module: 'job'},
    {id: 33, label: 'emploi_formez_vous', module: 'job'},
    {id: 34, label: 'emploi_actus', module: 'job'}
  ];

  public years = [];
  public months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
  public websites = [
    {id: 0, label: 'Site Profilplus', slug: 'profilplus'},
    {id: 1, label: 'Site Emploi', slug: 'job'}
  ];

  public regions = [
    {name: 'Auvergne-Rhône-Alpes', departements: ['01', '03', '07', '15', '26', '38', '42', '43', '63', '69', '73', '74']},
    {name: 'Bourgogne-Franche-Comté', departements: ['21', '25', '39', '58', '70', '71', '89', '90']},
    {name: 'Bretagne', departements: ['22', '29', '35', '56']},
    {name: 'Centre-Val de Loire', departements: ['18', '28', '36', '37', '41', '45']},
    {name: 'Corse', departements: ['2A', '2B']},
    {name: 'Grand Est', departements: ['08', '10', '51', '52', '54', '55', '57', '67', '68', '88']},
    {name: 'Hauts-de-France', departements: ['02', '59', '60', '62', '80']},
    {name: 'Île-de-France', departements: ['75', '77', '78', '91', '92', '93', '94', '95']},
    {name: 'Normandie', departements: ['14', '27', '50', '61', '76']},
    {name: 'Nouvelle-Aquitaine', departements: ['16', '17', '19', '23', '24', '33', '40', '47', '64', '79', '86', '87']},
    {name: 'Occitanie', departements: ['09', '11', '12', '30', '31', '32', '34', '46', '48', '65', '66', '81', '82']},
    {name: 'Pays de la Loire', departements: ['44', '49', '53', '72', '85']},
    {name: 'Provence-Alpes-Côte d\'Azur', departements: ['04', '05', '06', '13', '83', '84']}
  ];

  public observable: Observable<any>;

  constructor(
    private apiService: ApiService,
    private alertService: AlertService, private authService: AuthService) {
      const currentYear = new Date().getFullYear();
      for (let index = 2010; index <= currentYear; index++) {
        this.years.push(index);
      }

      this.subject = new BehaviorSubject(null);
      this.observable = this.subject.asObservable();

      this.authService.currentUser.subscribe({
        complete: () => {
          this.start();
        }
      });
  }

  public start() {
    // AGENCIES (OPEN)
    this.apiService.get('enums')
      .then((response: any) => {
        this.subject.next({
          ...response,
          websites: this.websites,
          regions: this.regions,
          parent_menus: this.parentMenus,
          promo_types: this.promoTypes,
          years: this.years,
          months: this.months,
          universes: this.universes
        });
      })
      .catch(err => {
        this.alertService.error('Erreur de chargement des enums :' + err);
      });

    this.observable.subscribe({
      next: (value) => {
        if (value && value.brands && value.highlightCategories && value.companies
          && value.adherents && value.agencies && value.services && value.skills
          && value.tire_data && value.tire_models && value.pro_companies) {
          this.subject.complete();
        }
      }
    });
  }

  public get enums() {
    return (this.subject.value) ? this.subject.value : {
      brands: null,
      highlightCategories: null,
      agencies: null,
      agencies_closed: null,
      companies: null,
      adherents: null,
      services: null,
      skills: null,
      universes: null,
      promo_types: null,
      parent_menus: null,
      websites: null,
      tire_data: null,
      tire_models: null,
      pro_companies: null,
      regions: null
    };
  }

}
