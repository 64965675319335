<script src="../../common/page-backgrounds/page-backgrounds.component.ts"></script>
<app-topbar moduleSlug="myprofilplus">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Demandes de devis</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round"
      (click)="$event.preventDefault(); export()">
      <i class="material-icons">pie_chart</i>
      Export .CSV
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">subject</i>
        </div>
        <h4 class="card-title">Demandes de devis <span *ngIf="items && datatable.count">
          ({{datatable.count}} éléments)</span>
        </h4>
      </div>
      <div class="card-body">
        <app-filter-bar [filters]="filters"
          (changed)="setPage({offset: 0}, null, $event)">
          <p *ngIf="loadingList">chargement...</p>
        </app-filter-bar>
        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [externalPaging]="true"
            [externalSorting]="true"
            [loadingIndicator]="loadingList"
            [reorderable]="false"
            (page)="setPage($event)"
            (sort)="onSort($event)"
          >
            <ngx-datatable-column name="ID" prop="id" [width]="75" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Agence" prop="agency" [width]="280" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a title="Voir l'agence" class="ppp-link" target="_blank"
                  [routerLink]="['/profilplus-agences/edit/', value.id]"
                  *ngIf="policiesService.currentUser.policies['open-agency']
                    && policiesService.currentUser.policies['open-agency'].update; else agencyOnlyData">
                  {{value.name}}
                </a>
                <ng-template #agencyOnlyData>
                  {{value.name}}
                </ng-template>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Nom client" prop="customer_name" [width]="200" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ row.details && row.details.appointment && row.details.appointment.customer ? row.details.appointment.customer.name : '' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Email client" prop="customer_email" [width]="200" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ row.details && row.details.appointment && row.details.appointment.customer ? row.details.appointment.customer.email : '' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Tél. client" prop="customer_phone" [width]="180" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ row.details && row.details.appointment && row.details.appointment.customer ? row.details.appointment.customer.phone : '' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Universe" prop="universe" [width]="75" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Date de création" prop="created_at" [width]="220" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date:'EEEE dd MMMM y HH:mm:ss' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a [routerLink]="[ '/website/quote-requests', row.id, 'consultation' ]" class="btn btn-success"
                  title="Consulter">
                    <i class="material-icons">visibility</i>
                </a>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>

        </div>
      </div>
    </div>
  </div>
</div>
