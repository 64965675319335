<ng-container *ngIf="modules[currentModule] && currentService.slug && currentItem">
  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/'+currentModule]">{{modules[currentModule].name}}</a></li>
          <ng-container *ngIf="!currentService.sub">
            <li class="breadcrumb-item"><a
                [routerLink]="['/'+currentModule, currentService.slug]">{{currentService.name}}</a></li>
          </ng-container>
          <ng-container *ngIf="currentService.sub">
            <li class="breadcrumb-item"><a href="javascript:;">{{currentService.name}}</a></li>
            <li class="breadcrumb-item">
              <a
                [routerLink]="['/'+currentModule, currentService.slug, currentService.sub.slug]">{{currentService.sub.name}}</a>
            </li>
          </ng-container>
          <li class="breadcrumb-item active"><a href="javascript:;">Détails</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <app-show-content [fields]="fields" [item]="currentItem">
    <ng-template #template let-default let-item="item" let-field="field">

      <!-- GENERAL -->
      <ng-container *ngIf="field.slug === 'created_at'">
        <strong>Date de création :</strong> {{item.created_at | date:'EEEE dd MMMM y HH:mm:ss'}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'agency' && item.agency">
        <strong>Agence :</strong> {{item.agency.name}}
      </ng-container>

      <ng-container *ngIf="field.slug === 'new_line'">
        <hr>
      </ng-container>

      <!-- ORDER -->
      <ng-container *ngIf="field.slug === 'status_order' && item.status">
        <strong>Statut : </strong>
        <span [ngSwitch]="item.status">
          <span *ngSwitchCase="'Pending'">En attente</span>
          <span *ngSwitchCase="'Opened'">Ouverte</span>
          <span *ngSwitchCase="'Validated'">Validée</span>
          <span *ngSwitchCase="'Honored'">Honorée</span>
          <span *ngSwitchCase="'Cancelled'">Annulée</span>
          <span *ngSwitchCase="'Unhonored'">Non honorée</span>
          <span *ngSwitchDefault>--</span>
        </span>
      </ng-container>
      <ng-container *ngIf="field.slug === 'details_name' && item.customer">
        <strong>Client : </strong>
        {{ item.customer.first_name ? item.customer.first_name : ''}}
        {{ item.customer.last_name ? item.customer.last_name : ''}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'details_email' && item.customer">
        <strong>Email client : </strong> {{ item.customer.email ? item.customer.email : ''}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'details_phone' && item.customer">
        <strong>Téléphone client : </strong> {{ item.customer.phone ? item.customer.phone : ''}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'order'">
        <strong>Commande du client :</strong>
        <ul>
          <ng-container *ngFor="let tire of item.details.tires">
            <li>
              <div><strong>Pneu(s) :</strong></div>
              <div>
                Référence : {{ tire.name }}<br>
                Prix unitaire : {{ tire.price_with_tax }} € TTC<br>
                Quantité : {{ tire.quantity }}<br>
                Prix total : {{ tire.price_with_tax * tire.quantity }} € TTC
              </div>
            </li>
            <li>
              <div><strong>{{ tire.assembly_text }} :</strong></div>
              <div>Quantité x {{ tire.quantity }} = {{ tire.assembly_price }} €</div>
            </li>
          </ng-container>
        </ul>
      </ng-container>

      <ng-container *ngIf="field.slug === 'details_environmental_contribution'">
        <strong>Contribution environnementale et sanitaire :</strong> {{ item.details.environmental_contribution }} €
      </ng-container>

      <ng-container *ngIf="field.slug === 'total_str'">
        <strong>Montant de la commande :</strong> {{ item.details.total_str}} € TTC
      </ng-container>

      <ng-container *ngIf="field.slug === 'order_number'">
        <strong>Commande N°{{ item.details.order_number}}</strong>
      </ng-container>
      <ng-container *ngIf="field.slug === 'universe' && item.universe">
        <strong>Univers :</strong> {{ item.universe.name}}
      </ng-container>
      <ng-container
        *ngIf="field.slug === 'agency_details' && item.details.appointment && item.details.appointment.agency">
        <ng-container>
          <strong>Agence : </strong> {{ item.details.appointment.agency ? item.details.appointment.agency.name :
          ''}}<br>
        </ng-container>
        <ng-container>
          <strong>Adresse de l'agence : </strong> {{ item.details.appointment.agency ?
          item.details.appointment.agency.address : ''}}<br>
        </ng-container>
        <ng-container>
          <strong>Téléphone de l'agence : </strong> {{ item.details.appointment.agency ?
          item.details.appointment.agency.phone : ''}}<br>
        </ng-container>
      </ng-container>

      <ng-container
        *ngIf="field.slug === 'appointment_details' && item.details.appointment && item.details.appointment.time">
        <strong>Rendez-vous du client :</strong> {{ item.details.appointment.time }}
      </ng-container>

      <!-- DEMANDES DE DEVIS -->
      <ng-container *ngIf="field.slug === 'services' && item.details.services && item.details.services.length > 0">
        <strong>Service(s) :</strong>
        <ul>
          <li *ngFor="let service of item.details.services">
            <ng-container *ngIf="service.options && service.name; else serviceObject">
              {{service.name}} : {{service.options.join(', ')}}
            </ng-container>
            <ng-template #serviceObject>
              {{service}}
            </ng-template>
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="field.slug === 'customer_name' && item.details.appointment.customer.name">
        <strong>Nom client :</strong> {{item.details.appointment.customer.name}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'customer_email' && item.details.appointment.customer.email">
        <strong>Email client :</strong> {{item.details.appointment.customer.email}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'customer_number' && item.details.appointment.customer.phone">
        <strong>Tél. client :</strong> {{item.details.appointment.customer.phone}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'customer_comment' && item.details.appointment.customer.comment">
        <strong>Commentaire :</strong> {{item.details.appointment.customer.comment}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'customer_civility' && item.details.appointment.customer.civility">
        <strong>Civilité :</strong> {{item.details.appointment.customer.civility}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'customer_optin' && item.details.appointment.customer.optin_info_com">
        <strong>Optin :</strong> {{item.details.appointment.customer.optin_info_com}}
      </ng-container>

      <!-- CUSTOMER -->
      <ng-container *ngIf="field.slug === 'civility' && item.civility !== null">
        <strong>Civilité : </strong>
        <span *ngIf="item.civility === 0">Non connu (NC)</span>
        <span *ngIf="item.civility === 1">Mr</span>
        <span *ngIf="item.civility === 2">Mme</span>
        <span *ngIf="item.civility === 3">Mlle</span>
      </ng-container>

      <ng-container *ngIf="field.slug === 'deactivated' && item.deactivated !== null">
        <strong>Compte désactivé ? </strong>
        <i *ngIf="item.deactivated === false" class="material-icons red">clear</i>
        <i *ngIf="item.deactivated === true" class="material-icons green">done</i>
      </ng-container>

      <ng-container *ngIf="field.slug === 'pro' && item.pro !== null">
        <strong>Compte pro ? (B2B) </strong>
        <i *ngIf="item.pro === false" class="material-icons red">clear</i>
        <i *ngIf="item.pro === true" class="material-icons green">done</i>
      </ng-container>

      <ng-container *ngIf="field.slug === 'pro_company' && item.pro_company">
        <strong>Entreprise :</strong> {{item.pro_company.name}}
      </ng-container>

      <ng-container *ngIf="field.slug === 'optin_promo' && item.optin_promo !== null">
        <strong>Newsletter promotion : </strong>
        <i *ngIf="item.optin_promo === false" class="material-icons red">clear</i>
        <i *ngIf="item.optin_promo === true" class="material-icons green">done</i>
      </ng-container>

      <ng-container *ngIf="field.slug === 'optin_myprofilplus' ">
        <strong>Newsletter MyProfil+ : </strong>
        <i *ngIf="item.optin_myprofilplus === false" class="material-icons red">clear</i>
        <i *ngIf="item.optin_myprofilplus === true" class="material-icons green">done</i>
      </ng-container>

      <ng-container *ngIf="field.slug === 'vehicules' && item.vehicules.length > 0">
        <hr>
        <strong>Véhicule(s) :</strong>
        <ul>
          <li *ngFor="let vehicule of item.vehicules">
            <span *ngIf="vehicule.model">Modèle : {{vehicule.model}}<br></span>
            <span *ngIf="vehicule.brand_name">Marque : {{vehicule.brand_name}}<br></span>
            <span *ngIf="vehicule.kilometers">Kilométrage : {{vehicule.kilometers}}<br></span>
            <span *ngIf="vehicule.immatriculation">immatriculation : {{vehicule.immatriculation}}<br></span>
            <span *ngIf="vehicule.professional">Professionnel :
              <i *ngIf="vehicule.professional === false" class="material-icons red">clear</i>
              <i *ngIf="vehicule.professional === true" class="material-icons green">done</i>
              <br>
            </span>
            <span *ngIf="vehicule.tire_width">Largeur de pneus : {{vehicule.tire_width}}<br></span>
            <span *ngIf="vehicule.tire_height">Hauteur de pneus : {{vehicule.tire_height}}<br></span>
            <span *ngIf="vehicule.tire_diameter">Diamètre de pneus : {{vehicule.tire_diameter}}<br></span>
            <span *ngIf="vehicule.technical_inspection_at">Date d'inspection technique :
              {{vehicule.technical_inspection_at | date:'EEEE dd MMMM y'}}<br></span>
            <span *ngIf="vehicule.entry_into_service_at">Date d'entrée en service : {{vehicule.entry_into_service_at |
              date:'EEEE dd MMMM y'}}<br></span>
            <span *ngIf="vehicule.last_invoice_at">Dernière facture le : {{vehicule.last_invoice_at | date:'EEEE dd MMMM
              y'}}<br></span>
            <span *ngIf="vehicule.tires_ean">EAN des pneus : {{vehicule.tires_ean}}<br></span>
            <span *ngIf="vehicule.draining_code">Code de vidange : {{vehicule.draining_code}}<br></span>
          </li>
        </ul>
        <hr>
      </ng-container>

      <ng-container *ngIf="field.slug === 'loyalty_card' && item.loyalty_card">
        <strong>Carte de fidélité :</strong> {{item.loyalty_card.number}}
      </ng-container>

      <ng-container *ngIf="field.slug === 'loyalty_rule' && item.loyalty_rule">
        <strong>Promotion associée à la carte de fidélité :</strong> {{item.loyalty_rule.name}}
      </ng-container>

      <ng-container *ngIf="(field.slug === 'billing_address' || field.slug === 'shipping_address') && item[field.slug]">
        <strong>{{field.name}} :</strong>
        {{item[field.slug].street_number ? item[field.slug].street_number : ''}}
        {{item[field.slug].street_name ? item[field.slug].street_name : ''}}
        {{item[field.slug].zipcode ? item[field.slug].zipcode : ''}}
        {{item[field.slug].city ? item[field.slug].city : ''}}
      </ng-container>

      <!-- CUSTOMER PRO COMPANIES -->
      <ng-container *ngIf="field.slug === 'customers' && item.customers && item.customers.length > 0">
        <strong>Client(s) :</strong>
        <ul>
          <li *ngFor="let customer of item.customers">{{customer.first_name}} {{customer.last_name}}</li>
        </ul>
      </ng-container>


      <!-- TIRE MODEL -->
      <ng-container *ngIf="field.slug === 'brand'">
        <strong>Marque : </strong> {{item.brand.name}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'tire_model_photo' && item.tire_model_photo">
        <strong>Photo : </strong><br>
        <img [src]="item.tire_model_photo" width="125">
      </ng-container>

      <!-- TIRES -->
      <ng-container *ngIf="field.slug === 'category' && item.category && item.category.name">
        <strong>Catégorie : </strong> {{item.category.name}}
      </ng-container>

      <ng-container *ngIf="field.slug === 'season' && item.season !== null">
        <strong>Saison : </strong>
        <span *ngIf="item.season === 0">Été</span>
        <span *ngIf="item.season === 1">Hiver</span>
        <span *ngIf="item.season === 2">4 saisons</span>
      </ng-container>

      <ng-container *ngIf="field.slug === 'rimport' && item.rimport !== null">
        <strong>RImport : </strong>
        <span *ngIf="item.rimport === 0">R</span>
        <span *ngIf="item.rimport === 1">ZR</span>
        <span *ngIf="item.rimport === 2">RF</span>
      </ng-container>

      <ng-container *ngIf="field.slug === 'speed_index' && item.speed_index && item.speed_index.name">
        <strong>Indice de vitesse : </strong> {{item.speed_index.name}}
      </ng-container>

      <ng-container *ngIf="field.slug === 'runflat' && item.runflat !== null">
        <strong>Runflat ? </strong>
        <i *ngIf="item.runflat === false" class="material-icons red">clear</i>
        <i *ngIf="item.runflat === true" class="material-icons green">done</i>
      </ng-container>

      <ng-container *ngIf="field.slug === 'label_fuel' && item.label_fuel && item.label_fuel.name">
        <strong>Résistance au roulement : </strong> {{item.label_fuel.name}}
      </ng-container>

      <ng-container *ngIf="field.slug === 'label_wet' && item.label_wet && item.label_wet.name">
        <strong>Adhérence sur sol mouillé : </strong> {{item.label_wet.name}}
      </ng-container>

      <ng-container *ngIf="field.slug === 'tire_model' && item.tire_model && item.tire_model.name">
        <strong>Profil de pneu : </strong> {{item.tire_model.name}}
      </ng-container>

      <ng-container
        *ngIf="field.slug === 'tire_brand' && item.tire_model && item.tire_model.brand && item.tire_model.brand.name">
        <strong>Marque : </strong> {{item.tire_model.brand.name}}
      </ng-container>

      <ng-container *ngIf="field.slug === 'price_with_tax' && item.price_with_tax !== null">
        <strong>Prix
          <span *ngIf="item.tire_type !== 'AgUsedTire'">TTC</span>
          <span *ngIf="item.tire_type === 'AgUsedTire'">HT</span>
          :
        </strong>
        {{ item.price_with_tax | number:'1.2' }} €
        <span *ngIf="item.tire_type !== 'AgUsedTire'"> TTC</span>
        <span *ngIf="item.tire_type === 'AgUsedTire'"> HT</span>
      </ng-container>

      <ng-container *ngIf="field.slug === 'on_sale' && item.on_sale !== null">
        <strong>Promo ? </strong>
        <i *ngIf="item.on_sale === false" class="material-icons red">clear</i>
        <i *ngIf="item.on_sale === true" class="material-icons green">done</i>
      </ng-container>

      <ng-container *ngIf="field.slug === 'bf_price' && item.bf_price !== null">
        <strong>Prix BF : </strong> {{ item.bf_price | number:'1.2' }} €
      </ng-container>

      <ng-container *ngIf="field.slug === 'price_with_sale' && item.price_with_sale !== null">
        <strong>Prix avec promo : </strong> {{ item.price_with_sale | number:'1.2' }} €
      </ng-container>

      <ng-container *ngIf="field.slug === 'law_code' && item.law_code !== null">
        <strong>Loi appliquée : </strong>
        <span *ngIf="item.law_code === 0">1222/2009</span>
        <span *ngIf="item.law_code === 1">2020/740</span>
      </ng-container>

      <ng-container *ngIf="field.slug === 'tire_photo_1' && item.tire_photo_1">
        <strong>Photo 1 : </strong><br>
        <img [src]="item.tire_photo_1" width="100">
      </ng-container>

      <ng-container *ngIf="field.slug === 'tire_photo_2' && item.tire_photo_2">
        <strong>Photo 2 : </strong><br>
        <img [src]="item.tire_photo_2" width="100">
      </ng-container>

      <ng-container *ngIf="field.slug === 'tire_photo_3' && item.tire_photo_3">
        <strong>Photo 3 : </strong><br>
        <img [src]="item.tire_photo_3" width="100">
      </ng-container>

      <ng-container *ngIf="field.slug === 'tire_photo_4' && item.tire_photo_4">
        <strong>Photo 4 : </strong><br>
        <img [src]="item.tire_photo_4" width="100">
      </ng-container>

      <ng-container *ngIf="field.slug === 'is_3pmsf' && item.is_3pmsf !== null">
        <strong>3PMSF ? </strong>
        <i *ngIf="item.is_3pmsf === false" class="material-icons red">clear</i>
        <i *ngIf="item.is_3pmsf === true" class="material-icons green">done</i>
      </ng-container>

      <ng-container *ngIf="field.slug === 'ice_grip' && item.ice_grip !== null">
        <strong>Adhérence sur verglas ? </strong>
        <i *ngIf="item.ice_grip === false" class="material-icons red">clear</i>
        <i *ngIf="item.ice_grip === true" class="material-icons green">done</i>
      </ng-container>

      <!-- CUSTOMER REVIEWS -->

      <ng-container *ngIf="field.slug === 'rating'">
        <strong>Note : </strong> {{ currentItem.rating }} / 5
      </ng-container>

      <ng-container *ngIf="field.slug === 'reviewable_type' && item.reviewable_type !== null">
        <strong>Porte sur : </strong>
        <ng-container *ngIf="currentItem.reviewable_type === 'Tire'">
          <ng-container [ngSwitch]="currentItem.reviewable_tire_universe">
            <span *ngSwitchCase="'VlTire'">
              <a target="_blank" class="ppp-link" [routerLink]="['/website/tires/vl', currentItem.reviewable_id]"
                *ngIf="policiesService.currentUser.policies['vl-tire'].read">Page du pneu VL</a>
            </span>
            <span *ngSwitchCase="'AgTire'">
              <a target="_blank" class="ppp-link" [routerLink]="['/website/tires/ag', currentItem.reviewable_id]"
                *ngIf="policiesService.currentUser.policies['ag-tire'].read">Page du pneu AG</a>
            </span>
            <span *ngSwitchCase="'AgUsedTire'">
              <a target="_blank" class="ppp-link" [routerLink]="['/website/tires/ag_used', currentItem.reviewable_id]"
                *ngIf="policiesService.currentUser.policies['ag_used-tire'].read">Page du pneu d'occasion AG</a>
            </span>
            <span *ngSwitchCase="'PlTire'">
              <a target="_blank" class="ppp-link" [routerLink]="['/website/tires/pl', currentItem.reviewable_id]"
                *ngIf="policiesService.currentUser.policies['pl-tire'].read">Page du pneu PL</a>
            </span>
          </ng-container>

        </ng-container>
        <ng-container *ngIf="currentItem.reviewable_type  === 'Agency'">
          <a target="_blank" class="ppp-link" [routerLink]="['/profilplus-agences/edit/', currentItem.reviewable_id]"
            *ngIf="policiesService.currentUser.policies['open-agency']
                  && policiesService.currentUser.policies['open-agency'].update">Agence
            #{{currentItem.reviewable_id}}</a>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="field.slug === 'status_review' && item.status">
        <strong>Statut : </strong>
        <ng-container [ngSwitch]="item.status.id">
          <ng-container *ngSwitchCase="0">
            Non modéré
          </ng-container>
          <ng-container *ngSwitchCase="1">
            Modéré
          </ng-container>
          <ng-container *ngSwitchCase="2">
            Archivé
          </ng-container>
          <ng-container *ngSwitchDefault>
            --
          </ng-container>
        </ng-container>
      </ng-container>


    </ng-template>
  </app-show-content>

  <div>
    <a [routerLink]="currentService.sub ? ['/'+currentModule, currentService.slug, currentService.sub.slug] : ['/'+currentModule, currentService.slug]"
      type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
      Retour
    </a>
  </div>

</ng-container>