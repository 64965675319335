<ng-container *ngIf="!circle; else normal">
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-container>
<ng-template #normal>
  <div class="cssload-container">
    <div class="cssload-speeding-wheel"></div>
  </div>
</ng-template>

