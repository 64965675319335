import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Question } from 'src/app/models/Question';
import { Questionnaire } from 'src/app/models/Questionnaire';
import { Rubric } from 'src/app/models/Rubric';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { QuestionnairesService } from 'src/app/services/questionnaires.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import questionnaire_fields from 'src/assets/questionnaire_fields.json';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public submitted: Boolean = false;
  public editFormGroup: UntypedFormGroup;
  public editMode: any = {
    active: false,
    data: null
  };
  public loading: Boolean = false;

  public custom_company = questionnaire_fields.custom_company;
  public custom_contact = questionnaire_fields.custom_contact;

  private tags_updated: {
    field: string,
    updated: {
      index: number, old_value: string, new_value: string
    }[]
  }[] = [];

  rubricEdition: {
    active: boolean,
    uuid: string | number
  } = { active: false, uuid: null };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public policiesService: PoliciesService,
    private formBuilder: UntypedFormBuilder,
    private enumsService: EnumsService,
    private alertService: AlertService,
    public loadingService: LoadingService,
    private questionnairesService: QuestionnairesService,
    public authService: AuthService) {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        if (this.route.snapshot.params.questionnaire_id) { // EDIT
          this.editMode.active = this.route.snapshot.url[this.route.snapshot.url.length - 1].path !== 'clone' ? true : false;
          this.questionnairesService.getById(this.route.snapshot.params.questionnaire_id)
            .then((response: Questionnaire) => {
              this.editMode.data = response;
              this.loading = false;
              this.editFormGroup = this.formBuilder.group(this.getNormalForm(response));

              if (this.route.snapshot.url[this.route.snapshot.url.length - 1].path === 'clone') {
                const name = this.editFormGroup.controls.name.value + " (clone)"
                this.editFormGroup.controls.name.patchValue(name);

                // Reset data on clone
                const rubrics = []
                response.rubrics.forEach(r => {
                  r.uuid = 'temp-' + Math.random() * 20
                  r.questions.forEach(q => {
                    q.uuid = 'temp-' + Math.random() * 20
                  })
                  rubrics.push(r)
                })

                this.editMode.data.rubrics = rubrics
                this.editFormGroup.controls.rubrics.patchValue(rubrics)
              }
            });
        }
        else {
          this.loading = false;
          this.editFormGroup = this.formBuilder.group(this.getNormalForm());
        }
      }
    });
  }

  private getNormalForm(data?: Questionnaire) {
    var date = new Date();
    var day = date.getDate();
    var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    var year = date.getFullYear();
    var today = (year + "-" + month + "-" + day).toString();
    return {
      name: [data ? data.name : '', Validators.required],
      show_name: [data ? data.show_name : '', Validators.required],
      for_year: [data ? data.for_year : null, Validators.required],
      start_at: [data ? data.start_at.toString().split('T')[0] : today, Validators.required],
      end_at: [data ? data.end_at.toString().split('T')[0] : today, Validators.required],
      society_fields: [data ? data.society_fields : [], null],
      contact_fields: [data ? data.contact_fields : [], null],
      rubrics: [data ? data.rubrics : [], null]
    };
  }

  ngOnInit(): void {
  }

  get f() {
    return this.editFormGroup.controls;
  }

  /*
  * return Index from Form field
  */
  testFieldsPresence(slug: string, field: string): number {
    return this.f[field].value.findIndex(i => i.slug === slug);
  }

  testFieldsRequired(slug: string, field: string): number {
    const ob = this.f[field].value.find(i => i.slug === slug);
    return ob ? ob.required : false;
  }

  onChangeFields(e: HTMLInputElement, field: string): void {
    const arr: Array<any> = this.f[field].value;
    const index = arr.findIndex(item => item.slug === e.value);
    if (e.checked) {
      if (index === -1) {
        let field_info = null

        switch (field) {
          case "society_fields":
            field_info = this.custom_company.find(item => item.slug === e.value);
            break;
          case "contact_fields":
            field_info = this.custom_contact.find(item => item.slug === e.value);
            break;
          default:
            break;
        }

        const obj: {
          slug: string,
          required: boolean,
          defined_options?: string[],
          options?: string[],
          type: string
        } = { slug: e.value, required: false, type:  field_info ? field_info.type : 'text'};

        if(field_info && field_info.defined_options != undefined) {
          obj.defined_options = [];
        }

        // Specific case for checkbox and radio btn
        if(field_info && field_info.options != undefined) {
          obj.options = field_info.options;
        }

        arr.push(obj);
      }
    } else {
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    this.editFormGroup.controls[field].patchValue(arr);
  }

  onAddOption(field: string, index: number, $event): void {
    if ($event.target.value != '') {
      const arr: any = [...this.f[field].value];
      arr[index].defined_options.push($event.target.value);

      this.editFormGroup.controls[field].patchValue(arr);
      // reset field
      $event.target.value = '';
    }
  }

  onRemoveOption(field: string, indexInField: number, optionIndex: number): void {
    const arr: any = [...this.f[field].value];
    arr[indexInField].defined_options.splice(optionIndex, 1);
    this.editFormGroup.controls[field].patchValue(arr);
  }

  onEditOption(field: string, indexInField: number, optionIndex: number, value: string): void {
    const arr: any = [...this.f[field].value];

    // prepare tags_updated log of tags updated
    const index = this.tags_updated.findIndex(item => item.field === field);
    if (index === -1) {
      this.tags_updated.push({ field: arr[indexInField].slug, updated: [
        {old_value: arr[indexInField].defined_options[optionIndex], new_value: value, index: optionIndex}
      ]});
   } else {
      const index_updated = this.tags_updated[index].updated.findIndex(item => item.index === optionIndex);
      if (index_updated === -1) {
        this.tags_updated[index].updated.push({old_value: arr[indexInField].defined_options[optionIndex], new_value: value, index: optionIndex});
      } else {
        this.tags_updated[index].updated[index_updated].new_value = value;
      }
   }

    arr[indexInField].defined_options[optionIndex] = value;
    this.editFormGroup.controls[field].patchValue(arr);

    console.log(this.tags_updated)
  }

  onChangeFieldRequired(slug: string, require: boolean, field: string): void {
    const arr: Array<any> = this.f[field].value;
    const index = arr.findIndex(item => item.slug === slug);
    if (index !== -1) {
      arr[index].required = require;
    }
    this.f[field].patchValue(arr);
  }

  onRubricEdited($event: Rubric) {
    const arr: Array<Rubric> = this.f.rubrics.value;
    if ($event.uuid) { // Update
      const index = arr.findIndex(q => q.uuid === $event.uuid);
      if (index !== -1) { // replace
        arr[index] = $event;
      }
    } else { // Create
      $event.uuid = 'temp-' + Math.random() * 20;
      arr.push($event);
      this.editFormGroup.patchValue({ rubrics: arr });
    }

    // Close rubric form
    this.rubricEdition.active = false;
    this.rubricEdition.uuid = null;
  }

  onDeleteRubric(rubricUuid: string) {
    const rubric: Rubric = this.editMode.data.rubrics.find(r => r.uuid === rubricUuid);
    const uuids: Array<string | number> = [];
    if (rubric) {
      rubric.questions.forEach((q: Question) => uuids.push(q.uuid));
    }
    this.questionnairesService.checkBeforDeleteQuesiton(uuids.join(','))
      .then(() => {
        const arr = this.f.rubrics.value;
        const index = arr.findIndex(r => r.uuid === rubricUuid);
        if (index !== -1) { // replace
          arr.splice(index, 1);
        }
        this.editFormGroup.patchValue({ rubrics: arr });
      })
      .catch(() => {
        this.alertService.error('Certaines questions de cette rubrique possèdent des réponses.');
      });
  }

  checkIfFieldWithOptionsAreCompleted() {
    let res = true
    this.custom_company.forEach(field => {
      if (field.defined_options) {
        const index = this.f.society_fields.value.findIndex(f => f.slug === field.slug);
        if (index !== -1) {
          if (this.f.society_fields.value[index].defined_options && this.f.society_fields.value[index].defined_options.length === 0) {
            res = false
          }
        }
      }
    });
    return res;
  }

  /* FORM */
  onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    // remove temp uuid for new rubrics
    const rubrics = this.editFormGroup.value.rubrics;
    rubrics.forEach((r, i) => {
      if (r.uuid.startsWith('temp-')) {
        rubrics[i].uuid = null;
      }
      // remove temp uui for new question
      rubrics[i].questions.forEach((q, j) => {
        if (q.uuid.startsWith('temp-')) {
          rubrics[i].questions[j].uuid = null;
        }
      });
    });

    this.editFormGroup.patchValue({rubrics});

    const questionnaire: Questionnaire = {
      ...this.editFormGroup.value
    };

    // Edit mode
    if (this.editMode.active) {
      questionnaire.id = this.editMode.data.id;
      questionnaire.tags_updated = this.tags_updated;
    }

    this.questionnairesService.edit(questionnaire).then((response: Questionnaire) => {
      this.router.navigate(['/meetings/questionnaires/edit', response.id]);
      this.alertService.success((this.editMode && this.editMode.active) ? 'Modification enregistrée avec succès.' : 'Questionnaire créé avec succès');
    }).catch((err) => {
      this.alertService.error(err);
    });

  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.f.rubrics.value, event.previousIndex, event.currentIndex);
  }
}
