import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import ActivityForm from 'src/app/models/activityForm';
import { ActivityFormsService } from 'src/app/services/activity-form.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import acitivityFormFields from '../../../../../assets/activity_form_fields.json';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public editFormGroup: UntypedFormGroup;
  public addFormGroup: UntypedFormGroup;
  public submitted: boolean = false;
  public editMode: any = {
    active: false,
    data: null
  };
  loading = false

  fields = acitivityFormFields

  constructor(private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private activityFormsService: ActivityFormsService,
    public authService: AuthService,
    public enumsService: EnumsService,
    public loadingService: LoadingService) {
      this.loading = true
      this.enumsService.observable.subscribe({
        complete: () => {
          // set form
          if (this.route.snapshot.params.id) { // UPDATE
            this.editMode.active = true;
            this.activityFormsService.getById(this.route.snapshot.params.id)
              .then((response: ActivityForm) => {
                this.editMode.data = response;
                this.editFormGroup = this.formBuilder.group(this.getNormalForm(response));
                this.loading = false
              })
              .catch((err) => {
                this.alertService.error(err);
              });
          } else { // CREATE
            this.editFormGroup = this.formBuilder.group(this.getNormalForm());
            this.loading = false
          }

          // set options with enums
          const yearField = this.fields.find(f => f.slug == 'year')
          if(yearField) {
            yearField.options = this.enumsService.years
          }

          const monthField = this.fields.find(f => f.slug == 'month')
          if(monthField) {
            monthField.options = this.enumsService.months
          }

          const adherentField = this.fields.find(f => f.slug == 'adherent_id')
          if(adherentField) {
            adherentField.options = this.enumsService.enums.adherents
            // Prefill adherent list if there is only one
            if (adherentField.options.length === 1) {
              this.editFormGroup.controls.adherent_id.patchValue(adherentField.options[0].id)
            }
          }
        }
      })
    }

  ngOnInit(): void {}

  get f() { return this.editFormGroup.controls; }

  private getNormalForm(data?: ActivityForm) {
    const obj = {}
    for (const field of this.fields) {
      obj[field.slug] = [data ? data[field.slug] : ((field.type == "number") ? 0 : null), Validators.required]
    }

    return obj
  }


  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    const activityForm = {
      ...this.editFormGroup.value,
    };

    if (this.editMode) {
      activityForm.id = this.route.snapshot.params.id;
    }
    this.activityFormsService.edit(activityForm).then(() => {
      this.router.navigate(['/activity-tracking/activity-forms']);
      this.alertService.success((this.editMode && this.editMode) ? 'Modification enregistrée avec succès.' : 'Marque enregistrée avec succès.');
    })
    .catch((err) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-info'
        },
        buttonsStyling: false,
        reverseButtons: true,
        focusConfirm: false
      });

      swalWithBootstrapButtons.fire({
        title: 'Traitement impossible',
        text: "Il existe déjà une saisie pour l'adhérent, l'année et le mois indiqués.",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.value) {
          return ;
        }
      });
    });
  }
}
