import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import modules from '../../assets/modules.json';
import { AlertService } from '../services/alert.service';

import { AuthService } from '../services/auth.service';
import { PoliciesService } from '../services/policies.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthService,
        private policiesService: PoliciesService,
        private alertService: AlertService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
          // logged in so return true
            // check policies (have read attribute to tr-ue)
            this.policiesService.currentUser = currentUser;
            const module = state.url.split('/')[1];
            const item = state.url.split('/')[2];

            // MODULE AND ITEM PREVENT CASE
            const exeptionsUrl = ['/users-management/edit/' + currentUser.id.toString(), module + '/help'];
            if (module && modules[module]) { // THIS IS A MODULE

              if (!exeptionsUrl.includes(state.url)) {

                // CHECK MODULE PERMITION
                if (!this.policiesService.checkModule(modules[module])) {
                  this.router.navigate(['/']); // GO BACK TO INDEX
                  this.alertService.error('Vous n\'avez pas accès à ce module.');
                  return false;
                }

                // CHECK ITEM PERMITION
                const itemData = modules[module].items.find(mI => mI.slug === item);
                // console.log(route.params);
                if (itemData) {
                  if (itemData.slug === 'edit') {
                    // If params (:id, :_other_id) check update rule else create rule
                    if (Object.keys(route.params).length > 0) {
                      if (!this.policiesService.userHaveRule(itemData.model, 'update')) {
                        this.router.navigate(['/']); // GO BACK TO INDEX
                        this.alertService.error('Vous n\'avez pas accès à la mise à jour pour cette page.');
                        return false;
                      }
                    } else {
                      if (!this.policiesService.userHaveRule(itemData.model, 'create')) {
                        this.router.navigate(['/']); // GO BACK TO INDEX
                        this.alertService.error('Vous n\'avez pas accès à la création pour cette page.');
                        return false;
                      }
                    }
                  } else if (!this.policiesService.checkItem(itemData)) {
                    this.router.navigate(['/']); // GO BACK TO INDEX
                    this.alertService.error('Vous n\'avez pas accès à cette page.');
                    return false;
                  }


                }

              }
            }

            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
    }
}
