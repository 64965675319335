import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { AlertService } from 'src/app/services/alert.service';
import { User } from 'src/app/models/User';
import { AgenciesService } from 'src/app/services/agencies.service';

@Component({
  selector: 'app-adherents-agencies',
  templateUrl: './adherents-agencies.component.html',
  styleUrls: ['./adherents-agencies.component.scss']
})
export class AdherentsAgenciesComponent implements OnInit {
  public user: User = null;
  public adherentsSearch: string = '';
  public adherentsList: Array<any> = [];
  public agenciesSearch: string = '';
  public agenciesList: Array<any> = [];
  public adherentFilter: Array<number>;
  public showListAdherents: Boolean = false;
  public showListAgencies: Boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private agenciesService: AgenciesService,
    private alertService: AlertService,
    private chRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterContentInit() {
    this.userService.getById(this.route.snapshot.params.id)
      .then((user: User) => {
        this.user = user;
        // Get adherents
        this.agenciesService.getAdherents()
          .then((response: Array<any>) => {
            this.adherentsList = response;


            this.chRef.detectChanges(); // Detect HTML CHANGING

            // @ts-ignore: Unreachable code error
            jQuery('.select-adherents').selectpicker({
              noneSelectedText: 'Aucun élément sélectionné'
            });
          });

        // Get agencies
        this.agenciesService.getAgencies([])
          .then((response: Array<any>) => {
            this.agenciesList = response;
          });

      })
      .catch((err) => {
        this.alertService.error(err.message);
        this.router.navigate(['/users-management']);
      });
  }


  // ADHERENTS
  addAdherent(adherent) {
    if (this.user.adherents.findIndex(e => e.id === adherent.id) === -1) {
      const temp = this.user.adherents;
      temp.push(adherent);
      this.updateAdherents(temp)
        .then(() => {
          // this.user.adherents.push(adherent);
        })
        .catch((err) => {
          this.alertService.error(err);
        });
    }

  }

  removeAdherent(adherent) {
    const temp = this.user.adherents;
    const index = temp.findIndex(a => a.id === adherent.id);
    if (index !== -1) {

      temp.splice(index, 1);

      this.updateAdherents(temp)
        .then(() => {
          this.user.adherents = temp;
        })
        .catch((err) => {
          this.alertService.error(err);
        });
    }
  }

  updateAdherents(adherentsOnlyIds: Array<number> = []) {
    return new Promise((resolve, reject) => {
      this.userService.updateAdherents(this.user.id, adherentsOnlyIds)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }


  // AGENCES
  addAgency(agency) {
    if (this.user.agencies.findIndex(e => e.id === agency.id) === -1)  {
      const temp = this.user.agencies;
      temp.push(agency);
      this.updateAgencies(temp)
        .then(() => {
          // this.user.agencies.push(agency);
        })
        .catch((err) => {
          this.alertService.error(err);
        });
    }
  }


  removeAgency(agency) {
    const temp = this.user.agencies;
    const index = temp.findIndex(a => a.id === agency.id);
    if (index !== -1) {

      temp.splice(index, 1);

      this.updateAgencies(temp)
        .then(() => {
          this.user.agencies = temp;
        })
        .catch((err) => {
          this.alertService.error(err);
        });
    }
  }

  updateAgencies(agenciesOnlyIds: Array<number> = []) {
    return new Promise((resolve, reject) => {
      this.userService.updateAgencies(this.user.id, agenciesOnlyIds)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  onChangeAdherentFilter($event) {
    this.agenciesService.getAgencies(this.adherentFilter)
      .then((response: Array<any>) => {
        this.agenciesList = response;
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

}
