import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpComponent } from '../help/help.component';
import { AuthGuard } from 'src/app/helpers/auth.guard';
import { IndexContentComponent } from 'src/app/components/index-content/index-content.component';

/* HOMEPAGE */
import { ProfilplusAgencesComponent } from './profilplus-agences.component';

/* AGENCIES */
import { OpenAgenciesComponent } from './agencies/open-agencies/open-agencies.component';
import { ClosedAgenciesComponent } from './agencies/closed-agencies/closed-agencies.component';
import { EditComponent } from './agencies/edit/edit.component';
import { EditSkillsComponent } from './agencies/edit-skills/edit-skills.component';
import { EditServicesComponent } from './agencies/edit-services/edit-services.component';
import { EditCloseDatesComponent } from './agencies/edit-close-dates/edit-close-dates.component';
import { EditWebStoreOpeningTimesComponent } from './agencies/edit-web-store-opening-times/edit-web-store-opening-times.component';
import { EditCorrespondantsComponent } from './agencies/edit-correspondants/edit-correspondants.component';
import { EditCorrespondantComponent } from './agencies/edit-correspondants/edit-correspondant/edit-correspondant.component';
import { EditMailerSlugsComponent } from './agencies/edit-correspondants/edit-mailer-slugs/edit-mailer-slugs.component';
import { SettingsComponent } from './agencies/settings/settings.component';

/* SERVICES */
import { ServicesComponent } from './services/services.component';
import { ServicesEditComponent } from './services/services-edit/services-edit.component';

/* SKILLS */
import { SkillsComponent } from './skills/skills.component';
import { SkillsEditComponent } from './skills/skills-edit/skills-edit.component';

const routes: Routes = [
    {
        path: 'profilplus-agences',
        component: ProfilplusAgencesComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: IndexContentComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'edit',
            redirectTo: 'open-agencies/edit'
          },
          /* Liste des agences ouvertes */
          {
            path: 'open-agencies',
            component: OpenAgenciesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'open-agencies/page/:page',
            component: OpenAgenciesComponent,
            canActivate: [AuthGuard]
          },
          /* Liste des agences fermées */
          {
            path: 'closed-agencies',
            component: ClosedAgenciesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'closed-agencies/page/:page',
            component: ClosedAgenciesComponent,
            canActivate: [AuthGuard]
          },
          /* Edition et création (agences ouvertes / agences fermées) */
          {
            path: 'open-agencies/edit/:id',
            component: EditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'open-agencies/edit',
            component: EditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'closed-agencies/edit/:id',
            component: EditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'closed-agencies/edit',
            component: EditComponent,
            canActivate: [AuthGuard]
          },
          /* Métiers (agences ouvertes / agences fermées) */
          {
            path: 'open-agencies/edit/skills/:id',
            component: EditSkillsComponent,
          },
          {
            path: 'closed-agencies/edit/skills/:id',
            component: EditSkillsComponent,
          },
          /* Services (agences ouvertes / agences fermées) */
          {
            path: 'open-agencies/edit/services/:id',
            component: EditServicesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'closed-agencies/edit/services/:id',
            component: EditServicesComponent,
            canActivate: [AuthGuard]
          },
          /* Horaires magasin (agences ouvertes / agences fermées) */
          {
            path: 'open-agencies/edit/opening-times/store/:id',
            component: EditWebStoreOpeningTimesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'closed-agencies/edit/opening-times/store/:id',
            component: EditWebStoreOpeningTimesComponent,
            canActivate: [AuthGuard]
          },
          /* Dates de fermeture (agences ouvertes / agences fermées) */
          {
            path: 'open-agencies/edit/close-dates/:id',
            component: EditCloseDatesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'closed-agencies/edit/close-dates/:id',
            component: EditCloseDatesComponent,
            canActivate: [AuthGuard]
          },
          /* Correspondants (agences ouvertes / agences fermées) */
          {
            path: 'open-agencies/correspondants/edit/:id',
            component: EditCorrespondantsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'open-agencies/correspondants/edit/:agency_id/correspondant/:correspondant_id',
            component: EditCorrespondantComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'open-agencies/correspondants/edit/:agency_id/correspondant',
            component: EditCorrespondantComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'open-agencies/correspondants/edit/:agency_id/correspondant/:correspondant_id/mailer_slugs',
            component: EditMailerSlugsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'closed-agencies/correspondants/edit/:id',
            component: EditCorrespondantsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'closed-agencies/correspondants/edit/:agency_id/correspondant/:correspondant_id',
            component: EditCorrespondantComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'closed-agencies/correspondants/edit/:agency_id/correspondant',
            component: EditCorrespondantComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'closed-agencies/correspondants/edit/:agency_id/correspondant/:correspondant_id/mailer_slugs',
            component: EditMailerSlugsComponent,
            canActivate: [AuthGuard],
          },
          /* Services */
          {
            path: 'services',
            component: ServicesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'services/edit/:service_id',
            component: ServicesEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'services/edit',
            component: ServicesEditComponent,
            canActivate: [AuthGuard]
          },
          /* Métiers */
          {
            path: 'skills',
            component: SkillsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'skills/edit/:skill_id',
            component: SkillsEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'skills/edit',
            component: SkillsEditComponent,
            canActivate: [AuthGuard]
          },
          /* Paramètres avancés */
          {
            path: 'open-agencies/edit/settings/:id',
            component: SettingsComponent,
            canActivate: [AuthGuard]
          },
          /* Aide */
          {
            path: 'help',
            component: HelpComponent,
            canActivate: [AuthGuard],
          }
        ]
      },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ProfilplusAgencesRoutingModule {}
