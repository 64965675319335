<ng-container *ngIf="!loading; else templateLoading">

  <app-topbar moduleSlug="profilplus-agences">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences']">Profil Plus Agences</a></li>
          <li class="breadcrumb-item">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies'] : ['/profilplus-agences/closed-agencies']">
              Liste des agences {{ router.url.split('/').includes('open-agencies') ? 'ouvertes' : 'fermées' }}
            </a>
          </li>
          <li class="breadcrumb-item" *ngIf="agency">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit', agency.id] : ['/profilplus-agences/closed-agencies/edit', agency.id]">
              Agence {{agency.name}}
            </a>
          </li>
          <li class="breadcrumb-item active" *ngIf="agency"><a href="javascript:;">Éditer les métiers</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">build</i>
          </div>
          <h4 class="card-title" *ngIf="agency">Édition des métiers : <strong class="description">{{agency.name}}</strong></h4>
        </div>
        <div class="card-body">
          <!-- BUTTONS -->
          <div>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/', agency.id] : ['/profilplus-agences/closed-agencies/edit/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">store</i> Fiche agence
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/skills/', agency.id] : ['/profilplus-agences/closed-agencies/edit/skills/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">build</i> Métiers
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/services/', agency.id] : ['/profilplus-agences/closed-agencies/edit/services/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">playlist_add_check</i> Services
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/close-dates/', agency.id] : ['/profilplus-agences/closed-agencies/edit/close-dates/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">report</i> Dates fermeture
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/opening-times/store/', agency.id] : ['/profilplus-agences/closed-agencies/edit/opening-times/store/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">store</i> Horaires magasin
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/correspondants/edit/', agency.id] : ['/profilplus-agences/closed-agencies/correspondants/edit/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">people</i> Correspondants
            </a>
            <a *ngIf="policiesService.currentUser.is_admin && router.url.split('/').includes('open-agencies')"
                [routerLink]="['/profilplus-agences/open-agencies/edit/settings', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">settings</i> Paramètres avancés
            </a>
          </div>

          <div *ngIf="skills && agencySkills">
            <div class="card">
              <div class="card-body">
                <div class="form-check" *ngFor="let sk of skills">
                  <label class="form-check-label" [for]="sk.id">
                    <input class="form-check-input"
                          type="checkbox"
                          [checked]="isSelectedSkills(sk.id)"
                          (change)="onSelectSkills($event)"
                          [value]="sk.id"
                          [name]="sk.id" [id]="sk.id">
                          {{sk.name}}
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <button *ngIf="!loadingService.loading; else templateSubmit"
            class="ppp-button ppp-button-black btn btn-primary btn-round"
            (click)="onSubmit()">Mettre à jour
          </button>
          <ng-template #templateSubmit>
            <button
              disabled="true"
              class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
              ...
            </button>
          </ng-template>

        </div>
      </div>
    </div>
  </div>

</ng-container>

<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
