<app-topbar moduleSlug="website">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Mapping de fichiers</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<!-- NEW FILE MAPPING BUTTON -->
<div class="row" *ngIf="policiesService.currentUser.policies['file'].create">
  <div class="col-md-6">
    <a *ngIf="policiesService.currentUser.policies.file.create"
      class="ppp-button ppp-button-black btn btn-primary btn-round" [routerLink]="['/website/file-mapping/edit']">
      <i class="material-icons">find_in_page</i>
      Nouveau mapping de fichiers
    </a>
  </div>
</div>

<!-- LIST -->
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">find_in_page</i>
        </div>
        <h4 class="card-title">Mapping de fichiers</h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <ngx-datatable class="material" [rows]="fileMappings" [columnMode]="'standard'" [rowHeight]="'auto'"
            [headerHeight]="50" [footerHeight]="50" [reorderable]="false" [loadingIndicator]="loading">
            <ngx-datatable-column name="ID" prop="id" [width]="60">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Date de création" prop="created_at" [width]="180">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date: 'dd/MM/yy' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Rayon de recherche" prop="execution_data" [width]="210">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value.radius ? value.radius : '-' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Fichier clients" prop="file" [width]="180">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a [href]="value" title="Télécharger">
                  <i style="color:#333;" class="material-icons">cloud_download</i>
                </a>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Fichier agences" prop="file_2" [width]="180">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a [href]="value" title="Télécharger">
                  <i style="color:#333;" class="material-icons">cloud_download</i>
                </a>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Fichier résultat" prop="file_reporting" [width]="180">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngIf="row.file_reporting">
                  <a href="#"
                    (click)="$event.preventDefault(); clickDownload(row.file_reporting, 'profilplus-export-errors-' + row.id + '-' + row.request_type + '.xlsx')"
                    title="Télécharger">
                    <i style="color:#333;" class="material-icons">cloud_download</i>
                  </a>
                </ng-container>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Nb résultats" prop="execution_data" [width]="160">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value.number_of_results ? value.number_of_results : '-' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Carte interactive" prop="uuid" [width]="210">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngIf="value">
                  <a [href]="'https://www.profilplus.fr/mapping/' + value" title="Lien">
                    <i style="color:#333;" class="material-icons">map</i>
                  </a>
                </ng-container>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [sortable]="false">
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
