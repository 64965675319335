<ng-container *ngIf="!loading; else templateLoading">
  <app-topbar moduleSlug="website">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item">
            <a [routerLink]="['/' + currentModule.slug]">
              {{currentModule.name}}
            </a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="[
            (currentModule.slug == 'website') ? '/website/settings' : '/' + currentModule.slug,
            'page-backgrounds']">Habillages de page</a>
          </li>
          <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
            <li class="breadcrumb-item active"><a href="javascript:;">Éditer un habillage de page</a></li>
          </ng-container>
          <ng-template #templateElse>
            <li class="breadcrumb-item active"><a href="javascript:;">Créer un nouvel habillage de page</a></li>
          </ng-template>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <ng-container *ngIf="editFormGroup">
    <div class="row" *ngIf="!editMode.active || (editMode.active && editMode.data)">
      <div class="col-lg-12">
        <form class="form" [formGroup]="editFormGroup">

          <div class="card">
            <!-- HEADER -->
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">settings</i>
              </div>
              <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
                <h4 class="card-title">Édition d'un habillage de page</h4>
              </ng-container>
              <ng-template #templateElse>
                <h4 class="card-title">Création d'un nouvel habillage de page</h4>
              </ng-template>
            </div>

            <!-- MAIN CONTENT -->
            <div class="card-body">

              <div class="col-lg-4 col-md-12">
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input formControlName="apply_to_all" class="form-check-input" type="checkbox" name="apply_to_all">
                    Appliquer à toutes les pages ?
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
              </div>

              <br>

              <div class="col-lg-4 col-md-12">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.url.errors }">
                  <label for="url" class="bmd-label-floating">URL de la page</label>
                  <input type="text" class="form-control" id="url" formControlName="url">
                </p>
              </div>

              <div class="col-lg-4 col-md-12">
                <div class="form-group">
                  <label>Image de gauche</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active ? editMode.data.fileLeft : null"
                    (filesSelected)="onFileLeftSelected($event)"
                    (fileDeleted)="onFileLeftSelected(null)"></app-drag-drop-file-input>
                </div>
              </div>

              <div class="col-lg-4 col-md-12">
                <div class="form-group">
                  <label>Image de droite</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active ? editMode.data.fileRight : null"
                    (filesSelected)="onFileRightSelected($event)"
                    (fileDeleted)="onFileRightSelected(null)"></app-drag-drop-file-input>
                </div>
              </div>

              <div class="col-lg-4 col-md-12">
                <div class="form-group" [ngClass]="{'has-danger': submitted && editFormGroup.value.page === null}">
                  <app-datalist *ngIf="pages"
                    [items]="pages"
                    [preselect]="preselectPages"
                    [property]="'title'"
                    label="Page CMS"
                    (selected)="onSelectPage($event)"
                    (typed)=checkValuePage($event)
                  >
                  </app-datalist>
                </div>
              </div>

              <div class="col-lg-4 col-md-12" *ngIf="currentModule.slug == 'website'">
                <div class="form-group form-radio-group" [ngClass]="{ 'has-danger': submitted && f.universe.errors }">
                  <label class="col-form-label">Univers</label>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio" name="universe" formControlName="universe" [value]="0"> B2C
                      <span class="circle">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="radio" name="universe" formControlName="universe" [value]="1"> B2B
                      <span class="circle">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-12">
                <div class="form-group" [ngClass]="{ 'has-danger': submitted && f.start_date.errors }">
                  <label class="col-form-label">Date de début</label>
                  <div class="form-group">
                    <input type="date" class="form-control datetimepicker" name="start_date" formControlName="start_date">
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-12">
                <div class="form-group" [ngClass]="{ 'has-danger': submitted && f.end_date.errors }">
                  <label class="col-form-label">Date de fin</label>
                  <div class="form-group">
                    <input type="date" class="form-control datetimepicker" name="end_date" formControlName="end_date">
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-12">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.rank.errors }">
                  <label for="rank" class="bmd-label-floating">Ordre d'affichage (1 en 1er, 2 en 2ème, etc)</label>
                  <input type="number" min="0" max="99999" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="rank" formControlName="rank">
                </p>
              </div>

              <hr style="border-color:transparent;">

              <!-- NAVIGATION LINKS -->
              <a [routerLink]="[(currentModule.slug == 'website') ? '/website/settings' : '/' + currentModule.slug, 'page-backgrounds']" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
                Retour
              </a>
              <button [disabled]="editFormGroup.invalid"
                class="ppp-button ppp-button-black btn btn-primary btn-round"
                (click)="onSubmit()">
                <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
                <ng-template #creationTemplateText>Créer</ng-template>
              </button>
            </div>

          </div>

        </form>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
