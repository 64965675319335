import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { PageAttachmentsService } from 'src/app/services/pageAttachments.service';
import { PagesService } from 'src/app/services/pages.service';

@Component({
  selector: 'app-page-answers',
  templateUrl: './page-answers.component.html',
  styleUrls: ['./page-answers.component.scss']
})
export class PageAnswersComponent implements OnInit {
  public items: Array<any> = [];
  public columns: Array<any> = [
    { name: 'ID', slug: 'id'}
  ];


  public loading: boolean = false;
  public loadingList: boolean = false;

  constructor(
    private alertService: AlertService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private pagesService: PagesService,
    private pageAttachmentsService: PageAttachmentsService,
    private enumsService: EnumsService) {

      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          this.loading = false;
          this.loadingList = true;
          this.pageAttachmentsService.getAll()
            .then((response: any) => {
              this.loadingList = false;
              this.items = response.items;
            })
            .catch((err) => {
              this.loadingList = false;
              this.alertService.error(err);
            });
        }
      });

    }

  ngOnInit(): void {
  }

  onDelete(id) {

  }

}
