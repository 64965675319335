import { Injectable } from '@angular/core';

import 'bootstrap-notify';
import { ApiService } from './api.service';

import { Questionnaire } from '../models/Questionnaire';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';


@Injectable({ providedIn: 'root' })
export class QuestionnairesService {
  constructor(private apiService: ApiService) {

  }

  getById(questionnaireId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('questionnaires/' + questionnaireId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });

    });
  }

  getAll(sortBy: { field: string, order: string } = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('questionnaires'
        + (sortBy ? `?sort_by:${sortBy.field}=${sortBy.order}` : '')
        + (filters ? '&' + parseFiltersForUrl(filters) : '')
      ).then((response) => {
        resolve(response);
      })
        .catch((err) => {
          reject(err);
        });
    });
  }


  getAllWithPagination(page: string | number, sortBy: { field: string, order: string } = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('questionnaires?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '')
        + (filters ? '&' + parseFiltersForUrl(filters) : '')
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(questionnaire: Questionnaire) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('questionnaire:name', questionnaire.name);
      formData.append('questionnaire:show_name', questionnaire.show_name);
      formData.append('questionnaire:start_at', questionnaire.start_at.toString());
      formData.append('questionnaire:end_at', questionnaire.end_at.toString());
      formData.append('questionnaire:for_year', questionnaire.for_year ? questionnaire.for_year.toString() : '');
      formData.append('questionnaire:society_fields', questionnaire.society_fields
        ? JSON.stringify(questionnaire.society_fields) : '');
      formData.append('questionnaire:contact_fields', questionnaire.contact_fields
        ? JSON.stringify(questionnaire.contact_fields) : '');
      formData.append('questionnaire:rubrics', questionnaire.rubrics
        ? JSON.stringify(questionnaire.rubrics) : '');

      if (questionnaire.tags_updated) {
        formData.append('questionnaire:tags_updated', JSON.stringify(questionnaire.tags_updated));
      }

      if (questionnaire.id) {
        this.apiService.put('questionnaires/' + questionnaire.id, formData)
          .then((response: Questionnaire) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        this.apiService.post('questionnaires', formData)
          .then((response: Questionnaire) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      }

    });
  }

  delete(questionnaireId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('questionnaires/' + questionnaireId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  checkBeforDeleteQuesiton(questionUuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.delete('questionnaires/question/' + questionUuid)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
