import { Component, OnInit } from '@angular/core';
import modules from '../../../../assets/modules.json';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/User';
import { Router } from '@angular/router';
import { PoliciesService } from 'src/app/services/policies.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [ // Have is own instance
    PoliciesService
  ]
})
export class SidebarComponent implements OnInit {

  public modules;
  public modulesList;
  public user: User;

  constructor(
    private authService: AuthService,
    public router: Router,
    public policiesService: PoliciesService) {
    this.modulesList = Object.keys(modules);
    this.modules = modules;
  }

  ngOnInit(): void {
    this.authService.currentUser.subscribe({
      complete: () => {
        this.user = this.authService.currentUserValue;
        this.policiesService.currentUser = this.authService.currentUserValue;
      }
    });
  }


  onLogout(event: Event) {
    event.preventDefault();
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  ngAfterViewInit() {
    jQuery('.collapse').on('show.bs.collapse', () => {

    // @ts-ignore: Unreachable code error
      jQuery('.collapse').collapse('hide');
    });
  }
}
