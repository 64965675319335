<app-topbar moduleSlug="job">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active">Tableau de bord</a>
        </li>
        <li class="breadcrumb-item"><a [routerLink]="['/job']">Site emploi</a></li>
        <li class="breadcrumb-item" [ngSwitch]="route.snapshot.params.type">
          <a [routerLink]="['/job', 'candidatures-' + route.snapshot.params.type]" *ngSwitchCase="'spontanee'">Candidatures spontanées</a>
          <a [routerLink]="['/job', 'candidatures-' + route.snapshot.params.type]" *ngSwitchCase="'flash'">Candidatures flash</a>
          <span *ngSwitchDefault></span>
        </li>
        <li class="breadcrumb-item">{{route.snapshot.params.id}}</li>
        <li class="breadcrumb-item">Historique</li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>


<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">list</i>
        </div>
        <h4 class="card-title">Historique
        </h4>
      </div>
      <div class="card-body">
        <ng-container *ngIf="historiques; else no_history">
          <ul>
            <li *ngFor="let historique of historiques">
              {{historique.created_at | date: 'dd/MM/yy - HH:mm:ss' }} - {{historique.user.email}}
              <ul *ngIf="historique.user.adherents">
                <li *ngFor="let adherent of historique.user.adherents">{{adherent.name}}</li>
              </ul>
            </li>
          </ul>
        </ng-container>
        <ng-template #no_history>
          <p>Aucun historique de consultation enregistré.</p>
        </ng-template>
      </div>
    </div>
  </div>
</div>
