import { Injectable } from '@angular/core';
import { HighLightCategory } from '../models/HighLightCategory';
import { ApiService } from './api.service';

@Injectable({providedIn: 'root'})
export class HighLightCategoriesService {
  constructor(private apiService: ApiService) { }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('highlight_categories')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id) {
    return new Promise((resolve, reject) => {
      this.apiService.get('highlight_categories/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  edit(categorie: HighLightCategory) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('highlight_category:name', categorie.name);
      formData.append('highlight_category:rank', categorie.rank.toString());
      formData.append('brand_ids', categorie.brands.join(','))
      this.apiService.put('highlight_categories/' + categorie.id, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
}
