import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import modules from '../../../../assets/modules.json';

import { User } from 'src/app/models/User';
import { LoyaltyCard } from 'src/app/models/LoyaltyCard';
import { LoyaltyCustomerHistory } from 'src/app/models/LoyaltyCustomerHistory';
import { LoyaltyCardOrganization } from 'src/app/models/LoyaltyCardOrganization';

import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoyaltyCardsService } from 'src/app/services/loyalty-cards.service';
import { LoyaltyCustomerHistoriesService } from 'src/app/services/loyalty-customer-histories.service';
import { LoyaltyCardOrganizationsService } from 'src/app/services/loyalty-card-organizations.service';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {

  // URL FORMAT domain/module/service/id/consultation

  public modules;
  public modulesStringList;
  public user: User;
  currentModule: string = null;
  currentService: {name: string, slug: string, sub: any} = {
    name: null,
    slug: null,
    sub: null
  };

  currentItem: LoyaltyCard | LoyaltyCustomerHistory | LoyaltyCardOrganization = null;
  public fields: Array<any>;

  constructor(private authService: AuthService,
              public loyaltyCardsService: LoyaltyCardsService,
              public loyaltyCustomerHistoriesService: LoyaltyCustomerHistoriesService,
              public loyaltyCardOrganizationsService: LoyaltyCardOrganizationsService,
              public alertService: AlertService,
              private route: ActivatedRoute,
              private router: Router) {

    this.currentModule =  this.router.url.split('/')[1];
    this.modules = modules;

    this.currentService.slug = this.router.url.split('/')[2];
    switch (this.currentService.slug) {
      case 'loyalty-cards':
        this.currentService.name =  'cartes de fidélité';
        this.fields = [
          {name: 'ID', slug: 'id'},
          {name: 'N° carte de fidélité', slug: 'number'},
          {name: 'Client', slug: 'customer', template: true},
          {name: 'Société', slug: 'loyalty_card_organization', template: true}
        ];
        // Find Loyalty Card by ID
        this.loyaltyCardsService.getById(this.route.snapshot.params.id)
          .then((response: LoyaltyCard) => {
            this.currentItem = response;
          })
          .catch((err) => {
            this.alertService.error(err);
          });
        break;
      case 'loyalty-cards-history':
        this.currentService.name =  'historiques des transactions sur cartes';
        this.fields = [
          { name: 'ID', slug: 'id' },
          { name: 'Client', slug: 'name', template: true },
          { name: 'Promotion', slug: 'loyalty_rule', template: true },
          { name: "Montant d'achats", slug: 'amount', template: true },
          { name: 'Points gagnés', slug: 'earned_points' },
          { name: 'Montant gagné (€)', slug: 'earned_amount', template: true },
          { name: 'Agence', slug: 'agency', template: true }
        ];
        // Find Loyalty Customer History by ID
        this.loyaltyCustomerHistoriesService.getById(this.route.snapshot.params.id)
          .then((response: LoyaltyCardOrganization) => {
            this.currentItem = response;
          })
          .catch((err) => {
            this.alertService.error(err);
          });
        break;
      case 'societies':
        this.currentService.name =  'sociétés';
        this.fields = [
          { name: 'ID', slug: 'id' },
          { name: 'Nom', slug: 'name' },
          { name: 'Adhérent', slug: 'adherent_name' },
          { name: 'Nombre de cartes de fidélité créées', slug: 'created_count', template: true },
          { name: 'Nombre de cartes de fidélité activées', slug: 'activated_count', template: true }
        ];
        // Find Loyalty Card Organization by ID
        this.loyaltyCardOrganizationsService.getById(this.route.snapshot.params.id)
          .then((response: LoyaltyCardOrganization) => {
            this.currentItem = response;
          })
          .catch((err) => {
            this.alertService.error(err);
          });
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    if (this.authService.currentUserValue) {
      this.modules = modules;
      this.modulesStringList = Object.keys(modules);
      this.user = this.authService.currentUserValue;
    }
  }

}
