<app-topbar moduleSlug="adherents-companies-management">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/adherents-companies-management']">Adhérents / Sociétés</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/adherents-companies-management', module]">Liste des adhérents</a></li>
        <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
          <li class="breadcrumb-item active"><a href="javascript:;">Éditer un adhérent</a></li>
        </ng-container>
        <ng-template #templateElse>
          <li class="breadcrumb-item active"><a href="javascript:;">Créer un nouvel adhérent</a></li>
        </ng-template>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<ng-container *ngIf="editFormGroup">
  <div class="row" *ngIf="!editMode.active || (editMode.active && editMode.data)">
    <div class="col-12">
      <form class="form row" [formGroup]="editFormGroup">
        <div class="col-lg-6 col-md-12">
          <div class="card">
            <!-- HEADER -->
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">domain</i>
              </div>
              <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
                <h4 class="card-title">Édition d'un adhérent : <strong class="description">{{editMode.data.name}}</strong></h4>
              </ng-container>
              <ng-template #templateElse>
                <h4 class="card-title">Création d'un nouvel adhérent</h4>
              </ng-template>
            </div>

            <!-- MAIN CONTENT -->
            <div class="card-body">
              <div>
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.name.errors }">
                  <label for="adherent_name" class="bmd-label-floating">Nom</label>
                  <input type="text" class="form-control" id="adherent_name" formControlName="name">
                </p>
              </div>

              <div>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.mail.errors }">
                  <label for="adherent_mail" class="bmd-label-floating">Email</label>
                  <input type="email" class="form-control" id="adherent_mail" formControlName="mail">
                  <label class="error" for="adherent_mail" *ngIf="f.mail.errors && f.mail.errors.email">Veuillez entrer un email valide.</label>
                </p>
              </div>

              <div>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.phone_number.errors }">
                  <label for="adherent_phone_number" class="bmd-label-floating">Téléphone fixe</label>
                  <input type="text" class="form-control" id="adherent_phone_number" formControlName="phone_number">
                </p>
              </div>

              <div>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.phone_number2.errors }">
                  <label for="adherent_phone_number2" class="bmd-label-floating">Téléphone mobile</label>
                  <input type="text" class="form-control" id="adherent_phone_number2" formControlName="phone_number2">
                </p>
              </div>

              <div>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.siret.errors }">
                  <label for="adherent_siret" class="bmd-label-floating">SIRET</label>
                  <input type="text" class="form-control" id="adherent_siret" formControlName="siret">
                </p>
              </div>

              <div>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.rib.errors }">
                  <label for="adherent_rib" class="bmd-label-floating">RIB</label>
                  <input type="text" class="form-control" id="adherent_rib" formControlName="rib">
                </p>
              </div>

              <div>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.codefac.errors }">
                  <label for="adherent_codefac" class="bmd-label-floating">Code facturation</label>
                  <input type="text" class="form-control" id="adherent_codefac" formControlName="codefac">
                </p>
              </div>

              <div>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.vat_number.errors }">
                  <label for="adherent_vat_number" class="bmd-label-floating">Numéro TVA</label>
                  <input type="text" class="form-control" id="adherent_vat_number" formControlName="vat_number">
                </p>
              </div>

              <div class="form-group form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" [name]="" formControlName="is_closed">Adhérent fermé ?
                  <span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
              </div>

              <div>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.human_ressource_last_name.errors }">
                  <label for="human_ressource_last_name" class="bmd-label-floating">Nom RH</label>
                  <input type="text" class="form-control" id="human_ressource_last_name" formControlName="human_ressource_last_name">
                </p>
              </div>

              <div>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.human_ressource_first_name.errors }">
                  <label for="human_ressource_first_name" class="bmd-label-floating">Prénom RH</label>
                  <input type="text" class="form-control" id="human_ressource_first_name" formControlName="human_ressource_first_name">
                </p>
              </div>

              <div>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.human_ressource_email.errors }">
                  <label for="human_ressource_email" class="bmd-label-floating">Email RH</label>
                  <input type="text" class="form-control" id="human_ressource_email" formControlName="human_ressource_email">
                </p>
              </div>

              <div>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.regions.errors }">
                  <label for="regions" class="bmd-label-floating">Région(s)</label> <br>
                  <select multiple="true" formControlName="regions">
                    <option *ngFor="let region of enumsService.enums.regions"
                      [value]="region.name">{{region.name}}</option>
                  </select>
                </p>
              </div>

              <div>
                <div class="form-group">
                  <label for="description" class="bmd-label-floating">Site Emploi : description affichée sur la page adhérent</label>
                  <ckeditor formControlName="description" [config]="ckEditorConfig"></ckeditor>
                </div>
              </div>

              <div>
                <div class="form-group">
                  <label>Site Emploi : image 1 affichée sur la page adhérent</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active ? editMode.data.job_image_1 : null"
                    (fileDeleted)="onJobImageSelected('job_image_1', null)"
                    (filesSelected)="onJobImageSelected('job_image_1', $event)"></app-drag-drop-file-input>
                </div>
              </div>

              <div>
                <div class="form-group">
                  <label>Site Emploi : image 2 affichée sur la page adhérent</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active ? editMode.data.job_image_2 : null"
                    (fileDeleted)="onJobImageSelected('job_image_2', null)"
                    (filesSelected)="onJobImageSelected('job_image_2', $event)"></app-drag-drop-file-input>
                </div>
              </div>

              <div>
                <div class="form-group">
                  <label>Site Emploi : image 3 affichée sur la page adhérent</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active ? editMode.data.job_image_3 : null"
                    (fileDeleted)="onJobImageSelected('job_image_3', null)"
                    (filesSelected)="onJobImageSelected('job_image_3', $event)"></app-drag-drop-file-input>
                </div>
              </div>

              <hr style="border-color:transparent;">

              <!-- NAVIGATION LINKS -->
              <a [routerLink]="['/adherents-companies-management', module]" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
                Retour
              </a>

              <button *ngIf="!loadingService.loading; else templateSubmit"
                [disabled]="editFormGroup.invalid"
                class="ppp-button ppp-button-black btn btn-primary btn-round"
                (click)="onSubmit()">
                <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
                <ng-template #creationTemplateText>Créer</ng-template>
              </button>
              <ng-template #templateSubmit>
                <button
                  disabled="true"
                  class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                  ...
                </button>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12" *ngIf="(editMode.active && editMode.data);">
          <div class="card">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">loyalty</i>
              </div>
              <h4 class="card-title">Promotions rattachée(s) à l'adhérent</h4>
            </div>
            <div class="card-body">
              <div class="card-body">
                <ul class="col-12">
                  <li *ngFor="let item of editMode.data.loyalty_rules">
                    <button type="button" class="btn btn-danger" title="Supprimer"
                      (click)="onDelete(item.id)">
                      <i class="material-icons">close</i>
                    </button>
                    {{item.name}}
                  </li>
                </ul>
                <form *ngIf="addFormGroup" class="form row" [formGroup]="addFormGroup">
                  <div class="col-10">
                    <label for="value" class="bmd-label-floating">Liste des promotions existantes</label>
                    <select class="form-control" id="value" formControlName="loyalty_rule">
                      <option
                        *ngFor="let option of authService.currentUserValue.loyalty_rules"
                        [attr.value]="option.id"
                        [disabled]="isAlreadySelected(option.id)"
                        >
                        {{option.name}}
                      </option>
                    </select>
                  </div>
                  <div class="row col-2">
                    <button
                      *ngIf="!loadingService.loading; else templateSubmit"
                      [disabled]="addFormGroup.invalid"
                      class="ppp-button ppp-button-black btn btn-primary btn-round"
                      (click)="onAddLoyaltyRule()"
                    >
                      <ng-container><i class="material-icons">add</i></ng-container>
                    </button>
                    <ng-template #templateSubmit>
                      <button
                        disabled="true"
                        class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()"
                      >
                        ...
                      </button>
                    </ng-template>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
