import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import modules from '../../../../assets/modules.json';

import { User } from 'src/app/models/User';
import { QuoteRequest } from 'src/app/models/QuoteRequest';

import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { QuoteRequestsService } from 'src/app/services/quote-requests.service';
import { PageAttachmentsService } from 'src/app/services/pageAttachments.service';
import { VehiculesService } from 'src/app/services/vehicules.service';
import { AddressesService } from 'src/app/services/addresses.service';
import { OrdersService } from 'src/app/services/orders.service';
import { CustomersService } from 'src/app/services/customers.service';
import { TireModelsService } from 'src/app/services/tireModels.service';
import { TiresService } from 'src/app/services/tires.service';
import { CustomerReviewsService } from 'src/app/services/customer-reviews.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { ProCompaniesService } from 'src/app/services/pro-companies.service';
import { AppointmentsService } from 'src/app/services/appointments.service';
import { DeletedLoyaltyCardsService } from 'src/app/services/deleted-loyalty-cards-service';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {
  public modules;
  public modulesStringList;
  public user: User;
  currentModule: string = null;
  currentService: { name: string, slug: string, sub: any } = {
    name: null,
    slug: null,
    sub: null
  };

  currentItem: any = null;
  public fields: Array<any>;

  constructor(public authService: AuthService,
              public policiesService: PoliciesService,
              public quoteRequestsService: QuoteRequestsService,
              public appointmentsService: AppointmentsService,
              public alertService: AlertService,
              public vehiculesService: VehiculesService,
              public pageAttachmentsService: PageAttachmentsService,
              public addressesService: AddressesService,
              public ordersService: OrdersService,
              public customersService: CustomersService,
              public TireModelsService: TireModelsService,
              public customerReviewsService: CustomerReviewsService,
              public proCompaniesService: ProCompaniesService,
              private tiresService: TiresService,
              private deletedLoyaltyCardsService: DeletedLoyaltyCardsService,
              private route: ActivatedRoute,
              private router: Router) {
    this.policiesService.currentUser = this.authService.currentUserValue;

    this.currentModule = this.router.url.split('/')[1];
    this.modules = modules;

    this.currentService.slug = this.router.url.split('/')[2];
    switch (this.currentService.slug) {
      case 'deleted-loyalty-cards':
        this.currentService.name = 'Historique clients / cartes'
        this.fields = [
          { name: 'ID', slug: 'id' },
          { name: 'Montant restant (encours)', slug: 'remaining_earning_amount' },
          { name: 'Date', slug: 'created_at' },
          { name: 'Numéro de carte', slug: 'card_number' }
        ]

        this.deletedLoyaltyCardsService.getById(this.route.snapshot.params.id)
          .then((response: QuoteRequest) => {
            this.currentItem = response;
          })
          .catch((err) => {
            this.alertService.error(err);
          })

        break;
      case 'quote-requests':
        this.currentService.name = 'Demande de devis'
        this.fields = [
          { name: 'ID', slug: 'id' },
          { slug: 'services', template: true },
          { slug: 'customer_civility', template: true },
          { slug: 'details_name', template: true },
          { slug: 'appointment_details', template: true },
          { slug: 'customer_comment', template: true },
          { slug: 'customer_name', template: true },
          { slug: 'customer_email', template: true },
          { slug: 'customer_number', template: true },
          { slug: 'customer_optin', template: true },
          { slug: 'agency_details', template: true },
          { slug: 'created_at', template: true }
        ];
        this.quoteRequestsService.getById(this.route.snapshot.params.id)
          .then((response: QuoteRequest) => {
            this.currentItem = response;
          })
          .catch((err) => {
            this.alertService.error(err);
          })
        break;
      case 'appointments':
        this.currentService.name = 'Demande de rendez-vous'
        this.fields = [
          { name: 'ID', slug: 'id' },
          { slug: 'services', template: true },
          { slug: 'customer_civility', template: true },
          { slug: 'details_name', template: true },
          { slug: 'appointment_details', template: true },
          { slug: 'customer_comment', template: true },
          { slug: 'customer_name', template: true },
          { slug: 'customer_email', template: true },
          { slug: 'customer_number', template: true },
          { slug: 'customer_optin', template: true },
          { slug: 'agency_details', template: true },
          { slug: 'created_at', template: true }
        ];
        this.appointmentsService.getById(this.route.snapshot.params.id)
          .then((response: QuoteRequest) => {
            this.currentItem = response;
          })
          .catch((err) => {
            this.alertService.error(err);
          })
        break;
      case 'tires':
        this.currentService.name = 'Gestion des pneus';
        if (this.router.url.split('/')[3] === 'tire-models') {
          this.currentService.sub = {
            name: 'Profils de pneus',
            slug: 'tire-models'
          }
          this.fields = [
            { name: 'ID', slug: 'id' },
            { name: 'Dénomination', slug: 'name' },
            { name: 'Titre', slug: 'title' },
            { name: 'Description', slug: 'description' },
            { name: 'Marque', slug: 'brand', template: true },
            { name: 'Photo', slug: 'tire_model_photo', template: true },
            { name: 'Clé de vidéo Youtube ', slug: 'video_key' }
          ];
          this.TireModelsService.getById(this.route.snapshot.params.id)
            .then((response: any) => {
              this.currentItem = response;
            })
            .catch((err) => {
              this.alertService.error(err);
            });
        }

        if (this.router.url.split('/')[3] === 'vl' || this.router.url.split('/')[3] === 'pl' || this.router.url.split('/')[3] === 'ag' || this.router.url.split('/')[3] === 'ag_used') {
          this.currentService.sub = {
            name: '',
            slug: this.route.snapshot.params.type
          }
          switch (this.route.snapshot.params.type) {
            case 'vl':
              this.currentService.sub.name = 'Pneus VL'
              break;
            case 'pl':
              this.currentService.sub.name = 'Pneus PL'
              break;
            case 'ag':
              this.currentService.sub.name = 'Pneus AG'
              break;
            case 'ag_used':
              this.currentService.sub.name = 'Pneus AG d\'occasion'
              break;
            default:
              break;
          }
          this.fields = [
            { name: 'Nom', slug: 'name' },
            { name: 'EAN', slug: 'ean' },
            { name: 'Catégorie', slug: 'category', template: true },
            { name: 'Saison', slug: 'season', template: true },
            { name: 'Largeur', slug: 'width' },
            { name: 'RImport', slug: 'rimport', template: true },
            { name: 'Hauteur', slug: 'height' },
            { name: 'Diamètre', slug: 'diameter' },
            { name: 'Indice de charge', slug: 'load_index' },
            { name: 'Indice de vitesse', slug: 'speed_index', template: true },
            { name: 'Runflat', slug: 'runflat', template: true },
            { name: 'Résistance au roulement', slug: 'label_fuel', template: true },
            { name: 'Adhérence sur sol mouillé', slug: 'label_wet', template: true },
            { name: 'Bruit de roulement externe', slug: 'label_noise' },
            { name: 'Profil de pneu', slug: 'tire_model', template: true },
            { name: 'Marque', slug: 'tire_brand', template: true },
            { name: 'Prix TTC', slug: 'price_with_tax', template: true },
            { name: 'Promo', slug: 'on_sale', template: true },
            { name: 'Description', slug: 'description' },
            { name: 'Homologation', slug: 'homologation' },
            { name: 'Prix BF', slug: 'bf_price', template: true },
            { name: 'Prix avec promo', slug: 'price_with_sale', template: true },
            { name: 'Quantité', slug: 'sale_quantity' },
            { name: 'Loi appliquée', slug: 'law_code', template: true },
            { name: '3PMSF ?', slug: 'is_3pmsf', template: true },
            { name: 'Adhérence sur verglas ?', slug: 'ice_grip', template: true },
            { name: 'URL vers la fiche pneu EPREL', slug: 'eprel_url' },
            { name: 'Catégorie de bruit', slug: 'category_noise' },
            { name: 'Profil du pneu', slug: 'tire_profile' },
            { name: 'Taux d\'usure', slug: 'wear_rate' },
            { name: 'Mm de gomme restant', slug: 'remaining_gum' },
            { name: 'Photo 1', slug: 'tire_photo_1', template: true },
            { name: 'Photo 2', slug: 'tire_photo_2', template: true },
            { name: 'Photo 3', slug: 'tire_photo_3', template: true },
            { name: 'Photo 4', slug: 'tire_photo_4', template: true },
            { name: 'Pneu disponible dans l\'agence', slug: 'agency_id' },
            { name: 'Lieu de vente (Ville)', slug: 'saler_city' },
            { name: 'Département', slug: 'subdivision_code' },
            { name: 'Prénom du vendeur', slug: 'saler_first_name' },
            { name: 'Nom du vendeur', slug: 'saler_last_name' },
            { name: 'Téléphone du vendeur', slug: 'saler_phone' },
            { name: 'Email du vendeur', slug: 'saler_email' },
            { name: 'Référence d\'annonce', slug: 'reference_number' }
          ];
          this.tiresService.getById(this.route.snapshot.params.id)
            .then((response: QuoteRequest) => {
              this.currentItem = response;
            })
            .catch((err) => {
              this.alertService.error(err);
            });
        }
        break;
      case 'pages':
        if (this.router.url.split('/')[3] === 'attachments') {
          this.currentService.name = 'Pièces jointes';
          this.pageAttachmentsService.getById(this.router.url.split('/')[4])
            .then((reponse: any) => {
              this.currentItem = reponse;
              console.log(this.currentItem)
              this.fields = [
                { name: 'ID', slug: 'id' },
                { name: 'Page', slug: 'page_id' },
                { name: 'File', slug: 'file', template: true },
                { name: 'Key', slug: 'key' },
                { name: 'Date d\'upload', slug: 'created_at', template: true },
              ];
            })
            .catch((err) => {
              this.alertService.error(err);
            });
        }
        break;
      case 'customers':
        switch (this.router.url.split('/')[3]) {
          case 'customer-entreprises':
            this.currentService.sub = {
              name: 'Entreprises (PRO)',
              slug: 'customer-entreprises'
            }
            this.proCompaniesService.getById(this.router.url.split('/')[4])
              .then((reponse: any) => {
                this.currentItem = reponse;
                this.fields = [
                  { name: 'ID', slug: 'id' },
                  { name: 'Nom de la société', slug: 'name' },
                  { name: 'Adresse', slug: 'address' },
                  { name: 'Téléphone de la société', slug: 'phone' },
                  { name: 'Email de la société', slug: 'email' },
                  { name: 'Fax de la société', slug: 'fax' },
                  { name: 'Numéro TVA', slug: 'nds' },
                  { name: 'N° siret', slug: 'siret' },
                  { name: 'Raison sociale', slug: 'full_name' },
                  { name: 'Code postal', slug: 'zipcode' },
                  { name: 'Ville', slug: 'city' },
                  { name: 'Clients', slug: 'customers', template: true },
                ];
              })
              .catch((err) => {
                this.alertService.error(err);
              });
            break;
          case 'vehicules':
            this.currentService.name = 'Véhicules';
            this.vehiculesService.getById(this.router.url.split('/')[4])
              .then((reponse: any) => {
                this.currentItem = reponse;
                this.fields = [
                  { name: 'ID', slug: 'id' },
                  { name: 'Immatricule', slug: 'matricule' },
                ];
              })
              .catch((err) => {
                this.alertService.error(err);
              });
            break;
          default:
            // by default use CUSTOMER info
            this.customersService.getById(this.route.snapshot.params.id)
              .then((reponse) => {
                this.currentService.name = 'Clients';
                this.currentItem = reponse;
                this.fields = [
                  { name: 'ID', slug: 'id' },
                  { name: 'Civilité', slug: 'civility', template: true },
                  { name: 'Nom', slug: 'last_name' },
                  { name: 'Prénom', slug: 'first_name' },
                  { name: 'Email', slug: 'email' },
                  { name: 'Téléphone fixe', slug: 'phone' },
                  { name: 'Téléphone mobile', slug: 'mob_phone' },
                  { name: 'Newsletter promotion', slug: 'optin_promo', template: true },
                  { name: 'Newsletter MyProfil+', slug: 'optin_myprofilplus', template: true },
                  { name: 'Agence', slug: 'agency', template: true },
                  { name: 'Société', slug: 'company' },
                  { name: 'Entreprise', slug: 'pro_company', template: true },
                  { name: 'Adresse de facturation', slug: 'billing_address', template: true },
                  { name: 'Compte désactivé ?', slug: 'deactivated', template: true },
                  { name: 'Compte pro ? (B2B)', slug: 'pro', template: true },
                  { name: 'Adresse de livraison', slug: 'shipping_address', template: true },
                  { name: 'Véhicules', slug: 'vehicules', template: true },
                  { name: 'Carte de fidélité', slug: 'loyalty_card', template: true },
                  { name: 'Promotion associée à la carte de fidélité', slug: 'loyalty_rule', template: true },
                  { name: 'Date de création', slug: 'created_at', template: true }
                ];
              })
              .catch((err) => {
                this.alertService.error(err);
              });

            break;
        }
        break;
      case 'orders':
        this.currentService.name =  'Commandes';
          this.ordersService.getById(parseInt(this.router.url.split('/')[3]))
            .then((response: any) => {
              this.currentItem = response;
              this.fields = [
                { name: 'ID', slug: 'id' },
                { name: 'new_line', slug: 'new_line', template: true },
                { name: "Numéro de commande", slug: 'order_number', template: true },
                { name: 'ID de transaction Google', slug: 'google_id_transaction' },
                { name: 'Statut', slug: 'status_order', template: true },
                { name: 'Univers', slug: 'universe', template: true },
                { name: 'new_line', slug: 'new_line', template: true },
                { name: 'Client', slug: 'details_name', template: true },
                { name: 'Email client', slug: 'details_email', template: true },
                { name: 'Téléphone client', slug: 'details_phone', template: true },
                { name: 'new_line', slug: 'new_line', template: true },
                { name: 'Commande', slug: 'order', template: true },
                { name: 'new_line', slug: 'new_line', template: true },
                { name: 'Montage, équilibrage en garage', slug: 'assembly', template: true },
                { name: 'Contribution environmentale', slug: 'details_environmental_contribution', template: true },
                { name: 'Montant de la commande', slug: 'total_str', template: true },
                { name: 'new_line', slug: 'new_line', template: true },
                { name: 'Rendez-vous du client', slug: 'appointment_details', template: true },
                { name: 'new_line', slug: 'new_line', template: true },
                { name: 'Agence', slug: 'agency_details', template: true }
              ];
            })
            .catch((err) => {
              this.alertService.error(err);
            });
        break;
      case 'customer-reviews':
        this.currentService.name = 'Avis client';
        this.customerReviewsService.getById(parseInt(this.router.url.split('/')[3]))
          .then((response) => {
            this.currentItem = response;
            this.fields = [
              { name: 'ID', slug: 'id' },
              { name: "Utilisateur", slug: 'reviewer_name' },
              { name: "Email", slug: 'email' },
              { name: "Contenu", slug: 'content' },
              { name: "Note", slug: 'rating', template: true },
              { name: "Porte sur", slug: 'reviewable_type', template: true },
              { name: "Statut", slug: 'status_review', template: true },
            ];
          })
          .catch((err) => {
            this.alertService.error(err);
          });
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    if (this.authService.currentUserValue) {
      this.modules = modules;
      this.modulesStringList = Object.keys(modules);
      this.user = this.authService.currentUserValue;
    }
  }

}
