import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { BrandInformationsService } from 'src/app/services/brand-informations.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';

import config from 'src/app/config';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public loading: boolean = false;
  public editMode: {
    active: boolean,
    data: any
  } = {active: false, data: null};
  public preselectBrand = null;
  public submitted: boolean = false;
  public editFormGroup: UntypedFormGroup;
  public ckEditorConfig = config.ckEditor;


  constructor(
    public enumsService: EnumsService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public brandInformationsService: BrandInformationsService,
    private alertService: AlertService,
    public loadingService: LoadingService) {

      this.enumsService.observable.subscribe({
        complete: () => {
          if (this.route.snapshot.params.id) { // EDIT
            this.editMode.active = true;
            this.brandInformationsService.getById(this.route.snapshot.params.id).then((response: any) => {
              this.editMode.data = response;

              if (response.brand) {
                this.preselectBrand = this.enumsService.enums.brands.findIndex(b => b.id === response.brand.id)
              }

              this.editMode.data.edito_image = (response.edito_image) ? [{name: 'affichage de votre fichier', link: response.edito_image}] : null;

              this.editFormGroup = this.formBuilder.group({
                brand_id: [response.brand.id, Validators.required],
                universe: [response.universe.id, Validators.required],
                video: [response.video, null],
                description: [response.description, null],
                content: [response.content, null],
                is_default: [response.is_default, null],
                edito_content: [response.edito_content, null],
                edito_image: [null, null],
                edito_start_at: [response.edito_start_at ? new Date(response.edito_start_at).toISOString().split("T")[0] : null, null],
                edito_end_at: [response.edito_end_at ? new Date(response.edito_end_at).toISOString().split("T")[0] : null, null]
              });
            }).catch((err) => {
              this.loading = false;
              this.alertService.error(err);
            });
          } else {
            this.editFormGroup = this.formBuilder.group({
              brand_id: [null, Validators.required],
              universe : [null, Validators.required],
              video: [null, null],
              description: [null, null],
              content: [null, null],
              is_default: [false, null],
              edito_content: [null, null],
              edito_image: [null, null],
              edito_start_at: [null, null],
              edito_end_at: [null, null]
            });
          }
        }
      });
    }

  ngOnInit(): void {
  }

  get f() { return this.editFormGroup.controls; }

  onSelectedBrand(brand) {
    this.editFormGroup.patchValue({brand_id: brand.id});
  }


  onFileSelected(files: Array<File>) {
    this.editFormGroup.patchValue({edito_image: files ? files[0] : ''});
    if (!files && this.editMode.data) {
      this.editMode.data.edito_image = null;
      this.editFormGroup.patchValue({edito_image: ''});
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    const brandInformation = {
      ...this.editFormGroup.value,
    };

    if (this.editMode) {
      brandInformation.id = this.route.snapshot.params.id;
    }
    this.brandInformationsService.edit(brandInformation).then(response => {
      this.router.navigate(['/website/tires/brand-informations']);
      this.alertService.success((this.editMode && this.editMode) ? 'Modification enregistrée avec succès.' : 'Informations de marque enregistrées');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

}
