import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import 'bootstrap-notify';
import { ApiService } from './api.service';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';

@Injectable({ providedIn: 'root' })
export class AgenciesService {
  constructor(private apiService: ApiService, private router: Router) {

  }

  getById(id: string | number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('agencies/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get all agencies
   */
  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('agencies/all_open')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /* Get all OPEN agencies */
  getAllOpenAgenciesWithPagination(page: string | number, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('agencies/open?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
        + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /* Get all CLOSED agencies */
  getAllClosedAgenciesWithPagination(page: string | number, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('agencies/closed?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
        + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAdherents() {
    return new Promise((resolve, reject) => {
      this.apiService.get('adherents')
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }


  getAgencies(adherants: Array<Number>) {
    return new Promise((resolve, reject) => {
      this.apiService.get('agencies/all_open' + ((adherants && adherants.length > 0) ? '?adherent_ids=' + adherants : ''))
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  searchAgencies(strg: String) {
    return new Promise((resolve, reject) => {
      this.apiService.get('agencies/all_open?agency_name=' + strg)
        .then((response) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  edit(agency: any, exceptionalClosing: boolean = false) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('agency:adherent_id', agency.adherent_id ? agency.adherent_id : '');
      formData.append('agency:company_id', agency.company_id ? agency.company_id : '');
      formData.append('agency:name', agency.name ? agency.name : '');
      formData.append('agency:phone', agency.phone ? agency.phone : '');
      formData.append('agency:fax', agency.fax ? agency.fax : '');
      formData.append('agency:latitude', agency.latitude ? agency.latitude : '');
      formData.append('agency:longitude', agency.longitude ? agency.longitude : '');
      formData.append('agency:id_agency', agency.id_agency ? agency.id_agency : '');
      formData.append('agency:adherent_code', agency.adherent_code ? agency.adherent_code : '');

      formData.append('agency:warehouse', agency.warehouse ? agency.warehouse.toString() : false);

      formData.append('agency:mail_manager', agency.mail_manager ? agency.mail_manager : '');
      formData.append('agency:lastname_manager', agency.lastname_manager ? agency.lastname_manager : '');
      formData.append('agency:firstname_manager', agency.firstname_manager ? agency.firstname_manager : '');
      formData.append('agency:mail_industrial_manager', agency.mail_industrial_manager ? agency.mail_industrial_manager : '');
      formData.append('agency:lastname_industrial_manager', agency.lastname_industrial_manager ? agency.lastname_industrial_manager : '');
      formData.append('agency:firstname_industrial_manager', agency.firstname_industrial_manager ? agency.firstname_industrial_manager : '');


      formData.append('address:street_number', agency.address_street_number ? agency.address_street_number : '');
      formData.append('address:street_name', agency.address_street_name ? agency.address_street_name : '');
      formData.append('address:city', agency.address_city ? agency.address_city : '');
      formData.append('address:zipcode', agency.address_zipcode ? agency.address_zipcode : '');

      formData.append('agency:display', agency.display ? agency.display.toString() : false);
      formData.append('agency:deactivate_contact', agency.deactivate_contact ? agency.deactivate_contact.toString() : false);
      formData.append('agency:deactivate_appointment', agency.deactivate_appointment ? agency.deactivate_appointment.toString() : false);
      formData.append('agency:deactivate_quote_requests', agency.deactivate_quote_requests ?
        agency.deactivate_quote_requests.toString() : false);
      formData.append('agency:deactivate_orders', agency.deactivate_orders ? agency.deactivate_orders.toString() : false);
      formData.append('agency:agency_payment', agency.agency_payment ? agency.agency_payment.toString() : false);
      formData.append('agency:online_payment', agency.online_payment ? agency.online_payment.toString() : false);
      formData.append('agency:settlement_payment', agency.settlement_payment ? agency.settlement_payment.toString() : false);

      formData.append('agency:intervention_emails', agency.intervention_emails ? agency.intervention_emails : '');

      formData.append('agency:accessibility_counter', agency.accessibility_counter ? agency.accessibility_counter.toString() : false);
      formData.append('agency:accessibility_toilet', agency.accessibility_toilet ? agency.accessibility_toilet.toString() : false);
      formData.append('agency:accessibility_building', agency.accessibility_building ? agency.accessibility_building.toString() : false);
      formData.append('agency:accessibility_parking', agency.accessibility_parking ? agency.accessibility_parking.toString() : false);
      formData.append('agency:accessibility_wheelchairs_slots', agency.accessibility_wheelchairs_slots ?
        agency.accessibility_wheelchairs_slots.toString() : false);
      formData.append('agency:social_reason', agency.social_reason ? agency.social_reason : '');
      formData.append('agency:b2b_only', agency.b2b_only ? agency.b2b_only.toString() : false);
      formData.append('agency:physical_agency', agency.physical_agency ? agency.physical_agency.toString() : false);
      formData.append('agency:pack_phone_plus', agency.pack_phone_plus ? agency.pack_phone_plus.toString() : false);
      formData.append('agency:phone_lines_count', agency.phone_lines_count ? agency.phone_lines_count : '');
      formData.append('agency:screen_memo_quantity', agency.screen_memo_quantity != null ? agency.screen_memo_quantity : 3);
      formData.append('agency:delivery_status', agency.delivery_status ? agency.delivery_status.toString() : false);
      formData.append('agency:erp', agency.erp ? agency.erp : '');
      formData.append('agency:wincho_tires', agency.wincho_tires ? agency.wincho_tires : 0);
      formData.append('agency:advertisement_tires', agency.advertisement_tires ? agency.advertisement_tires : 0);
      formData.append('agency:operation_sheet', agency.operation_sheet ? agency.operation_sheet : 0);
      formData.append('agency:wincho_ema', agency.wincho_ema ? agency.wincho_ema : 0);
      formData.append('agency:advertisement_ema', agency.advertisement_ema ? agency.advertisement_ema : 0);
      formData.append('agency:advertisement_a4_ema', agency.advertisement_a4_ema ? agency.advertisement_a4_ema : 0);
      formData.append('agency:permanent_4x3', agency.permanent_4x3 ? agency.permanent_4x3 : 0);
      formData.append('agency:operation_sheet_ema', agency.operation_sheet_ema ? agency.operation_sheet_ema : 0);
      formData.append('agency:quantity_masts', agency.quantity_masts ? agency.quantity_masts : 0);
      formData.append('agency:quantity_bays', agency.quantity_bays ? agency.quantity_bays : 0);
      formData.append('agency:quantity_bridges', agency.quantity_bridges ? agency.quantity_bridges : 0);
      formData.append('agency:quantity_vi_interventions', agency.quantity_vi_interventions ? agency.quantity_vi_interventions : 0);
      formData.append('agency:ref_epyx', agency.ref_epyx ? agency.ref_epyx : 0);
      formData.append('agency:date_epyx', agency.date_epyx ? agency.date_epyx.toString() : '');
      formData.append('agency:vat_number', agency.vat_number ? agency.vat_number : '');
      formData.append('agency:siret', agency.siret ? agency.siret : '');
      formData.append('agency:is_client_base', agency.is_client_base ? agency.is_client_base.toString() : false);
      formData.append('agency:payment_visa', agency.payment_visa ? agency.payment_visa.toString() : false);
      formData.append('agency:payment_mastercard', agency.payment_mastercard ? agency.payment_mastercard.toString() : false);
      formData.append('agency:payment_american_express', agency.payment_american_express ?
      agency.payment_american_express.toString() : false);
      formData.append('agency:payment_mobile_nfc', agency.payment_mobile_nfc ? agency.payment_mobile_nfc.toString() : false);
      formData.append('agency:payment_check', agency.payment_check ? agency.payment_check.toString() : false);
      formData.append('agency:has_protective_wall', agency.has_protective_wall ? agency.has_protective_wall.toString() : false);
      formData.append('agency:op_tire_easel', agency.op_tire_easel != null ? agency.op_tire_easel : 1);
      formData.append('agency:op_ema_easel', agency.op_ema_easel != null ? agency.op_ema_easel : 1);

      if (agency.id) { // EDIT
        this.apiService.put('agencies/' + agency.id, formData).then((response) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
      } else { // NEW
        this.apiService.post('agencies', formData).then((response) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
      }

    });
  }

  delete(id: string | number) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('agencies/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getCloseDates(id: string | number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('agencies/' + id + '/closed_dates')
      .then((response) => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
    });
  }

  deleteCloseDates(agencyId: string | number, dateId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('agencies/' + agencyId + '/closed_dates/' + dateId)
      .then((response) => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
    });
  }

  getServices(id: string | number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('agencies/' + id + '/services')
        .then((response) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getSkills(id: string | number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('agencies/' + id + '/skills')
        .then((response) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getCorrespondants(id: string | number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('agencies/' + id + '/correspondants')
        .then((response) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getCorrespondant(agencyId: string | number, correspondantId: string | number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('agencies/' + agencyId + '/correspondants/' + correspondantId)
        .then((response) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  deleteCorrespondants(agencyId: string | number, correspondantId: string | number) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('agencies/' + agencyId + '/correspondants/' + correspondantId)
        .then((response) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getCorrespondantMailSlug(agencyId: string | number, correspondantId: string | number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('agencies/' + agencyId + '/correspondants/' + correspondantId + '/scripsit')
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllMailSlug() {
    return new Promise((resolve, reject) => {
      this.apiService.get('scripsit/all')
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addCorrespondantMailSlugs(agencyId: string | number, correspondantId: string | number, mailSlugId: string | number) {
    const formData = new FormData();
    formData.append('correspondant:email', '---');
    return new Promise((resolve, reject) => {
      this.apiService.post('agencies/' + agencyId + '/correspondants/' + correspondantId + '/scripsit/' + mailSlugId, formData)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteCorrespondantMailSlug(agencyId: string | number, correspondantId: string | number, mailSlugId: string | number) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('agencies/' + agencyId + '/correspondants/' + correspondantId + '/scripsit/' + mailSlugId)
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getOpeningTimes(id: number, type: string) {
    return new Promise((resolve, reject) => {
      this.apiService.get('agencies/' + id + '/' + type + '_opening_times')
        .then((response) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  editClosedDate(agencyId: string | number, closeDate) {
    const formData = new FormData();
    formData.append('closed_date:name', closeDate.name);
    formData.append('closed_date:during', closeDate.during);
    formData.append('closed_date:during_end', closeDate.during_end);

    if (closeDate.id) { // EDIT
      return new Promise((resolve, reject) => {
        this.apiService.put('agencies/' + agencyId + '/closed_dates/' + closeDate.id, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    } else { // CREATE
      return new Promise((resolve, reject) => {
        this.apiService.post('agencies/' + agencyId + '/closed_dates', formData)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  editCorrespondant(agencyId, correspondant) {
    const formData = new FormData();
    formData.append('correspondant:firstname', correspondant.firstname ? correspondant.firstname : '');
    formData.append('correspondant:lastname', correspondant.lastname ? correspondant.lastname : '');
    formData.append('correspondant:email', correspondant.email ? correspondant.email : '');
    formData.append('correspondant:phone_number', correspondant.phone_number ? correspondant.phone_number : '');
    formData.append('correspondant:phone_number2', correspondant.phone_number2 ? correspondant.phone_number2 : '');
    formData.append('correspondant:role', correspondant.role);

    if (correspondant.id) { // EDIT
      return new Promise((resolve, reject) => {
        this.apiService.put('agencies/' + agencyId + '/correspondants/' + correspondant.id, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    } else { // CREATE
      return new Promise((resolve, reject) => {
        this.apiService.post('agencies/' + agencyId + '/correspondants', formData)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  }

  updateServices(agencyId, services: Array<{id: number, data: string | number}>) {

    const formData = new FormData();
    formData.append('services_ids', JSON.stringify(services));

    return new Promise((resolve, reject) => {
      this.apiService.put('agencies/' + agencyId + '/services', formData)
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateSkills(agencyId, skillsId: Array<number>) {

    const formData = new FormData();
    formData.append('skills_ids', skillsId.join(','));

    return new Promise((resolve, reject) => {
      this.apiService.put('agencies/' + agencyId + '/skills', formData)
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  updateOpeningTime(agencyId, type, openingTimes) {
    const formData = new FormData();
    formData.append('opening_time:data', JSON.stringify(openingTimes));

    return new Promise((resolve, reject) => {
      this.apiService.put('agencies/' + agencyId + '/' + type + '_opening_times', formData)
        .then((response) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  close(agencyId: string | number, destinationAgencyId: string | number) {
    return new Promise((resolve, reject) => {
      this.apiService.put('agencies/' + agencyId + '/close/' + destinationAgencyId, null)
        .then((response) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    })
  }
}
