import { Injectable } from '@angular/core';
import { Brand } from '../models/Brand';
import { ApiService } from './api.service';

@Injectable({providedIn: 'root'})
export class BrandsService {
  constructor(private apiService: ApiService) { }
  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('brands/all')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id) {
    return new Promise((resolve, reject) => {
      this.apiService.get('brands/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('brands/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(brand: Brand) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('brand:name', brand.name);
      formData.append('brand:title', (brand.title) ? brand.title : '');
      formData.append('brand:rank', (brand.rank) ? brand.rank : '');

      if (brand.logo !== null) {
        formData.append('brand:logo', brand.logo);
      }

      if (brand.id) { // UPDATE
        this.apiService.put('brands/' + brand.id, formData)
        .then((response: Brand) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      } else { // CREATE
        this.apiService.post('brands', formData)
        .then((response: Brand) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }
}
