import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { HighLightRulesService } from 'src/app/services/highLightRules.service';
import { PoliciesService } from 'src/app/services/policies.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-highlight-rules',
  templateUrl: './highlight-rules.component.html',
  styleUrls: ['./highlight-rules.component.scss']
})
export class HighlightRulesComponent implements OnInit {
  items: Array<any> = null;

  public loading: boolean = false;
  public loadingList: boolean = false;

  constructor(
    private alertService: AlertService,
    public enumsService: EnumsService,
    public highLightRulesService: HighLightRulesService,
    public authService: AuthService,
    public policiesService: PoliciesService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
    }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.loading = false;
        this.loadingList = true;
        this.highLightRulesService.getAll()
        .then((response: any) => {
          this.loadingList = false;
          this.items = response;
        })
        .catch((err) => {
          this.loadingList = false;
          this.alertService.error(err);
        })
      }
    });
  }

  getHighLightCategory(id) {
    const index = this.enumsService.enums.highlightCategories.findIndex(hc => hc.id === id)
    return (index !== -1) ? this.enumsService.enums.highlightCategories[index] : null
  }

  onDelete(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cette règle de mise en avant ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.highLightRulesService.delete(id)
        .then(() => {
          const index = this.items.findIndex(e => e.id === id);
          if (index !== -1) {
            this.items.splice(index, 1);
            this.items = [...this.items];
            this.alertService.success('Règle de mise en avant supprimée avec succès.');
          }
        })
        .catch((err) => {
          this.alertService.error(err);
        });
      }
    });
  }
}
