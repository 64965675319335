import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import modules from '../../../../assets/modules.json';

import { User } from 'src/app/models/User';
import { Intervention } from 'src/app/models/Intervention';
import { InterventionComment } from 'src/app/models/InterventionComment';
import { Site } from 'src/app/models/Site';
import { Address } from 'src/app/models/Address';

import { AuthService } from 'src/app/services/auth.service';
import { SitesService } from 'src/app/services/sites.service';
import { AddressesService } from 'src/app/services/addresses.service';
import { AlertService } from 'src/app/services/alert.service';
import { InterventionsService } from 'src/app/services/interventions.service';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {

  // URL FORMAT domain/module/service/id/consultation

  public modules;
  public modulesStringList;
  public user: User;
  currentModule: string = null;
  currentService: {name: string, slug: string, sub: any} = {
    name: null,
    slug: null,
    sub: null
  };

  currentItem: Address | Site | Intervention | InterventionComment = null;
  public fields: Array<any>;

  constructor(private authService: AuthService,
              public addressesService: AddressesService,
              public sitesSerive: SitesService,
              public interventionsService: InterventionsService,
              public alertService: AlertService,
              private route: ActivatedRoute,
              private router: Router) {

    this.currentModule =  this.router.url.split('/')[1];
    this.modules = modules;

    this.currentService.slug = this.router.url.split('/')[2];
    switch (this.currentService.slug) {
      case 'sites/addresses':
        this.currentService.name =  'sites';
        this.fields = [
          { name: 'ID', slug: 'id' },

          { name: 'Date création', slug: 'created_at', template: true },
          { name: 'Date mise à jour', slug: 'update_at', template: true }
        ];
        // Find Address by ID
        this.addressesService.getById(this.route.snapshot.params.id)
          .then((response: Address) => {
            this.currentItem = response;
          })
          .catch((err) => {
            this.alertService.error(err);
          });
        break;
      case 'sites':
        this.currentService.name =  'sites';
        if (this.router.url.split('/')[3] && this.router.url.split('/')[3] === 'addresses' || this.router.url.split('/')[3] === 'billing-addresses') {
          this.fields = [
            { name: 'ID', slug: 'id' },
            { name: 'Numéro de rue', slug: 'street_number' },
            { name: 'Nom rue', slug: 'street_name' },
            { name: 'Code postal', slug: 'zipcode' },
            { name: 'Ville', slug: 'city' },
            { name: 'Date création', slug: 'created_at', template: true },
            { name: 'Date mise à jour', slug: 'update_at', template: true }
          ];
          // Find Site by ID
          this.addressesService.getById(this.route.snapshot.params.id)
            .then((response: Site) => {
              this.currentItem = response;
            })
            .catch((err) => {
              this.alertService.error(err);
            });
          } else {
          this.fields = [
            { name: 'ID', slug: 'id' },
            { name: 'Raison sociale', slug: 'social_reason' },
            { name: 'Code', slug: 'code' },
            { name: 'Agence', slug: 'agency', template: true },
            { name: 'Contact', slug: 'contact' },
            { name: 'Numéro de téléphone', slug: 'phone' },
            { name: 'Adresse intervention', slug: 'site_intervention_address', template: true },
            { name: 'Adresse de facturation', slug: 'site_billing_address', template: true },
            { name: 'Date création', slug: 'created_at', template: true },
            { name: 'Date mise à jour', slug: 'update_at', template: true }
          ];
          // Find Site by ID
          this.sitesSerive.getById(this.route.snapshot.params.id)
            .then((response: Site) => {
              this.currentItem = response;
            })
            .catch((err) => {
              this.alertService.error(err);
            });
          }
        break;
      case 'interventions':
        this.currentService.name = 'interventions';
        const n: any = this.router.url.split('/')[3];
        if (n === 'comments') { // n is a number ?
          // COMMENT
          this.currentService.sub = {
            name: 'Commentaires',
            slug: 'comments'
          };

          this.fields = [
            { name: 'ID', slug: 'id', template: true },
            { name: 'Intervention', slug: 'intervention', template: true },
            { name: 'Content', slug: 'content', template: true },
            { name: 'Date création', slug: 'created_at', template: true },
            { name: 'Date mise à jour', slug: 'updated_at', template: true }
          ];

          this.interventionsService.getCommentById(this.route.snapshot.params.id)
            .then((response: InterventionComment) => {
              this.currentItem = response;
            })
            .catch((err) => {
              this.alertService.error(err);
            });

        } else {
          // INTERVENTION
          this.fields = [
            { name: 'ID', slug: 'id' },
            { name: 'Nom du site', slug: 'site_name', template: true },
            { name: 'Référence BA', slug: 'ba_reference' },
            { name: 'Valorisation', slug: 'valorization' },
            { name: 'Marque du charriot', slug: 'cart_band' },
            { name: 'Type du chariot', slug: 'cart_type' },
            { name: 'Type de montage (avant)', slug: 'front_mounting_type', template: true },
            { name: 'Type de montage (arrière)', slug: 'back_mounting_type', template: true },
            { name: 'Numéro de parc', slug: 'pack_number' },
            { name: 'Numéro de série', slug: 'serial_number' },
            { name: 'Visite Générale Périodique (VGP)', slug: 'vgp' },
            { name: "Mise à l'Arrêt Conseilée (MAC)", slug: 'mac' },
            { name: 'Dimensions (avant)', slug: 'front_dimensions' },
            { name: 'Dimensions (arrière)', slug: 'back_dimensions' },
            { name: 'Type pneumatique', slug: 'tire_type', template: true },
            { name: 'Marque(s) demandée(s)', slug: 'requested_brand', template: true },
            { name: 'Pneu avant gauche interne', slug: 'internal_front_left_tire' },
            { name: 'Pneu avant droit interne', slug: 'internal_front_right_tire' },
            { name: 'Pneu avant gauche externe', slug: 'external_front_left_tire' },
            { name: 'Pneu avant droit externe', slug: 'external_front_right_tire' },
            { name: 'Pneu arrière gauche', slug: 'back_left_tire' },
            { name: 'Pneu arrière droit', slug: 'back_right_tire' },
            { name: 'Prise en compte le', slug: 'processed_at', template: true },
            { name: 'Validation de la commande et BA déposé le', slug: 'order_validated_at', template: true },
            { name: 'Validation de la commande et BA déposé par', slug: 'order_validated_by_id'},
            { name: "Date d'intervention saisie", slug: 'intervention_at', template: true },
            { name: "Date de clôture de l'intervention", slug: 'intervention_closed_at', template: true },
            { name: 'Date création', slug: 'created_at', template: true },
            { name: 'Date mise à jour', slug: 'updated_at', template: true },
            { name: 'Commentaires', slug: 'comments', template: true }
          ];
          this.interventionsService.getById(this.route.snapshot.params.id)
            .then((response: Intervention) => {
              this.currentItem = response;
            })
            .catch((err) => {
              this.alertService.error(err);
            });
        }
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    if (this.authService.currentUserValue) {
      this.modules = modules;
      this.modulesStringList = Object.keys(modules);
      this.user = this.authService.currentUserValue;
    }
  }

}
