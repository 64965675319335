<app-front-layout>
  <div class="content-center" content>
    <div class="row">
      <div class="col-md-12">
        <h1 class="title">404</h1>
        <h2>Page introuvable !</h2>
        <h4>
          Cette page n'existe pas ou n'existe plus.<br>
          Nous vous prions de nous excuser pour la gêne occasionnée.
        </h4>
      </div>
    </div>
  </div>
</app-front-layout>
