import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PageAttachment } from '../models/PageAttachment';

@Injectable({ providedIn: 'root' })
export class PageAttachmentsService {
  constructor(private apiService: ApiService) {}

  getById(pageAttachmentId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('page-attachments/' + pageAttachmentId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('page-attachments')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  delete(pageAttachmentId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('page-attachments/' + pageAttachmentId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
