<app-topbar moduleSlug="myprofilplus">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a>Tableau de bord</a></li>
          <li class="breadcrumb-item"><a>MyProfil+</a></li>
          <li class="breadcrumb-item active"><a href="javascript:;">Fusionner des cartes</a></li>
        </ol>
      </nav>
    </ng-container>
</app-topbar>

<div class="row">
  <div class="col-md-6">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">card_giftcard</i>
        </div>
        <h4 class="card-title">Fusionner des cartes</h4>
      </div>
      <div class="card-body" *ngIf="editFormGroup" >
        <div class="alert alert-danger">Attention, cette opération est irréversible.</div>
        <form class="row" [formGroup]="editFormGroup">
          <div class="col-md-12">
            <p class="form-group is-required">
              <label class="bmd-label-floating">Fusionner les achats/points de la carte</label>
              <input type="text" class="form-control" id="card_id" formControlName="card_id" placeholder="XXXXXXXXXXXXXXXXXX">
            </p>
          </div>
          <div class="col-md-12">
            <p class="form-group is-required">
              <label class="bmd-label-floating">dans la carte</label>
              <input type="text" class="form-control" id="card_id2" formControlName="card_id2" placeholder="XXXXXXXXXXXXXXXXXX">
            </p>
            <strong *ngIf="f.card_id.value && f.card_id2.value && editFormGroup.invalid">
              Fusion impossible : il s'agit de la même carte !
            </strong>
          </div>
        </form>
        <div class="col-12 mt-3">
          <input
            type="submit"
            [disabled]="editFormGroup.invalid"
            class="ppp-button ppp-button-blue btn btn-primary btn-round"
            *ngIf="!loadingService.loading; else templateSubmit"
            value="Fusionner" (click)="onConfirm()">
          <ng-template #templateSubmit>
            <button
              disabled="true"
              class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
              ...
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
