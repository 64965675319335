<app-topbar moduleSlug="profilplus-direct">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/profilplus-direct']">Profil Plus Direct</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/profilplus-direct/sites']">Sites</a></li>
          <li class="breadcrumb-item active" *ngIf="type === 'addresses'">
            <a [routerLink]="['/profilplus-direct/sites']">Adresses</a>
          </li>
          <li class="breadcrumb-item active" *ngIf="type === 'billing-addresses'">
            <a [routerLink]="['/profilplus-direct/sites']">Adresses de facturation</a>
          </li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">schedule</i>
        </div>
        <h4 class="card-title">Addresses
        </h4>
      </div>
      <div class="card-body">

        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [externalPaging]="true"
            [externalSorting]="true"
            [loadingIndicator]="loadingList"
            [reorderable]="false"
            (page)="setPage($event)"
            (sort)="onSort($event)"
          >
            <ngx-datatable-column name="ID" prop="id" [width]="75" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Numéro rue" prop="street_number" [width]="200" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Nom rue" prop="street_name" [width]="250" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Code postal" prop="zipcode" [width]="200" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Ville" prop="city" [width]="200" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a [routerLink]="['/profilplus-direct/sites/addresses', row.id, 'consultation']" type="button" class="btn btn-success" title="Consulter">
                  <i class="material-icons">visibility</i>
                </a>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>

      </div>
    </div>
  </div>
</div>
