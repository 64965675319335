import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ActivityTrackingRoutingModule } from './activity-tracking-routing';
import { ActivityTrackingComponent } from './activity-tracking.component';
import { ActivityFormsComponent } from './activity-forms/activity-forms.component';
import { ActivityDashboardComponent } from './activity-dashboard/activity-dashboard.component';
import { EditComponent } from './activity-forms/edit/edit.component';



@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    ActivityTrackingRoutingModule,

    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot()
  ],
  declarations: [
    ActivityTrackingComponent,
    ActivityFormsComponent,
    ActivityDashboardComponent,
    EditComponent
  ]
})
export class ActivityTrackingModule { }
