import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { WorkersService } from 'src/app/services/workers.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public loading: boolean = false;
  public editFormGroup: UntypedFormGroup;
  public submitted: Boolean = false;

  constructor(
    private router: Router,
    private workersService: WorkersService,
    private enumsService: EnumsService,
    public loadingService: LoadingService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.enumsService.observable.subscribe({
      complete: () => {
        this.editFormGroup = this.formBuilder.group({
          radius: ['', Validators.required],
          number_of_results: ['', Validators.required],
          mapping_file_1: ['', Validators.required],
          mapping_file_2: ['', Validators.required]
        });
      }
    });
  }

  get f() {
    return this.editFormGroup.controls;
  }

  onFile1Selected(files: Array<File>) {
    this.editFormGroup.patchValue({mapping_file_1: files[0]});
  }

  onFile2Selected(files: Array<File>) {
    this.editFormGroup.patchValue({mapping_file_2: files[0]});
  }

  onSubmit() {
    this.submitted = true;

    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    this.workersService.startNewWorker('files_mapping', {
      file_1:this.editFormGroup.value.mapping_file_1,
      file_2:this.editFormGroup.value.mapping_file_2,
      radius: this.editFormGroup.value.radius,
      number_of_results: this.editFormGroup.value.number_of_results}).then(() => {
      this.alertService.success('Mapping de fichiers en cours de création, veuillez patienter. Vous pouvez également retrouver les résultats dans l\'onglet Notifications du menu de gauche.');
      this.router.navigate(['/website/file-mapping']);

    })
    .catch((err) => {
      window.scrollTo(0, 0);
      this.alertService.error(err);
      return;
    });

  }
}
