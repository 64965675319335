import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { User } from '../models/User';
import { AuthService } from './auth.service';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';
import modules from '../../assets/modules.json';

@Injectable({ providedIn: 'root' })
export class UserService {
  private modules: any = modules;
  private modulesList: any = Object.keys(modules);
  constructor(private authService: AuthService, private apiService: ApiService) { }

  getAllWithPagination(page: string | number, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('users?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
        + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  users(filters: any = null): Promise<User[]>  {
    return new Promise((resolve, reject) => {
      // filters to filters url
      const users: User[] = [];
      this.apiService.get('users/all' + (filters ? '?' + parseFiltersForUrl(filters) : ''))
        .then((response: any[]) => {
          response.forEach(u => {
            users.push(u);
          });
          resolve(users);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id: number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('users/' + id.toString())
      .then((response: User) => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
    });
  }

  edit(user: User) {
    const formData = new FormData();
    formData.append('user:first_name', user.first_name ? user.first_name : '');
    formData.append('user:last_name', user.last_name ? user.last_name : '');
    formData.append('user:email', user.email ? user.email : '');
    formData.append('user:is_admin', user.is_admin.toString());
    formData.append('user:receives_ac_stats', user.receives_ac_stats.toString());

    if (user.password && user.password !== '' && user.password_confirmation && user.password_confirmation !== '') {
      formData.append('user:password', user.password);
      formData.append('user:password_confirmation', user.password_confirmation);
    }

    // Add all rules for all model if is_admin
    if (user.is_admin) {
      user.policies = {}; // initialise policies properties
      this.modulesList.forEach(m => {
        if (this.modules[m] !== undefined) {
          for (const model of this.modules[m].models) {
            user.policies[model] = {};
            const rules = ['read', 'create', 'update', 'delete'];
            rules.forEach(rule => {
              user.policies[model][rule] = true;
            });
          }
        }
      });
    }


    return new Promise((resolve, reject) => {
      if (user.id) { // EDIT
        this.apiService.put('users/' + user.id, formData)
        .then((response: any) => {
          // CHANGE IF USER IS CURRENT AUTH USER
          if (user.id  == this.authService.currentUserValue.id) {

            const userForLocal: User = {
              ...this.authService.currentUserValue,
              first_name: user.first_name,
              last_name: user.last_name,
              email: user.email,
              is_admin: user.is_admin
            };

            if (user.is_admin !== this.authService.currentUserValue.is_admin) { // reset adherents and user
              userForLocal.adherents = [];
              userForLocal.agencies = [];
              userForLocal.part_of_adherent_ids = [];
            }
            this.authService.updateAuth(userForLocal);
          }

          if (user.is_admin) {
            this.savePolicies(user.id, JSON.stringify(user.policies))
              .then(() => {
                resolve(response);
              })
              .catch(err => {
                reject(err);
              });
          } else {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
      } else { // NEW
        this.apiService.post('sign_ups', formData)
        .then((response: any) => {
          if (user.is_admin) {
            this.savePolicies(response.id, JSON.stringify(user.policies))
              .then(() => {
                resolve(response);
              })
              .catch(err => {
                reject(err);
              });
          } else {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
      }

    });
  }

  savePolicies(userId: number | string, policies: string) {
    return new Promise((resolve, reject) => {
      this.apiService.put('users/' + userId + '/roles', policies)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateAdherents(userId: number | string, adherents: Array<any>) {
    console.log(adherents);
    const temp = adherents.map(x => x.id).join(',');
    const formData = new FormData();
    formData.append('adherent_ids', temp ? temp : '');

    return new Promise((resolve, reject) => {
      this.apiService.put('users/' + userId + '/adherents', formData)
        .then((response) => {
          // CHANGE IF USER IS CURRENT AUTH USER
          if (userId  === this.authService.currentUserValue.id) {

            // UPDATE PART_OF_ADHERENTS
            let adherents_ids = adherents.map(x => x.id);
            let part_of_adherent_ids = new Set([...adherents_ids, ...this.authService.currentUserValue.adherents.map(x => x.adherent_id)]);

            // SAVE
            this.authService.updateAuth({
              ...this.authService.currentUserValue,
              adherents,
              part_of_adherent_ids: Array.from(part_of_adherent_ids)
            });
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateAgencies(userId: number | string, agencies: Array<any>) {
    const temp = agencies.map(x => x.id).join(',');
    const formData = new FormData();
    formData.append('agency_ids', temp ? temp : '');

    return new Promise((resolve, reject) => {
      this.apiService.put('users/' + userId + '/agencies', formData)
        .then((response) => {
          // CHANGE IF USER IS CURRENT AUTH USER
          if (userId  === this.authService.currentUserValue.id) {
            // UPDATE PART_OF_ADHERENTS

            let adherent_ids = agencies.map(x => x.adherent_id);
            let part_of_adherent_ids = new Set([...adherent_ids, ...this.authService.currentUserValue.adherents.map(x => x.id)]);

            // SAVE
            this.authService.updateAuth({
              ...this.authService.currentUserValue,
              agencies,
              part_of_adherent_ids: Array.from(part_of_adherent_ids)
            });
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  delete(id: number) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('users/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
