<app-topbar moduleSlug="profilplus-direct">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/profilplus-direct']">Profil Plus Direct</a></li>
          <li class="breadcrumb-item active"><a href="javascript:;">Commentaires</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">comment</i>
        </div>
        <h4 class="card-title">Commentaires <span>({{datatable.count}} éléments)</span>
        </h4>
      </div>
      <div class="card-body">
        <app-filter-bar [filters]="filters"
          (changed)="setPage({offset: 0}, null, $event)">
          <p *ngIf="loadingList">chargement...</p>
        </app-filter-bar>
        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [externalPaging]="true"
            [externalSorting]="true"
            [loadingIndicator]="loadingList"
            [reorderable]="false"
            (page)="setPage($event)"
            (sort)="onSort($event)"
            >
              <ngx-datatable-column name="ID" prop="id" [width]="75" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Intervention" prop="intervention" [width]="200" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a class="ppp-link" [routerLink]="['/profilplus-direct/interventions', row.intervention.id, 'consultation']" routerLinkActive="router-link-active">
                    {{row.intervention.ba_reference}}
                  </a>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Contenu" prop="content" [width]="450" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Date création" prop="created_at" [width]="220" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value | date:'EEEE dd MMMM y' }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Actions" [width]="300" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a [routerLink]="[ '/profilplus-direct/interventions/comments', row.id, 'consultation' ]" class="btn btn-success" title="Modifier les informations" (click)="showData(row)">
                    <i class="material-icons">visibility</i>
                  </a>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>

        </div>
      </div>
    </div>
  </div>
</div>
