import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { TiresService } from 'src/app/services/tires.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tires',
  templateUrl: './tires.component.html',
  styleUrls: ['./tires.component.scss']
})
export class TiresComponent implements OnInit {
  type: string;
  // tslint:disable-next-line:variable-name
  type_label: string;
  items: Array<any> = null;
  type_param: string;

  public loading: boolean = false;
  public loadingList: boolean = false;

  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [];
  public optionsFilteredByType: any;


  constructor(
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private activeRoute: ActivatedRoute,
    private alertService: AlertService,
    public enumsService: EnumsService,
    public tiresService: TiresService,
    public authService: AuthService,
    public policiesService: PoliciesService) {
      this.activeRoute.params.subscribe(routeParams => {
        if (routeParams.type) {
          this.type = this.route.snapshot.params.type;
          switch (this.route.snapshot.params.type) {
            case 'vl':
              this.type_param = "VlTire";
              this.type_label = 'Pneus VL';
              break;
            case 'pl':
              this.type_param = "PlTire";
              this.type_label = 'Pneus PL';
              break;
            case 'ag':
              this.type_param = "AgTire";
              this.type_label = 'Pneus AG';
              break;
            case 'ag_used':
              this.type_param = "AgUsedTire";
              this.type_label = "Pneus d'occasion AG";
              break;
            default:
              break;
          }
        }
      });

      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          this.loading = false;
          // ON ROUTE CHANGING
          this.activeRoute.params.subscribe(() => {
            // SET FILTERS
            this.optionsFilteredByType = this.enumsService.enums.tire_data[this.type]

            this.filters = [
              {
                name: 'EAN',
                slug: 'ean',
                type: 'text',
                class: 'col-2',
                value: null
              }
            ]

            this.filters.push({
              name: 'Marque',
              slug: 'brand',
              selected_emit_property: 'name',
              type: 'autocomplete',
              class: 'col-2',
              options: this.enumsService.enums.brands
            });
            this.filters.push({
              name: 'Profil de pneu',
              slug: 'tire_profile',
              selected_emit_property: 'name',
              type: 'autocomplete',
              class: 'col-2',
              options: this.enumsService.enums.tire_models
            });
            if (this.type === 'ag_used') {
              this.filters.push({
                name: 'Largeur',
                slug: 'width',
                type: 'select',
                class: 'col-2',
                options: this.optionsFilteredByType.width
              })
              this.filters.push({
                name: 'Hauteur',
                slug: 'height',
                type: 'select',
                class: 'col-2',
                options: this.optionsFilteredByType.height
              })
              this.filters.push({
                name: 'Diamètre',
                slug: 'diameter',
                type: 'select',
                class: 'col-2',
                options: this.optionsFilteredByType.diameter
              })

              this.filters.push({
                name: 'Indice de charge',
                slug: 'load_index',
                type: 'select',
                class: 'col-2',
                options: this.optionsFilteredByType.load_index
              })
            } else {
              this.filters.push({
                name: 'Largeur',
                slug: 'width',
                type: 'text',
                class: 'col-2',
                value: null
              })

              this.filters.push({
                name: 'Hauteur',
                slug: 'height',
                type: 'text',
                class: 'col-2',
                value: null
              })

              this.filters.push({
                name: 'Diamètre',
                slug: 'diameter',
                type: 'text',
                class: 'col-2',
                value: null
              })

              this.filters.push({
                name: 'Indice de charge',
                slug: 'load_index',
                type: 'text',
                class: 'col-2',
                value: null
              })
            }

            this.filters.push({
              name: 'Indice de vitesse',
              slug: 'speed_index',
              selected_emit_property: 'value',
              type: 'autocomplete',
              class: 'col-2',
              options: this.optionsFilteredByType.speed_index
            });
          });
        }
      });

      this.policiesService.currentUser = this.authService.currentUserValue;
    }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // ON ROUTE CHANGING
    this.activeRoute.params.subscribe(() => {
      this.setPage({offset: 0});
    })
    this.cd.detectChanges();
  }


  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;

    this.tiresService.getAllTiresWithPagination(this.pageOffsetSelected + 1, this.type_param, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 20;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

  onDelete(id: string | number) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce pneu ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText:  'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.tiresService.delete(id)
        .then(() => {
          const index = this.items.findIndex(e => e.id === id);
          if (index !== -1) {
            this.items.splice(index, 1);
            this.items = [...this.items];
            this.alertService.success('Pneu supprimé avec succès.');
          }
        })
        .catch((err) => {
          this.alertService.error(err);
        });
      }
    });
  }
}
