<app-topbar moduleSlug="users-management">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <ng-container *ngIf="this.currentUser.policies['users-management'] && policyListRules && policyListRules.read === true">
          <li class="breadcrumb-item"><a [routerLink]="['/users-management']">Liste des utilisateurs</a></li>
        </ng-container>
        <li class="breadcrumb-item active" *ngIf="user"><a href="javascript:;">{{user.first_name}} {{user.last_name}}</a></li>
        <li class="breadcrumb-item active" *ngIf="!user"><a href="javascript:;">Créer un utilisateur</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>
<div class="row" *ngIf="editFormGroup">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">account_box</i>
        </div>
        <h4 class="card-title">{{ (edit) ? 'Édition' : 'Création'}} d'un utilisateur</h4>
      </div>
      <div class="card-body">
        <!-- BUTTONS -->
        <div *ngIf="edit && user && currentUser && currentUser.is_admin">
          <a [routerLink]="['/users-management/edit', user.id ,'rules']" type="button" class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
            <i class="material-icons">https</i> Droits
          </a>
          <a *ngIf="(user.id != currentUser.id || user.id == currentUser.id) && !user.is_admin"
            [routerLink]="['/users-management/edit', user.id, 'adherents-agencies']" type="button" class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
            <i class="material-icons">domain</i> Adhérents / Agences
          </a>
        </div>
        <form class="form" [formGroup]="editFormGroup" *ngIf="edit && user || !edit">

          <div class="input-group form-control-lg">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="material-icons">face</i>
              </span>
            </div>
            <div class="form-group col-lg-6" [ngClass]="{ 'has-danger': submitted && f.first_name.errors }">
              <label for="first_name"  class="bmd-label-floating">Prénom *</label>
              <input name="first_name" type="text" class="form-control" formControlName="first_name" required="true">
            </div>
          </div>

          <div class="input-group form-control-lg">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="material-icons">face</i>
              </span>
            </div>
            <div class="form-group col-lg-6" [ngClass]="{ 'has-danger': submitted && f.last_name.errors }">
              <label for="last_name" class="bmd-label-floating">Nom *</label>
              <input name="last_name" type="text" class="form-control" formControlName="last_name" required="true">
            </div>
          </div>

          <div class="input-group form-control-lg">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="material-icons">mail</i>
              </span>
            </div>
            <div class="form-group col-lg-6" [ngClass]="{ 'has-danger': submitted && f.email.errors }">
              <label for="email"  class="bmd-label-floating">Email *</label>
              <input name="email" type="email" class="form-control" formControlName="email" required="true">
              <label class="error" for="email" *ngIf="f.email.errors && f.email.errors.email">Veuillez entrer un email valide.</label>
            </div>
          </div>

          <div class="category form-category" *ngIf="edit">Ne pas saisir si le mot de passe n'est pas changé.</div>
          <div class="input-group form-control-lg">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="material-icons">lock_outline</i>
              </span>
            </div>
            <div class="form-group col-lg-6" [ngClass]="{ 'has-danger': submitted && f.password.errors }">
              <label for="password"  class="bmd-label-floating">Mot de passe {{(edit) ? '': '*'}}</label>
              <input name="password" type="password" class="form-control" formControlName="password" id="password">
              <label *ngIf="f.password.errors && f.password.errors.minlength">
                Le mot de passe doit dépasser les 6 caractères.
              </label>
            </div>
          </div>

          <div class="input-group form-control-lg">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="material-icons">lock_outline</i>
              </span>
            </div>
            <div class="form-group col-lg-6"  [ngClass]="{ 'has-danger': submitted && f.password_confirmation.errors }">
              <label for="password_confirmation"  class="bmd-label-floating">Confirmation {{(edit) ? '': '*'}}</label>
              <input name="password_confirmation" type="password" class="form-control" formControlName="password_confirmation">
              <label *ngIf="f.password_confirmation.errors && f.password_confirmation.errors.mustMatch">
                Le mot de passe de confirmation ne correspond pas.
              </label>
            </div>
          </div>

          <div *ngIf="currentUser && currentUser.is_admin" class="input-group form-control-lg">
            <div class="form-group col-lg-6">
              <div class="form-check">
                <label for="is_admin" class="form-check-label">
                  <input name="is_admin" id="is_admin" formControlName="is_admin" class="form-check-input form-control" type="checkbox">Compte "super administrateur"
                  <span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div *ngIf="currentUser && currentUser.is_admin; else simpleUser" class="input-group form-control-lg">
            <div class="form-group col-lg-6">
              <div class="form-check">
                <label for="receives_ac_stats" class="form-check-label">
                  <input name="receives_ac_stats" id="receives_ac_stats" formControlName="receives_ac_stats" class="form-check-input form-control" type="checkbox">Peut recevoir les statistiques des actions commerciales ?
                  <span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <ng-template #simpleUser>
            <div *ngIf="currentUser && edit" class="input-group form-control-lg">
              <div class="form-group col-lg-6">
                <div class="form-check">
                  Peut recevoir les statistiques des actions commerciales : {{ user.receives_ac_stats ? "Oui" : "Non"  }}
                </div>
              </div>
            </div>
          </ng-template>

          <div class="category form-category">* Champs obligatoires</div>

          <ng-container *ngIf="this.currentUser.policies['users-management'] && policyListRules && policyListRules.read === true; else dashboard">
            <a *ngIf="edit"
                [routerLink]="['/users-management/list']"
                type="button"
                class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
              Retour
            </a>
          </ng-container>

          <ng-template #dashboard>
            <a *ngIf="edit"
                [routerLink]="['/']"
                type="button"
                class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
              Tableau de bord
            </a>
          </ng-template>

          <a *ngIf="!loadingService.loading; else templateSubmit"
          class="ppp-button ppp-button-black btn btn-primary btn-round"
          (click)="onSubmit()">{{ (edit) ? 'Mettre à jour' : 'Créer'}}</a>
          <ng-template #templateSubmit>
            <button
              disabled="true"
              class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
              ...
            </button>
          </ng-template>

        </form>
      </div>
    </div>
  </div>
</div>
