<app-topbar moduleSlug="profilplus-direct">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/profilplus-direct']">Profil Plus Direct</a></li>
          <li class="breadcrumb-item active"><a href="javascript:;">Sites</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>
<div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">place</i>
          </div>
          <h4 class="card-title">Sites <span *ngIf="items">({{datatable.count}} éléments)</span>
          </h4>
        </div>
        <div class="card-body">
          <app-filter-bar [filters]="filters"
            (changed)="setPage({offset: 0}, null, $event)">
            <p *ngIf="loadingList">chargement...</p>
          </app-filter-bar>
          <div class="table-responsive">
            <ngx-datatable
              #datatable
              class="material"
              [rows]="items"
              [columnMode]="'standard'"
              [rowHeight]="'auto'"
              [headerHeight]="50"
              [footerHeight]="50"
              [externalPaging]="true"
              [externalSorting]="true"
              [loadingIndicator]="loadingList"
              [reorderable]="false"
              (page)="setPage($event)"
              (sort)="onSort($event)"
            >
              <ngx-datatable-column name="ID" prop="id" [width]="75" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Raison sociale" prop="social_reason" [width]="250" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Code" prop="code" [width]="100" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Agence" prop="agency" [width]="300" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value.name }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Contact" prop="contact" [width]="160" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{row.contact}}<br>
                  {{row.phone}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Actions" [width]="300" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a [routerLink]="[ '/profilplus-direct/sites', row.id, 'consultation' ]" class="btn btn-success" title="Consulter" (click)="showData(row)">
                    <i class="material-icons">visibility</i>
                  </a>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
</div>
