import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { BrandQuestionsService } from 'src/app/services/brand-questions.service';
import { EnumsService } from 'src/app/services/enums.service';
import { PoliciesService } from 'src/app/services/policies.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  items: any[] = null;

  public loading: boolean = false;
  public loadingList: boolean = false;
  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [
  ];

  constructor(
    private cd: ChangeDetectorRef,
    private enumsService: EnumsService,
    public authService: AuthService,
    private alertService: AlertService,
    public brandQuestionsService: BrandQuestionsService,
    public policiesService: PoliciesService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
    }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.loading = false;
      }
    });
  }


  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;

    this.brandQuestionsService.getAllWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }


  onDelete(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cette question ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.brandQuestionsService.delete(id)
        .then(() => {
          const index = this.items.findIndex(e => e.id === id);
          if (index !== -1) {
            this.items.splice(index, 1);
            this.items = [...this.items];
            this.alertService.success('Question supprimée avec succès.');
          }
        })
        .catch((err) => {
          this.alertService.error(err);
        });
      }
    });
  }
}
