<div class="diskSpace" *ngIf="diskSpace">
  <div class="diskSpace-background"></div>
  <div class="diskSpace-modal">
    <h2>Espace disque insuffisant</h2>
    <p>
      Vous ne disposez pas assez d'espace disque sur votre ordinateur pour utiliser le Portail.<br>
      Veuillez libérer de la place sur votre disque dur et rafraîchir cette page.
    </p>
  </div>
</div>
