<ng-container *ngIf="!loading; else templateLoading">

  <app-topbar moduleSlug="profilplus-agences">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences']">Profil Plus Agences</a></li>
          <li class="breadcrumb-item">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies'] : ['/profilplus-agences/closed-agencies']">
              Liste des agences {{ router.url.split('/').includes('open-agencies') ? 'ouvertes' : 'fermées' }}
            </a>
          </li>
          <li class="breadcrumb-item" *ngIf="agency">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit', agency.id] : ['/profilplus-agences/closed-agencies/edit', agency.id]">
              Agence {{agency.name}}
            </a>
          </li>
          <li class="breadcrumb-item active"><a href="javascript:;">Éditer les horaires d'ouverture magasin</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">store</i>
          </div>
          <h4 class="card-title" *ngIf="agency">Édition des horaires d'ouverture magasin : <strong class="description">{{agency.name}}</strong></h4>
        </div>
        <div class="card-body">
          <!-- BUTTONS -->
          <div>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/', agency.id] : ['/profilplus-agences/closed-agencies/edit/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">store</i> Fiche agence
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/skills/', agency.id] : ['/profilplus-agences/closed-agencies/edit/skills/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">build</i> Métiers
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/services/', agency.id] : ['/profilplus-agences/closed-agencies/edit/services/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">playlist_add_check</i> Services
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/close-dates/', agency.id] : ['/profilplus-agences/closed-agencies/edit/close-dates/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">report</i> Dates fermeture
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/opening-times/store/', agency.id] : ['/profilplus-agences/closed-agencies/edit/opening-times/store/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">store</i> Horaires magasin
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/correspondants/edit/', agency.id] : ['/profilplus-agences/closed-agencies/correspondants/edit/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">people</i> Correspondants
            </a>
            <a *ngIf="policiesService.currentUser.is_admin && router.url.split('/').includes('open-agencies')"
                [routerLink]="['/profilplus-agences/open-agencies/edit/settings', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">settings</i> Paramètres avancés
            </a>
          </div>

          <p>
            <strong class="description">Attention</strong> de respecter les tranches horaires du début à la fin de la matinée mais aussi du début à la fin de l'après-midi :<br>
            Les horaires d'ouverture <strong class="description">ne peuvent pas être supérieurs</strong> aux horaires de fermeture.
          </p>
          <div class="table-responsive">
            <table class="table" *ngIf="openingTimes">
              <thead>
                <tr>
                  <td>Jours</td>
                  <td>Horaires ouverture (matin)</td>
                  <td>Horaires fermeture (matin)</td>
                  <td>Horaires ouverture (après-midi)</td>
                  <td>Horaires fermeture (après-midi)</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let day of ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']; let index = index">
                  <td style="color:#004093;font-weight:500;">
                    {{day}}
                  </td>
                  <td>
                    <p class="form-group">
                      <input type="time"
                      (change)="update(index+1, 'morning', 0, $event)"
                      [value]="getOpeningTimes(index+1) && getOpeningTimes(index+1).morning[0] ? getOpeningTimes(index+1).morning[0] : null"
                      name="morning-opening" id="morning-opening" class="form-control">
                    </p>
                  </td>
                  <td>
                    <p class="form-group">
                      <input type="time"
                      (change)="update(index+1, 'morning', 1, $event)"
                      [value]="getOpeningTimes(index+1) && getOpeningTimes(index+1).morning[1] ? getOpeningTimes(index+1).morning[1] : null"
                      name="morning-closing" id="morning-closing" class="form-control">
                    </p>
                  </td>
                  <td>
                    <p class="form-group">
                      <input type="time"
                      (change)="update(index+1, 'afternoon', 0, $event)"
                      [value]="getOpeningTimes(index+1) && getOpeningTimes(index+1).afternoon[0] ? getOpeningTimes(index+1).afternoon[0] : null"
                      name="morning-opening" id="afternoon-opening" class="form-control">
                    </p>
                  </td>
                  <td>
                    <p class="form-group">
                      <input type="time"
                      (change)="update(index+1, 'afternoon', 1, $event)"
                      [value]="getOpeningTimes(index+1) && getOpeningTimes(index+1).afternoon[1] ? getOpeningTimes(index+1).afternoon[1] : null"
                      name="afternoon-closing" id="afternoon-closing" class="form-control">
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <button *ngIf="!loadingService.loading; else templateSubmit"
            class="ppp-button ppp-button-black btn btn-primary btn-round"
            (click)="onSubmit()">Mettre à jour
          </button>
          <ng-template #templateSubmit>
            <button
              disabled="true"
              class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
              ...
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

</ng-container>

<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
