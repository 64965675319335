import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AgenciesService } from 'src/app/services/agencies.service';
import { EnumsService } from 'src/app/services/enums.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';

import { Agency } from 'src/app/models/Agency';
import { User } from 'src/app/models/User';
import { LoadingService } from 'src/app/services/loading.service';

function emailConditionallyRequiredValidator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }
  if (formControl.parent.get('lastname_manager').value) {
    return Validators.required(formControl);
  }
  return null;
}

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  public erps = [
    {name: ''},
    {name: 'WINPRO'},
    {name: 'WCONTACT'},
    {name: 'IBSM'},
    {name: 'ERP'},
    {name: 'CEGID'},
    {name: 'PSI (EDP)'}
  ];

  public preselectErp: number;
  public user: User;
  public companies: Array<any>;
  public adherents: Array<any>;
  public preselectCompanies: number;
  public preselectAdherents: number;
  public editFormGroup: UntypedFormGroup;
  public loading: Boolean = false;
  public submitted: Boolean = false;
  public editMode: any = {
    active: false,
    data: null
  };

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private enumsService: EnumsService,
    private alertService: AlertService,
    private agenciesService: AgenciesService,
    private authService: AuthService,
    public loadingService: LoadingService) {

    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.companies = this.enumsService.enums.companies;
        this.adherents = this.enumsService.enums.adherents;

        if (this.route.snapshot.params.id) { // EDIT
          this.editMode.active = true;
          this.agenciesService.getById(this.route.snapshot.params.id)
            .then((response: Agency) => {
              this.editMode.data = response;
              this.editFormGroup = this.formBuilder.group(this.getNormalForm(response), {
                validators: [emailConditionallyRequiredValidator]
              });
              this.preselectErp = this.erps.findIndex(e => e.name === response.erp);
              if (response.adherent) {
                this.preselectAdherents = this.adherents.findIndex(a => a.id === response.adherent.id);
              }
              if (response.company) {
                this.preselectCompanies = this.filteredCompanies.findIndex(a => a.id === response.company.id);
              }
              this.loading = false;
            })
            .catch((err) => {
              this.alertService.error(err);
              this.loading = false;
            });
        }
        else { // CREATE
          this.editFormGroup = this.formBuilder.group(this.getNormalForm(), {
            validators: [emailConditionallyRequiredValidator]
          });
          this.loading = false;
        }
      }
    });
  }

  ngOnInit(): void {
    if (this.authService.currentUserValue) {
      this.user = this.authService.currentUserValue;
    }
  }

  ngDoCheck() {
    if (this.editFormGroup) {
      this.editFormGroup.controls['lastname_manager'].valueChanges.subscribe(value => {
        this.editFormGroup.get('mail_manager').updateValueAndValidity();
      });
    }
  }

  get f() {
    return this.editFormGroup.controls;
  }

  get filteredCompanies() {
    return this.editFormGroup.value.adherent_id
      ? this.companies.filter(c => c.adherent && c.adherent.id === this.editFormGroup.value.adherent_id) : this.companies;
  }

  getNormalForm(data = null) {
    return {
      name: [data ? data.name : '', Validators.required],
      adherent_id: [data && data.adherent ? data.adherent.id : '', Validators.required],
      company_id: [data && data.company ? data.company.id : '', null],
      phone: [data ? data.phone : '', null],
      fax: [data ? data.fax : '', null],
      latitude: [data ? data.latitude : '', null],
      longitude: [data ? data.longitude : '', null],
      id_agency: [data ? data.id_agency : '', null],
      adherent_code: [data ? data.adherent_code : '', null],
      warehouse: [data ? data.warehouse : false, null],
      mail_manager: [data ? data.mail_manager : '', [Validators.required, emailConditionallyRequiredValidator]],
      lastname_manager: [data ? data.lastname_manager : '', Validators.required],
      firstname_manager: [data ? data.firstname_manager : '', Validators.required],
      mail_industrial_manager: [data ? data.mail_industrial_manager : '', null],
      lastname_industrial_manager: [data ? data.lastname_industrial_manager : '', null],
      firstname_industrial_manager: [data ? data.firstname_industrial_manager : '', null],
      address_street_number: [
        data && data.address ? (data.address.street_number ? data.address.street_number : '') : '',
        null],
      address_street_name: [
        data && data.address ? (data.address.street_name ? data.address.street_name : '') : '',
        Validators.required],
      address_city: [data && data.address ? (data.address.city ? data.address.city : '') : '', Validators.required],
      address_zipcode: [data && data.address ? (data.address.zipcode ? data.address.zipcode : '') : '', Validators.required],
      display: [data ? data.display : false, null],
      deactivate_contact: [data ? data.deactivate_contact : false, null],
      deactivate_appointment: [data ? data.deactivate_appointment : false, null],
      deactivate_quote_requests: [data ? data.deactivate_quote_requests : false, null],
      deactivate_orders: [data ? data.deactivate_orders : false, null],
      agency_payment: [data ? data.agency_payment : false, null],
      online_payment: [data ? data.online_payment : false, null],
      settlement_payment: [data ? data.settlement_payment : false, null],
      intervention_emails: [data ? data.intervention_emails : '', null],

      // WARNING: NOT DEFAULT FALSE IN DB
      accessibility_counter: [data && data.accessibility_counter ? data.accessibility_counter : false, null],
      accessibility_toilet: [data && data.accessibility_toilet ? data.accessibility_toilet : false, null],
      accessibility_building: [data && data.accessibility_building ? data.accessibility_building : false, null],
      accessibility_parking: [data && data.accessibility_parking ? data.accessibility_parking : false, null],

      accessibility_wheelchairs_slots: [data ? data.accessibility_wheelchairs_slots : false, null],
      social_reason: [data ? data.social_reason : '', null],
      b2b_only: [data ? data.b2b_only : false, null],
      physical_agency: [data ? data.physical_agency : false, null],
      pack_phone_plus: [data ? data.pack_phone_plus : false, null],
      phone_lines_count: [data && data.phone_lines_count ? data.phone_lines_count : 1, Validators.required],
      delivery_status: [data ? data.delivery_status : false, null],
      erp: [data ? data.erp : '', null],
      wincho_tires: [data ? data.wincho_tires : 0, null],
      advertisement_tires: [data ? data.advertisement_tires : 0, null],
      operation_sheet: [data ? data.operation_sheet : 0, null],
      wincho_ema: [data ? data.wincho_ema : 0, null],
      advertisement_ema: [data ? data.advertisement_ema : 0, null],
      advertisement_a4_ema: [data ? data.advertisement_a4_ema : 0, null],
      permanent_4x3: [data ? data.permanent_4x3 : 0, null],
      operation_sheet_ema: [data ? data.operation_sheet_ema : 0, null],
      quantity_masts: [data ? data.quantity_masts : 0, null],
      quantity_bays: [data ? data.quantity_bays : 0, null],
      quantity_bridges: [data ? data.quantity_bridges : 0, null],
      quantity_vi_interventions: [data ? data.quantity_vi_interventions : 0, null],
      ref_epyx: [data ? data.ref_epyx : 0, null],
      date_epyx: [data && data.date_epyx ? data.date_epyx.toString().split('T')[0] : null, null],
      vat_number: [data ? data.vat_number : '', null],
      siret: [data ? data.siret : '', null],
      is_client_base: [data ? data.is_client_base : false, null],
      payment_visa: [data ? data.payment_visa : false, null],
      payment_mastercard: [data ? data.payment_mastercard : false, null],
      payment_american_express: [data ? data.payment_american_express : false, null],
      payment_mobile_nfc: [data ? data.payment_mobile_nfc : false, null],
      payment_check: [data ? data.payment_check : false, null],
      has_protective_wall: [data ? data.has_protective_wall : false, null],
      screen_memo_quantity: [data && data.screen_memo_quantity != null ? data.screen_memo_quantity : 3, null],
      op_tire_easel: [data && data.op_tire_easel != null ? data.op_tire_easel : 1, null],
      op_ema_easel: [data && data.op_ema_easel != null ? data.op_ema_easel : 1, null]
    };
  }

  // ADHERENT/COMPANY
  onSelectAdherent($event) {
    this.editFormGroup.patchValue({adherent_id: $event.id});
  }

  onSelectCompany($event = null) {
    this.editFormGroup.patchValue({company_id: $event.id});
  }

  /* ERP */
  onSelectErp($event) {
    this.editFormGroup.patchValue({erp: $event.name});
  }

  checkValue($event) {
    if ($event === '') {
      this.editFormGroup.patchValue({erp: null});
    }
  }

  displayCompanies() {
    return this.editFormGroup.value.generic_company_type === 'Company' ? true : false;
  }

  displayAdherents() {
    return this.editFormGroup.value.generic_company_type === 'Adherent' ? true : false;
  }

  /* FORM */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    const agency: Agency = {
      ...this.editFormGroup.value
    };

    // Edit mode
    if (this.editMode.active) {
      agency.id = this.route.snapshot.params.id;
    }


    this.agenciesService.edit(agency).then((response) => {
      if (!this.editMode.active) {
        // ADD TO ENUMS
        this.enumsService.enums.agencies.push(response);
      }
      this.alertService.success((this.editMode && this.editMode.active) ? 'Modification enregistrée avec succès.' : 'Agence enregistrée avec succès.');
      this.router.url.split('/').includes('open-agencies') ? this.router.navigate(['/profilplus-agences/open-agencies']) : this.router.navigate(['/profilplus-agences/closed-agencies']);
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

}
