import { Injectable } from '@angular/core';

import 'bootstrap-notify';
import { ApiService } from './api.service';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';

@Injectable({ providedIn: 'root' })
export class LoyaltyCardsService {
  constructor(private apiService: ApiService) {}

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('loyalty_cards')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllWithPagination(page: string | number, sortBy: {field: string, order: string} = null, filters = null, card_type = null) {
    return new Promise((resolve, reject) => {
      const url = card_type ? 'loyalty_cards/special' : 'loyalty_cards'
      this.apiService.get(`${url}?page=${page}`
        + (card_type ? '&q:card_type=' + card_type : '')
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
        + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id: number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('loyalty_cards/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(card) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      if (card.adherent_id) {
        formData.append('adherent_id', card.adherent_id)
      }
      if (card.organization_name) {
        formData.append('organization_name', card.organization_name)
      }
      formData.append('card_type', card.card_type)
      formData.append('number_of_cards', card.number_of_cards)
      this.apiService.post('loyalty_cards', formData)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('loyalty_cards/' + id)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        })
    })
  }

  deleteHistory(history_id) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('loyalty_customer_histories/' + history_id)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        })
    })
  }

  // Get loyalty customer histories for this loyalty card (LoyaltyCustomerHistory)
  getHistoryById(card_id) {
    return new Promise((resolve, reject) => {
      this.apiService.get('loyalty_cards/' + card_id + '/histories')
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        })
    })
  }

  // Get expired histories for this loyalty card (LoyaltyCardExpiredHistory)
  getExpiredHistoryById(card_id) {
    return new Promise((resolve, reject) => {
      this.apiService.get('loyalty_cards/' + card_id + '/expired_histories')
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        })
    })
  }

  debit(card_id, amount) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('amount', amount ? amount : '');

      this.apiService.post('loyalty_cards/' + card_id + '/debit', formData)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        })
    })
  }

  credit(card_id, amount, loyalty_rule_id) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('amount', amount ? amount : '');
      formData.append('loyalty_rule_id', loyalty_rule_id ? loyalty_rule_id : '');

      this.apiService.post('loyalty_cards/' + card_id + '/credit', formData)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        })
    })
  }

  merge(card_1, card_2) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('loyalty_card_1', card_1);
      formData.append('loyalty_card_2', card_2);
      this.apiService.post('loyalty_cards/merge', formData)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        })
    })
  }

  renew(card_id) {
    return new Promise((resolve, reject) => {
      this.apiService.post('loyalty_cards/' + card_id + '/renew', null)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        })
    })
  }

}
