import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { TiresService } from 'src/app/services/tires.service';
import { Tire } from '../../../../models/Tire';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public loading: boolean = false;
  public editMode: {
    active: boolean,
    data: any
  } = {active: false, data: null};
  public submitted: boolean = false;
  public editFormGroup: UntypedFormGroup;
  public type: string;
  public typeLabel: string;
  public typeParam: string;
  public optionsFilteredByType: any;

  public tire_photo_1: string | File = null;
  public tire_photo_2: string | File = null;
  public tire_photo_3: string | File = null;
  public tire_photo_4: string | File = null;

  constructor(
    public enumsService: EnumsService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private tiresService: TiresService,
    private alertService: AlertService,
    public loadingService: LoadingService) {
    this.enumsService.observable.subscribe({
      complete: () => {
        this.type = this.route.snapshot.params.type;
        this.optionsFilteredByType = this.enumsService.enums.tire_data[this.type]
        switch (this.type) {
          case 'vl':
            this.typeParam = "VlTire";
            this.typeLabel = 'Pneu VL';
            break;
          case 'pl':
            this.typeParam = "PlTire";
            this.typeLabel = 'Pneu PL';
            break;
          case 'ag':
            this.typeParam = "AgTire";
            this.typeLabel = 'Pneu AG';
            break;
          case 'ag_used':
            this.typeParam = "AgUsedTire";
            this.typeLabel = 'Pneu AG d\'occasion';
            break;
          default:
            break;
        }
        if (this.route.snapshot.params.id) { // EDIT
          this.editMode.active = true;
          this.loading = true;
          this.tiresService.getById(this.route.snapshot.params.id).then((response: Tire) => {
            this.editMode.data = response;

            if (this.route.snapshot.params.type === 'ag_used') {
              this.editMode.data.tire_photo_1 = (response.tire_photo_1) ? [{name: 'photo n°1', link: response.tire_photo_1}] : null
              this.editMode.data.tire_photo_2 = (response.tire_photo_2) ? [{name: 'photo n°2', link: response.tire_photo_2}] : null
              this.editMode.data.tire_photo_3 = (response.tire_photo_3) ? [{name: 'photo n°3', link: response.tire_photo_3}] : null
              this.editMode.data.tire_photo_4 = (response.tire_photo_4) ? [{name: 'photo n°4', link: response.tire_photo_4}] : null
            }

            this.editFormGroup = this.formBuilder.group(
              this.route.snapshot.params.type === 'ag_used' ? this.getAgUsedForm(response) : this.getNormalForm(response));
              this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.alertService.error(err);
          });
        } else {
          this.editFormGroup = this.formBuilder.group(
            this.route.snapshot.params.type === 'ag_used' ? this.getAgUsedForm() : this.getNormalForm());
        }
      }
    });
  }

  ngOnInit(): void { }

  get f() { return this.editFormGroup.controls; }

  getNormalForm(data?: Tire ) {
    return {
      ean: [data ? data.ean : null, null],
      category: [data && data.category ? data.category.id : null, null],
      season: [data ? data.season : null, null],
      width: [data ? data.width : null, null],
      height: [data ? data.height : null, null],
      diameter : [data ? data.diameter : null, null],
      load_index : [data ? data.load_index : null, null],
      speed_index: [data && data.speed_index ? data.speed_index.id : null, null],
      runflat : [data ? data.runflat : null, null],
      label_fuel : [data && data.label_fuel ? data.label_fuel.id : null, null],
      label_wet : [data && data.label_wet ? data.label_wet.id : null, null],
      label_noise : [data ? data.label_noise : null, null],
      tire_model_id : [data && data.tire_model ? data.tire_model.id : null, null],
      price_with_tax : [data ? data.price_with_tax : 0, Validators.required],
      on_sale : [data ? data.on_sale : null, null],
      description : [data ? data.description : null, null],
      homologation : [data ? data.homologation : null, null],
      bf_price : [data ? data.bf_price : null, null],
      price_with_sale : [data ? data.price_with_sale : null, null],
      sale_quantity : [data ? data.sale_quantity : null, null],
      law_code : [data ? data.law_code : null, null],
      is_3pmsf : [data ? data.is_3pmsf : null, null],
      ice_grip : [data ? data.ice_grip : null, null],
      eprel_url : [data ? data.eprel_url : null, null],
      category_noise : [data ? data.category_noise : null, null],
    };
  }

  getAgUsedForm(data?: Tire) {
    return {
      tire_brand_id: [data && data.brand ? data.brand.id : null, Validators.required],
      tire_profile: [data ? data.tire_profile : null, Validators.required],
      ean: [data ? data.ean : null, null],
      width: [data ? data.width : null, Validators.required],
      height: [data ? data.height : null, Validators.required],
      diameter : [data ? data.diameter : null, Validators.required],
      load_index : [data ? data.load_index : null, Validators.required],
      speed_index: [data && data.speed_index ? data.speed_index.id : null, Validators.required],
      wear_rate : [data ? data.wear_rate : null, Validators.required],
      remaining_gum : [data ? data.remaining_gum : null, [Validators.required, Validators.min(0)]],
      sale_quantity : [data ? data.sale_quantity : null, [Validators.required,  Validators.min(0), Validators.max(10)]],
      price_with_tax : [data ? data.price_with_tax : null, Validators.required],
      description : [data ? data.description : null, null],
      agency_id : [data && data.agency ? data.agency.id : null, null],
      saler_city : [data ? data.saler_city : null, Validators.required],
      subdivision_code : [data ? data.subdivision_code : null, Validators.required],
      saler_first_name : [data ? data.saler_first_name : null, null],
      saler_last_name : [data ? data.saler_last_name : null, Validators.required],
      saler_phone : [data ? data.saler_phone : null, Validators.required],
      saler_email : [data ? data.saler_email : null, Validators.required],
      reference_number : [data ? data.reference_number : null,null],
    };
  }

  onSelectedBrand($event) {
    this.editFormGroup.patchValue({brand_id: $event.id});
  }

  onPictureSelected(files: Array<File>, params) {
    if (params) {
      switch (params) {
        case 'tire_photo_1': {
          this.tire_photo_1 = files ? files[0] : '';
          break;
        }
        case 'tire_photo_2': {
          this.tire_photo_2 = files ? files[0] : '';
          break;
        }
        case 'tire_photo_3': {
          this.tire_photo_3 = files ? files[0] : '';
          break;
        }
        case 'tire_photo_4': {
          this.tire_photo_4 = files ? files[0] : '';
          break;
        }
        default: {
          break;
        }
      }

      if (!files && this.editMode.data[params]) {
        this.editMode.data[params] = null
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    const tire = {
      ...this.editFormGroup.value,
      tire_photo_1: this.tire_photo_1,
      tire_photo_2: this.tire_photo_2,
      tire_photo_3: this.tire_photo_3,
      tire_photo_4: this.tire_photo_4,
      type: this.typeParam
    };

    if (this.editMode) {
      tire.id = this.route.snapshot.params.id;
    }
    this.tiresService.edit(tire).then(() => {
      this.router.navigate(['/website/tires/', this.type]);
      this.alertService.success((this.editMode && this.editMode) ? 'Modification enregistrée avec succès.' : 'Pneu enregistré avec succès.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }
}
