import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { AddressesService } from 'src/app/services/addresses.service';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit {
  dataTable: any;
  public type: string;
  public items: Array<any>;

  public loading: boolean = false;
  public loadingList: boolean = false;
  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [];
  public optionsFilteredByType: any;

  columns = [
    {name: 'ID', slug: 'id'},
    {name: 'Numéro rue', slug: 'street_number'},
    {name: 'Nom rue', slug: 'street_name'},
    {name: 'Code postal', slug: 'zipcode'},
    {name: 'Ville', slug: 'city'},
  ];

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    public enumsService: EnumsService,
    private addressesService: AddressesService,
    private alertService: AlertService
  ) {
    this.type = this.router.url.split('/')[3];
    this.enumsService.observable.subscribe({
      complete: () => {
        this.loading = false;
        this.setPage({offset: 0});
      }
    });
  }


  ngOnInit(): void { }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.loadingList = true;
    const addressesType = this.type === 'addresses' ? 'site_intervention_address' : 'site_billing_address';
    this.addressesService.getAllWithPagination(this.pageOffsetSelected + 1, addressesType, sortBy, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }
}
