<footer class="ppp-footer footer">
  <div [ngClass]="[ fullwidth ? 'container-fluid' :  'container']">
    <nav class="float-left">
      <ng-content></ng-content>
    </nav>
    <div class="copyright float-right">
      PROFIL PLUS PORTAIL &copy; 2020
    </div>
  </div>
</footer>
