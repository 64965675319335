import { Component, OnInit } from '@angular/core';

import { AlertService } from 'src/app/services/alert.service';
import { LoyaltyRulesService } from 'src/app/services/loyalty-rules.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-loyalty-rules',
  templateUrl: './loyalty-rules.component.html',
  styleUrls: ['./loyalty-rules.component.scss']
})
export class LoyaltyRulesComponent implements OnInit {
  public loading: boolean = false;
  public loyaltyRules: Array<any> = null;

  public columns = [
    {name: 'ID', slug: 'id'},
    {name: 'Nom', slug: 'name'},
    {name: 'Coefficient', slug: 'coefficient'},
    {name: 'Arrondi', slug: 'round'},
    {name: 'Extra points', slug: 'extra_points'},
    {name: 'Date de début', slug: 'start_at', template: true},
    {name: 'Date de fin', slug: 'end_at', template: true},
    {name: 'Activée ?', slug: 'active', template: true}
  ];

  constructor(
    private loyaltyRulesService: LoyaltyRulesService,
    private alertService: AlertService) {}

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.loading = true;
    this.loyaltyRulesService.getAll()
      .then((response: Array<any>) => {
        this.loading = false;
        this.loyaltyRules = response;
      })
      .catch((err) => {
        this.loading = false;
        this.alertService.error(err);
      });
  }

  onDelete(loyaltyRuleId) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cette promotion ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.loyaltyRulesService.delete(loyaltyRuleId)
          .then(() => {
            const index = this.loyaltyRules.findIndex(e => e.id === loyaltyRuleId);
            if (index !== -1) {
              this.loyaltyRules.splice(index, 1);
              this.loyaltyRules = [...this.loyaltyRules];
              this.alertService.success('Promotion supprimée avec succès.');
            }
          })
          .catch((err) => {
            this.alertService.error(err);
          });
      }
    });
  }

}
