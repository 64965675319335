import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { AppointmentConfiguration } from '../models/AppointmentConfiguration';

@Injectable({ providedIn: 'root' })
export class AppointmentConfigurationsService {
  constructor(private apiService: ApiService) {}

  getById(appointmentConfigurationId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('appointment_configurations/' + appointmentConfigurationId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('appointment_configurations')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(appointmentConfiguration) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('appointment_configuration:duration', appointmentConfiguration.duration ? appointmentConfiguration.duration : 0);
      formData.append('appointment_configuration:ressources_count', appointmentConfiguration.ressources_count ? appointmentConfiguration.ressources_count : 0);
      formData.append('appointment_configuration:min_delay', appointmentConfiguration.min_delay ? appointmentConfiguration.min_delay : 0);
      formData.append('appointment_configuration:agency_id', appointmentConfiguration.agency);
      formData.append('appointment_configuration:appointment_type_id', appointmentConfiguration.appointment_type);
      if (appointmentConfiguration.id) {
        this.apiService.put('appointment_configurations/' + appointmentConfiguration.id, formData)
        .then((response: any) => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
      } else {
        this.apiService.post('appointment_configurations', formData)
        .then((response: any) => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }

  delete(appointmentConfigurationId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('appointment_configurations/' + appointmentConfigurationId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
