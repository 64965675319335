export default {
    viewChecked() {
        if (jQuery('.selectpicker-vanilla').length !== 0) {

            // @ts-ignore: Unreachable code error
            jQuery('.selectpicker-vanilla').selectpicker({
                noneSelectedText: 'Aucun élément sélectionné'
            });
        }
    },
    afterChange() {
        window.scrollTo(0, 0);
    }
};

