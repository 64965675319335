import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';

import { AlertService } from 'src/app/services/alert.service';
import { PageBackgroundsService } from 'src/app/services/page-backgrounds.service';
import { AuthService } from 'src/app/services/auth.service';
import { PoliciesService } from 'src/app/services/policies.service';

import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import {PagesService} from '../../../services/pages.service';

@Component({
  selector: 'app-page-backgrounds',
  templateUrl: './page-backgrounds.component.html',
  styleUrls: ['./page-backgrounds.component.scss']
})
export class PageBackgroundsComponent implements OnInit {
  public items: Array<any> = null;
  public loading: boolean = false;
  public loadingList: boolean = false;
  public currentModule: {
    slug: string,
    name: string
  } = {
    slug: 'website',
    name: 'Site Profil Plus'
  };

  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [];

  constructor(
    private cd: ChangeDetectorRef,
    private alertService: AlertService,
    private  pagesService: PagesService,
    private pageBackgroundsService: PageBackgroundsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    public policiesService: PoliciesService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
      this.route.data.subscribe((data) => {
        if (data.moduleSlug && data.moduleName) {
          this.currentModule.slug = data.moduleSlug;
          this.currentModule.name = data.moduleName;

          const website = this.currentModule.slug === 'job' ? 1 : 0;
          this.pagesService.getAll(website).then((pages) => {
            this.filters.push({
              name: 'Page CMS',
              slug: 'page_cms',
              selected_emit_property: 'id',
              type: 'autocomplete',
              on_select_only: true,
              property: 'title',
              options: pages
            });
          });
        }
      });
    }


  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  setPage(pageInfo, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected;
    this.loadingList = true;
    const website = this.currentModule.slug === 'job' ? 1 : 0;
    this.pageBackgroundsService.getAll(website, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.items = response;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onDelete(pageBackgroundId) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cet habillage de page ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.pageBackgroundsService.delete(pageBackgroundId)
          .then(() => {
            const index = this.items.findIndex(e => e.id === pageBackgroundId);
            if (index !== -1) {
              this.items.splice(index, 1);
              this.items = [...this.items];
              this.alertService.success('Habillage de page supprimé avec succès.');
            }
          })
          .catch((err) => {
            this.alertService.error(err);
          });
      }
    });
  }

}
