import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { QuoteRequest } from 'src/app/models/QuoteRequest';
import { AlertService } from 'src/app/services/alert.service';
import { AppointmentsService } from 'src/app/services/appointments.service';
import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { QuoteRequestsService } from 'src/app/services/quote-requests.service';
import { WorkersService } from 'src/app/services/workers.service';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {
  public items: Array<QuoteRequest> = null;

  public loading: boolean = false;
  public loadingList: boolean = false;

  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [
    {
      name: 'Nom client',
      slug: 'name',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Email client',
      slug: 'email',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Tél client',
      slug: 'phone',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Date de début du RDV',
      slug: 'appointment_start_at',
      type: 'date',
      class: 'col-2',
      value: null
    },
    {
      name: 'Date de fin du RDV',
      slug: 'appointment_end_at',
      type: 'date',
      class: 'col-2',
      value: null
    },
    {
      name: 'Date de début de création',
      slug: 'created_at_start_at',
      type: 'date',
      class: 'col-2',
      value: null
    },
    {
      name: 'Date de fin de création',
      slug: 'created_at_end_at',
      type: 'date',
      class: 'col-2',
      value: null
    }
  ];
  public optionsFilteredByType: any;


  constructor(
    private cd: ChangeDetectorRef,
    private appointmentsService: AppointmentsService,
    private router: Router,
    private enumsService: EnumsService,
    private alertService: AlertService,
    private authService: AuthService,
    private workersService: WorkersService,
    public policiesService: PoliciesService) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.policiesService.currentUser = this.authService.currentUserValue;
      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          this.loading = false;
          this.filters.push({
            name: 'Agence',
            slug: 'agency_name',
            selected_emit_property: 'name',
            type: 'autocomplete',
            on_select_only: true,
            class: 'col-2',
            options: this.enumsService.enums.agencies
          })
        }
      });
    }


  ngOnInit(): void { }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;
    this.appointmentsService.getAllWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }


  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

  export() {
    this.workersService.startNewWorker('export_appointments', this.filtersSelected).then(() => {
      this.alertService.success('Export de la liste des demandes de rendez-vous, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }
}
