import { Component, OnInit } from '@angular/core';

import { AlertService } from 'src/app/services/alert.service';
import { AppSettingsService } from 'src/app/services/app-settings.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss']
})
export class AppSettingsComponent implements OnInit {
  public appSettings: Array<any> = null;
  public loadingList: boolean = false;
  constructor(private alertService: AlertService, private appSettingsService: AppSettingsService) {}

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.loadingList = true;
    this.appSettingsService.getAll()
      .then((response: Array<any>) => {
        this.loadingList = false;
        this.appSettings = response;
      })
      .catch((err) => {
        this.loadingList = false;
        this.alertService.error(err);
      });
  }

  onDelete(AppSettingId) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cette configuration ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.appSettingsService.delete(AppSettingId)
          .then(() => {
            const index = this.appSettings.findIndex(e => e.id === AppSettingId);
            if (index !== -1) {
              this.appSettings.splice(index, 1);
              this.appSettings = [...this.appSettings];
              this.alertService.success('Configuration supprimée avec succès.');
            }
          })
          .catch((err) => {
            this.alertService.error(err);
          });
      }
    });
  }

}
