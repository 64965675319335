import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'round'})

export class RoundPipe implements PipeTransform {
  transform(value: any, digits: number = 2): string {
    const preResult : any = parseFloat(value).toFixed(digits)
    return !isNaN(preResult) ? preResult : value
  }
}
