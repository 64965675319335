import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import Swal from 'sweetalert2';

import { AuthService } from 'src/app/services/auth.service';
import { AdherentsService } from 'src/app/services/adherents.service';
import { AlertService } from 'src/app/services/alert.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { EnumsService } from 'src/app/services/enums.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-adherents',
  templateUrl: './adherents.component.html',
  styleUrls: ['./adherents.component.scss']
})
export class AdherentsComponent implements OnInit {

  public items: Array<any> = null;
  public loading: boolean = false;
  public loadingList: boolean = false;
  @ViewChild('datatable') datatable: any;

  public columns = [
    {name: 'ID', slug: 'id'},
    {name: 'Nom', slug: 'name'},
    {name: 'Code facturation', slug: 'codefac'}
  ];

  public filters: Array<any> = [];
  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  module: string = null

  constructor(
    private cd: ChangeDetectorRef,
              private route: ActivatedRoute,
              private alertService: AlertService,
              private adherentsService: AdherentsService,
              private authService: AuthService,
              public policiesService: PoliciesService,
              private enumsService: EnumsService) {
    this.policiesService.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  ngAfterContentInit() {
    this.loading = true;
    this.module = this.route.snapshot.url[0].path
    this.enumsService.observable.subscribe({
      complete: () => {
        this.filters.push({
          name: 'Nom de l\'adhérent',
          slug: 'adherent_name',
          type: 'autocomplete',
          class: 'col-3',
          options: this.enumsService.enums.adherents
        });
        this.loading = false;
      }
    });
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;

    this.adherentsService.getAll(this.module, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.items = response;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onDelete(adherentId) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cet adhérent ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.adherentsService.delete(adherentId)
          .then(() => {
            const index = this.items.findIndex(e => e.id === adherentId);
            if (index !== -1) {
              this.items.splice(index, 1);
              this.items = [...this.items];
              this.alertService.success('Adhérent supprimé avec succès.');
            }
          })
          .catch((err) => {
            this.alertService.error(err);
          });
      }
    });
  }

}
