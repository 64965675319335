<app-topbar moduleSlug="profilplus-site">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Clients</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<!-- DISABLED FOR NOW -->
<div class="row">
  <div class="col-md-12">
    <a (click)="$event.preventDefault(); exportCustomers()" class="ppp-button ppp-button-black btn btn-primary btn-round">
      <i class="material-icons">library_add</i>
      Export .csv
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">people</i>
        </div>
        <h4 class="card-title">Liste des clients</h4>
      </div>
      <div class="card-body">
        <app-filter-bar [filters]="filters"
          (changed)="setPage({offset: 0}, null, $event)">
          <p *ngIf="loadingList">chargement...</p>
        </app-filter-bar>
        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [externalPaging]="true"
            [externalSorting]="true"
            [loadingIndicator]="loadingList"
            [reorderable]="false"
            (page)="setPage($event)"
            (sort)="onSort($event)"
          >
            <ngx-datatable-column [resizeable]="false" name="ID" prop="id" [width]="75">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Compte désactivé ?" prop="deactivated" [width]="195">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <i *ngIf="value === false" class="material-icons red">clear</i>
                <i *ngIf="value === true" class="material-icons green">done</i>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Compte pro ?" prop="pro" [width]="150">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <i *ngIf="value === false" class="material-icons red">clear</i>
                <i *ngIf="value === true" class="material-icons green">done</i>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Entreprise PRO" prop="pro_company" [width]="150">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value.name }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Nom" prop="last_name" [width]="150">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Prénom" prop="first_name" [width]="150">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Courriel" prop="email" [width]="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Tél. fixe" prop="phone" [width]="130">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="policiesService.currentUser.policies['order'].read" [routerLink]="['/website/orders/by-customer-id', row.id]" type="button" class="btn btn-success" title="Voir les commandes de ce client">
                  <i class="material-icons">shopping_cart</i>
                </a>
                <a [routerLink]="['/website/customers/', row.id]" type="button" class="btn btn-success" title="Afficher le client">
                  <i class="material-icons">visibility</i>
                </a>
                <a *ngIf="policiesService.currentUser.policies['customer'].update" [routerLink]="['/website/customers/edit/', row.id]" type="button" class="btn btn-success" title="Modifier le client">
                  <i class="material-icons">edit</i>
                </a>
                <button *ngIf="policiesService.currentUser.is_admin && !row.loyalty_card"
                  type="button" class="btn btn-danger"
                  (click)="onDelete(row.id)" title="Supprimer">
                  <i class="material-icons">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
