<app-alert></app-alert>
<app-front-layout>
  <div class="container" content>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
        <form class="form" [formGroup]="loginFormGroup"  (ngSubmit)="onSubmit()">

          <div class="ppp-login card card-login" [ngClass]="{'card-hidden': !formVisible}">
            <div class="card-header card-header-rose text-center">
              <h4 class="card-title">Authentification</h4>
            </div>
            <div class="card-body ">
              <span class="bmd-form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="material-icons">email</i>
                    </span>
                  </div>
                  <input type="email" class="form-control" id="pp-login-email" placeholder="Adresse email" formControlName="email">
                </div>
              </span>
              <span class="bmd-form-group">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="material-icons">lock_outline</i>
                    </span>
                  </div>
                  <input type="password" class="form-control" placeholder="Mot de passe" formControlName="password">
                </div>
              </span>
            </div>
            <div class="card-footer justify-content-center">
              <input type="submit" value="Se connecter" class="ppp-button btn btn-rose btn-link btn-lg" >
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</app-front-layout>
