import { Injectable } from '@angular/core';

import 'bootstrap-notify';
import { ApiService } from './api.service';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';

@Injectable({ providedIn: 'root' })
export class AddressesService {
  constructor(private apiService: ApiService) {}

  getAll(type) {
    return new Promise((resolve, reject) => {
      this.apiService.get('addresses?address_type=' + type)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllWithPagination(page: string | number, type: string, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('addresses?address_type=' + type
        + '&page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
        + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id: number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('addresses/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  delete(id: number) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('addresses/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
