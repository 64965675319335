<app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a>Tableau de bord</a></li>
            <li class="breadcrumb-item"><a>Site Profil Plus</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/website/customers']">Clients</a></li>
            <ng-container  *ngIf="(editMode.active && editMode.data); else templateElse">
                <li class="breadcrumb-item active"><a href="javascript:;">Édition du client {{editMode.data.first_name + ' ' +  editMode.data.last_name }}</a></li>
            </ng-container>
            <ng-template #templateElse>
                <li class="breadcrumb-item active"><a href="javascript:;">Création d'un nouveau client</a></li>
            </ng-template>
        </ol>
      </nav>
    </ng-container>
</app-topbar>

<div class="card">
    <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
            <i class="material-icons">supervisor_account</i>
        </div>
        <ng-container  *ngIf="(editMode.active && editMode.data); else h4else">
            <h4 class="card-title">Édition du client {{editMode.data.first_name + ' ' +  editMode.data.last_name }}</h4>
        </ng-container>
        <ng-template #h4else>
            <h4 class="card-title">Création d'un nouveau client</h4>
        </ng-template>
    </div>
    <div class="card-body row">
        <form *ngIf="editFormGroup" class="col-md-12 col-lg-4" [formGroup]="editFormGroup">

            <div class="form-group" style="margin-bottom: 3em;">
              <label for="civility" class="bmd-label-floating">Civilité</label>
              <select class="form-control" formControlName="civility" id="civility" style="margin-top: 1.5em;">
                <option value="0">Non connu (NC)</option>
                <option value="1">Monsieur</option>
                <option value="2">Madame</option>
              </select>
            </div>

            <div class="form-group">
              <label for="last_name" class="bmd-label-floating">Nom</label>
              <input type="text" class="form-control" id="last_name" formControlName="last_name">
            </div>

            <div class="form-group">
              <label for="first_name" class="bmd-label-floating">Prénom</label>
              <input type="text" class="form-control" id="first_name" formControlName="first_name">
            </div>

            <div class="form-group">
              <label for="company" class="bmd-label-floating">Entreprise</label>
              <input type="text" class="form-control" id="company" formControlName="company">
            </div>

            <div class="form-group">
              <label for="mob_phone" class="bmd-label-floating">Téléphone mobile</label>
              <input type="tel" class="form-control" id="mob_phone" formControlName="mob_phone">
            </div>

            <div class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.email.errors }">
              <label for="email" class="bmd-label-floating">Email</label>
              <input type="email" class="form-control" id="email" formControlName="email" email>
              <label class="error" for="email" *ngIf="f.email.errors && f.email.errors.email">
                Veuillez entrer une adresse email valide.
              </label>
            </div>

            <div class="form-group">
              <label for="birthday" class="bmd-label-floating">Date de naissance</label>
              <input type="date" class="form-control" id="birthday" formControlName="birthday">
            </div>

            <br>

            <h4>Adresse de livraison</h4>
            <div style="border:1px solid #aaa;padding:0 12px 12px 12px;">
              <div class="form-group">
                <label for="shipping_address_street_name" class="bmd-label-floating">Adresse</label>
                <input type="text" class="form-control" id="shipping_address_street_name" formControlName="shipping_address_street_name">
              </div>

              <div class="form-group">
                <label for="shipping_address_zipcode" class="bmd-label-floating">Code postal</label>
                <input minlength="5" maxlength="5" NumbersOnly [allowDecimals]="false" [allowSign]="false" type="text" class="form-control" id="shipping_address_zipcode" formControlName="shipping_address_zipcode">
                <label class="error" *ngIf="f.shipping_address_zipcode.value !== '' && f.shipping_address_zipcode.errors">
                  Veueillez renseigner renseigner un code postal valide (5 chiffres).
                </label>
              </div>

              <div class="form-group">
                <label for="shipping_address_city" class="bmd-label-floating">Ville</label>
                <input type="text" class="form-control" id="shipping_address_city" formControlName="shipping_address_city">
              </div>
            </div>

            <div class="form-group">
              <label for="phone" class="bmd-label-floating">Téléphone</label>
              <input type="tel" class="form-control" id="phone" formControlName="phone">
            </div>

            <div class="form-group">
              <app-datalist
                *ngIf="enumsService.enums.companies"
                label="Rechercher une société"
                [items]="enumsService.enums.pro_companies"
                (typed)="onResetProCompany($event)"
                (selected)="onSelectedProCompany($event)"
                [preselect]="preselectProCompany"
                [property]="'name'">
              </app-datalist>
            </div>

            <div class="form-group form-check" *ngIf="editMode.data.optin_myprofilplus === false">
              <label class="form-check-label">
                <input formControlName="optin_promo" class="form-check-input" type="checkbox" name="card">
                Opt-in Prospects ?
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>

            <div class="form-group form-check" *ngIf="editMode.data.optin_promo === false">
              <label class="form-check-label">
                <input formControlName="optin_myprofilplus" class="form-check-input" type="checkbox" name="card">
                Opt-in MyProfil+ ?
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>

            <div class="form-group form-check">
              <label class="form-check-label">
                <input formControlName="deactivated" class="form-check-input" type="checkbox" name="card">
                Compte désactivé ?
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>

            <br>

            <div>
                <input
                  type="submit"
                  [disabled]="editFormGroup.invalid"
                  class="ppp-button ppp-button-blue btn btn-primary btn-round"
                  [value]="editMode.active ? 'Mettre à jour' : 'Créer'"
                  (click)="onSubmit()">
            </div>
        </form>
    </div>
</div>
