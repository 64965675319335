
<app-topbar moduleSlug="myprofilplus">
	<ng-container title>
	  <nav aria-label="breadcrumb" role="navigation">
		<ol class="breadcrumb">
		  <li class="breadcrumb-item"><a>Tableau de bord</a></li>
		  <li class="breadcrumb-item"><a>MyProfil+</a></li>
      <li class="breadcrumb-item"><a [routerLink]="['/myprofilplus/customers']">Clients MyProfil+</a></li>
		  <ng-container *ngIf="(editMode.active && editMode.data && editMode.data.customer); else templateElse">
			  <li class="breadcrumb-item active"><a href="javascript:;">Édition du client MyProfil+ <span v-if="editMode.data.number"> lié à la carte {{editMode.data.number}}</span></a></li>
		  </ng-container>
		  <ng-template #templateElse>
			  <li class="breadcrumb-item active"><a href="javascript:;">Création d'un nouveau client MyProfil+</a></li>
		  </ng-template>
		</ol>
	  </nav>
	</ng-container>
</app-topbar>
<div class="row" *ngIf="editFormGroup">
  <!-- left side -->
	<form class="col-md-5" [formGroup]="editFormGroup">
	  <section class="card">
			<div class="card-header card-header-rose card-header-icon">
				<div class="card-icon">
				<i class="material-icons">store</i>
				</div>
				<h4 class="card-title">Agence / Numéro de carte</h4>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-lg-6 col-12">
            <p class="form-group is-required">
              <app-datalist
                label="Agence"
                [items]="agenciesList"
                [preselect]="preselectAgency"
                [property]="'name'"
                (selected)="onSelectedAgency($event)">
              </app-datalist>
            </p>
					</div>
          <ng-container *ngIf="editMode.active; else cardEdit">
            <div class="col-lg-12 mt-3">
              <p>Carte : {{editMode.data.number}}</p>
            </div>
            <div *ngIf="authService.currentUserValue && authService.currentUserValue.loyalty_rules
              && authService.currentUserValue.loyalty_rules.length > 0 && !editMode.data.unvalidated_at"
              class="form-group row col-12">
              <label class="col-md-12">Promotion</label>
              <div class="col-md-6 col-12">
                <select class="form-control" formControlName="loyalty_rule_id" style="position: inherit;top: inherit;">
                  <option *ngFor="let option of authService.currentUserValue.loyalty_rules" [value]="option.id">{{option.name}}</option>
                </select>
              </div>
            </div>
          </ng-container>
          <ng-template #cardEdit>
            <div class="col-lg-12">
              <div class="form-check">
                <label class="form-check-label">
                  <input formControlName="numbered_card"
                    type="radio" [value]="false"> Sans carte
                </label>
                <label class="form-check-label">
                  <input formControlName="numbered_card"
                    type="radio" [value]="true"> Avec carte prénumérotée
                </label>
              </div>
            </div>
            <div class="col-12" *ngIf="f.numbered_card.value">
              <p class="form-group is-required">
                <label class="bmd-label-floating">Identifiant de la carte</label>
                <input type="text" class="form-control" id="card_number"
                  formControlName="card_number">
              </p>
            </div>
          </ng-template>
				</div>
			</div>
		</section>
		<section class="card">
			<div class="card-header card-header-rose card-header-icon">
				<div class="card-icon">
					<i class="material-icons">supervisor_account</i>
				</div>
				<h4 class="card-title">Informations Client</h4>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-lg-12 col-md-12" >
            <ng-container *ngIf="editMode.active && editMode.data">
              <table>
                <tbody>
                  <tr>
                    <td><strong>Date de création du compte</strong></td>
                    <td>{{ editMode.data.created_at | date: 'dd/MM/yy à HH:mm:ss' }}</td>
                  </tr>
                  <tr>
                    <td><strong>État du compte</strong></td>
                    <td *ngIf="editMode.data.customer && !editMode.data.customer.locked_at && !editMode.data.customer.unlocked_at">
                      Fidélité V1 - Ancien compte
                    </td>
                    <td *ngIf="editMode.data.customer && editMode.data.customer.locked_at">
                      Fidélité V2 - Compte temporaire non activé depuis le {{ editMode.data.customer.locked_at | date: 'dd/MM/yy à HH:mm:ss' }}
                    </td>
                    <td *ngIf="editMode.data.customer && !editMode.data.customer.locked_at && editMode.data.customer.unlocked_at">
                      Fidélité V2 - Compte activé le {{ editMode.data.customer.unlocked_at | date: 'dd/MM/yy à HH:mm:ss' }}
                    </td>
                    <td *ngIf="editMode.data.unvalidated_at">
                      Fidélité V2 - Carte invalidée le {{ editMode.data.unvalidated_at | date: 'dd/MM/yy à HH:mm:ss' }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <button (click)="onActivateCustomer()"
                class="ppp-button ppp-button-blue btn btn-primary btn-round my-5 col-12"
                *ngIf="authService.currentUserValue.is_admin && editMode.data.customer && editMode.data.customer.locked_at">
                 Activer manuellement le compte
              </button>

            </ng-container>
            <p class="form-group is-required">
              <label class="bmd-label-floating">Civilité</label>
              <select required class="form-control" formControlName="civility" style="margin-top: -11px;">
                <option selected disabled>Veuillez choisir une civilité</option>
                <option value="0">Non connu (NC)</option>
                <option value="1">Monsieur</option>
                <option value="2">Madame</option>
              </select>
						</p>
					</div>
					<div class="col-lg-12 col-md-12" style="margin-top:1em;">
						<p class="form-group">
              <label class="bmd-label-floating">Société</label>
              <input type="text" class="form-control" id="society"
                formControlName="company">
						</p>
					</div>
					<div class="col-lg-6 col-md-12" >
						<p class="form-group is-required">
              <label class="bmd-label-floating">Prénom</label>
						  <input type="text" required class="form-control" id="firstname"
							  formControlName="first_name">
						</p>
					</div>
					<div class="col-lg-6 col-md-12" >
						<p class="form-group is-required">
              <label class="bmd-label-floating">Nom</label>
						<input type="text" required class="form-control" id="last_name"
							formControlName="last_name">
						</p>
					</div>
					<div class="col-lg-6 col-md-12" >
						<p class="form-group"
              [ngClass]="{ 'has-danger': f.email.value !== '' && f.email.errors,
              'is-required': f.mob_phone.value === '' }">
              <label class="bmd-label-floating">E-mail</label>
						  <input type="email" class="form-control" id="email"
							  formControlName="email" email>
                <label class="error" *ngIf="f.email.value !== '' && f.email.errors">
                  Veuillez renseigner un email valide.
                </label>
						</p>
					</div>
					<div class="col-lg-6 col-md-12" >
						<p class="form-group"
              [ngClass]="{ 'is-required': f.email.value === '' }">
              <label class="bmd-label-floating">Téléphone mobile</label>
              <input
                type="text"
                class="form-control"
                id="round"
                formControlName="mob_phone"
              >
            </p>
          </div>
          <div class="col-lg-12">
            <br>
            <p class="alert alert-warning">
              <strong>
                Seul l'un des deux champs (email ou mobile) est obligatoire.<br>
                Ne mettez pas de fausses coordonnées !
              </strong>
            </p>
          </div>
        </div>
		</div>
		</section>
    <section class="card">
			<div class="card-header card-header-rose card-header-icon">
				<div class="card-icon">
				<i class="material-icons">supervisor_account</i>
				</div>
				<h4 class="card-title">Coordonnées client</h4>
			</div>
			<div class="card-body">
        <div class="row col-md-12">
          <div class="col-lg-6 col-md-12">
            <p class="form-group">
              <label class="bmd-label-floating">Adresse</label>
              <input type="text" class="form-control" id="adress"
                formControlName="shipping_address_street_name">
            </p>
          </div>
          <div class="col-lg-6 col-md-12">
            <p class="form-group"
            [ngClass]="{ 'has-danger': f.shipping_address_zipcode.value !== '' && f.shipping_address_zipcode.errors }">
            <label class="bmd-label-floating">Code postal</label>
            <input
              formControlName="shipping_address_zipcode"
              type="text"
              minlength="5"
              maxlength="5"
              NumbersOnly
              [allowDecimals]="false"
              [allowSign]="false"
              class="form-control"
              id="round"
            >
            <label class="error" *ngIf="f.shipping_address_zipcode.value !== '' && f.shipping_address_zipcode.errors">
              Veuillez renseigner un code postal valide.
            </label>
            </p>
          </div>
          <div class="col-lg-6 col-md-12">
            <p class="form-group">
              <label class="bmd-label-floating">Ville</label>
            <input type="text" class="form-control" id="city"
              formControlName="shipping_address_city">
            </p>
          </div>
          <div class="col-lg-6 col-md-12">
            <p class="form-group">
            <label class="bmd-label-floating">Téléphone fixe</label>
            <input
              formControlName="phone"
              type="text"
              class="form-control"
              id="phone_fix"
            >
            </p>
          </div>
          <div class="col-lg-12">
            <div class="form-group">
              <label class="col-form-label">Date de naissance</label>
                <input type="date"
                class="form-control datetimepicker"
                 name="birth_at" formControlName="birthday">
            </div>
          </div>
        </div>
			</div>
		</section>

    <section *ngIf="editMode.active && editMode.data && editMode.data.customer && !editMode.data.unvalidated_at" class="card" style="padding-left:1em;">
      <div class="form-check">
        <p class="alert">
            Opt-in MyProfil+ ? 
            <strong *ngIf="!editMode.is_yassin" >{{ editMode.data.customer.optin_myprofilplus ? "Oui" : "Non" }}</strong>
            <input *ngIf="editMode.is_yassin" class="form-control"
            type="checkbox"
            name="card" formControlName="optin_myprofilplus">
        </p>
      </div>
    </section>

    <div class="col-12" *ngIf="!editMode.active || (editMode.active && !editMode.data.unvalidated_at)">
      <input
        type="submit"
        [disabled]="editFormGroup.invalid"
        class="ppp-button ppp-button-blue btn btn-primary btn-round"
        *ngIf="!loadingService.loading; else templateSubmit"
        [value]="editMode.active ? 'Mettre à jour' : 'Créer'" (click)="onSubmit()">
      <ng-template #templateSubmit>
        <button
          disabled="true"
          class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
          ...
        </button>
      </ng-template>
    </div>
	</form>
  <!-- end left side -->
  <!-- rigth side -->
  <div class="col-md-7" *ngIf="editMode.active && editMode.data.customer">
	  <section class="card">
      <div class="card-header card-header-rose card-header-icon">
				<div class="card-icon">
				<i class="material-icons">view_list</i>
				</div>
				<h4 class="card-title">Encours : <strong>{{ editMode.data.total_earned_points <= 0 ? 0 : editMode.data.total_earned_points }} pts</strong> pour <strong>{{ editMode.data.total_earned_amount <= 0 ? 0 : editMode.data.total_earned_amount }}€</strong></h4>
			</div>
      <div class="card-body">
        <table class="table-responsive">
          <thead>
            <tr>
              <td *ngIf="authService.currentUserValue.adherents.length > 0 || authService.currentUserValue.is_admin"><strong>Action</strong></td>
              <td><strong>Date</strong></td>
              <td><strong>Promotion</strong></td>
              <td><strong>Montants achats</strong></td>
              <td><strong>Points</strong></td>
              <td><strong>Euros gagnés</strong></td>
              <td><strong>Agence</strong></td>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let history of historyList; let indexOfElement=index;">
              <ng-container *ngIf="history.data && history.data.expired_at; else defaultHistory">
                <tr>
                  <td></td>
                  <td>{{ history.data.expired_at | date: 'dd/MM/yy' }}</td>
                  <td colspan="5">
                    [Péremption] Carte expirée le {{ history.data.expired_at | date: 'dd/MM/yy' }} (montant expiré : {{ history.data.expired_amount }} €)<br>
                    <input
                      [disabled]="loadingService.loading"
                      *ngIf="historyList[0].created_at == history.created_at" type="button" class="ppp-button-black btn btn-primary ppp-btn-renew" value="Renouveler l'encours expiré" (click)="onRenew()">
                  </td>
                </tr>
              </ng-container>
              <ng-template #defaultHistory>
                <tr style="background-color:#eee;">
                  <td class="pa-3" *ngIf="authService.currentUserValue.adherents.length > 0 || authService.currentUserValue.is_admin">
                    <div class="closeTable" (click)="onDeleteHistory(history.id)">
                      <i class="material-icons">close</i>
                    </div>
                  </td>
                  <td>{{ history.created_at | date: 'dd/MM/yy' }}</td>
                  <td>{{ history.loyalty_rule ? history.loyalty_rule.name : '' }}</td>
                  <td>{{ history.amount && history.loyalty_rule && history.loyalty_rule.name != 'Renouvellement encours' ? history.amount : '' }}</td>
                  <td>{{ history.earned_points ? history.earned_points : '' }}</td>
                  <td>{{ history.earned_amount ? history.earned_amount : '' }}</td>
                  <td>{{history.agency ? history.agency.name : ''}}</td>
                </tr>
              </ng-template>
            </ng-container>
          </tbody>
        </table>
      </div>
    </section>
    <form class="card" *ngIf="creditFormGroup"
      [formGroup]="creditFormGroup">
      <div class="card-header card-header-rose card-header-icon">
				<div class="card-icon">
				<i class="material-icons">euro_symbol</i>
				</div>
				<h4 class="card-title">Créditer en euros</h4>
			</div>
      <div class="card-body">
        <div class="form-group row" *ngIf="authService.currentUserValue && authService.currentUserValue.loyalty_rules
        && authService.currentUserValue.loyalty_rules.length > 0">
          <label class="col-12">Promotion</label>
          <div class="col-md-6 col-12">
            <select class="form-control" formControlName="creditPromotion" style="position: inherit;top: inherit;">
              <option *ngFor="let option of authService.currentUserValue.loyalty_rules" [value]="option.id">{{option.name}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6"
            [ngClass]="{ 'has-danger': c.creditAmount.value !== '' && c.creditAmount.errors }">
            <div class="form-group">
              <label class="bmd-label-floating">Montant</label>
              <input type="number" step=".01" NumbersOnly [allowDecimals]="true" [allowSign]="false" class="form-control" id="round" formControlName="creditAmount">
              <label class="error" *ngIf="authService.currentUserValue.agencies.length > 0 && c.creditAmount.value !== '' && c.creditAmount.errors">
                La saisie d'un montant supérieur à 5000€ est impossible.
              </label>
            </div>
          </div>
          <div class="col-md-6">
            <input type="submit" [disabled]="loadingService.loading || creditFormGroup.invalid" class="ppp-button ppp-button-blue btn btn-primary btn-round col-12" value="Créditer" style="margin-top: 1em;" (click)="onSubmitCredit()">
          </div>
        </div>
      </div>
    </form>
    <form class="card" *ngIf="creditFormGroup"
    [formGroup]="debitFormGroup">
      <div class="card-header card-header-rose card-header-icon">
				<div class="card-icon">
				<i class="material-icons">euro_symbol</i>
				</div>
				<h4 class="card-title">Débiter en euros</h4>
			</div>
      <div class="card-body">
        <div class="row">
          <ng-container *ngIf="(editMode.data.customer && editMode.data.customer.locked_at); else templateDebitElse">
            <div class="col-md-12">
              Impossible d'effectuer des débits car il s'agit d'un compte temporaire non activé !
            </div>
          </ng-container>
          <ng-template #templateDebitElse>
            <div class="col-md-6" [ngClass]="{ 'has-danger': d.debitAmount.value !== '' && d.debitAmount.errors }">
              <div class="form-group">
                <label class="bmd-label-floating">Montant</label>
                <input type="number" step=".01" NumbersOnly [allowDecimals]="true" [allowSign]="false" class="form-control" id="round" formControlName="debitAmount">
                <label class="error" *ngIf="authService.currentUserValue.agencies.length > 0 && d.debitAmount.value !== '' && d.debitAmount.errors">
                  La saisie d'un montant supérieur à 5000€ est impossible.
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <input type="submit" [disabled]="loadingService.loading || debitFormGroup.invalid" class="ppp-button ppp-button-blue btn btn-primary btn-round col-12" value="Débiter" style="margin-top: 1em;" (click)="onSubmitDebit()">
            </div>
          </ng-template>
        </div>
      </div>
    </form>

    <section class="card" *ngIf="editMode.active && editMode.data.customer">
      <div class="card-header card-header-rose card-header-icon">
				<div class="card-icon">
				<i class="material-icons">list</i>
				</div>
				<h4 class="card-title">Véhicules</h4>
			</div>
      <div class="card-body">
        <ul *ngIf="editMode.data.customer.vehicules && editMode.data.customer.vehicules.length > 0; else NoVehicule">
          <li *ngFor="let vehicule of this.editMode.data.customer.vehicules">
            {{vehicule.model}}
            <ul>
              <li>Immatriculation : {{vehicule.immatriculation}}</li>
              <li>Kilomètres : {{vehicule.kilometers}}km</li>
            </ul>
          </li>
        </ul>
        <ng-template #NoVehicule>
          Aucun véhicule existant.
        </ng-template>
      </div>
    </section>
  </div>
  <!-- end rigth side -->
</div>
