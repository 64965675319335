import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { EnumsService } from 'src/app/services/enums.service';
import { AlertService } from 'src/app/services/alert.service';
import { AgenciesService } from 'src/app/services/agencies.service';
import { Agency } from 'src/app/models/Agency';
import { LoadingService } from 'src/app/services/loading.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-edit-web-store-opening-times',
  templateUrl: './edit-web-store-opening-times.component.html',
  styleUrls: ['./edit-web-store-opening-times.component.scss']
})
export class EditWebStoreOpeningTimesComponent implements OnInit {
  public type: string; // WEB OR STORE
  public agency: Agency;
  public loading: boolean = false;
  public openingTimes: Array<{
    day_of_week: number,
    morning: Array<string>,
    afternoon: Array<string>
  }>;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private enumsService: EnumsService,
    private alertService: AlertService,
    private agenciesService: AgenciesService,
    public loadingService: LoadingService,
    public policiesService: PoliciesService,
    private authService: AuthService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
    }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.type =  this.router.url.split('/')[5]; // WEB OR STORE
        this.agenciesService.getById(this.route.snapshot.params.id)
          .then((agency: any) => {
            this.agency = agency;
            this.agenciesService.getOpeningTimes(this.route.snapshot.params.id, this.type)
              .then((response: Array<any>) => {
                this.loading = false;
                this.openingTimes = response;
                const res = [];
                response.forEach(op => {
                  const loopIndex = res.findIndex(opf => opf.day_of_week === op.day_of_week);
                  if (loopIndex !== -1) {
                    if (parseInt(op.during[0].split(':')[0]) < 12) { // compare first hour < 12 === morning
                      res[loopIndex].morning = op.during;
                    } else {
                      res[loopIndex].afternoon = op.during;
                    }
                  } else {
                    const morning = (parseInt(op.during[0].split(':')[0]) < 12) ? op.during : [];
                    const afternoon = (parseInt(op.during[0].split(':')[0]) > 12) ? op.during : [];
                    res.push({
                      day_of_week: op.day_of_week,
                      morning,
                      afternoon
                    });
                  }
                });
                this.openingTimes = res;
              })
              .catch(err => {
                this.loading = false;
                this.alertService.error(err);
              });
            })
            .catch((err) => {
              this.loading = false;
              this.alertService.error(err);
            });
      }
    });
  }

  getOpeningTimes(day) {
    const res = this.openingTimes.find(op => op.day_of_week === day);
    return res;
  }

  update(day, part: string, openOrClose: number, $event) {
    const index = this.openingTimes.findIndex(ot => ot.day_of_week === day);
    if (index !== -1) { // Exist
      if (part === 'morning') {
        this.openingTimes[index].morning[openOrClose] = $event.target.value;
      }
      if (part === 'afternoon') {
        this.openingTimes[index].afternoon[openOrClose] = $event.target.value;
      }
    } else { // Not exist (create a new openingTimes)
      const value = (openOrClose === 0) ? [$event.target.value, ''] : ['', $event.target.value];

      if (part === 'morning') {
        this.openingTimes.push({day_of_week: day, morning: value, afternoon: ['', '']});
      }
      if (part === 'afternoon') {
        this.openingTimes.push({day_of_week: day, morning: ['', ''], afternoon: value});
      }
    }
  }

  onSubmit() {
    this.agenciesService.updateOpeningTime(this.route.snapshot.params.id, this.type, this.openingTimes)
      .then(() => {
        this.alertService.success('Horaires changées !');
      })
      .catch(err => {
        this.alertService.error(err);
      });
  }

}
