<app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
          <li class="breadcrumb-item active"><a href="javascript:;">Slideshows</a></li>
        </ol>
      </nav>
    </ng-container>
</app-topbar>

<div class="row" *ngIf="!loading && authService.currentUserValue.policies.slideshow.create">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round"
       [routerLink]="['/website/settings/slideshows', 'edit']">
      <i class="material-icons">library_add</i>
      Créer un nouveau slideshow
    </a>
  </div>
</div>


<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">directions_car</i>
        </div>
        <h4 class="card-title">Liste des Slideshows</h4>
      </div>
      <div class="card-body">
        <app-filter-bar [filters]="filters"
          (changed)="setPage({offset: 0}, null, $event)">
          <p *ngIf="loadingList">chargement...</p>
        </app-filter-bar>
        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [externalPaging]="true"
            [externalSorting]="true"
            [loadingIndicator]="loadingList"
            [reorderable]="false"
            (page)="setPage($event)"
            (sort)="onSort($event)"
          >
            <ngx-datatable-column [resizeable]="false" name="ID" prop="id"  [width]="70">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Nom" prop="name" [width]="250">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <!-- ACTIONS -->
            <ngx-datatable-column [resizeable]="false" name="Actions" [sortable]="false" [width]="250">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                <a *ngIf="authService.currentUserValue.policies.slideshow.update"
                  [routerLink]="['/website/settings/slideshows/' ,'edit', row.id]"
                  type="button" class="btn btn-success"
                  title="Modifier le pneu">
                  <i class="material-icons">edit</i>
                </a>

                <button *ngIf="authService.currentUserValue.policies.slideshow.delete"
                        type="button" class="btn btn-danger"
                        (click)="onDelete(row.id)" title="Supprimer">
                  <i class="material-icons">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>

          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
