import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

import modules from '../../../../assets/modules.json';

import { CommercialActionsService } from 'src/app/services/commercial-actions.service';
import { AlertService } from 'src/app/services/alert.service';
import { WorkersService } from 'src/app/services/workers.service';
import { AuthService } from 'src/app/services/auth.service';
import { PoliciesService } from 'src/app/services/policies.service';

import { CommercialAction } from 'src/app/models/CommercialAction';
import { User } from 'src/app/models/User';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  public modules;
  public modulesStringList;
  public user: User;
  public adherentIds: Array<Number> = null;

  dataTable: any;

  public commercialActions: Array<CommercialAction> = [];
  public commercialActionsArchived: Array<CommercialAction> = [];
  public loading: Boolean = false;

  constructor(
    private workersService: WorkersService,
    private commercialActionsService: CommercialActionsService,
    private alertService: AlertService,
    public authService: AuthService,
    public policiesService: PoliciesService) {
      this.modules = modules;
      this.policiesService.currentUser = this.authService.currentUserValue;
    }

  ngOnInit(): void {
    if (this.authService.currentUserValue) {
      this.modules = modules;
      this.modulesStringList = Object.keys(modules);
      this.user = this.authService.currentUserValue;
    }
  }

  ngAfterContentInit() {
    this.loading = true;
    // Current commercial actions
    this.commercialActionsService.getAll()
      .then((response: Array<CommercialAction>) => {

        // SPLIT CURRENTS AND ARCHIVED
        this.commercialActions = response.filter((ac: CommercialAction) => new Date(ac.end_at) >= new Date());
        this.commercialActionsArchived = response.filter((ac: CommercialAction) => new Date(ac.end_at) < new Date());
        this.loading = false;

      })
      .catch((err) => {
        this.alertService.error(err);
        this.loading = false;
      });
  }

  canDelete(actionId) {
    this.user.adherents.forEach(adherent => {
      this.adherentIds.push(adherent.id);
    });
  }

  editAgreementChecking(row: CommercialAction) {
    return this.authService.currentUserValue.is_admin ? true : new Date(row.start_at) <= new Date();
  }

  downloadCharts(id) {
    this.workersService.startNewWorker('commercial_action_stats', {commercial_action_id: id})
      .then((response) => {
        this.alertService.success('Statistiques en cours d\'export, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

  onDelete(actionId, archived: boolean = false) {
    // CHECK POSSIBILITY
    let agreementCount: number = 0;

    if (agreementCount <= 0) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-info'
          },
          buttonsStyling: false,
          reverseButtons: true,
          focusConfirm: false
        });

        swalWithBootstrapButtons.fire({
          title: 'Êtes-vous sûr de vouloir supprimer cette action commerciale ?',
          text: 'Suppression irréversible.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Annuler',
          confirmButtonText: 'Confirmer'
        }).then((result) => {
          if (result.value) {
            this.commercialActionsService.delete(actionId)
            .then((response) => {
              // SUPPRESSION IN DOM
              if (archived) {
                this.commercialActionsArchived = this.commercialActionsArchived.filter(e => e.id !== actionId);
              } else {
                this.commercialActions = this.commercialActions.filter(e => e.id !== actionId);
              }
            })
            .catch((err) => {
              this.alertService.error(err);
            });
          }
        });
      } else {
        Swal.fire({
          title: 'Supression impossible',
          text: 'Des accords ont déjà été créés pour cette action commerciale.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok'
        });
      }

  }

  editable(actionId) {
    const index = this.commercialActions.findIndex(ac => ac.id === actionId);
    if (index !== -1) {
      return this.authService.currentUserValue.is_admin
        || this.authService.currentUserValue.adherents.findIndex(a => a.id === this.commercialActions[index].adherent_id) !== -1;
    }
  }

  editableArchive(actionId) {
    const index = this.commercialActionsArchived.findIndex(ac => ac.id === actionId);
    if (index !== -1) {
      return this.authService.currentUserValue.is_admin
        || this.authService.currentUserValue.adherents.findIndex(a => a.id === this.commercialActionsArchived[index].adherent_id) !== -1;
    }
  }
}
