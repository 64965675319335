import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AlertService } from 'src/app/services/alert.service';
import { PageBackgroundsService } from 'src/app/services/page-backgrounds.service';
import { PagesService } from 'src/app/services/pages.service';
import { PageBackground } from 'src/app/models/PageBackground';
import { EnumsService } from 'src/app/services/enums.service';

@Component({
  selector: 'app-page-backgrounds-edit',
  templateUrl: './page-backgrounds-edit.component.html',
  styleUrls: ['./page-backgrounds-edit.component.scss']
})
export class PageBackgroundsEditComponent implements OnInit {
  public loading: boolean = false;
  public pages: Array<any>;
  public preselectPages: number;
  public editFormGroup: UntypedFormGroup;
  public submitted: boolean = false;
  public editMode: any = {
    active: false,
    data: null
  };

  public fileLeft: string | File = null;
  public fileRight: string | File = null;
  public currentModule: {
    slug: string,
    name: string
  } = {
    slug: 'website',
    name: 'Site Profil Plus'
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public enumsService: EnumsService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private pageBackgroundsService: PageBackgroundsService,
    private pagesService: PagesService) {
      this.route.data.subscribe((data) => {
        if(data.moduleSlug && data.moduleName) {
          this.currentModule.slug = data.moduleSlug
          this.currentModule.name = data.moduleName
        }
      })
    }

  get f() { return this.editFormGroup.controls; }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        const website = this.currentModule.slug == 'job' ? 1 : 0
        this.pagesService.getAll(website).then((response: Array<any>) => {
          this.pages = response
          if (this.route.snapshot.params.page_background_id) {
            this.editMode.active = true;
            this.pageBackgroundsService.getById(this.route.snapshot.params.page_background_id)
              .then((response: PageBackground) => {
                this.editMode.data = response;
                this.editMode.data.fileLeft = (response.background_image_left) ? [{name: 'affichage de votre fichier', link: response.background_image_left}] : null;
                this.editMode.data.fileRight = (response.background_image_right) ? [{name: 'affichage de votre fichier', link: response.background_image_right}] : null;

                this.loading = false;
                this.editFormGroup = this.formBuilder.group(this.getNormalForm(response));

                if (response.page) {
                  this.preselectPages = this.pages.findIndex(a => a.id === response.page.id);
                }
                this.preSetWebsite()
              })
              .catch((err) => {
                this.loading = false;
                this.alertService.error(err);
              });
          } else {
            this.loading = false;
            this.editFormGroup = this.formBuilder.group(this.getNormalForm());
            this.preSetWebsite()
          }
        })
        .catch((err) => {
          this.loading = false;
          this.alertService.error(err);
        });
      }
    })
  }

  getNormalForm(data?: PageBackground) {
    return {
      apply_to_all: [data ? data.apply_to_all : false, null],
      url: [data ? data.url : '', null],
      universe: [data ? data.universe : 0, null],
      start_date: [data && data.start_date ? data.start_date.toString().split('T')[0] : null, null],
      end_date: [data && data.end_date ? data.end_date.toString().split('T')[0] : null, null],
      rank: [data ? data.rank : 0, null],
      page: [data && data.page ? data.page.id : null, null],
      website: [data ? data.website : 0, null]
    }
  }

  preSetWebsite() {
    // Pre set website field
    const website = this.enumsService.enums.websites.find(w => w.slug == this.currentModule.slug)
    if(website) {
      this.editFormGroup.controls.website.patchValue(website.id)
    }
  }

  onSelectPage($event) {
    this.editFormGroup.patchValue({page: $event.id});
    console.log($event);
  }

  checkValuePage($event) {
    if ($event === '') {
      this.editFormGroup.patchValue({page: null});
    }
  }

  onFileLeftSelected(files: Array<File>) {
    this.fileLeft = files ? files[0] : '';
    if (!files && this.editMode.data) {
      this.editMode.data.fileLeft = null;
    }
  }

  onFileRightSelected(files: Array<File>) {
    this.fileRight = files ? files[0] : '';
    if (!files && this.editMode.data) {
      this.editMode.data.fileRight = null;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const pageBackground = {
      ...this.editFormGroup.value,
      background_image_left: this.fileLeft,
      background_image_right: this.fileRight
    };
    if (this.editMode) {
      pageBackground.id = this.route.snapshot.params.page_background_id;
    }
    this.pageBackgroundsService.edit(pageBackground).then((response) => {
      this.router.navigate(['/' + (this.currentModule.slug == 'website' ? 'website/settings' : this.currentModule.slug) + '/page-backgrounds']);
      this.alertService.success((this.editMode && this.editMode) ? 'Modification enregistrée avec succès.' : 'Habillage de page enregistré avec succès.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

}
