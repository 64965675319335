import { Injectable } from '@angular/core';

import 'bootstrap-notify';
import { ApiService } from './api.service';

import parseFiltersForUrl from '../helpers/parseFiltersForUrl';
import { DeletedLoyaltyCard } from '../models/DeleteLoyaltyCard';


@Injectable({ providedIn: 'root' })
export class DeletedLoyaltyCardsService {
  constructor(private apiService: ApiService) {

  }

  getById(formId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('deleted_loyalty_cards/' + formId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });

    });
  }

  getAll(sortBy: { field: string, order: string } = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('deleted_loyalty_cards' + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '')
        + (filters ? '&' + parseFiltersForUrl(filters) : '')
      ).then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }


  getAllWithPagination(page: string | number, sortBy: { field: string, order: string } = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('deleted_loyalty_cards?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '')
        + (filters ? '&' + parseFiltersForUrl(filters) : '')
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(deleted_loyalty_card: DeletedLoyaltyCard) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();


      if (deleted_loyalty_card.id) {
        this.apiService.put('deleted_loyalty_cards/' + deleted_loyalty_card.id, formData)
          .then((response: DeletedLoyaltyCard) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        this.apiService.post('deleted_loyalty_cards', formData)
          .then((response: DeletedLoyaltyCard) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      }

    });
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('deleted_loyalty_cards/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
