import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SpecialOffersService } from 'src/app/services/special-offers.service';
import { PagesService } from 'src/app/services/pages.service';

import { SpecialOffer } from 'src/app/models/SpecialOffer';

import config from 'src/app/config';
import { Service } from 'src/app/models/Service';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public pages: Array<any>;
  public preselectPages: number;
  public loading: boolean = false;
  public editMode: {
    active: boolean,
    data: any
  } = {active: false, data: null};
  public submitted: boolean = false;
  public editFormGroup: UntypedFormGroup = null;

  public tire_offer_file: string | File = null;
  public special_offer_image_a: string | File = null;
  public special_offer_image_b: string | File = null;
  public special_offer_image_c: string | File = null;
  public special_offer_image_d: string | File = null;
  public special_offer_image_aa: string | File = null;
  public special_offer_image_bb: string | File = null;

  public ckEditorConfig = config.ckEditor;
  public services: Array<Service> = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public enumsService: EnumsService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private specialOffersService: SpecialOffersService,
    public loadingService: LoadingService,
    private pagesService: PagesService) {
      this.enumsService.observable.subscribe({
        complete: () => {
          this.pagesService.getAll().then((response: Array<any>) => {
            this.pages = response;
            if (this.route.snapshot.params.id) { // EDIT
              this.editMode.active = true;
              this.loading = true;
              this.specialOffersService.getById(this.route.snapshot.params.id)
                .then((response: SpecialOffer) => {
                  this.editMode.data = response;
                  this.editMode.data.tire_offer_file = (response.tire_offer_file) ? [{name: 'affichage de votre fichier', link: response.tire_offer_file}] : null;
                  this.editMode.data.special_offer_image_a = (response.special_offer_image_a) ? [{name: 'affichage de votre fichier', link: response.special_offer_image_a}] : null;
                  this.editMode.data.special_offer_image_b = (response.special_offer_image_b) ? [{name: 'affichage de votre fichier', link: response.special_offer_image_b}] : null;
                  this.editMode.data.special_offer_image_c = (response.special_offer_image_c) ? [{name: 'affichage de votre fichier', link: response.special_offer_image_c}] : null;
                  this.editMode.data.special_offer_image_d = (response.special_offer_image_d) ? [{name: 'affichage de votre fichier', link: response.special_offer_image_d}] : null;
                  this.editMode.data.special_offer_image_aa = (response.special_offer_image_aa) ? [{name: 'affichage de votre fichier', link: response.special_offer_image_aa}] : null;
                  this.editMode.data.special_offer_image_bb = (response.special_offer_image_bb) ? [{name: 'affichage de votre fichier', link: response.special_offer_image_bb}] : null;

                  this.loading = false;
                  this.editFormGroup = this.formBuilder.group({
                    name: [response.name, Validators.required],
                    slug: [response.slug, null],
                    universe: [response.universe ? response.universe : 0, null],
                    promo_type: [response.promo_type ? response.promo_type : 0, null],
                    start_date: [response.start_date ? response.start_date.toString().split('T')[0] : null, null],
                    limit_date: [response.limit_date ? response.limit_date.toString().split('T')[0] : null, null],
                    quantity: [response.quantity, null],
                    special_price: [response.special_price, null],
                    hide_promo_tag: [response.hide_promo_tag, null],
                    message: [response.message, null],
                    page: [response.page ? response.page.id : null, null],
                    services: [response.services ? [...response.services.map(x => x.id)] : [], null],
                    description_for_offer_of_type_quote_appointment: [response.description_for_offer_of_type_quote_appointment ? response.description_for_offer_of_type_quote_appointment : '', null],
                    agency_banner_url: [response.agency_banner_url ? response.agency_banner_url : '', null]
                  });

                  if (response.page) {
                    this.preselectPages = this.pages.findIndex(a => a.id === response.page.id);
                  }

                })
                .catch((err) => {
                  this.loading = false;
                  this.alertService.error(err);
                });
            } else {
              this.editFormGroup = this.formBuilder.group({
                name: [null, Validators.required],
                slug: [null, null],
                universe: [0, null],
                promo_type: [0, null],
                start_date: [null, null],
                limit_date: [null, null],
                quantity: [null, null],
                special_price: [null, null],
                hide_promo_tag: [false, null],
                message: [null, null],
                page: [null, null],
                services: [[], null],
                description_for_offer_of_type_quote_appointment: ['' , null],
                agency_banner_url: ['' , null]
              });
            }
          })
          .catch((err) => {
            this.alertService.error(err);
          });

          this.services = this.enumsService.enums.services.filter(s => s.b2c_quotation === true);
        }
      });

    }

    get f() {
      return this.editFormGroup.controls;
    }

  ngOnInit(): void {}

  onSelectPage($event) {
    this.editFormGroup.patchValue({page: $event.id});
  }

  checkValuePage($event) {
    if ($event === '') {
      this.editFormGroup.patchValue({page: null});
    }
  }

  onFileTireOfferFileSelected(files: Array<File>) {
    this.tire_offer_file = files ? files[0] : '';
    if (this.editMode.data) {
      this.editMode.data.tire_offer_file = null;
    }
  }

  onFileSpecialOfferImageASelected(files: Array<File>) {
    this.special_offer_image_a = files ? files[0] : '';
    if (this.editMode.data) {
      this.editMode.data.special_offer_image_a = null;
    }
  }

  onFileSpecialOfferImageBSelected(files: Array<File>) {
    this.special_offer_image_b = files ? files[0] : '';
    if (this.editMode.data) {
      this.editMode.data.special_offer_image_b = null;
    }
  }

  onFileSpecialOfferImageCSelected(files: Array<File>) {
    this.special_offer_image_c = files ? files[0] : '';
    if (this.editMode.data) {
      this.editMode.data.special_offer_image_c = null;
    }
  }

  onFileSpecialOfferImageDSelected(files: Array<File>) {
    this.special_offer_image_d = files ? files[0] : '';
    if (this.editMode.data) {
      this.editMode.data.special_offer_image_d = null;
    }
  }

  onFileSpecialOfferImageAaSelected(files: Array<File>) {
    this.special_offer_image_aa = files ? files[0] : '';
    if (this.editMode.data) {
      this.editMode.data.special_offer_image_aa = null;
    }
  }

  onFileSpecialOfferImageBbSelected(files: Array<File>) {
    this.special_offer_image_bb = files ? files[0] : '';
    if (this.editMode.data) {
      this.editMode.data.special_offer_image_bb = null;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const specialOffer = {
      ...this.editFormGroup.value,
      tire_offer_file: this.tire_offer_file,
      special_offer_image_a: this.special_offer_image_a,
      special_offer_image_b: this.special_offer_image_b,
      special_offer_image_c: this.special_offer_image_c,
      special_offer_image_d: this.special_offer_image_d,
      special_offer_image_aa: this.special_offer_image_aa,
      special_offer_image_bb: this.special_offer_image_bb
    };
    if (this.editMode) {
      specialOffer.id = this.route.snapshot.params.id;
    }
    this.specialOffersService.edit(specialOffer).then((response: SpecialOffer) => {
      this.router.navigate(['/website/special-offers', 'edit', response.id.toString()]);
      this.alertService.success((this.editMode && this.editMode) ? 'Modification enregistrée avec succès.' : 'Offre spéciale créée');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

}
