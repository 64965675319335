<ng-container *ngIf="!loading; else templateLoading">
  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
          <li class="breadcrumb-item"><a href="javascript:;">Gestion des pneus</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website/tires/highlight-categories']">Catégories de mise en avant</a></li>
          <li *ngIf="editMode.data" class="breadcrumb-item active">
            <a href="javascript:;">Édition de la catégorie de mise en avant : {{editMode.data.name}}</a>
          </li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">directions_car</i>
          </div>
            <h4 *ngIf="editMode.data" class="card-title">Édition de la catégorie de mise en avant : <strong class="description">{{editMode.data.name}}</strong></h4>
        </div>


        <div class="card-body" *ngIf="editFormGroup">
          <form class="form" [formGroup]="editFormGroup">
            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group is-required"
                [ngClass]="{ 'has-danger': submitted && f.name.errors }">
                <label for="name" class="bmd-label-floating">Nom</label>
                <input type="text" class="form-control" id="name"
                formControlName="name">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group"
                [ngClass]="{ 'has-danger': submitted && f.rank.errors }">
                <label for="rank" class="bmd-label-floating">Ordre</label>
                <input type="text" class="form-control" id="rank" formControlName="rank">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-group"
                [ngClass]="{ 'has-danger': submitted && f.brands.errors }">
                <label for="">Marques :</label><br>
                <div class="bootstrap-tagsinput info-badge">
                  <ng-container *ngFor="let brand of selectedBrands; let j = index">
                    <span class="tag badge">{{brand.name}}<span data-role="remove" (click)="onRemoveBrand(brand)"></span></span>
                  </ng-container>
                </div>
                <app-datalist
                  label="Sélectionner des marques"
                  (selected)="onSelectedBrand($event)"
                  [items]="enumsService.enums.brands"
                  [restOnSelect]="true"
                  [property]="'name'">
                </app-datalist>
              </div>
            </div>

            <button [disabled]="editFormGroup.invalid" *ngIf="!loadingService.loading; else templateSubmit"
              class="ppp-button ppp-button-black btn btn-primary btn-round mt-4"
              (click)="onSubmit()">
              Mettre à jour
            </button>
            <ng-template #templateSubmit>
              <button
                disabled="true"
                class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                ...
              </button>
            </ng-template>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
