<app-topbar moduleSlug="myprofilplus">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website', 'file-mapping']">Mapping de fichiers</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Créer un mapping de fichiers</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>
<div class="row">
  <div class="col-md-12">
    <div class="card">

      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">find_in_page</i>
        </div>
        <h4 class="card-title">Création d'un mapping de fichiers</h4>
      </div>

      <div class="card-body">

        <div class="col-lg-4 col-md-12 alert alert-danger">
          Attention, le coût des calculs peut être important selon la quantité de clients étudiés.
        </div>

        <form class="form" [formGroup]="editFormGroup" *ngIf="editFormGroup">

          <!-- FIELDS -->
          <div class="col-lg-6 col-md-12" style="padding:0;">
            <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.radius.errors }">
              <label for="actions" class="bmd-label-floating">Rayon de recherche (pré-filtre)</label>
              <input type="text" class="form-control" name="radius" id="radius" formControlName="radius">
                <br>
            </p>
          </div>
          <div class="col-lg-6 col-md-12" style="padding:0;">
            <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.number_of_results.errors }">
              <label for="actions" class="bmd-label-floating">Nombre de résultats</label>
              <input type="number" min="1" max="5" step="1" class="form-control" name="number_of_results" id="number_of_results" formControlName="number_of_results">
                <br>
            </p>
          </div>
          <div class="col-lg-6 col-md-12" style="padding:0;">
            <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.mapping_file_1.errors }">
              <label for="actions" class="bmd-label-floating">Fichier clients</label>
              <app-drag-drop-file-input [forceTypes]="['xlsx']" [limit]="1" appDragDrop [list]="null" (filesSelected)="onFile1Selected($event)"></app-drag-drop-file-input>
            </p>
          </div>
          <div class="col-lg-6 col-md-12" style="padding:0;">
            <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.mapping_file_2.errors }">
              <label for="actions" class="bmd-label-floating">Fichier agences</label>
              <app-drag-drop-file-input [forceTypes]="['xlsx']" [limit]="1" appDragDrop [list]="null" (filesSelected)="onFile2Selected($event)"></app-drag-drop-file-input>
            </p>
          </div>

          <!-- SUBMIT BUTTON -->
          <button [disabled]="editFormGroup.invalid"  *ngIf="!loadingService.loading; else templateSubmit"
            class="ppp-button ppp-button-black btn btn-primary btn-round mt-4"
            (click)="onSubmit()">
            Créer
          </button>
          <ng-template #templateSubmit>
            <button
              disabled="true"
              class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
              ...
            </button>
          </ng-template>

        </form>
      </div>
    </div>
  </div>
</div>
