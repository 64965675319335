import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from 'src/app/models/Customer';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { CustomersService } from 'src/app/services/customers.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  public editFormGroup: UntypedFormGroup;
  public editMode: any = {
    active: false,
    data: null
  };
  public loading: Boolean = false;
  public submitted: Boolean = false;

  public preselectProCompany;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public enumsService: EnumsService,
    private alertService: AlertService,
    public loadingService: LoadingService,
    public authService: AuthService,
    private customersService: CustomersService) {
      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          if (this.route.snapshot.params.id) { // EDIT
            this.editMode.active = true;
            this.customersService.getById(this.route.snapshot.params.id)
              .then((response: Customer) => {
                this.editMode.data = response;

                if (response.pro_company && response.pro_company.id) {
                  this.preselectProCompany = this.enumsService.enums.pro_companies
                  .findIndex(c => c.id === response.pro_company.id)
                }

                this.editFormGroup = this.formBuilder.group({
                  first_name: [response.first_name, null],
                  last_name: [response.last_name, null],
                  civility: [response.civility ? response.civility : '0', null],
                  company: [response.company, null],
                  mob_phone: [response.mob_phone, null],
                  email: [response.email, Validators.required],
                  birthday: [response.birthday, null],
                  shipping_address_street_name: [response.shipping_address ? response.shipping_address.street_name : null, null],
                  shipping_address_zipcode: [response.shipping_address ? response.shipping_address.zipcode : null, null],
                  shipping_address_city: [response.shipping_address ? response.shipping_address.city : null, null],
                  phone: [response.phone, null],
                  optin_myprofilplus: [response.optin_myprofilplus, null],
                  pro_company_id: [response.pro_company ? response.pro_company.id : null, null],
                  optin_promo: [response.optin_promo, null],
                  deactivated: [response.deactivated, null],
                  pro: [response.pro, null],
                });
                this.loading = false;
              })
              .catch((err) => {
                this.alertService.error(err);
                this.loading = false;
              });
          }
          else { // CREATE
            this.editFormGroup = this.formBuilder.group({
              first_name: [],
              last_name: [],
              civility: ['0', null],
              company: [],
              mob_phone: [],
              email: [null, Validators.required],
              birthday: [],
              shipping_address_street_name: [],
              shipping_address_zipcode: [],
              shipping_address_city: [],
              phone: [],
              optin_myprofilplus: [],
              pro_company_id: [],
              optin_promo: [],
              deactivated: [],
              pro: [],
            });
            this.loading = false;
          }
        }
      });

    }

  ngOnInit(): void {
  }

  get f() { return this.editFormGroup.controls; }

  onSelectedProCompany($event) {
    this.editFormGroup.patchValue({pro_company_id: $event.id})
  }

  onResetProCompany($event) {
    if ($event === "") {
      this.editFormGroup.patchValue({pro_company_id: ''})
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    const customer: Customer = {
      ...this.editFormGroup.value
    };

    // Edit mode
    if (this.editMode.active) {
      customer.id = this.route.snapshot.params.id;
    }

    this.customersService.edit(customer).then(() => {
      this.router.navigate(['/website/customers']);
      this.alertService.success((this.editMode && this.editMode.active) ? 'Modification enregistrée avec succès.' : 'Agence enregistrée avec succès.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

}
