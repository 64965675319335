<ng-container *ngIf="!loading; else templateElse">
  <app-topbar moduleSlug="myprofilplus">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/myprofilplus']">MyProfil+</a></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Cartes de fidélité</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">list</i>
          </div>
          <h4 class="card-title">Liste des cartes de fidélité <span>({{this.datatable.count}} éléments)</span>
          </h4>
        </div>
        <div class="card-body">

          <div class="table-responsive">
            <ngx-datatable
                #datatable
                class="material"
                [rows]="items"
                [columnMode]="'standard'"
                [rowHeight]="'auto'"
                [headerHeight]="50"
                [footerHeight]="50"
                [externalPaging]="true"
                [externalSorting]="true"
                [loadingIndicator]="loadingList"
                [reorderable]="false"
                (page)="setPage($event)"
                (sort)="onSort($event)"
              >
                <ngx-datatable-column [resizeable]="false" name="ID" prop="id" [width]="75">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [resizeable]="false" name="N° carte de fidélité" prop="number" [width]="200">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{value}}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [resizeable]="false" name="Adhérent" prop="adherent" [width]="240">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{ value.name }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [resizeable]="false" name="Société" prop="loyalty_card_organization" [width]="210">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{ value.name }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [resizeable]="false" name="Client" prop="customer" [width]="200">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{value.first_name}} {{value.last_name}}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [resizeable]="false" name="Date de création" prop="created_at" [width]="190">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{ value  | date:'EEEE dd MMMM y' }}
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column [resizeable]="false" name="Actions" [sortable]="false">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <a [routerLink]="[ '/myprofilplus/loyalty-cards', row.id, 'consultation' ]" class="btn btn-success" title="Consulter">
                        <i class="material-icons">visibility</i>
                    </a>
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>
<ng-template #templateElse>
  <app-loading></app-loading>
</ng-template>
