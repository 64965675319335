<ng-container *ngIf="!loading; else templateLoading">

  <app-topbar moduleSlug="profilplus-agences">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences']">Profil Plus Agences</a></li>
          <li class="breadcrumb-item">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies'] : ['/profilplus-agences/closed-agencies']">
              Liste des agences {{ router.url.split('/').includes('open-agencies') ? 'ouvertes' : 'fermées' }}
            </a>
          </li>
          <li class="breadcrumb-item" *ngIf="agency">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit', agency.id] : ['/profilplus-agences/closed-agencies/edit', agency.id]">
              Agence {{agency.name}}
            </a>
          </li>
          <li class="breadcrumb-item" *ngIf="agency">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/correspondants/edit', agency.id] : ['/profilplus-agences/closed-agencies/correspondants/edit', agency.id]">
              Liste des correspondants
            </a>
          </li>
          <li class="breadcrumb-item active"><a href="javascript:;">
            <ng-container *ngIf="editMode.active && editMode.data; else templateElse">Éditer un correspondant</ng-container>
            <ng-template #templateElse>Créer un correspondant</ng-template>
          </a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <ng-container *ngIf="editFormGroup">
    <div class="ppp-correspondant-edition row" *ngIf="!editMode.active ||(editMode.active && editMode.data)">
      <div class="col-lg-12">
        <form class="form" [formGroup]="editFormGroup">
          <div class="card">

            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">people</i>
              </div>
              <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
                <h4 class="card-title">Édition d'un correspondant</h4>
              </ng-container>
              <ng-template #templateElse>
                <h4 class="card-title">Création d'un nouveau correspondant</h4>
              </ng-template>
            </div>

            <div class="card-body">
              <!-- BUTTONS -->
              <div>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/', agency.id] : ['/profilplus-agences/closed-agencies/edit/', agency.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">store</i> Fiche agence
                </a>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/skills/', agency.id] : ['/profilplus-agences/closed-agencies/edit/skills/', agency.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">build</i> Métiers
                </a>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/services/', agency.id] : ['/profilplus-agences/closed-agencies/edit/services/', agency.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">playlist_add_check</i> Services
                </a>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/close-dates/', agency.id] : ['/profilplus-agences/closed-agencies/edit/close-dates/', agency.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">report</i> Dates fermeture
                </a>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/opening-times/store/', agency.id] : ['/profilplus-agences/closed-agencies/edit/opening-times/store/', agency.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">store</i> Horaires magasin
                </a>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/correspondants/edit/', agency.id] : ['/profilplus-agences/closed-agencies/correspondants/edit/', agency.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">people</i> Correspondants
                </a>
              </div>

              <div class="col-lg-4 col-md-12 mb-4" style="padding:0;">
                <div class="form-group is-required" [ngClass]="{'has-danger': submitted && f.role.errors }">
                  <app-datalist *ngIf="roles"
                    [items]="roles"
                    [preselect]="preselectRole"
                    [property]="'name'"
                    label="Rôle"
                    (selected)="onSelectRole($event)"
                    (typed)=checkValue($event)
                  >
                  </app-datalist>
                </div>
              </div>

              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group is-required"  [ngClass]="{ 'has-danger': submitted && f.lastname.errors }">
                  <label for="lastname" class="bmd-label-floating">Nom</label>
                  <input type="text" class="form-control" id="lastname" formControlName="lastname">
                </p>
              </div>

              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group is-required"  [ngClass]="{ 'has-danger': submitted && f.firstname.errors }">
                  <label for="firstname" class="bmd-label-floating">Prénom</label>
                  <input type="text" class="form-control" id="firstname" formControlName="firstname">
                </p>
              </div>

              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group is-required"  [ngClass]="{ 'has-danger': submitted && f.email.errors }">
                  <label for="email" class="bmd-label-floating">Email</label>
                  <input name="email" type="email" class="form-control" id="email" formControlName="email" required="true">
                  <label class="error" for="email" *ngIf="f.email.errors && f.email.errors.email">Veuillez entrer un email valide.</label>
                </p>
              </div>

              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group"  [ngClass]="{ 'has-danger': submitted && f.phone_number.errors }">
                  <label for="phone_number" class="bmd-label-floating">Numéro de téléphone</label>
                  <input type="phone" maxlength="10" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="phone_number" formControlName="phone_number">
                </p>
              </div>

              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group"  [ngClass]="{ 'has-danger': submitted && f.phone_number2.errors }">
                  <label for="phone_number2" class="bmd-label-floating">Numéro de téléphone 2</label>
                  <input type="phone" maxlength="10" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="phone_number2" formControlName="phone_number2">
                </p>
              </div>


              <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/correspondants/edit/', agency.id] : ['/profilplus-agences/closed-agencies/correspondants/edit/', agency.id]"
                  type="button"
                  class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
                Retour à la liste des correspondants
              </a>
              <button [disabled]="editFormGroup.invalid"  *ngIf="!loadingService.loading; else templateSubmit"
                class="ppp-button ppp-button-black btn btn-primary btn-round"
                (click)="onSubmit()">
                <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
                <ng-template #creationTemplateText>Créer</ng-template>
              </button>
              <ng-template #templateSubmit>
                <button
                  disabled="true"
                  class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                  ...
                </button>
              </ng-template>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-container>

</ng-container>

<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
