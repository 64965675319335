import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

import { SpecialOffer } from '../models/SpecialOffer';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';

@Injectable({ providedIn: 'root' })
export class SpecialOffersService {
  constructor(private apiService: ApiService) {}

  getById(specialOfferId: string | number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('special_offers/' + specialOfferId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('special_offers/all')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllWithPagination(page: string | number, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('special_offers?page=' + page
      + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
      + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async formatData(specialOffer: SpecialOffer) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append('special_offer:name', specialOffer.name ? specialOffer.name : '');
      formData.append('special_offer:slug', specialOffer.slug ? specialOffer.slug : '');
      formData.append('special_offer:universe', specialOffer.universe.toString() ? specialOffer.universe.toString() : '0');
      formData.append('special_offer:promo_type', specialOffer.promo_type ? specialOffer.promo_type.toString() : '0');
      formData.append('special_offer:start_date', specialOffer.start_date ? specialOffer.start_date.toString() : '');
      formData.append('special_offer:limit_date', specialOffer.limit_date ? specialOffer.limit_date.toString() : '');
      formData.append('special_offer:quantity', specialOffer.quantity ? specialOffer.quantity.toString() : '0');
      formData.append('special_offer:special_price', specialOffer.special_price ? specialOffer.special_price.toString() : '0');
      formData.append('special_offer:hide_promo_tag', specialOffer.hide_promo_tag ? specialOffer.hide_promo_tag.toString() : 'false');
      formData.append('special_offer:message', specialOffer.message ? specialOffer.message : '');
      formData.append('special_offer:services', specialOffer.services ? specialOffer.services.join(',') : '');
      formData.append('special_offer:description_for_offer_of_type_quote_appointment',
        specialOffer.description_for_offer_of_type_quote_appointment ? specialOffer.description_for_offer_of_type_quote_appointment : '');
      formData.append('special_offer:agency_banner_url', specialOffer.agency_banner_url ? specialOffer.agency_banner_url : '');

      if (specialOffer.page && specialOffer.page.toString()) {
        formData.append('special_offer:page_id', specialOffer.page.toString());
      }

      if (specialOffer.tire_offer_file !== null) {
        formData.append('special_offer:tire_offer_file', specialOffer.tire_offer_file ? specialOffer.tire_offer_file : '');
      }

      if (specialOffer.special_offer_image_a !== null) {
        formData.append('special_offer:special_offer_image_a', specialOffer.special_offer_image_a ? specialOffer.special_offer_image_a : '');
      }

      if (specialOffer.special_offer_image_b !== null) {
        formData.append('special_offer:special_offer_image_b', specialOffer.special_offer_image_b ? specialOffer.special_offer_image_b : '');
      }

      if (specialOffer.special_offer_image_c !== null) {
        formData.append('special_offer:special_offer_image_c', specialOffer.special_offer_image_c ? specialOffer.special_offer_image_c : '');
      }

      if (specialOffer.special_offer_image_d !== null) {
        formData.append('special_offer:special_offer_image_d', specialOffer.special_offer_image_d ? specialOffer.special_offer_image_d : '');
      }

      if (specialOffer.special_offer_image_aa !== null) {
        formData.append('special_offer:special_offer_image_aa', specialOffer.special_offer_image_aa ? specialOffer.special_offer_image_aa : '');
      }

      if (specialOffer.special_offer_image_bb !== null) {
        formData.append('special_offer:special_offer_image_bb', specialOffer.special_offer_image_bb ? specialOffer.special_offer_image_bb : '');
      }
      resolve(formData);
    });
  }

  edit(specialOffer) {
    return new Promise((resolve, reject) => {
      this.formatData(specialOffer).then((formData) => {
        if (specialOffer.id) {
          this.apiService.put('special_offers/' + specialOffer.id, formData)
          .then((response: any) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
        } else {
          this.apiService.post('special_offers', formData)
          .then((response: any) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
        }
      });
    });
  }

  delete(specialOfferId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('special_offers/' + specialOfferId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
