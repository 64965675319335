import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Customer } from 'src/app/models/Customer';

import { AlertService } from 'src/app/services/alert.service';
import { CustomersService } from 'src/app/services/customers.service';
import { EnumsService } from 'src/app/services/enums.service';

import { AuthService } from 'src/app/services/auth.service';
import { PoliciesService } from 'src/app/services/policies.service';
import {WorkersService} from '../../../services/workers.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  items: Array<Customer> = null;

  public loading: boolean = false;
  public loadingList: boolean = false;
  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [
    {
      name: 'Nom',
      slug: 'last_name',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Prénom',
      slug: 'first_name',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Email',
      slug: 'email',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Avec ou Sans carte',
      slug: 'loyalty_card_presence',
      type: 'radio',
      class: 'col-4',
      options: [
        {
          name: 'Tous',
          value: null,
          default: true
        },
        {
          name: 'Avec',
          value: 'true'
        },
        {
          name: 'Sans',
          value: 'false'
        }
      ]
    },
    {
      name: 'Opt-in Prospects',
      slug: 'optin_promo',
      type: 'checkbox',
      class: 'col-2',
      value: null
    },
    {
      name: 'Opt-in MyProfil+',
      slug: 'optin_myprofilplus',
      type: 'checkbox',
      class: 'col-2',
      value: null
    }
  ];

  constructor(
    private cd: ChangeDetectorRef,
    private alertService: AlertService,
    public enumsService: EnumsService,
    public customersService: CustomersService,
    public authService: AuthService,
    private workersService: WorkersService,
    public policiesService: PoliciesService) {
    this.policiesService.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.filters.unshift({
          name: 'Entreprise PRO',
          slug: 'pro_company',
          type: 'autocomplete',
          class: 'col-2',
          selected_emit_property: 'name',
          options: this.enumsService.enums.pro_companies
        });
        this.loading = false;
      }
    });
  }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  exportCustomers() {
    this.workersService.startNewWorker('export_customers', this.filtersSelected).then((response) => {
      this.alertService.success('Export de la liste des clients, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

  onDelete(id: string | number) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce client ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText:  'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.customersService.delete(id)
        .then(() => {
          const index = this.items.findIndex(e => e.id === id);
          if (index !== -1) {
            this.items.splice(index, 1);
            this.items = [...this.items];
            this.alertService.success('Client supprimé avec succès.');
          }
        })
        .catch((err) => {
          this.alertService.error(err);
        });
      }
    });
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;

    this.customersService.getAllWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }
}
