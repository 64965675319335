import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { ProfilplusSiteRoutingModule } from './profilplus-site-routing.module';
import { ProfilplusSiteComponent } from './profilplus-site.component';

import { AppSettingsComponent } from './app-settings/app-settings.component';
import { AppSettingsEditComponent } from './app-settings/app-settings-edit/app-settings-edit.component';

import { AppointmentConfigurationsComponent } from './appointment-configurations/appointment-configurations.component';
import { AppointmentConfigurationsEditComponent } from './appointment-configurations/appointment-configurations-edit/appointment-configurations-edit.component';

import { AppointmentTypesComponent } from './appointment-types/appointment-types.component';
import { AppointmentTypesEditComponent } from './appointment-types/appointment-types-edit/appointment-types-edit.component';

import { PageBackgroundsComponent } from '../common/page-backgrounds/page-backgrounds.component';
import { PageBackgroundsEditComponent } from '../common/page-backgrounds/page-backgrounds-edit/page-backgrounds-edit.component';

import { CustomersComponent } from './customers/customers.component';
import { EditComponent as CustomerEditComponent } from './customers/edit/edit.component';

import { CmsComponent } from '../common/cms/cms.component';
import { EditComponent as CmsEditComponent } from '../common/cms/edit/edit.component';
import { PageAttachmentsComponent } from '../common/cms/page-attachments/page-attachments.component';
import { PageAnswersComponent } from '../common/cms/page-answers/page-answers.component';

import { QuoteRequestsComponent } from './quote-requests/quote-requests.component';

import { ShowComponent } from './show/show.component';

import { SpecialOffersComponent } from './special-offers/special-offers.component';
import { EditComponent as SpecialOffersEditComponent } from './special-offers/edit/edit.component';

import { ProfilplusFormsComponent } from './profilplus-forms/profilplus-forms.component';
import { EditComponent as ProfilplusFormsEditComponent } from './profilplus-forms/edit/edit.component';

import { FileMappingComponent } from './file-mapping/file-mapping.component';
import { EditComponent as FileMappingEditComponent} from './file-mapping/edit/edit.component';

import { VehiculesComponent } from './vehicules/vehicules.component';
import { EditComponent as VehiculesEditComponent } from './vehicules/edit/edit.component';

import { CustomerEntreprisesComponent } from './customer-entreprises/customer-entreprises.component';
import { EditComponent as CustomerEntreprisesEditComponent } from './customer-entreprises/edit/edit.component';

import { TiresComponent } from './tires/tires.component';
import { EditComponent as TireEditComponent } from './tires/edit/edit.component';

import { BrandsComponent } from './tires/brands/brands.component';
import { EditComponent as BrandEditComponent} from './tires/brands/edit/edit.component';

import { TireModelsComponent } from './tires/tire-models/tire-models.component';
import { EditComponent as TireModelEditComponent} from './tires/tire-models/edit/edit.component';

import { BrandInformationsComponent } from './tires/brand-informations/brand-informations.component';
import { EditComponent as BrandInformationsEditComponent } from './tires/brand-informations/edit/edit.component';

import { HighlightCategoriesComponent } from './tires/highlight-categories/highlight-categories.component';
import { EditComponent as HighlightCategoryEditComponent } from './tires/highlight-categories/edit/edit.component';

import { HighlightRulesComponent } from './tires/highlight-rules/highlight-rules.component';
import { EditComponent as HighlightRulesEditComponent } from './tires/highlight-rules/edit/edit.component';

import { OrdersComponent } from './orders/orders.component';
import { CustomersReviewsComponent } from './customers-reviews/customers-reviews.component';
import { EditComponent as CustomersReviewsEditComponent } from './customers-reviews/edit/edit.component';


import { FileManagerComponent } from './file-manager/file-manager.component';
import { SlideshowsComponent } from './slideshows/slideshows.component';
import { EditComponent as SlideshowsEdit } from './slideshows/edit/edit.component';
import { QuestionsComponent } from './tires/questions/questions.component';
import { EditComponent as QuestionsEdit } from './tires/questions/edit/edit.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { DeletedLoyaltyCardsComponent } from './deleted-loyalty-cards/deleted-loyalty-cards.component';

@NgModule({
  imports:
  [
    SharedModule,
    CommonModule,
    ProfilplusSiteRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot()
  ],
  declarations: [
    ProfilplusSiteComponent,
    AppSettingsComponent,
    AppSettingsEditComponent,
    AppointmentConfigurationsComponent,
    AppointmentConfigurationsEditComponent,
    AppointmentTypesComponent,
    AppointmentTypesEditComponent,
    PageBackgroundsComponent,
    PageBackgroundsEditComponent,
    CustomersComponent,
    CustomerEditComponent,
    CmsComponent,
    CmsEditComponent,
    QuoteRequestsComponent,
    ShowComponent,
    PageAttachmentsComponent,
    PageAnswersComponent,
    SpecialOffersComponent,
    SpecialOffersEditComponent,
    ProfilplusFormsComponent,
    ProfilplusFormsEditComponent,
    FileMappingComponent,
    FileMappingEditComponent,
    VehiculesComponent,
    VehiculesEditComponent,
    CustomerEntreprisesComponent,
    CustomerEntreprisesEditComponent,
    TiresComponent,
    TireEditComponent,
    BrandsComponent,
    TireModelsComponent,
    BrandInformationsComponent,
    HighlightCategoriesComponent,
    HighlightRulesComponent,
    BrandEditComponent,
    TireModelEditComponent,
    HighlightRulesEditComponent,
    HighlightCategoryEditComponent,
    BrandInformationsEditComponent,
    OrdersComponent,
    FileManagerComponent,
    CustomersReviewsComponent,
    CustomersReviewsEditComponent,
    SlideshowsComponent,
    SlideshowsEdit,
    QuestionsComponent,
    QuestionsEdit,
    AppointmentsComponent,
    DeletedLoyaltyCardsComponent
  ],
  exports: [
    ProfilplusSiteComponent
  ],
  providers: []
})
export class ProfilplusSiteModule {}
