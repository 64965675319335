import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoyaltyCardOrdersService } from 'src/app/services/loyalty-card-orders.service';
import { AgenciesService } from 'src/app/services/agencies.service';

import { LoyaltyCardOrder } from 'src/app/models/LoyaltyCardOrder';

@Component({
  selector: 'app-loyalty-card-orders-edit',
  templateUrl: './loyalty-card-orders-edit.component.html',
  styleUrls: ['./loyalty-card-orders-edit.component.scss']
})
export class LoyaltyCardOrdersEditComponent implements OnInit {
  public agencies: Array<any>;
  public preselectAgencies: number;
  public editFormGroup: UntypedFormGroup;
  public submitted: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private enumsService: EnumsService,
    private loyaltyCardOrdersService: LoyaltyCardOrdersService,
    private agenciesService: AgenciesService) {

      this.enumsService.observable.subscribe({
        complete: () => {
          this.agenciesService.getAll().then((responseAgencies: Array<any>) => {
            this.agencies = responseAgencies;
            this.editFormGroup = this.formBuilder.group({
              amount: [null, [Validators.required, Validators.min(-9999), Validators.max(9999)]],
              agency: [null, Validators.required]
            });
          })
          .catch((err) => {
            this.alertService.error(err);
          });
        }
      });
    }

  ngOnInit(): void {}

  get f() { return this.editFormGroup.controls; }

  onSelectAgency($event) {
    this.editFormGroup.patchValue({agency: $event.id});
  }

  checkValue($event) {
    if ($event === '') {
      this.editFormGroup.patchValue({agency: null});
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const loyaltyCardOrder = {
      ...this.editFormGroup.value
    };

    this.loyaltyCardOrdersService.edit(loyaltyCardOrder)
      .then((response) => {
        this.router.navigate(['/myprofilplus/loyalty-card-orders']);
        this.alertService.success('Inventaire Cartes / Agences enregistré avec succès.');
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

}
