import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { EnumsService } from 'src/app/services/enums.service';
import { AlertService } from 'src/app/services/alert.service';
import { AgenciesService } from 'src/app/services/agencies.service';
import { LoadingService } from 'src/app/services/loading.service';

import { Agency } from 'src/app/models/Agency';
@Component({
  selector: 'app-edit-correspondant',
  templateUrl: './edit-correspondant.component.html',
  styleUrls: ['./edit-correspondant.component.scss']
})
export class EditCorrespondantComponent implements OnInit {
  public agency: Agency = null;
  public loading: boolean = false;
  public editFormGroup: UntypedFormGroup;
  public submitted: Boolean = false;
  public roles = [
    {name: ''},
    {name: 'Commercial agricole'},
    {name: 'Responsable Marketing'},
    {name: 'Responsable PL'},
    {name: 'Devis TC4'},
    {name: 'Responsable Agri'},
    {name: 'Responsable GC'},
    {name: 'Responsable Manut'}
  ];
  public preselectRole: number;
  public editMode: any = {
    active: false,
    data: null
  };

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private enumsService: EnumsService,
    private alertService: AlertService,
    private agenciesService: AgenciesService,
    public loadingService: LoadingService) {}

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        if (this.router.url.split('/').includes('open-agencies')) {
          this.agency = this.enumsService.enums.agencies.find(a => a.id === parseInt(this.route.snapshot.params.agency_id));
        } else {
          this.agency = this.enumsService.enums.agencies_closed.find(a => a.id === parseInt(this.route.snapshot.params.agency_id));
        }

        if (this.route.snapshot.params.correspondant_id) { // EDIT
          this.editMode.active = true;
          this.agenciesService.getCorrespondant(this.route.snapshot.params.agency_id, this.route.snapshot.params.correspondant_id)
            .then((response: any) => {
              this.loading = false;
              this.editMode.data = response;
              this.editFormGroup = this.formBuilder.group(this.getNormalForm(response));
              this.preselectRole = this.roles.findIndex(e => e.name === response.role);
            })
            .catch((err) => {
              this.alertService.error(err);
              this.loading = false;
            });
        } else { // NEW
          this.editFormGroup = this.formBuilder.group(this.getNormalForm());
          this.loading = false;
        }

      }
    });
  }

  get f() {
    return this.editFormGroup.controls;
  }

  getNormalForm(data = null) {
    return {
      id: [data ? data.id : null, null],
      email: [data ? data.email : null, [Validators.required, Validators.email]],
      firstname: [data ? data.firstname : null, Validators.required],
      lastname: [data ? data.lastname : null, Validators.required],
      phone_number: [data ? data.phone_number : null, null],
      phone_number2: [data ? data.phone_number2 : null, null],
      role: [data ? data.role : null, Validators.required]
    }
  }

  onSelectRole($event) {
    this.editFormGroup.patchValue({role: $event.name});
  }

  checkValue($event) {
    if ($event === '') {
      this.editFormGroup.patchValue({role: null});
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    this.agenciesService.editCorrespondant(this.route.snapshot.params.agency_id, this.editFormGroup.value)
      .then((response) => {
        this.alertService.success((this.editMode && this.editMode.active) ? 'Modification enregistrée avec succès.' : 'Correspondant enregistré avec succès.');
        this.router.url.split('/').includes('open-agencies') ? this.router.navigate(['/profilplus-agences/open-agencies/correspondants/edit/', this.route.snapshot.params.agency_id]) : this.router.navigate(['/profilplus-agences/closed-agencies/correspondants/edit/', this.route.snapshot.params.agency_id]);
      })
      .catch(err => {
        this.alertService.error(err);
      });


  }

}
