import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() label: string = null;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.onClose.emit();
  }

}
