<app-topbar moduleSlug="website">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website/settings/appointment-types']">Types de rendez-vous</a></li>
        <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
          <li class="breadcrumb-item active"><a href="javascript:;">Éditer un type de rendez-vous</a></li>
        </ng-container>
        <ng-template #templateElse>
          <li class="breadcrumb-item active"><a href="javascript:;">Créer un nouveau type de rendez-vous</a></li>
        </ng-template>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<ng-container *ngIf="editFormGroup">
  <div class="row" *ngIf="!editMode.active || (editMode.active && editMode.data)">
    <div class="col-lg-12">
      <form class="form" [formGroup]="editFormGroup">

        <div class="card">
          <!-- HEADER -->
          <div class="card-header card-header-rose card-header-icon">
            <div class="card-icon">
              <i class="material-icons">settings</i>
            </div>
            <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
              <h4 class="card-title">Édition d'un type de rendez-vous : <strong class="description">{{editMode.data.name}}</strong></h4>
            </ng-container>
            <ng-template #templateElse>
              <h4 class="card-title">Création d'un nouveau type de rendez-vous</h4>
            </ng-template>
          </div>

          <!-- MAIN CONTENT -->
          <div class="card-body">
            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.name.errors }">
                <label for="name" class="bmd-label-floating">Nom</label>
                <input type="text" class="form-control" id="name" formControlName="name">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.symbol.errors }">
                <label for="symbol" class="bmd-label-floating">Symbole</label>
                <input type="text" class="form-control" id="symbol" formControlName="symbol">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.duration.errors }">
                <label for="duration" class="bmd-label-floating">Durée (min)</label>
                <input type="number" min="0" max="99999" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="duration" formControlName="duration">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.ressources_count.errors }">
                <label for="ressources_count" class="bmd-label-floating">Quantité</label>
                <input type="number" min="0" max="99999" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="ressources_count" formControlName="ressources_count">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.ressource_name.errors }">
                <label for="ressource_name" class="bmd-label-floating">Nom ressource</label>
                <input type="text" class="form-control" id="ressource_name" formControlName="ressource_name">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.min_delay.errors }">
                <label for="min_delay" class="bmd-label-floating">Délai minimum de prise de rendez-vous</label>
                <input type="number" min="0" max="99999" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="min_delay" formControlName="min_delay">
              </p>
            </div>

            <hr style="border-color:transparent;">

            <!-- NAVIGATION LINKS -->
            <a [routerLink]="['/website/settings/appointment-types']" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
              Retour
            </a>
            <button [disabled]="editFormGroup.invalid"
              class="ppp-button ppp-button-black btn btn-primary btn-round"
              (click)="onSubmit()">
              <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
              <ng-template #creationTemplateText>Créer</ng-template>
            </button>
          </div>

        </div>

      </form>
    </div>
  </div>
</ng-container>
