<app-topbar moduleSlug="website">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/' + currentModule.slug]">
            {{currentModule.name}}
          </a>
        </li>
        <li class="breadcrumb-item active"><a href="javascript:;">Habillages de page</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round" [routerLink]="'./edit'">
      <i class="material-icons">library_add</i>
      Créer un nouvel habillage de page
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">settings</i>
        </div>
        <h4 class="card-title">Habillages de page</h4>
      </div>
      <div class="card-body">
        <app-filter-bar [filters]="filters"
                        (changed)="setPage({offset: 0}, null, $event)">
          <p style="margin-top: 10px;" *ngIf="loadingList">chargement...</p>
        </app-filter-bar>

        <div class="table-responsive">
          <ngx-datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [loadingIndicator]="loadingList"
          >
            <ngx-datatable-column name="ID" prop="id" [width]="75">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Global ?" prop="apply_to_all" [width]="100">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <i *ngIf="row.apply_to_all === true; else inactive" class="material-icons green">done</i>
                <ng-template #inactive>
                  <i class="material-icons red">clear</i>
                </ng-template>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Url page" prop="url" [width]="220">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Univers" prop="universe" [width]="100" *ngIf="currentModule && currentModule.slug == 'website'">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div [ngSwitch]="value">
                  <div *ngSwitchCase="0">B2C</div>
                  <div *ngSwitchCase="1">B2B</div>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Image gauche" prop="background_image_left" [width]="150">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <img [src]="value" width="140" alt="" />
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Image droite" prop="background_image_right" [width]="150">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <img [src]="value" width="140" alt="" />
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Page CMS" prop="page" [width]="275">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a title="Voir la page CMS" class="ppp-link" target="_blank" [routerLink]="['/' + currentModule.slug + '/pages/edit/', row.page.id]" *ngIf="row.page && policiesService.currentUser.policies.page.update; else pageOnlyData">
                  {{ row.page.title }}
                </a>
                <ng-template #pageOnlyData>
                  {{ row.page ? row.page.title : '' }}
                </ng-template>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a [routerLink]="'/' + currentModule.slug + (currentModule.slug == 'website' ? '/settings/page-backgrounds' : '/page-backgrounds') + '/edit/' + row.id" type="button" class="btn btn-success" title="Modifier l'habillage de page">
                  <i class="material-icons">edit</i>
                </a>
                <button type="button" class="btn btn-danger" title="Supprimer">
                  <i class="material-icons" (click)="onDelete(row.id)">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
