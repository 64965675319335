import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { HighLightRule } from 'src/app/models/HighLightRule';
import { Page } from 'src/app/models/Page';
import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { HighLightRulesService } from 'src/app/services/highLightRules.service';
import { LoadingService } from 'src/app/services/loading.service';
import { TiresService } from 'src/app/services/tires.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public loading: boolean = false;
  public editMode: {
    active: boolean;
    data: any;
  } = { active: false, data: null };
  public submitted: boolean = false;
  public editFormGroup: UntypedFormGroup;

  public preselectBrand = null;
  public preselectExcludeBrand = null;
  public preselectHighLightCategory = null;

  constructor(
    public enumsService: EnumsService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public highLightRulesService: HighLightRulesService,
    private alertService: AlertService,
    public loadingService: LoadingService
  ) {
    this.enumsService.observable.subscribe({
      complete: () => {
        if (this.route.snapshot.params.id) {
          // EDIT
          this.editMode.active = true;
          this.loading = true;
          this.highLightRulesService
            .getById(this.route.snapshot.params.id)
            .then((response: HighLightRule) => {
              this.editMode.data = response;
              this.loading = false;

              // PRESELECT BRAND
              if (response.brand && response.brand.id) {
                this.preselectBrand = this.enumsService.enums.brands.findIndex((b) => b.id === response.brand.id);
              }

              // PRESELECT EXCLUDED BRAND
              if (response.exclude_brand && response.exclude_brand.id) {
                this.preselectExcludeBrand = this.enumsService.enums.brands.findIndex((b) => b.id === response.exclude_brand.id);
              }

              // PRESELECT HIGHLIGHT CATEGORY
              if (response.highlight_category_id) {
                this.preselectHighLightCategory = this.enumsService.enums.highlightCategories.findIndex(
                  (b) => b.id === response.highlight_category_id
                );
              }

              // INIT FORM
              this.editFormGroup = this.formBuilder.group({
                highlight_category_id: [response.highlight_category_id, Validators.required],
                brand_id: [response.brand ? response.brand.id : null, Validators.required],
                rank: [response.rank, null],
                price_order: [response.price_order, Validators.required],
                max_result: [response.max_result, null],
                exclude_brand_id: [response.exclude_brand ? response.exclude_brand.id : null, Validators.required]
              });
            })
            .catch((err) => {
              this.loading = false;
              this.alertService.error(err);
            });
        } else {
          // INIT FORM
          this.editFormGroup = this.formBuilder.group({
            highlight_category_id: [null, Validators.required],
            brand_id: [null, Validators.required],
            rank: [null, null],
            price_order: [null, Validators.required],
            max_result: [null, null],
            exclude_brand_id: [null, Validators.required]
          });
        }
      }
    });
  }

  ngOnInit(): void {}

  get f() {
    return this.editFormGroup.controls;
  }

  onSelectedBrand($event, exclude = false) {
    if (exclude) {
      this.editFormGroup.patchValue({ exclude_brand_id: $event.id });
    } else {
      this.editFormGroup.patchValue({ brand_id: $event.id });
    }
  }

  onSelectedHighLightCategory($event) {
    this.editFormGroup.patchValue({ highlight_category_id: $event.id });
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    const rule: HighLightRule = {
      ...this.editFormGroup.value
    };

    if (this.editMode) {
      rule.id = this.route.snapshot.params.id;
    }
    this.highLightRulesService
      .edit(rule)
      .then(() => {
        this.router.navigate(['/website/tires/highlight-rules']);
        this.alertService.success(
          this.editMode && this.editMode ? 'Modification enregistrée avec succès.' : 'Catégorie de mise en avant enregistrée avec succès.'
        );
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }
}
