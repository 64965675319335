import { Injectable } from '@angular/core';

import 'bootstrap-notify';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';
import { Customer } from '../models/Customer';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class CustomersService {
  constructor(private apiService: ApiService) {}

  getById(pageId: string | number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('customers/' + pageId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('customers')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllWithPagination(page, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get("customers" + '?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
        + (filters ? '&' + parseFiltersForUrl(filters) : '')
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(customer: Customer) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      formData.append('customer:last_name', customer.last_name ? customer.last_name : '');
      formData.append('customer:first_name', customer.first_name ? customer.first_name : '');
      formData.append('customer:civility', customer.civility != null ? customer.civility.toString() : '');
      formData.append('customer:company', customer.company ? customer.company : '');

      formData.append('customer:pro_company_id', customer.pro_company_id ? customer.pro_company_id.toString() : '');

      if (customer.email && customer.email.length > 0) {
        formData.append('customer:email', customer.email ? customer.email : null);
      }
      if (customer.mob_phone  && customer.mob_phone.toString().length > 0) {
        formData.append('customer:mob_phone', customer.mob_phone ? customer.mob_phone.toString() : '');
      }
      formData.append('customer:phone', customer.phone ? customer.phone.toString() : '');
      formData.append('customer:birthday', customer.birthday ? customer.birthday.toString() : '');
      formData.append('loyalty_card:agency_id', customer.agency_id ? customer.agency_id.toString() : '');
      if (customer.card_number) {
        formData.append('loyalty_card:number', customer.card_number ? customer.card_number : '');
      }
      formData.append('customer:loyalty_rule_id', customer.loyalty_rule_id ? customer.loyalty_rule_id.toString() : '');
      formData.append('customer:optin_promo', customer.optin_promo ? customer.optin_promo.toString() : 'false');
      formData.append('customer:optin_myprofilplus', customer.optin_myprofilplus ? customer.optin_myprofilplus.toString() : 'false');
      formData.append('customer:deactivated', customer.deactivated ? customer.deactivated.toString() : 'false');
      /* Adresse de livraison */
      formData.append('shipping_address:street_name', customer.shipping_address_street_name ? customer.shipping_address_street_name : '');
      formData.append('shipping_address:zipcode', customer.shipping_address_zipcode ? customer.shipping_address_zipcode : '');
      formData.append('shipping_address:city', customer.shipping_address_city ? customer.shipping_address_city : '');

      if (customer.id) {
        this.apiService.put('customers/' + customer.id, formData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      } else {
        this.apiService.post('customers', formData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }

  delete(pageId: string | number) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('customers/' + pageId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAddress(type: string, customerId: number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('customers/' + customerId)
        .then((response) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        })
    })
  }

  activate(customerId) {
    return new Promise((resolve, reject) => {
      this.apiService.put('customers/' + customerId + '/activate', null)
        .then((response) => {
          resolve(response)
        })
        .catch((err) => {
          reject(err);
        })
    })
  }
}
