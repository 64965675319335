import { Injectable } from '@angular/core';

import 'bootstrap-notify';
import { ApiService } from './api.service';
import { CustomerReview } from '../models/CustomerReview';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';

@Injectable({ providedIn: 'root' })
export class CustomerReviewsService {
  constructor(private apiService: ApiService) {

  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('reviews')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllWithPagination(page: string | number, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      const res: Array<CustomerReview> = [];
      this.apiService.get('reviews?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
        + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id: number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('reviews/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(customereview: CustomerReview) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('review:status', customereview.status.toString())
      formData.append('review:answer', customereview.answer ? customereview.answer : '')
      this.apiService.put('reviews/' + customereview.id, formData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
