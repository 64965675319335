import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { AuthService } from 'src/app/services/auth.service';
import { PoliciesService } from 'src/app/services/policies.service';

import { ProCompany } from 'src/app/models/ProCompany';

import { ProCompaniesService } from 'src/app/services/pro-companies.service';
@Component({
  selector: 'app-customer-entreprises',
  templateUrl: './customer-entreprises.component.html',
  styleUrls: ['./customer-entreprises.component.scss']
})
export class CustomerEntreprisesComponent implements OnInit {
  public items: Array<any> = [];
  public loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public enumsService: EnumsService,
    private proCompaniesService: ProCompaniesService,
    private alertService: AlertService,
    public authService: AuthService,
    public policiesService: PoliciesService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
    }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.proCompaniesService.getAll().then((response: Array<ProCompany>) => {
          this.items = response;
          this.loading = false;
        })
        .catch((err) => {
          this.alertService.error(err);
          this.loading = false;
        });
      }
    });
  }

}
