import { Injectable } from '@angular/core';

import 'bootstrap-notify';
import { ApiService } from './api.service';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';

@Injectable({ providedIn: 'root' })
export class TiresService {
  constructor(private apiService: ApiService) {

  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('tires')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllTiresWithPagination(page: string | number, type: string, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('tires/list/'+ type +'?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
        + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllByType(type) {
    return new Promise((resolve, reject) => {
      this.apiService.get('tires/' + type)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('tires/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }


  edit(tire) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      (tire.sale_quantity)?formData.append('tire:sale_quantity', tire.sale_quantity):null
      formData.append('tire:type', tire.type ? tire.type : 'VlTire')
      formData.append('tire:ean', tire.ean ? tire.ean : '')
      formData.append('tire:category', tire.category ? tire.category : '')
      formData.append('tire:season', tire.season ? tire.season : '')
      formData.append('tire:width', tire.width ? tire.width : '')
      formData.append('tire:height', tire.height ? tire.height : '')
      formData.append('tire:diameter', tire.diameter ? tire.diameter : '')
      formData.append('tire:load_index', tire.load_index ? tire.load_index : '')
      formData.append('tire:speed_index', tire.speed_index ? tire.speed_index : '')
      formData.append('tire:runflat', tire.runflat ? tire.runflat : 'false')
      formData.append('tire:label_fuel', tire.label_fuel ? tire.label_fuel : '')
      formData.append('tire:label_wet', tire.label_wet ? tire.label_wet : '')
      formData.append('tire:label_noise', tire.label_noise ? tire.label_noise : '')
      formData.append('tire:price_with_tax', tire.price_with_tax ? tire.price_with_tax : 0)
      formData.append('tire:on_sale', tire.on_sale ? tire.on_sale : 'false')
      formData.append('tire:description', tire.description ? tire.description : '')
      formData.append('tire:homologation', tire.homologation ? tire.homologation : '')
      formData.append('tire:bf_price', tire.bf_price ? tire.bf_price : '')
      formData.append('tire:price_with_sale', tire.price_with_sale ? tire.price_with_sale : '')
      formData.append('tire:subdivision_code', tire.subdivision_code ? tire.subdivision_code : '')
      formData.append('tire:wear_rate', tire.wear_rate ? tire.wear_rate : '')
      formData.append('tire:remaining_gum', tire.remaining_gum ? tire.remaining_gum : '')
      formData.append('tire:saler_first_name', tire.saler_first_name ? tire.saler_first_name : '')
      formData.append('tire:saler_last_name', tire.saler_last_name ? tire.saler_last_name : '')
      formData.append('tire:saler_email', tire.saler_email ? tire.saler_email : '')
      formData.append('tire:saler_phone', tire.saler_phone ? tire.saler_phone : '')
      formData.append('tire:saler_city', tire.saler_city ? tire.saler_city : '')

      if (tire.tire_photo_1 !== null) {
        formData.append('tire:tire_photo_1', tire.tire_photo_1 ? tire.tire_photo_1 : '')
      }

      if (tire.tire_photo_2 !== null) {
        formData.append('tire:tire_photo_2', tire.tire_photo_2 ? tire.tire_photo_2 : '')
      }

      if (tire.tire_photo_3 !== null) {
        formData.append('tire:tire_photo_3', tire.tire_photo_3 ? tire.tire_photo_3 : '')
      }

      if (tire.tire_photo_4 !== null) {
        formData.append('tire:tire_photo_4', tire.tire_photo_4 ? tire.tire_photo_4 : '')
      }

      formData.append('tire:tire_profile', tire.tire_profile ? tire.tire_profile : '')
      formData.append('tire:reference_number', tire.reference_number ? tire.reference_number : '')
      formData.append('tire:law_code', tire.law_code ? tire.law_code : '')
      formData.append('tire:is_3pmsf', tire.is_3pmsf ? tire.is_3pmsf : '')
      formData.append('tire:ice_grip', tire.ice_grip ? tire.ice_grip : '')
      formData.append('tire:eprel_url', tire.eprel_url ? tire.eprel_url : '')
      formData.append('tire:category_noise', tire.category_noise ? tire.category_noise : '')
      formData.append('tire:tire_model', tire.tire_model ? tire.tire_model : '')
      formData.append('tire:agency_id', tire.agency_id ? tire.agency_id : '')
      formData.append('tire:tire_brand_id', tire.tire_brand_id ? tire.tire_brand_id : '')
      formData.append('tire:tire_model_id', tire.tire_model_id ? tire.tire_model_id : '')

      if (tire.id) {
        this.apiService.put('tires/' + tire.id, formData)
        .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        this.apiService.post('tires/', formData)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      }
    })
  }

  getById(id) {
    return new Promise((resolve, reject) => {
      this.apiService.get('tires/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }


  // Generic method to get pagination for specific endpoint

  getAllWithPagination(page, property = "tire-models", sortBy = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get(property+'?page=' + page + (sortBy ? '&sort_by=' + sortBy : '' )
      + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
