import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Customer } from 'src/app/models/Customer';
import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoyaltyCardsService } from 'src/app/services/loyalty-cards.service';
import { WorkersService } from 'src/app/services/workers.service';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  public remaining: String;
  public items: Customer[] = null;
  public loading: boolean = false;
  public loadingList: boolean = false;
  @ViewChild('datatable') datatable: any;

  public filtersSelectedAsObservable = new BehaviorSubject(null)

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [
    {
      name: 'N° de carte',
      slug: 'number',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Nom',
      slug: 'last_name',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Prénom',
      slug: 'first_name',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Email',
      slug: 'email',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Ville',
      slug: 'city',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Code postal',
      slug: 'zipcode',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Téléphone',
      slug: 'phone',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Date de création',
      slug: 'created_at',
      type: 'date',
      class: 'col-2',
      value: null
    },
  ];

  constructor(
    private cd: ChangeDetectorRef,
    private workersService: WorkersService,
    private alertService: AlertService,
    public enumsService: EnumsService,
    public policiesService: PoliciesService,
    public authService: AuthService,
    private loyaltyCardService: LoyaltyCardsService) {
      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          // DYNAMICS FILTERS
          if (this.authService.currentUserValue.adherents.length > 0 || this.authService.currentUserValue.is_admin) {
            this.filters.push({
              name: 'Agence',
              slug: 'agency_id',
              selected_emit_property: 'id',
              type: 'autocomplete',
              class: 'col-2',
              on_select_only: true,
              options: this.enumsService.enums.agencies
            });
          }

          // DATA
          this.loading = false;

          // Observe filters selected
          this.filtersSelectedAsObservable.subscribe(data => {
            /* For Adherents and Agencies add special filters */
            if (!this.authService.currentUserValue.is_admin) {
              const index = this.filters.findIndex(f => f.slug === 'expand_search_checked')

              if (data !== null && Object.keys(data).length > 0) {
                if (index === -1) {
                  this.filters.push(
                    {
                      name: 'Je souhaite élargir ma recherche à toutes les agences de l\'enseigne Profil Plus',
                      slug: 'expand_search_checked',
                      type: 'checkbox',
                      class: 'col-12 ppp-filters-advanced-search',
                      value: null
                    });
                }
              }
              else {
                if (index !== -1) {
                  this.filters.splice(index, 1)
                }
              }
            }
          })
        }
      });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  exportCards() {
    this.workersService.startNewWorker('myprofilplus_customers_export', this.filtersSelectedAsObservable.value).then(() => {
      this.alertService.success('Export de la liste des clients, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelectedAsObservable.next(filters !== null ? filters : this.filtersSelectedAsObservable.value)
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.remaining = filters && filters.expand_search;
    this.loadingList = true;
    this.loyaltyCardService.getAllWithPagination(this.pageOffsetSelected + 1,
      this.sortSelected, this.filtersSelectedAsObservable.value, false)
      .then((response: any) => {
        this.datatable.limit = 15;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

  onDelete(id: string | number) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce client ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText:  'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.loyaltyCardService.delete(id)
        .then(() => {
          const index = this.items.findIndex(e => e.id === id);
          if (index !== -1) {
            this.items.splice(index, 1);
            this.items = [...this.items];
            this.alertService.success('Client supprimé avec succès.');
          }
        })
        .catch((err) => {
          this.alertService.error(err);
        });
      }
    });
  }

}
