import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Question } from 'src/app/models/Question';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

function valuesConditionallyRequiredValidator(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }
  if (formControl.parent.get('question_type').value === 'select-unique' ||
  formControl.parent.get('question_type').value === 'select-multiple') {
    return Validators.required(formControl);
  }
  return null;
}

@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss']
})
export class QuestionFormComponent implements OnInit {
  public editFormGroup: UntypedFormGroup;
  @Input() question!: Question;
  @Output() edited = new EventEmitter();
  @Output() canceled = new EventEmitter();
  public submitted: boolean = false;
  values_updated : {index: number, old_value: string, new_value: string}[] = []

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.editFormGroup = this.formBuilder.group({
      name: [this.question ? this.question.name : '', Validators.required],
      question_type: [this.question ? this.question.question_type : null, Validators.required],
      required: [this.question ? this.question.required : false, null],
      values: [this.question ? this.question.values : [], valuesConditionallyRequiredValidator],
      new_value: ['', null]
    }, {
      validators: [valuesConditionallyRequiredValidator]
    });

    // Set up form
    this.editFormGroup.controls.question_type.valueChanges.subscribe(type => {
      if (type !== 'select-unique' || type !== 'select-multiple') {
        this.editFormGroup.patchValue({ values: [] });
      }
    });
  }

  get f() {
    return this.editFormGroup.controls;
  }

  onAddValue(value: string) {
    if (value) {
      const arr: Array<string> = this.f.values.value;
      arr.push(value);
      this.editFormGroup.controls.values.patchValue(arr);
      this.editFormGroup.patchValue({ new_value: '' });
    }
  }

  onRemoveValue(index) {
    const arr: Array<string> = this.f.values.value;
    if (arr[index]) {
      arr.splice(index, 1);
    }
    this.editFormGroup.patchValue({ values: arr });
  }

  onEditValue(value, index) {
    const arr: Array<string> = this.f.values.value;
    if (value != '') {
      if (arr[index]) {
        const index2 = this.values_updated.findIndex(v => v.index == index)
        if (index2 == -1) {
          this.values_updated.push({index: index, old_value: arr[index], new_value: value})
        } else {
          this.values_updated[index2].new_value = value
        }
        arr[index] = value
      }
      this.editFormGroup.patchValue({ values: arr });
    } else {
      arr.splice(index, 1)
      this.editFormGroup.patchValue({ values: arr });
    }
  }

  /* FORM */
  onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid
    if (this.editFormGroup.invalid) {
      // window.scrollTo(0, 0);
      return;
    }

    const res: Question = {
      ...this.editFormGroup.value
    };

    delete res.new_value;

    // Edit mode
    if (this.question && this.question.uuid) {
      res.uuid = this.question.uuid;
    }

    if (this.values_updated && this.values_updated.length > 0) {
      res.values_updated = this.values_updated
    }

    this.edited.emit(res);
    this.editFormGroup.reset();
  }

  onCancel(): void {
    this.canceled.emit();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.f.values.value, event.previousIndex, event.currentIndex);
  }
}
