<app-topbar moduleSlug="profilplus-agences">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences']">Profil Plus Agences</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Liste des services</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div *ngIf="policiesService.currentUser.policies.service.create" class="row">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round"
     [routerLink]="['/profilplus-agences/services/edit']">
      <i class="material-icons">library_add</i>
      Créer un nouveau service
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">list</i>
        </div>
        <h4 class="card-title">Liste des services</h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <ngx-datatable
            class="material"
            [rows]="services"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [loadingIndicator]="loadingList"
          >
            <ngx-datatable-column name="ID" prop="id" [width]="75">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Métier" prop="skill" [width]="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a title="Voir le métier" class="ppp-link" target="_blank" [routerLink]="['/profilplus-agences/skills/edit/', row.skill.id]" *ngIf="row.skill && policiesService.currentUser.policies.skill.update; else skillOnlyData">
                  {{ row.skill.name }}
                </a>
                <ng-template #skillOnlyData>
                  {{ row.skill.name }}
                </ng-template>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Dénomination" prop="name" [width]="230">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Titre" prop="title" [width]="220">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Rang" prop="rank" [width]="90">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Visibilité site B2C ?" prop="b2c_quotation" [width]="210">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <i *ngIf="row.b2c_quotation === true; else noB2CQuotation" class="material-icons green">done</i>
                <ng-template #noB2CQuotation>
                  <i class="material-icons red">clear</i>
                </ng-template>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Visibilité B2B ?" prop="b2b_quotation" [width]="210">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <i *ngIf="row.b2b_quotation === true; else noB2BQuotation" class="material-icons green">done</i>
                <ng-template #noB2BQuotation>
                  <i class="material-icons red">clear</i>
                </ng-template>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="policiesService.currentUser.policies.service.update" [routerLink]="['/profilplus-agences/services/edit/', row.id]" type="button" class="btn btn-success" title="Modifier le service">
                  <i class="material-icons">edit</i>
                </a>
                <button *ngIf="policiesService.currentUser.policies.service.delete" type="button" class="btn btn-danger" (click)="onDelete(row.id)" title="Supprimer">
                  <i class="material-icons">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
