<nav class="ppp-header navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top text-white" [ngClass]="(router.url.split('/').includes('login')) ? 'ppp-login-header': ''">
  <div class="container">

    <div class="navbar-wrapper">
    </div>

    <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-end">
      <ul class="navbar-nav">
        <li class="nav-item">
        </li>
      </ul>
    </div>

  </div>
</nav>
