<ng-container *ngIf="!loading; else templateElse">
  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website/customers-addresses']">Entreprises (PRO)</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">people</i>
          </div>
          <h4 class="card-title">Liste des entreprises (PRO)</h4>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <ngx-datatable
              class="material"
              [rows]="items"
              [columnMode]="'standard'"
              [rowHeight]="'auto'"
              [headerHeight]="50"
              [footerHeight]="50"
              [loadingIndicator]="loading"
            >
              <ngx-datatable-column name="ID" prop="id" [width]="75">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Nom" prop="name" [width]="300">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Raison sociale" prop="full_name" [width]="300">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Actions" [width]="300" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a [routerLink]="['/website/customers/customer-entreprises/', row.id]"
                    type="button" class="btn btn-success"
                    title="Voir l'entreprise">
                    <i class="material-icons">visibility</i>
                  </a>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #templateElse>
  <app-loading></app-loading>
</ng-template>
