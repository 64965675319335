import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberWithSpaces'
})
export class NumberWithSpacesPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return value;

    // Convertit le nombre en chaine de caractères
    let stringValue = value.toString();

    // Ajoute un espace tous les 3 caractères en partant de la fin
    let result = "";
    let i = stringValue.length - 1;
    let j = 0;
    while (i >= 0) {
      result = stringValue.charAt(i) + result;
      j++;
      if (j % 3 == 0 && i > 0) {
        result = " " + result;
      }
      i--;
    }

    return result;
  }
}
