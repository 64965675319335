import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { AppointmentType } from '../models/AppointmentType';

@Injectable({ providedIn: 'root' })
export class AppointmentTypesService {
  constructor(private apiService: ApiService) {}

  /**
   * Get a specific appointment type
   * @param appointmentTypeId Appointment type ID
   */
  getById(appointmentTypeId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('appointment_types/' + appointmentTypeId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get all appointment types
   */
  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('appointment_types')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Create or update a specific appointment type
   * @param appointmentType The corresponding appointment type
   */
  edit(appointmentType) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('appointment_type:name', appointmentType.name ? appointmentType.name : '');
      formData.append('appointment_type:symbol', appointmentType.symbol ? appointmentType.symbol : '');
      formData.append('appointment_type:duration', appointmentType.duration ? appointmentType.duration : 0);
      formData.append('appointment_type:ressources_count', appointmentType.ressources_count ? appointmentType.ressources_count : 0);
      formData.append('appointment_type:ressource_name', appointmentType.ressource_name ? appointmentType.ressource_name : '');
      formData.append('appointment_type:min_delay', appointmentType.min_delay ? appointmentType.min_delay : 0);
      if (appointmentType.id) {
        this.apiService.put('appointment_types/' + appointmentType.id, formData)
        .then((response: any) => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
      } else {
        this.apiService.post('appointment_types', formData)
        .then((response: any) => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }

  /**
   * Delete a specific appointment type
   * @param appointmentTypeId Appointmen type ID
   */
  delete(appointmentTypeId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('appointment_types/' + appointmentTypeId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
