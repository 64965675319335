import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { HighLightCategoriesService } from 'src/app/services/highLightCategories.service';
import { PoliciesService } from 'src/app/services/policies.service';

@Component({
  selector: 'app-highlight-categories',
  templateUrl: './highlight-categories.component.html',
  styleUrls: ['./highlight-categories.component.scss']
})
export class HighlightCategoriesComponent implements OnInit {
  items: Array<any> = null;

  public loading: boolean = false;
  public loadingList: boolean = false;

  constructor(
    private alertService: AlertService,
    public enumsService: EnumsService,
    public highLightCategoriesService: HighLightCategoriesService,
    public authService: AuthService,
    public policiesService: PoliciesService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
    }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.loading = false;
        this.loadingList = true;
        this.highLightCategoriesService.getAll()
        .then((response: any) => {
          this.loadingList = false;
          this.items = response;
        })
        .catch((err) => {
          this.loadingList = false;
          this.alertService.error(err);
        });
      }
    });
  }
}
