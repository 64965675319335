<app-topbar moduleSlug="myprofilplus">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/myprofilplus']">MyProfil+</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Liste des inventaires Cartes / Agences</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round"
    [routerLink]="['/myprofilplus/loyalty-card-orders/edit']">
      <i class="material-icons">library_add</i>
      Créer un nouvel inventaire Cartes / Agences
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">search</i>
        </div>
        <h4 class="card-title">Liste des inventaires Cartes / Agences <span>({{this.datatable.count}} éléments)</span></h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [externalPaging]="true"
            [externalSorting]="true"
            [loadingIndicator]="loadingList"
            [reorderable]="false"
            (page)="setPage($event)"
            (sort)="onSort($event)"
          >
            <ngx-datatable-column [resizeable]="false" name="ID" prop="id" [width]="75" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Agence" prop="agency" [width]="350" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a title="Voir l'agence" class="ppp-link" target="_blank"
                  [routerLink]="['/profilplus-agences/edit/', value.id]"
                  *ngIf="policiesService.currentUser.policies['open-agency'] && policiesService.currentUser.policies['open-agency'].update; else agencyOnlyData">
                  {{value.name}}
                </a>
                <ng-template #agencyOnlyData>
                  {{value.name}}
                </ng-template>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Quantité" prop="amount" [width]="180" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{value}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Date de création" prop="created_at" [width]="200" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date:'EEEE dd MMMM y' }}
              </ng-template>
            </ngx-datatable-column>

          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
