import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { IndexContentComponent } from "src/app/components/index-content/index-content.component";
import { AuthGuard } from "src/app/helpers/auth.guard";

import { MeetingsComponent } from "./meetings.component";

import { QuestionnairesComponent } from "./questionnaires/questionnaires.component";
import { EditComponent as QuestionnairesEditComponent } from "./questionnaires/edit/edit.component";

import { ContactsComponent } from "./contacts/contacts.component";
import { EditComponent as contactEditComponent } from "./contacts/edit/edit.component";

import { HelpComponent } from '../help/help.component';
import {EditComponent as ProfilplusFormsEdit} from '../profilplus-site/profilplus-forms/edit/edit.component';

const routes: Routes = [
  {
    path: 'meetings',
    component: MeetingsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: IndexContentComponent,
        canActivate: [AuthGuard],
      },

      /* QUESTIONNAIRES */
      {
        path: 'questionnaires',
        component: QuestionnairesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'questionnaires/edit',
        component: QuestionnairesEditComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'questionnaires/edit/:questionnaire_id', // Update
        component: QuestionnairesEditComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'questionnaires/edit/:questionnaire_id/clone',
        component: QuestionnairesEditComponent,
        canActivate: [AuthGuard]
      },
      /* CONTACTS */
      {
        path: 'contacts',
        component: ContactsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'contacts/edit', // Création
        component: contactEditComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'contacts/edit/questionnaire/:questionnaire_id', // Création via un questionnaire
        component: contactEditComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'contacts/edit/questionnaire/:questionnaire_id/:action', // Création via un questionnaire
        component: contactEditComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'contacts/edit/:contact_id/questionnaire/:questionnaire_id', // Édition
        component: contactEditComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'contacts/edit/:contact_id/questionnaire/:questionnaire_id/:action', // Édition
        component: contactEditComponent,
        canActivate: [AuthGuard],
      },

      /* HELP */
      {
        path: 'help',
        component: HelpComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MeetingsRoutingModule {}
