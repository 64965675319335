import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { AlertService } from 'src/app/services/alert.service';
import { LoyaltyCardOrdersService } from 'src/app/services/loyalty-card-orders.service';

import Swal from 'sweetalert2';

import { EnumsService } from 'src/app/services/enums.service';
import { AuthService } from 'src/app/services/auth.service';
import { PoliciesService } from 'src/app/services/policies.service';

import { LoyaltyCardOrder } from 'src/app/models/LoyaltyCardOrder';

@Component({
  selector: 'app-loyalty-card-orders',
  templateUrl: './loyalty-card-orders.component.html',
  styleUrls: ['./loyalty-card-orders.component.scss']
})
export class LoyaltyCardOrdersComponent implements OnInit {
  public items: Array<LoyaltyCardOrder> = null;
  public totalPages: number;
  public totalItems: number;
  public currentPage: number;
  public nextPage: any;
  public loading: boolean = false;
  public loadingList: boolean = false;

  @ViewChild('datatable') datatable: any;
  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [];

  constructor(
    private cd: ChangeDetectorRef,
    private loyaltyCardOrdersService: LoyaltyCardOrdersService,
    private alertService: AlertService,
    public enumsService: EnumsService,
    private router: Router,
    private authService: AuthService,
    public policiesService: PoliciesService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          this.loading = false;
        }
      });
    }

  ngOnInit(): void {}

  ngAfterViewInit() {
    // ON ROUTE CHANGING
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;
    this.loyaltyCardOrdersService.getAllWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

  onDelete(loyaltyCardOrderId) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cet inventaire de cartes / agence ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.loyaltyCardOrdersService.delete(loyaltyCardOrderId)
          .then(() => {
            const index = this.items.findIndex(e => e.id === loyaltyCardOrderId);
            if (index !== -1) {
              this.items.splice(index, 1);
              this.items = [...this.items];
              this.alertService.success('Inventaire de cartes / agence supprimé avec succès.');
            }
          })
          .catch((err) => {
            this.alertService.error(err);
          });
      }
    });
  }

}
