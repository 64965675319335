import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoyaltyCustomerHistoriesService } from 'src/app/services/loyalty-customer-histories.service';

@Component({
  selector: 'app-compensation',
  templateUrl: './compensation.component.html',
  styleUrls: ['./compensation.component.scss']
})
export class CompensationComponent implements OnInit {
  public editFormGroup: UntypedFormGroup;
  public compensations: Array<any> = [];
  public submitted: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private enumsService: EnumsService,
    private loyaltyCustomerHistories: LoyaltyCustomerHistoriesService,
    private alertService: AlertService) {

      this.enumsService.observable.subscribe({
        complete: () => {
          // Prepare compensations array
          this.enumsService.enums.adherents.forEach(adherent => {
            // compensations array initialisation
            this.compensations.push({
              adherent,
              loading: false,
              result: []
            })
          })

          // Prepare search form
          this.editFormGroup = this.formBuilder.group({
            date_start: ['', Validators.required],
            date_end: ['', Validators.required]
          })
        }
      })
    }

  ngOnInit(): void {
  }


  get f() {
    return this.editFormGroup.controls;
  }

  onSubmit() {
    this.submitted = true;
    // LOOP QUERYING
    for (const compensation of this.compensations) {
      compensation.loading = true;
      this.loyaltyCustomerHistories.getCompensation(this.editFormGroup.value.date_start, this.editFormGroup.value.date_end, compensation.adherent.id).then((response: any) => {
        // RESULT FOR adherent in response
        compensation.loading = false;

        // parse response
        for(const resp in response) {
          compensation.result.push({
            client: resp,
            mount: response[resp]
          })
        }
      })
      .catch((err) => {
        this.alertService.error(err);
      });
    }
  }

}
