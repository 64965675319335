import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoyaltyCardsService } from 'src/app/services/loyalty-cards.service';
import { WorkersService } from 'src/app/services/workers.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  public items = []
  public loading: boolean = false;
  public loadingList: boolean = false;
  public cardType = "CE"
  @ViewChild('datatable', { static: false }) datatable: any;

  public filtersSelected: any = {
    activated: true
  };

  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [{
      name: 'N° carte',
      slug: 'number',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Nom société',
      slug: 'loyalty_card_organization_name',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Nom',
      slug: 'last_name',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Prénom',
      slug: 'first_name',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Date de création',
      slug: 'created_at',
      type: 'date',
      class: 'col-2',
      value: null
    },
    {
      name: 'Date d\'activation',
      slug: 'activated_at',
      type: 'date',
      class: 'col-2',
      value: null
    },
    {
      name: 'Type de carte',
      slug: 'card_type',
      type: 'radio',
      class: 'col-4',
      options: [
        {
          name: 'CE',
          value: 'CE',
          default: true
        },
        {
          name: 'VI',
          value: 'VI'
        },
        {
          name: 'Autres cartes inactives',
          value: 'autres'
        }
      ]
    }
  ];

  constructor(
    private cd: ChangeDetectorRef,
    private workersService: WorkersService,
    private route: ActivatedRoute,
    private enumsService: EnumsService,
    private alertService: AlertService,
    public authService: AuthService,
    public policiesService: PoliciesService,
    private loyaltyCardsService: LoyaltyCardsService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          this.filters.push({
            name: 'Agence',
            slug: 'agency_id',
            selected_emit_property: 'id',
            type: 'autocomplete',
            on_select_only: true,
            class: 'col-2',
            options: this.enumsService.enums.agencies
          });
          if (this.authService.currentUserValue.is_admin) {
            this.filters.push({
              name: 'Adhérent',
              slug: 'adherent_id',
              selected_emit_property: 'id',
              type: 'autocomplete',
              on_select_only: true,
              class: 'col-2',
              options: this.enumsService.enums.adherents
            });
          }
          this.loading = false;
          if (this.route.snapshot.params.type) {
            this.filters[2].options.forEach(e => e.default = false);
            const index = this.filters[2].options.findIndex(o => o.value == this.route.snapshot.params.type)
            if (index !== -1) {
              this.filters[2].options[index].default = true;
            }
          }
        }
      })
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.setPage({offset: 0}, null, (this.route.snapshot.params.type) ? {card_type: this.route.snapshot.params.type} : null);
    this.cd.detectChanges();
  }

  exportCards() {
    if (!this.filtersSelected.card_type) {
      this.filtersSelected.card_type = 'CE';
    }
    this.workersService.startNewWorker('myprofilplus_customers_export', this.filtersSelected).then((response) => {
      this.alertService.success('Export de la liste des cartes de fidélité, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }


  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;
    if (filters && filters.card_type) {
      this.cardType = filters.card_type;
    }
    this.loyaltyCardsService.getAllWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected, this.cardType)
      .then((response: any) => {
        this.datatable.limit = 15;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

}
