<app-topbar moduleSlug="myprofilplus">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/myprofilplus']">MyProfil+</a></li>
        <li class="breadcrumb-item active"><a [routerLink]="['/myprofilplus/compensation']">Compensation</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="card">
  <div class="card-header card-header-rose card-header-icon">
    <div class="card-icon">
      <i class="material-icons">pie_chart</i>
    </div>
    <h4 class="card-title">Compensation</h4>
  </div>
  <div class="card-body">
    <div class="col-lg-6">
      <form *ngIf="editFormGroup" class="form row" [formGroup]="editFormGroup">
        <div class="col-lg-6 col-md-12">
          <div class="form-group is-required">
            <label class="col-form-label">Date de début</label>
            <div class="form-group">
              <input type="date" class="form-control datetimepicker" name="date_start" formControlName="date_start">
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="form-group is-required">
            <label class="col-form-label">Date de fin</label>
            <div class="form-group">
              <input type="date" class="form-control datetimepicker" name="date_end" formControlName="date_end">
              <br>
            </div>
          </div>
        </div>
        <div class="col">
          <button
            [disabled]="editFormGroup.invalid"
            class="ppp-button ppp-button-black btn btn-primary btn-round"
            (click)="onSubmit()">
            Rechercher
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="card" *ngIf="submitted">
  <div class="card-body">
    <div *ngFor="let compensation of compensations" class="col-lg-6 ppp-compensation-item">
      <p>Les clients des agences de <strong>{{compensation.adherent.name}}</strong> ont dépensé :</p>
      <app-loading *ngIf="(compensation.loading); else clientList"></app-loading>
      <ng-template #clientList>
        <div *ngIf="(compensation.result.length == 0); else calcClient">
          Aucune dépense pour cet adhérent.
        </div>
        <ng-template #calcClient>
          <ul>
            <li *ngFor="let clients of compensation.result">{{clients.client}} : <strong>{{clients.mount}}€</strong></li>
          </ul>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
