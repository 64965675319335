import { Component, OnInit } from '@angular/core';

import Swal from 'sweetalert2';

import { AlertService } from 'src/app/services/alert.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthService } from 'src/app/services/auth.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { EnumsService } from 'src/app/services/enums.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  public loading: boolean = false;
  public companies: Array<any> = null;

  public filters: Array<any> = [
    {
      name: 'Nom de la société',
      slug: 'name',
      type: 'text',
      class: 'col-3',
      value: null
    },
  ];

  constructor(private alertService: AlertService,
              private companiesService: CompaniesService,
              private authService: AuthService,
              private enumsService: EnumsService,
              public policiesService: PoliciesService) {
    this.policiesService.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.companiesService.getAll()
          .then((response: Array<any>) => {
            this.filters.push({
              name: 'Adhérent',
              slug: 'adherent_name',
              type: 'autocomplete',
              class: 'col-3',
              options: this.enumsService.enums.adherents
            });
            this.loading = false;
            this.companies = response;
          })
          .catch((err) => {
            this.loading = false;
            this.alertService.error(err);
          });
        }
    });
  }

  onChange($event) {
    this.loading = true;
    this.companiesService.getAll($event)
    .then((response: Array<any>) => {
      this.loading = false;
      this.companies = response;
    })
    .catch((err) => {
      this.loading = false;
      this.alertService.error(err);
    });
  }

  onDelete(companyId) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cette société ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.companiesService.delete(companyId)
          .then(() => {
            const index = this.companies.findIndex(e => e.id === companyId);
            if (index !== -1) {
              this.companies.splice(index, 1);
              this.companies = [...this.companies];
              this.alertService.success('Société supprimée avec succès.');
            }
          })
          .catch((err) => {
            this.alertService.error(err);
          });
      }
    });
  }

}
