<nav class="ppp-topbar navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top">

  <!-- Hide / Display left menu switch -->
  <div class="navbar-wrapper" style="display:none;">
    <div class="navbar-minimize">
      <button id="minimizeSidebar" class="btn btn-just-icon btn-white btn-fab btn-round"  (click)="onSidebarMinify()">
        <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
        <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
      </button>
    </div>
  </div>

  <!-- Modules subnavigations -->
  <div class="ppp-subNavigation">
    <ng-content select="[title]"></ng-content>
  </div>

  <!-- Responsive menu button -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
    <span class="sr-only">Toggle navigation</span>
    <span class="navbar-toggler-icon icon-bar"></span>
    <span class="navbar-toggler-icon icon-bar"></span>
    <span class="navbar-toggler-icon icon-bar"></span>
  </button>

  <!-- Modules help -->
  <div *ngIf="displayHelp" class="ppp-helpButton collapse navbar-collapse justify-content-end">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a [routerLink]="['/' + moduleSlug, 'help']" class="nav-link navbar-text" target="_blank" *ngIf="modules[moduleSlug]" title="Accéder à l'aide du module">
          <i class="material-icons">help_outline</i> Aide du module
        </a>
      </li>
    </ul>
  </div>

</nav>
