<app-topbar moduleSlug="myprofilplus">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/myprofilplus']">MyProfil+</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/myprofilplus/ce-cards']">Liste des cartes CE / VI</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row">
  <div class="col-md-12">
    <a *ngIf="authService.currentUserValue.policies['ce-card'].create" class="ppp-button ppp-button-black btn btn-primary btn-round"
    [routerLink]="['/myprofilplus/ce-cards/edit']">
      <i class="material-icons">library_add</i>
      Créer une série de cartes CE ou VI
    </a>
    <a *ngIf="authService.currentUserValue.is_admin || authService.currentUserValue.adherents.length > 0" class="ppp-button ppp-button-black btn btn-primary btn-round"
      (click)="$event.preventDefault(); exportCards()">
      <i class="material-icons">pie_chart</i>
      Export .CSV
    </a>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">picture_in_picture</i>
        </div>
        <h4 class="card-title">Liste des cartes CE / VI</h4>
      </div>

      <div class="card-body">
        <app-filter-bar [filters]="filters"
            (changed)="setPage({offset: 0}, null, $event)">
          <p style="margin-top: 10px;" *ngIf="loadingList">chargement...</p>
        </app-filter-bar>

        <div style="margin-top: 2em;">
          <p>Courrier publipostage : <a href="./assets/files/CourrierCE2020.docx">CourrierCE.docx</a></p>
          <p>Carte publipostage : <a href="./assets/files/CartesCE.doc">CartesCE.doc</a></p>
        </div>

        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [externalPaging]="true"
            [externalSorting]="true"
            [loadingIndicator]="loadingList"
            [reorderable]="false"
            (page)="setPage($event)"
            (sort)="onSort($event)"
          >
            <ngx-datatable-column [resizeable]="false" name="ID" prop="id" [width]="70">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="N° carte" prop="number" [width]="130">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{value}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Nom société" prop="loyalty_card_organization" [width]="180">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value.name }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Nom" prop="last_name" [width]="125">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngIf="row.customer">
                  {{ row.customer.last_name }}
                </ng-container>

              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Prénom" prop="first_name" [width]="125">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngIf="row.customer">
                  {{ row.customer.first_name }}
                </ng-container>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Adhérent" prop="adherent" [width]="230">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a title="Voir l'adhérent" class="ppp-link" target="_blank" [routerLink]="['/adherents-companies-management', (value.is_closed ? 'closed' : 'open'), 'edit', value.id]"
                *ngIf="value && policiesService.currentUser.policies.adherent && policiesService.currentUser.policies.adherent.update; else adherentOnlyData">
                  {{ value.name }}
                </a>
                <ng-template #adherentOnlyData>
                  {{ value.name }}
                </ng-template>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Agence" prop="agency" [width]="215">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a title="Voir l'agence" class="ppp-link" target="_blank"
                  [routerLink]="['/profilplus-agences/edit/', value.id]"
                  *ngIf="policiesService.currentUser.policies['open-agency'] && policiesService.currentUser.policies['open-agency'].update; else agencyOnlyData">
                  {{value.name}}
                </a>
                <ng-template #agencyOnlyData>
                  {{value.name}}
                </ng-template>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Date création" prop="created_at" [width]="172">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date: 'dd/MM/yy' }}
              </ng-template>
            </ngx-datatable-column>

            <!-- <ngx-datatable-column name="Actions" [width]="80" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="authService.currentUserValue.policies['myprofilplus-customer'].update"
                    [routerLink]="['/myprofilplus/customers/edit/', row.id]"
                    type="button" class="btn btn-success" title="Modifier les informations">
                    <i class="material-icons">edit</i>
                </a>
              </ng-template>
            </ngx-datatable-column> -->

          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
