import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import * as $ from 'jquery';
import 'bootstrap-notify';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterRouteChange = false;
    private defaultNotifyOptions = {
    element: 'body',
    position: null,
    type: 'info',
    allow_dismiss: true,
    allow_duplicates: true,
    newest_on_top: true,
    showProgressbar: false,
    placement: {
      from: 'bottom',
      align: 'left'
    },
    offset: 20,
    spacing: 10,
    z_index: 1031,
    delay: 5000,
    timer: 1000,
    url_target: '_blank',
    mouse_over: null,
    animate: {
      enter: 'animated fadeInDown',
      exit: 'animated fadeOutUp'
    },
    onShow: null,
    onShown: null,
    onClose: null,
    onClosed: null,
    icon_type: 'class',
    template: `
      <div data-notify="container" class="col-11 col-md-4 alert alert-{0} alert-with-icon" role="alert">
        <i data-notify="icon" class="material-icons">notifications</i>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <i class="material-icons">close</i>
        </button>
        <span data-notify="title">{1}</span> <span data-notify="message">{2}</span>
        <div class="progress" data-notify="progressbar">
          <div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;">
          </div>
        </div>
        <a href="{3}" target="{4}" data-notify="url"></a>
      </div>`
    };

  constructor(private router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert message
          this.clear();
        }
      }
    });


    this.getAlert()
    .subscribe(message => {
      // Notify
      if (message && message.text) {
        $[`notify`]({
            icon: 'add_alert',
            message: message.text
          }, {
            ...this.defaultNotifyOptions,
            type: message.type,
            delay: message.noDelay ? false : 5000,
            placement: {
              from: 'bottom',
              align: 'left'
            }
          });
      }
    });
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  success(message: string,
          keepAfterRouteChange: boolean = false,
          options: {btn: boolean, callback: any} = null) {
    this.keepAfterRouteChange = keepAfterRouteChange;

    if (options !== null && options.btn && options.callback) { // Add link with callback
      message += ` <a href="#" id="notification-btn">cliquez ici</a>`;

      this.subject.next({ type: 'success', text: message, noDelay: true });

      // Add Event on click
      const link = document.querySelector('#notification-btn');
      if (link) {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          options.callback();
        });
      }
    } else { // Normal toast
      this.subject.next({ type: 'success', text: message });
    }
  }

  error(message: string, keepAfterRouteChange: boolean = false) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({ type: 'danger', text: message });
  }

  warning(message: string, keepAfterRouteChange: boolean = false) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({ type: 'warning', text: message, noDelay: true });
  }

  clear() {
    // clear by calling subject.next() without parameters
    this.subject.next(null);
  }
}
