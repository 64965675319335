import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users.component';
import { ListComponent } from './list/list.component';
import { AdherentsAgenciesComponent } from './adherents-agencies/adherents-agencies.component';
import { EditComponent } from './edit/edit.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { RulesComponent } from './rules/rules.component';
import { AdminsComponent } from './admins/admins.component';
import { EditComponent as AdminsEditComponent } from './admins/edit/edit.component';

@NgModule({
  imports:
  [
    SharedModule,
    CommonModule,
    UsersRoutingModule,

    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot()
  ],
  declarations: [
    UsersComponent,
    ListComponent,
    AdherentsAgenciesComponent,
    EditComponent,
    RulesComponent,
    AdminsComponent,
    AdminsEditComponent
  ],
  providers: []
})
export class UsersModule {}
