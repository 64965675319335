import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { LoyaltyRule } from '../models/LoyaltyRule';

@Injectable({ providedIn: 'root' })
export class LoyaltyRulesService {
  constructor(private apiService: ApiService) {}

  /**
   * Get a specific loyalty rule
   * @param loyaltyRuleId loyalty rule ID
   */
  getById(loyaltyRuleId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('loyalty_rules/' + loyaltyRuleId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get all loyalty rules
   */
  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('loyalty_rules')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Create or update a specific loyalty rule
   * @param loyaltyRule rule The corresponding loyalty rule
   */
  edit(loyaltyRule) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('loyalty_rule:name', loyaltyRule.name ? loyaltyRule.name : '');
      formData.append('loyalty_rule:coefficient', loyaltyRule.coefficient ? loyaltyRule.coefficient : 0);
      formData.append('loyalty_rule:round', loyaltyRule.round ? loyaltyRule.round : 0);
      formData.append('loyalty_rule:extra_points', loyaltyRule.extra_points ? loyaltyRule.extra_points : 0);
      formData.append('loyalty_rule:start_at', loyaltyRule.start_at.toString());
      formData.append('loyalty_rule:end_at', loyaltyRule.end_at.toString());
      formData.append('loyalty_rule:active', loyaltyRule.active ? loyaltyRule.active : false);
      if (loyaltyRule.id) {
        this.apiService.put('loyalty_rules/' + loyaltyRule.id, formData)
        .then((response: any) => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
      } else {
        this.apiService.post('loyalty_rules', formData)
        .then((response: any) => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }

  /**
   * Delete a specific loyalty rule
   * @param loyaltyRuleId loyalty rule ID
   */
  delete(loyaltyRuleId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('loyalty_rules/' + loyaltyRuleId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
