import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/User';


import Swal from 'sweetalert2';

import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { EnumsService } from 'src/app/services/enums.service';
import { WorkersService } from 'src/app/services/workers.service';
import {BehaviorSubject} from 'rxjs';
import {Customer} from '../../../models/Customer';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [PoliciesService]
})
export class ListComponent implements OnInit {
  public items: Customer[] = null;
  public loading: boolean = false;
  public loadingList: boolean = false;
  @ViewChild('datatable') datatable: any;
  public filtersSelectedAsObservable = new BehaviorSubject(null)

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters = [];

  constructor(
    private cd: ChangeDetectorRef,
    private workersService: WorkersService,
    private userService: UserService,
    private alertService: AlertService,
    private authService: AuthService,
    public policiesService: PoliciesService,
    public enumsService: EnumsService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
      this.loading = true;
      this.enumsService.observable.subscribe({
      complete: () => {
        this.filters.push({
          name: 'Email',
          slug: 'email',
          type: 'email',
          class: 'col-2',
          value: null
        });

        this.filters.push({
          name: 'Nom',
          slug: 'last_name',
          type: 'text',
          class: 'col-2',
          value: null
        });
        this.filters.push({
          name: 'Prénom',
          slug: 'first_name',
          type: 'text',
          class: 'col-2',
          value: null
        });
        this.filters.push({
          name: 'Adhérent',
          slug: 'adherent_name',
          type: 'autocomplete',
          class: 'col-2',
          options: this.enumsService.enums.adherents
        });
        this.filters.push({
          name: 'Agence',
          slug: 'agency_name',
          type: 'autocomplete',
          class: 'col-2',
          options: this.enumsService.enums.agencies
        });

        this.loading = false;
      }});
    }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  setPage(pageInfo, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected;
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;
    this.userService.getAllWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 20;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

  onDelete(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.userService.delete(id)
          .then(() => {
            const index = this.items.findIndex(e => e.id === id);
            if (index !== -1) {
              this.items.splice(index, 1);
              this.items = [...this.items];
              this.alertService.success('Utilisateur supprimé avec succès.');
            }
          })
          .catch((err) => {
            this.alertService.error(err);
          });
      }
    });
  }

  exportUsers() {
    this.workersService.startNewWorker('users_export', {}).then((response) => {
      this.alertService.success('Export de la liste des utilisateurs en cours, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }
}
