<ng-container *ngIf="modules[currentModule] && currentService.slug && currentItem">
  <app-topbar moduleSlug="profilplus-direct">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/'+currentModule]">{{modules[currentModule].name}}</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/'+currentModule, currentService.slug]">Liste des {{currentService.name}}</a></li>
          <li *ngIf="currentService.sub" class="breadcrumb-item">
            <a [routerLink]="['/'+currentModule,  currentService.slug, currentService.sub.slug]">{{currentService.sub.name}}</a>
          </li>
          <li class="breadcrumb-item active"><a href="javascript:;">Détails</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <app-show-content [fields]="fields" [item]="currentItem">
    <ng-template #template let-default let-item="item" let-field="field">

      <!-- CARTES DE FIDÉLITÉ -->
      <ng-container *ngIf="field.slug === 'customer' && item.customer">
        <strong>Client :</strong> {{item.customer.first_name}} {{item.customer.last_name}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'loyalty_card_organization' && item.loyalty_card_organization">
        <strong>Société :</strong> {{item.loyalty_card_organization.name}}
      </ng-container>

      <!-- HISTORIQUE TRANSACTIONS CARTES -->
      <ng-container *ngIf="field.slug === 'name' && (item.customer.first_name || item.customer.last_name)">
        <strong>Nom :</strong> {{item.customer.first_name}} {{item.customer.last_name}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'loyalty_rule' && item.loyalty_rule">
        <strong>Promotion :</strong> {{item.loyalty_rule.name}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'amount'">
        <strong>Montant d'achats :</strong> {{item.amount ? item.amount : "0.00"}} €
      </ng-container>
      <ng-container *ngIf="field.slug === 'earned_amount' && item.earned_amount">
        <strong>Montant gagné (€) :</strong> {{item.earned_amount}} €
      </ng-container>
      <ng-container *ngIf="field.slug === 'agency' && item.agency">
        <strong>Agence :</strong> {{item.agency.name}}
      </ng-container>

      <!-- SOCIÉTÉS -->
      <ng-container *ngIf="field.slug === 'created_count' && item.loyalty_cards && item.loyalty_cards.created_count">
        <strong>Nombre de cartes de fidélité créées :</strong> {{item.loyalty_cards.created_count}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'activated_count' && item.loyalty_cards && item.loyalty_cards.activated_count">
        <strong>Nombre de cartes de fidélité activées :</strong> {{item.loyalty_cards.activated_count}}
      </ng-container>

    </ng-template>

  </app-show-content>

  <div>
    <a [routerLink]="['/'+currentModule, currentService.slug]" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
      Retour
    </a>
  </div>

</ng-container>
