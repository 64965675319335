import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { InterventionComment } from 'src/app/models/InterventionComment';

import { AlertService } from 'src/app/services/alert.service';
import { InterventionsService } from 'src/app/services/interventions.service';
import { EnumsService } from 'src/app/services/enums.service';
import { Intervention } from 'src/app/models/Intervention';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  public items: Array<InterventionComment> = null;
  public currentComments: InterventionComment = null;

  public totalPages: number;
  public totalItems: number;
  public currentPage: number;
  public nextPage: any;

  public loading: boolean = false;
  public loadingList: boolean = false;

  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [
    {
      name: 'Contenu',
      slug: 'content',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Date de début',
      slug: 'start_at',
      type: 'date',
      class: 'col-2',
      value: null
    },
    {
      name: 'Date de fin',
      slug: 'end_at',
      type: 'date',
      class: 'col-2',
      value: null
    }
  ];
  public optionsFilteredByType: any;
  constructor(
    private cd: ChangeDetectorRef,
    private interventionsService: InterventionsService,
    private enumsService: EnumsService,
    private alertService: AlertService) {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.loading = false;
        this.filters.push({
          name: 'Intervention',
          slug: 'ba_reference',
          selected_emit_property: 'ba_reference',
          property: "ba_reference",
          type: 'autocomplete',
          class: 'col-2',
          options: [],
          callback: (e, filter) => {
            if (e) {
              this.interventionsService.getAll({
                ba_reference: e
              }).then((interventions: Intervention[]) => {
                filter.options = interventions.filter(intervention => intervention.ba_reference)
              })
            } else {
              filter.options = []
            }
          }
        })
      }
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  showData(item) {
    this.currentComments = item;

    this.cd.detectChanges();

    // @ts-ignore: Unreachable code error
    jQuery('#comments-modal').modal();
    jQuery('#comments-modal').on('hidden.bs.modal', (e) => {
      this.currentComments = null;
    });
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;

    this.interventionsService.getCommentsWithPagination(this.pageOffsetSelected + 1, sortBy, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }
}
