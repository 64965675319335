import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

import { LoyaltyCardOrder } from '../models/LoyaltyCardOrder';

@Injectable({ providedIn: 'root' })
export class LoyaltyCardOrdersService {
  constructor(private apiService: ApiService) {}

  getById(loyaltyCardOrderId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('loyalty_card_orders/' + loyaltyCardOrderId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('loyalty_card_orders')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllWithPagination(page: string | number, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('loyalty_card_orders?page=' + page
      + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(loyaltyCardOrder) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('loyalty_card_order:amount', loyaltyCardOrder.amount ? loyaltyCardOrder.amount : 0);
      formData.append('loyalty_card_order:agency_id', loyaltyCardOrder.agency);
      if (loyaltyCardOrder.id) {
        this.apiService.put('loyalty_card_orders/' + loyaltyCardOrder.id, formData)
        .then((response: any) => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
      } else {
        this.apiService.post('loyalty_card_orders', formData)
        .then((response: any) => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }

  delete(loyaltyCardOrderId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('loyalty_card_orders/' + loyaltyCardOrderId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
