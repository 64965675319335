import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

import { Questionnaire } from '../models/Questionnaire';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';
import { Contact } from '../models/Contact';
import { convertFileToBase64 } from '../helpers/convertFiles';
import { TextractClient, AnalyzeDocumentCommand, AnalyzeDocumentCommandInput } from "@aws-sdk/client-textract";
import { Buffer } from "buffer"
import config from "../config"

@Injectable({ providedIn: 'root' })
export class ContactsService {
  constructor(private apiService: ApiService) {

  }

  getById(questionnaireId, contactId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('questionnaires/' + questionnaireId + '/contacts/' + contactId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });

    });
  }

  getAll(sortBy: { field: string, order: string } = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('contacts' + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '')
        + (filters ? '&' + parseFiltersForUrl(filters) : '')
      ).then((response) => {
        resolve(response);
      })
        .catch((err) => {
          reject(err);
        });
    });
  }


  getAllWithPagination(page: string | number, sortBy: { field: string, order: string } = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('contacts?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '')
        + (filters ? '&' + parseFiltersForUrl(filters) : '')
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(questionnaireId: number | string, contact: Contact) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('contact:society_fields_answers', JSON.stringify(contact.society_fields_answers));
      formData.append('contact:contact_fields_answers', JSON.stringify(contact.contact_fields_answers));
      formData.append('contact:rubrics_answers', JSON.stringify(contact.rubrics_answers));

      formData.append('contact:user_id', contact.user_id ? JSON.stringify(contact.user_id) : '');

      if (contact.id) {
        this.apiService.put('questionnaires/' + questionnaireId + '/contacts/' + contact.id, formData)
          .then((response: Questionnaire) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        this.apiService.post('questionnaires/' + questionnaireId + '/contacts', formData)
          .then((response: Questionnaire) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      }

    });
  }

  delete(contactId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('contacts/' + contactId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  sendDocumentary(fields: any, contactId: number | string) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append('contact:other_recipients', fields.other);

      // to save the email if is not saved previously
      formData.append('contact:email', fields.email);

      fields.files.forEach((f, index) => {
        formData.append(`contact:files[${index}]`, f);
      })

      this.apiService.post('contacts/' + contactId + '/documentary', formData)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  fileToByteArray(file) {
    return new Promise((resolve, reject) => {
        try {
            let reader = new FileReader();
            let fileByteArray = [];
            reader.readAsArrayBuffer(file);
            reader.onloadend = (evt) => {
              if (evt.target.readyState == FileReader.DONE) {
                let arrayBuffer = evt.target.result,
                // @ts-ignore
                array = new Uint8Array(arrayBuffer);
                for (let byte of array) {
                  fileByteArray.push(byte);
                }
              }
              resolve(fileByteArray);
            }
        }
        catch (e) {
            reject(e);
        }
    })
}

  scan(file, queries: {Text: string, Alias?: string, Page?: string[]}[]) {
    return new Promise((resolve, reject) => {
      this.fileToByteArray(file)
        .then((response) => {
          // START WITH AWS CLIENT
          const client = new TextractClient({
            region: "eu-west-1",
            credentials: {
              accessKeyId: config.awsCredentials.accessKeyId,
              secretAccessKey: config.awsCredentials.secretKey
            }
          });

          // let blob = response.replace('data:', '').replace(/^.+,/, '');

          const params = {
            Document: {
              Bytes: response
            },
            FeatureTypes: [
              "QUERIES"
            ],
            QueriesConfig: {
              Queries: [
                ...queries
              ]
            }
          };

          // @ts-ignore
          const command = new AnalyzeDocumentCommand(params);

          client
          .send(command)
          .then((data) => {
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          });
        })
        .catch((err) => {
          reject(err);
        })
    })
  }
}
