import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { SkillsService } from 'src/app/services/skills.service';

import { Skill } from 'src/app/models/Skill';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-skills-edit',
  templateUrl: './skills-edit.component.html',
  styleUrls: ['./skills-edit.component.scss']
})
export class SkillsEditComponent implements OnInit {
  public editFormGroup: UntypedFormGroup;
  public submitted: boolean = false;
  public editMode: any = {
    active: false,
    data: null
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private enumsService: EnumsService,
    private skillsService: SkillsService,
    public loadingService: LoadingService) {

      this.enumsService.observable.subscribe({
        complete: () => { // LAUNCH COMPONENT
          if (this.route.snapshot.params.skill_id) { // EDIT SKILL
            this.editMode.active = true;
            this.skillsService.getById(this.route.snapshot.params.skill_id)
              .then((response: Skill) => {
                this.editMode.data = response;
                this.editFormGroup = this.formBuilder.group({
                  name: [response.name, Validators.required],
                  rank: [response.rank, null]
                });
              })
              .catch((err) => {
                this.alertService.error(err);
              });
          } else { // NEW SKILL
            this.editFormGroup = this.formBuilder.group({
              name: ['', Validators.required]
            });
          }
        }
      });
    }

  ngOnInit(): void {}


  ngAfterContentInit() {}

  get f() { return this.editFormGroup.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const skill = {
      ...this.editFormGroup.value
    };
    if (this.editMode.active) {
      skill.id = this.route.snapshot.params.skill_id;
    }
    this.skillsService.edit(skill)
      .then((response) => {
        if (!this.editMode.active) {
          // ADD TO ENUMS
          this.enumsService.enums.skills.push(response);
        }
        this.router.navigate(['/profilplus-agences/skills']);
        this.alertService.success((this.editMode && this.editMode.active) ? 'Modification enregistrée avec succès.' : 'Métier enregistré avec succès.');
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

}
