import { Injectable } from '@angular/core';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class OrdersService {
  constructor(private apiService: ApiService) {}

  getById(id: number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('orders/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('orders')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllWithPagination(page: string | number, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('orders?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
        + (filters ? '&' + parseFiltersForUrl(filters) : '')
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
