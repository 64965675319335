<app-topbar moduleSlug="profilplus-site">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Formulaires Profil Plus</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row" *ngIf="policiesService.currentUser.policies['profilplus-form'].create">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round"

       [routerLink]="['/website/profilplus-forms/edit']">
      <i class="material-icons">library_add</i>
      Créer un nouveau formulaire
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">schedule</i>
        </div>
        <h4 class="card-title">Formulaires Profil Plus
          <strong class="description"> en cours et à venir</strong>
        </h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <ngx-datatable
            class="material"
            [rows]="currentItems"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [loadingIndicator]="">
            <ngx-datatable-column name="ID" prop="id" [width]="70">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Titre du formulaire" prop="title" [width]="250">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Date de début" prop="start_at" [width]="160">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date: 'dd/MM/yy' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Date de fin" prop="end_at" [width]="160">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date: 'dd/MM/yy' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Univers" prop="universe" [width]="100">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Type de formulaire" prop="form_type" [width]="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a [routerLink]="['/website/profilplus-forms/edit/', row.id]"
                   *ngIf="policiesService.currentUser.policies['profilplus-form'].update"
                   type="button" class="btn btn-success" title="Modifier les informations">
                  <i class="material-icons">edit</i>
                </a>
                <button class="btn btn-success" type="button" title="Télécharger les statistiques" (click)="downloadCharts(row.id)">
                  <i class="material-icons">pie_chart</i>
                </button>
                <a [routerLink]="['/website/profilplus-forms/edit/', row.id, 'clone']"
                   *ngIf="policiesService.currentUser.policies['profilplus-form'].create"
                   class="btn btn-success" type="button" title="Dupliquer">
                  <i class="material-icons">content_copy</i>
                </a>
                <button type="button" class="btn btn-danger" title="Supprimer" (click)="onDelete(row.id)"
                  *ngIf="policiesService.currentUser.policies['profilplus-form'].delete">
                  <i class="material-icons">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">archive</i>
        </div>
        <h4 class="card-title">Formulaires Profil Plus
          <strong class="description"> archivés</strong>
        </h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <ngx-datatable
            class="material"
            [rows]="archivedItems"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [loadingIndicator]="">
            <ngx-datatable-column name="ID" prop="id" [width]="70">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Titre du formulaire" prop="title" [width]="250">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Date de début" prop="start_at" [width]="160">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date: 'dd/MM/yy' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Date de fin" prop="end_at" [width]="160">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date: 'dd/MM/yy' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Univers" prop="universe" [width]="100">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Type de formulaire" prop="form_type" [width]="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a [routerLink]="['/website/profilplus-forms/edit/', row.id]"
                   *ngIf="policiesService.currentUser.policies['profilplus-form'].update"
                   type="button" class="btn btn-success" title="Modifier les informations">
                  <i class="material-icons">edit</i>
                </a>
                <button class="btn btn-success" type="button" title="Télécharger les statistiques" (click)="downloadCharts(row.id)">
                  <i class="material-icons">pie_chart</i>
                </button>
                <a [routerLink]="['/website/profilplus-forms/edit/', row.id, 'clone']"
                   *ngIf="policiesService.currentUser.policies['profilplus-form'].create"
                   class="btn btn-success" type="button" title="Dupliquer">
                  <i class="material-icons">content_copy</i>
                </a>
                <button type="button" class="btn btn-danger" title="Supprimer" (click)="onDelete(row.id, true)"
                        *ngIf="policiesService.currentUser.policies['profilplus-form'].delete">
                  <i class="material-icons">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
