import { Injectable } from '@angular/core';

import 'bootstrap-notify';
import { Vehicule } from '../models/Vehicule';
import { ApiService } from './api.service';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';

@Injectable({ providedIn: 'root' })
export class VehiculesService {
  constructor(private apiService: ApiService) {

  }

  getById(vehicleId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('vehicles/' + vehicleId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllWithPagination(page: string | number, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('vehicles?page=' + page
      + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
      + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(vehicle) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('vehicule:model', vehicle.model ? vehicle.model : '');
      formData.append('vehicule:kilometers', vehicle.kilometers ? vehicle.kilometers : '');
      formData.append('vehicule:immatriculation', vehicle.immatriculation ? vehicle.immatriculation : '');
      formData.append('vehicule:customer_id', vehicle.customer_id ? vehicle.customer_id : '');

      if (vehicle.id) {
        this.apiService.put('vehicles/' + vehicle.id, formData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      } else {
        this.apiService.post('vehicles', formData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }

  delete(vehicleId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('vehicles/' + vehicleId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
