import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/User';
import { AlertService } from 'src/app/services/alert.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { UserService } from 'src/app/services/user.service';
import modules from '../../../../assets/modules.json';
import modelLabels from '../../../../assets/modelLabels.json';


@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit {
  public user: User;
  public modules: any;
  public modulesList: any;
  public modelLabels: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private alertService: AlertService,
    public policiesService: PoliciesService) { }

  ngOnInit(): void {
    this.modulesList = Object.keys(modules);
    this.modules = modules;
    this.modelLabels = modelLabels;
    this.userService.getById(this.route.snapshot.params.id)
      .then((user: User) => {
        this.user = user;
        this.policiesService.currentUser  = user;
      })
      .catch((err) => {
        this.alertService.error(err.message);
        this.router.navigate(['/users-management']);
      });
  }

  save() {
    this.policiesService.save()
      .then(() => {
        this.alertService.success("Droits d'accès mis à jour avec succès.");
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
          this.router.navigate(['/users-management', 'edit', this.route.snapshot.params.id, 'rules']));
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

  changeLine(model, state) {
    if (state !== undefined) {
      const rules = ['read', 'create', 'update', 'delete']
      rules.forEach(rule => {
        this.policiesService.updateRule(model, rule, state)
      });
    }
  }

  changeColumn(rule, state) {
    if (state !== undefined) {
      this.modulesList.forEach(module => {
        this.modules[module].models.forEach(model => {
          this.policiesService.updateRule(model, rule, state)
        });
      });
    }
  }
}
