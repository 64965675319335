import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { EnumsService } from 'src/app/services/enums.service';
import { AlertService } from 'src/app/services/alert.service';
import { AgenciesService } from 'src/app/services/agencies.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { AuthService } from 'src/app/services/auth.service';

import { Agency } from 'src/app/models/Agency';

@Component({
  selector: 'app-edit-close-dates',
  templateUrl: './edit-close-dates.component.html',
  styleUrls: ['./edit-close-dates.component.scss']
})
export class EditCloseDatesComponent implements OnInit {
  public exceptionalClosureTypes = [
    {name: ''},
    {name: 'COVID'},
    {name: 'Inventaire'},
    {name: 'Congé annuel'},
    {name: 'Travaux'}
  ];

  public preselectExceptionalClosureType: number;
  public loading: boolean = false;
  public agency: Agency;
  public closeDates: Array<any> = [];

  public editFormGroup: UntypedFormGroup = null;
  public editFormGroup2: UntypedFormGroup;
  public submitted: boolean = false;
  public submitted2: boolean = false;
  public loadingList: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private enumsService: EnumsService,
    private alertService: AlertService,
    private agenciesService: AgenciesService,
    public loadingService: LoadingService,
    public policiesService: PoliciesService,
    private authService: AuthService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
    }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.loading = false;
        this.loadingList = true;
        if (this.router.url.split('/').includes('open-agencies')) {
          this.agency = this.enumsService.enums.agencies.find(a => a.id === parseInt(this.route.snapshot.params.id));
        } else {
          this.agency = this.enumsService.enums.agencies_closed.find(a => a.id === parseInt(this.route.snapshot.params.id));
        }

        this.agenciesService.getCloseDates(this.route.snapshot.params.id).then((response: Array<any>) => {
          this.loadingList = false;
          this.closeDates = response;
        })
        .catch(err => {
          this.loadingList = false;
          this.alertService.error(err);
        });
      }
    });
  }

  edit(id = null) {
    this.editFormGroup = this.formBuilder.group({
      id: ['', null],
      name: ['', Validators.required],
      during: ['', Validators.required],
      during_end: ['', Validators.required]
    });


    if (id) {// PRE LOAD DATA
      const preselect = this.closeDates.find(cd => cd.id === id);
      if (preselect) {
        this.editFormGroup.patchValue({id, name: preselect.name, during: preselect.during});
        this.editFormGroup.get('during').clearValidators()
        this.editFormGroup.get('during').updateValueAndValidity()
        this.editFormGroup.get('during_end').clearValidators()
        this.editFormGroup.get('during_end').updateValueAndValidity()
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    this.agenciesService.editClosedDate(this.route.snapshot.params.id, this.editFormGroup.value).then((response: any) => {
      this.alertService.success('Date de fermeture' + (this.editFormGroup.value.id !== null  ? ' mise à jour' : ' créée'));
      window.location.reload();
    })
    .catch(err => {
      this.alertService.error(err);
    });
  }

  get f() {
    return this.editFormGroup.controls;
  }

  onDelete(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cette journée de fermeture ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
      this.agenciesService.deleteCloseDates(this.route.snapshot.params.id, id)
        .then(() => {
          const index = this.closeDates.findIndex(cd => cd.id === id);
          if (index !== -1) {
            this.closeDates.splice(index, 1);
            this.closeDates = [...this.closeDates];
              this.alertService.success('Journée de fermeture supprimée avec succès.');
          }
        })
        .catch((err) => {
          this.alertService.error(err);
        });
      }
    });
  }

  onChangeStartAt($event) {
    if ($event.target.value && new Date($event.target.value) < new Date()) {
      this.editFormGroup.patchValue({during: new Date().toISOString().slice(0, 10)});
    }
    if (this.editFormGroup.value.during_end) {
      if ($event.target.value && $event.target.value > this.editFormGroup.value.during_end) {
        this.editFormGroup.patchValue({during: this.editFormGroup.value.during_end});
      }
    }
  }

  onChangeEndAt($event) {
    if ($event.target.value && new Date($event.target.value) < new Date()) {
      this.editFormGroup.patchValue({during_end: new Date().toISOString().slice(0, 10)});
    }
    if (this.editFormGroup.value.during) {
      if ($event.target.value && $event.target.value < this.editFormGroup.value.during) {
        this.editFormGroup.patchValue({during_end: this.editFormGroup.value.during});
      }
    }
  }

  onSort(event) {
    console.log('Sort Event', event);
  }
}
