import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpComponent } from '../help/help.component';
import { AuthGuard } from 'src/app/helpers/auth.guard';
import { IndexContentComponent } from 'src/app/components/index-content/index-content.component';

/* HOMEPAGE */
import { GestionApaComponent } from './gestion-apa.component';

/* ADHERENTS */
import { AdherentsComponent } from './adherents/adherents.component';
import { AdherentsEditComponent } from './adherents/adherents-edit/adherents-edit.component';

/* COMPANIES */
import { CompaniesComponent } from './companies/companies.component';
import { CompaniesEditComponent } from './companies/companies-edit/companies-edit.component';

const routes: Routes = [
  {
    path: 'adherents-companies-management',
    component: GestionApaComponent,
    canActivate: [AuthGuard],
    children: [
      /* GENERAL */
      {
        path: '',
        component: IndexContentComponent,
      },
      {
        path: 'help',
        component: HelpComponent,
        canActivate: [AuthGuard],
      },
      /* ADHERENTS */
      {
        path: 'open',
        component: AdherentsComponent,
      },
      {
        path: 'open/edit/:adherent_id',
        component: AdherentsEditComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'open/edit',
        component: AdherentsEditComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'closed',
        component: AdherentsComponent,
      },
      {
        path: 'closed/edit/:adherent_id',
        component: AdherentsEditComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'closed/edit',
        component: AdherentsEditComponent,
        canActivate: [AuthGuard]
      },
      /* COMPANIES */
      {
        path: 'companies',
        component: CompaniesComponent,
      },
      {
        path: 'company/edit/:company_id',
        component: CompaniesEditComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'company/edit',
        component: CompaniesEditComponent,
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionApaRoutingModule {}
