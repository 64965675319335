import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { ServicesService } from 'src/app/services/services.service';
import { SkillsService } from 'src/app/services/skills.service';

import { Service } from 'src/app/models/Service';
import { LoadingService } from 'src/app/services/loading.service';
import { SpecialOffersService } from 'src/app/services/special-offers.service';
import { SpecialOffer } from 'src/app/models/SpecialOffer';

@Component({
  selector: 'app-services-edit',
  templateUrl: './services-edit.component.html',
  styleUrls: ['./services-edit.component.scss']
})
export class ServicesEditComponent implements OnInit {
  public skills: Array<any>;
  public preselectSkills: number;
  preselectSpecialOffer: number;
  public editFormGroup: UntypedFormGroup;
  public submitted: boolean = false;
  public editMode: any = {
    active: false,
    data: null
  };
  public specialOffers: Array<SpecialOffer> = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private enumsService: EnumsService,
    private servicesService: ServicesService,
    private skillsService: SkillsService,
    private specialOffersService: SpecialOffersService,
    public loadingService: LoadingService) {

      this.enumsService.observable.subscribe({
        complete: () => {


          if (this.route.snapshot.params.service_id) {
            this.editMode.active = true;
            this.servicesService.getById(this.route.snapshot.params.service_id)
              .then((response: Service) => {
                this.editMode.data = response;
                this.editFormGroup = this.formBuilder.group({
                  service_name: [response.name, null],
                  service_title: [response.title, null],
                  service_b2c_quotation: [response.b2c_quotation, null],
                  service_b2b_quotation: [response.b2b_quotation, null],
                  service_rank: [response.rank, Validators.min(0)],
                  service_skill: [response.skill.id, Validators.required],
                  service_options: [response.options],
                });


                this.skillsService.getAll()
                .then((skills: Array<any>) => {
                  this.skills = skills;
                  this.preselectSkills = this.skills.findIndex(a => a.id === response.skill.id);
                })
                .catch((err) => {
                  this.alertService.error(err);
                });

              })
              .catch((err) => {
                this.alertService.error(err);
              });
          } else {
            this.editFormGroup = this.formBuilder.group({
              service_name: ['', null],
              service_title: ['', null],
              service_b2c_quotation: [false, null],
              service_b2b_quotation: [false, null],
              service_rank: [0, Validators.min(0)],
              service_skill: [null, Validators.required],
              service_options: [null, null],
            });

            this.skillsService.getAll().then((skills: Array<any>) => {
              this.skills = skills;
            })
            this.specialOffersService.getAll().then((specialOffers: Array<SpecialOffer>) => {
              this.specialOffers = specialOffers;
            })
          }
        }
      });
    }

  ngOnInit(): void {}

  get f() { return this.editFormGroup.controls; }

  onSelectSkill($event) {
    if ($event) {
      this.editFormGroup.patchValue({service_skill: $event.id});
    } else {
      this.editFormGroup.patchValue({service_skill: null});
    }
  }

  onSelectSpecialOffer($event) {
    if($event) {
      // this.editFormGroup.patchValue({service_special_offers: $event});
      if (!this.editFormGroup.value.service_special_offers.includes($event.id)) {
        this.editFormGroup.patchValue({service_special_offers:
          // [...this.editFormGroup.value.service_special_offers, $event.id]
          // authorize only one special offer
          [$event.id]
        })
      }

    } else {
      this.editFormGroup.patchValue({service_special_offers: []});
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const service = {
      ...this.editFormGroup.value
    };
    if (this.editMode.active) {
      service.id = this.route.snapshot.params.service_id;
    }

    this.servicesService.edit(service)
      .then((response: Service) => {
        if (!this.editMode.active) {
          // ADD TO ENUMS
          this.enumsService.enums.services.push(response)
        }
        this.router.navigate(['/profilplus-agences/services', 'edit', response.id]);
        this.alertService.success((this.editMode && this.editMode.active) ? 'Modification enregistrée avec succès.' : 'Service enregistré avec succès.');
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

}
