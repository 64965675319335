import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { convertFileToBase64 } from '../../../../helpers/convertFiles';

import Swal from 'sweetalert2';

import { EnumsService } from 'src/app/services/enums.service';
import { AgenciesService } from 'src/app/services/agencies.service';
import { AlertService } from 'src/app/services/alert.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { AuthService } from 'src/app/services/auth.service';
import { WorkersService } from 'src/app/services/workers.service';
import { FileInputComponent } from 'src/app/components/file-input/file-input.component';

@Component({
  selector: 'app-open-agencies',
  templateUrl: './open-agencies.component.html',
  styleUrls: ['./open-agencies.component.scss']
})
export class OpenAgenciesComponent implements OnInit {
  @ViewChild('FileInput') FileInput: FileInputComponent;

  public items: Array<any> = null;
  public loading: boolean = false;
  public loadingList: boolean = false;
  public fileImporting: boolean = false;
  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [];

  constructor(
    private cd: ChangeDetectorRef,
    private workersService: WorkersService,
    private authService: AuthService,
    public enumsService: EnumsService,
    private alertService: AlertService,
    private agenciesService: AgenciesService,
    public policiesService: PoliciesService,
    public router: Router) {
      this.policiesService.currentUser = this.authService.currentUserValue;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          this.filters.push({
            name: 'Nom',
            slug: 'agency_name',
            type: 'autocomplete',
            class: 'col-2',
            options: this.enumsService.enums.agencies
          });
          this.filters.push({
            name: 'Société',
            slug: 'company_name',
            type: 'autocomplete',
            class: 'col-2',
            options: this.enumsService.enums.companies
          });
          this.filters.push({
            name: 'Adhérent',
            slug: 'adherent_name',
            type: 'autocomplete',
            class: 'col-2',
            options: this.enumsService.enums.adherents
          });
          this.filters.push({
            name: 'Nom du chef d\'agence',
            slug: 'lastname_manager',
            type: 'text',
            class: 'col-2',
            value: null
          });
          this.loading = false;
        }
      });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  downloadAgenciesMultiWorksheet() {
    this.workersService.startNewWorker('agencies_export', {}).then((response) => {
      this.alertService.success('Export de la liste des agences en cours, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

  downloadOnlineAgenciesMonoWorksheet() {
    this.workersService.startNewWorker('agencies_export', {mono_online_agencies: true}).then((response) => {
      this.alertService.success('Export de la liste des agences ouvertes en cours, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

  downloadPLV()  {
    this.workersService.startNewWorker('agencies_export', {plv: true}).then((response) => {
      this.alertService.success('Export PLV, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

  downloadADV()  {
    this.workersService.startNewWorker('agencies_export', {adv: true}).then((response) => {
      this.alertService.success('Export de la liste adv, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

  downloadEMA()  {
    this.workersService.startNewWorker('agencies_export', {ema: true}).then((response) => {
      this.alertService.success('Export de la liste ema, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

  importFile($event) {
    if ($event) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-info'
        },
        buttonsStyling: false,
        reverseButtons: true,
        focusConfirm: false
      });

      swalWithBootstrapButtons.fire({
        title: 'Attention !',
        text: 'Attention, vous êtes sur le point de mettre à jour les informations de plusieurs agences. Le temps de traitement de votre fichier peut prendre plusieurs minutes. Vous pouvez retrouver les informations de votre import dans le menu "Notifications".',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Confirmer'
      }).then((result) => {
        if (result.value) {
          convertFileToBase64($event)
            .then((response: string) => {
              this.fileImporting = true;
              this.workersService.startNewWorker('agencies_import', {file: response})
                .then(() => {
                  this.alertService.success('Import en cours, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
                })
                .catch((err) => {
                  this.alertService.error(err);
                });
            });
        } else {
          // cancel
          this.FileInput.clean();
        }
      });
    }
  }

  setPage(pageInfo, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;
    this.agenciesService.getAllOpenAgenciesWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 20;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

  onDelete(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cette agence ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.agenciesService.delete(id).then(() => {
          const index = this.items.findIndex(e => e.id === id);
          if (index !== -1) {
            this.items.splice(index, 1);
            this.items = [...this.items];
            this.alertService.success('Agence supprimée avec succès.');
          }
        })
        .catch((err) => {
          this.alertService.error(err);
        });
      }
    });

  }

}
