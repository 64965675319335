<app-topbar moduleSlug="users-management">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/users-management']">Utilisateurs</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Liste des administrateurs</a></li>
        <li class="breadcrumb-item active" *ngIf="user"><a href="javascript:;">{{user.first_name}} {{user.last_name}}</a></li>
        <li class="breadcrumb-item active" *ngIf="!user"><a href="javascript:;">Créer un utilisateur</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>
<div class="row" *ngIf="editFormGroup">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">account_box</i>
        </div>
        <h4 class="card-title">{{ (edit) ? 'Édition' : 'Création'}} d'un administrateur</h4>
      </div>
      <div class="card-body">
        <form class="form" [formGroup]="editFormGroup" *ngIf="edit && user || !edit">

          <div class="input-group form-control-lg">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="material-icons">face</i>
              </span>
            </div>
            <div class="form-group col-lg-6" [ngClass]="{ 'has-danger': submitted && f.first_name.errors }">
              <label for="first_name"  class="bmd-label-floating">Prénom *</label>
              <input name="first_name" type="text" class="form-control" formControlName="first_name" required="true">
            </div>
          </div>

          <div class="input-group form-control-lg">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="material-icons">face</i>
              </span>
            </div>
            <div class="form-group col-lg-6" [ngClass]="{ 'has-danger': submitted && f.last_name.errors }">
              <label for="last_name" class="bmd-label-floating">Nom *</label>
              <input name="last_name" type="text" class="form-control" formControlName="last_name" required="true">
            </div>
          </div>

          <div class="input-group form-control-lg">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="material-icons">mail</i>
              </span>
            </div>
            <div class="form-group col-lg-6" [ngClass]="{ 'has-danger': submitted && f.email.errors }">
              <label for="email"  class="bmd-label-floating">Email *</label>
              <input name="email" type="email" class="form-control" formControlName="email" required="true">
              <label class="error" for="email" *ngIf="f.email.errors && f.email.errors.email">Veuillez entrer un email valide.</label>
            </div>
          </div>

          <div class="input-group form-control-lg">
            <div class="form-group col-lg-6">
              <div class="form-check">
                <label for="profil_plus_direct_admin" class="form-check-label">
                  <input name="profil_plus_direct_admin" id="profil_plus_direct_admin" formControlName="profil_plus_direct_admin" class="form-check-input form-control" type="checkbox">Administrateur Profil Plus Direct
                  <span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div class="category form-category">* Champs obligatoires</div>

          <input
            type="submit"
            class="ppp-button ppp-button-blue btn btn-primary btn-round"
            (click)="onSubmit()"
            [value]="edit ? 'Mettre à jour' : 'Créer'" (click)="onSubmit()">
        </form>
      </div>
    </div>
  </div>
</div>
