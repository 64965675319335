import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { BrandsService } from 'src/app/services/brands.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public loading: boolean = false;
  public editMode: {
    active: boolean,
    data: any
  } = {active: false, data: null};
  public submitted: boolean = false;
  public editFormGroup: UntypedFormGroup;

  public logo: File | string = null;

  constructor(
    private enumsService: EnumsService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public brandsService: BrandsService,
    private alertService: AlertService,
    public loadingService: LoadingService) {

      this.enumsService.observable.subscribe({
        complete: () => {
          if (this.route.snapshot.params.id) { // EDIT
            this.editMode.active = true;
            this.loading = true;
            this.brandsService.getById(this.route.snapshot.params.id).then((response: any) => {
              this.editMode.data = response;
              this.loading = false;

              this.editMode.data.logo = (response.logo) ? [{name: 'affichage de votre fichier', link: response.logo}] : null;

              this.editFormGroup = this.formBuilder.group({
                name: [response.name, Validators.required],
                title: [response.title, null],
                // logo: [response.logo, null],
                rank: [response.rank, null]
              });
            })
            .catch((err) => {
              this.loading = false;
              this.alertService.error(err);
            });
          } else {
            this.editFormGroup = this.formBuilder.group({
              name: ['', Validators.required],
              title: ['', null],
              logo: [null, null],
              rank: ['', null]
            });

          }
        }
      });
    }

  ngOnInit(): void { }

  get f() { return this.editFormGroup.controls; }


  onFileSelected(files: Array<File>) {
    this.logo = files ? files[0] : '';
    if (this.editMode.data) {
      this.editMode.data.logo = null;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    const brand = {
      ...this.editFormGroup.value,
      logo: this.logo,
    };

    if (this.editMode) {
      brand.id = this.route.snapshot.params.id;
    }
    this.brandsService.edit(brand).then(() => {
      this.router.navigate(['/website/tires/brands']);
      this.alertService.success((this.editMode && this.editMode) ? 'Modification enregistrée avec succès.' : 'Marque enregistrée avec succès.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

}
