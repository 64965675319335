import { Injectable } from '@angular/core';
import { User } from '../models/User';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class PoliciesService {
  public currentUser: User = null;

  constructor(private apiService: ApiService,
              private authService: AuthService) {

  }

  public save() {
    return new Promise((resolve, reject) => {
      this.apiService.put('users/' + this.currentUser.id + '/roles', JSON.stringify(this.currentUser.policies))
      .then((response) => {
        // CHANGE IF USER IS CURRENT AUTH USER
        if (this.currentUser.id === this.authService.currentUserValue.id) {
          const policies = JSON.parse(JSON.stringify(this.currentUser.policies)); // Prevent object referencing
          this.authService.updateAuth({
            ...this.authService.currentUserValue,
            policies
          });
        }
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  // UPGRADE POLICIES SYSTEM

  /**
   * Get a boolean if current user's module get rule with true value
   * @param model model name (user, adherent, agency...)
   * @param rule rule name (read, create, update, delete)
   */
  userHaveRule(model: string, rule: string): boolean {
    if (this.currentUser.policies[model]) {
      return this.currentUser.policies[model][rule];
    }
    return false;
  }

/**
 * return true if item.model or submodule.model
 * is enabled for current user
 * @param item
 */
  checkItem(item) {
    const itemChecking = (item.model && item.type) && this.userHaveRule(item.model, item.type);
    const subItemChecking = item['sub-items']
      && item['sub-items'].filter(s =>
        this.userHaveRule(s.model, s.type)
      ).length > 0;

    // console.log(item.name, itemChecking, subItemChecking);
    return itemChecking || subItemChecking;
  }

  /**
   * Check if module have one item or subItem from this module that works with checkedItem method
   * @param module
   */
  checkModule(module) {
    return module.items && module.items.filter(i => this.checkItem(i)).length > 0;
  }


  /**
   *  Update current user policies structure
   * @param model model name (user, adherent, agency...)
   * @param rule rule name (read, create, update, delete)
   * @param bool boolean
   */
  updateRule(model: string, rule: string, bool: boolean) {
    if (this.currentUser.policies[model]) {
      this.currentUser.policies[model][rule] = bool;
    } else {
      this.currentUser.policies[model] = {
        read: false,
        create: false,
        update: false,
        delete: false
      };
      this.currentUser.policies[model][rule] = bool;
    }

    // this.save();
  }

}
