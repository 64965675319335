import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { Skill } from '../models/Skill';

@Injectable({ providedIn: 'root' })
export class SkillsService {
  constructor(private apiService: ApiService) {

  }

  /**
   * Get a specific skill
   * @param skillId Skill ID
   */
  getById(skillId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('skills/' + skillId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get all skills
   */
  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('skills')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Create or update a specific skill
   * @param skill The corresponding skill
   */
  edit(skill) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('skill:name', skill.name ? skill.name : '');
      formData.append('skill:rank', skill.rank ? skill.rank : '');
      if (skill.id) { // UPDATE
        this.apiService.put('skills/' + skill.id, formData)
        .then((response: Skill) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      } else { // CREATE
        this.apiService.post('skills', formData)
        .then((response: Skill) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }

  /**
   * Delete a specific skill
   * @param skillId Skill ID
   */
  delete(skillId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('skills/' + skillId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
