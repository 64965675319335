import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import modules from '../../../../assets/modules.json';

import { User } from 'src/app/models/User';
import { EmailsSmsHistory } from 'src/app/models/EmailsSmsHistory';

import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { EmailsSmsHistoryService } from 'src/app/services/emails-sms-history.service';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {
  public modules;
  public modulesStringList;
  public user: User;
  currentModule: string = null;
  currentService: {name: string, slug: string, sub: any} = {
    name: null,
    slug: null,
    sub: null
  };

  currentItem: EmailsSmsHistory = null;
  public fields: Array<any>;

  constructor(private authService: AuthService,
              public emailsSmsHistoryService: EmailsSmsHistoryService,
              public alertService: AlertService,
              private route: ActivatedRoute,
              private router: Router) {

    this.currentModule =  this.router.url.split('/')[1];
    this.modules = modules;

    this.currentService.slug = this.router.url.split('/')[2];
    switch (this.currentService.slug) {
      case 'list':
        this.currentService.name =  'historiques emails/SMS';
        this.fields = [
          {name: 'ID', slug: 'id'},
          {name: 'Name', slug: 'name'},
          {name: 'To', slug: 'to'},
          {name: 'Sujet', slug: 'subject'},
          {name: 'Contenu', slug: 'body'},
          {name: 'Date', slug: 'created_at', template: true},
        ];
        this.emailsSmsHistoryService.getById(this.route.snapshot.params.id)
          .then((response: EmailsSmsHistory) => {
            this.currentItem = response;
          })
          .catch((err) => {
            this.alertService.error(err);
          });
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
    if (this.authService.currentUserValue) {
      this.modules = modules;
      this.modulesStringList = Object.keys(modules);
      this.user = this.authService.currentUserValue;
    }
  }

}
