import { Component, OnInit } from '@angular/core';

import { EnumsService } from 'src/app/services/enums.service';
import { AlertService } from 'src/app/services/alert.service';
import { SkillsService } from 'src/app/services/skills.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { AuthService } from 'src/app/services/auth.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {
  public skills: Array<any> = null;
  public loadingList: boolean = false;

  public columns = [
    {name: 'ID', slug: 'id'},
    {name: 'Dénomination', slug: 'name'}
  ];

  constructor(
    private skillsService: SkillsService,
    private alertService: AlertService,
    public enumsService: EnumsService,
    private authService: AuthService,
    public policiesService: PoliciesService) {
    this.policiesService.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.loadingList = true;
    this.skillsService.getAll()
      .then((response: Array<any>) => {
        this.loadingList = false;
        this.skills = response;
      })
      .catch((err) => {
        this.loadingList = false;
        this.alertService.error(err);
      });
  }

  onDelete(skillId) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce métier ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.skillsService.delete(skillId)
          .then(() => {
            const index = this.skills.findIndex(e => e.id === skillId);
            if (index !== -1) {
              this.skills.splice(index, 1);
              this.skills = [...this.skills];
              this.alertService.success('Métier supprimé avec succès.');
            }
          })
          .catch((err) => {
            this.alertService.error(err);
          });
      }
    });
  }
}
