<app-alert></app-alert>
<app-dashboard-layout *ngIf="user">
  <span title>Tableau de bord</span>
  <div class="ppp-dashboard" content>
    <app-topbar moduleSlug="">
      <ng-container title>
      </ng-container>
    </app-topbar>

    <hr>
    <h1>Modules internes</h1>
    <hr>

    <div class="row">
      <ng-container *ngFor="let module of modulesStringList">
        <div *ngIf="policiesService.checkModule(modules[module])" class="col-md-3 col-sm-4">
          <a [routerLink]="['/'+module]" class="ppp-dashboard-modules-item">
            <div class="card card-product">
              <div class="card-header card-header-image" data-header-animation="true">
                <img class="img" src="{{modules[module].banner}}">
              </div>
              <div class="card-body">
                <h4 class="card-title">{{modules[module].name}}</h4>
              </div>
            </div>
          </a>
        </div>
      </ng-container>
    </div>

    <hr>
    <h1>Modules externes</h1>
    <hr>

    <div class="row">
      <div class="col-md-3 col-sm-4" *ngIf="user.is_admin || user.adherents.length > 0">
        <a href="https://emploi-profilplus.fr/" class="ppp-dashboard-modules-item" target="_blank">
          <div class="card card-product">
            <div class="card-header card-header-image" data-header-animation="true">
              <img class="img" src="./assets/img/logo_profilplus_emploi.jpg">
            </div>
            <div class="card-body">
              <h4 class="card-title">Profil Plus Emploi</h4>
            </div>
          </div>
        </a>
      </div>
      <div class="col-md-3 col-sm-4" *ngIf="user.is_admin || user.adherents.length > 0">
        <a href="http://board.findyourtyre.com/connexion.php#00003000014" class="ppp-dashboard-modules-item" target="_blank">
          <div class="card card-product">
            <div class="card-header card-header-image" data-header-animation="true">
              <img class="img" src="./assets/img/logo_profilplus_appro.jpg">
            </div>
            <div class="card-body">
              <h4 class="card-title">Profil Plus Appro</h4>
            </div>
          </div>
        </a>
      </div>
      <div class="col-md-3 col-sm-4">
        <a href="https://www.profilplus-inside.fr/" class="ppp-dashboard-modules-item" target="_blank">
          <div class="card card-product">
            <div class="card-header card-header-image" data-header-animation="true">
              <img class="img" src="./assets/img/logo_inside.jpg">
            </div>
            <div class="card-body">
              <h4 class="card-title">Profil Plus Inside</h4>
            </div>
          </div>
        </a>
      </div>
      <div class="col-md-3 col-sm-4" *ngIf="user.is_admin || user.adherents.length > 0">
        <a href="http://www.staci.com/fr/e-cats/" class="ppp-dashboard-modules-item" target="_blank">
          <div class="card card-product">
            <div class="card-header card-header-image" data-header-animation="true">
              <img class="img" src="./assets/img/logo_staci.jpg">
            </div>
            <div class="card-body">
              <h4 class="card-title">Staci</h4>
            </div>
          </div>
        </a>
      </div>
      <div class="col-md-3 col-sm-4" *ngIf="user.is_admin || user.adherents.length > 0">
        <a href="http://maya.mediapost.fr/index.php?cont=login&erreur=noaccess" class="ppp-dashboard-modules-item" target="_blank">
          <div class="card card-product">
            <div class="card-header card-header-image" data-header-animation="true">
              <img class="img" src="./assets/img/logo_mediapilot.jpg">
            </div>
            <div class="card-body">
              <h4 class="card-title">Mediapilot</h4>
            </div>
          </div>
        </a>
      </div>
    </div>

  </div>
</app-dashboard-layout>
