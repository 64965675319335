<ng-container *ngIf="!loading; else templateLoading">

  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Profil Plus site</a></li>
          <li class="breadcrumb-item "><a [routerLink]="['/website/customers']">Clients</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website/customers/vehicules']">Liste des véhicules</a></li>
          <ng-container  *ngIf="(editMode.active && editMode.data); else templateElse">
            <li class="breadcrumb-item active"><a href="javascript:;">{{editMode.data.name}}</a></li>
          </ng-container>
          <ng-template #templateElse>
            <li class="breadcrumb-item active"><a href="javascript:;">Créer un véhicule</a></li>
          </ng-template>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <ng-container  *ngIf="editFormGroup">
    <div class="ppp-agency-edition row" *ngIf="!editMode.active ||(editMode.active && editMode.data)">
      <div class="col-lg-12">
        <form class="form" [formGroup]="editFormGroup" (ngSubmit)="onSubmit()">
          <div class="card">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">directions_car</i>
              </div>
              <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
                <h4 class="card-title">Édition du véhicule : <strong class="description">{{editMode.data.name}}</strong></h4>
              </ng-container>
              <ng-template #templateElse>
                <h4 class="card-title">Création d'un nouveau véhicule</h4>
              </ng-template>
            </div>

            <div class="card-body">

              <button type="submit" [disabled]="editFormGroup.invalid" *ngIf="!loadingService.loading; else templateSubmit"
                class="ppp-button ppp-button-black btn btn-primary btn-round">
                <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
                <ng-template #creationTemplateText>Créer</ng-template>
              </button>
              <ng-template #templateSubmit>
                <button
                  disabled="true"
                  class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                  ...
                </button>
              </ng-template>


            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
