import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { WorkersService } from 'src/app/services/workers.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  public editFormGroup: UntypedFormGroup;
  public submitted: Boolean = false;
  public adherentsList: Array<any> = [];
  public agenciesList: Array<any> = [];
  public typeList = [
    { type: 'analytic', value: 0, label: 'Analyse fidélité - Fidélité générale'},
    { type: 'analytic', value: 1, label: 'Analyse fidélité - Fidélité par adhérent'},
    { type: 'analytic', value: 2, label: 'Analyse fidélité - Fidélité par agence'},
    { type: 'analytic', value: 3, label: 'Analyse fidélité - Echanges entre agences'},
    { type: 'query', value: 7, label: 'Requête - Comptabilité générale'},
    { type: 'query', value: 8, label: 'Requête - Encours fidélité'},
    { type: 'query', value: 9, label: 'Suivi des comptes'}
  ];

  public selectedType = null;
  public loading = false;

  public agency_selection = null;
  public adherent_selection = null

  constructor(
    private workersService: WorkersService,
    private alertService: AlertService,
    private enumsService: EnumsService,
    public loadingService: LoadingService,
    private chRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder) {

  }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.loading = false;
        this.editFormGroup = this.formBuilder.group({
          statistic: [null, Validators.required],
          begin: ['', null],
          end: ['', null],
          expirated: [false, null],
          adherent: [[], null],
          agency: [[], null]
        });

        this.adherentsList = this.enumsService.enums.adherents;
        this.chRef.detectChanges(); // Detect HTML CHANGING

        // @ts-ignore: Unreachable code error
        jQuery('.select-analytic').selectpicker({
          noneSelectedText: 'Aucun élément sélectionné'
        });
      }
    });
  }

  get f() {
    return this.editFormGroup.controls;
  }

  onTypeSelection() {
    this.editFormGroup.patchValue({statistic: this.typeList[this.selectedType]});
    if (this.typeList[this.selectedType]) {
      
      // Validation on Date fields
      if (this.typeList[this.selectedType].value !== 8) {
        if (this.typeList[this.selectedType].value !== 7) { // 0, 1, 2, 3, 9
          this.editFormGroup.get('begin').setValidators([Validators.required]);
          this.editFormGroup.get('begin').updateValueAndValidity();
        } else { // 7
          this.editFormGroup.get('begin').setValidators(null);
          this.editFormGroup.get('begin').updateValueAndValidity();
        }
        // 0, 1, 2, 3, 7, 9
        this.editFormGroup.get('end').setValidators([Validators.required]);
        this.editFormGroup.get('end').updateValueAndValidity();
      }
      else { // 8
        this.editFormGroup.get('begin').setValidators(null);
        this.editFormGroup.get('begin').updateValueAndValidity();
        this.editFormGroup.get('end').setValidators(null);
        this.editFormGroup.get('end').updateValueAndValidity();
      }

      // Validations on Adherent and Agency fields
      if (this.typeList[this.selectedType].value === 8) { // 8
        this.editFormGroup.get('adherent').setValidators(Validators.required);
        this.editFormGroup.get('adherent').updateValueAndValidity();
        this.editFormGroup.get('agency').setValidators(null);
        this.editFormGroup.get('agency').updateValueAndValidity();
      } else { // 0, 1, 2, 3, 7, 9
        this.editFormGroup.get('adherent').setValidators(null);
        this.editFormGroup.get('adherent').updateValueAndValidity();
        this.editFormGroup.get('agency').setValidators(null);
        this.editFormGroup.get('agency').updateValueAndValidity();
      }
    }

    // RESET LIST SELECTION
    this.agency_selection = null
    this.adherent_selection = null
    this.editFormGroup.patchValue({adherent: []})
    this.editFormGroup.patchValue({agency: []})
    this.editFormGroup.patchValue({begin: ''})
    this.editFormGroup.patchValue({end: ''})
    this.editFormGroup.patchValue({expirated: false})
    this.agenciesList = []
  }

  checkingForAgenciesList() {
    this.editFormGroup.patchValue({adherent: this.adherent_selection})
    if (this.selectedType !== null && this.f.adherent.value !== null && (this.typeList[this.selectedType].value === 0)) {
        if (this.f.adherent.value.length > 1) {
          let arr = []
          this.f.adherent.value.forEach(element => {
            arr = [...arr, ...this.enumsService.enums.agencies.filter(a => a.adherent
              && a.adherent.id === parseInt(element))];
          });
          this.agenciesList = arr;
        } else {
          if (this.f.adherent.value[0] !== 'all') {
            this.agenciesList = this.enumsService.enums.agencies.filter(a => a.adherent
              && a.adherent.id === parseInt(this.f.adherent.value));
          } else {
            this.agenciesList = this.enumsService.enums.agencies;
          }
        }
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const res = {
      selected_request: this.selectedType,
      start_at: this.editFormGroup.value.begin,
      end_at: this.editFormGroup.value.end,
      adherent_ids: this.editFormGroup.value.adherent,
      agency_ids: this.editFormGroup.value.agency,
      include_expirated: this.editFormGroup.value.expirated
    }
    this.workersService.startNewWorker('loyalty_stats', res)
      .then(() => {
        this.alertService.success('Statistiques en cours d\'export, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
      })
      .catch((err) => {
        window.scrollTo(0, 0);
        this.alertService.error(err);
        return;
      });
  }

}
