<app-topbar moduleSlug="meetings">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a>Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/meetings']">Salons</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/meetings/contacts']">Contacts</a></li>
        <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
          <li class="breadcrumb-item active"><a href="javascript:;">Édition d'un contact</a></li>
        </ng-container>
        <ng-template #templateElse>
          <li class="breadcrumb-item active"><a href="javascript:;">Création d'un nouveau contact</a></li>
        </ng-template>
      </ol>
    </nav>
  </ng-container>
</app-topbar>
<div class="row">
  <div class="col-md-12">
    <button class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="sendDocumentary()" *ngIf="editMode.active">
      <i class="material-icons">send</i>
      Envoi documentaire
    </button>
    <button class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="exportToPDF()" *ngIf="editMode.active">
      <i class="material-icons">picture_as_pdf</i>
      Exporter la fiche contact (.pdf)
    </button>
  </div>
</div>
<form *ngIf="editFormGroup" [formGroup]="editFormGroup">

  <!-- QUESTIONNAIRE -->
  <div class="row">
    <ng-container *ngIf="(editMode.active && editMode.data); else questionnaireSelectBlock">
      <section class="card col-md-6">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">assignment</i>
          </div>
          <h4 class="card-title">Questionnaire rattaché</h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label class="col-form-label" for="new_answer">Questionnaire :</label>
            <div *ngIf="editMode.data.questionnaire">{{ editMode.data.questionnaire.name }}</div>
          </div>
        </div>
      </section>
    </ng-container>
    <ng-template #questionnaireSelectBlock>
      <section class="card col-md-6">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">autorenew</i>
          </div>
          <h4 class="card-title">Sélection du questionnaire</h4>
        </div>
        <div class="card-body">
          <div class="form-group is-required"
            [ngClass]="{ 'has-danger': submitted && f.questionnaire_id.errors }">
            <label class="col-form-label" for="new_answer">Rechercher un questionnaire</label>
            <select class="form-control custom-list" name="new_answer"
                    formControlName="questionnaire_id">
              <option value="" selected hidden>Selectionner</option>
              <option *ngFor="let q of questionnaires" [value]="q.id">{{q.name}}</option>
            </select>
            <label class="error" for="new_answer" *ngIf="submitted && f.questionnaire_id.errors">
              Champ obligatoire.
            </label>
          </div>
        </div>
      </section>
    </ng-template>
  </div>

  <div class="row">
    <div class="col-md-12">
      <button class="ppp-button ppp-button-black btn btn-primary btn-round"
              (click)="downloadQuestionnairePDF(f.questionnaire_id.value)"
              *ngIf="policiesService.currentUser.is_admin && !editMode.active && f.questionnaire_id.value">
        <i class="material-icons">picture_as_pdf</i>
        Exporter le questionnaire vide (.pdf)
      </button>
      <ng-container *ngIf="!loadingScan; else scanningElse">
        <app-file-input
          *ngIf="f.questionnaire_id.value"
          [capture]="true"
          btnClass="ppp-button ppp-button-black btn btn-primary btn-round"
          (filesSelected)="onScan($event)"
          label="Scan formulaire"></app-file-input>
        <app-file-input
            id="fileInputScanAndCreate"
            *ngIf="f.questionnaire_id.value"
            [capture]="true"
            btnClass="ppp-button ppp-button-black btn btn-primary btn-round"
            (filesSelected)="onScan($event, true)"
            label="Scan rapide"></app-file-input>
      </ng-container>
    </div>
  </div>

  <div class="topdf pdfHeader showOnlyPdf" *ngIf="f.questionnaire_id.value && questionnaire && questionnaire.society_fields.length > 0">
    <h1>{{ questionnaire.name }}</h1>
  </div>
  <!-- SOCIÉTÉ -->
  <div class="row">
    <article class="col-md-6 ppp-contacts-society topdf"
      *ngIf="f.questionnaire_id.value && questionnaire && questionnaire.society_fields.length > 0">
      <section class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">domain</i>
          </div>
          <h4 class="card-title">Société</h4>
        </div>
        <div class="card-body">
          <!--LOOP-->
          <ng-container *ngFor="let field of societyFields">
            <div class="form-group" *ngIf="getFieldFromQuestionnaire(field.slug, 'society_fields')"
              [class]="{
                'is-required': getFieldFromQuestionnaire(field.slug, 'society_fields').required,
                'has-danger': submitted && f['society_' + field.slug].errors
              }">
              <ng-container [ngSwitch]="field.type">
                <ng-container *ngSwitchCase="'radio'">
                  <label [for]="field.slug">{{field.label}}</label><br>
                  <ng-container *ngFor="let option of field.options">
                    <label class="force-no-required">
                      <input [formControlName]="'society_' + field.slug" type="radio"
                        [value]="option" [name]="'society_' + field.slug">&nbsp;{{option}}&nbsp;
                    </label>
                  </ng-container>
                  <br>
                </ng-container>
                <ng-container *ngSwitchCase="'number'">
                  <label [for]="field.slug">{{field.label}}</label>
                  <input class="form-control" type="number" [formControlName]="'society_' + field.slug"
                    [name]="'society_' + field.slug" [id]="field.slug">
                </ng-container>
                <ng-container *ngSwitchCase="'postal_code'">
                  <label [for]="field.slug">{{field.label}}</label>
                  <input class="form-control" type="text" [formControlName]="'society_' + field.slug"
                    [name]="'society_' + field.slug" [id]="field.slug" minlength="5" maxlength="5" NumbersOnly
                    [allowDecimals]="false" [allowSign]="false">
                  <ng-container *ngIf="f['society_' + field.slug].value !== '' && f['society_' + field.slug].errors">
                    <small class="error">Remarque : veuillez saisir un code postal valide</small>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'select'">
                  <label [for]="field.slug">{{field.label}} (choix unique)</label>
                  <select class="form-control" [formControlName]="'society_' + field.slug"
                    [name]="'society_' + field.slug" [id]="field.slug">
                    <option value="" selected hidden class="excludeToPdf">Choisir une valeur</option>
                    <ng-container
                      *ngIf="getFieldFromQuestionnaire(field.slug, 'society_fields')">
                      <option *ngFor="let option of
                        getFieldFromQuestionnaire(field.slug, 'society_fields').defined_options ? getFieldFromQuestionnaire(field.slug, 'society_fields').defined_options : getFieldFromQuestionnaire(field.slug, 'society_fields').options"
                        [value]="option">{{option}}</option>
                    </ng-container>
                  </select>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <label [for]="field.slug">{{field.label}}</label>
                  <input class="form-control" type="text" [formControlName]="'society_' + field.slug"
                    [name]="'society_' + field.slug" [id]="field.slug">
                </ng-container>
              </ng-container>

              <label class="error" for="new_answer" *ngIf="submitted && getFieldFromQuestionnaire(field.slug, 'society_fields').required && f['society_' + field.slug].errors">
                Champ obligatoire.
              </label>

            </div>
          </ng-container>
        </div>
      </section>
    </article>

    <!-- CONTACT -->
    <article class="col-md-6 topdf"
      *ngIf="f.questionnaire_id.value && questionnaire && questionnaire.contact_fields.length > 0">
      <section class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">person</i>
          </div>
          <h4 class="card-title">Contact</h4>
        </div>
        <div class="card-body">
          <!--LOOP-->
          <ng-container *ngFor="let field of contactFields">
            <div class="form-group" *ngIf="getFieldFromQuestionnaire(field.slug, 'contact_fields')"
              [class]="{
                'is-required': getFieldFromQuestionnaire(field.slug, 'contact_fields').required,
                'has-danger': submitted && f['contact_' + field.slug].errors
              }">
              <ng-container [ngSwitch]="field.type">
                <ng-container *ngSwitchCase="'email'">
                  <label [for]="field.slug">{{field.label}}</label>
                  <input class="form-control" type="email" [formControlName]="'contact_' + field.slug"
                    [name]="'contact_' + field.slug" [id]="field.slug" email>
                  <ng-container *ngIf="f['contact_' + field.slug].value !== '' && f['contact_' + field.slug].errors">
                    <small class="error">Remarque : veuillez saisir un email valide</small>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'phone'">
                  <label [for]="field.slug">{{field.label}}</label>
                  <input type="tel"
                    pattern="^((\+)[1-9]{2}|0)[1-9](\d{2}){4}$"
                    class="form-control" [id]="field.slug" [formControlName]="'contact_' + field.slug">
                  <ng-container *ngIf="f['contact_' + field.slug].value !== '' && f['contact_' + field.slug].errors">
                    <small class="error">Remarque : veuillez saisir un numéro de téléphone valide</small>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'radio'">
                  <label [for]="field.slug">{{field.label}}</label><br>
                  <ng-container *ngFor="let option of field.options">
                    <label class="force-no-required">
                      <input [formControlName]="'contact_' + field.slug" type="radio" [value]="option" [name]="'contact_' + field.slug">&nbsp;{{option}}&nbsp;
                    </label>
                  </ng-container>
                  <br>
                </ng-container>
                <ng-container *ngSwitchCase="'checkbox'">
                  <label [for]="'contact_' + field.slug">{{field.label}}</label><br>
                  <ng-container *ngFor="let option of field.options">
                    <label class="force-no-required">
                      <input
                        (change)="checkboxList('contact_' + field.slug, option, $event.target.checked)"
                        type="checkbox"
                        [checked]="f['contact_' + field.slug].value && f['contact_' + field.slug].value.includes(option)"
                        [value]="option"
                        [name]="'contact_' + field.slug + []">
                        &nbsp;{{option}}&nbsp;
                    </label>
                  </ng-container>
                  <br>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <label [for]="field.slug">{{field.label}}</label>
                  <input class="form-control" [formControlName]="'contact_' + field.slug" [name]="'contact_' + field.slug"
                    [id]="field.slug">
                </ng-container>
              </ng-container>

              <label class="error" for="new_answer" *ngIf="submitted && getFieldFromQuestionnaire(field.slug, 'contact_fields').required && f['contact_' + field.slug].errors">
                Champ obligatoire.
              </label>

            </div>
          </ng-container>
        </div>
      </section>
    </article>
  </div>

  <!-- RUBRIQUES ET QUESTIONS -->
  <ng-container *ngIf="f.questionnaire_id.value && questionnaire && questionnaire.rubrics.length > 0">
    <section class="card col-md-12 topdf">

      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">question_answer</i>
        </div>
        <h4 class="card-title">Rubriques et questions</h4>
      </div>

      <div class="ppp-contacts-rubrics toBreakPdf" *ngFor="let rubric of questionnaire.rubrics">
        <div class="card-header card-header-rose card-header-icon">
          <h4 class="card-title"><strong>{{ rubric.name }}</strong></h4>
        </div>

        <div class="card-body">
          <div class="col-lg-6 col-md-12">
            <ng-container *ngIf="rubric.questions.length > 0">
              <ng-container *ngFor="let question of rubric.questions">
                <div class="form-group" [class]="{
                  'is-required': question.required,
                  'has-danger': submitted && question.required && !findAnswer(question.uuid)
                }">

                  <ng-container *ngIf="question.question_type === 'text'">
                    <label [for]="question.name">{{question.name}}</label>
                    <input class="form-control" type="text" [name]="question.name" [id]="question.name"
                      (change)="onEditRubricField(question.uuid, $event.target.value)" [value]="findAnswer(question.uuid)">
                  </ng-container>

                  <ng-container *ngIf="question.question_type === 'number'">
                    <label [for]="question.name">{{question.name}}</label>
                    <input class="form-control" type="number" NumbersOnly [allowDecimals]="true" [allowSign]="true"
                      [name]="question.name" [id]="question.name"
                      (change)="onEditRubricField(question.uuid, $event.target.value)" [value]="findAnswer(question.uuid)">
                  </ng-container>

                  <ng-container *ngIf="question.question_type === 'checkbox'">
                    <div class="form-check">
                      <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" name="contact_infos"
                          (change)="onEditRubricField(question.uuid, $event.target.checked)"
                          [checked]="findAnswer(question.uuid)">{{question.name}}
                        <span class="form-check-sign">
                          <span class="check"></span>
                        </span>
                      </label>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="question.question_type === 'select-unique'">
                    <label>{{question.name}} (choix unique)</label>
                    <select class="form-control custom-list"
                      (change)="onEditRubricField(question.uuid, $event.target.value)">
                      <option value="" selected disabled hidden class="excludeToPdf">Choisir une valeur</option>
                      <option *ngFor="let value of question.values" [value]="value"
                        [selected]="findAnswer(question.uuid) && findAnswer(question.uuid) === value">{{value}}</option>
                    </select>
                  </ng-container>

                  <ng-container *ngIf="question.question_type === 'select-multiple'">
                    <label>{{question.name}} (choix multiples)</label>
                    <p class="excludeToPdf"><small>Pour sélectionner plusieurs options, maintenez la touche CTRL du clavier ou cliquez et maintenez le clic gauche de votre souris.</small></p>
                    <select class="form-control multiple" multiple
                      [name]="question.name" [id]="question.name"
                      (change)="onEditRubricField(question.uuid, getSelectMultiple($event.target.selectedOptions))">
                      <option *ngFor="let value of question.values;" [value]="value"
                        [selected]="findAnswer(question.uuid) && findAnswer(question.uuid).includes(value)">{{value}}
                      </option>
                    </select>
                  </ng-container>

                  <label class="error" for="new_answer" *ngIf="submitted && question.required && !findAnswer(question.uuid)">
                    Champ obligatoire.
                  </label>

                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

    </section>
  </ng-container>

  <!-- ACTIONS -->
  <a [routerLink]="['/meetings/contacts']" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
    Retour
  </a>
  <input type="submit"
    class="ppp-button ppp-button-blue btn btn-primary btn-round" *ngIf="!loadingService.loading; else templateSubmit"
    value="Enregistrer et créer un nouveau" (click)="onSubmitAndNew()">
    <ng-template #templateSubmit>
      <button disabled="true" class="ppp-button ppp-button-black btn btn-primary btn-round"
      (click)="$event.preventDefault()">
      ...
    </button>
  </ng-template>
  <input type="submit"
        class="ppp-button ppp-button-blue btn btn-primary btn-round" *ngIf="!loadingService.loading; else templateSubmit"
        value="Créer + envoi documentaire" (click)="onSubmitAndOpenModal()">
  <ng-template #templateSubmit>
    <button disabled="true" class="ppp-button ppp-button-black btn btn-primary btn-round"
            (click)="$event.preventDefault()">
      ...
    </button>
  </ng-template>
</form>

<form form *ngIf="editFormGroupModal" [formGroup]="editFormGroupModal">
  <app-modal label="documentary-send" (onClose)="editFormGroupModal = null;">
    <ng-container title>ENVOI DOCUMENTAIRE</ng-container>
    <ng-container content>
      <div class="form-group">
        <label>Email du contact</label>
        <input class="form-control" type="email"
          (blur)="onBlurEmail()"
          formControlName="email"
          [disabled]="f.contact_email.value !== '' ? true : false">
      </div>
      <div class="form-group">
        <label>Autres destinataires (commerciaux, entreprises, ...)</label>
        <input class="form-control" type="text" placeholder="exemple@mail.fr;exemple@mail.fr;exemple@mail.fr" formControlName="other">
        <small>Remarque : pour indiquer plusieurs emails, veuillez les séparer par un point-virgule (;).</small>
      </div>
      <div class="form-group">
        <label for="test">Vos documents</label>
        <app-drag-drop-file-input
          appDragDrop
          [forceTypes]="null"
          [list]="null"
          (filesSelected)="onFileSelected($event)">
        </app-drag-drop-file-input>
      </div>
    </ng-container>
    <ng-container footer>
      <button
        [disabled]="editFormGroupModal.invalid"
        class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="onSubmitModal()">
        Envoyer
      </button>
      <button
        [disabled]="editFormGroupModal.invalid"
        class="ppp-button ppp-button-black btn btn-primary btn-round" style="margin-left:5px;" (click)="onSubmitModal(true)">
        Envoyer + créer un nouveau contact
      </button>
    </ng-container>
  </app-modal>
</form>

<ng-template #scanningElse>
  <app-modal label="scan-loading">
    <ng-container content>
      <div class="text-center">
        <app-loading [circle]="true"></app-loading>
        <p class="mt-3">Scan en cours...</p>
      </div>
    </ng-container>
  </app-modal>
</ng-template>
