import { Injectable } from '@angular/core';

import 'bootstrap-notify';
import { ApiService } from './api.service';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';

@Injectable({ providedIn: 'root' })
export class TireModelsService {
  constructor(private apiService: ApiService) {

  }

  getAllTiresWithPagination(page, sortBy = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('tire_models' + '?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
        + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id) {
    return new Promise((resolve, reject) => {
      this.apiService.get('tire_models/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('tire_models/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  edit(tire) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('tire_model:name', tire.name.toString());
      formData.append('tire_model:brand_id', tire.brand_id.toString());
      formData.append('tire_model:brand_highlight', tire.brand_highlight.toString());

      (tire.title) ? formData.append('tire_model:title', tire.title.toString()) : '';
      (tire.description) ? formData.append('tire_model:description', tire.description.toString()) : '';
      (tire.video_key) ? formData.append('tire_model:video_key', tire.video_key.toString()) : '';

      if (tire.tire_model_photo !== null) {
        formData.append('tire_model:tire_model_photo', tire.tire_model_photo);
      }
      if (tire.id) {
        this.apiService.put('tire_models/' + tire.id, formData)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      }
      else {
        this.apiService.post('tire_models', formData)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      }
    })
  }
}
