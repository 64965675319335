import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import { AlertService } from 'src/app/services/alert.service';
import { LoyaltyCustomerHistoriesService } from 'src/app/services/loyalty-customer-histories.service';

import { LoyaltyCustomerHistory } from 'src/app/models/LoyaltyCustomerHistory';
import { EnumsService } from 'src/app/services/enums.service';

@Component({
  selector: 'app-loyalty-customer-histories',
  templateUrl: './loyalty-customer-histories.component.html',
  styleUrls: ['./loyalty-customer-histories.component.scss']
})
export class LoyaltyCustomerHistoriesComponent implements OnInit {
  public items: Array<LoyaltyCustomerHistory> = null;
  public loading: boolean = false;
  public loadingList: boolean = false;
  @ViewChild('datatable') datatable: any;
  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [];

  constructor(
    private cd: ChangeDetectorRef,
    private loyaltyCustomerHistoriesService: LoyaltyCustomerHistoriesService,
    public enumsService: EnumsService,
    private alertService: AlertService) {
      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          this.loading = false;
        }
      });
    }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;
    this.loyaltyCustomerHistoriesService.getAllWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }
}
