<app-topbar moduleSlug="profilplus-site">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active">Tableau de bord</a>
        </li>
        <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website/settings/slideshows']">Slideshows</a></li>
        <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
          <li class="breadcrumb-item active"><a href="javascript:;">Édition du slideshow {{editMode.data.name}}</a></li>
        </ng-container>
        <ng-template #templateElse>
          <li class="breadcrumb-item active"><a href="javascript:;">Création d'un nouveau slideshow</a></li>
        </ng-template>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row">
  <div class="col-md-12">
    <div class="card">

      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">supervisor_account</i>
        </div>
        <ng-container *ngIf="(editMode.active && editMode.data); else h4else">
          <h4 class="card-title">Édition du slideshow {{editMode.data.name }}</h4>
        </ng-container>
        <ng-template #h4else>
          <h4 class="card-title">Création d'un nouveau slideshow</h4>
        </ng-template>
      </div>


      <div class="card-body row">
        <form *ngIf="editFormGroup" class="form col-md-12 col-lg-4"
          [formGroup]="editFormGroup">

          <div class="form-group">
            <label for="name" class="bmd-label-floating">Nom</label>
            <input type="text" class="form-control" id="name" formControlName="name">
          </div>


          <div class="form-group">
            <label for="agency_content" class="bmd-label-floating">Description</label>
            <textarea class="form-control" formControlName="description"></textarea>
          </div>

          <div class="form-group">
            <label for="agency_content" class="bmd-label-floating">Contenu</label>
            <textarea class="form-control" formControlName="content"></textarea>
          </div>

          <div class="form-group"
            [ngClass]="{ 'has-danger': submitted && f.brand_id.errors }">
            <app-datalist
              [preselect]="preselectBrand"
              label="Sélectionner une marque"
              (selected)="onSelectedBrand($event)"
              [items]="enumsService.enums.brands"
              [property]="'name'">
            </app-datalist>
          </div>

          <div class="form-group">
            <label>Image</label>
            <app-drag-drop-file-input
              [limit]="1"
              [forceTypes]="['jpg', 'jpeg', 'png']"
              appDragDrop
              [preview]="true"
              [list]="editMode.active && editMode.data.image ? editMode.data.image : null"
              (filesSelected)="onFileSelected($event)"
              (fileDeleted)="onFileSelected(null)"></app-drag-drop-file-input>
          </div>

          <div class="form-group">
            <label for="margin_left" class="bmd-label-floating">margin_left</label>
            <input type="number" class="form-control" id="margin_left" formControlName="margin_left">
          </div>

          <div class="form-group">
            <label for="margin_top" class="bmd-label-floating">margin_top</label>
            <input type="number" class="form-control" id="margin_top" formControlName="margin_top">
          </div>

          <div class="form-group">
            <label for="special_offer_id" class="bmd-label-floating">special_offer_id</label>
            <input type="text" class="form-control" id="special_offer_id" formControlName="special_offer_id">
          </div>

          <div class="form-group">
            <label for="external_link" class="bmd-label-floating">external_link</label>
            <input type="text" class="form-control" id="external_link" formControlName="external_link">
          </div>

          <a [routerLink]="['/website/settings/slideshows']" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mt-4 mr-2">
            Retour
          </a>
          <button [disabled]="editFormGroup.invalid" *ngIf="!loadingService.loading; else templateSubmit"
            class="ppp-button ppp-button-black btn btn-primary btn-round mt-4"
            (click)="onSubmit()">
            <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
            <ng-template #creationTemplateText>Créer</ng-template>
          </button>
          <ng-template #templateSubmit>
            <button
              disabled="true"
              class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
              ...
            </button>
          </ng-template>

        </form>
      </div>
    </div>
  </div>
</div>
