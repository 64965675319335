import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/User';
import { ApiService } from './api.service';
import { AlertService } from './alert.service';

@Injectable()
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private apiService: ApiService,
              private router: Router,
              private alertService: AlertService) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();

      if (localStorage.getItem('currentUser') // Already connected
        && JSON.parse(localStorage.getItem('currentUser')).token
        && JSON.parse(localStorage.getItem('currentUser')).policies) {
        this.apiService.defaultConfig.headers.set('Authorization', `Bearer ${JSON.parse(localStorage.getItem('currentUser')).token}`);
        // Check token validity
        this.apiService.get('me')
          .then((response: any) => {
            // UPDATE LOCALSTORAGE
            localStorage.setItem('currentUser', JSON.stringify({
              ...JSON.parse(localStorage.getItem('currentUser')),
              ...response
            }));

            this.currentUserSubject.next({ // Update users infos after /me request
              ...JSON.parse(localStorage.getItem('currentUser')),
              ...response
            });
            this.currentUserSubject.complete();
          })
          .catch((err) => {
            // // Get out
            if (err) {
              localStorage.removeItem('currentUser');
              this.alertService.error(err);
              this.router.navigate(['/login']);
            }
          });
      }
      else {
        this.router.navigate(['/login']);
      }

    }

  public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }

  login(email, password) {
    const formData = new FormData();
    formData.append('user:email', email);
    formData.append('user:password', password);
    return new Promise((resolve, reject) => {
      this.apiService.post('sign_ins', formData)
        .then((response: User) => {
          if (response.token) {
            this.apiService.defaultConfig.headers.set('Authorization', `Bearer ${response.token}`);
            localStorage.setItem('currentUser', JSON.stringify(response));
            this.currentUserSubject.next(response);
            this.currentUserSubject.complete();
            resolve(response);
          } else {
            reject('No token');
          }

        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    localStorage.removeItem('access_token');
    this.currentUserSubject.next(null);
  }

  updateAuth(newData) {
    const currentUser = {
      ...this.currentUserValue,
      ...newData
    };

    this.currentUserSubject.next(currentUser);
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }
}
