import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from 'src/app/models/Page';
import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { VehiculesService } from 'src/app/services/vehicules.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public loading: boolean = false;
  public editMode: {
    active: boolean,
    data: any
  } = {active: false, data: null};


  public submitted: boolean = false;

  public editFormGroup: UntypedFormGroup;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private enumsService: EnumsService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private vehiculeService: VehiculesService,
    public loadingService: LoadingService) {

      this.enumsService.observable.subscribe({
        complete: () => {
          if (this.route.snapshot.params.id) { // EDIT
            this.loading = true;
            this.vehiculeService.getById(this.route.snapshot.params.id)
              .then((page: Page) => {
                this.loading = false;
                this.editFormGroup = this.formBuilder.group({
                  id: [page.id, null],
                  title: [page.title, Validators.required],
                  content: [page.content, Validators.required]
                });
              })
              .catch((err) => {
                this.loading = false;
                this.alertService.error(err);
              })
          } else {
            this.editFormGroup = this.formBuilder.group({
              title: ['', Validators.required],
              content: ['', Validators.required]
            });

          }
        }
      });
    }

    get f() {
      return this.editFormGroup.controls;
    }

  ngOnInit(): void {
  }


  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    this.vehiculeService.edit(this.editFormGroup.value)
      .then(response => {

      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

}
