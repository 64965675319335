<ng-container *ngIf="!loading; else templateLoading">
  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
          <li class="breadcrumb-item"><a href="javascript:;">Gestion des pneus</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website/tires/', type]">Liste des pneus ({{typeLabel}})</a></li>
          <ng-container *ngIf="(editMode.active && editMode.data); else elseItem">
            <li class="breadcrumb-item active"><a href="javascript:;">{{editMode.data.name}}</a></li>
          </ng-container>
          <ng-template #elseItem>
            <li class="breadcrumb-item active"><a href="javascript:;">Créer un pneu {{typeLabel}}</a></li>
          </ng-template>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">directions_car</i>
          </div>
          <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
            <h4 class="card-title">Édition d'un pneu : <strong class="description">{{editMode.data.name}}</strong></h4>
          </ng-container>
          <ng-template #templateElse>
            <h4 class="card-title">Création d'un nouveau pneu {{typeLabel}}</h4>
          </ng-template>
        </div>

        <div class="card-body">
          <form class="form container" *ngIf="editFormGroup" [formGroup]="editFormGroup">
            <div class="col-lg-6 col-md-12">

              <!-- PNEUS D'OCCASION AG -->
              <ng-container *ngIf="type === 'ag_used';else templateElse">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.tire_brand_id.errors }">
                  <label for="tire_brand_id" class="bmd-label-floating">Marque du pneu</label>
                  <select class="selectpicker-vanilla" name="tire_brand_id" formControlName="tire_brand_id">
                    <option *ngFor="let item of enumsService.enums.brands" [value]="item.id">{{item.name}}</option>
                  </select>
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.tire_profile.errors }">
                  <label for="ean" class="bmd-label-floating">Profil du pneu</label>
                  <input type="text" class="form-control" id="tire_profile" formControlName="tire_profile">
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.ean.errors }">
                  <label for="ean_ag" class="bmd-label-floating">EAN</label>
                  <input type="text" class="form-control" id="ean_ag" formControlName="ean">
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.width.errors }">
                  <label for="width_ag" class="bmd-label-floating">Largeur</label>
                  <select class="selectpicker-vanilla" formControlName="width">
                    <option *ngFor="let item of optionsFilteredByType.width" [value]="item">{{item}}</option>
                  </select>
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.height.errors }">
                  <label for="height_ag" class="bmd-label-floating">Hauteur</label>
                  <select class="selectpicker-vanilla" formControlName="height">
                    <option *ngFor="let item of optionsFilteredByType.height" [value]="item">{{item}}</option>
                  </select>
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.diameter.errors }">
                  <label for="diameter_ag" class="bmd-label-floating">Diamètre</label>
                  <select class="selectpicker-vanilla" formControlName="diameter">
                    <option *ngFor="let item of optionsFilteredByType.diameter" [value]="item">{{item}}</option>
                  </select>
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.load_index.errors }">
                  <label for="load_index_ag" class="bmd-label-floating">Indice de charge</label>
                  <select class="selectpicker-vanilla" formControlName="load_index">
                    <option *ngFor="let item of optionsFilteredByType.load_index" [value]="item">{{item}}</option>
                  </select>
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.speed_index.errors }">
                  <label for="speed_index_ag" class="bmd-label-floating">Indice de vitesse</label>
                  <select class="selectpicker-vanilla" formControlName="speed_index">
                    <option *ngFor="let item of optionsFilteredByType.speed_index" [value]="item.value">{{item.name}}</option>
                  </select>
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.wear_rate.errors }">
                  <label for="wear_rate" class="bmd-label-floating">Taux d'usure</label>
                  <select class="selectpicker-vanilla" formControlName="wear_rate">
                    <option *ngFor="let item of optionsFilteredByType.wear_rate" [value]="item">{{item}}</option>
                  </select>
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.remaining_gum.errors }">
                  <label for="remaining_gum" class="bmd-label-floating">Mm de gomme restant</label>
                  <input type="text" class="form-control" id="remaining_gum" formControlName="remaining_gum">
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.sale_quantity.errors }">
                  <label for="sale_quantity_ag" class="bmd-label-floating">Quantité</label>
                  <input type="number"  min="0" NumbersOnly [allowDecimals]="false" [allowSign]="false"
                    class="form-control" id="sale_quantity_ag" formControlName="sale_quantity">
                  <label class="error" for="sale_quantity_ag" *ngIf="f.sale_quantity.errors">
                    Veuillez renseigner une quantité comprise entre 0 et 10.
                  </label>
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.price_with_tax.errors }">
                  <label for="price_with_tax_ag" class="bmd-label-floating">Prix unitaire HT</label>
                  <input type="text" class="form-control" id="price_with_tax_ag" formControlName="price_with_tax">
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.description.errors }">
                  <label for="description_ag" class="bmd-label-floating">Description</label>
                  <textarea rows="5" cols="10" class='form-control' id="description_ag" formControlName="description"></textarea>
                </p>

                <p class="form-group is-required">
                  <label class="bmd-label-floating">Photo 1</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png', 'webp']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active && editMode.data.tire_photo_1 ? editMode.data.tire_photo_1 : null"
                    (filesSelected)="onPictureSelected($event, 'tire_photo_1')"
                    (fileDeleted)="onPictureSelected(null, 'tire_photo_1')"></app-drag-drop-file-input>
                </p>

                <p class="form-group">
                  <label class="bmd-label-floating">Photo 2</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png', 'webp']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active && editMode.data.tire_photo_2 ? editMode.data.tire_photo_2 : null"                    (filesSelected)="onPictureSelected($event, 'tire_photo_2')"
                    (fileDeleted)="onPictureSelected(null, 'tire_photo_2')"></app-drag-drop-file-input>
                </p>

                <p class="form-group">
                  <label class="bmd-label-floating">Photo 3</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png', 'webp']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active && editMode.data.tire_photo_3 ? editMode.data.tire_photo_3 : null"                    (filesSelected)="onPictureSelected($event, 'tire_photo_3')"
                    (fileDeleted)="onPictureSelected(null, 'tire_photo_3')"></app-drag-drop-file-input>
                </p>

                <p class="form-group">
                  <label class="bmd-label-floating">Photo 4</label>
                  <app-drag-drop-file-input
                    [limit]="1"
                    [forceTypes]="['jpg', 'jpeg', 'png', 'webp']"
                    appDragDrop
                    [preview]="true"
                    [list]="editMode.active && editMode.data.tire_photo_4 ? editMode.data.tire_photo_4 : null"                    (filesSelected)="onPictureSelected($event, 'tire_photo_3')"
                    (filesSelected)="onPictureSelected($event, 'tire_photo_4')"
                    (fileDeleted)="onPictureSelected(null, 'tire_photo_4')"></app-drag-drop-file-input>
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.agency_id.errors }">
                  <label for="agency_id" class="bmd-label-floating">Pneu disponible dans l'agence</label>
                  <select class="selectpicker-vanilla" formControlName="agency_id">
                    <option *ngFor="let item of enumsService.enums.agencies" [value]="item.id">{{item.name}}</option>
                  </select>
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.saler_city.errors }">
                  <label for="saler_city" class="bmd-label-floating">Lieu de vente (Ville)</label>
                  <input type="text" class="form-control" id="saler_city" formControlName="saler_city">
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.subdivision_code.errors }">
                  <label for="subdivision_code" class="bmd-label-floating">Département</label>
                  <select class="selectpicker-vanilla" formControlName="subdivision_code">
                    <option value=""></option>
                    <option value="01">01 Ain</option>
                    <option value="02">02 Aisne</option>
                    <option value="03">03 Allier</option>
                    <option value="04">04 Alpes-de-Haute-Provence</option>
                    <option value="05">05 Hautes-Alpes</option>
                    <option value="06">06 Alpes-Maritimes</option>
                    <option value="07">07 Ardèche</option>
                    <option value="08">08 Ardennes</option>
                    <option value="09">09 Ariège</option>
                    <option value="10">10 Aube</option>
                    <option value="11">11 Aude</option>
                    <option value="12">12 Aveyron</option>
                    <option value="13">13 Bouches-du-Rhône</option>
                    <option value="14">14 Calvados</option>
                    <option value="15">15 Cantal</option>
                    <option value="16">16 Charente</option>
                    <option value="17">17 Charente-Maritime</option>
                    <option value="18">18 Cher</option>
                    <option value="19">19 Corrèze</option>
                    <option value="21">21 Côte-d'Or</option>
                    <option value="22">22 Côtes-d'Armor</option>
                    <option value="23">23 Creuse</option>
                    <option value="24">24 Dordogne</option>
                    <option value="25">25 Doubs</option>
                    <option value="26">26 Drôme</option>
                    <option value="27">27 Eure</option>
                    <option value="28">28 Eure-et-Loir</option>
                    <option value="29">29 Finistère</option>
                    <option value="2A">2A Corse-du-Sud</option>
                    <option value="2B">2B Haute-Corse</option>
                    <option value="30">30 Gard</option>
                    <option value="31">31 Haute-Garonne</option>
                    <option value="32">32 Gers</option>
                    <option value="33">33 Gironde</option>
                    <option value="34">34 Hérault</option>
                    <option value="35">35 Ille-et-Vilaine</option>
                    <option value="36">36 Indre</option>
                    <option value="37">37 Indre-et-Loire</option>
                    <option value="38">38 Isère</option>
                    <option value="39">39 Jura</option>
                    <option value="40">40 Landes</option>
                    <option value="41">41 Loir-et-Cher</option>
                    <option value="42">42 Loire</option>
                    <option value="43">43 Haute-Loire</option>
                    <option value="44">44 Loire-Atlantique</option>
                    <option value="45">45 Loiret</option>
                    <option value="46">46 Lot</option>
                    <option value="47">47 Lot-et-Garonne</option>
                    <option value="48">48 Lozère</option>
                    <option value="49">49 Maine-et-Loire</option>
                    <option value="50">50 Manche</option>
                    <option value="51">51 Marne</option>
                    <option value="52">52 Haute-Marne</option>
                    <option value="53">53 Mayenne</option>
                    <option value="54">54 Meurthe-et-Moselle</option>
                    <option value="55">55 Meuse</option>
                    <option value="56">56 Morbihan</option>
                    <option value="57">57 Moselle</option>
                    <option value="58">58 Nièvre</option>
                    <option value="59">59 Nord</option>
                    <option value="60">60 Oise</option>
                    <option value="61">61 Orne</option>
                    <option value="62">62 Pas-de-Calais</option>
                    <option value="63">63 Puy-de-Dôme</option>
                    <option value="64">64 Pyrénées-Atlantiques</option>
                    <option value="65">65 Hautes-Pyrénées</option>
                    <option value="66">66 Pyrénées-Orientales</option>
                    <option value="67">67 Bas-Rhin</option>
                    <option value="68">68 Haut-Rhin</option>
                    <option value="69">69 Rhône</option>
                    <option value="70">70 Haute-Saône</option>
                    <option value="71">71 Saône-et-Loire</option>
                    <option value="72">72 Sarthe</option>
                    <option value="73">73 Savoie</option>
                    <option value="74">74 Haute-Savoie</option>
                    <option value="75">75 Paris</option>
                    <option value="76">76 Seine-Maritime</option>
                    <option value="77">77 Seine-et-Marne</option>
                    <option value="78">78 Yvelines</option>
                    <option value="79">79 Deux-Sèvres</option>
                    <option value="80">80 Somme</option>
                    <option value="81">81 Tarn</option>
                    <option value="82">82 Tarn-et-Garonne</option>
                    <option value="83">83 Var</option>
                    <option value="84">84 Vaucluse</option>
                    <option value="85">85 Vendée</option>
                    <option value="86">86 Vienne</option>
                    <option value="87">87 Haute-Vienne</option>
                    <option value="88">88 Vosges</option>
                    <option value="89">89 Yonne</option>
                    <option value="90">90 Territoire de Belfort</option>
                    <option value="91">91 Essonne</option>
                    <option value="92">92 Hauts-de-Seine</option>
                    <option value="93">93 Seine-Saint-Denis</option>
                    <option value="94">94 Val-de-Marne</option>
                    <option value="95">95 Val-d'Oise</option>
                  </select>
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.saler_first_name.errors }">
                  <label for="saler_first_name" class="bmd-label-floating">Prénom du vendeur</label>
                  <input type="text" class="form-control" id="saler_first_name" formControlName="saler_first_name">
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.saler_last_name.errors }">
                  <label for="saler_last_name" class="bmd-label-floating">Nom du vendeur</label>
                  <input type="text" class="form-control" id="saler_last_name" formControlName="saler_last_name">
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.saler_phone.errors }">
                  <label for="saler_phone" class="bmd-label-floating">Téléphone du vendeur</label>
                  <input type="text" class="form-control" id="saler_phone" formControlName="saler_phone">
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.saler_email.errors }">
                  <label for="saler_email" class="bmd-label-floating">Email du vendeur</label>
                  <input type="text" class="form-control" id="saler_email" formControlName="saler_email">
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.reference_number.errors }">
                  <label for="reference_number" class="bmd-label-floating">Référence d'annonce</label>
                  <input type="text" class="form-control" id="reference_number" formControlName="reference_number">
                </p>
              </ng-container>

              <!-- PNEUS VL / PL / AG -->
              <ng-template #templateElse>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.ean.errors }">
                  <label for="ean" class="bmd-label-floating">EAN</label>
                  <input type="text" class="form-control" formControlName="ean">
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.category.errors }">
                  <label for="description_ag" class="bmd-label-floating">Catégorie</label>
                  <select class="selectpicker-vanilla" formControlName="category">
                    <option *ngFor="let item of enumsService.enums.tire_data.global.category"
                    [value]="item.value">{{item.name}}</option>
                  </select>
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.season.errors }">
                  <label for="description_ag" class="bmd-label-floating">Saison</label>
                  <select class="selectpicker-vanilla" formControlName="season">
                    <option *ngFor="let item of enumsService.enums.tire_data.global.season" [value]="item.value">{{item.name}}</option>
                  </select>
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.width.errors }">
                  <label for="width" class="bmd-label-floating">Largeur</label>
                  <input type="text" class="form-control" formControlName="width">
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.height.errors }">
                  <label for="height" class="bmd-label-floating">Hauteur</label>
                  <input type="text" class="form-control" formControlName="height">
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.diameter.errors }">
                  <label for="diameter" class="bmd-label-floating">Diamètre</label>
                  <input type="text" class="form-control" formControlName="diameter">
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.load_index.errors }">
                  <label for="load_index" class="bmd-label-floating">Indice de charge</label>
                  <input type="text" class="form-control" formControlName="load_index">
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.speed_index.errors }">
                  <label for="load_index" class="bmd-label-floating">Indice de vitesse</label>
                  <select class="selectpicker-vanilla" formControlName="speed_index">
                    <option *ngFor="let item of optionsFilteredByType.speed_index"
                    [value]="item.value">{{item.name}}</option>
                  </select>
                </p>

                <div class="form-group align-checkbox" [ngClass]="{ 'has-danger': submitted && f.runflat.errors }">
                  <p class="col-lg-12">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" name="runflat" formControlName="runflat"> Runflat ?
                      <span class="form-check-sign"><span class="check"></span></span>
                    </label>
                  </p>
                </div>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.label_fuel.errors }">
                  <label for="label_fuel" class="bmd-label-floating">Résistance au roulement</label>
                  <select class="selectpicker-vanilla" formControlName="label_fuel">
                    <option *ngFor="let item of enumsService.enums.tire_data.global.label_fuel"
                    [value]="item.value">{{item.name}}</option>
                  </select>
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.label_wet.errors }">
                  <label for="label_wet" class="bmd-label-floating">Adhérence sur sol mouillé</label>
                  <select class="selectpicker-vanilla" formControlName="label_wet">
                    <option *ngFor="let item of enumsService.enums.tire_data.global.label_wet"
                    [value]="item.value">{{item.name}}</option>
                  </select>
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.label_noise.errors }">
                  <label for="label_noise" class="bmd-label-floating">Bruit de roulement externe</label>
                  <input type="text" class="form-control" formControlName="label_noise">
                </p>

                <p class="form-group">
                  <label for="tire_model_id" class="bmd-label-floating">Profil de pneu</label>
                  <select class="selectpicker-vanilla" formControlName="tire_model_id">
                    <option *ngFor="let item of enumsService.enums.tire_models"
                    [value]="item.id">{{item.name}}</option>
                  </select>
                </p>

                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.price_with_tax.errors }">
                  <label for="price_with_tax" class="bmd-label-floating">Prix TTC</label>
                  <input type="number" step="0.01" class="form-control" formControlName="price_with_tax" NumbersOnly>
                </p>

                <div class="form-group align-checkbox" [ngClass]="{ 'has-danger': submitted && f.on_sale.errors }">
                  <p class="col-lg-12">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" formControlName="on_sale"> Promo
                      <span class="form-check-sign"><span class="check"></span></span>
                    </label>
                  </p>
                </div>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.description.errors }">
                  <label for="description" class="bmd-label-floating">Description</label>
                  <textarea rows="5" cols="10" class="form-control" formControlName="description"></textarea>
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.homologation.errors }">
                  <label for="homologation" class="bmd-label-floating">Homologation</label>
                  <input type="text" class="form-control" formControlName="homologation">
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.bf_price.errors }">
                  <label for="bf_price" class="bmd-label-floating">Prix BF</label>
                  <input type="text" class="form-control" formControlName="bf_price">
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.price_with_sale.errors }">
                  <label for="price_with_sale" class="bmd-label-floating">Prix avec promo</label>
                  <input type="text" class="form-control" formControlName="price_with_sale">
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.sale_quantity.errors }">
                  <label for="price_with_sale" class="bmd-label-floating">Quantité</label>
                  <input type="number" NumbersOnly [allowDecimals]="false" [allowSign]="false"
                    class="form-control" formControlName="sale_quantity">
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.law_code.errors }">
                  <label for="law_code" class="bmd-label-floating">Loi appliquée</label>
                  <select class="selectpicker-vanilla" formControlName="law_code">
                    <option *ngFor="let item of enumsService.enums.tire_data.global.law_code" [value]="item.value">{{item.name}}</option>
                  </select>
                </p>

                <div class="form-group align-checkbox" [ngClass]="{ 'has-danger': submitted && f.is_3pmsf.errors }">
                  <p class="col-lg-12">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" formControlName="is_3pmsf"> 3PMSF ?
                      <span class="form-check-sign"><span class="check"></span></span>
                    </label>
                  </p>
                </div>

                <div class="form-group align-checkbox" [ngClass]="{ 'has-danger': submitted && f.ice_grip.errors }">
                  <p class="col-lg-12">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" formControlName="ice_grip"> Adhérence sur verglas ?
                      <span class="form-check-sign"><span class="check"></span></span>
                    </label>
                  </p>
                </div>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.eprel_url.errors }">
                  <label for="eprel_url" class="bmd-label-floating">URL vers la fiche pneu EPREL</label>
                  <input type="text" class="form-control" id="eprel_url" formControlName="eprel_url">
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.category_noise.errors }">
                  <label for="category_noise" class="bmd-label-floating">Catégorie de bruit</label>
                  <select class="selectpicker-vanilla" formControlName="category_noise">
                    <option *ngFor="let item of enumsService.enums.tire_data.global.category_noise" [value]="item">{{item}}</option>
                  </select>
                </p>
              </ng-template>

              <button [disabled]="editFormGroup.invalid || (type === 'ag_used' && !editMode.data.tire_photo_1 && !tire_photo_1)" *ngIf="!loadingService.loading; else templateSubmit"
                class="ppp-button ppp-button-black btn btn-primary btn-round mt-4"
                (click)="onSubmit()">
                <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
                <ng-template #creationTemplateText>Créer</ng-template>
              </button>
              <ng-template #templateSubmit>
                <button
                  disabled="true"
                  class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                  ...
                </button>
              </ng-template>
            </div>
          </form>
        </div>


      </div>
    </div>
  </div>
</ng-container>
<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
