<ng-container *ngIf="modules[currentModule]">
  <app-topbar [moduleSlug]="currentModule">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item active"><a href="javascript:;">{{modules[currentModule].name}}</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <div class="row">
    <ng-container *ngFor="let item of modules[currentModule].items">

      <ng-container *ngIf="item['sub-items']; else templateElse">
        <div class="col-lg-3 col-md-6 col-sm-6" *ngIf="policiesService.checkItem(item)">
          <div class="card card-stats">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">{{item.icon}}</i>
              </div>
              <p class="card-category">{{item.name}}</p>
            </div>
            <div class="card-footer">
              <div *ngIf="item.description" class="stats" [innerHtml]="item.description"></div>
              <div class="subitems">
                <ng-container>
                  <ul>
                    <ng-container  *ngFor="let subItem of item['sub-items']">
                      <li *ngIf="policiesService.userHaveRule(subItem.model, subItem.type)">
                        <a [routerLink]="['/' + currentModule, item.slug, subItem.slug]">
                          <i class="material-icons">keyboard_arrow_right</i> {{subItem.name}}
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #templateElse>
        <div class="col-lg-3 col-md-6 col-sm-6" *ngIf="policiesService.checkItem(item)">
          <a [routerLink]="['/' + currentModule, item.slug]">
            <div class="card card-stats only-item">
              <div class="card-header card-header-rose card-header-icon">
                <div class="card-icon">
                  <i class="material-icons">{{item.icon}}</i>
                </div>
                <p class="card-category">{{item.name}}</p>
              </div>
              <div class="card-footer">
                <div *ngIf="item.description" class="stats" [innerHtml]="item.description"></div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>

    </ng-container>
  </div>
</ng-container>
