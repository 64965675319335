<app-dashboard-layout>
  <span title>Tableau de bord</span>
  <div class="ppp-dashboard" content>
    <app-topbar moduleSlug="">
      <ng-container title>
      </ng-container>
    </app-topbar>
    <div class="row">
      <div class="col-md-12">
        <div class="card">

          <div class="card-header card-header-rose card-header-icon">
            <div class="card-icon">
              <i class="material-icons">info</i>
            </div>
            <h4 class="card-title">Liste des notifications
            </h4>
          </div>

          <div class="card-body">

            <app-filter-bar [filters]="filters" (changed)="setPage({offset: 0}, null, $event)">
              <p *ngIf="loadingList">chargement...</p>
            </app-filter-bar>

            <div class="table-responsive">
              <ngx-datatable #datatable class="material" [rows]="items" [columnMode]="'standard'" [rowHeight]="'auto'"
              [headerHeight]="50" [footerHeight]="50" [externalPaging]="true" [externalSorting]="true"
              [loadingIndicator]="loadingList" [reorderable]="false" (page)="setPage($event)" (sort)="onSort($event)">

                <!-- ID -->
                <ngx-datatable-column name="ID" prop="id" [width]="75">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                  </ng-template>
                </ngx-datatable-column>

                <!-- DESCRIPTION -->
                <ngx-datatable-column name="Description" prop="request_type" [width]="350">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <div [ngSwitch]="row.request_type">
                      <div *ngSwitchCase="'commercial_action_stats'">[ Actions commerciales ]<br>Statistiques "{{getCommercialAction(row.execution_data.commercial_action_id)}}"</div>
                      <div *ngSwitchCase="'agencies_export'">[ Profil Plus Agences ]<br>Export Agences</div>
                      <div *ngSwitchCase="'export_candidatures'">[ Site Emploi ]<br>Export Candidatures</div>
                      <div *ngSwitchCase="'export_offers'">[ Site Emploi ]<br>Export Offres</div>
                      <div *ngSwitchCase="'agencies_import'">[ Profil Plus Agences ]<br>Import Agences</div>
                      <div *ngSwitchCase="'agencies_import_report'">[ Profil Plus Agences ]<br>Rapport d'erreurs sur l'import Agences</div>
                      <div *ngSwitchCase="'loyalty_stats'">[ MyProfil+ ]<br>{{getTypeAnalytic(row.execution_data.selected_request)}}
                        <ng-container *ngIf="row.execution_data.adherent_ids && row.execution_data.adherent_ids.length > 0">
                          <p>Adhérent(s) : <ng-container *ngFor="let item of row.execution_data.adherent_ids">{{getAdherent(item)}}</ng-container>
                          </p>
                        </ng-container>
                        <ng-container *ngIf="row.execution_data.agency_ids && row.execution_data.agency_ids.length > 0">
                          <p [title]="getAgencies(row.execution_data.agency_ids).join(', ')"
                          >Agence(s) : {{getAgencies(row.execution_data.agency_ids, true).join(', ')}}<span *ngIf="row.execution_data.agency_ids.length >3">...</span></p>
                        </ng-container>
                      </div>
                      <div *ngSwitchCase="'export_reviews'">[ Site Profil Plus ]<br>Export des avis clients</div>
                      <div *ngSwitchCase="'export_appointments'">[ Site Profil Plus ]<br>Export des demandes de RDV</div>
                      <div *ngSwitchCase="'export_quote_requests'">[ Site Profil Plus ]<br>Export des demandes de Devis</div>
                      <div *ngSwitchCase="'files_mapping'">[ Site Profil Plus ]<br>Mapping de fichiers</div>
                      <div *ngSwitchCase="'myprofilplus_customers_export'">[ MyProfil+ ]<br>Export clients MyProfil+</div>
                      <div *ngSwitchCase="'questionnaire_contacts_export'">[ Salons ]<br>Export des contacts provenant des questionnaires</div>
                      <div *ngSwitchDefault>[ Autres ]<br>{{row.request_type}}</div>
                    </div>
                  </ng-template>
                </ngx-datatable-column>

                <!-- FICHIER -->
                <ngx-datatable-column name="Fichier" prop="file" [width]="100">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <ng-container *ngIf="value; else templateElseDownload">
                      <ng-container *ngIf="row.request_type === 'files_mapping'">
                        <a [href]="value" target="_blank"
                          [title]="'Télécharger ' + row.filename">
                          <i style="color:#333;" class="material-icons">cloud_download</i>
                        </a>
                      </ng-container>
                      <ng-container *ngIf="row.request_type === 'questionnaire_contacts_export'">
                        <a href="#" (click)="$event.preventDefault(); clickDownload(row.file, row.filename)"
                          [title]="'Télécharger ' + row.filename">
                          <i style="color:#333;" class="material-icons">cloud_download</i>
                        </a>
                      </ng-container>
                      <ng-container *ngIf="row.request_type != 'questionnaire_contacts_export' && row.request_type != 'files_mapping'">
                        <a href="#" (click)="$event.preventDefault(); clickDownload(row.file, row.filename)"
                            [title]="'Télécharger ' + row.filename">
                          <i style="color:#333;" class="material-icons">cloud_download</i>
                        </a>
                      </ng-container>
                    </ng-container>
                    <ng-template #templateElseDownload>en cours...</ng-template>
                  </ng-template>
                </ngx-datatable-column>

                <!-- FICHIER 2 -->
                <ngx-datatable-column name="Fichier 2" prop="file_2" [width]="100">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <ng-container *ngIf="row.request_type === 'files_mapping'">
                      <ng-container *ngIf="value; else templateElseDownload">
                        <a [href]="value" target="_blank"
                          [title]="'Télécharger ' + row.filename">
                          <i style="color:#333;" class="material-icons">cloud_download</i>
                        </a>
                      </ng-container>
                      <ng-template #templateElseDownload>
                        en cours...
                      </ng-template>
                    </ng-container>
                  </ng-template>
                </ngx-datatable-column>

                <!-- TRAITÉE LE -->
                <ngx-datatable-column name="Traitée le" prop="treated_at" [width]="150">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <ng-container *ngIf="row.treated_at; else templateElseTreatedAt">
                      {{ row.treated_at | date: 'dd/MM/yy - HH:mm' }}
                    </ng-container>
                    <ng-template #templateElseTreatedAt>
                      En cours...
                    </ng-template>
                  </ng-template>
                </ngx-datatable-column>

                <!-- RAPPORT -->
                <ngx-datatable-column name="Rapport" prop="file_reporting" [width]="120">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <ng-container *ngIf="row.file_reporting">
                      <a href="#"
                        (click)="$event.preventDefault(); clickDownload(row.file_reporting, 'profilplus-export-errors-' + row.id + '-' + row.request_type + '.xlsx')"
                        title="Télécharger">
                        <i style="color:#333;" class="material-icons">cloud_download</i>
                      </a>
                    </ng-container>
                  </ng-template>
                </ngx-datatable-column>

                <!-- UTILISATEUR -->
                <ngx-datatable-column name="Utilisateur" prop="user" [width]="200">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{ value.first_name }} {{ value.last_name }}
                  </ng-template>
                </ngx-datatable-column>

                <!-- ACTIONS -->
                <ngx-datatable-column name="Actions" [sortable]="false">
                  <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    <button type="button" class="btn btn-danger" (click)="onDelete(row.id)" title="Supprimer">
                      <i class="material-icons">close</i>
                    </button>
                  </ng-template>
                </ngx-datatable-column>

              </ngx-datatable>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</app-dashboard-layout>
