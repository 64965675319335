<div class="wrapper">

  <!-- LEFT NAVIGATION -->
  <app-sidebar></app-sidebar>

  <section class="main-panel">

    <!-- TOP BAR
    <app-topbar [moduleSlug]="moduleSlug">
      <ng-content select="[title]"></ng-content>
    </app-topbar> -->

    <!-- MAIN CONTENT -->
    <div class="content">
      <ng-content select="[content]"></ng-content>
    </div>

    <!-- FOOTER -->
    <app-footer [fullwidth]="true">
      <app-loading *ngIf="loadingService.loading"></app-loading>
    </app-footer>

  </section>

</div>
