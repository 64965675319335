import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import slugify from '../../../helpers/slugify';

import modules from '../../../../assets/modules.json';

import { User } from 'src/app/models/User';
import { Agreement } from 'src/app/models/Agreement';

import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { AgreementsService } from 'src/app/services/agreements.service';
import { EnumsService } from 'src/app/services/enums.service';

import jsPDF from 'jspdf';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {

  public modules;
  public modulesStringList;
  public user: User;
  public commercialActionId;
  public agreementId;

  public agenciesList = [];
  public limitDateForAc92 = new Date(2023, 8, 1)

  currentModule: string = null;
  currentService: {name: string, slug: string, sub: any} = {
    name: null,
    slug: null,
    sub: null
  };

  currentItem: Agreement = null;
  public fields: Array<any>;

  constructor(private authService: AuthService,
              public agreementsService: AgreementsService,
              public alertService: AlertService,
              private enumsService: EnumsService,
              private route: ActivatedRoute,
              private router: Router) {

    this.currentModule =  this.router.url.split('/')[1];
    this.modules = modules;
    this.commercialActionId = this.route.snapshot.params.action_id;
    this.agreementId = this.route.snapshot.params.agreement_id;

    this.enumsService.observable.subscribe({
      complete: () => {
        this.agenciesList = this.enumsService.enums.agencies;
        this.currentService.slug = this.router.url.split('/')[2];
        switch (this.currentService.slug) {
          case 'agreements':
            this.currentService.name =  "demandes d'accords";
            this.fields = [
              {name: 'Numéro Accord', slug: 'agreement_number', template: true},
              {name: 'Action commerciale', slug: 'commercial_action', template: true},
              {name: 'Code promotionnel saisi', slug: 'promo_code', template: true},
              {name: 'Date limite de validité', slug: 'validity_deadline_date', template: true},
              {name: 'Agence', slug: 'agency', template: true},
              {name: 'Montant à rembourser HT', slug: 'amount_ht', template: true},
              {name: 'Montant à rembourser TTC', slug: 'amount_ttc', template: true},
              {name: 'Nom client', slug: 'customer_lastname', template: true},
              {name: 'Prénom client', slug: 'customer_firstname', template: true},
              {name: 'Téléphone portable client', slug: 'customer_number', template: true},
              {name: 'Email client', slug: 'customer_email', template: true},
              {slug : 'invoice_amount', name: "Montant facture TTC", template: true},
              {slug : 'invoice_number', name: "Numéro facture", template: true},
              {slug : 'invoice_number_entered', name: "Numéro facture", template: true},
              {slug : 'brand_name', name: "Marque", template: true},
              {slug : 'tire_count', name: "Nombre de pneus", template: true},
              {slug : 'price_unit_ttc', name: "Prix unitaire du pneu (TTC)", template: true},
              {slug : 'price_unit_hc', name: "Prix unitaire du pneu (HT)", template: true},
              {name: 'Montant du bon d\'achat TTC', slug: 'coupon_amount_ttc', template: true},
              {name: 'Montant du bon d\'achat HT', slug: 'coupon_amount_ht', template: true}
            ];
            this.agreementsService.getById(this.route.snapshot.params.action_id, this.route.snapshot.params.agreement_id)
              .then((response: Agreement) => {
                this.currentItem = response;
              })
              .catch((err) => {
                this.alertService.error(err);
              });
            break;
          default:
            break;
        }

      }
    });
  }

  ngOnInit(): void {
    if (this.authService.currentUserValue) {
      this.modules = modules;
      this.modulesStringList = Object.keys(modules);
      this.user = this.authService.currentUserValue;
    }
  }

  ngAfterContentInit() {
  }

  getAgency(agencyId) {
    return this.agenciesList.find(ag => ag.id === agencyId)
  }

  getBrandName() {
    const brandId = this.currentItem.data.general_tire_brand;
    const brand = this.enumsService.enums.brands.find(b => b.id == brandId);
    return brand.name;
  }

  getCouponAmount(ttc = false) {
    const calculatedCouponAmount = this.currentItem.amount

    if (ttc) {
      return (calculatedCouponAmount).toFixed(2);
    } else {
      return (calculatedCouponAmount / 1.2).toFixed(2);
    }
  }


  openPDF() {
    const PDF = new jsPDF('p', 'mm', 'a4');

    // SETTINGS
    PDF.setLineHeightFactor(1.2);
    PDF.addFont('./assets/fonts/roboto/Roboto-Regular.ttf', 'roboto', "normal")
    PDF.addFont('./assets/fonts/roboto/Roboto-Bold.ttf', 'roboto', "bold")
    PDF.setFont('roboto');
    PDF.setFontSize(13)

    // HEADER
    var image = new Image();
    PDF.addImage(
      "./assets/img/pdf/header.jpg", "JPEG", 0, 0,
      PDF.internal.pageSize.getWidth(), 40
    );

    const customer = this.currentItem.data.customer;
    const agency = this.currentItem.agency;

    // ADDRESS BLOCK
    let addressBlock = ``;
    if (customer) {
      addressBlock += `${customer.society ? customer.society : ''}\n`;
      /* street_number to remove ? */
      // addressBlock += `${customer.street_number ? customer.street_number : ''} ${customer.street_name ? customer.street_name : ''} \n`;
      addressBlock += `${customer.street_name ? customer.street_name : ''} \n`;
      if (customer.street_name_more) {
        addressBlock += `${customer.street_name_more}\n`;
      }
      addressBlock += `${customer.zipcode ? customer.zipcode : ''} ${customer.city ? customer.city : ''}\n`;
    }

    var textAddress = PDF
    .splitTextToSize(addressBlock, 10000);

    PDF.text(textAddress, PDF.internal.pageSize.getWidth() - 108, 48);

    // ILLUSTRATION
    var image = new Image();
    PDF.addImage(
      "./assets/img/pdf/middle.jpg", "JPEG", 0, 100,
      PDF.internal.pageSize.getWidth(), 110
    );

    // CONTENT
    PDF.setFont('roboto', 'normal', 700);
    PDF.setFontSize(13);
    PDF.text(`\nCode bon d'achat : ${this.currentItem.data.general_promo_code ? this.currentItem.data.general_promo_code : ''}`, PDF.internal.pageSize.getWidth()/2, 206, {align: 'center'});

    PDF.setFont('roboto', 'normal', 700);
    PDF.setFontSize(14);
    PDF.text('Bénéficiaire', 15, 220);

    PDF.setFont('roboto', 'normal', 'normal');
    PDF.setFontSize(13);
    let blockText = ``;
    if (customer) {
      blockText += `\nSociété : ${customer.society ? customer.society :  ''}\n`;
      blockText += `Rue : ${customer.street_number ? customer.street_number : ''} ${customer.street_name ? customer.street_name : ''} \n`;
      blockText += `Ville : ${customer.zipcode ? customer.zipcode : ''} ${customer.city ? customer.city : ''}\n`;
      blockText += `Siret : ${customer.siret ? customer.siret : ''}\n`;
      blockText += `Tel : ${customer.phone ? customer.phone : ''}\n`;
      blockText += `Email : ${customer.email ? customer.email : ''}\n`;
    }

    blockText += `\n`;

    if (agency && agency.address) {
      PDF.setFont('roboto', 'normal', 700);
      PDF.setFontSize(14);
      PDF.text("Adresse de l'agence", 15, 264);
      blockText += `\n${agency.adherent ? agency.adherent.name : ''}\n${agency.name}\n${agency.address.street_number ? agency.address.street_number : ''} ${agency.address.street_name ? agency.address.street_name : ''} ${agency.address.zipcode ? agency.address.zipcode : ''} ${agency.address.city ? agency.address.city : ''}\n`
      PDF.setFont('roboto', 'normal', 'normal');
      PDF.setFontSize(13);
    }
    PDF.text(blockText, 15, 220);

    // FOOTER
    PDF.setFontSize(11);
    let footerBlock = `*Valable jusqu'au 31 décembre 2021, sur un ou plusieurs véhicules de la société. Utilisable en une seule fois,\n uniquement dans l'agence émettrice du bon d'achat.`;

    PDF.text(footerBlock, PDF.internal.pageSize.getWidth()/2, PDF.internal.pageSize.getHeight() - 10, {align: 'center'});

    // DOWNLOAD
    PDF.save(`agreement-${slugify(this.currentItem.id)}.pdf`);
  }

}
