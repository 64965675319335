import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoyaltyRulesService } from 'src/app/services/loyalty-rules.service';

import { LoyaltyRule } from 'src/app/models/LoyaltyRule';

@Component({
  selector: 'app-loyalty-rules-edit',
  templateUrl: './loyalty-rules-edit.component.html',
  styleUrls: ['./loyalty-rules-edit.component.scss']
})
export class LoyaltyRulesEditComponent implements OnInit {
  public editFormGroup: UntypedFormGroup;
  public submitted: boolean = false;
  public editMode: any = {
    active: false,
    data: null
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private enumsService: EnumsService,
    private loyaltyRulesService: LoyaltyRulesService) {

      this.enumsService.observable.subscribe({
        complete: () => {
          if (this.route.snapshot.params.loyalty_rule_id) {
            this.editMode.active = true;
            this.loyaltyRulesService.getById(this.route.snapshot.params.loyalty_rule_id)
              .then((response: LoyaltyRule) => {
                this.editMode.data = response;
                this.editFormGroup = this.formBuilder.group({
                  name: [response.name, Validators.required],
                  coefficient: [response.coefficient, Validators.min(0)],
                  round: [response.round, Validators.min(0)],
                  extra_points: [response.extra_points, Validators.min(0)],
                  start_at: [response.start_at ? response.start_at.toString().split('T')[0] : null, Validators.required],
                  end_at: [response.end_at ? response.end_at.toString().split('T')[0] : null, Validators.required],
                  active: [response.active, null]
                });
              })
              .catch((err) => {
                this.alertService.error(err);
              });
          } else {
            this.editFormGroup = this.formBuilder.group({
              name: ['', Validators.required],
              coefficient: [0, Validators.min(0)],
              round: [0, Validators.min(0)],
              extra_points: [0, Validators.min(0)],
              start_at: [null, Validators.required],
              end_at: [null, Validators.required],
              active: [false, null]
            });
          }
        }
      });
    }

  ngOnInit(): void {}

  get f() { return this.editFormGroup.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const loyaltyRule = {
      ...this.editFormGroup.value
    };
    if (this.editMode) {
      loyaltyRule.id = this.route.snapshot.params.loyalty_rule_id;
    }
    this.loyaltyRulesService.edit(loyaltyRule)
      .then((response) => {
        this.router.navigate(['/myprofilplus/loyalty-rules']);
        this.alertService.success((this.editMode && this.editMode.active) ? 'Modification enregistrée avec succès.' : 'Promotion enregistrée avec succès.');
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

}
