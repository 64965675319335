import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Questionnaire } from 'src/app/models/Questionnaire';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { PoliciesService } from 'src/app/services/policies.service';

import Swal from 'sweetalert2';
import { QuestionnairesService } from 'src/app/services/questionnaires.service';
import { WorkersService } from 'src/app/services/workers.service';
import { FileInputComponent } from 'src/app/components/file-input/file-input.component';

// https://github.com/SheetJS/sheetjs
import * as XLSX from 'xlsx';

import questionnaire_fields from 'src/assets/questionnaire_fields.json';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-questionnaires',
  templateUrl: './questionnaires.component.html',
  styleUrls: ['./questionnaires.component.scss']
})
export class QuestionnairesComponent implements OnInit {
  public items: Questionnaire[] = [];
  public loading: boolean = false;
  public loadingList: boolean = false;
  @ViewChild('datatable') datatable: any;
  @ViewChild('FileInput') FileInput: FileInputComponent;
  public fileImporting: boolean = false;

  public filtersSelected = null;
  public sortSelected = null;
  public filters: Array<any> = [];
  public editFormGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private workersService: WorkersService,
    private cd: ChangeDetectorRef,
    private alertService: AlertService,
    public enumsService: EnumsService,
    public policiesService: PoliciesService,
    public authService: AuthService,
    private questionnairesService: QuestionnairesService) {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.loading = false;

        this.editFormGroup = this.formBuilder.group({
          file: ['', Validators.required],
        });

        this.editFormGroup.controls.file.valueChanges.subscribe((f) => {
          this.importFile(f)
        })

      }
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.setPage();
    this.cd.detectChanges();
  }

  setPage(
    sortBy: { field: string, order: string } = null, filters = null) {
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected;
    this.loadingList = true;
    this.questionnairesService.getAll(this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.items = response;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  get currentItems() {
    const temp: Array<Questionnaire> = [];
    this.items.forEach((q: Questionnaire) => {
      if (new Date(q.end_at).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0)) {
        temp.push(q);
      }
    });
    return temp;
  }

  get archivedItems() {
    const temp: Array<Questionnaire> = [];
    this.items.forEach((q: Questionnaire) => {
      if (new Date(q.end_at).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) {
        temp.push(q);
      }
    });
    return temp;
  }

  onSort($event: { column: any, sorts: any[] }) {
    this.setPage({ field: $event.column.prop, order: $event.sorts[0].dir });
  }


  downloadCharts(id) {
    this.workersService.startNewWorker('questionnaire_contacts_export', {questionnaire_id: id})
      .then(() => {
        this.alertService.success('Statistiques en cours d\'export, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

  downloadImportModel(id) {
    const questionnaire = this.items.find((q: Questionnaire) => q.id === id);
    if (questionnaire) {
      const data: any[][] = [
        ['questionnaire_id'], // header,
        ['ID du questionnaire'], // header 2,
        [questionnaire.id] // first row
      ]

      // Add columns headers
      questionnaire.society_fields.forEach((field: {slug: string}) => {
        const name = questionnaire_fields.custom_company.find(f => f.slug === field.slug).label;
        const field_infos = questionnaire_fields.custom_company.find(f => f.slug === field.slug)
        if (field_infos) {
          const name = field_infos.label;
          data[0].push(field_infos.slug);
          data[1].push(name);
        }
      });

      questionnaire.contact_fields.forEach((field: {slug: string}) => {
        const field_infos = questionnaire_fields.custom_contact.find(f => f.slug === field.slug)
        if (field_infos) {
          const name = field_infos.label;
          data[0].push(field_infos.slug);
          data[1].push(name);
        }
      });

      /* generate a worksheet */
      var ws = XLSX.utils.aoa_to_sheet(data);

      /* add to workbook */
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Modèle d'import " + id);

      /* write workbook and force a download */
      XLSX.writeFile(wb, `modele_import_questionnaire_${id}.xlsx`);
    }
  }

  selectFile($event) {
    this.editFormGroup.patchValue({file: $event})
  }

  importFile($event) {
    if ($event) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-info'
        },
        buttonsStyling: false,
        reverseButtons: true,
        focusConfirm: false
      });

      swalWithBootstrapButtons.fire({
        title: 'Attention !',
        text: 'Le temps de traitement de votre fichier peut prendre plusieurs minutes. Vous pouvez retrouver les informations de votre import dans le menu "Notifications".',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Confirmer'
      }).then((result) => {
        if (result.value) {
          // this.fileImporting = true;
          this.workersService.startNewWorker('contacts_import',
            {file: this.editFormGroup.controls.file.value})
            .then(() => {
              this.alertService.success('Import en cours, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
            })
            .catch((err) => {
              this.alertService.error(err);
            });
        } else {
          // cancel
          // this.FileInput.clean();
        }
      });
    }
  }


  onDelete(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce questionnaire ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {



        this.questionnairesService.getById(id).then((response: Questionnaire) => {
          if (response) {
            if (response.contacts && response.contacts.length > 0) {
              this.alertService.error('Impossible de supprimer ce questionnaire : des contacts existent déjà.');
            } else {
              this.questionnairesService.delete(id).then(() => {
                const index = this.items.findIndex(e => e.id === id);
                if (index !== -1) {
                  this.items.splice(index, 1);
                  this.items = [...this.items];
                  this.alertService.success('Questionnaire supprimé avec succès.');
                }
              }).catch((err) => {
                this.alertService.error(err);
              });
            }
          }
        }).catch((err) => {
          this.alertService.error(err);
        });




      }
    });
  }
}
