import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { CompaniesService } from 'src/app/services/companies.service';

import { Company } from 'src/app/models/Company';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-companies-edit',
  templateUrl: './companies-edit.component.html',
  styleUrls: ['./companies-edit.component.scss']
})
export class CompaniesEditComponent implements OnInit {
  public adherents: Array<any>;
  public preselectAdherents: number;
  public editFormGroup: UntypedFormGroup;
  public submitted: boolean = false;
  public editMode: any = {
    active: false,
    data: null
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private enumsService: EnumsService,
    private companiesService: CompaniesService,
    public loadingService: LoadingService) {
      this.enumsService.observable.subscribe({
        complete: () => {
          this.adherents = this.enumsService.enums.adherents;
          if (this.route.snapshot.params.company_id) { // UPDATE
            this.editMode.active = true;
            this.companiesService.getById(this.route.snapshot.params.company_id)
              .then((response: Company) => {
                this.editMode.data = response;
                this.editFormGroup = this.formBuilder.group({
                  name: [response.name, Validators.required],
                  mail: [response.mail, Validators.email],
                  phone_number: [response.phone_number, null],
                  phone_number2: [response.phone_number2, null],
                  siret: [response.siret, null],
                  rib: [response.rib, null],
                  vat_number: [response.vat_number, null],
                  adherent_id: [response.adherent.id, null]
                });
                this.preselectAdherents = this.adherents.findIndex(a => a.id === response.adherent.id);
              })
              .catch((err) => {
                this.alertService.error(err);
              });
          } else { // CREATE
            this.editFormGroup = this.formBuilder.group({
              name: ['', Validators.required],
              mail: ['', Validators.email],
              phone_number: ['', null],
              phone_number2: ['', null],
              siret: ['', null],
              rib: ['', null],
              vat_number: ['', null],
              adherent_id: [null, Validators.required]
            });
          }
        }
      });
    }

  get f() { return this.editFormGroup.controls; }

  ngOnInit(): void {}

  onSelectAdherent($event) {
    this.editFormGroup.patchValue({adherent_id: $event.id});
  }

  checkValue($event) {
    if ($event === '') {
      this.editFormGroup.patchValue({adherent_id: null});
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const company = {
      ...this.editFormGroup.value
    };
    if (this.editMode) {
      company.id = this.route.snapshot.params.company_id;
    }
    this.companiesService.edit(company)
      .then((response) => {
        this.router.navigate(['/adherents-companies-management/companies']);
        this.alertService.success((this.editMode && this.editMode.active) ? 'Modification enregistrée avec succès.' : 'Société enregistrée avec succès.');
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

}
