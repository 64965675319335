import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { FileManager } from 'src/app/models/FileManager';
import { User } from 'src/app/models/User';

import { FileManagerService } from 'src/app/services/file-manager.service';

import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { PoliciesService } from 'src/app/services/policies.service';

import modules from '../../../../assets/modules.json';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit {
  public editFormGroup: UntypedFormGroup;
  public submitted: boolean = false;
  public modules;
  public modulesStringList;
  public user: User;
  public loading: boolean = false;
  public items: Array<FileManager> = [];

  constructor(public policiesService: PoliciesService,
              private alertService: AlertService,
              public router: Router,
              private formBuilder: UntypedFormBuilder,
              private authService: AuthService,
              private fileManagerService: FileManagerService,
              private enumsService: EnumsService) {

    this.editFormGroup = this.formBuilder.group({
      file: [null, Validators.required]
    });

    this.modules = modules;
    this.policiesService.currentUser = this.authService.currentUserValue;
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.fileManagerService.getAll().then((response: Array<FileManager>) => {
          this.items = response;
          this.loading = false;
        })
        .catch((err) => {
          this.alertService.error(err);
          this.loading = false;
        });
      }
    });
  }

  ngOnInit(): void {}

  onFileSelected(files: Array<File>) {
    this.editFormGroup.patchValue({file: files[0]});
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const fileManager = {
      ...this.editFormGroup.value
    };
    this.fileManagerService.create(fileManager).then((response) => {
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
      this.alertService.success('Fichier déposé avec succès.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

  copyToKeyboard(value) {
    navigator.clipboard.writeText(value);
  }

  onDelete(file) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce fichier ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.fileManagerService.delete(file)
        .then((response) => {
          const currentUrl = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
          this.alertService.success('Fichier supprimé avec succès.');
        })
        .catch((err) => {
          this.alertService.error(err);
        });
      }
    });
  }

}
