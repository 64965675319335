<app-topbar moduleSlug="users-management">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/users-management']">Utilisateurs</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Liste des utilisateurs</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div *ngIf="policiesService.currentUser.policies.user.create" class="row">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round"
     [routerLink]="['/users-management/edit']">
      <i class="material-icons">library_add</i>
      Créer un nouvel utilisateur
    </a>
    <button class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="exportUsers()">
      <i class="material-icons">library_add</i>
      Export Excel des utilisateurs
    </button>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">supervisor_account</i>
        </div>
        <h4 class="card-title">Liste des utilisateurs</h4>
      </div>
      <div class="card-body">
        <p>
          <strong class="description">Remarque :</strong> les utilisateurs de type "administrateurs" n'ont pas d'adhérents et d'agences.
        </p>
        <app-filter-bar [filters]="filters"
          (changed)="setPage({offset: 0}, null, $event)">
          <p style="margin-top: 10px;" *ngIf="loading">chargement...</p>
        </app-filter-bar>
        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [externalPaging]="true"
            [externalSorting]="true"
            [loadingIndicator]="loadingList"
            [reorderable]="false"
            (page)="setPage($event)"
            (sort)="onSort($event)"
          >
            <ngx-datatable-column name="ID" prop="id" [width]="50">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Email" prop="email" [width]="240">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Prénom" prop="first_name" [width]="170">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Nom" prop="last_name" [width]="170">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Admin ?" prop="is_admin" [width]="100">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <i *ngIf="value; else inactive" class="material-icons green">done</i>
                  <ng-template #inactive>
                    <i class="material-icons red">clear</i>
                  </ng-template>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Adhérents" prop="adherents" [width]="220">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngFor="let adherent of value | slice:0:3">
                  <div>{{adherent.name}}</div>
                </ng-container>
                <div *ngIf="value.length > 3">...</div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Agences" prop="agencies" [width]="240">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngFor="let agency of value | slice:0:3">
                  <div>{{agency.name}}</div>
                </ng-container>
                <div *ngIf="value.length > 3">...</div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="policiesService.currentUser.policies.user.update"
                    [routerLink]="['/users-management/edit/', row.id]" type="button" class="btn btn-success" title="Modifier les informations">
                  <i class="material-icons">edit</i>
                </a>
                <a *ngIf="policiesService.currentUser.policies.user.update"
                    [routerLink]="['/users-management/edit/', row.id, 'rules']" type="button" class="btn btn-info" title="Gestion des droits">
                  <i class="material-icons">https</i>
                </a>
                <ng-container *ngIf="!row.is_admin">
                  <a *ngIf="policiesService.currentUser.policies.user.update"
                      [routerLink]="['/users-management/edit/', row.id, 'adherents-agencies']"  type="button" class="btn btn-primary" title="Gestion des adhérents et des agences">
                    <i class="material-icons">domain</i>
                  </a>
                </ng-container>
                <button *ngIf="policiesService.currentUser.policies.user.delete" type="button" class="btn btn-danger" title="Supprimer" (click)="onDelete(row.id)">
                  <i class="material-icons">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>

      </div>
    </div>
  </div>
</div>
