import { Component, OnInit, Input, ContentChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-show-content',
  templateUrl: './show-content.component.html',
  styleUrls: ['./show-content.component.scss']
})
export class ShowContentComponent implements OnInit {
  @ContentChild(TemplateRef) template: TemplateRef<any>;

  @Input() item: Object;
  @Input() fields: Array<{name: string, slug: string, template: boolean}> = null;


  constructor(private router: Router) {}

  ngOnInit(): void {}

}
