<form *ngIf="editFormGroup" [formGroup]="editFormGroup">

  <p class="form-group is-required"
    [ngClass]="{ 'has-danger': submitted && f.name.errors }">
    <label class="bmd-label-floating">Nom de la rubrique :</label>
    <input type="text" required class="form-control" id="title" formControlName="name">
    <label class="error" for="title" *ngIf="submitted && f.name.errors">
      Champ obligatoire.
    </label>
  </p>

  <div style="margin-top:20px;">
    <p style="font-weight:500;margin-bottom:0;">Questions associées à la rubrique :</p>
    <div *ngIf="submitted && f.questions.value.length < 1" class="form-group has-danger">
      <label for="" class="error">Veuillez créer au moins une question afin de créer cette rubrique.</label>
    </div>
    <div class="questions">
      <div cdkDropList class="dragAndDrop-list"  (cdkDropListDropped)="drop($event)">
        <ul class="dragAndDrop-box" *ngFor="let item of f.questions.value" cdkDrag>
          <li>
            <div class="dragAndDrop-handle" cdkDragHandle>
              <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
            <div class="dragAndDrop-custom-placeholder" *cdkDragPlaceholder></div>
            <div class="pp-rubrique-content" *ngIf="!questionEdited.active || questionEdited.uuid !== item.uuid">
              {{item.name}}
              <ng-container *ngIf="item.required; else templateFacultatif">
                <span> (obligatoire)</span>
              </ng-container>
              <ng-template #templateFacultatif>
                <span> (optionnelle)</span>
              </ng-template>
            </div>
            <div *ngIf="!questionEdited.active || questionEdited.uuid !== item.uuid">
              <a href="#" class="ppp-rubrics-link" (click)="$event.preventDefault(); questionEdited.active = true; questionEdited.uuid = item.uuid">
                éditer
              </a>
              |
              <a href="#" class="ppp-rubrics-link" (click)="$event.preventDefault(); onDeleteQuestion(item.uuid)">
                supprimer
              </a>
            </div>
            <app-question-form [question]="item" *ngIf="questionEdited.active && questionEdited.uuid === item.uuid"
                               (canceled)="questionEdited.active = false; questionEdited.uuid = null" (edited)="onQuestionEdited($event)">
            </app-question-form>
          </li>
        </ul>
      </div>
      <div>
        <a href="#" *ngIf="questionEdited.uuid !== null || !questionEdited.active"
          class="ppp-button ppp-button-black btn btn-primary btn-round"
          (click)="$event.preventDefault(); questionEdited.active = true; questionEdited.uuid = null">
          Ajouter une nouvelle question
        </a>
        <app-question-form *ngIf="questionEdited.active && !questionEdited.uuid"
          (canceled)="questionEdited.active = false; questionEdited.uuid = null" (edited)="onQuestionEdited($event)">
        </app-question-form>
      </div>
    </div>
  </div>

  <button class="ppp-button ppp-button-gray btn btn-primary btn-round" type="submit" (click)="onCancel()">
    Annuler
  </button>
  <button class="ppp-button ppp-button-black btn btn-primary btn-round"
    type="submit" (click)="onSubmit()">
    <ng-container *ngIf="rubric; else ElseBlock">Mettre à jour la rubrique</ng-container>
    <ng-template #ElseBlock>Enregistrer la rubrique</ng-template>
  </button>

</form>
