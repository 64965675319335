import { Injectable } from '@angular/core';
import { LoadingService } from '../services/loading.service';

import config from '../config';


@Injectable({ providedIn: 'root' })
export class ApiService {
  public defaultConfig: any = {
    headers: new Headers(),
    mode: 'cors',
  };

  constructor(private loadingService: LoadingService) {
  }

  public get(path) {
    this.loadingService.loading = true;
    return new Promise((resolve, reject) => {
      fetch(config.apiUrl + path, {
        ...this.defaultConfig,
        method: 'GET'
      })
        .then(async (response: Response) => {
          let result = null;
          if (response.status != 204) {
            result = await response.json();
          }
          if (!response.ok) {
            let message = 'Impossible de traiter votre demande.';
            if (result && result.message && result.message.length > 0) {
              message = result.message;
            }
            reject(new Error(message));
          }
          return result;
        })
        .then((result) => {
          this.loadingService.loading = false;
          resolve(result);
        })
        .catch(err => {
          this.loadingService.loading = false;
          reject(err);
        });
    });
  }

  public getBlob(path) {
    this.loadingService.loading = true;
    return new Promise((resolve, reject) => {
      fetch(path, {
        ...this.defaultConfig,
        method: 'GET'
      })
        .then((response: Response) => {
          if (!response.ok) {
            reject(new Error('Impossible de traiter votre demande.'));
          }
          return response.blob();
        })
        .then((blob) => {
          this.loadingService.loading = false;
          resolve(blob);
        })
        .catch(err => {
          this.loadingService.loading = false;
          reject(err);
        });
    });
  }

  public post(path, params) {
    this.loadingService.loading = true;
    return new Promise((resolve, reject) => {
      fetch(config.apiUrl + path, {
        ...this.defaultConfig,
        method: 'POST',
        body: params
      })
      .then(async (response: any) => {
          let result = null;
          if (response.status != 204) {
            result = await response.json();
          }
          if (!response.ok) {
            let message = 'Impossible de traiter votre demande.';
            if (result && result.message && result.message.length > 0) {
              message = result.message;
            }
            reject(new Error(message));
          }
          return result;
        })
        .then((result) => {
          this.loadingService.loading = false;
          resolve(result);
        })
        .catch(err => {
          this.loadingService.loading = false;
          reject(err);
        });
    });
  }


  public put(path, params) {
    this.loadingService.loading = true;
    return new Promise((resolve, reject) => {
      fetch(config.apiUrl + path, {
        ...this.defaultConfig,
        method: 'PUT',
        body: params
      })
      .then(async (response: any) => {
        let result = null;
        if (response.status != 204) {
          result = await response.json();
        }
        if (!response.ok) {
          let message = 'Impossible de traiter votre demande.';
          if (result && result.message && result.message.length > 0) {
            message = result.message;
          }
          reject(new Error(message));
        }
        return result;
      })
      .then((result) => {
        this.loadingService.loading = false;
        resolve(result);
      })
      .catch(err => {
        this.loadingService.loading = false;
        reject(err);
      });
    });
  }


  public delete(path) {
    this.loadingService.loading = true;
    return new Promise((resolve, reject) => {
      fetch(config.apiUrl + path, {
        ...this.defaultConfig,
        method: 'DELETE'
      })
        .then(async (response: Response) => {
          let result = null;
          if (response.status != 204) {
            result = await response.json();
          }
          if (!response.ok) {
            let message = 'Impossible de traiter votre demande.';
            if (result && result.message && result.message.length > 0) {
              message = result.message;
            }
            reject(new Error(message));
          }
          return result;
        })
        .then((result) => {
          this.loadingService.loading = false;
          resolve(result);
        })
        .catch(err => {
          this.loadingService.loading = false;
          reject(err);
        });
    });
  }
}
