<ng-container *ngIf="!loading; else templateElse">
  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
          <li class="breadcrumb-item"><a href="javascript:;">Gestion des pneus</a></li>
          <li class="breadcrumb-item active"><a href="javascript:;">Informations de marque</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <div class="row" *ngIf="policiesService.currentUser.policies['brand-information'].create">
    <div class="col-md-12">
      <a class="ppp-button ppp-button-black btn btn-primary btn-round"
       [routerLink]="['/website/tires/brand-informations/edit']">
        <i class="material-icons">library_add</i>
        Créer de nouvelles informations de marque
      </a>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">directions_car</i>
          </div>
          <h4 class="card-title">Liste des informations de marque</h4>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <ngx-datatable
              class="material"
              [rows]="items"
              [columnMode]="'standard'"
              [rowHeight]="'auto'"
              [headerHeight]="50"
              [footerHeight]="50"
              [loadingIndicator]="loading"
            >
              <ngx-datatable-column name="ID" prop="id" [width]="75">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Marque" prop="brand" [width]="150">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a title="Voir la marque" class="ppp-link" target="_blank" [routerLink]="['/website/tires/brands/edit/', value.id]" *ngIf="value && policiesService.currentUser.policies.brand.update; else brandOnlyData">
                    {{ getBrand(value.id).name }}
                  </a>
                  <ng-template #brandOnlyData>
                    {{ getBrand(value.id).name }}
                  </ng-template>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Univers" prop="universe" [width]="100">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{row.universe.name}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Vidéo" prop="video" [width]="125">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Description" prop="description" [width]="500">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <span [title]="value">{{ value | truncate: 350 }}</span>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Actions" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a *ngIf="policiesService.currentUser.policies['brand-information'].update" [routerLink]="['/website/tires/brand-informations/edit', row.id]" type="button" class="btn btn-success" title="Modifier les informations de marque">
                    <i class="material-icons">edit</i>
                  </a>
                  <button *ngIf="policiesService.currentUser.policies['brand-information'].delete" type="button" class="btn btn-danger" (click)="onDelete(row.id)" title="Supprimer">
                    <i class="material-icons">close</i>
                  </button>
                </ng-template>
              </ngx-datatable-column>

            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #templateElse>
  <app-loading></app-loading>
</ng-template>
