import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

import { EnumsService } from 'src/app/services/enums.service';
import { AlertService } from 'src/app/services/alert.service';
import { AgenciesService } from 'src/app/services/agencies.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { AuthService } from 'src/app/services/auth.service';

import { Agency } from 'src/app/models/Agency';

@Component({
  selector: 'app-edit-correspondants',
  templateUrl: './edit-correspondants.component.html',
  styleUrls: ['./edit-correspondants.component.scss']
})
export class EditCorrespondantsComponent implements OnInit {
  public loading: boolean = false;
  public agency: Agency;
  public correspondants: Array<any> = [];
  public showEditModal: boolean = false;
  public editFormGroup: UntypedFormGroup;
  public submitted: boolean = false;

  public columns = [
    { name: 'Rôle', slug: 'role' },
    { name: 'ID', slug: 'id' },
    { name: 'Nom', slug: 'lastname' },
    { name: 'Prénom', slug: 'firstname' },
    { name: 'Email', slug: 'email' },
    { name: 'Numéro(s) de téléphone', slug: 'phone', template: true }
  ];

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private enumsService: EnumsService,
    private alertService: AlertService,
    public policiesService: PoliciesService,
    private authService: AuthService,
    private agenciesService: AgenciesService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
    }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        if (this.router.url.split('/').includes('open-agencies')) {
          this.agency = this.enumsService.enums.agencies.find(a => a.id === parseInt(this.route.snapshot.params.id));
        } else {
          this.agency = this.enumsService.enums.agencies_closed.find(a => a.id === parseInt(this.route.snapshot.params.id));
        }

        this.agenciesService.getCorrespondants(this.route.snapshot.params.id)
          .then((response: Array<any>) => {
            this.loading = false;
            this.initializeItems(response)
          })
          .catch(err => {
            this.loading = false;
            this.alertService.error(err);
          });

        this.editFormGroup = this.formBuilder.group({
          id: ['', null],
          name: ['', Validators.required],
          during: ['', Validators.required]
        });

      }
    });
  }

  get f() {
    return this.editFormGroup.controls;
  }

  initializeItems(correspondants) {
    // Update role title with increment over the id
    const objsSorted = correspondants.sort((a, b) => a.id - b.id) // force order by id
    const array_temp: {roleLabel: string, lastIndex: number}[] = []
    objsSorted.forEach(corr => {
      const index = array_temp.findIndex(at => at.roleLabel == corr.role)
      if (index != -1) {
        array_temp[index].lastIndex += 1
        corr.role_index = array_temp[index].lastIndex
      } else {
        array_temp.push({roleLabel: corr.role, lastIndex: 1})
        corr.role_index = 1
      }
    })
    this.correspondants = objsSorted;
  }

  onDelete(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce correspondant ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
      this.agenciesService.deleteCorrespondants(this.route.snapshot.params.id, id)
        .then(() => {
          const index = this.correspondants.findIndex(cd => cd.id === id);
          if (index !== -1) {
            this.correspondants.splice(index, 1);
            this.initializeItems([...this.correspondants])
            this.alertService.success('Correspondant supprimé avec succès.');
          }
        })
        .catch((err) => {
          this.alertService.error(err);
        });
      }
    });
  }
}
