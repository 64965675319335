<app-topbar moduleSlug="meetings">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/meetings']">Salons</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Contacts</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row" *ngIf="policiesService.currentUser.policies.contact.create">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round" [routerLink]="['edit']">
      <i class="material-icons">library_add</i>
      Créer un nouveau contact
    </a>
  </div>
</div>

<div class="col-md-12">
  <div class="row">
    <div class="card">

      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">contact_mail</i>
        </div>
        <h4 class="card-title">Liste des contacts</h4>
      </div>

      <div class="card-body">

        <app-filter-bar [filters]="filters" (changed)="setPage({offset: 0}, null, $event)">
          <p *ngIf="loadingList">chargement...</p>
        </app-filter-bar>

        <div class="table-responsive">
          <ngx-datatable #datatable class="material" [rows]="items" [columnMode]="'standard'" [rowHeight]="'auto'"
            [headerHeight]="50" [footerHeight]="50" [externalPaging]="true" [externalSorting]="true"
            [loadingIndicator]="loadingList" [reorderable]="false" (page)="setPage($event)" (sort)="onSort($event)">

            <ngx-datatable-column [resizeable]="false" name="ID" prop="id" [width]="75">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{value}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Questionnaire" prop="questionnaire" [width]="280">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{value.name}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Année du Salon" prop="for_year" [width]="280">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{row.questionnaire.for_year}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Raison sociale" prop="social_reason" [width]="180" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getFieldFromAnswer('social_reason', row.society_fields_answers)}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Nom" prop="last_name" [width]="135" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getFieldFromAnswer('last_name', row.contact_fields_answers)}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Prénom" prop="first_name" [width]="160" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getFieldFromAnswer('first_name', row.contact_fields_answers)}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Email" prop="email" [width]="160" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{getFieldFromAnswer('email', row.contact_fields_answers)}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Actions" [width]="100" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="policiesService.currentUser.policies.contact.update"
                  [routerLink]="['/meetings/contacts/edit/', row.id , 'questionnaire', row.questionnaire.id]"
                  type="button" class="btn btn-success" title="Modifier les informations">
                  <i class="material-icons">edit</i>
                </a>
                <button *ngIf="policiesService.currentUser.policies.contact.delete" type="button" class="btn btn-danger"
                  (click)="onDelete(row.id)" title="Supprimer">
                  <i class="material-icons">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>

          </ngx-datatable>
        </div>
      </div>

    </div>
  </div>
</div>
