import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { CandidaturesService } from 'src/app/services/candidatures.service';
import { EnumsService } from 'src/app/services/enums.service';
import Swal from 'sweetalert2';
import {WorkersService} from '../../../services/workers.service';
import { jsPDF } from 'jspdf';
import slugify from 'src/app/helpers/slugify';

@Component({
  selector: 'app-candidatures',
  templateUrl: './candidatures.component.html',
  styleUrls: ['./candidatures.component.scss']
})
export class CandidaturesComponent implements OnInit {
  type: string;
  loading!: boolean;
  loadingList!: boolean;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  filters: any[] = [
    {
      name: 'Ville du candidat',
      slug: 'city',
      type: 'text',
      class: 'col-3',
      value: null
    },
    {
      name: 'Date de début',
      slug: 'start_at',
      type: 'date',
      class: 'col-2',
      value: null
    },
    {
      name: 'Date de fin',
      slug: 'end_at',
      type: 'date',
      class: 'col-2',
      value: null
    }
  ];
  items = [];

  @ViewChild('datatable') datatable: any;

  constructor(
    private cd: ChangeDetectorRef,
    private alertService: AlertService,
    public authService: AuthService,
    private candidaturesService: CandidaturesService,
    private enumsService: EnumsService,
    private workersService: WorkersService,
    private route: ActivatedRoute) {
    this.route.data.subscribe((data) => {
      this.type = data.type;
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.loading = false;
        this.filters.unshift({
          name: 'Région',
          slug: 'region',
          type: 'select',
          class: 'col-3',
          options: this.enumsService.enums.regions.map(x => x.name)
        });
      }
    });
  }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected;
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;
    this.candidaturesService.getAllWithPagination(this.type, this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

  exportCandidatures() {
    this.workersService.startNewWorker('export_candidatures', this.filtersSelected).then(() => {
      this.alertService.success('Export de la liste des candidatures, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

  exportOffers() {
    this.workersService.startNewWorker('export_offers', null).then(() => {
      this.alertService.success('Export de la liste des offres, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

  async exportOffersPdf() {
    this.workersService.startNewWorker('export_offers_pdf', null).then(() => {
      this.alertService.success('Export de la liste des offres, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

  onDelete(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cette candidature ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.candidaturesService.delete(id)
        .then(() => {
          const index = this.items.findIndex(e => e.id === id);
          if (index !== -1) {
            this.items.splice(index, 1);
            this.items = [...this.items];
            this.alertService.success('Candidature supprimée avec succès.');
          }
        })
        .catch((err) => {
          this.alertService.error(err);
        });
      }
    });
  }
}
