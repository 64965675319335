import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import ActivityForm from '../models/activityForm';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';
import acitivityFormFields from '../../assets/activity_form_fields.json';

@Injectable({providedIn: 'root'})
export class ActivityFormsService {
  constructor(private apiService: ApiService) { }

  dashboard(year, month) {
    return new Promise((resolve, reject) => {
      this.apiService.get(`activity_forms/dashboard?year=${year}&month=${month}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    })
  }

  getAllWithPagination(page: string | number, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('activity_forms?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
        + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id) {
    return new Promise((resolve, reject) => {
      this.apiService.get('activity_forms/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('activity_forms/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(form: ActivityForm) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      for (const field of acitivityFormFields) {
        formData.append(`activity_form:${field.slug}`, form[field.slug] != null ? form[field.slug] : "")
      }

      if (form.id) { // UPDATE
        this.apiService.put('activity_forms/' + form.id, formData)
        .then((response: ActivityForm) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      } else { // CREATE
        this.apiService.post('activity_forms', formData)
        .then((response: ActivityForm) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }
}
