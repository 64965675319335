import { Component, OnInit } from '@angular/core';
import { Brand } from 'src/app/models/Brand';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { BrandsService } from 'src/app/services/brands.service';
import { EnumsService } from 'src/app/services/enums.service';
import { PoliciesService } from 'src/app/services/policies.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {
  items: Array<any> = null;

  public loading: boolean = false;
  public loadingList: boolean = false;

  constructor(
    private alertService: AlertService,
    public enumsService: EnumsService,
    public brandsService: BrandsService,
    public authService: AuthService,
    public policiesService: PoliciesService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
    }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.loading = false;
        this.setPage();
      }
    });
  }

  setPage() {
    this.loadingList = true;
    this.brandsService.getAll()
      .then((response: Array<Brand>) => {
        this.items = response;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort(event) {
    this.setPage();
  }

  onDelete(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cette marque ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.brandsService.delete(id)
        .then(() => {
          const index = this.items.findIndex(e => e.id === id);
          if (index !== -1) {
            this.items.splice(index, 1);
            this.items = [...this.items];
            this.alertService.success('Marque supprimée avec succès.');
          }
        })
        .catch((err) => {
          this.alertService.error(err);
        });
      }
    });
  }
}
