import { Component, OnInit } from '@angular/core';

import modules from '../../../assets/modules.json';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/User';
import { PoliciesService } from 'src/app/services/policies.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [ // Have is own instance
    PoliciesService
  ]
})
export class DashboardComponent implements OnInit {
  public modules;
  public modulesStringList;
  public user: User;
  constructor(private authService: AuthService,
              public policiesService: PoliciesService) {
    this.modules = modules;
  }

  ngOnInit(): void {
    if (this.authService.currentUserValue) {
      this.modules = modules;
      this.modulesStringList = Object.keys(modules);
      this.user = this.authService.currentUserValue;
      this.policiesService.currentUser = this.authService.currentUserValue;
    }
  }

}
