import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { AppointmentConfigurationsService } from 'src/app/services/appointment-configurations.service';
import { AgenciesService } from 'src/app/services/agencies.service';
import { AppointmentTypesService } from 'src/app/services/appointment-types.service';

import { AppointmentConfiguration } from 'src/app/models/AppointmentConfiguration';

@Component({
  selector: 'app-appointment-configurations-edit',
  templateUrl: './appointment-configurations-edit.component.html',
  styleUrls: ['./appointment-configurations-edit.component.scss']
})
export class AppointmentConfigurationsEditComponent implements OnInit {
  public agencies: Array<any>;
  public preselectAgencies: number;
  public appointmentTypes: Array<any>;
  public preselectAppointmentTypes: number;
  public editFormGroup: UntypedFormGroup;
  public submitted: boolean = false;
  public editMode: any = {
    active: false,
    data: null
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private enumsService: EnumsService,
    private appointmentConfigurationsService: AppointmentConfigurationsService,
    private agenciesService: AgenciesService,
    private appointmentTypesService: AppointmentTypesService) {
      this.enumsService.observable.subscribe({
        complete: () => {
          this.agenciesService.getAll().then((response: Array<any>) => {
            this.agencies = response;
            this.appointmentTypesService.getAll().then((response: Array<any>) => {
              this.appointmentTypes = response;
              if (this.route.snapshot.params.appointment_configuration_id) {
                this.editMode.active = true;
                this.appointmentConfigurationsService.getById(this.route.snapshot.params.appointment_configuration_id)
                  .then((response: AppointmentConfiguration) => {
                    this.editMode.data = response;
                    this.editFormGroup = this.formBuilder.group({
                      duration: [response.duration, Validators.required],
                      ressources_count: [response.ressources_count, Validators.required],
                      min_delay: [response.min_delay, null],
                      agency: [response.agency.id, Validators.required],
                      appointment_type: [response.appointment_type.id, Validators.required]
                    });
                    if (response.agency) {
                      this.preselectAgencies = this.agencies.findIndex(a => a.id === response.agency.id);
                    }
                    if (response.appointment_type) {
                      this.preselectAppointmentTypes = this.appointmentTypes.findIndex(a => a.id === response.appointment_type.id);
                    }
                  })
                  .catch((err) => {
                    this.alertService.error(err);
                  });
              } else {
                this.editFormGroup = this.formBuilder.group({
                  duration: [0, Validators.required],
                  ressources_count: [0, Validators.required],
                  min_delay: [0, null],
                  agency: [null, Validators.required],
                  appointment_type: [null, Validators.required]
                });
              }
            })
            .catch((err) => {
              this.alertService.error(err);
            });
          })
          .catch((err) => {
            this.alertService.error(err);
          });
        }
      });
    }

  get f() { return this.editFormGroup.controls; }

  ngOnInit(): void {}

  onSelectAgency($event) {
    this.editFormGroup.patchValue({agency: $event.id});
  }

  checkValueAgency($event) {
    if ($event === '') {
      this.editFormGroup.patchValue({agency: null});
    }
  }

  onSelectAppointmentType($event) {
    this.editFormGroup.patchValue({appointment_type: $event.id});
  }

  checkValueAppointmentType($event) {
    if ($event === '') {
      this.editFormGroup.patchValue({appointment_type: null});
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const appointmentConfiguration = {
      ...this.editFormGroup.value
    };
    if (this.editMode) {
      appointmentConfiguration.id = this.route.snapshot.params.appointment_configuration_id;
    }
    this.appointmentConfigurationsService.edit(appointmentConfiguration)
      .then((response) => {
        this.router.navigate(['/website/settings/appointment-configurations']);
        this.alertService.success((this.editMode && this.editMode) ? 'Modification enregistrée avec succès.' : 'Configuration de rendez-vous enregistrée avec succès.');
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

}
