import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { ProfilplusDirectRoutingModule } from './profilplus-direct-routing.module';
import { ProfilplusDirectComponent } from './profilplus-direct.component';
import { SitesComponent } from './sites/sites.component';
import { AddressesComponent } from './sites/addresses/addresses.component';
import { InterventionsComponent } from './interventions/interventions.component';
import { CommentsComponent } from './interventions/comments/comments.component';
import { ShowComponent } from './show/show.component';

@NgModule({
  imports:
  [
    SharedModule,
    CommonModule,
    ProfilplusDirectRoutingModule,

    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot()
  ],
  declarations: [
    ProfilplusDirectComponent,
    SitesComponent,
    AddressesComponent,
    InterventionsComponent,
    CommentsComponent,
    ShowComponent
  ],
  exports: [
    ProfilplusDirectComponent,
    SitesComponent,
    AddressesComponent
  ],
  providers: []
})
export class ProfilplusDirectModule {}
