<div class="fileinput">
  <div>
    <span [class]="btnClass !== '' ? btnClass : defaultBtnClass" class="btn-file">
        <span class="fileinput-new" *ngIf="file === null; else templateElse">{{label}}</span>
        <ng-template #templateElse>
          <span class="fileinput-exists">Changer</span>
        </ng-template>
        <ng-container *ngIf="capture; else normal">
          <input
          type="file"
          accept="image/*"
          capture="environment"
          name="..."
          (change)="import($event.target.files[0])"/>
        </ng-container>
        <ng-template #normal>
          <input
            type="file"
            name="..."
            (change)="import($event.target.files[0])"/>
        </ng-template>
    </span>
    <a *ngIf="file !== null" href="#" class="btn btn-gray btn-round fileinput-exists"
      data-dismiss="fileinput" (click)="$event.preventDefault(); clean()"><i class="fa fa-times"></i> Supprimer</a>
  </div>
</div>
