<ng-container *ngIf="!loading; else templateLoading">

  <app-topbar moduleSlug="profilplus-agences">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences']">Profil Plus Agences</a></li>
          <li class="breadcrumb-item">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies'] : ['/profilplus-agences/closed-agencies']">
              Liste des agences {{ router.url.split('/').includes('open-agencies') ? 'ouvertes' : 'fermées' }}
            </a>
          </li>
          <li class="breadcrumb-item" *ngIf="agency">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit', agency.id] : ['/profilplus-agences/closed-agencies/edit', agency.id]">
              Agence {{agency.name}}
            </a>
          </li>
          <li class="breadcrumb-item active" *ngIf="agency"><a href="javascript:;">Éditer les services</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">playlist_add_check</i>
          </div>
          <h4 class="card-title" *ngIf="agency">Édition des services : <strong class="description">{{agency.name}}</strong></h4>
        </div>
        <div class="card-body">
          <!-- BUTTONS -->
          <div>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/', agency.id] : ['/profilplus-agences/closed-agencies/edit/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">store</i> Fiche agence
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/skills/', agency.id] : ['/profilplus-agences/closed-agencies/edit/skills/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">build</i> Métiers
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/services/', agency.id] : ['/profilplus-agences/closed-agencies/edit/services/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">playlist_add_check</i> Services
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/close-dates/', agency.id] : ['/profilplus-agences/closed-agencies/edit/close-dates/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">report</i> Dates fermeture
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/opening-times/store/', agency.id] : ['/profilplus-agences/closed-agencies/edit/opening-times/store/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">store</i> Horaires magasin
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/correspondants/edit/', agency.id] : ['/profilplus-agences/closed-agencies/correspondants/edit/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">people</i> Correspondants
            </a>
            <a *ngIf="policiesService.currentUser.is_admin && router.url.split('/').includes('open-agencies')"
                [routerLink]="['/profilplus-agences/open-agencies/edit/settings', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">settings</i> Paramètres avancés
            </a>
          </div>

          <div *ngIf="hasThisSkill(1) && vlServices && agencyServices">
            <div class="card">
              <div class="card-body">
                <h4>Services <strong class="description">"Auto, Camionnette, 4X4"</strong></h4>
                <div class="form-check" *ngFor="let service of vlServices">
                  <div class="form-group col-lg-6" style="display:inline-block;">
                    <label class="form-check-label" [for]="service.id">
                      <input class="form-check-input"
                            [disabled]="isDisabled(service.slug, 'vl')"
                            type="checkbox"
                            [checked]="fromAgencyServices(service.id) ||
                              (service.slug === 'diagnostic-visuel-offert'
                              && fromAgencyServices(searchBySlug('vidange')))"
                            (change)="onSelectService($event, 'vl')"
                            [value]="service.id"
                            [name]="service.id" [id]="service.id">
                            {{service.title}}
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-group col-lg-6" style="display:inline-block;">
                    <input *ngIf="fromAgencyServices(service.id)"
                      type="text"
                      class="form-control" placeholder="À compléter"
                      [value]="fromAgencyServices(service.id).data"
                      (input)="updateServiceInfo(service.id, $event)"
                      [id]="service.id">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="hasThisSkill(2) && motoServices && agencyServices">
            <div class="card">
              <div class="card-body">
                <h4>Services <strong class="description">"Moto"</strong></h4>
                <div class="form-check" *ngFor="let service of motoServices">
                  <div class="form-group col-lg-4" style="display:inline-block;">
                    <label class="form-check-label" [for]="service.id">
                      <input class="form-check-input"
                            [disabled]="isDisabled(service.slug, 'moto')"
                            type="checkbox"
                            [checked]="fromAgencyServices(service.id)"
                            (change)="onSelectService($event, 'moto')"
                            [value]="service.id"
                            [name]="service.id" [id]="service.id">
                            {{service.title}}
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-group col-lg-2" style="display:inline-block;">
                    <input *ngIf="fromAgencyServices(service.id)"
                      type="text"
                      class="form-control" placeholder="Données complémentaires"
                      [value]="fromAgencyServices(service.id).data"
                      (input)="updateServiceInfo(service.id, $event)"
                      [id]="service.id">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="hasThisSkill(3) && agServices && agencyServices">
            <div class="card">
              <div class="card-body">
                <h4>Services <strong class="description">"Agraire"</strong></h4>
                <div class="form-check" *ngFor="let service of agServices">
                  <div class="form-group col-lg-4" style="display:inline-block;">
                    <label class="form-check-label" [for]="service.id">
                      <input class="form-check-input"
                            [disabled]="isDisabled(service.slug, 'ag')"
                            type="checkbox"
                            [checked]="fromAgencyServices(service.id)"
                            (change)="onSelectService($event, 'ag', service.title)"
                            [value]="service.id"
                            [name]="service.id" [id]="service.id">
                            {{service.title}}
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-group col-lg-2" style="display:inline-block;">
                    <input *ngIf="fromAgencyServices(service.id)"
                      type="text"
                      class="form-control" placeholder="Données complémentaires"
                      [value]="fromAgencyServices(service.id).data"
                      (input)="updateServiceInfo(service.id, $event)"
                      [id]="service.id"
                      >
                    </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="hasThisSkill(4) && plServices && agencyServices">
            <div class="card">
              <div class="card-body">
                <h4>Services <strong class="description">"Poids lourd"</strong></h4>
                <div class="form-check" *ngFor="let service of plServices">
                  <div class="form-group col-lg-4" style="display:inline-block;">
                    <label class="form-check-label" [for]="service.id">
                      <input class="form-check-input"
                            type="checkbox"
                            [disabled]="isDisabled(service.slug, 'pl')"
                            [checked]="fromAgencyServices(service.id)"
                            (change)="onSelectService($event, 'pl', service.title)"
                            [value]="service.id"
                            [name]="service.id" [id]="service.id">
                            {{service.title}}
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-group col-lg-2" style="display:inline-block;">
                    <input *ngIf="fromAgencyServices(service.id)"
                      type="text"
                      class="form-control" placeholder="Données complémentaires"
                      [value]="fromAgencyServices(service.id).data"
                      (input)="updateServiceInfo(service.id, $event)"
                      [id]="service.id">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="hasThisSkill(5) && gcServices && agencyServices">
            <div class="card">
              <div class="card-body">
                <h4>Services <strong class="description">"Génie civil"</strong></h4>
                <div class="form-check" *ngFor="let service of gcServices">
                  <div class="form-group col-lg-4" style="display:inline-block;">
                    <label class="form-check-label" [for]="service.id">
                      <input class="form-check-input"
                            type="checkbox"
                            [disabled]="isDisabled(service.slug, 'gc')"
                            [checked]="fromAgencyServices(service.id)"
                            (change)="onSelectService($event, 'gc', service.title)"
                            [value]="service.id"
                            [name]="service.id" [id]="service.id">
                            {{service.title}}
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-group col-lg-2" style="display:inline-block;">
                    <input *ngIf="fromAgencyServices(service.id)"
                      type="text"
                      class="form-control" placeholder="Données complémentaires"
                      [value]="fromAgencyServices(service.id).data"
                      (input)="updateServiceInfo(service.id, $event)"
                      [id]="service.id">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="hasThisSkill(6) && maServices && agencyServices">
            <div class="card">
              <div class="card-body">
                <h4>Services <strong class="description">"Manutention"</strong></h4>
                <div class="form-check" *ngFor="let service of maServices">
                  <div class="form-group col-lg-4" style="display:inline-block;">
                    <label class="form-check-label" [for]="service.id">
                      <input class="form-check-input"
                            type="checkbox"
                            [disabled]="isDisabled(service.slug, 'ma')"
                            [checked]="fromAgencyServices(service.id)"
                            (change)="onSelectService($event, 'ma')"
                            [value]="service.id"
                            [name]="service.id" [id]="service.id">
                            {{service.title}}
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-group col-lg-2" style="display:inline-block;">
                    <input *ngIf="fromAgencyServices(service.id)"
                      type="text"
                      class="form-control" placeholder="Données complémentaires"
                      [value]="fromAgencyServices(service.id).data"
                      (input)="updateServiceInfo(service.id, $event)"
                      [id]="service.id">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="hasThisSkill(7) && tpServices && agencyServices">
            <div class="card">
              <div class="card-body">
                <h4>Services <strong class="description">"Travaux publics"</strong></h4>
                <div class="form-check" *ngFor="let service of tpServices">
                  <div class="form-group col-lg-4" style="display:inline-block;">
                    <label class="form-check-label" [for]="service.id">
                      <input class="form-check-input"
                            type="checkbox"
                            [disabled]="isDisabled(service.slug, 'tp')"
                            [checked]="fromAgencyServices(service.id)"
                            (change)="onSelectService($event, 'tp')"
                            [value]="service.id"
                            [name]="service.id" [id]="service.id">
                            {{service.title}}
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-group col-lg-2" style="display:inline-block;">
                    <input *ngIf="fromAgencyServices(service.id)"
                      type="text"
                      class="form-control" placeholder="Données complémentaires"
                      [value]="fromAgencyServices(service.id).data"
                      (input)="updateServiceInfo(service.id, $event)"
                      [id]="service.id">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="plusServices && agencyServices">
            <div class="card">
              <div class="card-body">
                <h4>Services <strong class="description">"Nos services Plus"</strong></h4>
                <div class="form-check" *ngFor="let service of plusServices">
                  <div class="form-group col-lg-4" style="display:inline-block;">
                    <label class="form-check-label" [for]="service.id">
                      <input class="form-check-input"
                            type="checkbox"
                            [disabled]="isDisabled(service.slug, 'plus')"
                            [checked]="fromAgencyServices(service.id)"
                            (change)="onSelectService($event, 'plus')"
                            [value]="service.id"
                            [name]="service.id" [id]="service.id">
                            {{service.title}}
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-group col-lg-2" style="display:inline-block;">
                    <input *ngIf="fromAgencyServices(service.id)"
                      type="text"
                      class="form-control" placeholder="Données complémentaires"
                      [value]="fromAgencyServices(service.id).data"
                      (input)="updateServiceInfo(service.id, $event)"
                      [id]="service.id">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            class="ppp-button ppp-button-black btn btn-primary btn-round"
            (click)="onSubmit()">Mettre à jour
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
