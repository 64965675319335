<ng-container *ngIf="!loading; else templateLoading">

  <app-topbar moduleSlug="profilplus-agences">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences']">Profil Plus Agences</a></li>
          <ng-container  *ngIf="(editMode.active && editMode.data); else templateElse">
            <li class="breadcrumb-item">
              <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies'] : ['/profilplus-agences/closed-agencies']">
                Liste des agences {{ router.url.split('/').includes('open-agencies') ? 'ouvertes' : 'fermées' }}
              </a>
            </li>
            <li class="breadcrumb-item active"><a href="javascript:;">Agence {{editMode.data.name}}</a></li>
          </ng-container>
          <ng-template #templateElse>
            <li class="breadcrumb-item active"><a href="javascript:;">Créer une agence</a></li>
          </ng-template>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>


  <ng-container *ngIf="editFormGroup">
    <div class="ppp-agency-edition row" *ngIf="!editMode.active ||(editMode.active && editMode.data)">
      <div class="col-lg-12">
        <form class="form" [formGroup]="editFormGroup">
          <div class="card">

            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">domain</i>
              </div>
              <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
                <h4 class="card-title">Édition de l'agence : <strong class="description">{{editMode.data.name}}</strong></h4>
              </ng-container>
              <ng-template #templateElse>
                <h4 class="card-title">Création d'une nouvelle agence</h4>
              </ng-template>
            </div>

            <div class="card-body">
              <!-- BUTTONS -->
              <div *ngIf="editMode.active && editMode.data">
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/', editMode.data.id] : ['/profilplus-agences/closed-agencies/edit/', editMode.data.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">store</i> Fiche agence
                </a>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/skills/', editMode.data.id] : ['/profilplus-agences/closed-agencies/edit/skills/', editMode.data.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">build</i> Métiers
                </a>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/services/', editMode.data.id] : ['/profilplus-agences/closed-agencies/edit/services/', editMode.data.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">playlist_add_check</i> Services
                </a>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/close-dates/', editMode.data.id] : ['/profilplus-agences/closed-agencies/edit/close-dates/', editMode.data.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">report</i> Dates fermeture
                </a>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/opening-times/store/', editMode.data.id] : ['/profilplus-agences/closed-agencies/edit/opening-times/store/', editMode.data.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">store</i> Horaires magasin
                </a>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/correspondants/edit/', editMode.data.id] : ['/profilplus-agences/closed-agencies/correspondants/edit/', editMode.data.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">people</i> Correspondants
                </a>
                <a *ngIf="user.is_admin && router.url.split('/').includes('open-agencies')"
                    [routerLink]="['/profilplus-agences/open-agencies/edit/settings', editMode.data.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">settings</i> Paramètres avancés
                </a>
              </div>

              <!-- GENERAL DATA -->
              <h3>Informations de base</h3>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.name.errors }">
                  <label for="agency_name" class="bmd-label-floating">Nom de l'agence</label>
                  <input type="text" class="form-control" id="agency_name" formControlName="name">
                </p>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.social_reason.errors }">
                  <label for="agency_social_reason" class="bmd-label-floating">Raison sociale</label>
                  <input type="text" class="form-control" id="agency_social_reason" formControlName="social_reason">
                </p>
              </div>


              <!-- ADHERENT FIELD -->
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.adherent_id.errors }">
                  <app-datalist *ngIf="adherents"
                    [items]="adherents"
                    [preselect]="preselectAdherents"
                    [property]="'name'"
                    label="Adhérent"
                    (selected)="onSelectAdherent($event)"
                  >
                  </app-datalist>
                </p>
                <p class="form-group" *ngIf="editFormGroup.value.adherent_id !== null"
                  [ngClass]="{ 'has-danger': submitted && f.company_id.errors }">
                  <app-datalist *ngIf="companies"
                    [items]="filteredCompanies"
                    [preselect]="preselectCompanies"
                    [property]="'name'"
                    label="Société"
                    (selected)="onSelectCompany($event)"
                  >
                  </app-datalist>
                </p>
              </div>


              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" [ngClass]="{ 'has-danger': f.phone.value !== '' && f.phone.errors }">
                  <label for="agency_phone" class="bmd-label-floating">Numéro de téléphone</label>
                  <input type="text" minlength="10" maxlength="10"
                  NumbersOnly [allowDecimals]="false" [allowSign]="false"
                  class="form-control" id="agency_phone" formControlName="phone">
                  <label class="error" *ngIf="f.phone.errors">
                    Attention, veuillez renseigner un numéro valide.
                  </label>
                </p>
              </div>

              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" [ngClass]="{ 'has-danger': f.fax.value !== '' && f.fax.errors }">
                  <label for="agency_fax" class="bmd-label-floating">Fax</label>
                  <input type="text" minlength="10" maxlength="10"
                    NumbersOnly [allowDecimals]="false" [allowSign]="false"
                    class="form-control" id="agency_fax" formControlName="fax">
                  <label class="error"  *ngIf="f.fax.errors">
                    Attention, veuillez renseigner un numéro de Fax valide.
                  </label>
                </p>
              </div>

              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.adherent_code.errors }">
                  <label for="agency_adherent_code" class="bmd-label-floating">ID Adhérent</label>
                  <input type="text" class="form-control" id="agency_adherent_code" formControlName="adherent_code">
                </p>
              </div>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.id_agency.errors }">
                  <label for="agency_id_agency" class="bmd-label-floating">ID Agence</label>
                  <input type="text" class="form-control" id="agency_id_agency" formControlName="id_agency">
                </p>
              </div>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" [ngClass]="{ 'has-danger': f.siret.value !== '' &&  f.siret.errors }">
                  <label for="agency_siret" class="bmd-label-floating">SIRET</label>
                  <input type="text" minlength="14" maxlength="14"
                    class="form-control" id="agency_siret" formControlName="siret">
                  <label class="error"  *ngIf="f.siret.errors">
                    Attention, veuillez renseigner un SIRET valide (14 chiffres).
                  </label>
                </p>
              </div>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" [ngClass]="{ 'has-danger': (submitted && f.vat_number.errors) || (f.vat_number.value !== '' && f.vat_number.errors) }">
                  <label for="agency_vat_number" class="bmd-label-floating">Numéro TVA</label>
                  <input type="text" pattern="(FR)?[0-9]{11}" class="form-control" id="agency_vat_number" formControlName="vat_number">
                  <label *ngIf="f.vat_number.value !== '' && f.vat_number.errors" class="error">Veuillez renseigner un numéro de TVA valide (FR[11 chiffres]).</label>
                </p>
              </div>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.latitude.errors }">
                  <label for="agency_latitude" class="bmd-label-floating">Latitude</label>
                  <input type="text"
                  NumbersOnly [allowDecimals]="true"
                  [allowSign]="true"
                  class="form-control" id="agency_latitude" formControlName="latitude">
                </p>
              </div>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.longitude.errors }">
                  <label for="agency_longitude" class="bmd-label-floating">Longitude</label>
                  <input type="text"
                  NumbersOnly [allowDecimals]="true"
                  [allowSign]="true"
                  class="form-control" id="agency_longitude" formControlName="longitude">
                </p>
              </div>

              <br>

              <div class="form-group form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" [name]="" formControlName="b2b_only"> Agence exclusivement B2B ?
                  <span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
              </div>
              <div class="form-group form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" [name]="" formControlName="physical_agency"> Agence physique avec accueil client ?
                  <span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
              </div>
              <div class="form-group form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" [name]="" formControlName="warehouse"> Entrepôt
                  <span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
              </div>
              <div class="form-group form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" [name]="" formControlName="is_client_base"> Est une base Clients ?
                  <span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
              </div>

              <!-- RESPONSABLE -->
              <h3>Chef d'agence</h3>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.lastname_manager.errors }">
                  <label for="agency_lastname_manager" class="bmd-label-floating">Nom du chef d'agence</label>
                  <input type="text" class="form-control" id="agency_lastname_manager" formControlName="lastname_manager">
                </p>
              </div>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.firstname_manager.errors }">
                  <label for="agency_firstname_manager" class="bmd-label-floating">Prénom du chef d'agence</label>
                  <input type="text" class="form-control" id="agency_firstname_manager" formControlName="firstname_manager">
                </p>
              </div>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': f.mail_manager.value !== '' && f.mail_manager.errors }">
                  <label for="agency_manager_email" class="bmd-label-floating">Email du chef d'agence</label>
                  <input email type="email" class="form-control" id="agency_manager_email" formControlName="mail_manager">
                  <label class="error" for="email" *ngIf="f.mail_manager.errors && f.mail_manager.errors.email">Veuillez entrer un email valide.</label>
                </p>
              </div>

              <!-- RESPONSABLE INDUSTRIEL -->
              <h3>Chef d'agence industriel</h3>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.lastname_industrial_manager.errors }">
                  <label for="agency_lastname_industrial_manager" class="bmd-label-floating">Nom du chef d'agence industriel</label>
                  <input type="text" class="form-control" id="agency_lastname_industrial_manager" formControlName="lastname_industrial_manager">
                </p>
              </div>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.firstname_industrial_manager.errors }">
                  <label for="agency_firstname_industrial_manager" class="bmd-label-floating">Prénom du chef d'agence industriel</label>
                  <input type="text" class="form-control" id="agency_firstname_industrial_manager" formControlName="firstname_industrial_manager">
                </p>
              </div>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" [ngClass]="{ 'has-danger': f.mail_industrial_manager.value !== '' && f.mail_industrial_manager.errors }">
                  <label for="agency_mail_industrial_manager" class="bmd-label-floating">Email du chef d'agence industriel</label>
                  <input email type="email" class="form-control" id="agency_mail_industrial_manager" formControlName="mail_industrial_manager">
                  <label class="error" for="email" *ngIf="f.mail_industrial_manager.errors && f.mail_industrial_manager.errors.email">Veuillez entrer un email valide.</label>
                </p>
              </div>

              <!-- ADDRESS -->
              <h3>Adresse</h3>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.address_street_number.errors }">
                  <label for="agency_address_street_number" class="bmd-label-floating">Numéro de la rue</label>
                  <input type="text" class="form-control" id="agency_address_street_number" formControlName="address_street_number">
                </p>
              </div>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.address_street_name.errors }">
                  <label for="agency_address_street_name" class="bmd-label-floating">Nom de la rue</label>
                  <input type="text" class="form-control" id="agency_address_street_name" formControlName="address_street_name">
                </p>
              </div>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': (submitted && f.address_zipcode.errors) || (!submitted && f.address_zipcode.value !== '' && f.address_zipcode.errors) }">
                  <label for="agency_address_zipcode" class="bmd-label-floating">Code postal</label>
                  <input minlength="5" maxlength="5" NumbersOnly [allowDecimals]="false" [allowSign]="false" type="text" class="form-control" id="agency_address_zipcode" formControlName="address_zipcode">
                  <label class="error" *ngIf="f.address_zipcode.value !== '' && f.address_zipcode.errors">
                    Veueillez renseigner renseigner un code postal valide (5 chiffres).
                  </label>
                </p>
              </div>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.address_city.errors }">
                  <label for="agency_address_city" class="bmd-label-floating">Ville</label>
                  <input type="text" class="form-control" id="agency_address_city" formControlName="address_city">
                </p>
              </div>

              <ng-container *ngIf="user.is_admin">
                <!-- GENERAL SETTINGS -->
                <h3>Réglages pour le site Profil Plus</h3>
                <div class="col-lg-4 col-md-12" style="padding:0;">
                  <div class="form-group form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" [name]="" formControlName="display"> Mettre en ligne
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-group form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" [name]="" formControlName="deactivate_appointment"> Désactiver les demandes de RDV ?
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-group form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" [name]="" formControlName="deactivate_quote_requests"> Désactiver les demandes de devis ?
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-group form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" [name]="" formControlName="deactivate_orders"> Désactiver les prises de commandes ?
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-group form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" [name]="" formControlName="deactivate_contact"> Désactiver les demandes de contact ?
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-group form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" [name]="" formControlName="agency_payment"> Paiement en agence ?
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-group form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" [name]="" formControlName="online_payment"> Paiement en ligne par CB ?
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                  <div class="form-group form-check">
                    <label class="form-check-label">
                      <input class="form-check-input" type="checkbox" [name]="" formControlName="settlement_payment"> Paiement en x fois par CB ?
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                </div>
              </ng-container>

              <!-- PAYMENT METHOD -->

              <h3>Paiement</h3>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [name]="" formControlName="payment_visa"> Carte de crédit VISA
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [name]="" formControlName="payment_mastercard"> Carte de crédit MasterCard
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [name]="" formControlName="payment_american_express"> Carte de crédit American Express
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [name]="" formControlName="payment_mobile_nfc"> Paiements mobiles NFC
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [name]="" formControlName="payment_check"> Chèques
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
              </div>

              <!-- MISC SETTINGS -->
              <h3>Autres informations</h3>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.intervention_emails.errors }">
                  <label for="agency_intervention_emails" class="bmd-label-floating">Emails exclusifs POP3P</label>
                  <input type="text" class="form-control" id="agency_intervention_emails" formControlName="intervention_emails">
                </p>
                <small>Remarque : veuillez séparer les différents emails par une virgule ","</small>
              </div>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.erp.errors }">
                  <app-datalist *ngIf="erps"
                    [items]="erps"
                    [preselect]="preselectErp"
                    [property]="'name'"
                    label="ERP"
                    (selected)="onSelectErp($event)"
                    (typed)=checkValue($event)>
                  </app-datalist>
                </p>
                <br>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [name]="" formControlName="pack_phone_plus"> Pack Tél. répondeur et Musique Profil Plus ?
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                <br>
                <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.phone_lines_count.errors }">
                  <label for="phone_lines_count" class="bmd-label-floating">Nombre de lignes téléphoniques</label>
                  <input type="number" class="form-control" id="phone_lines_count" formControlName="phone_lines_count">
                </p>
              </div>

              <h3>PLV</h3>
              <div class="col-lg-4 col-md-12" style="padding:0;">

                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [name]="" formControlName="delivery_status"> Livraison PLV autorisée ?
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.wincho_tires.errors }">
                  <label for="agency_wincho_tires" class="bmd-label-floating">Quantité Wincho pneu (lot de 2 faces)</label>
                  <input type="number" min="0" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="agency_wincho_tires" formControlName="wincho_tires">
                </p>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.wincho_ema.errors }">
                  <label for="agency_wincho_ema" class="bmd-label-floating">Quantité Wincho EMA (lot de 2 faces)</label>
                  <input type="number" min="0" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="agency_wincho_ema" formControlName="wincho_ema">
                </p>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.advertisement_tires.errors }">
                  <label for="agency_advertisement_tires" class="bmd-label-floating">Quantité affiche pneu</label>
                  <input type="number" min="0" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="agency_advertisement_tires" formControlName="advertisement_tires">
                </p>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.advertisement_ema.errors }">
                  <label for="agency_advertisement_ema" class="bmd-label-floating">Quantité affiche EMA</label>
                  <input type="number" min="0" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="agency_advertisement_ema" formControlName="advertisement_ema">
                </p>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.advertisement_a4_ema.errors }">
                  <label for="agency_advertisement_a4_ema" class="bmd-label-floating">Quantité A4 EMA autres promo</label>
                  <input type="number" min="0" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="agency_advertisement_a4_ema" formControlName="advertisement_a4_ema">
                </p>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.operation_sheet.errors }">
                  <label for="agency_operation_sheet" class="bmd-label-floating">Quantité fiche OP pneu</label>
                  <input type="number" min="0" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="agency_operation_sheet" formControlName="operation_sheet">
                </p>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.operation_sheet_ema.errors }">
                  <label for="agency_operation_sheet_ema" class="bmd-label-floating">Quantité fiche OP EMA</label>
                  <input type="number" min="0" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="agency_operation_sheet_ema" formControlName="operation_sheet_ema">
                </p>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.permanent_4x3.errors }">
                  <label for="agency_permanent_4x3" class="bmd-label-floating">Quantité 4x3 permanent</label>
                  <input type="number" min="0" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="agency_permanent_4x3" formControlName="permanent_4x3">
                </p>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.quantity_masts.errors }">
                  <label for="agency_quantity_masts" class="bmd-label-floating">Quantité mâts pour drapeaux</label>
                  <input type="number" min="0" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="agency_quantity_masts" formControlName="quantity_masts">
                </p>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.screen_memo_quantity.errors }">
                  <label for="screen_memo_quantity" class="bmd-label-floating">Quantité Mémo écran</label>
                  <input type="number" class="form-control" id="screen_memo_quantity" formControlName="screen_memo_quantity">
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.op_tire_easel.errors }">
                  <label for="op_tire_easel" class="bmd-label-floating">Quantité Chevalets Op Pneu</label>
                  <input type="number"  min="0" NumbersOnly class="form-control" id="op_tire_easel" formControlName="op_tire_easel">
                </p>

                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.op_ema_easel.errors }">
                  <label for="op_ema_easel" class="bmd-label-floating">Quantité Chevalets Op EMA</label>
                  <input type="number"  min="0" NumbersOnly class="form-control" id="op_ema_easel" formControlName="op_ema_easel">
                </p>
              </div>

              <h3>Caractéristiques Agence</h3>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.quantity_bays.errors }">
                  <label for="agency_quantity_bays" class="bmd-label-floating">Quantité baies</label>
                  <input type="number" min="0" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="agency_quantity_bays" formControlName="quantity_bays">
                </p>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.quantity_bridges.errors }">
                  <label for="agency_quantity_bridges" class="bmd-label-floating">Quantité ponts</label>
                  <input type="number" min="0" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="agency_quantity_bridges" formControlName="quantity_bridges">
                </p>
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.quantity_vi_interventions.errors }">
                  <label for="agency_quantity_vi_interventions" class="bmd-label-floating">Quantité VI d'intervention</label>
                  <input type="number" min="0" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="agency_quantity_vi_interventions" formControlName="quantity_vi_interventions">
                </p>

                <!-- EPYX -->
                <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.ref_epyx.errors }">
                  <label for="agency_ref_epyx" class="bmd-label-floating">Ref EPYX</label>
                  <input type="number" min="0" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="agency_ref_epyx" formControlName="ref_epyx">
                </p>
                <div class="form-group" [ngClass]="{ 'has-danger': submitted && f.date_epyx.errors }">
                  <label for="date_epyx" class="col-form-label">Date EPYX</label>
                  <div class="form-group">
                    <input type="date" class="form-control datetimepicker" name="date_epyx" id="date_epyx" formControlName="date_epyx">
                  </div>
                </div>
              </div>

              <!-- ACCESSIBILITY -->
              <h3>Accessibilité aux personnes à mobilité réduite</h3>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [name]="" formControlName="accessibility_counter"> Comptoir adapté aux personnes à mobilité réduite
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [name]="" formControlName="accessibility_toilet"> Toilettes accessibles aux personnes à mobilité réduite
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [name]="" formControlName="accessibility_building"> Bâtiment accessible aux personnes à mobilité réduite
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [name]="" formControlName="accessibility_parking"> Stationnement réservé aux personnes à mobilité réduite
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [name]="" formControlName="accessibility_wheelchairs_slots"> Places assises accessibles en fauteuil roulant
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
              </div>

              <!-- HEALTH AND SECURITY -->
              <h3>Santé et sécurité</h3>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" [name]="" formControlName="has_protective_wall"> Parois de protection en caisse
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
              </div>

              <br>

              <a *ngIf="editMode.active && editMode.data"
                  [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies'] : ['/profilplus-agences/closed-agencies']"
                  type="button"
                  class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
                Retour à la liste
              </a>

              <button [disabled]="editFormGroup.invalid" *ngIf="!loadingService.loading; else templateSubmit"
                class="ppp-button ppp-button-black btn btn-primary btn-round"
                (click)="onSubmit()">
                <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
                <ng-template #creationTemplateText>Créer</ng-template>
              </button>
              <ng-template #templateSubmit>
                <button
                  disabled="true"
                  class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                  ...
                </button>
              </ng-template>

            </div>

          </div>
        </form>
      </div>
    </div>
  </ng-container>

</ng-container>

<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
