import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { BrandQuestionsService } from 'src/app/services/brand-questions.service';
import { BrandQuestion } from 'src/app/models/BrandQuestion';
import config from 'src/app/config';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public editFormGroup: UntypedFormGroup;
  public editMode: any = {
    active: false,
    data: null
  };
  public loading: Boolean = false;
  public submitted: Boolean = false;
  preselectBrand: number;
  public ckEditorConfig = config.ckEditor;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private brandQuestionsService: BrandQuestionsService,
              private formBuilder: UntypedFormBuilder,
              public loadingService:LoadingService,
              public enumsService: EnumsService,
              private alertService: AlertService) {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        if (this.route.snapshot.params.id) { // EDIT
          this.editMode.active = true;
          this.brandQuestionsService.getById(this.route.snapshot.params.id)
            .then((response: BrandQuestion) => {
              this.editMode.data = response;

              if (response.brand) {
                this.preselectBrand = this.enumsService.enums.brands.findIndex(b => b.id === response.brand.id)
              }

              this.loading = false;
              this.editFormGroup = this.formBuilder.group(this.getNormalForm(response));
            })
            .catch((err) => {
              this.alertService.error(err);
            });
        } else {
          this.loading = false;
          this.editFormGroup = this.formBuilder.group(this.getNormalForm());
        }
      }
    });
  }

  ngOnInit(): void {
  }

  get f() { return this.editFormGroup.controls; }

  private getNormalForm(data?: BrandQuestion) {
    return {
      title: [data ? data.title : '', Validators.required],
      content: [data ? data.content : '', Validators.required],
      brand_id: [data && data.brand ? data.brand.id : '', null],
      rank: [data  ? data.rank : '', null]
    };
  }

  onSelectedBrand(brand) {
    this.editFormGroup.patchValue({brand_id: brand.id});
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const question = {
      ...this.editFormGroup.value,
    };

    if (this.editMode) {
      question.id = this.route.snapshot.params.id;
    }
    this.brandQuestionsService.edit(question).then((response) => {
      this.router.navigate(['/website/tires/questions']);
      this.alertService.success((this.editMode && this.editMode) ? 'Modification enregistrée avec succès.' : 'Question enregistrée avec succès.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }
}
