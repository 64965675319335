<ng-container *ngIf="!loading; else templateLoading">
  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
          <li class="breadcrumb-item"><a href="javascript:;">Gestion des pneus</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website/tires/questions']">Foire aux questions</a></li>
          <ng-container  *ngIf="(editMode.active && editMode.data); else elseItem">
            <li class="breadcrumb-item active"><a href="javascript:;">Édition d'une question : {{editMode.data.title}}</a></li>
          </ng-container>
          <ng-template #elseItem>
            <li class="breadcrumb-item active"><a href="javascript:;">Créer une question</a></li>
          </ng-template>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">directions_car</i>
          </div>
          <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
            <h4 class="card-title">Édition d'une question : <strong class="description">{{editMode.data.title}}</strong></h4>
          </ng-container>
          <ng-template #templateElse>
            <h4 class="card-title">Création d'une nouvelle question</h4>
          </ng-template>
        </div>


        <div class="card-body">
          <form *ngIf="editFormGroup" class="form" [formGroup]="editFormGroup">

            <div class="col-lg-4 col-md-12">
              <p class="form-group"
                [ngClass]="{ 'has-danger': submitted && f.title.errors }">
                <label for="title" class="bmd-label-floating">Titre</label>
                <input type="text" class="form-control" id="title" formControlName="title">
              </p>
            </div>

            <div class="col-lg-12">
              <div class="form-group">
                <label for="content" class="bmd-label-floating">Contenu</label>
                <ckeditor formControlName="content" [config]="ckEditorConfig"></ckeditor>
              </div>
            </div>

            <div class="col-lg-4 col-md-12">
              <div class="form-group is-required"
                [ngClass]="{ 'has-danger': submitted && f.brand_id.errors }">
                <app-datalist
                  label="Marque"
                  (selected)="onSelectedBrand($event)"
                  [items]="enumsService.enums.brands"
                  [preselect]="preselectBrand"
                  [property]="'name'">
                </app-datalist>
              </div>
            </div>

            <div class="col-lg-4 col-md-12">
              <p class="form-group"
                [ngClass]="{ 'has-danger': submitted && f.rank.errors }">
                <label for="rank" class="bmd-label-floating">Ordre</label>
                <input type="text" class="form-control" id="rank" formControlName="rank">
              </p>
            </div>

            <a [routerLink]="['/website/tires/questions']" type="button"
              class="ppp-button ppp-button-gray btn btn-primary btn-round mt-4 mr-2">
              Retour
            </a>
            <button [disabled]="editFormGroup.invalid"
              *ngIf="!loadingService.loading; else templateSubmit"
              class="ppp-button ppp-button-black btn btn-primary btn-round mt-4"
              (click)="onSubmit()">
              <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
              <ng-template #creationTemplateText>Créer</ng-template>
            </button>
            <ng-template #templateSubmit>
              <button
                disabled="true"
                class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                ...
              </button>
            </ng-template>
          </form>
        </div>


      </div>
    </div>
  </div>
</ng-container>
<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
