import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpComponent } from '../help/help.component';
import { AuthGuard } from 'src/app/helpers/auth.guard';

import { ProfilplusDirectComponent } from './profilplus-direct.component';
import { IndexContentComponent } from 'src/app/components/index-content/index-content.component';

import { SitesComponent } from './sites/sites.component';

import { AddressesComponent } from './sites/addresses/addresses.component';

import { InterventionsComponent } from './interventions/interventions.component';
import { CommentsComponent } from './interventions/comments/comments.component';

import { ShowComponent } from './show/show.component';

const routes: Routes = [
    {
        path: 'profilplus-direct',
        component: ProfilplusDirectComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: IndexContentComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'sites',
            component: SitesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'sites/list',
            component: SitesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'sites/page/:page',
            component: SitesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'sites/:id/consultation',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'sites/addresses',
            component: AddressesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'sites/addresses/:id/consultation',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'sites/billing-addresses',
            component: AddressesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'sites/billing-addresses/:id/consultation',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'interventions',
            component: InterventionsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'interventions/list',
            component: InterventionsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'interventions/page/:page',
            component: InterventionsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'interventions/:id/consultation',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'interventions/comments',
            component: CommentsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'interventions/comments/page/:page',
            component: CommentsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'interventions/comments/:id/consultation',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'help',
            component: HelpComponent,
            canActivate: [AuthGuard],
          }
        ]
      },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfilplusDirectRoutingModule {}
