<app-topbar moduleSlug="job">
	<ng-container title>
		<nav aria-label="breadcrumb" role="navigation">
			<ol class="breadcrumb">
				<li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active">Tableau de bord</a>
				</li>
				<li class="breadcrumb-item"><a [routerLink]="['/job']">Site emploi</a></li>
				<li class="breadcrumb-item" [ngSwitch]="type">
					<a href="javascript:;" *ngSwitchCase="'spontanee'">Candidatures spontanées</a>
					<a href="javascript:;" *ngSwitchCase="'flash'">Candidatures flash</a>
					<span *ngSwitchDefault></span>
				</li>
			</ol>
		</nav>
	</ng-container>
</app-topbar>

<div class="row">
  <div class="col-md-12">
    <button class="ppp-button ppp-button-black btn btn-primary btn-round"
       (click)="exportCandidatures()">
      <i class="material-icons">pie_chart</i>
      Export Excel des candidatures
    </button>
    <button *ngIf="authService.currentUserValue.is_admin" class="ppp-button ppp-button-black btn btn-primary btn-round"
            (click)="exportOffers()">
      <i class="material-icons">pie_chart</i>
      Export Excel des offres
    </button>
    <button *ngIf="authService.currentUserValue.is_admin" class="ppp-button ppp-button-black btn btn-primary btn-round"
            (click)="exportOffersPdf()">
      <i class="material-icons">pie_chart</i>
      Export .pdf des offres
    </button>
  </div>
</div>

<div class="row">
	<div class="col-md-12">
		<div class="card">
			<div class="card-header card-header-rose card-header-icon">
				<div class="card-icon">
					<i class="material-icons">directions_car</i>
				</div>
				<h4 class="card-title">Liste des Candidatures
					<ng-container [ngSwitch]="type">
						<span *ngSwitchCase="'with-cv'">spontanées</span>
						<span *ngSwitchCase="'without-flash'">spontanées</span>
						<span *ngSwitchDefault></span>
					</ng-container>
				</h4>
			</div>
			<div class="card-body">
				<app-filter-bar [filters]="filters" (changed)="setPage({offset: 0}, null, $event)">
					<p *ngIf="loading">chargement...</p>
				</app-filter-bar>
				<div class="table-responsive">
					<ngx-datatable
						#datatable
						class="material"
						[rows]="items"
						[columnMode]="'standard'"
						[rowHeight]="'auto'"
						[headerHeight]="50"
						[footerHeight]="50"
						[externalPaging]="true"
						[externalSorting]="true"
						[loadingIndicator]="loadingList"
						[reorderable]="false"
						(page)="setPage($event)"
						(sort)="onSort($event)"
					>
						<ngx-datatable-column [resizeable]="false" name="ID" prop="id"  [width]="70">
						<ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
							{{ value }}
						</ng-template>
						</ngx-datatable-column>
									<ngx-datatable-column [resizeable]="false" name="email" prop="email" [width]="230">
						<ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
							{{ value }}
						</ng-template>
						</ngx-datatable-column>
						<ngx-datatable-column [resizeable]="false" name="Région" prop="region" [width]="170">
						<ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
							{{ value }}
						</ng-template>
						</ngx-datatable-column>
						<ngx-datatable-column [resizeable]="false" name="Ville" prop="city" [width]="200">
						<ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
							{{ value }}
						</ng-template>
						</ngx-datatable-column>
						<ngx-datatable-column [resizeable]="false" name="Métier(s)" prop="concerned_skills" [width]="260">
						<ng-template let-row="row" let-value="value" ngx-datatable-cell-template>{{ value.join("\n\n") }}</ng-template>
						</ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Date de création" prop="created_at" [width]="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value  | date: 'dd/MM/yy - HH:mm' }}
              </ng-template>
            </ngx-datatable-column>
						<!-- ACTIONS -->
						<ngx-datatable-column [resizeable]="false" name="Actions" [sortable]="false">
							<ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
								<a [routerLink]="['/job/', 'candidature', row.id]" class="btn btn-success" title="Consulter">
									<i class="material-icons">visibility</i>
								</a>
                <a *ngIf="authService.currentUserValue.is_admin" [routerLink]="['/job/', 'candidature', type, row.id, 'historique']" class="btn btn-success" title="Historique">
                  <i class="material-icons">list</i>
                </a>
								<button *ngIf="authService.currentUserValue.is_admin
								    && authService.currentUserValue.policies['candidature-' + type] && authService.currentUserValue.policies['candidature-' + type].delete"
												type="button" class="btn btn-danger"
												(click)="onDelete(row.id)" title="Supprimer">
									<i class="material-icons">close</i>
								</button>
							</ng-template>
						</ngx-datatable-column>
					</ngx-datatable>
				</div>
			</div>
		</div>
	</div>
</div>
