<ng-container *ngIf="this.item">
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">subject</i>
          </div>
          <h4 class="card-title">Détails</h4>
        </div>
        <div class="card-body">
          <ul>
            <li *ngFor="let field of fields">
              <ng-container *ngIf="field.template; else templateElse">
                <ng-container [ngTemplateOutlet]='template' [ngTemplateOutletContext]='{$implicit: "Hello", "item": item, "field": field}'></ng-container>
              </ng-container>
              <ng-template #templateElse>
                <div *ngIf="this.item[field.slug] != null || this.item[field.slug] != undefined"><strong>{{field.name}}</strong> : {{ this.item[field.slug] }}</div>
              </ng-template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-container>
