import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { PageBackground } from '../models/PageBackground';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';

@Injectable({ providedIn: 'root' })
export class PageBackgroundsService {
  constructor(private apiService: ApiService) {}

  getById(pageBackgroundId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('page_backgrounds/' + pageBackgroundId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAll(website = 0, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get(
        'page_backgrounds' + `?website=${website}`
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
        + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  async formatData(pageBackground: PageBackground) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append('page_background:apply_to_all', pageBackground.apply_to_all.toString() ? pageBackground.apply_to_all.toString() : "false");
      formData.append('page_background:url', pageBackground.url ? pageBackground.url : '');
      formData.append('page_background:universe', pageBackground.universe.toString() ? pageBackground.universe.toString() : '0');
      formData.append('page_background:start_date', pageBackground.start_date ? pageBackground.start_date.toString() : '');
      formData.append('page_background:end_date', pageBackground.end_date ? pageBackground.end_date.toString() : '');
      formData.append('page_background:rank', pageBackground.rank.toString() ? pageBackground.rank.toString() : '0');
      formData.append('page_background:website', pageBackground.website ? pageBackground.website.toString() : '0');

      if (pageBackground.page) {
        formData.append('page_background:page_id', pageBackground.page.toString());
      }

      if (pageBackground.background_image_left !== null) {
        formData.append('page_background:background_image_left', pageBackground.background_image_left ? pageBackground.background_image_left : '');
      }
      if (pageBackground.background_image_right !== null) {
        formData.append('page_background:background_image_right', pageBackground.background_image_right ? pageBackground.background_image_right : '');
      }

      resolve(formData);
    });
  }

  edit(pageBackground: PageBackground) {
    return new Promise((resolve, reject) => {
      this.formatData(pageBackground).then((formData) => {
        if (pageBackground.id) {
          this.apiService.put('page_backgrounds/' + pageBackground.id, formData)
          .then((response: any) => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
        } else {
          this.apiService.post('page_backgrounds', formData)
          .then((response: any) => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
        }
      });
    });
  }

  delete(pageBackgroundId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('page_backgrounds/' + pageBackgroundId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
