import { Component, OnInit } from '@angular/core';
import {AlertService} from '../../../../services/alert.service';
import {ApiService} from '../../../../services/api.service';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../../../../services/auth.service';
import {CandidaturesService} from '../../../../services/candidatures.service';

@Component({
  selector: 'app-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.scss']
})
export class HistoriqueComponent implements OnInit {
  loading!: boolean;
  type: string;
  historiques: any;

  constructor(
    private alertService: AlertService,
    private apiService: ApiService,
    public route: ActivatedRoute,
    private authService: AuthService,
    private candidaturesService: CandidaturesService) {
    this.route.data.subscribe((data) => {
      this.type = data.type;
    });
    this.loading = true;
    this.candidaturesService.getHistorique(this.route.snapshot.params.id)
      .then((response) => {
        this.loading = false;
        console.log(response);
        this.historiques = response;
      });
  }

  ngOnInit(): void {
  }
}
