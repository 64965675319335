<ng-container *ngIf="!loading; else templateElse">
  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
          <li class="breadcrumb-item"><a href="javascript:;">Gestion des pneus</a></li>
          <li class="breadcrumb-item active"><a href="javascript:;">Marques</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <div class="row" *ngIf="policiesService.currentUser.policies['brand'].create">
    <div class="col-md-12">
      <a class="ppp-button ppp-button-black btn btn-primary btn-round"
       [routerLink]="['/website/tires/brands/edit']">
        <i class="material-icons">library_add</i>
        Créer une nouvelle marque
      </a>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">directions_car</i>
          </div>
          <h4 class="card-title">Liste des marques</h4>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <ngx-datatable
              class="material"
              [rows]="items"
              [columnMode]="'standard'"
              [rowHeight]="'auto'"
              [headerHeight]="50"
              [footerHeight]="50"
              [loadingIndicator]="loadingList"
            >
              <ngx-datatable-column name="Dénomination" prop="name" [width]="175">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Titre" prop="title" [width]="175">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Logo" prop="logo" [width]="175">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <img [src]="value" width="100" alt="" />
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Ordre" prop="rank" [width]="85">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Actions" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a *ngIf="policiesService.currentUser.policies['brand'].update" [routerLink]="['/website/tires/brands/edit', row.id]"
                  type="button" class="btn btn-success"
                  title="Modifier la marque">
                    <i class="material-icons">edit</i>
                  </a>
                  <button *ngIf="policiesService.currentUser.policies['brand'].delete"
                    type="button" class="btn btn-danger"
                    (click)="onDelete(row.id)" title="Supprimer">
                    <i class="material-icons">close</i>
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #templateElse>
  <app-loading></app-loading>
</ng-template>
