import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { EnumsService } from 'src/app/services/enums.service';
import { AlertService } from 'src/app/services/alert.service';
import { AgenciesService } from 'src/app/services/agencies.service';
import { SkillsService } from 'src/app/services/skills.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { AuthService } from 'src/app/services/auth.service';

import { Agency } from 'src/app/models/Agency';

@Component({
  selector: 'app-edit-skills',
  templateUrl: './edit-skills.component.html',
  styleUrls: ['./edit-skills.component.scss']
})
export class EditSkillsComponent implements OnInit {
  public skills: Array<any> = [];
  public agency: Agency;
  public loading: boolean = false;
  public agencySkills: Array<any>;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private enumsService: EnumsService,
    private alertService: AlertService,
    private agenciesService: AgenciesService,
    private skillsService: SkillsService,
    public loadingService: LoadingService,
    public policiesService: PoliciesService,
    private authService: AuthService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
    }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        if (this.router.url.split('/').includes('open-agencies')) {
          this.agency = this.enumsService.enums.agencies.find(a => a.id === parseInt(this.route.snapshot.params.id));
        } else {
          this.agency = this.enumsService.enums.agencies_closed.find(a => a.id === parseInt(this.route.snapshot.params.id));
        }
        this.skills = this.enumsService.enums.skills;

        this.skillsService.getAll()
        .then((response: Array<any>) => {
          this.skills = response;
        })
        .catch((err) => {
          this.alertService.error(err);
        });

        this.agenciesService.getSkills(this.route.snapshot.params.id)
          .then((response: Array<any>) => {
            this.agencySkills = response.map(x => x.id);
            this.loading = false;
          })
          .catch((err) => {
            this.alertService.error(err);
            this.loading = false;
          });
      }
    });
  }

  isSelectedSkills(id) {
    return this.agencySkills.findIndex(a => a === id) !== -1;
  }

  onSelectSkills(e) {
    if (e.target.checked) {
      this.agencySkills.push(parseInt(e.target.value));
    } else {
      const index = this.agencySkills.findIndex(a => a === parseInt(e.target.value));
      if (index !== -1) {
        this.agencySkills.splice(index, 1);
      }
    }
  }

  onSubmit() {
    this.agenciesService.updateSkills(this.route.snapshot.params.id, this.agencySkills)
      .then((response) => {
        this.alertService.success("Métiers mis à jour.");
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

}
