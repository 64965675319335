<app-topbar moduleSlug="myprofilplus">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/myprofilplus']">MyProfil+</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/myprofilplus/ce-cards']">Liste des cartes CE / VI</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/myprofilplus/ce-cards/edit']">Création d'une série de cartes CE / VI</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">picture_in_picture</i>
        </div>
        <h4 class="card-title">Création d'une série de cartes CE / VI</h4>
      </div>
      <div class="card-body">
        <form *ngIf="editFormGroup && (authService.currentUserValue.is_admin || authService.currentUserValue.adherents && authService.currentUserValue.adherents.length > 0); else elseForm" [formGroup]="editFormGroup">

          <div class="col-md-4 col-12 ">
            <label style="color:#000;" for="card_type">Type de cartes</label>
            <div class="form-group is-required"
              [ngClass]="{ 'has-danger': submitted && f.card_type.errors }">
              <select name="card_type" id="card_type"
                class="form-control custom-list"
                formControlName="card_type"
                style="height:30px;" required="required">
                <option selected [value]="null" disabled>Sélectionner un type de carte</option>
                <option value="CE">CE</option>
                <option value="VI">VI</option>
              </select>
            </div>
          </div>

          <br>

          <div class="col-md-4 col-12">
            <div class="form-group" [ngClass]="{ 'has-danger': submitted && f.adherent_id.errors, 'is-required': f.card_type.value === 'VI'}"
            *ngIf="(authService.currentUserValue.adherents
            && authService.currentUserValue.adherents.length > 1) || authService.currentUserValue.is_admin; else templateElseAdherent">
              <app-datalist
                style="padding: 0;"
                *ngIf="adherentsList"
                [items]="adherentsList"
                [property]="'name'"
                label="Adhérent"
                (selected)="onSelectAdherent($event)"
              >
              </app-datalist>
            </div>
            <ng-template #templateElseAdherent>
              <p *ngIf="authService.currentUserValue.adherents">
                <br>
                Adhérent : {{authService.currentUserValue.adherents[0].name}}
              </p>
            </ng-template>

          </div>

          <div class="col-lg-4 col-md-12">
            <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.organization_name.errors }">
              <label for="name" class="bmd-label-floating">Nom de la société</label>
              <input type="text" class="form-control" id="name" formControlName="organization_name">
            </p>
          </div>

          <div class="col-lg-4 col-md-12">
            <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.number_of_cards.errors }">
              <label for="number" class="bmd-label-floating">Nombre de cartes</label>
              <input type="number" min="0" max="99999" step="0.01" NumbersOnly [allowDecimals]="true" [allowSign]="false" class="form-control" id="number" formControlName="number_of_cards">
            </p>
          </div>
          <br><br>
          <div class="col-lg-4 col-md-12">
            <p>Courrier publipostage : <a href="./assets/files/CourrierCE2020.docx">CourrierCE.docx</a></p>
            <p>Carte publipostage : <a href="./assets/files/CartesCE.doc">CartesCE.doc</a></p>
          </div>

          <div class="col-lg-4 col-md-12">
            <button [disabled]="editFormGroup.invalid"
              class="ppp-button ppp-button-black btn btn-primary btn-round"
              (click)="onSubmit()">
              Créer
            </button>
          </div>
        </form>
        <ng-template #elseForm>
          <div class="col-lg-6 col-md-12">
            <strong class="description">Vous devez disposer d'un compte Administrateur ou d'un compte Adhérent afin d'accéder à cette fonctionnalité.</strong>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
