import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {
  @Output() changed = new EventEmitter();
  @Input() filters: Array<
    {
      name: string,
      slug: string,
      type: string,
      value: any | null,
      options: any | null,
      class: string | null,
      property: string | null,
      selected_emit_property: string | null
   }> = [];

   debounce: any;

   @Input() callback_for_filters: Array<
    {
      filter: string,
      callback: void
    }
   >


  constructor() { }

  ngOnInit(): void {
  }

  clearTimeout() {
    clearTimeout(this.debounce);
  }

  onChanged(slug, $event, debounce = false) {
    let res = {};
    let current_filter = null

    this.filters.forEach(x => {
      if (x.slug === slug) {
        x.value = $event;
        current_filter = x
      }

      if (x.value) {
        res[x.slug] = x.value;
      }
    });

    if (debounce) {
      this.clearTimeout();
      this.debounce = setTimeout(() => {
        this.onChanged(slug, $event);
      }, 1000);
    } else {
      this.changed.emit(res);
      // launch callback for selected filters
      if(current_filter && current_filter.callback != undefined) {
        current_filter.callback($event, current_filter)
      }
    }
  }

}
