import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivityFormsService } from 'src/app/services/activity-form.service';
import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import acitivityDashboardColumns from '../../../../assets/activity_dashboard_columns.json';

@Component({
  selector: 'app-activity-dashboard',
  templateUrl: './activity-dashboard.component.html',
  styleUrls: ['./activity-dashboard.component.scss']
})
export class ActivityDashboardComponent implements OnInit {
  loading = false
  editFormGroup: UntypedFormGroup
  @ViewChild('datatable') datatable: any
  columns = acitivityDashboardColumns
  tables = [
    {
      name: "tire",
      title: "Pneus",
      items: [],
      columns: acitivityDashboardColumns
    },
    {
      name: "prestation_tire",
      title: "Prestations pneus",
      items: [],
      columns: [
        {
          "slug": "name",
          "label": "Nom",
          "width": "140"
        },
        {
          "slug": "b2c_volume",
          "label": "Volume B2C",
          "width": "110"
        },
        {
          "slug": "b2b_volume",
          "label": "Volume B2B",
          "width": "110"
        },
        {
          "slug": "volume_sum",
          "label": "Somme volumes",
          "width": "142"
        },
        {
          "slug": "total_volume",
          "label": "Évolution Volume",
          "width": "160"
        },
        {
          "slug": "total_cumul_volume",
          "label": "Évolution Volume Cumulé",
          "width": "225"
        }
      ]
    },
    {
      name: "geometry",
      title: "Géométrie",
      items: [],
      columns: acitivityDashboardColumns
    },
    {
      name: "vidange",
      title: "Vidange",
      items: [],
      columns: acitivityDashboardColumns
    },
    {
      name: "filtration",
      title: "Filtration",
      items: [],
      columns: [
        {
          slug: "name",
          label: "Nom",
          width: "140"
        },
        {
          slug: "volume_filtre_huile",
          label: "Volume filtre à huile",
          width: "190"
        },
        {
          slug: "volume_filtre_air",
          label: "Volume filtre à air",
          width: "180"
        },
        {
          slug: "volume_filtre_carburant",
          label: "Volume filtre à carburant",
          width: "210"
        },
        {
          slug: "volume_filtre_habitacle",
          label: "Volume filtre d'habitacle",
          width: "210"
        },
        {
          slug: "total_volume_filtre_huile",
          label: "Évolution Volume filtre à huile",
          width: "240"
        },
        {
          slug: "total_cumul_volume_filtre_huile",
          label: "Évolution Volume filtre à huile Cumulé",
          width: "290"
        },
        {
          slug: "total_volume_filtre_air",
          label: "Évolution Volume filtre à air",
          width: "230"
        },
        {
          slug: "total_cumul_volume_filtre_air",
          label: "Évolution Volume filtre à air Cumulé",
          width: "310"
        },
        {
          slug: "total_volume_filtre_carburant",
          label: "Évolution Volume filtre à carburant",
          width: "280"
        },
        {
          slug: "total_cumul_volume_filtre_carburant",
          label: "Évolution Volume filtre à air carburant Cumulé",
          width: "370"
        },
        {
          slug: "total_volume_filtre_habitacle",
          label: "Évolution Volume filtre à habitacle",
          width: "280"
        },
        {
          slug: "total_cumul_volume_filtre_habitacle",
          label: "Évolution Volume filtre à habitacle Cumulé",
          width: "350"
        }
      ]
    },
    {
      name: "freinage",
      title: "Freinage",
      items: [],
      columns: acitivityDashboardColumns
    },
    {
      name: "p_freinage",
      title: "Pièce freinage",
      items: [],
      columns: [
        {
          slug: "name",
          label: "Nom",
          width: "140"
        },
        {
          slug: "volume_disque",
          label: "Volume disques",
          width: "140"
        },
        {
          slug: "volume_plaquettes",
          label: "Volume plaquettes",
          width: "150"
        },
        {
          slug: "total_volume_disque",
          label: "Évolution Volume disques",
          width: "200"
        },
        {
          slug: "total_cumul_volume_disque",
          label: "Évolution Volume disques Cumulé",
          width: "260"
        },
        {
          slug: "total_volume_plaquettes",
          label: "Évolution Volume plaquettes",
          width: "235"
        },
        {
          slug: "total_cumul_volume_plaquettes",
          label: "Évolution Volume plaquettes Cumulé",
          width: "285"
        }
      ]
    }
  ]

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activityFormsService: ActivityFormsService,
    public authService: AuthService,
    public enumsService: EnumsService,
    private cd: ChangeDetectorRef,
    public loadingService: LoadingService
    ) {
      this.enumsService.observable.subscribe({
        complete: () => {
          this.editFormGroup = this.formBuilder.group({
            year: [null, Validators.required],
            month: [null, Validators.required]
          })

          this.editFormGroup.valueChanges.subscribe((fields) => {
            if(fields.year && fields.month) {
              this.query()
            }
          })
        }
      })
    }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.cd.detectChanges()
  }

  query() {
    this.loading = true
    this.activityFormsService.dashboard(this.editFormGroup.controls.year.value, this.editFormGroup.controls.month.value)
      .then((response: {adherents: any, totals: {}}) => {
        // totals lines:
        Object.keys(response.totals).forEach(k => {
          const table = this.tables.find(t => t.name == k)
          if (table) {
            const line = response.totals[k]
            line.name = "TOTAL"
            table.items = [line]
          }
        })

        Object.keys(response.adherents).forEach(k => {
          Object.keys(response.adherents[k]).forEach(k2 => {
            const table = this.tables.find(t => t.name == k2)
            if (table) {
              const line = response.adherents[k][k2]
              const adherent = this.enumsService.enums.adherents.find(ad => ad.id == k)
              line.name = adherent ? adherent.name : k
              table.items.push(line)
            }
          })
        })

        this.loading = false
      })
      .catch((err) => {
        this.loading = false
      })
  }
}
