<app-topbar moduleSlug="job">
	<ng-container title>
		<nav aria-label="breadcrumb" role="navigation">
			<ol class="breadcrumb">
				<li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active">Tableau de bord</a>
				</li>
				<li class="breadcrumb-item"><a [routerLink]="['/job']">Site emploi</a></li>
				<li class="breadcrumb-item active" *ngIf="currentItem">
          <ng-container [ngSwitch]="currentItem.category">
            <a *ngSwitchCase="'spontanee'" [routerLink]="['/job', 'candidatures-spontanees']">Candidatures spontanées</a>
            <a *ngSwitchCase="'flash'" [routerLink]="['/job', 'candidatures-flash']">Candidatures flash</a>
            <a *ngSwitchDefault>Candidature</a>
          </ng-container>
        </li>
				<li class="breadcrumb-item active"><a href="javascript:;">Détails</a></li>
			</ol>
		</nav>
	</ng-container>
</app-topbar>

<app-show-content [fields]="fields" [item]="currentItem">
	<ng-template #template let-default let-item="item" let-field="field">

      <!-- Type de candidature -->
      <ng-container *ngIf="field.slug === 'candidature_type' && item.category">
        <strong *ngIf="item.category == 'spontanee'">Candidature spontanée (CV obligatoire)</strong>
        <strong *ngIf="item.category == 'flash'">Candidature flash (CV optionnel)</strong>
        <hr>
      </ng-container>

    <!-- Métier(s) -->
    <ng-container *ngIf="field.slug === 'concerned_skills' && item.concerned_skills">
      <strong>Métier(s) : </strong>
      <ul>
        <ng-container *ngFor="let skill of item.concerned_skills">
          <li>
            {{ skill }}<span *ngIf="skill == 'Autre'"> : <br>{{item.other_concerned_skills}}</span>
          </li>
        </ng-container>
      </ul>
    </ng-container>

    <!-- Motivations -->
    <ng-container *ngIf="field.slug === 'motivations' && item.motivations">
      <strong>Motivations : </strong>
      <div>{{ item.motivations }}</div>
    </ng-container>

    <!-- CV -->
    <ng-container *ngIf="field.slug === 'path_to_file' && item.path_to_file">
      <strong>Curriculum Vitae (CV) : </strong>
      <a href="{{item.path_to_file}}">Lien de téléchargement du fichier</a>
    </ng-container>

	</ng-template>
</app-show-content>
