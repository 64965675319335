<app-topbar moduleSlug="website">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website/settings/app-settings']">Configurations</a></li>
        <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
          <li class="breadcrumb-item active"><a href="javascript:;">Éditer une configuration</a></li>
        </ng-container>
        <ng-template #templateElse>
          <li class="breadcrumb-item active"><a href="javascript:;">Créer une nouvelle configuration</a></li>
        </ng-template>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<ng-container *ngIf="editFormGroup">
  <div class="row" *ngIf="!editMode.active || (editMode.active && editMode.data)">
    <div class="col-lg-12">
      <form class="form" [formGroup]="editFormGroup">

        <div class="card">
          <!-- HEADER -->
          <div class="card-header card-header-rose card-header-icon">
            <div class="card-icon">
              <i class="material-icons">settings</i>
            </div>
            <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
              <h4 class="card-title">Édition d'une configuration : <strong
                  class="description">{{editMode.data.key}}</strong></h4>
            </ng-container>
            <ng-template #templateElse>
              <h4 class="card-title">Création d'une nouvelle configuration</h4>
            </ng-template>
          </div>

          <!-- MAIN CONTENT -->
          <div class="card-body">
            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.key.errors }">
                <label for="key" class="bmd-label-floating">Clé unique</label>
                <input type="text" class="form-control" id="key" formControlName="key">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.value.errors }">
                <label for="value" class="bmd-label-floating">Valeur</label>
                <input type="text" class="form-control" id="value" formControlName="value">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.content.errors }">
                <label for="agency_content" class="bmd-label-floating">Contenu</label>
                <textarea class="form-control" formControlName="content"></textarea>
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-group">
                <label class="bmd-label-floating">Fichier</label>
                <app-drag-drop-file-input [limit]="1" [forceTypes]="null" appDragDrop [downloadByLink]="true"
                  [list]="editMode.active ? editMode.data.file : null" (filesSelected)="onFileSelected($event)"
                  (fileDeleted)="onFileSelected(null)"></app-drag-drop-file-input>
              </div>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-group" [ngClass]="{ 'has-danger': submitted && f.process_date_time.errors }">
                <label class="col-form-label">À traiter le</label>
                <div class="form-group">
                  <input type="date" class="form-control datetimepicker" name="process_date_time"
                    formControlName="process_date_time">
                </div>
              </div>
            </div>

            <hr style="border-color:transparent;">

            <!-- NAVIGATION LINKS -->
            <a [routerLink]="['/website/settings/app-settings']" type="button"
              class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
              Retour
            </a>
            <button [disabled]="editFormGroup.invalid" class="ppp-button ppp-button-black btn btn-primary btn-round"
              (click)="onSubmit()">
              <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à
                jour</ng-container>
              <ng-template #creationTemplateText>Créer</ng-template>
            </button>
          </div>

        </div>

      </form>
    </div>
  </div>
</ng-container>