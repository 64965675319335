<app-topbar moduleSlug="profilplus-site">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
        <li class="breadcrumb-item"><a href="javascript:;">Gestion des pneus</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">{{type_label}}</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>


<div class="row" *ngIf="!loading && authService.currentUserValue.policies[type + '-tire'].create">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round"
       [routerLink]="['/website/tires/', type ,'edit']">
      <i class="material-icons">library_add</i>
      Créer un nouveau pneu ({{type_label}})
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">directions_car</i>
        </div>
        <h4 class="card-title">Liste des pneus <strong class="description">({{type_label}})</strong></h4>
      </div>
      <div class="card-body">

        <!-- DISABLED FOR NOW -->
        <app-filter-bar [filters]="filters"
          (changed)="setPage({offset: 0}, null, $event)">
          <p style="margin-top: 10px;" *ngIf="loadingList">chargement...</p>
        </app-filter-bar>

        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [externalPaging]="true"
            [externalSorting]="true"
            [loadingIndicator]="loadingList"
            [reorderable]="false"
            (page)="setPage($event)"
            (sort)="onSort($event)"
          >

            <!-- PNEUS D'OCCASION AG -->
            <ng-container *ngIf="type === 'ag_used'; else elseColumns">
              <ngx-datatable-column [resizeable]="false" name="ID" prop="id" [width]="70">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Description" prop="description" [width]="250">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Photo 1" prop="tire_photo_1" [width]="150">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <img [src]="value" width="75" [alt]="row.name"/>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Prix unit. HT" prop="price_with_tax" [width]="150">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Agence" prop="agency" [width]="200">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{value.name}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Marque" prop="brand" [width]="140">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{value.name}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Profil" prop="tire_profile" [width]="140">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{value}}
                </ng-template>
              </ngx-datatable-column>
            </ng-container>

            <!-- PNEUS VL / PL / AG -->
            <ng-template #elseColumns>
              <ngx-datatable-column [resizeable]="false" name="ID" prop="id" [width]="70">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Ean" prop="ean" [width]="125">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Catégorie" prop="category" [width]="110">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value.name }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Saison" prop="season" [width]="90">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div [ngSwitch]="value">
                    <div *ngSwitchCase="0">Été</div>
                    <div *ngSwitchCase="1">Hiver</div>
                    <div *ngSwitchCase="2">4 saisons</div>
                  </div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Largeur" prop="width" [width]="100">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Hauteur" prop="height" [width]="100">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Diamètre" prop="diameter" [width]="100">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Indice charge" prop="load_index" [width]="140">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Indice vitesse" prop="speed_index" [width]="150">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value.name }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column [resizeable]="false" name="Runflat ?" prop="runflat" [width]="110">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <ng-container *ngIf="value; else runflatElse">
                    <i class="material-icons green">done</i>
                  </ng-container>
                  <ng-template #runflatElse>
                    <i class="material-icons red">clear</i>
                  </ng-template>
                </ng-template>
              </ngx-datatable-column>
            </ng-template>

            <!-- ACTIONS -->
            <ngx-datatable-column [resizeable]="false" name="Actions" [sortable]="false" *ngIf="type">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>

                <a [routerLink]="['/website/tires/', type , row.id]"
                  type="button" class="btn btn-success"
                  title="Voir le pneu">
                  <i class="material-icons">visibility</i>
                </a>

                <a *ngIf="authService.currentUserValue.policies[type + '-tire'].update"
                  [routerLink]="['/website/tires/', type ,'edit', row.id]"
                  type="button" class="btn btn-success"
                  title="Modifier le pneu">
                  <i class="material-icons">edit</i>
                </a>

                <button *ngIf="authService.currentUserValue.policies[type + '-tire'].delete"
                        type="button" class="btn btn-danger"
                        (click)="onDelete(row.id)" title="Supprimer">
                  <i class="material-icons">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
