import { Injectable } from '@angular/core';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';
import { ApiService } from './api.service';
import { Admin } from '../models/Admin';

@Injectable({ providedIn: 'root' })
export class AdminsService {
  constructor(private apiService: ApiService) {
  }

  getAllWithPagination(page: string | number, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('admins?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
        + (filters ? '&' + parseFiltersForUrl(filters) : '')
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id: number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('admins/' + id.toString())
      .then((response: any) => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
    });
  }


  edit(admin: Admin) {
    const formData = new FormData();
    formData.append('admin:first_name', admin.first_name ? admin.first_name : '');
    formData.append('admin:last_name', admin.last_name ? admin.last_name : '');
    formData.append('admin:email', admin.email ? admin.email : '');
    formData.append('admin:profil_plus_direct_admin', admin.profil_plus_direct_admin ? admin.profil_plus_direct_admin.toString() : 'false')

    return new Promise((resolve, reject) => {
      if (admin.id) { // EDIT
        this.apiService.put('admins/' + admin.id, formData)
        .then((response: any) => {
            resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }
}
