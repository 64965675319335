import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { Adherent } from '../models/Adherent';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';

@Injectable({ providedIn: 'root' })
export class AdherentsService {
  constructor(private apiService: ApiService) {

  }

  /**
   * Get a specific adherent
   * @param adherentId Adherent ID
   */
  getById(adherentId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('adherents/' + adherentId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get all adherents
   */
  getAll(type, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('adherents/filtered/'
        + type +'/'
        + (filters ? '?' + parseFiltersForUrl(filters) : '')
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' ))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Create or update a specific adherent
   * @param adherent The corresponding adherent
   */
  edit(adherent: Adherent) {
    return new Promise((resolve, reject) => {
      console.log(adherent.regions)
      const formData = new FormData();
      formData.append('adherent:name', adherent.name ? adherent.name : '');
      formData.append('adherent:mail', adherent.mail ? adherent.mail : '');
      formData.append('adherent:phone_number', adherent.phone_number ? adherent.phone_number : '');
      formData.append('adherent:phone_number2', adherent.phone_number2 ? adherent.phone_number2 : '');
      formData.append('adherent:siret', adherent.siret ? adherent.siret : '');
      formData.append('adherent:rib', adherent.rib ? adherent.rib : '');
      formData.append('adherent:codefac', adherent.codefac ? adherent.codefac : '');
      formData.append('adherent:human_ressource_last_name', adherent.human_ressource_last_name ? adherent.human_ressource_last_name : '');
      formData.append('adherent:human_ressource_first_name', adherent.human_ressource_first_name ? adherent.human_ressource_first_name : '');
      formData.append('adherent:human_ressource_email', adherent.human_ressource_email ? adherent.human_ressource_email : '');
      formData.append('adherent:regions[]', adherent.regions ? adherent.regions : '');
      formData.append('adherent:vat_number', adherent.vat_number ? adherent.vat_number : '');
      formData.append('adherent:is_closed', adherent.is_closed.toString());

      formData.append('adherent:description', adherent.description ? adherent.description : '');

      if (adherent.job_image_1 !== null) {
        formData.append('adherent:job_image_1', adherent.job_image_1);
      }
      if (adherent.job_image_2 !== null) {
        formData.append('adherent:job_image_2', adherent.job_image_2);
      }
      if (adherent.job_image_3 !== null) {
        formData.append('adherent:job_image_3', adherent.job_image_3);
      }

      if (adherent.id) { // UPDATE
        this.apiService.put('adherents/' + adherent.id, formData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      } else { // CREATE
        this.apiService.post('adherents', formData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }

  updateLoyaltyRules(adherentId, loyaltyRules: Array<number>) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('loyalty_rule_ids', loyaltyRules.join(','))
      this.apiService.put('adherents/' + adherentId, formData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Delete a specific adherent
   * @param adherentId Adherent ID
   */
  delete(adherentId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('adherents/' + adherentId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
