<ng-container *ngIf="!loading; else templateLoading">
  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
          <li class="breadcrumb-item"><a href="javascript:;">Gestion des pneus</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website/tires/highlight-rules']">Règles de mise en avant</a></li>
          <ng-container *ngIf="(editMode.active && editMode.data); else elseItem">
            <li class="breadcrumb-item active"><a href="javascript:;">Édition d'une règle de mise en avant</a></li>
          </ng-container>
          <ng-template #elseItem>
            <li class="breadcrumb-item active"><a href="javascript:;">Créer une nouvelle règle de mise en avant</a></li>
          </ng-template>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">directions_car</i>
          </div>
          <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
            <h4 class="card-title">Édition d'une règle de mise en avant</h4>
          </ng-container>
          <ng-template #templateElse>
            <h4 class="card-title">Création d'une nouvelle règle de mise en avant</h4>
          </ng-template>
        </div>

        <div class="card-body" *ngIf="editFormGroup">
          <form class="form" [formGroup]="editFormGroup">

            <!-- CATEGORIE DE MISE EN AVANT -->
            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group is-required"
                [ngClass]="{ 'has-danger': submitted && f.brand_id.errors }">
                <app-datalist
                  label="Catégorie de mise en avant"
                  (selected)="onSelectedHighLightCategory($event)"
                  [items]="enumsService.enums.highlightCategories"
                  [preselect]="preselectHighLightCategory"
                  [property]="'name'">
                </app-datalist>
              </p>
            </div>

            <!-- MARQUE -->
            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group is-required"
                [ngClass]="{ 'has-danger': submitted && f.brand_id.errors }">
                <app-datalist
                  label="Marque"
                  (selected)="onSelectedBrand($event)"
                  [items]="enumsService.enums.brands"
                  [preselect]="preselectBrand"
                  [property]="'name'">
                </app-datalist>
              </p>
            </div>

            <!-- ORDRE -->
            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-group is-required"
                [ngClass]="{ 'has-danger': submitted && f.rank.errors }">
                <label for="rank" class="bmd-label-floating">Ordre </label>
                <input type="number" class="form-control" id="rank" formControlName="rank">
              </div>
            </div>

            <!-- ORDRE DE PRIX -->
            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-group form-radio-group is-required" [ngClass]="{ 'has-danger': submitted && f.price_order.errors }">
                <label class="col-form-label">Ordre de prix (ASCendant / DESCendant)</label>
                <div class="form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" name="price_order" formControlName="price_order" [value]="0"> DESC
                    <span class="circle">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label">
                    <input class="form-check-input" type="radio" name="price_order" formControlName="price_order" [value]="1"> ASC
                    <span class="circle">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <!-- RESULTAT MAX -->
            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-group"
                [ngClass]="{ 'has-danger': submitted && f.max_result.errors }">
                <label for="max_result" class="bmd-label-floating">Résultat max</label>
                <input type="number" class="form-control" id="max_result" formControlName="max_result">
              </div>
            </div>

            <!-- MARQUE EXCLUE -->
            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-group is-required"
                [ngClass]="{ 'has-danger': submitted && f.exclude_brand_id.errors }">
                <app-datalist
                  label="Exclure la marque"
                  (selected)="onSelectedBrand($event, true)"
                  [items]="enumsService.enums.brands"
                  [preselect]="preselectExcludeBrand"
                  [property]="'name'">
                </app-datalist>
              </div>
            </div>

            <button [disabled]="editFormGroup.invalid" *ngIf="!loadingService.loading; else templateSubmit"
              class="ppp-button ppp-button-black btn btn-primary btn-round mt-4"
              (click)="onSubmit()">
              <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
              <ng-template #creationTemplateText>Créer</ng-template>
            </button>
            <ng-template #templateSubmit>
              <button
                disabled="true"
                class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                ...
              </button>
            </ng-template>
          </form>
        </div>

      </div>
    </div>
  </div>
</ng-container>
<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
