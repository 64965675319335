<app-topbar moduleSlug="profilplus-agences">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences']">Profil Plus Agences</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences/services']">Liste des services</a></li>
        <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
          <li class="breadcrumb-item active"><a href="javascript:;">Éditer un service</a></li>
        </ng-container>
        <ng-template #templateElse>
          <li class="breadcrumb-item active"><a href="javascript:;">Créer un nouveau service</a></li>
        </ng-template>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<ng-container *ngIf="editFormGroup">
  <div class="row" *ngIf="!editMode.active || (editMode.active && editMode.data)">
    <div class="col-lg-12">
      <form class="form" [formGroup]="editFormGroup">
        <div class="card">
          <!-- HEADER -->
          <div class="card-header card-header-rose card-header-icon">
            <div class="card-icon">
              <i class="material-icons">playlist_add_check</i>
            </div>
            <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
              <h4 class="card-title">Édition du service : <strong class="description">{{editMode.data.name}}</strong></h4>
            </ng-container>
            <ng-template #templateElse>
              <h4 class="card-title">Création d'un nouveau service</h4>
            </ng-template>
          </div>
          <!-- MAIN CONTENT -->
          <div class="card-body">

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.service_name.errors }">
                <label for="service_name" class="bmd-label-floating">Dénomination</label>
                <input type="text" class="form-control" id="service_name" formControlName="service_name">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.service_title.errors }">
                <label for="service_title" class="bmd-label-floating">Titre</label>
                <input type="text" class="form-control" id="service_title" formControlName="service_title">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.service_rank.errors }">
                <label for="service_rank" class="bmd-label-floating">Rang</label>
                <input type="number" min="0" max="999" NumbersOnly [allowDecimals]="false" [allowSign]="false" class="form-control" id="service_rank" formControlName="service_rank">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.service_options.errors }">
                <label for="service_options" class="bmd-label-floating">Options</label>
                <input type="text" class="form-control" id="service_options" formControlName="service_options">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-group is-required" [ngClass]="{'has-danger': submitted && editFormGroup.value.service_skill === null}">
                <app-datalist *ngIf="skills"
                  [items]="skills"
                  [preselect]="preselectSkills"
                  [property]="'name'"
                  label="Métier"
                  (selected)="onSelectSkill($event)"
                  (typed)=onSelectSkill(null)
                >
                </app-datalist>
              </div>
            </div>

<!--
            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-group is-required" [ngClass]="{'has-danger': submitted && editFormGroup.value.service_skill === null}">
                <app-datalist *ngIf="specialOffers"
                  [items]="specialOffers"
                  [preselect]="preselectSpecialOffer"
                  [property]="'name'"
                  label="Offre spéciale"
                  (selected)="onSelectSpecialOffer($event)"
                  (typed)=onSelectSpecialOffer(null)
                >
                </app-datalist>
              </div>
            </div> -->

            <br>
            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-group form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" [name]="" formControlName="service_b2c_quotation"> Visible sur le site B2C ?
                  <span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
              </div>
              <div class="form-group form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" [name]="" formControlName="service_b2b_quotation"> Visible sur le B2B ?
                  <span class="form-check-sign">
                    <span class="check"></span>
                  </span>
                </label>
              </div>
            </div>
            <br>

            <!-- NAVIGATION LINKS -->
            <a [routerLink]="['/profilplus-agences/services']" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
              Retour
            </a>
            <button [disabled]="editFormGroup.invalid"  *ngIf="!loadingService.loading; else templateSubmit"
              class="ppp-button ppp-button-black btn btn-primary btn-round"
              (click)="onSubmit()">
              <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
              <ng-template #creationTemplateText>Créer</ng-template>
            </button>
            <ng-template #templateSubmit>
              <button
                disabled="true"
                class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                ...
              </button>
            </ng-template>

          </div>

        </div>

      </form>
    </div>
  </div>
</ng-container>
