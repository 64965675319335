<ng-container *ngIf="modules[currentModule] && currentService.slug && currentItem">
  <app-topbar moduleSlug="emails-sms-history">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/'+currentModule]">{{modules[currentModule].name}}</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/'+currentModule, currentService.slug]">Liste des {{currentService.name}}</a></li>
          <li *ngIf="currentService.sub" class="breadcrumb-item">
            <a [routerLink]="['/'+currentModule,  currentService.slug, currentService.sub.slug]">{{currentService.sub.name}}</a>
          </li>
          <li class="breadcrumb-item active"><a href="javascript:;">Détails</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <app-show-content [fields]="fields" [item]="currentItem">
    <ng-template #template let-default let-item="item" let-field="field">
      <ng-container *ngIf="field.slug === 'created_at' && item.created_at">
        <strong>Date : </strong>
        {{item.created_at | date:'dd/MM/y HH:mm'}}
      </ng-container>
    </ng-template>
  </app-show-content>

  <div>
    <a [routerLink]="['/'+currentModule, currentService.slug]" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
      Retour
    </a>
  </div>

</ng-container>
