import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AlertService } from 'src/app/services/alert.service';
import { AdherentsService } from 'src/app/services/adherents.service';

import { Adherent } from 'src/app/models/Adherent';
import { LoadingService } from 'src/app/services/loading.service';
import { AuthService } from 'src/app/services/auth.service';
import { DatalistComponent } from 'src/app/components/datalist/datalist.component';
import { ViewChild } from '@angular/core';
import { EnumsService } from 'src/app/services/enums.service';
import config from '../../../../config';

@Component({
  selector: 'app-adherents-edit',
  templateUrl: './adherents-edit.component.html',
  styleUrls: ['./adherents-edit.component.scss']
})
export class AdherentsEditComponent implements OnInit {
  public editFormGroup: UntypedFormGroup;
  public addFormGroup: UntypedFormGroup;
  public submitted: boolean = false;
  public editMode: any = {
    active: false,
    data: null
  };
  module: string = null

  job_images = {
    job_image_1: null,
    job_image_2: null,
    job_image_3: null
  }

  public ckEditorConfig = config.ckEditor;

  @ViewChild('loyaltyRulesDatalist') datalist: DatalistComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private adherentsService: AdherentsService,
    public authService: AuthService,
    public enumsService: EnumsService,
    public loadingService: LoadingService) {

      this.enumsService.observable.subscribe({
        complete: () => {
          this.module = this.route.snapshot.url[0].path
          if (this.route.snapshot.params.adherent_id) { // UPDATE
            this.editMode.active = true;
            this.adherentsService.getById(this.route.snapshot.params.adherent_id)
              .then((response: Adherent) => {
                this.editMode.data = response;
                this.editFormGroup = this.formBuilder.group(this.getNormalForm(response));
                this.editMode.data.job_image_1 = (response.job_image_1) ? [{name: 'affichage de votre fichier', link: response.job_image_1}] : null;
                this.editMode.data.job_image_2 = (response.job_image_2) ? [{name: 'affichage de votre fichier', link: response.job_image_2}] : null;
                this.editMode.data.job_image_3 = (response.job_image_3) ? [{name: 'affichage de votre fichier', link: response.job_image_3}] : null;
                this.addFormGroup = this.formBuilder.group({
                  loyalty_rule: ['', Validators.required]
                });
              })
              .catch((err) => {
                this.alertService.error(err);
              });
          } else { // CREATE
            this.editFormGroup = this.formBuilder.group(this.getNormalForm());
          }
        }
      })
    }

  get f() { return this.editFormGroup.controls; }

  ngOnInit(): void {}

  private getNormalForm(data: Adherent = null) {
    return {
      name: [data ? data.name :'', Validators.required],
      mail: [data ? data.mail :'', Validators.email],
      phone_number: [data ? data.phone_number :'', null],
      phone_number2: [data ? data.phone_number2 : '', null],
      siret: [data ? data.siret :'', null],
      rib: [data ? data.rib :'', null],
      codefac: [data ? data.codefac :'', null],
      vat_number: [data ? data.vat_number :'', null],
      is_closed: [data ? data.is_closed : false, null],
      human_ressource_last_name: [data ? data.human_ressource_last_name : '', null],
      human_ressource_first_name: [data ? data.human_ressource_first_name : '', null],
      human_ressource_email: [data ? data.human_ressource_email : '', null],
      regions: [data ? data.regions : '', null],
      description: [data ? data.description: '', null]
    }
  }

  isAlreadySelected(id) {
    return this.editMode.data.loyalty_rules ? this.editMode.data.loyalty_rules.find(i => i.id === id) : null
  }

  onJobImageSelected(slug, files: Array<File>) {
    this.job_images[slug] = files ? files[0] : '';
    if (this.editMode.active) {
      this.editMode.data[slug] = null
    }
  }


  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const adherent = {
      ...this.editFormGroup.value,
      job_image_1: this.job_images.job_image_1,
      job_image_2: this.job_images.job_image_2,
      job_image_3: this.job_images.job_image_3
    };
    if (this.editMode) {
      adherent.id = this.route.snapshot.params.adherent_id;
    }
    this.adherentsService.edit(adherent)
      .then(() => {
        this.router.navigate(['/adherents-companies-management', this.module]);
        this.alertService.success((this.editMode && this.editMode.active) ? 'Modification enregistrée avec succès.' : 'Adhérent enregistré avec succès.');
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

  onAddLoyaltyRule() {
    if (this.addFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    const loyaltyRules: number[] = this.editMode.data.loyalty_rules.map(x => x.id)

    loyaltyRules.push(parseInt(this.addFormGroup.value.loyalty_rule))

    this.adherentsService.updateLoyaltyRules(this.editMode.data.id, loyaltyRules)
      .then(() => {
        this.alertService.success('Promotion ajoutée avec succès.');

        // update loyalty_rules list
        const index = this.authService.currentUserValue.loyalty_rules
          .findIndex(i => i.id === parseInt(this.addFormGroup.value.loyalty_rule))

        console.log(this.authService.currentUserValue.loyalty_rules[index])
        if (index !== -1) {
          this.editMode.data.loyalty_rules.push(this.authService.currentUserValue.loyalty_rules[index])
        }

        this.addFormGroup.patchValue({loyalty_rule: ''})
      })
      .catch((err) => {
        this.alertService.error(err)
      })

  }

  onDelete(id) {
    const loyaltyRules: number[] = this.editMode.data.loyalty_rules.map(x => x.id)
    const indexLoyaltyRule = loyaltyRules.findIndex(x => x === id)

    if (indexLoyaltyRule !== -1) {
      loyaltyRules.splice(indexLoyaltyRule, 1)
    }

    this.adherentsService.updateLoyaltyRules(this.editMode.data.id, loyaltyRules)
      .then(() => {
        this.alertService.success('Promotion supprimée avec succès.');
        // update loyalty_rules list
        this.editMode.data.loyalty_rules.splice(indexLoyaltyRule)
      })
      .catch((err) => {
        this.alertService.error(err)
      })
  }

}
