import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AlertService } from 'src/app/services/alert.service';
import { AppSettingsService } from 'src/app/services/app-settings.service';

import { AppSetting } from 'src/app/models/AppSetting';

@Component({
  selector: 'app-app-settings-edit',
  templateUrl: './app-settings-edit.component.html',
  styleUrls: ['./app-settings-edit.component.scss']
})
export class AppSettingsEditComponent implements OnInit {
  public editFormGroup: UntypedFormGroup;
  public submitted: boolean = false;
  public editMode: any = {
    active: false,
    data: null
  };

  public file: string | File = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private appSettingsService: AppSettingsService) {
    if (this.route.snapshot.params.app_setting_id) {
      this.editMode.active = true;
      this.appSettingsService.getById(this.route.snapshot.params.app_setting_id)
        .then((response: AppSetting) => {
          this.editMode.data = response;

          this.editMode.data.file = (response.asset_image) ? [{ name: response.id + '-' + response.key, link: response.asset_image }] : null;

          this.editFormGroup = this.formBuilder.group({
            key: [response.key ? response.key : '', Validators.required],
            value: [response.value ? response.value : '', null],
            content: [response.content ? response.content : '', null],
            process_date_time: [response.process_date_time ? response.process_date_time.toString().split('T')[0] : null, null]
          });
        })
        .catch((err) => {
          this.alertService.error(err);
        });
    } else {
      this.editFormGroup = this.formBuilder.group({
        key: ['', Validators.required],
        value: ['', null],
        content: ['', null],
        process_date_time: [null, null]
      });
    }
  }

  get f() { return this.editFormGroup.controls; }

  ngOnInit(): void { }

  onFileSelected(files: Array<File>) {
    this.file = files ? files[0] : '';
    this.editMode.data.file = null
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const appSetting = {
      ...this.editFormGroup.value,
      asset_image: this.file
    };
    if (this.editMode) {
      appSetting.id = this.route.snapshot.params.app_setting_id;
    }
    this.appSettingsService.edit(appSetting).then((response) => {
      this.router.navigate(['/website/settings/app-settings']);
      this.alertService.success((this.editMode && this.editMode) ? 'Modification enregistrée avec succès.' : 'Configuration enregistrée avec succès.');
    })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

}
