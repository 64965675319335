import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MustNotMatch } from 'src/app/helpers/mustmach.validator';
import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LoyaltyCardsService } from 'src/app/services/loyalty-cards.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-fusion',
  templateUrl: './fusion.component.html',
  styleUrls: ['./fusion.component.scss']
})
export class FusionComponent implements OnInit {
  public editFormGroup: UntypedFormGroup;
  public loading: Boolean = false;
  public submitted: Boolean = false;

  public filters: Array<any> = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private enumsService: EnumsService,
    private alertService: AlertService,
    public loadingService: LoadingService,
    private loyaltyCardsService: LoyaltyCardsService) {
      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          this.editFormGroup = this.formBuilder.group({
            card_id: ['', Validators.required],
            card_id2: ['', Validators.required]
          },
          {
            validator: MustNotMatch('card_id', 'card_id2')
          });

        }
      });
    }

  ngOnInit(): void {
  }

  get f() {
    return this.editFormGroup.controls;
  }

  onConfirm() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Attention',
      text: "Une fois l'opération effectuée, la carte et ses données seront supprimées.",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.onSubmit();
      } else {
        // cancel
        return;
      }
    });
  }

  /* FORM */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    this.loyaltyCardsService.merge(this.editFormGroup.controls.card_id.value, this.editFormGroup.controls.card_id2.value).then(() => {
      this.alertService.success(`La carte ${this.editFormGroup.controls.card_id.value} a été fusionnée dans la carte ${this.editFormGroup.controls.card_id2.value}`);
      this.editFormGroup.reset();
      this.submitted = false;
    })
    .catch((err) => {
      this.alertService.error(err);
    });

  }

}
