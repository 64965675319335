<app-topbar moduleSlug="myprofilplus">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/myprofilplus']">MyProfil+</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/myprofilplus/loyalty-card-orders']">Liste des inventaires Cartes / Agences</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Créer un nouvel inventaire</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<ng-container *ngIf="editFormGroup">
  <div class="row">
    <div class="col-lg-12">
      <form class="form" [formGroup]="editFormGroup">
        <div class="card">
          <!-- HEADER -->
          <div class="card-header card-header-rose card-header-icon">
            <div class="card-icon">
              <i class="material-icons">search</i>
            </div>
            <h4 class="card-title">Création d'un nouvel inventaire</h4>
          </div>
          <!-- MAIN CONTENT -->
          <div class="card-body">

            <p>
              Pour <strong>mettre à jour le stock</strong> mettez simplement le nombre (négatif ou positif) pour atteindre votre stock réel.<br>
              Par exemple si le stock virtuel est de 20 mais que votre stock physique est de 12 alors mettez <strong>-8</strong> dans le champ puis validez.<br>
              Si le stock virtuel est de 20 mais que votre stock physique est de 32 alors mettez <strong>12</strong> dans le champ puis validez.
            </p>
            <p>Pour <strong>passer une commande de carte</strong>, il vous suffit de mettre la quantité 200 et de valider.</p>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.amount.errors }">
                <label for="amount" class="bmd-label-floating">Quantité</label>
                <input type="number" min="-9999" max="9999" step="1" pattern="^-?[0-9]{1,7}" class="form-control" id="amount" formControlName="amount">
                <label class="error" *ngIf="f.amount.value !== '' && f.amount.errors">
                  Veuillez renseigner un nombre entier compris entre -9999 et 9999 inclus.
                </label>
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-group is-required" [ngClass]="{'has-danger': submitted && editFormGroup.value.agency === null}">
                <app-datalist *ngIf="agencies"
                  [items]="agencies"
                  [preselect]="preselectAgencies"
                  [property]="'name'"
                  label="Agence"
                  (selected)="onSelectAgency($event)"
                  (typed)=checkValue($event)
                >
                </app-datalist>
              </div>
            </div>

            <!-- NAVIGATION LINKS -->
            <a [routerLink]="['/myprofilplus/loyalty-card-orders']" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
              Retour
            </a>
            <button [disabled]="editFormGroup.invalid || editFormGroup.value.amount === 0"
              class="ppp-button ppp-button-black btn btn-primary btn-round"
              (click)="onSubmit()">
              Créer
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
