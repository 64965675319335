<ng-container *ngIf="!loading; else templateLoading">

  <app-topbar moduleSlug="profilplus-agences">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences']">Profil Plus Agences</a></li>
          <li class="breadcrumb-item">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies'] : ['/profilplus-agences/closed-agencies']">
              Liste des agences {{ router.url.split('/').includes('open-agencies') ? 'ouvertes' : 'fermées' }}
            </a>
          </li>
          <li class="breadcrumb-item" *ngIf="agency">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit', agency.id] : ['/profilplus-agences/closed-agencies/edit', agency.id]">
              Agence {{agency.name}}
            </a>
          </li>
          <li class="breadcrumb-item">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/correspondants/edit', agency.id] : ['/profilplus-agences/closed-agencies/correspondants/edit', agency.id]">
              Liste des correspondants
            </a>
          </li>
          <li class="breadcrumb-item active"><a href="javascript:;">Gestion des emails rattachés à un correspondant</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <ng-container *ngIf="editFormGroup && !loading">
    <div class="ppp-correspondant-edition row">
      <div class="col-lg-12">
        <form class="form" [formGroup]="editFormGroup">
          <div class="card">

            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">mail</i>
              </div>
              <h4 class="card-title">Gestion des emails rattachés à un correspondant</h4>
            </div>

            <div class="card-body">
              <!-- BUTTONS -->
              <div>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/', agency.id] : ['/profilplus-agences/closed-agencies/edit/', agency.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">store</i> Fiche agence
                </a>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/skills/', agency.id] : ['/profilplus-agences/closed-agencies/edit/skills/', agency.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">build</i> Métiers
                </a>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/services/', agency.id] : ['/profilplus-agences/closed-agencies/edit/services/', agency.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">playlist_add_check</i> Services
                </a>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/close-dates/', agency.id] : ['/profilplus-agences/closed-agencies/edit/close-dates/', agency.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">report</i> Dates fermeture
                </a>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/opening-times/store/', agency.id] : ['/profilplus-agences/closed-agencies/edit/opening-times/store/', agency.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">store</i> Horaires magasin
                </a>
                <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/correspondants/edit/', agency.id] : ['/profilplus-agences/closed-agencies/correspondants/edit/', agency.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">people</i> Correspondants
                </a>
              </div>

              <hr><h5><strong>Rappel des informations du correspondant</strong></h5><hr>
              <div class="col-lg-4 col-md-12" style="padding:0;">
                <p class="form-group" *ngIf="currentCorrespondantData && currentCorrespondantData.role">
                  <strong>Rôle :</strong> {{ currentCorrespondantData.role }}
                </p>
                <p class="form-group" *ngIf="currentCorrespondantData && currentCorrespondantData.firstname">
                  <strong>Prénom :</strong> {{ currentCorrespondantData.firstname }}
                </p>
                <p class="form-group" *ngIf="currentCorrespondantData && currentCorrespondantData.lastname">
                  <strong>Nom :</strong> {{ currentCorrespondantData.lastname }}
                </p>
                <p class="form-group" *ngIf="currentCorrespondantData && currentCorrespondantData.email">
                  <strong>Email :</strong> {{ currentCorrespondantData.email }}
                </p>
                <p class="form-group" *ngIf="currentCorrespondantData && currentCorrespondantData.phone_number">
                  <strong>Numéro de téléphone :</strong> {{ currentCorrespondantData.phone_number }}
                </p>
                <p class="form-group" *ngIf="currentCorrespondantData && currentCorrespondantData.phone_number2">
                  <strong>Numéro de téléphone 2 :</strong> {{ currentCorrespondantData.phone_number2 }}
                </p>
              </div>

              <hr><h5><strong>Emails rattachés au correspondant</strong></h5><hr>
              <div>

                <!-- LISTE DES EMAILS DÉJÀ RATTACHÉS À CE CORRESPONDANT -->
                <ul style="list-style-type: none;padding-left:15px;">
                  <li *ngFor="let email of editFormGroup.value.mails">
                    <button type="button" class="btn btn-danger" (click)="onDelete(email.id)" title="Supprimer" style="padding:5px;margin-right:10px;">
                      <i class="material-icons">close</i>
                    </button>
                    <span>{{email.name}}</span>
                  </li>
                </ul>

                <!-- AJOUT DE NOUVEAUX EMAILS À RATTACHER AU CORRESPONDANT -->
                <div class="form-group col-lg-6">
                  <div class="row">
                    <div class="col-6">
                      <app-datalist #datalist *ngIf="mailerSlugs && mailerSlugs.length > 0; else elseBlock1"
                        label="Rechercher un email"
                        [items]="filteredSlug"
                        (selected)="onNewEmail($event)"
                        [property]="'name'">
                      </app-datalist>
                    </div>
                    <div class="col-6 text-left">
                        <button (click)="onSubmit()"
                          title="Rattacher cet email au correspondant"
                          [disabled]="editFormGroup.invalid"
                          class="ppp-button ppp-button-black btn btn-primary btn-round"
                          style="padding:15px;">
                          <i class="material-icons">add</i>
                        </button>
                    </div>
                  </div>
                  <ng-template #elseBlock1>
                    <app-loading></app-loading>
                  </ng-template>
                </div>
              </div>

              <br>

              <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/correspondants/edit/', agency.id] : ['/profilplus-agences/closed-agencies/correspondants/edit/', agency.id]"
                  type="button"
                  class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
                Retour à la liste des correspondants
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-container>

</ng-container>

<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
