import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';
import Slideshow from '../models/Slideshow';

@Injectable({ providedIn: 'root' })
export class CandidaturesService {
  constructor(private apiService: ApiService) {

  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('candidatures')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllWithPagination(category, page: string | number, sortBy: { field: string, order: string } = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('candidatures/' + '?page=' + page
        + `&category=${category}`
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '')
        + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id): Promise<Slideshow> {
    return new Promise((resolve, reject) => {
      this.apiService.get('candidatures/' + id)
        .then((response: Slideshow) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('candidatures/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getHistorique(id) {
    return new Promise((resolve, reject) => {
      this.apiService.get('candidatures/' + id + '/historique')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addHistorique(id, userId) {
    const formData = new FormData();
    formData.append('candidatures_historique:user_id', userId);
    formData.append('candidatures_historique:candidature_id', id);
    return new Promise((resolve, reject) => {
      this.apiService.post('candidatures/' +  id + '/historique', formData)
        .then((response) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
