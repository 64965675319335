import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({providedIn: 'root'})
export class ProCompaniesService {
  constructor(private apiService: ApiService) { }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('pro_companies').then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  getById(id) {
    return new Promise((resolve, reject) => {
      this.apiService.get('pro_companies/' + id).then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

}
