import { Component, OnInit, Renderer2, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { User } from 'src/app/models/User';
import modules from '../../../../assets/modules.json';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  public modules;
  @Input() moduleSlug: string;
  @Input() displayHelp: boolean = true;

  sideBarMinified: Boolean;
  public user: User;
  constructor(private renderer: Renderer2, private authService: AuthService, private router: Router) {
    this.modules = modules;
  }

  ngOnInit(): void {
    this.user = this.authService.currentUserValue;
  }

  ngAfterContentInit() {
    if (document.body.classList.contains('sidebar-mini')) {
      this.sideBarMinified = true;
    }
  }

  onSidebarMinify() {
    if (this.sideBarMinified) {
      this.renderer.removeClass(document.body, 'sidebar-mini');
      this.sideBarMinified = false;
    } else {
      this.renderer.addClass(document.body, 'sidebar-mini');
      this.sideBarMinified = true;
    }
  }

  onLogout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}
