<ng-container *ngIf="!loading; else templateElse">
  <app-topbar moduleSlug="website">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
          <li class="breadcrumb-item active"><a href="javascript:;">Gestionnaire de fichiers</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">file_upload</i>
          </div>
          <h4 class="card-title">Gestionnaire de fichiers</h4>
        </div>
        <div class="card-body">

          <ng-container *ngIf="editFormGroup">
            <div class="row">
              <div class="col-lg-12">
                <form class="form" [formGroup]="editFormGroup">
                  <div class="col-lg-3 col-md-3 form-group is-required" style="padding:0;">
                    <app-drag-drop-file-input
                      [forceTypes]="['pdf', 'png', 'jpg', 'jpeg', 'webp', 'mp4', 'gif']"
                      [limit]="1"
                      appDragDrop
                      [list]="null"
                      (filesSelected)="onFileSelected($event)"></app-drag-drop-file-input>
                      <button [disabled]="editFormGroup.invalid" class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="onSubmit()">
                        Envoyer
                      </button>
                  </div>

                </form>
              </div>
            </div>
          </ng-container>

          <hr>

          <div class="table-responsive">
            <ngx-datatable
              class="material"
              [rows]="items"
              [columnMode]="'standard'"
              [rowHeight]="'auto'"
              [headerHeight]="50"
              [footerHeight]="50"
              [loadingIndicator]="loading"
            >
              <ngx-datatable-column name="Aperçu" prop="url" [width]="200">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <img [src]="value" alt="Aperçu du fichier" [width]="150" *ngIf="row.is_image">
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Nom du fichier" prop="filename" [width]="330">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="URL" prop="url" [width]="250">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <button type="button" class="btn btn-success" (click)="copyToKeyboard(value)">
                    <i class="material-icons">mode_edit</i> Copier l'URL
                  </button>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Actions" [width]="300" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <button type="button" class="btn btn-danger" (click)="onDelete(row.filename)" title="Supprimer">
                    <i class="material-icons">close</i>
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #templateElse>
  <app-loading></app-loading>
</ng-template>
