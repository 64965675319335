import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { Service } from '../models/Service';

@Injectable({ providedIn: 'root' })
export class ServicesService {
  constructor(private apiService: ApiService) {

  }

  /**
   * Get a specific service
   * @param serviceId Service ID
   */
  getById(serviceId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('services/' + serviceId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get all services
   */
  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('services')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Create or update a specific service
   * @param service The corresponding service
   */
  edit(service) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('service:name', service.service_name ? service.service_name : '');
      formData.append('service:title', service.service_title ? service.service_title : '');
      formData.append('service:b2c_quotation', service.service_b2c_quotation ? service.service_b2c_quotation : false);
      formData.append('service:b2b_quotation', service.service_b2b_quotation ? service.service_b2b_quotation : false);
      formData.append('service:rank', service.service_rank ? service.service_rank : 0);
      formData.append('service:skill_id', service.service_skill);
      formData.append('service:options', service.service_options);
      if (service.id) {
        this.apiService.put('services/' + service.id, formData)
        .then((response: Service) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      } else {
        this.apiService.post('services', formData)
        .then((response: Service) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }

  /**
   * Delete a specific service
   * @param serviceId Service ID
   */
  delete(serviceId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('services/' + serviceId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
