
<ng-container *ngIf="!loading; else templateLoading">

  <app-topbar moduleSlug="profilplus-agences">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences']">Profil Plus Agences</a></li>
          <li class="breadcrumb-item">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies'] : ['/profilplus-agences/closed-agencies']">
              Liste des agences {{ router.url.split('/').includes('open-agencies') ? 'ouvertes' : 'fermées' }}
            </a>
          </li>
          <li class="breadcrumb-item" *ngIf="agency">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit', agency.id] : ['/profilplus-agences/closed-agencies/edit', agency.id]">
              Agence {{agency.name}}
            </a>
          </li>
          <li class="breadcrumb-item active"><a href="javascript:;">Liste des correspondants</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <div class="row" *ngIf="agency && router.url.split('/').includes('open-agencies')">
    <div class="col-md-12">
      <a class="ppp-button ppp-button-black btn btn-primary btn-round"
        [routerLink]="['/profilplus-agences/open-agencies/correspondants/edit', agency.id, 'correspondant']">
        <i class="material-icons">library_add</i>
        Créer un correspondant
      </a>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">people</i>
          </div>
          <h4 class="card-title" *ngIf="agency">Liste des correspondants : <strong class="description">{{agency.name}}</strong></h4>
        </div>
        <div class="card-body">
          <!-- BUTTONS -->
          <div>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/', agency.id] : ['/profilplus-agences/closed-agencies/edit/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">store</i> Fiche agence
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/skills/', agency.id] : ['/profilplus-agences/closed-agencies/edit/skills/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">build</i> Métiers
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/services/', agency.id] : ['/profilplus-agences/closed-agencies/edit/services/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">playlist_add_check</i> Services
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/close-dates/', agency.id] : ['/profilplus-agences/closed-agencies/edit/close-dates/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">report</i> Dates fermeture
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/opening-times/store/', agency.id] : ['/profilplus-agences/closed-agencies/edit/opening-times/store/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">store</i> Horaires magasin
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/correspondants/edit/', agency.id] : ['/profilplus-agences/closed-agencies/correspondants/edit/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">people</i> Correspondants
            </a>
            <a *ngIf="policiesService.currentUser.is_admin && router.url.split('/').includes('open-agencies')"
                [routerLink]="['/profilplus-agences/open-agencies/edit/settings', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">settings</i> Paramètres avancés
            </a>
          </div>

          <div class="table-responsive">
            <ngx-datatable
              class="material"
              [rows]="correspondants"
              [columnMode]="'standard'"
              [rowHeight]="'auto'"
              [headerHeight]="50"
              [footerHeight]="50"
              [loadingIndicator]="loading"
            >
              <ngx-datatable-column name="ID" prop="id" [width]="75">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Rôle" prop="role" [width]="200">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ row.role }} {{ row.role_index }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Nom" prop="lastname" [width]="200">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Prénom" prop="firstname" [width]="200">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>


              <ngx-datatable-column name="Email" prop="email" [width]="200">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Numéro(s) de téléphone" prop="phone" [width]="300">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{row.phone_number ? row.phone_number : ''}}<br>
                  {{row.phone_number2 ? row.phone_number2 : ''}}
                </ng-template>
              </ngx-datatable-column>


              <ngx-datatable-column name="Actions" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a *ngIf="router.url.split('/').includes('open-agencies')" [routerLink]="['/profilplus-agences/open-agencies/correspondants/edit', agency.id, 'correspondant', row.id]" routerLinkActive="router-link-active"  type="button" class="btn btn-success" title="Modifier le correspondant">
                    <i class="material-icons">edit</i>
                  </a>
                  <a *ngIf="policiesService.currentUser.is_admin" [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/correspondants/edit', agency.id, 'correspondant', row.id, 'mailer_slugs'] : ['/profilplus-agences/closed-agencies/correspondants/edit', agency.id, 'correspondant', row.id, 'mailer_slugs']" routerLinkActive="router-link-active"  type="button" class="btn btn-success" title="Modifier les emails rattachés">
                    <i class="material-icons">mail</i>
                  </a>
                  <button type="button" class="btn btn-danger" (click)="onDelete(row.id)" title="Supprimer">
                    <i class="material-icons">close</i>
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>

          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>

<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
