import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { MyprofilplusRoutingModule } from './myprofilplus-routing.module';
import { MyprofilplusComponent } from './myprofilplus.component';

import { LoyaltyCardsComponent } from './loyalty-cards/loyalty-cards.component';

import { LoyaltyCustomerHistoriesComponent } from './loyalty-customer-histories/loyalty-customer-histories.component';

import { LoyaltyCardOrdersComponent } from './loyalty-card-orders/loyalty-card-orders.component';
import { LoyaltyCardOrdersEditComponent } from './loyalty-card-orders/loyalty-card-orders-edit/loyalty-card-orders-edit.component';

import { LoyaltyRulesComponent } from './loyalty-rules/loyalty-rules.component';
import { LoyaltyRulesEditComponent } from './loyalty-rules/loyalty-rules-edit/loyalty-rules-edit.component';

import { LoyaltyCardOrganizationsComponent } from './loyalty-card-organizations/loyalty-card-organizations.component';

import { ShowComponent } from './show/show.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { ListComponent as ceCardList } from './ce-cards/list/list.component';
import { EditComponent as ceCardEdit } from './ce-cards/edit/edit.component';
import { ListComponent } from './customers/list/list.component';
import { EditComponent } from './customers/edit/edit.component';
import { FusionComponent } from './customers/fusion/fusion.component';
import { CompensationComponent } from './compensation/compensation.component';

@NgModule({
  imports:
  [
    SharedModule,
    CommonModule,
    MyprofilplusRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot()
  ],
  declarations: [
    MyprofilplusComponent,
    LoyaltyCardsComponent,
    LoyaltyCustomerHistoriesComponent,
    LoyaltyCardOrdersComponent,
    LoyaltyCardOrdersEditComponent,
    LoyaltyRulesComponent,
    LoyaltyRulesEditComponent,
    LoyaltyCardOrganizationsComponent,
    ShowComponent,
    AnalyticsComponent,
    ceCardList,
    ceCardEdit,
    ListComponent,
    EditComponent,
    FusionComponent,
    CompensationComponent
  ],
  exports: [
    MyprofilplusComponent
  ],
  providers: []
})
export class MyprofilplusModule {}
