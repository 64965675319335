import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { EnumsService } from 'src/app/services/enums.service';
import { AgenciesService } from 'src/app/services/agencies.service';
import { AlertService } from 'src/app/services/alert.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { AuthService } from 'src/app/services/auth.service';
import { WorkersService } from 'src/app/services/workers.service';
import { FileInputComponent } from 'src/app/components/file-input/file-input.component';

@Component({
  selector: 'app-closed-agencies',
  templateUrl: './closed-agencies.component.html',
  styleUrls: ['./closed-agencies.component.scss']
})
export class ClosedAgenciesComponent implements OnInit {
  @ViewChild('FileInput') FileInput: FileInputComponent;

  public items: Array<any> = null;
  public loading: boolean = false;
  public loadingList: boolean = false;
  public fileImporting: boolean = false;

  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [];

  constructor(
    private cd: ChangeDetectorRef,
    private workersService: WorkersService,
    private authService: AuthService,
    public enumsService: EnumsService,
    private alertService: AlertService,
    private agenciesService: AgenciesService,
    private route: ActivatedRoute,
    public policiesService: PoliciesService,
    public router: Router) {
      this.policiesService.currentUser = this.authService.currentUserValue;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          this.filters.push({
            name: 'Nom',
            slug: 'agency_name',
            type: 'autocomplete',
            class: 'col-2',
            options: this.enumsService.enums.agencies_closed
          });
          this.filters.push({
            name: 'Société',
            slug: 'company_name',
            type: 'autocomplete',
            class: 'col-2',
            options: this.enumsService.enums.companies
          });
          this.filters.push({
            name: 'Adhérent',
            slug: 'adherent_name',
            type: 'autocomplete',
            class: 'col-2',
            options: this.enumsService.enums.adherents
          });
          this.filters.push({
            name: 'Nom du chef d\'agence',
            slug: 'lastname_manager',
            type: 'text',
            class: 'col-2',
            value: null
          });
          this.loading = false;
        }
      });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  downloadOfflineAgenciesMonoWorksheet() {
    this.workersService.startNewWorker('agencies_export', {mono_offline_agencies: true}).then((response) => {
      this.alertService.success('Export de la liste des agences fermées en cours, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;
    this.agenciesService.getAllClosedAgenciesWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected).then((response: any) => {
      this.datatable.limit = 20;
      this.datatable.count = response.pagination.total_items;
      this.items = response.items;
      this.loadingList = false;
    })
    .catch((err) => {
      this.alertService.error(err);
      this.loadingList = false;
    });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

}
