import { environment } from '../environments/environment';

export default {
  apiUrl: environment.apiUrl,
  appSignalFrontApiKey: environment.appSignalFrontApiKey,
  awsCredentials: {
    accessKeyId: environment.awsAccessKeyId,
    secretKey: environment.awsSecretAccessKey
  },
  ckEditor: {
    extraPlugins: 'colorbutton,justify',
    allowedContent: true,
    extraAllowedContent: '*(*)',
    filebrowserBrowseUrl: environment.apiUrl + '/browser/browse.php?type=Files',
    filebrowserUploadUrl: environment.apiUrl + '/uploader/upload.php?type=Files',
    width: 600,
    height: 300,
    resize_dir: 'vertical',
    resize_minWidth: 200,
    resize_minHeight: 300,
    resize_maxWidth: 800,
    resize_maxHeight: 700,
  }
};
