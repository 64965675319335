import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit {
  public file: File = null;
  @Input() capture = false;
  @Input() btnClass = '';
  @Input() label = 'Importer';
  public defaultBtnClass = 'btn btn-raised btn-round btn-default';
  @Output() filesSelected = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  import($event) {
    this.file = $event;
    this.filesSelected.emit(this.file);
  }

  clean() {
    this.file = null;
    this.filesSelected.emit(this.file);
  }

}
