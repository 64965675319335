import { Injectable } from '@angular/core';

import 'bootstrap-notify';
import { ApiService } from './api.service';

import { Questionnaire } from '../models/Questionnaire';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';
import {Form} from "../models/Form";


@Injectable({ providedIn: 'root' })
export class FormsService {
  constructor(private apiService: ApiService) {

  }

  getById(formId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('forms/' + formId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });

    });
  }

  getAll(sortBy: { field: string, order: string } = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('forms' + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '')
        + (filters ? '&' + parseFiltersForUrl(filters) : '')
      ).then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }


  getAllWithPagination(page: string | number, sortBy: { field: string, order: string } = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('forms?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '')
        + (filters ? '&' + parseFiltersForUrl(filters) : '')
      )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(form: Form) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('formulaire:title', form.title);
      formData.append('formulaire:universe', form.universe);
      formData.append('formulaire:slug', form.slug);
      formData.append('formulaire:form_type', form.form_type.toString());
      formData.append('formulaire:top_form_content', form.top_form_content ? form.top_form_content : '');
      formData.append('formulaire:bottom_form_content', form.bottom_form_content ? form.bottom_form_content : '');
      formData.append('formulaire:custom_fields', JSON.stringify(form.custom_fields));
      formData.append('formulaire:start_at', form.start_at.toString());
      formData.append('formulaire:end_at', form.end_at.toString());
      formData.append('formulaire:adherent_ids', form.adherents && form.adherents.length > 0 ? form.adherents.map(x => x.id).join(',') : '');

      formData.append('formulaire:agency_mail_subject', form.agency_mail_subject.toString());
      formData.append('formulaire:client_mail_subject', form.client_mail_subject.toString());

      if (form.banner !== null) {
        formData.append('formulaire:banner', form.banner ? form.banner : '');
      }

      if (form.id) {
        this.apiService.put('forms/' + form.id, formData)
          .then((response: Questionnaire) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        this.apiService.post('forms', formData)
          .then((response: Questionnaire) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      }

    });
  }

  delete(formId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('forms/' + formId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
