import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { AlertService } from './alert.service';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';

@Injectable({ providedIn: 'root' })
export class WorkersService {

  constructor(private authService: AuthService, private apiService: ApiService, private alertService: AlertService) {

  }


  getNotifications() {
    return new Promise((resolve, reject) => {
        this.apiService.get('job_service_requests/all')
        .then((response) => {
            resolve(response);
        })
        .catch((err) => {
            reject(err);
        });
    });
  }

  getNotificationsPagination(page: string | number, sortBy: { field: string, order: string } = null, filters = null) {
    return new Promise((resolve, reject) => {
        this.apiService.get('job_service_requests?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '')
        + (filters ? '&' + parseFiltersForUrl(filters) : '')
      )
        .then((response) => {
            resolve(response);
        })
        .catch((err) => {
            reject(err);
        });
    });
  }


  startNewWorker(requestType: string, executionData: any) {
    const formData: FormData = new FormData();
    formData.append('job_service_request:request_type', requestType);
    if (requestType === 'files_mapping') {
      formData.append('job_service_request:file_1', executionData.file_1);
      formData.append('job_service_request:file_2', executionData.file_2);
      formData.append('job_service_request:execution_data', JSON.stringify({
        radius: parseInt(executionData.radius),
        number_of_results: parseInt(executionData.number_of_results)
      }));
    }
    else if (requestType === 'contacts_import') {
      formData.append('job_service_request:file', executionData.file);
    }
    else {
      formData.append('job_service_request:execution_data', JSON.stringify(executionData));
    }

    return new Promise((resolve, reject) => {
      this.apiService.post('job_service_requests', formData)
        .then((response: any) => {

          if (typeof Worker !== 'undefined') {
            // Create a new worker
            // BEFORE: const worker = new Worker('../workers/job-service-requests.worker', {type: 'module'});
            const worker = new Worker(new URL('../workers/job-service-requests.worker', import.meta.url), {type: 'module'});

            // Set worker on message actions
            this.setWorker(worker);

            // launch worker
            worker.postMessage({
              job_id: response.id,
              user_token: this.authService.currentUserValue.token
            });

          } else {
            // Web Workers are not supported in this environment.
            // You should add a fallback so that your program still executes correctly.
          }

          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  download(url, filename) {
    this.apiService.getBlob(url)
    .then((blob: Blob) => {
      //ts-ignore
      const href = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = href;
      a.download = filename;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

  /**
   * Define what doing on worker message return
   * @param worker
   */
  setWorker(worker: Worker) {
    worker.addEventListener('message', ({ data }) => {
      if (data.finish) {
        worker.terminate(); // Delete worker
        if (data.success) {
          let filename = 'profilplus-export-' + data.result.id + '-' + data.result.request_type + '.xlsx'
          if (["questionnaire_contacts_export", "export_offers_pdf", "commercial_action_stats"].includes(data.result.request_type)) {
            filename = data.result.filename
          }
          this.alertService.success(`Export terminé, votre fichier est accessible :`,
            false,
            { // Add btn to toast alert
              btn: true,
              callback: () => {
                this.download(data.result.request_type == "files_mapping" ? data.result.file_reporting : data.result.file, filename);
              }
            });
            if (data.result.file_reporting && data.result.request_type === "agencies_import") {
              this.alertService.warning('Import non terminé car des erreurs de données ont été rencontrées. Retrouvez l\'historique des erreurs dans l\'onglet Notifications');
            }
        } else {
          this.alertService.error('Erreur de traitement : ' + data.result);
        }
      }
    });
  }

  delete(id) {
    return new Promise((resolve, reject) => {
        this.apiService.delete('job_service_requests/' + id)
        .then((response) => {
            resolve(response);
        })
        .catch((err) => {
            reject(err);
        });
    });
  }
}
