import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import routeChanger from 'src/app/helpers/routeChanger';

@Component({
  selector: 'app-myprofilplus',
  templateUrl: './myprofilplus.component.html',
  styleUrls: ['./myprofilplus.component.scss']
})
export class MyprofilplusComponent implements OnInit {
  public moduleSlug: string;

  constructor(
    private router: Router) { }

  ngOnInit(): void {
    this.moduleSlug = this.router.url.split('/')[1];
  }

  ngAfterViewChecked() {
    routeChanger.viewChecked();
  }

  changeOfRoutes() {
    routeChanger.afterChange();
  }
}
