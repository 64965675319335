import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import Swal from "sweetalert2";
import {FormsService} from "../../../services/forms.service";
import {AlertService} from "../../../services/alert.service";
import {EnumsService} from "../../../services/enums.service";
import {PoliciesService} from "../../../services/policies.service";
import {AuthService} from "../../../services/auth.service";
import {Questionnaire} from "../../../models/Questionnaire";
import {WorkersService} from "../../../services/workers.service";

@Component({
  selector: 'app-profilplus-forms',
  templateUrl: './profilplus-forms.component.html',
  styleUrls: ['./profilplus-forms.component.scss']
})
export class ProfilplusFormsComponent implements OnInit {
  public items: Questionnaire[] = [];
  public currentItems: Questionnaire[] = [];
  public archivedItems: Questionnaire[] = [];
  public loading: boolean = false;
  public loadingList: boolean = false;
  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public filters: Array<any> = [];

  constructor(
    private workersService: WorkersService,
    private cd: ChangeDetectorRef,
    public policiesService: PoliciesService,
    public authService: AuthService,
    public enumsService: EnumsService,
    private alertService: AlertService,
    private formsService: FormsService
  ) {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.loading = false;
      }
    });
  }


  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.setPage();
    this.cd.detectChanges();
  }

  setPage(
    sortBy: { field: string, order: string } = null, filters = null) {
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected;
    this.loadingList = true;
    this.formsService.getAll(this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.items = response;
        this.currentItems = response.filter((q: Questionnaire) => new Date(q.end_at).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0));
        this.archivedItems = response.filter((q: Questionnaire) => new Date(q.end_at).setHours(0,0,0,0) < new Date().setHours(0,0,0,0));
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  downloadCharts(id) {
    this.workersService.startNewWorker('formulaire_export', {form_id: id})
      .then(() => {
        this.alertService.success('Statistiques en cours d\'export, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }


  onDelete(formId: number, archived = false) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce formulaire ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.formsService.delete(formId)
          .then(() => {
            const temp = archived ? this.archivedItems : this.items
            const index = temp.findIndex(e => e.id === formId);
            if (index !== -1) {
              temp.splice(index, 1);
              this.items = [...temp];
              this.alertService.success('Formulaire supprimé avec succès.');
            }
          })
          .catch((err) => {
            this.alertService.error(err);
          });
      }
    });
  }
}
