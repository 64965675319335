<app-topbar moduleSlug="activity-forms">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/activity-tracking']">Suivi d'activité TC4</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Vue d'ensemble</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="col-md-12">

  <!-- Filtres -->
  <div class="row">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">visibility</i>
        </div>
        <h4 class="card-title">Vue d'ensemble</h4>
      </div>
      <div class="card-body">
        <form class="row" [formGroup]="editFormGroup" *ngIf="editFormGroup">
          <div class="col col-2">
            <div class="form-group">
              <label for="year">Année</label>
              <select class="form-control" name="year"
                formControlName="year">
                <option default selected disabled>Choisir une année</option>
                <option *ngFor="let option of enumsService.years" [value]="option">{{option}}</option>
              </select>
            </div>
          </div>
          <div class="col col-2">
            <div class="form-group">
              <label for="month">Mois</label>
              <select class="form-control" name="month"
                formControlName="month">
                <option default selected disabled>Choisir un mois</option>
                <option *ngFor="let option of enumsService.months" [value]="option">{{option}}</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- Tableaux de suivi d'activité TC4 -->
  <div class="row" *ngIf="editFormGroup && editFormGroup.controls.year.value && editFormGroup.controls.month.value">
    <div class="card">
      <div class="card-body">
        <div>
          <div class="activityTrackingList" *ngFor="let table of tables">
            <h4 class="card-title">{{table.title}}</h4>
            <div class="table-responsive">
              <ngx-datatable
                #datatable
                class="activityDashboard"
                [rows]="table.items"
                [columnMode]="'standard'"
                [rowHeight]="'auto'"
                [headerHeight]="30"
                [footerHeight]="50"
                [reorderable]="false"
                [loadingIndicator]="loading"
              >
                <ng-container *ngFor="let column of table.columns">
                  <ngx-datatable-column [name]="column.label" [prop]="column.slug" [sortable]="false" [width]="column.width">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                      <span [ngClass]="{'total-line': row['name'] == 'TOTAL'}">
                        <span *ngIf="!['b2c_volume', 'b2b_volume', 'volume_sum',
                        'b2c_marge', 'b2b_marge', 'marge_sum',
                        'volume_filtre_huile', 'volume_filtre_air',
                        'volume_filtre_carburant', 'volume_filtre_habitacle', 'volume_disque', 'volume_plaquettes'].includes(column.slug)">
                          <span *ngIf="value && value > 0">+</span>
                        </span>
                        <ng-container *ngIf="column.slug != 'name'; else elseTemplate">
                          {{ (value ? value : "") | round: 0 | numberWithSpaces}}
                          <span *ngIf="value && (!['b2c_volume', 'b2b_volume', 'volume_sum',
                          'b2c_marge', 'b2b_marge', 'marge_sum',
                          'volume_filtre_huile', 'volume_filtre_air',
                          'volume_filtre_carburant', 'volume_filtre_habitacle', 'volume_disque', 'volume_plaquettes'].includes(column.slug))">%</span>
                        </ng-container>
                        <ng-template #elseTemplate>
                          {{value}}
                        </ng-template>
                      </span>
                    </ng-template>
                  </ngx-datatable-column>
                </ng-container>
              </ngx-datatable>
            </div>
            <p>
              <strong class="description">Remarque : </strong> les cellules vides ne peuvent être calculées car certaines données sont manquantes.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
