import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { Company } from '../models/Company';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';

@Injectable({ providedIn: 'root' })
export class CompaniesService {
  constructor(
    private apiService: ApiService) {}

  /**
   * Get a specific company
   * @param companyId Company ID
   */
  getById(companyId) {
    return new Promise((resolve, reject) => {
      this.apiService.get('companies/' + companyId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get all companies
   */
  getAll(filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('companies' + (filters ? '?' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Create or update a specific company
   * @param company The corresponding company
   */
  edit(company) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('company:name', company.name ? company.name : '');
      formData.append('company:mail', company.mail ? company.mail : '');
      formData.append('company:phone_number', company.phone_number ? company.phone_number : '');
      formData.append('company:phone_number2', company.phone_number2 ? company.phone_number2 : '');
      formData.append('company:siret', company.siret ? company.siret : '');
      formData.append('company:rib', company.rib ? company.rib : '');
      formData.append('company:vat_number', company.vat_number ? company.vat_number : '');
      formData.append('company:adherent_id', company.adherent_id);
      if (company.id) { // UPDATE
        this.apiService.put('companies/' + company.id, formData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      } else { // CREATE
        this.apiService.post('companies', formData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }


  getAllWithPagination(page, sortBy = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('companies?page=' + page + (sortBy ? '&sort_by=' + sortBy : '' ))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Delete a specific company
   * @param companyId company ID
   */
  delete(companyId) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('companies/' + companyId)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
