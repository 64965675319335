<form *ngIf="editFormGroup" [formGroup]="editFormGroup" (keydown.enter)="$event.preventDefault()">

  <p class="form-group is-required"
    [ngClass]="{ 'has-danger': submitted && f.name.errors }">
    <label class="bmd-label-floating">Nom de la question :</label>
    <input type="text" required class="form-control" id="question" formControlName="name">
    <label class="error" for="title" *ngIf="submitted && f.name.errors">
      Champ obligatoire.
    </label>
  </p>

  <div class="form-group is-required"
    [ngClass]="{ 'has-danger': submitted && f.question_type.errors }">
    <label class="col-form-label" for="question_type">Type de réponse attendue :</label>
    <div>
      <select name="question_type" formControlName="question_type" class="ppp-question-type">
        <option value="" selected d>Veuillez sélectionner un type</option>
        <option value="text">Texte</option>
        <option value="number">Nombre</option>
        <option value="checkbox">Case à cocher</option>
        <option value="select-unique">Liste déroulante (choix unique)</option>
        <option value="select-multiple">Liste déroulante (choix multiples)</option>
      </select>
    </div>
    <label class="error" for="title" *ngIf="submitted && f.question_type.errors">
      Champ obligatoire.
    </label>
  </div>
  <ng-container *ngIf="f.question_type.value === 'select-unique' || f.question_type.value === 'select-multiple'">
    <div class="form-group is-required"
      [ngClass]="{ 'has-danger': submitted && f.values.errors }">
      <label class="bmd-label-floating">Valeurs de la liste déroulante <small>(appuyez sur entrée pour valider chaque valeur)</small> :</label><br>
      <!--values tags-->
      <div class="bootstrap-tagsinput info-badge example-list" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let item of f.values.value; let i = index">
          <div class="tag badge example-box" cdkDrag>
            <div class="example-handle" cdkDragHandle>
              <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </div>
            <p class="example-content" contenteditable="true" (focusout)="onEditValue($event.target.innerText, i)">{{item}}</p>
            <span data-role="remove" (click)="onRemoveValue(i)"></span>
          </div>
        </ng-container>
      </div>
      <!--end values tags-->
      <input type="text" class="form-control" id="values" formControlName="new_value"
        (keyup.enter)="onAddValue($event.target.value)" (focusout)="onAddValue($event.target.value)">

      <label class="error" for="values" *ngIf="submitted && f.values.errors">
        Champ obligatoire.
      </label>
    </div>
  </ng-container>
  <div class="form-group" *ngIf="f.question_type.value !== 'checkbox'">
    <label>Question optionelle ou obligatoire :</label>
    <div class="form-check">
      <label class="form-check-label">
        <input class="form-check-input" formControlName="required" type="radio" name="required" [value]="false" checked>
        Optionnelle
        <span class="circle"><span class="check"></span></span>
      </label>
      <label class="form-check-label">
        <input class="form-check-input" formControlName="required" type="radio" name="required" [value]="true">
        Obligatoire
        <span class="circle"><span class="check"></span></span>
      </label>
    </div>
  </div>

  <button class="ppp-button ppp-button-gray btn btn-primary btn-round" type="submit" (click)="onCancel()">
    Annuler
  </button>
  <button class="ppp-button ppp-button-black btn btn-primary btn-round" type="submit"
    (click)="onSubmit()">
    <ng-container *ngIf="question; else ElseBlock">Mettre à jour la question</ng-container>
    <ng-template #ElseBlock>Enregistrer la question</ng-template>
  </button>

</form>
