<app-topbar moduleSlug="adherents-companies-management">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/adherents-companies-management']">Adhérents / Sociétés</a></li>
        <li class="breadcrumb-item active">
          <a href="javascript:;">
            Liste des adhérents {{module == 'open' ? 'ouverts' : 'fermés'}}
          </a>
        </li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div *ngIf="policiesService.currentUser.policies.adherent.create" class="row">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round" *ngIf="module == 'open'"
     [routerLink]="['/adherents-companies-management', module, 'edit']">
      <i class="material-icons">library_add</i>
      Créer un nouvel adhérent
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">list</i>
        </div>
        <h4 class="card-title">Liste des adhérents</h4>
      </div>
      <div class="card-body">
        <app-filter-bar [filters]="filters"
          (changed)="setPage({offset: 0}, null, $event)">
          <p style="margin-top: 10px;" *ngIf="loading">chargement...</p>
        </app-filter-bar>

        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [loadingIndicator]="loadingList"
          >
            <ngx-datatable-column name="ID" prop="id" [width]="50">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Nom" prop="name" [width]="300">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Code facturation" prop="codefac" [width]="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value.name }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="policiesService.currentUser.policies.adherent.update"
                  [routerLink]="['/adherents-companies-management/',module, 'edit', row.id]" type="button" class="btn btn-success" title="Modifier l'adhérent">
                  <i class="material-icons">edit</i>
                </a>
                <button *ngIf="policiesService.currentUser.policies.adherent.delete"
                  type="button" class="btn btn-danger" title="Supprimer">
                  <i class="material-icons" (click)="onDelete(row.id)">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
