import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { CandidaturesService } from 'src/app/services/candidatures.service';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {
  loading!: boolean
  currentItem = null
	fields = [
    {name: 'Type de candidature', slug: 'candidature_type', template: true},
		{name: 'Nom', slug: 'last_name'},
		{name: 'Prénom', slug: 'first_name'},
		{name: 'N° téléphone mobile', slug: 'mobile_phone'},
		{name: 'Adresse email', slug: 'email'},
		{name: 'Région', slug: 'region'},
    {name: 'Ville', slug: 'city'},
    {name: 'Rayon de la recherche', slug: 'distance'},
    {name: 'Métier(s)', slug: 'concerned_skills', template: true},
    {name: 'Motivations', slug: 'motivations', template: true},
    {name: 'Fichier du cv', slug: 'path_to_file', template: true}
	]

  constructor(
    private alertService: AlertService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private candidaturesService: CandidaturesService) {
    this.loading = true;
    this.candidaturesService.getById(this.route.snapshot.params.id)
      .then((response) => {
        this.loading = false;
        this.currentItem = response;
        if (this.authService.currentUserValue.adherents && this.authService.currentUserValue.adherents.length > 0) {
          this.candidaturesService.addHistorique(this.route.snapshot.params.id, this.authService.currentUserValue.id)
            .then(() => {

            })
            .catch((err) => {

            });
        }
      });
  }

  ngOnInit(): void {
  }

  dowloadAttachment(path, filename) {
    this.apiService.getBlob(path)
    .then((blob: Blob) => {
      const href = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = href;
      a.download = filename;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }
}
