import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { EmailsSmsHistoryRoutingModule } from './emails-sms-history-routing.module';
import { EmailsSmsHistoryComponent } from './emails-sms-history.component';

import { ListComponent } from './list/list.component';

import { ShowComponent } from './show/show.component';

@NgModule({
  imports:
  [
    SharedModule,
    CommonModule,
    EmailsSmsHistoryRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot()
  ],
  declarations: [
    EmailsSmsHistoryComponent,
    ListComponent,
    ShowComponent
  ],
  exports: [
    EmailsSmsHistoryComponent
  ],
  providers: []
})
export class EmailsSmsHistoryModule {}
