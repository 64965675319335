<ng-container *ngIf="!loading; else templateElse">
  <app-topbar moduleSlug="emails-sms-history">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/emails-sms-history']">Historique emails/SMS</a></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Liste des historiques emails/SMS</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">list</i>
          </div>
          <h4 class="card-title">Historiques emails/SMS <span>({{this.datatable.count}} éléments)</span>
          </h4>
        </div>
        <div class="card-body">

          <div class="table-responsive">
            <ngx-datatable
              #datatable
              class="material"
              [rows]="items"
              [columnMode]="'standard'"
              [rowHeight]="'auto'"
              [headerHeight]="50"
              [footerHeight]="50"
              [externalPaging]="true"
              [externalSorting]="true"
              [loadingIndicator]="loadingList"
              [reorderable]="false"
              (page)="setPage($event)"
              (sort)="onSort($event)"
            >

              <ngx-datatable-column name="Date" prop="created_at" [width]="150">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{value  | date:'dd/MM/y HH:mm'}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Sujet" prop="subject" [width]="360">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="To" prop="to" [width]="400">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Actions" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a [routerLink]="[ '/emails-sms-history/list', row.id, 'consultation' ]"
                    class="btn btn-success" title="Consulter">
                      <i class="material-icons">visibility</i>
                  </a>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #templateElse>
  <app-loading></app-loading>
</ng-template>
