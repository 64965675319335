<div class="ppp-navigation sidebar" data-color="rose" data-background-color="black">
  <div class="logo">
    <a href="/" class="simple-text logo-mini">
      <img src="./assets/img/logo.png" />
    </a>
    <a href="/" class="simple-text logo-normal">
      Profil Plus - Portail
    </a>
  </div>
  <div class="sidebar-wrapper" *ngIf="policiesService.currentUser">
      <div class="user">
        <div class="user-info" *ngIf="user">
          <a data-toggle="collapse" href="#collapseAccount" class="username">
            <span>
              {{user.first_name}} {{user.last_name}}
              <b class="caret"></b>
            </span>
          </a>
          <div class="collapse" id="collapseAccount">
            <ul class="nav">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/users-management/edit/', user.id]">
                  <span class="sidebar-mini"> <i class="material-icons">account_circle</i> </span>
                  <span class="sidebar-normal">Mon compte</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript;" (click)="onLogout($event)">
                  <span class="sidebar-mini"> <i class="material-icons">power_settings_new</i> </span>
                  <span class="sidebar-normal"> Déconnexion </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/']" routerLinkActive="active" >
            <i class="material-icons">dashboard</i>
            <p> Tableau de bord </p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/notifications']" routerLinkActive="active" >
            <i class="material-icons">info</i>
            <p> Notifications </p>
          </a>
        </li>

        <ng-container *ngFor="let module of modulesList">
          <li *ngIf="policiesService.checkModule(modules[module])" class="nav-item" [ngClass]="(router.url.split('/').includes(module)) ? 'active': ''">
            <a class="nav-link" data-toggle="collapse" [href]="'#' + module"
              [attr.aria-expanded]="(router.url.split('/').includes(module)) ? 'true': 'false'">
              <i class="material-icons">{{(modules[module].icon) ? modules[module].icon : 'add_box'}}</i>
              <p title="{{modules[module].name}}"> {{modules[module].name}}
                <b class="caret"></b>
              </p>
            </a>
            <div class="collapse" [id]="module" [ngClass]="(router.url.split('/').includes(module)) ? 'show': ''">
              <ul class="nav">
                <ng-container *ngFor="let item of modules[module].items" >
                  <li *ngIf="policiesService.checkItem(item)" [ngClass]="(router.url.split('/').includes(item.slug)) ? 'active': ''">
                    <a class="nav-link"
                      [routerLink]="item['sub-items'] ? ['/' + module, item.slug, item['sub-items'][0].slug] : ['/' + module, item.slug]"
                      routerLinkActive="router-link-active" [routerLinkActiveOptions]="{exact: true}">
                      <span class="sidebar-mini"> <i class="material-icons">{{item.icon}}</i> </span>
                      <span class="sidebar-normal" title="{{item.name}}"> {{item.name}} </span>
                    </a>
                    <ng-container *ngIf="item['sub-items']">
                      <ul>
                        <ng-container  *ngFor="let subItem of item['sub-items']">
                          <li *ngIf="policiesService.userHaveRule(subItem.model, subItem.type)">
                            <a [routerLink]="['/' + module, item.slug, subItem.slug]" routerLinkActive="router-link-active" >
                              {{subItem.name}}
                            </a>
                          </li>
                        </ng-container>
                      </ul>
                    </ng-container>
                  </li>
                </ng-container>
              </ul>
            </div>
          </li>
        </ng-container>
      </ul>
  </div>
  <div class="sidebar-background"></div>
</div>
