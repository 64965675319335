import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { InterventionsService } from 'src/app/services/interventions.service';

import { Intervention } from 'src/app/models/Intervention';
import { EnumsService } from 'src/app/services/enums.service';
import { SitesService } from 'src/app/services/sites.service';

@Component({
  selector: 'app-interventions',
  templateUrl: './interventions.component.html',
  styleUrls: ['./interventions.component.scss']
})
export class InterventionsComponent implements OnInit {

  public items: Array<Intervention> = null;
  public totalPages: number;
  public totalItems: number;
  public currentPage: number;
  public nextPage: any;

  public loading: boolean = false;
  public loadingList: boolean = false;
  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;

  mounting_list = [
    {
      name: "Sans talons",
      id: 0
    },
    {
      name: "Talon bloquant",
      id: 1
    },
    {
      name: "Avec talons bloquants",
      id: 2
    },{
      name: "Bi flasques",
      id: 3
    }
  ]

  public filters: Array<any> = [
    {
      name: 'Marque chariot',
      slug: 'cart_band',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Type chariot',
      slug: 'cart_type',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Type montage (avant)',
      slug: 'front_mounting_type',
      selected_emit_property: 'id',
      property: "name",
      type: 'select',
      class: 'col-2',
      options: this.mounting_list
    },
    {
      name: 'Type montage (arrière)',
      slug: 'back_mounting_type',
      selected_emit_property: 'id',
      property: "name",
      type: 'select',
      class: 'col-2',
      options: this.mounting_list
    }
  ];
  public optionsFilteredByType: any;

  constructor(
    private cd: ChangeDetectorRef,
    private interventionsService: InterventionsService,
    private sitesService: SitesService,
    private enumsService: EnumsService,
    private alertService: AlertService) {
      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          this.loading = false;
          this.sitesService.getAll()
            .then((sites) => {
              this.filters.push({
                name: 'Site',
                slug: 'site_id',
                selected_emit_property: 'id',
                property: "social_reason",
                type: 'autocomplete',
                on_select_only: true,
                class: 'col-2',
                options: sites
              })
            })
        }
      });
    }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;

    this.interventionsService.getAllWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

}
