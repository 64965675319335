import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { SitesService } from 'src/app/services/sites.service';

import { Site } from 'src/app/models/Site';
import { EnumsService } from 'src/app/services/enums.service';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit {
  public items: Array<any> = null;
  public currentSite: Site = null;
  public totalPages: number;
  public totalItems: number;
  public currentPage: number;
  public nextPage: any;

  public loading: boolean = false;
  public loadingList: boolean = false;
  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [
    {
      name: 'Raison sociale',
      slug: 'social_reason',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Code',
      slug: 'code',
      type: 'text',
      class: 'col-2',
      value: null
    },
  ];
  public optionsFilteredByType: any;

  constructor(
    private cd: ChangeDetectorRef,
    private sitesService: SitesService,
    private enumsService: EnumsService,
    private alertService: AlertService) {
      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          this.loading = false;
          this.loading = false;
          this.filters.push({
            name: 'Agence',
            slug: 'agency_id',
            selected_emit_property: 'id',
            type: 'autocomplete',
            on_select_only: true,
            class: 'col-2',
            options: this.enumsService.enums.agencies
          })
        }
      });
    }


  ngOnInit(): void { }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;
    this.sitesService.getAllWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

  showData(item) {
    this.currentSite = item;

    this.cd.detectChanges();

    // @ts-ignore: Unreachable code error
    jQuery('#sites-modal').modal();
    jQuery('#sites-modal').on('hidden.bs.modal', (e) => {
      this.currentSite = null;
    });
  }

}
