<ng-container *ngIf="modules[currentModule] && currentService.slug && currentItem">
  <app-topbar moduleSlug="profilplus-direct">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/'+currentModule]">{{modules[currentModule].name}}</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/'+currentModule, currentService.slug]">Liste des {{currentService.name}}</a></li>
          <li *ngIf="currentService.sub" class="breadcrumb-item">
            <a [routerLink]="['/'+currentModule,  currentService.slug, currentService.sub.slug]">{{currentService.sub.name}}</a>
          </li>
          <li class="breadcrumb-item active"><a href="javascript:;">Détails</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <app-show-content [fields]="fields" [item]="currentItem">
    <ng-template #template let-default let-item="item" let-field="field">

      <!-- COMMENTAIRES -->
      <ng-container *ngIf="field.slug === 'id'">
        <strong>ID</strong> : {{item.id}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'intervention'">
        <strong>Intervention</strong> :
        <a class="ppp-link" [routerLink]="['/profilplus-direct/interventions', item.intervention.id, 'consultation']" target="_blank">
          {{item.intervention.ba_reference}}
        </a>
      </ng-container>
      <ng-container *ngIf="field.slug === 'content'">
        <strong>Contenu</strong> :
        <div class="ppp-contentWithBorder">{{item.content}}</div>
      </ng-container>

      <!-- INTERVENTIONS -->
      <!-- Type de montage (avant) -->
      <ng-container *ngIf="field.slug === 'front_mounting_type'">
        <strong>Type de montage (avant) :</strong> {{item.front_mounting_type.name}}
      </ng-container>
      <!-- Type de montage (arrière) -->
      <ng-container *ngIf="field.slug === 'back_mounting_type'">
        <strong>Type de montage (arrière) :</strong> {{item.back_mounting_type.name}}
      </ng-container>
      <!-- Type de pneumatique -->
      <ng-container *ngIf="field.slug === 'tire_type'">
        <strong>Type de pneumatique :</strong> {{item.tire_type.name}}
      </ng-container>
      <!-- Marque(s) demandée(s) -->
      <ng-container *ngIf="field.slug === 'requested_brand'">
        <strong>Marque demandée :</strong> {{item.requested_brand.name}}
      </ng-container>
      <!-- Commentaires -->
      <ng-container *ngIf="field.slug === 'comments'">
        <strong>Commentaires :</strong> {{item.intervention_comments.length}}
        <div class="ppp-contentWithBorder" *ngFor="let comment of item.intervention_comments">
          <div class="ppp-contentWithBorder-date">{{comment.created_at | date: 'dd/MM/yy à hh:ss' }}</div>
          <div>{{comment.content}}</div>
        </div>
      </ng-container>
      <!-- Nom du site -->
      <ng-container *ngIf="field.slug === 'site_name'">
        <strong>Nom du site :</strong>
        <a class="ppp-link" [routerLink]="['/profilplus-direct/sites', item.site.id, 'consultation']" target="_blank">
          {{item.site.social_reason}}
        </a>
      </ng-container>
      <!-- Date prise en compte -->
      <ng-container *ngIf="field.slug === 'processed_at'">
        <strong>Prise en compte :</strong> {{item.processed_at | date: 'dd/MM/yy à hh:ss'}}
      </ng-container>
      <!-- Date validation de commande + BA déposé -->
      <ng-container *ngIf="field.slug === 'order_validated_at'">
        <strong>Validation de la commande et BA déposé :</strong> {{item.order_validated_at | date: 'dd/MM/yy à hh:ss'}}
      </ng-container>
      <!-- Date intervention saisie -->
      <ng-container *ngIf="field.slug === 'intervention_at'">
        <strong>Date d'intervention saisie :</strong> {{item.intervention_at | date: 'dd/MM/yy à hh:ss'}}
      </ng-container>
      <!-- Date clotûre intervention -->
      <ng-container *ngIf="field.slug === 'intervention_closed_at'">
        <strong>Date de clôture de l'intervention :</strong> {{item.intervention_closed_at | date: 'dd/MM/yy à hh:ss'}}
      </ng-container>
      <!-- Créée le -->
      <ng-container *ngIf="field.slug === 'created_at'">
        <strong>Date de création :</strong> {{item.created_at | date: 'dd/MM/yy à hh:ss'}}
      </ng-container>
      <!-- Mise à jour le-->
      <ng-container *ngIf="field.slug === 'updated_at'">
        <strong>Date de mise à jour :</strong> {{item.updated_at | date: 'dd/MM/yy à hh:ss'}}
      </ng-container>

      <!-- SITES -->
      <ng-container *ngIf="field.slug === 'agency' && item.agency">
        <strong>Agence :</strong>
        {{item.agency.name}}
      </ng-container>
      <!-- Mise à jour le-->
      <ng-container *ngIf="field.slug === 'update_at'">
        <strong>Date de mise à jour :</strong> {{item.update_at | date: 'dd/MM/yy à hh:ss'}}
      </ng-container>
      <ng-container *ngIf="field.slug === 'site_intervention_address' && item.address_intervention.id">
        <strong>Adresse intervention :</strong>
        <a class="ppp-link" [routerLink]="['/profilplus-direct/sites/addresses', item.address_intervention.id, 'consultation']" target="_blank">
          {{item.address_intervention.street_number}} {{item.address_intervention.street_name}}
          {{item.address_intervention.zipcode}} {{item.address_intervention.city}}
        </a>
      </ng-container>
      <ng-container *ngIf="field.slug === 'site_billing_address' && item.address_billing.id">
        <strong>Adresse de facturation :</strong>
        <a class="ppp-link" [routerLink]="['/profilplus-direct/sites/billing-addresses', item.address_billing.id, 'consultation']" target="_blank">
          {{item.address_billing.street_number}} {{item.address_billing.street_name}}
          {{item.address_billing.zipcode}} {{item.address_billing.city}}
        </a>
      </ng-container>

    </ng-template>
  </app-show-content>
</ng-container>
