import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpComponent } from '../help/help.component';
import { AuthGuard } from 'src/app/helpers/auth.guard';
import { IndexContentComponent } from 'src/app/components/index-content/index-content.component';
import { ActivityTrackingComponent } from './activity-tracking.component';
import { ActivityFormsComponent } from './activity-forms/activity-forms.component';
import { ActivityDashboardComponent } from './activity-dashboard/activity-dashboard.component';
import { EditComponent as FormEditComponent } from './activity-forms/edit/edit.component';

const routes: Routes = [
    {
        path: 'activity-tracking',
        canActivate: [AuthGuard],
        component: ActivityTrackingComponent,
        children: [
          {
            path: '',
            component: IndexContentComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'activity-forms',
            component: ActivityFormsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'activity-form/edit',
            component: FormEditComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'activity-form/edit/:id',
            component: FormEditComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'activity-dashboard',
            component: ActivityDashboardComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'help',
            component: HelpComponent,
            canActivate: [AuthGuard],
          }
        ]
      },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActivityTrackingRoutingModule {}
