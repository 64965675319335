<app-topbar moduleSlug="profilplus-site">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
        <li class="breadcrumb-item"><a href="javascript:;">Gestion des pneus</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Catégories de mise en avant</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">directions_car</i>
        </div>
        <h4 class="card-title">Liste des catégories de mise en avant</h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <ngx-datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [loadingIndicator]="loadingList"
          >

            <ngx-datatable-column name="Nom" prop="name" [width]="120">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Ordre" prop="rank" [width]="120">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Marques" prop="brands" [width]="200">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ul>
                  <li *ngFor="let brand of value">
                    {{brand.name}}
                  </li>
                </ul>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="policiesService.currentUser.policies['highlight-category'].update" [routerLink]="['/website/tires/highlight-categories/edit', row.id]"
                type="button" class="btn btn-success"
                title="Modifier la catégorie de mise en avant">
                  <i class="material-icons">edit</i>
                </a>
              </ng-template>
            </ngx-datatable-column>

          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
