import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeetingsComponent } from './meetings.component';
import { MeetingsRoutingModule } from './meetings-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SharedModule } from 'src/app/shared.module';
import { QuestionnairesComponent } from './questionnaires/questionnaires.component';
import { ContactsComponent } from './contacts/contacts.component';
import { EditComponent as QuestionnairesEditComponent } from './questionnaires/edit/edit.component';
import { EditComponent as ContactsEditComponent } from './contacts/edit/edit.component';
import { RubricFormComponent } from './questionnaires/edit/rubric-form/rubric-form.component';
import { QuestionFormComponent } from './questionnaires/edit/question-form/question-form.component';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    MeetingsRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot()
  ],
  declarations: [
    MeetingsComponent,
    ContactsComponent,
    QuestionnairesComponent,
    QuestionnairesEditComponent,
    ContactsEditComponent,
    RubricFormComponent,
    QuestionFormComponent
  ],
  exports: [
    MeetingsComponent
  ],
  providers: []
})
export class MeetingsModule { }
