import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-datalist',
  templateUrl: './datalist.component.html',
  styleUrls: ['./datalist.component.scss']
})
export class DatalistComponent implements OnInit {
  @Input() ajaxQuerying: boolean = false;
  @Input() multiple: boolean = false;
  @Input() label: string = 'Agence';
  @Input() adherentsagenciespage: boolean = false;
  @Input() items: Array<any> = null;
  @Input() preselect: any = null;
  @Input() restOnSelect: boolean = false;
  @Input() property: any = 'name';
  @Input() disabled: boolean = false;
  @Output() selected = new EventEmitter<any>();
  @Output() typed = new EventEmitter<any>();

  public searchField;
  public showList: Boolean = false;
  public selection = [];

  constructor() { }

  ngOnInit(): void {
    if (this.preselect && this.preselect !== -1 && this.items && this.items[this.preselect]) {
      this.searchField = this.items[this.preselect][this.property];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.preselect && changes.preselect.currentValue !== null
        && changes.preselect.currentValue !== -1 && this.items && this.items[changes.preselect.currentValue]) {
      this.searchField = this.items[changes.preselect.currentValue][this.property];
    }
  }

  onTyped($event) {
    this.showList = true;
    this.typed.emit($event.target.value);
  }

  onSelected(item) {
    if (this.multiple) {
      if (!this.selection.includes(item)) {
        this.selection.push(item);
      } else {
        const index = this.selection.findIndex(e => e === item);
        if (index !== -1) {
          this.selection.splice(index, 1);
        }
      }

      this.selected.emit(this.selection);
    } else {
      this.selection = [item];
      if (!this.restOnSelect) {
        this.searchField = item[this.property];
      }
      // this.items = []
      this.showList = false;

      this.selected.emit(this.selection[0]);
    }
  }

}
