import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Question } from 'src/app/models/Question';
import { Rubric } from 'src/app/models/Rubric';
import { AlertService } from 'src/app/services/alert.service';
import { QuestionnairesService } from 'src/app/services/questionnaires.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-rubric-form',
  templateUrl: './rubric-form.component.html',
  styleUrls: ['./rubric-form.component.scss']
})
export class RubricFormComponent implements OnInit {
  public editFormGroup: UntypedFormGroup;
  @Input() rubric: Rubric = null;

  @Output() edited = new EventEmitter();
  @Output() canceled = new EventEmitter();
  public submitted: boolean = false;

  questionEdited: {
    active: boolean,
    uuid: null
  } = { active: false, uuid: null };

  constructor(
    private alertService: AlertService,
    private questionnairesService: QuestionnairesService,
    private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.editFormGroup = this.formBuilder.group({
      name: [this.rubric ? this.rubric.name : '', Validators.required],
      questions: [this.rubric ? this.rubric.questions : [], Validators.required],
    });
  }

  get f() {
    return this.editFormGroup.controls;
  }

  onQuestionEdited($event: Question) {
    const arr = this.f.questions.value;
    if ($event.uuid) { // Update
      const index = arr.findIndex(q => q.uuid === $event.uuid);
      if (index !== -1) { // replace
        arr[index] = $event;
      }
    } else { // CREATE
      $event.uuid = 'temp-' + Math.random() * 20;
      arr.push($event);
      this.editFormGroup.patchValue({ questions: arr });
    }

    this.questionEdited.active = false;
    this.questionEdited.uuid = null;
  }


  onDeleteQuestion(questionUuid: string) {
    this.questionnairesService.checkBeforDeleteQuesiton(questionUuid).then(() => {
        const arr = this.f.questions.value;
        const index = arr.findIndex(q => q.uuid === questionUuid);
        if (index !== -1) { // replace
          arr.splice(index, 1);
        }
        this.editFormGroup.patchValue({ questions: arr });
      })
      .catch((err) => {
        this.alertService.error('Cette question ne peut être supprimée car des réponses y sont rattachées.')
      });
  }

  /* FORM */
  onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid
    if (this.editFormGroup.invalid || (this.f.questions && this.f.questions.value.length < 1)) {
      // window.scrollTo(0, 0);
      return;
    }

    const res: Rubric = {
      ...this.editFormGroup.value
    };

    // Edit mode
    if (this.rubric && this.rubric.uuid) {
      res.uuid = this.rubric.uuid;
    }

    this.edited.emit(res);
    this.editFormGroup.reset();
  }

  onCancel(): void {
    this.canceled.emit();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.f.questions.value, event.previousIndex, event.currentIndex);
  }
}
