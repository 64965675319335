<app-topbar moduleSlug="profilplus-agences">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences']">Profil Plus Agences</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Liste des agences fermées</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row">
  <div class="col-md-9">
    <div>
      <button *ngIf="policiesService.currentUser.is_admin || (policiesService.currentUser && policiesService.currentUser.adherents.length > 0)" class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="downloadOfflineAgenciesMonoWorksheet()">
        <i class="material-icons">pie_chart</i>
        Export Agences fermées (mono)
      </button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">list</i>
        </div>
        <h4 class="card-title">Liste des agences fermées
        </h4>
      </div>
      <div class="card-body">
        <app-filter-bar [filters]="filters"
          (changed)="setPage({offset: 0}, null, $event)">
          <p style="margin-top: 10px;" *ngIf="loadingList">chargement...</p>
        </app-filter-bar>

        <div class="table-responsive">
          <ngx-datatable
            #datatable
            class="material"
            [rows]="items"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [externalPaging]="true"
            [externalSorting]="true"
            [loadingIndicator]="loadingList"
            [reorderable]="false"
            (page)="setPage($event)"
            (sort)="onSort($event)"
          >
            <ngx-datatable-column [resizeable]="false" name="ID" prop="id" [width]="50" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Nom" prop="name" [width]="200" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Société" prop="company" [width]="200" [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ (row.company) ? row.company.name : ''}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Adhérent" prop="adherent" [width]="200" [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ (row.adherent) ? row.adherent.name : '' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="CP" prop="zipcode" [width]="80" [sortable]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ (row.address) ? row.address.zipcode : '' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Nom du chef d'agence" prop="lastname_manager" [width]="220" [sortable]="false">
              <ng-template let-row="row" let-value="value"  ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Actions" [width]="300" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="policiesService.currentUser.policies['closed-agency'].update"
                  [routerLink]="['/profilplus-agences/closed-agencies/edit/', row.id]" type="button" class="btn btn-success" title="Modifier les informations de l'agence">
                  <i class="material-icons">edit</i>
                </a>
                <a *ngIf="policiesService.currentUser.policies['closed-agency'].update"
                  [routerLink]="['/profilplus-agences/closed-agencies/edit/skills/', row.id]" type="button" class="btn btn-success" title="Modifier les métiers">
                  <i class="material-icons">build</i>
                </a>
                <a *ngIf="policiesService.currentUser.policies['closed-agency'].update"
                  [routerLink]="['/profilplus-agences/closed-agencies/edit/services/', row.id]" type="button" class="btn btn-success" title="Modifier les services">
                  <i class="material-icons">playlist_add_check</i>
                </a>
                <a *ngIf="policiesService.currentUser.policies['closed-agency'].update"
                  [routerLink]="['/profilplus-agences/closed-agencies/edit/close-dates/', row.id]" type="button" class="btn btn-success" title="Modifier les dates de fermeture">
                  <i class="material-icons">report</i>
                </a>
                <a *ngIf="false && policiesService.currentUser.policies['closed-agency'].update"
                  [routerLink]="['/profilplus-agences/closed-agencies/edit/opening-times/web/', row.id]" type="button" class="btn btn-success" title="Modifier les horaires d'ouverture web">
                  <i class="material-icons">web</i>
                </a>
                <a *ngIf="policiesService.currentUser.policies['closed-agency'].update"
                  [routerLink]="['/profilplus-agences/closed-agencies/correspondants/edit/', row.id]" type="button" class="btn btn-success" title="Modifier les correspondants">
                  <i class="material-icons">people</i>
                </a>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
