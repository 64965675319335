import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpComponent } from '../help/help.component';
import { AuthGuard } from 'src/app/helpers/auth.guard';

import { MyprofilplusComponent } from './myprofilplus.component';
import { IndexContentComponent } from 'src/app/components/index-content/index-content.component';

import { LoyaltyCardsComponent } from './loyalty-cards/loyalty-cards.component';

import { LoyaltyCustomerHistoriesComponent } from './loyalty-customer-histories/loyalty-customer-histories.component';

import { LoyaltyCardOrdersComponent } from './loyalty-card-orders/loyalty-card-orders.component';
import { LoyaltyCardOrdersEditComponent } from './loyalty-card-orders/loyalty-card-orders-edit/loyalty-card-orders-edit.component';

import { LoyaltyRulesComponent } from './loyalty-rules/loyalty-rules.component';
import { LoyaltyRulesEditComponent } from './loyalty-rules/loyalty-rules-edit/loyalty-rules-edit.component';

import { LoyaltyCardOrganizationsComponent } from './loyalty-card-organizations/loyalty-card-organizations.component';

import { ShowComponent } from './show/show.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { ListComponent as CustomersList} from './customers/list/list.component';
import { EditComponent as CustomersEdit} from './customers/edit/edit.component';
import { FusionComponent } from './customers/fusion/fusion.component';
import { CompensationComponent } from './compensation/compensation.component';

import { ListComponent as ceCardList } from './ce-cards/list/list.component';
import { EditComponent as ceCardEdit } from './ce-cards/edit/edit.component';

const routes: Routes = [
    {
        path: 'myprofilplus',
        component: MyprofilplusComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: IndexContentComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'loyalty-cards',
            component: LoyaltyCardsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'loyalty-cards/page/:page',
            component: LoyaltyCardsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'loyalty-cards/:id/consultation',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'loyalty-cards-history',
            component: LoyaltyCustomerHistoriesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'loyalty-cards-history/page/:page',
            component: LoyaltyCustomerHistoriesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'loyalty-cards-history/:id/consultation',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'loyalty-card-orders',
            component: LoyaltyCardOrdersComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'loyalty-card-orders/page/:page',
            component: LoyaltyCardOrdersComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'loyalty-card-orders/edit/:loyalty_card_order_id',
            component: LoyaltyCardOrdersEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'loyalty-card-orders/edit',
            component: LoyaltyCardOrdersEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'loyalty-rules',
            component: LoyaltyRulesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'loyalty-rules/edit/:loyalty_rule_id',
            component: LoyaltyRulesEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'loyalty-rules/edit',
            component: LoyaltyRulesEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'societies',
            component: LoyaltyCardOrganizationsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'societies/page/:page',
            component: LoyaltyCardOrganizationsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'societies/:id/consultation',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'analytics',
            component: AnalyticsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'customers',
            component: CustomersList,
            canActivate: [AuthGuard]
          },
          {
            path: 'customers/edit',
            component: CustomersEdit,
            canActivate: [AuthGuard]
          },
          {
            path: 'customers/edit/:id',
            component: CustomersEdit,
            canActivate: [AuthGuard]
          },
          {
            path: 'fusion',
            component: FusionComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'help',
            component: HelpComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'ce-cards',
            component: ceCardList,
            canActivate: [AuthGuard],
          },
          {
            path: 'ce-cards/edit',
            component: ceCardEdit,
            canActivate: [AuthGuard],
          },
          {
            path: 'ce-cards/edit/:id',
            component: ceCardEdit,
            canActivate: [AuthGuard],
          },
          {
            path: 'ce-cards/:type',
            component: ceCardList,
            canActivate: [AuthGuard],
          },
          {
            path: 'compensation',
            component: CompensationComponent,
            canActivate: [AuthGuard],
          }
        ]
      },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyprofilplusRoutingModule {}
