import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import routeChanger from '../../helpers/routeChanger';


@Component({
  selector: 'app-gestion-apa',
  templateUrl: './gestion-apa.component.html',
  styleUrls: ['./gestion-apa.component.scss']
})
export class GestionApaComponent implements OnInit {
  public moduleSlug: string;

  constructor(
    private router: Router) { }

  ngOnInit(): void {
    this.moduleSlug = this.router.url.split('/')[1];
  }

  ngAfterViewChecked() {
    routeChanger.viewChecked();
  }

  changeOfRoutes() {
    routeChanger.afterChange();
  }
}
