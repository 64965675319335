import { Address } from './Address';
import { Company } from './Company';
import { Adherent } from './Adherent';

// tslint:disable: variable-name
export class Agency {
  public id: number;
  public created_at: Date;
  public updated_at: Date;
  public generic_company_type: string;
  public generic_company_id: number;
  public company: Company;
  public adherent: Adherent;

  public name: string;
  public id_agency: string;
  public adherent_code: string;
  public address: Address;
  public lastname_manager: string;
  public firstname_manager: string;
  public mail_manager: string;
  public lastname_industrial_manager: string;
  public firstname_industrial_manager: string;
  public mail_industrial_manager: string;
  public phone: string;
  public fax: string;
  public latitude: number;
  public longitude: number;
  public warehouse: boolean;
  public online_payment: boolean;
  public settlement_payment: boolean;
  public agency_payment: boolean;
  public deactivate_appointment: boolean;
  public deactivate_quote_requests: boolean;
  public deactivate_orders: boolean;
  public deactivate_contact: boolean;
  public display: boolean;
  public intervention_emails: string;
  public accessibility_counter: boolean;
  public accessibility_toilet: boolean;
  public accessibility_building: boolean;
  public accessibility_parking: boolean;
  public accessibility_wheelchairs_slots: boolean;
  public social_reason: string;
  public b2b_only: boolean;
  public physical_agency: boolean;
  public pack_phone_plus: boolean;
  public screen_memo_quantity: number;
  public delivery_status: boolean;
  public erp: string;
  public wincho_tires: number;
  public advertisement_tires: number;
  public operation_sheet: number;
  public wincho_ema: number;
  public advertisement_ema: number;
  public advertisement_a4_ema: number;
  public permanent_4x3: number;
  public operation_sheet_ema: number;
  public quantity_masts: number;
  public quantity_bays: number;
  public quantity_bridges: number;
  public quantity_vi_interventions: number;
  public ref_epyx: number;
  public date_epyx: Date;
  public vat_number: string;
  public siret: string;
  public is_client_base: boolean;
  public payment_visa: boolean;
  public payment_mastercard: boolean;
  public payment_american_express: boolean;
  public payment_mobile_nfc: boolean;
  public payment_check: boolean;
  public has_protective_wall: boolean;
  op_tire_easel: number;
  op_ema_easel: number;
}
