<app-topbar moduleSlug="meetings">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a>Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/meetings']">Salons</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/meetings/questionnaires']">Questionnaires</a></li>
        <ng-container *ngIf="(editMode.active && editMode.data); else navigateElse">
          <li class="breadcrumb-item active"><a href="javascript:;">Édition du questionnaire <span
                v-if="editMode.data.number">{{editMode.data.id}}</span></a></li>
        </ng-container>
        <ng-template #navigateElse>
          <li class="breadcrumb-item active"><a href="javascript:;">Création d'un nouveau questionnaire</a></li>
        </ng-template>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<form class="row" *ngIf="editFormGroup" [formGroup]="editFormGroup" (keydown.enter)="$event.preventDefault()">

  <section class="card col-md-12">
    <div class="card-header card-header-rose card-header-icon">
      <div class="card-icon">
        <i class="material-icons">assignment</i>
      </div>
      <ng-container *ngIf="(editMode.active && editMode.data.id); else h4else">
        <h4 class="card-title">Édition du questionnaire {{ editMode.data.id }}</h4>
      </ng-container>
      <ng-template #h4else>
        <h4 class="card-title">Création d'un nouveau questionnaire</h4>
      </ng-template>
      <p class="text-danger">Veuillez remplir les champs obligatoires identifiés par une asterisque rouge (*)</p>

    </div>

    <div class="card-body">
      <h1>Informations générales</h1>
      <p class="col-lg-4 col-md-12 form-group is-required"
        [ngClass]="{ 'has-danger': submitted && f.name.errors }">
        <label class="bmd-label-floating">Titre</label>
        <input type="text" required class="form-control" id="title" formControlName="name">
        <label class="error" for="title" *ngIf="submitted && f.name.errors">
          Champ obligatoire.
        </label>
      </p>
      <p class="col-lg-4 col-md-12 form-group is-required"
        [ngClass]="{ 'has-danger': submitted && f.show_name.errors }">
        <label class="bmd-label-floating">Salon</label>
        <input type="text" required class="form-control" id="salon" formControlName="show_name">
        <label class="error" for="title" *ngIf="submitted && f.show_name.errors">
          Champ obligatoire.
        </label>
      </p>
      <p class="col-lg-4 col-md-12 form-group is-required"
        [ngClass]="{ 'has-danger': submitted && f.for_year.errors }">
        <label class="bmd-label-floating">Année du salon</label>
        <input type="text" minlength="4" maxlength="4" NumbersOnly [allowDecimals]="false" [allowSign]="false"
          class="form-control" id="for_year" formControlName="for_year">
        <label class="error" for="title" *ngIf="submitted && f.for_year.errors">
          Champ obligatoire.
        </label>
      </p>
      <p class="col-lg-4 col-md-12 form-group is-required"
        [ngClass]="{ 'has-danger': submitted && f.start_at.errors }">
        <label class="bmd-label-floating">Date de début</label>
        <input type="date" required class="form-control" id="start_at" formControlName="start_at">
        <label class="error" for="start_at" *ngIf="submitted && f.start_at.errors">
          Champ obligatoire.
        </label>
      </p>
      <p class="col-lg-4 col-md-12 form-group is-required"
        [ngClass]="{ 'has-danger': submitted && f.end_at.errors }">
        <label class="bmd-label-floating">Date de fin</label>
        <input type="date" required class="form-control" id="end_at" formControlName="end_at">
        <label class="error" for="end_at" *ngIf="submitted && f.end_at.errors">
          Champ obligatoire.
        </label>
      </p>
      <small>Remarque : saisir la même date s'il s'agit d'un salon sur un seul jour.</small>

      <br><br>

      <h1 class="col-12">Champs paramétrables</h1>
      <div class="row">
        <div class="col-6">
          <h2>Champs "Société"</h2>
          <ng-container *ngFor="let checkbox of custom_company">
            <!--FIELD CHECKBOX-->
            <div class="form-check">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" name="contact_infos" [value]="checkbox.slug"
                  [checked]="testFieldsPresence(checkbox.slug, 'society_fields') !== -1"
                  (change)="onChangeFields($event.target, 'society_fields')">
                {{checkbox.label}}
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>
            <!--END FIELD CHECKBOX-->
            <!--REQUIRED RADIO-->
            <ng-container *ngIf="testFieldsPresence(checkbox.slug, 'society_fields') !== -1">
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [name]="'society_fields-'+checkbox.slug+'-required'"
                    [value]="false" [checked]="!testFieldsRequired(checkbox.slug, 'society_fields')"
                    (change)="onChangeFieldRequired(checkbox.slug, false, 'society_fields')">
                  Optionnelle
                  <span class="circle">
                    <span class="check"></span>
                  </span>
                </label>
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [name]="'society_fields-'+checkbox.slug+'-required'"
                    [value]="true" [checked]="testFieldsRequired(checkbox.slug, 'society_fields')"
                    (change)="onChangeFieldRequired(checkbox.slug, true, 'society_fields')"> Obligatoire
                  <span class="circle">
                    <span class="check"></span>
                  </span>
                </label>
              </div>
            </ng-container>
            <!--END REQUIRED RADIO-->
            <!--OPTIONS -->
            <ng-container *ngIf="testFieldsPresence(checkbox.slug, 'society_fields') !== -1 &&
            f.society_fields.value[testFieldsPresence(checkbox.slug, 'society_fields')].defined_options">
              <div class="col-lg-8 col-md-12 form-check">
                <label class="form-check-label">
                  Valeurs possibles :
                </label>
                <!--values tags-->
                <div class="bootstrap-tagsinput info-badge">
                  <ng-container *ngFor="let item of f.society_fields.value[testFieldsPresence(checkbox.slug, 'society_fields')].defined_options; let i = index">
                    <span class="tag badge">
                      <span contenteditable="true" (focusout)="onEditOption('society_fields', testFieldsPresence(checkbox.slug, 'society_fields'), i, $event.target.innerText)">{{item}}</span>
                      <span data-role="remove" (click)="onRemoveOption('society_fields', testFieldsPresence(checkbox.slug, 'society_fields'), i)"></span>
                    </span>
                  </ng-container>
                </div>
                <!--end values tags-->
                <input type="text" class="form-control" id="values"
                  (keyup.enter)="onAddOption('society_fields', testFieldsPresence(checkbox.slug, 'society_fields'), $event)">
              </div>
            </ng-container>
            <!--END OPTIONS-->
          </ng-container>
        </div>

        <div class="col-6">
          <h2>Champs "Contact"</h2>
          <ng-container *ngFor="let checkbox of custom_contact">
            <!--FIELD CHECKBOX-->
            <div class="form-check">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" name="contact_infos" [value]="checkbox.slug"
                  [checked]="testFieldsPresence(checkbox.slug, 'contact_fields') !== -1"
                  (change)="onChangeFields($event.target, 'contact_fields')">
                {{checkbox.label}}
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>
            <!--END FIELD CHECKBOX-->

            <!--REQUIRED RADIO-->
            <ng-container *ngIf="testFieldsPresence(checkbox.slug, 'contact_fields') !== -1">
              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [name]="'contact_fields-' + checkbox.slug + '-required'"
                    [value]="false" [checked]="!testFieldsRequired(checkbox.slug, 'contact_fields')"
                    (change)="onChangeFieldRequired(checkbox.slug, false, 'contact_fields')">
                  Optionnelle
                  <span class="circle">
                    <span class="check"></span>
                  </span>
                </label>
                <label class="form-check-label">
                  <input class="form-check-input" type="radio" [name]="'contact_fields-' + checkbox.slug + '-required'"
                    [value]="true" [checked]="testFieldsRequired(checkbox.slug, 'contact_fields')"
                    (change)="onChangeFieldRequired(checkbox.slug, true, 'contact_fields')"> Obligatoire
                  <span class="circle">
                    <span class="check"></span>
                  </span>
                </label>
              </div>
            </ng-container>
            <!--END REQUIRED RADIO-->
          </ng-container>
        </div>
      </div>

      <h1 class="col-12">Rubriques et questions</h1>
      <div class="row">
        <div class="col-12">
          <div>

            <!-- Liste des rubriques existantes -->
            <div cdkDropList class="dragAndDrop-list"  (cdkDropListDropped)="drop($event)">
              <div class="dragAndDrop-box" *ngFor="let item of f.rubrics.value" cdkDrag>
                <div class="dragAndDrop-handle" cdkDragHandle>
                  <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </div>
                <div class="dragAndDrop-custom-placeholder" *cdkDragPlaceholder></div>
                <ng-container *ngIf="rubricEdition.uuid !== item.uuid">
                  <div class="pp-rubrique-content">
                    {{item.name}}
                  </div>
                  <div>
                    <a href="#" class="ppp-rubrics-link" (click)="$event.preventDefault(); rubricEdition.active = true; rubricEdition.uuid = item.uuid">
                      éditer
                    </a>
                    |
                    <a href="#" class="ppp-rubrics-link" (click)="$event.preventDefault(); onDeleteRubric(item.uuid)">
                      supprimer
                    </a>
                  </div>
                </ng-container>
                <app-rubric-form [rubric]="item" *ngIf="rubricEdition.active && rubricEdition.uuid === item.uuid"
                                 (edited)="onRubricEdited($event)" (canceled)="rubricEdition.active = false; rubricEdition.uuid = null" style="width: 100%; padding: 2em">
                </app-rubric-form>
              </div>
            </div>

            <!-- Ajouter une nouvelle rubrique -->
            <div class="rubrique col-lg-6 col-md-12">
              <a href="#" *ngIf="rubricEdition.uuid !== null || !rubricEdition.active"
                class="ppp-button ppp-button-black btn btn-primary btn-round"
                (click)="$event.preventDefault(); rubricEdition.active = true; rubricEdition.uuid = null">
                Créer une nouvelle rubrique
              </a>
              <app-rubric-form *ngIf="rubricEdition.active && !rubricEdition.uuid" (edited)="onRubricEdited($event)"
                (canceled)="rubricEdition.active = false; rubricEdition.uuid = null">
              </app-rubric-form>
            </div>

          </div>
        </div>
      </div>

    </div>
  </section>

  <div class="col-12">
    <a [routerLink]="['/meetings/questionnaires']" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
      Retour
    </a>
    <button type="submit" class="ppp-button ppp-button-blue btn btn-primary btn-round"
      [disabled]="!f.valid && !checkIfFieldWithOptionsAreCompleted()"
      *ngIf="!loadingService.loading; else templateSubmit"
      (click)="onSubmit()">{{editMode.active ? 'Mettre à jour' : 'Créer'}}</button>
    <ng-template #templateSubmit>
      <button disabled="true" class="ppp-button ppp-button-black btn btn-primary btn-round"
        (click)="$event.preventDefault()">
        ...
      </button>
    </ng-template>
  </div>
</form>
