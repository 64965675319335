import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {LoadingService} from '../../../../services/loading.service';
import config from '../../../../config';
import {AgenciesService} from '../../../../services/agencies.service';
import {FormsService} from "../../../../services/forms.service";
import {AlertService} from "../../../../services/alert.service";
import {Form} from "../../../../models/Form";

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public loading: boolean = false;
  public editMode: {
    active: boolean,
    data: any
  } = {active: false, data: null};
  public adherentsList: Array<any> = [];
  public selectedAdherentsList: Array<any> = [];
  public submitted: boolean = false;
  public editFormGroup: UntypedFormGroup = null;
  public ckEditorConfig = config.ckEditor;
  public customFields = [
    { slug: 'last_name', label: 'Nom', type: "text", step: "infos", universe: ['b2c', 'b2b-vl', 'b2b-pl', 'b2b-ag'] },
    { slug: 'first_name', label: 'Prénom', type: "text", step: "infos", universe: ['b2c', 'b2b-vl', 'b2b-pl', 'b2b-ag'] },
    { slug: 'email', label: 'Email', type: "email", step: "infos", universe: ['b2c', 'b2b-vl', 'b2b-pl', 'b2b-ag'] }, // B2C
    { slug: 'phone', label: 'Téléphone', type: "phone", step: "infos", universe: ['b2c', 'b2b-vl', 'b2b-pl', 'b2b-ag'] }, // B2C
    { slug: 'social_reason', label: 'Raison sociale', type: "text", step: "infos", universe: ['b2b-vl', 'b2b-pl', 'b2b-ag'] }, // B2B
    { slug: 'address', label: 'Adresse', type: "text", step: "infos", universe: ['b2c', 'b2b-vl', 'b2b-pl', 'b2b-ag'] },
    { slug: 'zip_code', label: 'Code postal', type: "text", step: "infos", universe: ['b2c', 'b2b-vl', 'b2b-pl', 'b2b-ag'] },
    { slug: 'city', label: 'Ville', type: "text", step: "infos", universe: ['b2c', 'b2b-vl', 'b2b-pl', 'b2b-ag'] },
    { slug: 'vehicles_number', label: 'Nombre de véhicules', type: "number", step: "vehicule", universe: ['b2b-vl', 'b2b-pl', 'b2b-ag'] }, // B2B
    { slug: 'registration', label: 'Immatriculation', type: "text", step: "vehicule", universe: ['b2c', 'b2b-vl', 'b2b-pl', 'b2b-ag'] },
    { slug: 'mileage', label: 'Kilométrage', type: "text", step: "vehicule", universe: ['b2c', 'b2b-vl', 'b2b-pl', 'b2b-ag'] },
    { slug: 'custom_field', label: 'Champ libre', type: "text", step: "infos", universe: ['b2c', 'b2b-vl', 'b2b-pl', 'b2b-ag'] }
  ];


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public loadingService: LoadingService,
    private agenciesService: AgenciesService,
    private alertService: AlertService,
    private formsService: FormsService) {

    if (this.route.snapshot.params.id) { // EDIT
      this.editMode.active = this.route.snapshot.url[this.route.snapshot.url.length - 1].path !== 'clone' ? true : false;
      this.formsService.getById(this.route.snapshot.params.id)
        .then((response) => {
          this.editMode.data = response;
          this.editFormGroup = this.formBuilder.group(this.getNormalForm(this.editMode.data));
          if (this.route.snapshot.url[this.route.snapshot.url.length - 1].path === 'clone') {
            const title = this.editFormGroup.controls.title.value + " (clone)"
            this.editFormGroup.controls.title.patchValue(title);
          }
        })
        .catch((error) => {
          this.alertService.error(error);
        });
    } else {
      this.editFormGroup = this.formBuilder.group(this.getNormalForm(null));
    }
  }

  get f() {
    return this.editFormGroup.controls;
  }

  ngOnInit(): void {

  }

  private setSubscribeForm() {
    this.editFormGroup.get('title').valueChanges.subscribe((field) => {
      const default_title = this.editFormGroup.value.title ? this.editFormGroup.value.title : "{{form_type_name}}"
      const new_agency_subject = this.editFormGroup.controls.agency_mail_subject.value.replace(default_title, field)
      this.editFormGroup.controls.agency_mail_subject.patchValue(new_agency_subject, field)
      const new_client_subject = this.editFormGroup.controls.client_mail_subject.value.replace(default_title, field)
      this.editFormGroup.controls.client_mail_subject.patchValue(new_client_subject, field)
    });
  }

  private getNormalForm(data?: Form): any {
    return {
      title: [data ? data.title : null , Validators.required],
      slug: [data ? data.slug : null , Validators.required],
      start_at: [data ? new Date(data.start_at).toISOString().split("T")[0] : null , Validators.required],
      end_at: [data ? new Date(data.end_at).toISOString().split("T")[0] : null , Validators.required],
      universe: [data ? data.universe : 'b2c' , Validators.required],
      form_type: [data ? this.editMode.data.form_type : 'allopneus' , Validators.required],
      banner: [null , null],
      top_form_content: [data ? this.editMode.data.top_form_content : null , null],
      bottom_form_content: [data ? this.editMode.data.bottom_form_content : null , null],
      custom_fields: [data ? JSON.parse(this.editMode.data.custom_fields) : [] , null],
      adherents: [data ? this.editMode.data.adherents : [] , Validators.required],
      agency_mail_subject: [data && this.editMode.data.agency_mail_subject ? this.editMode.data.agency_mail_subject : '' , null],
      client_mail_subject: [data && this.editMode.data.client_mail_subject ? this.editMode.data.client_mail_subject : '' , null]
    };
  }

  ngAfterContentInit() {
    this.agenciesService.getAdherents()
      .then((response: Array<any>) => {
        this.adherentsList = response;
      });
  }

  onSubmit() {
    this.submitted = true;
    const form = this.editFormGroup.value;
    if (this.editMode.active) {
      form.id = this.route.snapshot.params.id;
    }

    // Reorder the custom fields with the right order
    const fields_ordered = [];
    this.customFields.forEach((field, index) => {
      let f = form.custom_fields.find((f) => f.slug === field.slug);
      if (f) {
        fields_ordered.push(f);
      }
    });

    form.custom_fields = fields_ordered;

    this.formsService.edit(form)
      .then((response: any) => {
        this.router.navigate(['/website/profilplus-forms/edit', response.id]);
        this.alertService.success((this.editMode && this.editMode.active) ? 'Modification enregistrée avec succès.' : 'Questionnaire créé avec succès');
      }).catch((err) => {
        this.alertService.error(err);
      });
  }

  addAdherent(adherent: Array<any>) {
    const arr: any[] = this.f.adherents.value;
    arr.push(adherent);
    this.editFormGroup.controls.adherents.patchValue(arr);
  }

  removeAdherent(index: number) {
    const arr: Array<string> = this.f.adherents.value;
    if (arr[index]) {
      arr.splice(index, 1);
    }
    this.editFormGroup.patchValue({ adherents: arr });
  }

  onFileSelected(files: Array<File>) {
    this.editFormGroup.patchValue({banner: files ? files[0] : ''});
    if (!files && this.editMode.data) {
      this.editMode.data.banner = null;
    }
  }

  checkIfIsDisplayByUniverse(field) {
    return field.universe && field.universe.includes(this.f.universe.value);
  }

  // REMOVE
  findField(fieldSlug: string) {
    return this.f.custom_fields.value.find(c => c.slug === fieldSlug);
  }

  onChangeFields(e: HTMLInputElement, field: string): void {
    const arr: Array<any> = this.f.custom_fields.value;
    const index = arr.findIndex(item => item.slug === e.value);
    // field_infos
    const field_infos = this.customFields.find(f => f.slug === e.value);

    if (e.checked) {
      if (index === -1) {
        const obj: {slug: string, required: boolean, type: string, step: string, label?: string} = { slug: e.value, required: false,
          type: field_infos.type, step: field_infos.step };

          if (field_infos.slug !== 'custom_field') {
            obj.label = field_infos.label
          }
        arr.push(obj);
      }
    } else {
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    this.editFormGroup.controls.custom_fields.patchValue(arr);
  }

  onChangeFieldRequired(radio: boolean, slug: string) {
    const arr: Array<any> = this.f.custom_fields.value;
    const currentCheckbox = arr.find(c => c.slug === slug);
    currentCheckbox.required = radio;
    this.editFormGroup.controls.custom_fields.patchValue(arr);
  }

  onUpdateLabel(value, slug) {
    const arr: Array<any> = this.f.custom_fields.value;
    const field = arr.find(c => c.slug === slug);

    if (field) {
      field.label = value
    }

    this.editFormGroup.controls.custom_fields.patchValue(arr);
  }
}
