import { Injectable } from '@angular/core';

import 'bootstrap-notify';
import { ApiService } from './api.service';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';
import { BrandQuestion } from '../models/BrandQuestion';

@Injectable({ providedIn: 'root' })
export class BrandQuestionsService {
  constructor(private apiService: ApiService) { }

  getAll(type) {
    return new Promise((resolve, reject) => {
      this.apiService.get('questions?address_type=' + type)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllWithPagination(page: string | number, type: string, sortBy: { field: string, order: string } = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('questions?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '')
        + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id: number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('questions/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  delete(id: number) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('questions/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(question: BrandQuestion) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('question:title', question.title ? question.title : '');
      formData.append('question:rank', question.rank ? question.rank.toString() : '');
      formData.append('question:content', question.content ? question.content : '');
      formData.append('question:brand_id', question.brand_id ? question.brand_id.toString() : '');

      if (question.id) {
        this.apiService.put('questions/' + question.id, formData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      } else {
        this.apiService.post('questions', formData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }

}
