<div *ngIf="limit === null || files.length < limit" class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop
  (fileDropped)="uploadFile($event)">
  <label for=""><strong>Déposer</strong> un fichier ici ou <strong>cliquer</strong> pour le rechercher</label>
  <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)"
    [multiple]="(limit > 1) ? 'true' : 'false'">
</div>
<div class="files-list" *ngFor="let file of files;let i= index">
  <p class="files-list-preview">
    <ng-container *ngIf="previews[file.name] && previews[file.name].type === 'image'; else elseType">
      <img [src]="previews[file.name].preview" width="100%" [alt]="previews[file.name].alt">
    </ng-container>
    <ng-template #elseType>
      {{ file.name }}
    </ng-template>
  </p>
  <button type="button" class="btn btn-primary ppp-button ppp-button-blue" (click)="deleteAttachment(i)">
    <span class="material-icons delete-file">
      close
    </span>
  </button>
</div>
<ng-container *ngIf="list">
  <div class="files-list" *ngFor="let file of list;let i= index">
    <p class="files-list-preview">
      <ng-container *ngIf="preview; else elsePreview">
        <img [src]="file.link" [alt]="file.name" />
      </ng-container>
      <ng-template #elsePreview>
        {{ file.name }}
      </ng-template>
    </p>
    <button *ngIf="!removeDeleteAction" type="button" class="btn btn-primary ppp-button ppp-button-blue"
      (click)="deleteListedFile(i)">
      <span class="material-icons delete-file">
        close
      </span>
    </button>
    <ng-container *ngIf="!preview">
      <button type="button" *ngIf="!downloadByLink; else elseDownload"
        class="btn btn-primary ppp-button ppp-button-blue" (click)="dowloadAttachment(i)">
        <span class="material-icons delete-file">
          cloud_download
        </span>
      </button>
      <ng-template #elseDownload>
        <a [href]="file.link" class="btn btn-primary ppp-button ppp-button-blue" [download]="file.name">
          <span class="material-icons delete-file">
            cloud_download
          </span>
        </a>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
<hr>