<app-topbar moduleSlug="adherents-companies-management">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/adherents-companies-management']">Adhérents / Sociétés</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/adherents-companies-management/companies']">Liste des sociétés</a></li>
        <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
          <li class="breadcrumb-item active"><a href="javascript:;">Éditer une société</a></li>
        </ng-container>
        <ng-template #templateElse>
          <li class="breadcrumb-item active"><a href="javascript:;">Créer une nouvelle société</a></li>
        </ng-template>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<ng-container *ngIf="editFormGroup">
  <div class="row" *ngIf="!editMode.active || (editMode.active && editMode.data)">
    <div class="col-lg-12">
      <form class="form" [formGroup]="editFormGroup">

        <div class="card">
          <!-- HEADER -->
          <div class="card-header card-header-rose card-header-icon">
            <div class="card-icon">
              <i class="material-icons">domain</i>
            </div>
            <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
              <h4 class="card-title">Édition d'une société : <strong class="description">{{editMode.data.name}}</strong></h4>
            </ng-container>
            <ng-template #templateElse>
              <h4 class="card-title">Création d'une nouvelle société</h4>
            </ng-template>
          </div>

          <!-- MAIN CONTENT -->
          <div class="card-body">

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.name.errors }">
                <label for="company_name" class="bmd-label-floating">Nom</label>
                <input type="text" class="form-control" id="company_name" formControlName="name">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.mail.errors }">
                <label for="company_mail" class="bmd-label-floating">Email</label>
                <input type="email" class="form-control" id="company_mail" formControlName="mail">
                <label class="error" for="company_mail" *ngIf="f.mail.errors && f.mail.errors.email">Veuillez entrer un email valide.</label>
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.phone_number.errors }">
                <label for="company_phone_number" class="bmd-label-floating">Téléphone fixe</label>
                <input type="text" class="form-control" id="company_phone_number" formControlName="phone_number">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.phone_number2.errors }">
                <label for="company_phone_number2" class="bmd-label-floating">Téléphone mobile</label>
                <input type="text" class="form-control" id="company_phone_number2" formControlName="phone_number2">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.siret.errors }">
                <label for="company_siret" class="bmd-label-floating">SIRET</label>
                <input type="text" class="form-control" id="company_siret" formControlName="siret">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.rib.errors }">
                <label for="company_rib" class="bmd-label-floating">RIB</label>
                <input type="text" class="form-control" id="company_rib" formControlName="rib">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group" [ngClass]="{ 'has-danger': submitted && f.vat_number.errors }">
                <label for="company_vat_number" class="bmd-label-floating">Numéro TVA</label>
                <input type="text" class="form-control" id="company_vat_number" formControlName="vat_number">
              </p>
            </div>

            <div class="col-lg-4 col-md-12" style="padding:0;">
              <div class="form-group is-required" [ngClass]="{'has-danger': submitted && editFormGroup.value.adherent_id === null}">
                <app-datalist *ngIf="adherents"
                  [items]="adherents"
                  [preselect]="preselectAdherents"
                  [property]="'name'"
                  label="Adhérent"
                  (selected)="onSelectAdherent($event)"
                  (typed)=checkValue($event)
                >
                </app-datalist>
              </div>
            </div>

            <hr style="border-color:transparent;">

            <!-- NAVIGATION LINKS -->
            <a [routerLink]="['/adherents-companies-management/societies']" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
              Retour
            </a>
            <button *ngIf="!loadingService.loading; else templateSubmit"
              [disabled]="editFormGroup.invalid"
              class="ppp-button ppp-button-black btn btn-primary btn-round"
              (click)="onSubmit()">
              <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
              <ng-template #creationTemplateText>Créer</ng-template>
            </button>
            <ng-template #templateSubmit>
              <button
                disabled="true"
                class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                ...
              </button>
            </ng-template>
          </div>

        </div>

      </form>
    </div>
  </div>
</ng-container>
