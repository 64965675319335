<ng-container *ngIf="!loading; else templateLoading">

  <app-topbar moduleSlug="profilplus-agences">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences']">Profil Plus Agences</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences/open-agencies']">Liste des agences ouvertes</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences/open-agencies/edit', editMode.data.id]">Agence {{editMode.data.name}}</a></li>
          <li class="breadcrumb-item active"><a href="javascript:;">Paramètres avancés</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <ng-container *ngIf="editFormGroup">
    <div class="ppp-agency-edition row">
      <div class="col-lg-12">
        <form class="form" [formGroup]="editFormGroup">
          <div class="card">

            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">settings</i>
              </div>
              <h4 class="card-title">Paramètres avancés</h4>
            </div>

            <div class="card-body">
              <!-- BUTTONS -->
              <div *ngIf="editMode.active && editMode.data">
                <a [routerLink]="['/profilplus-agences/open-agencies/edit/', editMode.data.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">store</i> Fiche agence
                </a>
                <a [routerLink]="['/profilplus-agences/open-agencies/edit/skills/', editMode.data.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">build</i> Métiers
                </a>
                <a [routerLink]="['/profilplus-agences/open-agencies/edit/services/', editMode.data.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">playlist_add_check</i> Services
                </a>
                <a [routerLink]="['/profilplus-agences/open-agencies/edit/close-dates/', editMode.data.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">report</i> Dates fermeture
                </a>
                <a [routerLink]="['/profilplus-agences/open-agencies/edit/opening-times/store/', editMode.data.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">store</i> Horaires magasin
                </a>
                <a [routerLink]="['/profilplus-agences/open-agencies/correspondants/edit/', editMode.data.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">people</i> Correspondants
                </a>
                <a *ngIf="user.is_admin && router.url.split('/').includes('open-agencies')"
                    [routerLink]="['/profilplus-agences/open-agencies/edit/settings', editMode.data.id]"
                    type="button"
                    class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
                  <i class="material-icons">settings</i> Paramètres avancés
                </a>
              </div>

              <h3>Fermeture définitive de l'agence <span class="description">{{editMode.data.name}}</span></h3>

              <div class="col-lg-6 col-md-12" style="padding:0;">
                <div class="form-group is-required" [ngClass]="{'has-danger': submitted && editFormGroup.value.agency === null}">
                  <app-datalist *ngIf="agencies"
                    [items]="agencies"
                    [property]="'name'"
                    label="Agence de destination, vers laquelle rattacher les données clients MyProfil+ de l'agence actuelle"
                    (selected)="onSelectAgency($event)"
                    (typed)=checkValue($event)
                  >
                  </app-datalist>
                </div>

                <br>

                <!-- TODO 1 : faire en sorte que ce bouton soit cliquable seulement lorsqu'une agence est choisie au dessus -->
                <!-- TODO 2 : au clic, affichage de la modale et tout ce qui va avec... (vérif avec mot "SUPPRIMER")-->
                <button [disabled]="editFormGroup.invalid" *ngIf="!loadingService.loading; else templateSubmit"
                  class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="onSubmit()">
                  Supprimer définitivement cette agence
                </button>
                <ng-template #templateSubmit>
                  <button disabled="true" class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                    ...
                  </button>
                </ng-template>
              </div>

              <br>
              <div>À la fermeture de cette agence, les actions suivantes s'exécuteront automatiquement :</div>

              <ul>
                <li><strong class="description">Suppression de la visibilité de l'agence : </strong>l'agence sera mise hors ligne et ne sera plus affichée sur le site Profil Plus ;</li>
                <li><strong class="description">Archivage de l'agence : </strong>l'agence restera accessible via l'onglet "Agences fermées", qui référence toutes les agences fermées ;</li>
                <li><strong class="description">Exclusion de l'agence dans les différents exports : </strong>l'agence fermée n'apparaîtra plus dans les exports agences, exports PLV et exports ADV. Néanmoins, elle sera disponible dans l'export des agences fermées ;</li>
                <li><strong class="description">Rattachement des données clients MyP+ : </strong>les données clients MyProfil+ de l'agence fermée seront automatiquement transférées vers l'agence de destination sélectionnée ;</li>
                <li><strong class="description">Envoi d'un email de confirmation aux personnes concernées : </strong>une fois l'opération terminée, un email sera automatiquement envoyé à Julien SOMA, Yassin EL BARNOUSSI et Aurore MARCEAU pour confirmer la bonne fermeture de cette agence ;</li>
              </ul>

              <a [routerLink]="['/profilplus-agences/open-agencies']"
                  type="button"
                  class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
                Retour à la liste
              </a>

            </div>

          </div>
        </form>
      </div>
    </div>
  </ng-container>

</ng-container>

<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
