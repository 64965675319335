import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { AgenciesService } from 'src/app/services/agencies.service';

import { Agency } from 'src/app/models/Agency';
import { DatalistComponent } from 'src/app/components/datalist/datalist.component';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-edit-mailer-slugs',
  templateUrl: './edit-mailer-slugs.component.html',
  styleUrls: ['./edit-mailer-slugs.component.scss']
})
export class EditMailerSlugsComponent implements OnInit {
  public agency: Agency = null;
  public loading: boolean = false;
  public editFormGroup: UntypedFormGroup;
  public submitted: Boolean = false;
  public currentCorrespondantData: any;

  public mailerSlugs = [];

  @ViewChild('datalist') datalist: DatalistComponent;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private enumsService: EnumsService,
    private alertService: AlertService,
    private agenciesService: AgenciesService) {}

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        if (this.router.url.split('/').includes('open-agencies')) {
          this.agency = this.enumsService.enums.agencies.find(a => a.id === parseInt(this.route.snapshot.params.agency_id));
        } else {
          this.agency = this.enumsService.enums.agencies_closed.find(a => a.id === parseInt(this.route.snapshot.params.agency_id));
        }
        // CORRESPONDANTS DATA
        this.agenciesService.getCorrespondant(this.route.snapshot.params.agency_id, this.route.snapshot.params.correspondant_id)
          .then((response: any) => {
            this.currentCorrespondantData = response;
            this.loading = false;
            this.editFormGroup = this.formBuilder.group({
              mails: [[], null],
              new_email: ['', Validators.required]
            });

            this.agenciesService.getCorrespondantMailSlug(this.agency.id, this.currentCorrespondantData.id)
              .then((res: any) => {
                this.editFormGroup.patchValue({mails: res});

                // GET MAILSLUG
                this.agenciesService.getAllMailSlug()
                  .then((slugs: any) => {
                    this.mailerSlugs = slugs;
                  })
                  .catch((err) => {

                  });

              })
              .catch((err) => {
                this.alertService.error(err);
              });


          })
          .catch((err) => {
            this.alertService.error(err);
            this.loading = false;
          });
      }
    });
  }

  get f() {
    return this.editFormGroup.controls;
  }

  get filteredSlug() {
    return this.mailerSlugs.filter(slug => this.editFormGroup.value.mails.findIndex(r => r.id === slug.id) === -1 );
  }

  onNewEmail($event) {
    this.editFormGroup.patchValue({new_email: $event});
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    this.agenciesService
    .addCorrespondantMailSlugs(this.agency.id, this.currentCorrespondantData.id, this.editFormGroup.value.new_email.id)
      .then(() => {
        let mails = this.editFormGroup.value.mails;
        mails.push(this.editFormGroup.value.new_email);
        this.editFormGroup.patchValue({mails});
        this.alertService.success('Nouvel email rattaché au correspondant');
        this.datalist.searchField = '';
      })
      .then((err: any) => {
        this.alertService.error(err);
      });
  }

  onDelete($event) {
    this.agenciesService.deleteCorrespondantMailSlug(this.agency.id, this.currentCorrespondantData.id, $event).then(() => {
      this.alertService.success('Email supprimé de ce correspondant.');
      const mails = this.editFormGroup.value.mails;
      const index = mails.findIndex(m => m.id === $event);
      if (index !== -1) {
        mails.splice(index, 1);
        this.editFormGroup.patchValue({mails});
      }
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

}
