<ng-container *ngIf="!loading; else templateLoading">

  <app-topbar moduleSlug="profilplus-agences">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences']">Profil Plus Agences</a></li>
          <li class="breadcrumb-item">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies'] : ['/profilplus-agences/closed-agencies']">
              Liste des agences {{ router.url.split('/').includes('open-agencies') ? 'ouvertes' : 'fermées' }}
            </a>
          </li>
          <li class="breadcrumb-item" *ngIf="agency">
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit', agency.id] : ['/profilplus-agences/closed-agencies/edit', agency.id]">
              Agence {{agency.name}}
            </a>
          </li>
          <li class="breadcrumb-item active" *ngIf="agency"><a href="javascript:;">Liste des dates de fermeture</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <div class="row">
    <div class="col-md-12">
      <a class="ppp-button ppp-button-black btn btn-primary btn-round"
        (click)="edit()">
        <i class="material-icons">library_add</i>
        Créer une nouvelle date de fermeture
      </a>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">report</i>
          </div>
          <h4 class="card-title" *ngIf="agency">Liste des dates de fermeture : <strong class="description">{{agency.name}}</strong></h4>
        </div>
        <div class="card-body">
          <!-- BUTTONS -->
          <div>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/', agency.id] : ['/profilplus-agences/closed-agencies/edit/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">store</i> Fiche agence
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/skills/', agency.id] : ['/profilplus-agences/closed-agencies/edit/skills/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">build</i> Métiers
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/services/', agency.id] : ['/profilplus-agences/closed-agencies/edit/services/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">playlist_add_check</i> Services
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/close-dates/', agency.id] : ['/profilplus-agences/closed-agencies/edit/close-dates/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">report</i> Dates fermeture
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/edit/opening-times/store/', agency.id] : ['/profilplus-agences/closed-agencies/edit/opening-times/store/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">store</i> Horaires magasin
            </a>
            <a [routerLink]="router.url.split('/').includes('open-agencies') ? ['/profilplus-agences/open-agencies/correspondants/edit/', agency.id] : ['/profilplus-agences/closed-agencies/correspondants/edit/', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">people</i> Correspondants
            </a>
            <a *ngIf="policiesService.currentUser.is_admin && router.url.split('/').includes('open-agencies')"
                [routerLink]="['/profilplus-agences/open-agencies/edit/settings', agency.id]"
                type="button"
                class="mr-2 ppp-button ppp-button-blue btn btn-primary btn-round">
              <i class="material-icons">settings</i> Paramètres avancés
            </a>
          </div>
          <p>
            <strong class="description">Remarque :</strong> Chaque année, les jours fériés annuels habituels sont automatiquement ajoutés par un script. Vous n'avez pas besoin de les indiquer.
          </p>
          <div class="table-responsive">
            <ngx-datatable
              class="material"
              [rows]="closeDates"
              [columnMode]="'standard'"
              [rowHeight]="'auto'"
              [headerHeight]="50"
              [footerHeight]="50"
              [loadingIndicator]="loadingList"
              (sort)="onSort($event)"
            >
              <ngx-datatable-column name="ID" prop="id" [width]="75">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Dénomination" prop="name" [width]="350">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Date de fermeture" prop="during" [width]="200">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value | date: 'dd/MM/yy' }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Actions" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a href="javascript:;" (click)="edit(row.id)" type="button" class="btn btn-success" title="Modifier la date de fermeture">
                    <i class="material-icons">edit</i>
                  </a>
                  <button type="button" class="btn btn-danger" (click)="onDelete(row.id)" title="Supprimer">
                    <i class="material-icons">close</i>
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-modal label="edit-close-dates" (onClose)="editFormGroup = null;" *ngIf="editFormGroup !== null">
    <ng-container title *ngIf="editFormGroup.value.id; else creationTitleText">Édition d'une date de fermeture</ng-container>
    <ng-template #creationTitleText>Création d'une nouvelle date de fermeture</ng-template>
    <ng-container content>
      <form  class="form" [formGroup]="editFormGroup">
        <p class="form-group"  [ngClass]="{ 'has-danger': submitted && f.name.errors }">
          <label for="name" class="bmd-label-floating">Nom</label>
          <input type="text" class="form-control" id="name" formControlName="name">
        </p>

        <ng-container *ngIf="!editFormGroup.value.id">
          <p class="form-group"  [ngClass]="{ 'has-danger': submitted && f.name.errors }">
            <label for="date" class="bmd-label-floating">Date de début</label>
            <input type="date" class="form-control" id="date" formControlName="during" (change)="onChangeStartAt($event)">
          </p>

          <p class="form-group"  [ngClass]="{ 'has-danger': submitted && f.name.errors }">
            <label for="date" class="bmd-label-floating">Date de fin</label>
            <input type="date" class="form-control" id="date" formControlName="during_end" (change)="onChangeEndAt($event)">
          </p>
        </ng-container>
        <button [disabled]="editFormGroup.invalid"
          class="mt-4 ppp-button ppp-button-black btn btn-primary btn-round"
          (click)="onSubmit()">
          <ng-container *ngIf="editFormGroup.value.id; else creationTemplateText">Mettre à jour</ng-container>
          <ng-template #creationTemplateText>Créer</ng-template>
        </button>
      </form>
    </ng-container>
  </app-modal>

</ng-container>

<ng-template #templateLoading>
  <app-loading></app-loading>
</ng-template>
