import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import routeChanger from '../../helpers/routeChanger';

@Component({
  selector: 'app-commercial-actions',
  templateUrl: './commercial-actions.component.html',
  styleUrls: ['./commercial-actions.component.scss']
})
export class CommercialActionsComponent implements OnInit {
  public moduleSlug: string;

  constructor(
    private router: Router) { }

  ngOnInit(): void {
    this.moduleSlug = this.router.url.split('/')[1];
  }

  ngAfterViewChecked() {
    routeChanger.viewChecked();
  }

  changeOfRoutes() {
    routeChanger.afterChange();
  }
}
