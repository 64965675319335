import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HighLightRule } from '../models/HighLightRule';

@Injectable({ providedIn: 'root' })
export class HighLightRulesService {
  constructor(private apiService: ApiService) {}

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService
        .get('highlight_rules')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id) {
    return new Promise((resolve, reject) => {
      this.apiService
        .get('highlight_rules/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(highlightRule: HighLightRule) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('highlight_rule:highlight_category_id', highlightRule.highlight_category_id.toString());
      formData.append('highlight_rule:brand_id', highlightRule.brand_id.toString());
      formData.append('highlight_rule:rank', highlightRule.rank.toString());
      formData.append('highlight_rule:price_order', highlightRule.price_order.toString());
      if (highlightRule.max_result) {
        formData.append('highlight_rule:max_result', highlightRule.max_result.toString());
      }
      formData.append('highlight_rule:exclude_brand_id', highlightRule.exclude_brand_id.toString());
      if (highlightRule.id) {
        // UPDATE
        this.apiService
          .put('highlight_rules/' + highlightRule.id, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        // CREATE
        this.apiService
          .post('highlight_rules', formData)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      this.apiService
        .delete('highlight_rules/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
