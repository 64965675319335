<script src="../activity-forms.component.ts"></script>
<app-topbar moduleSlug="activity-forms">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/activity-tracking']">Suivi d'activité TC4</a></li>
        <li class="breadcrumb-item active"><a [routerLink]="['/activity-tracking', 'activity-forms']">Formulaires de suivi</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">{{ (editMode.active) ? 'Édition' : 'Création'}} d'un nouveau formulaire de suivi</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">account_box</i>
        </div>
        <h4 class="card-title">{{ (editMode.active) ? 'Édition' : 'Création'}} d'un formulaire de suivi</h4>
      </div>
      <div class="card-body">
        <form class="form" [formGroup]="editFormGroup" *ngIf="editFormGroup && !loading">

          <div class="col-lg-4 col-md-12 mb-3 form-group is-required" style="padding:0;" *ngFor="let field of fields">
            <ng-container *ngIf="field.options == undefined || field.options && field.options.length > 1">
              <label for="actions" class="bmd-label-floating">{{field.label}}</label>
              <ng-container [ngSwitch]="field.type">
                <select *ngSwitchCase="'select'"
                  class="form-control"
                  [name]="field.slug"
                  [formControlName]="field.slug">
                  <ng-container *ngIf="field.property_to_show && field.property_to_send; else elseOption">
                    <option *ngFor="let option of field.options" [value]="option[field.property_to_send]">{{option[field.property_to_show]}}</option>
                  </ng-container>
                  <ng-template #elseOption>
                    <option *ngFor="let option of field.options" [value]="option">{{option}}</option>
                  </ng-template>
                </select>

                <input *ngSwitchDefault
                  type="number"
                  class="form-control"
                  [name]="field.slug"
                  [formControlName]="field.slug"
                  NumbersOnly
                  [allowDecimals]="true"
                  [allowSign]="true">
              </ng-container>
            </ng-container>
          </div>

          <a [routerLink]="['/activity-tracking/activity-forms']" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mt-4 mr-2">
            Retour
          </a>
          <button [disabled]="editFormGroup.invalid"  *ngIf="!loadingService.loading; else templateSubmit"
            class="ppp-button ppp-button-black btn btn-primary btn-round mt-4"
            (click)="onSubmit()">
            <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
            <ng-template #creationTemplateText>Créer</ng-template>
          </button>
          <ng-template #templateSubmit>
            <button
              disabled="true"
              class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
              ...
            </button>
          </ng-template>
        </form>
      </div>
    </div>
  </div>
</div>
