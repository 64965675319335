<ng-container *ngIf="!loading; else templateElse">
<app-topbar moduleSlug="myprofilplus">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/myprofilplus']">MyProfil+</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Statistiques</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">pie_chart</i>
        </div>
        <h4 class="card-title">Statistiques</h4>
      </div>
      <div class="card-body">
        <form class="form" [formGroup]="editFormGroup" *ngIf="editFormGroup">

          <!-- Choix de la statistique : -->
          <!-- "Fidélité générale" (0) -->
          <!-- "Fidélité par adhérent" (1) -->
          <!-- "Fidélité par agence" (2) -->
          <!-- "Échanges entre agences" (3) -->
          <!-- "Comptabilité générale" (7) -->
          <!-- "Encours fidélité" (8) -->
          <!-- "Suivi des comptes" (9) -->
          <div class="form-group">
            <div class="form-group col-lg-6">
              <p>Choisir une statistique :</p>
              <select
                (change)="onTypeSelection()"
                [(ngModel)]="selectedType" [ngModelOptions]="{standalone: true}"
                class="select-analytic"
                data-style="select-with-transition" data-size="7" tabindex="-98">
                <option [value]="null">Statistique</option>
                <option *ngFor="let type of typeList; let i = index" [value]="i">{{type.label}}</option>
              </select>
            </div>
          </div>
          <br>

          <!-- Choix de l'adhérent -->
          <!-- Statistiques concernées : "Fidélité générale" (0), "Fidélité par agence" (2), "Échanges entre agences" (3), "Comptabilité générale" (7), "Encours fidélité" (8) -->
          <div class="form-group my-4" *ngIf="selectedType && typeList[selectedType] && typeList[selectedType].value !== 1 && typeList[selectedType].value !== 9">
            <div class="form-group col-lg-6">
              <label for="adherent" >Choisir l'adhérent concerné :</label> <br>
              <select name="adherent" class="form-control"
                [(ngModel)]="adherent_selection"
                [ngModelOptions]="{standalone: true}"
                (ngModelChange)="checkingForAgenciesList()">
                <option value="all" *ngIf="typeList[selectedType].type === 'query' && typeList[selectedType].value !== 8">Tous les adhérents</option> <!-- Que pour "Comptabilité générale" (7) -->
                <option *ngFor="let adherent of adherentsList" [ngValue]="adherent.id">{{adherent.name}}</option>
              </select>
            </div>
          </div>

          <!-- Choix de l'agence -->
          <!-- Statistique concernée : "Fidélité générale" (0) -->
          <div class="form-group my-4" *ngIf="selectedType && adherent_selection !== null && typeList[selectedType] && typeList[selectedType].value === 0">
            <div class="form-group col-lg-6">
              <label for="agency">Choisir l'agence concernée par l'adhérent :</label> <br>
              <select name="agency" class="form-control" [(ngModel)]="agency_selection" [ngModelOptions]="{standalone: true}"
                (ngModelChange)="editFormGroup.patchValue({agency: [$event]})">
                <option value="all">Toutes les agences</option>
                <ng-container *ngIf="agenciesList">
                  <option *ngFor="let agency of agenciesList" [ngValue]="agency.id">{{agency.name}}</option>
                </ng-container>
              </select>
            </div>
          </div>

          <br>

          <!-- Choix des dates de début et de fin -->
          <ng-container *ngIf="selectedType && typeList[selectedType]">
            <!-- Statistiques concernées : Toutes sauf "Comptabilité générale" (7) -->
            <div class="form-group" *ngIf="typeList[selectedType].value !== 7">
              <div class="form-group col-lg-6 is-required" [ngClass]="{ 'has-danger': submitted && f.begin.errors }">
                <label for="begin" class="bmd-label-floating">Date de début</label>
                <input name="begin" type="date" class="form-control" formControlName="begin" required="true">
              </div>
            </div>
            <br>
            <!-- Statistiques concernées : Toutes -->
            <div class="form-group">
              <div class="form-group col-lg-6 is-required" [ngClass]="{ 'has-danger': submitted && f.end.errors }">
                <label for="end" class="bmd-label-floating">Date de fin</label>
                <input name="end" type="date" class="form-control" formControlName="end" required="true">
              </div>
            </div>
            <br>
          </ng-container>

          <button [disabled]="editFormGroup.invalid" *ngIf="!loadingService.loading; else templateSubmit"
            class="ppp-button ppp-button-black btn btn-primary btn-round mt-4"
            (click)="onSubmit()">
            Calculer
          </button>
          <ng-template #templateSubmit>
            <button
              disabled="true"
              class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
              ...
            </button>
          </ng-template>

        </form>
      </div>
    </div>
  </div>
</div>
</ng-container>

<ng-template #templateElse>
  <app-loading></app-loading>
</ng-template>
