<app-topbar moduleSlug="profilplus-agences">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences']">Profil Plus Agences</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Liste des métiers</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div *ngIf="policiesService.currentUser.policies.skill.create" class="row">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round"
     [routerLink]="['/profilplus-agences/skills/edit']">
      <i class="material-icons">library_add</i>
      Créer un nouveau métier
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">list</i>
        </div>
        <h4 class="card-title">Liste des métiers</h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <ngx-datatable
            class="material"
            [rows]="skills"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [loadingIndicator]="loadingList"
          >
            <ngx-datatable-column name="ID" prop="id" [width]="75">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Dénomination" prop="name" [width]="280">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="policiesService.currentUser.policies.skill.update" [routerLink]="['/profilplus-agences/skills/edit/', row.id]" type="button" class="btn btn-success" title="Modifier le métier">
                  <i class="material-icons">edit</i>
                </a>
                <button *ngIf="policiesService.currentUser.policies.skill.delete" type="button" class="btn btn-danger" (click)="onDelete(row.id)" title="Supprimer">
                  <i class="material-icons">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
