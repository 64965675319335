import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpComponent } from '../help/help.component';
import { AuthGuard } from 'src/app/helpers/auth.guard';

import { UsersComponent } from './users.component';
import { IndexContentComponent } from 'src/app/components/index-content/index-content.component';

import { ListComponent } from './list/list.component';
import { AdherentsAgenciesComponent } from './adherents-agencies/adherents-agencies.component';
import { EditComponent } from './edit/edit.component';
import { RulesComponent } from './rules/rules.component';
import { AdminsComponent } from './admins/admins.component';
import { EditComponent as AdminsEditComponent } from './admins/edit/edit.component';

const routes: Routes = [
  {
    path: 'users-management',
    canActivate: [AuthGuard],
    component: UsersComponent,
    children: [
      {
        path: '',
        component: IndexContentComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'list',
        component: ListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'edit/:id/rules',
        component: RulesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'edit/:id/adherents-agencies',
        component: AdherentsAgenciesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'edit/:id',
        component: EditComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'edit',
        component: EditComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'help',
        component: HelpComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'admins',
        canActivate: [AuthGuard],
        component: AdminsComponent
      },
      {
        path: 'admins/edit/:id',
        canActivate: [AuthGuard],
        component: AdminsEditComponent
      },
      {
        path: 'admins/edit',
        canActivate: [AuthGuard],
        component: AdminsEditComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
