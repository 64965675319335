import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import routeChanger from '../../helpers/routeChanger';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  public moduleSlug: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.moduleSlug = this.router.url.split('/')[1];
  }

  ngAfterViewChecked() {
    routeChanger.viewChecked();
  }

  changeOfRoutes() {
    routeChanger.afterChange();
  }
}
