import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AgenciesService } from 'src/app/services/agencies.service';
import { EnumsService } from 'src/app/services/enums.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';

import { Agency } from 'src/app/models/Agency';
import { User } from 'src/app/models/User';
import { LoadingService } from 'src/app/services/loading.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  public agencies: Array<any>;
  public user: User;
  public editFormGroup: UntypedFormGroup;
  public loading: Boolean = false;
  public submitted: Boolean = false;
  public editMode: any = {
    active: false,
    data: null
  };

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private enumsService: EnumsService,
    private alertService: AlertService,
    private agenciesService: AgenciesService,
    private authService: AuthService,
    public loadingService: LoadingService) {

    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        const currentAgencyId = parseInt(this.route.snapshot.params.id);
        this.agenciesService.getAll().then((responseAgencies: Array<any>) => {
          this.agencies = responseAgencies;
          if (currentAgencyId) {
            this.editMode.active = true;
            this.agenciesService.getById(currentAgencyId).then((response: Agency) => {
              const currentAgencyIndex = this.agencies.findIndex(a => a.id === currentAgencyId);
              if (currentAgencyIndex !== -1) {
                this.agencies.splice(currentAgencyIndex, 1);
              }
              this.editMode.data = response;
              this.editFormGroup = this.formBuilder.group({
                agency: [null, Validators.required]
              });
              this.loading = false;
            }).catch((err) => {
              this.alertService.error(err);
              this.loading = false;
            });
          }
        }).catch((err) => {
          this.alertService.error(err);
        });
      }
    });
  }

  ngOnInit(): void {
    if (this.authService.currentUserValue) {
      this.user = this.authService.currentUserValue;
    }
  }

  onSelectAgency($event) {
    this.editFormGroup.patchValue({agency: $event.id});
  }

  checkValue($event) {
    if ($event === '') {
      this.editFormGroup.patchValue({agency: null});
    }
  }

  ngDoCheck() {}

  get f() {
    return this.editFormGroup.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Fermeture définitive',
      text: 'Attention, pour confirmer la fermeture de cette agence Profil Plus, veuillez saisir "SUPPRIMER" et cliquer sur le bouton "Confirmer".',
      input: 'text',
      onOpen: () => {
        const input = Swal.getInput()
        input.oninput = () => {
          const btn: HTMLButtonElement = document.querySelector(".swal2-confirm")
          if (input.value !== 'SUPPRIMER') {
            btn.disabled = true;
          } else {
            btn.disabled = false;
          }
        }
      },
      inputValidator: (value) => {
        if (value !== 'SUPPRIMER') {
          return "Vous devez écrire SUPPRIMER dans le champ pour continuer."
        }
      },
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.isConfirmed) {
        this.agenciesService.close(this.route.snapshot.params.id, this.editFormGroup.value.agency).then(() => {
          this.router.navigate(['/profilplus-agences/open-agencies']);
          this.alertService.success('Fermeture définitive de l\'agence enregistrée avec succès.');
        })
        .catch((err) => {
          this.alertService.error(err);
        });
      }
    });
  }

}
