import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { EnumsService } from 'src/app/services/enums.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {
  public formVisible: boolean = false;
  public loginFormGroup: UntypedFormGroup;
  constructor(private alertService: AlertService,
              private authService: AuthService, private router: Router,
              private enumsService: EnumsService) {

  }

  ngOnInit(): void {
    this.loginFormGroup = new UntypedFormGroup({
      email: new UntypedFormControl(),
      password: new UntypedFormControl()
    });
  }

  ngAfterContentInit() {
    setTimeout(() => { // show form with animation
      this.formVisible = true;
    }, 500);
  }


  onSubmit() {
    this.authService.login(this.loginFormGroup.value.email, this.loginFormGroup.value.password)
      .then((response) => {
        this.alertService.success('Connexion avec succès', false);
        this.router.navigate(['/']).then(() => {
         this.enumsService.start(); // ADD FORCE RELOAD TO REFRESH ENUMS DATA
        });
      })
      .catch((err) => {
        this.alertService.error('L\'authentification a échoué', false);
      });
  }
}
