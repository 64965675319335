import { Component, OnInit } from '@angular/core';

import modules from '../../../assets/modules.json';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/User';
import { Router } from '@angular/router';
import { PoliciesService } from 'src/app/services/policies.service';

@Component({
  selector: 'app-index-content',
  templateUrl: './index-content.component.html',
  styleUrls: ['./index-content.component.scss']
})
export class IndexContentComponent implements OnInit {
  public modules;
  public modulesStringList;
  public user: User;
  currentModule: string = null;

  // tslint:disable-next-line: variable-name
  constructor(private authService: AuthService,
              private router: Router,
              public policiesService: PoliciesService) {
    this.currentModule =  this.router.url.split('/')[1];
    this.modules = modules;
    this.policiesService.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    if (this.authService.currentUserValue) {
      this.modules = modules;
      this.modulesStringList = Object.keys(modules);
      this.user = this.authService.currentUserValue;
    }
  }
}
