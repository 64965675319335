<app-topbar moduleSlug="website">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Configurations de rendez-vous</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round"
     [routerLink]="['/website/settings/appointment-configurations/edit']">
      <i class="material-icons">library_add</i>
      Créer une nouvelle configuration de rendez-vous
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">settings</i>
        </div>
        <h4 class="card-title">Configurations de rendez-vous</h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">

          <ngx-datatable
            class="material"
            [rows]="appointmentConfigurations"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [loadingIndicator]="loadingList"
          >
            <ngx-datatable-column name="ID" prop="id" [width]="75">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Agence" prop="agency" [width]="350">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a title="Voir l'agence" class="ppp-link" target="_blank" [routerLink]="['/profilplus-agences/edit/', value.id]"
                  *ngIf="policiesService.currentUser.policies['open-agency'] &&
                        policiesService.currentUser.policies['open-agency'].update; else agencyOnlyData">
                  {{value.name}}
                </a>
                <ng-template #agencyOnlyData>
                  {{value.name}}
                </ng-template>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Type de RDV" prop="appointment_type" [width]="150">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value.name }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Durée (min)" prop="duration" [width]="160">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Quantité" prop="ressources_count" [width]="150">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Délai min. de prise de RDV" prop="min_delay" [width]="280">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a [routerLink]="['/website/settings/appointment-configurations/edit/', row.id]" type="button" class="btn btn-success" title="Modifier la configuration de rendez-vous">
                  <i class="material-icons">edit</i>
                </a>
                <button type="button" class="btn btn-danger" title="Supprimer">
                  <i class="material-icons" (click)="onDelete(row.id)">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>

        </div>
      </div>
    </div>
  </div>
</div>
