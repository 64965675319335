<ng-container>

  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
          <li class="breadcrumb-item active"><a href="javascript:;">Liste des commandes</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <div class="row">
    <div class="col-md-12">
      <a class="ppp-button ppp-button-black btn btn-primary btn-round"
        (click)="$event.preventDefault(); export()">
        <i class="material-icons">pie_chart</i>
        Export Excel des commandes
      </a>
    </div>
  </div>


  <div class="row">
    <div class="col-md-12">

      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">add_shopping_cart</i>
          </div>
          <h4 class="card-title">Liste des commandes</h4>
        </div>

        <div class="card-body">
          <app-filter-bar [filters]="filters"
            (changed)="setPage({offset: 0}, null, $event)">
            <p *ngIf="loadingList">chargement...</p>
          </app-filter-bar>
          <div class="table-responsive">
            <ngx-datatable
              #datatable
              class="material"
              [rows]="items"
              [columnMode]="'standard'"
              [rowHeight]="'auto'"
              [headerHeight]="50"
              [footerHeight]="50"
              [externalPaging]="true"
              [externalSorting]="true"
              [loadingIndicator]="loadingList"
              [reorderable]="false"
              (page)="setPage($event)"
              (sort)="onSort($event)"
            >
              <ngx-datatable-column [resizeable]="false" name="ID" prop="id" [width]="75">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="N° d'ordre" prop="order_number" [width]="160">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ row.details.order_number }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Client" prop="customer" [width]="200">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a class="ppp-link" target="_blank" [routerLink]="['/website/customers', value.id]" *ngIf="policiesService.currentUser.policies['customer'].read; else customerOnlyData">
                    {{value.first_name ? value.first_name : ''}}
                    {{value.last_name ? value.last_name : ''}}
                  </a>
                  <ng-template #customerOnlyData>
                    {{value.first_name ? value.first_name : ''}}
                    {{value.last_name ? value.last_name : ''}}
                  </ng-template>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Agence" prop="details" [width]="200">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <ng-container *ngIf="value.appointment && value.appointment.agency">
                    <a title="Voir l'agence" class="ppp-link" target="_blank"
                      [routerLink]="['/profilplus-agences/edit/', value.appointment.agency.id]"
                      *ngIf="policiesService.currentUser.policies['open-agency']
                        && policiesService.currentUser.policies['open-agency'].update; else agencyOnlyData">
                      {{value.appointment.agency.name}}
                    </a>
                    <ng-template #agencyOnlyData>
                      {{value.appointment.agency.name}}
                    </ng-template>
                  </ng-container>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Statut" prop="status" [width]="100">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div [ngSwitch]="value">
                    <div class="ppp-orders-neutral" *ngSwitchCase="'Pending'">En attente</div>
                    <div class="ppp-orders-neutral" *ngSwitchCase="'Opened'">Ouverte</div>
                    <div class="ppp-orders-ok" *ngSwitchCase="'Validated'">Validée</div>
                    <div class="ppp-orders-ok" *ngSwitchCase="'Honored'">Honorée</div>
                    <div class="ppp-orders-nok" *ngSwitchCase="'Cancelled'">Annulée</div>
                    <div class="ppp-orders-nok" *ngSwitchCase="'Unhonored'">Non honorée</div>
                    <div *ngSwitchDefault>--</div>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Univers" prop="universe" [width]="95">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{row.universe.name}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Date du RDV" prop="appointment_time" [width]="190">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <ng-container *ngIf="row.details.appointment && row.details.appointment.start_time">
                    {{row.details.appointment.start_time[0] | date: 'EEEE dd MMMM y à HH:mm' }}
                  </ng-container>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Lien commande" prop="details" [width]="180" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <ng-container *ngIf="value">
                    <a [href]="value.customer_order_url" class="ppp-link" target="_blank">Récapitulatif</a>
                  </ng-container>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Date de création" prop="created_at" [width]="180">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value | date: 'EEEE dd MMMM y HH:mm:ss' }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Actions" [width]="100" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a [routerLink]="['/website/orders/', row.id]" type="button"
                    class="btn btn-success" title="Voir les informations de la commande">
                    <i class="material-icons">visibility</i>
                  </a>
                </ng-template>
              </ngx-datatable-column>

            </ngx-datatable>
          </div>
        </div>
      </div>

    </div>
  </div>
</ng-container>
