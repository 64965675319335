import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HighLightCategory } from 'src/app/models/HighLightCategory';
import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { HighLightCategoriesService } from 'src/app/services/highLightCategories.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public loading: boolean = false;
  public editMode: {
    active: boolean,
    data: any
  } = {active: false, data: null};
  public submitted: boolean = false;
  public editFormGroup: UntypedFormGroup;

  constructor(
    public enumsService: EnumsService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public highLightCategoriesService: HighLightCategoriesService,
    private alertService: AlertService,
    public loadingService: LoadingService) {

      this.enumsService.observable.subscribe({
        complete: () => {
          if (this.route.snapshot.params.id) { // EDIT
            this.editMode.active = true;
            this.loading = true;
            this.highLightCategoriesService.getById(this.route.snapshot.params.id)
              .then((response: HighLightCategory) => {
                this.editMode.data = response;
                this.loading = false;
                this.editFormGroup = this.formBuilder.group({
                  name: [response.name, Validators.required],
                  rank  : [response.rank , null],
                  brands: [[], null],
                });

                if (response.brands) {
                  this.editFormGroup.patchValue({brands: [...response.brands.map(x => x.id)]})
                }

              })
              .catch((err) => {
                this.loading = false;
                this.alertService.error(err);
              });
          } else {
            this.editFormGroup = this.formBuilder.group({
              name: [null, Validators.required],
              rank  : [null, null],
              brands: [[], null],
            });
          }
        }
      });
    }

  ngOnInit(): void {
  }

  get f() { return this.editFormGroup.controls; }

  get selectedBrands() {
    return this.editFormGroup.value.brands.map(x => this.enumsService.enums.brands.find(b => b.id === x))
  }

  onSelectedBrand($event) {
    if (!this.editFormGroup.value.brands.includes($event.id)) {
      this.editFormGroup.patchValue({brands:
        [...this.editFormGroup.value.brands, $event.id]})
    }
  }

  onRemoveBrand($event) {
    const index = this.editFormGroup.value.brands.findIndex(b => b === $event.id);
    if (index !== -1) {
      let values = this.editFormGroup.value.brands;
      values.splice(index, 1);
      this.editFormGroup.patchValue({brands: values});
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    const categorie: HighLightCategory = {
      ...this.editFormGroup.value,
    };

    categorie.id = this.route.snapshot.params.id;

    this.highLightCategoriesService.edit(categorie).then(() => {
      this.router.navigate(['/website/tires/highlight-categories']);
      this.alertService.success('Modification enregistrée avec succès.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

}
