import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Contact } from 'src/app/models/Contact';
import { Questionnaire } from 'src/app/models/Questionnaire';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { ContactsService } from 'src/app/services/contacts.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { QuestionnairesService } from 'src/app/services/questionnaires.service';
import { jsPDF } from 'jspdf';
import slugify from '../../../../helpers/slugify';

import questionnaire_fields from 'src/assets/questionnaire_fields.json';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public submitted: Boolean = false;
  public editFormGroup: UntypedFormGroup;
  public editFormGroupModal: UntypedFormGroup;
  public editMode: any = {
    active: false,
    data: null
  };
  public loading: Boolean = false;
  public questionnaires: Array<Questionnaire> = [];
  public questionnaire: Questionnaire = null;

  public contactFields: Array<{ slug: string, label: string, type: string, options?: any, queries?: string[] }> = questionnaire_fields.custom_contact;

  public societyFields: Array<{ slug: string, label: string, type: string, options?: any, defined_options?: boolean, queries?: string[] }> = questionnaire_fields.custom_company;
  loadingScan = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public policiesService: PoliciesService,
    private formBuilder: UntypedFormBuilder,
    private enumsService: EnumsService,
    private alertService: AlertService,
    public loadingService: LoadingService,
    private questionnairesService: QuestionnairesService,
    private contactsService: ContactsService,
    public authService: AuthService) {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        // Get Questionnaires list
        this.questionnairesService.getAll()
          .then((response: Array<Questionnaire>) => {
            this.questionnaires = response;

            // Instance form
            if (this.route.snapshot.params.contact_id) { // EDIT MODE
              this.editMode.active = true;
              this.contactsService.getById(this.route.snapshot.params.questionnaire_id, this.route.snapshot.params.contact_id)
                .then((contact: Contact) => {
                  this.loading = false;
                  this.editMode.data = contact;
                  this.editFormGroup = this.formBuilder.group(this.getNormalForm(contact));
                  this.setUpForm();
                  this.editFormGroup.patchValue({ questionnaire_id: contact.questionnaire.id });

                  if (this.route.snapshot.params.action === 'send') {
                    this.sendDocumentary();
                  }
                })
                .catch(err => {
                  console.log(err)
                })
            }
            else {
              // Only active questionnaire
              this.questionnaires = this.questionnaires
                                    .filter(q => new Date(q.end_at)
                                    .setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0));

              this.loading = false;
              this.editFormGroup = this.formBuilder.group(this.getNormalForm());
              this.setUpForm();
              if (this.route.snapshot.params.questionnaire_id) {
                this.editFormGroup.controls.questionnaire_id.patchValue(this.route.snapshot.params.questionnaire_id);
              }
            }

          });
      }
    });
  }

  ngOnInit(): void { }

  private getNormalForm(data?: Contact) {
    return {
      questionnaire_id: ['', Validators.required],
      /*society*/
      society_prospect_or_customer: [data ? this.getFieldFromAnswer('prospect_or_customer', data.society_fields_answers) : '', null],
      society_social_reason: [data ? this.getFieldFromAnswer('social_reason', data.society_fields_answers) : '', null],
      society_firm: [data ? this.getFieldFromAnswer('firm', data.society_fields_answers) : '', null],
      society_postal_code: [data ? this.getFieldFromAnswer('postal_code', data.society_fields_answers) : '', null],
      society_city: [data ? this.getFieldFromAnswer('city', data.society_fields_answers) : '', null],
      society_account_manager: [data ? this.getFieldFromAnswer('account_manager', data.society_fields_answers) : '', null],
      /*contact*/
      contact_civility: [data ? this.getFieldFromAnswer('civility', data.contact_fields_answers) : '', null],
      contact_first_name: [data ? this.getFieldFromAnswer('first_name', data.contact_fields_answers) : '', null],
      contact_last_name: [data ? this.getFieldFromAnswer('last_name', data.contact_fields_answers) : '', null],
      contact_phone: [data ? this.getFieldFromAnswer('phone', data.contact_fields_answers) : '', null],
      contact_email: [data ? this.getFieldFromAnswer('email', data.contact_fields_answers) : '', null],
      contact_current_function: [data ? this.getFieldFromAnswer('current_function', data.contact_fields_answers) : '', null],
      contact_who_are_you: [data ? [...this.getFieldFromAnswer('who_are_you', data.contact_fields_answers, [])] : [], null],
      /* rubrics */
      rubrics_answers: [data && data.rubrics_answers ? data.rubrics_answers : [], null],
    };
  }

  private setUpForm() {
    // Set up form
    this.editFormGroup.controls.questionnaire_id.valueChanges.subscribe(id => {
      const questionnaire = this.questionnaires.find(q => q.id == id);
      if (questionnaire) {
        this.submitted = false;
        this.questionnaire = questionnaire;
        this.updateFormValidation(questionnaire);
      }
    });
  }

  private updateFormValidation(questionnaire: Questionnaire) {
    Object.keys(this.editFormGroup.controls).forEach(key => {
      // Reset validation
      if (key != 'questionnaire_id'){
        this.editFormGroup.get(key).setErrors(null) ;
        this.editFormGroup.get(key).clearValidators();
        this.editFormGroup.get(key).updateValueAndValidity();
      }
    });
    questionnaire.society_fields.forEach((f: { slug: string, required: boolean }) => {
      if (this.f['society_' + f.slug]) {
        if (f.required) {
          this.f['society_' + f.slug].setValidators(Validators.required);
        } else {
          this.f['society_' + f.slug].setValidators(null);
        }
        this.f['society_' + f.slug].updateValueAndValidity();
      }
    });
    questionnaire.contact_fields.forEach((f: { slug: string, required: boolean }) => {
      if (this.f['contact_' + f.slug]) {
        if (f.required) {
          this.f['contact_' + f.slug].setValidators(Validators.required);
        } else {
          this.f['contact_' + f.slug].setValidators(null);
        }
        this.f['contact_' + f.slug].updateValueAndValidity();
      }
    });
  }

  get f() {
    return this.editFormGroup.controls;
  }

  get checkRubricsValidation(): boolean {
    let bool = true;
    if (this.questionnaire) {
      if (this.questionnaire.rubrics && this.questionnaire.rubrics.length > 0) {
        this.questionnaire.rubrics.forEach(r => {
          r.questions.forEach(q => {
            if (q.required && !this.findAnswer(q.uuid)) {
              bool = false;
            }
          });
        });
      } else {
        bool = true;
      }
    }
    return bool;
  }

  getFieldFromAnswer(field: String, answersArray: Array<any>, value_default: any = '') {
    const answ: any = answersArray.find(a => a.slug === field);
    return answ ? answ.value : value_default;
  }

  getFieldFromQuestionnaire(slug, array) {
    return this.questionnaire ? this.questionnaire[array].find(f => f.slug === slug) : null;
  }

  onEditRubricField(questionUuid, answer) {
    const arr = this.f.rubrics_answers.value;
    const index = arr.findIndex(q => q.question_uuid === questionUuid);
    if (index !== -1) {
      if (answer) {
        arr[index].data = answer;
      } else {
        arr.splice(index, 1);
      }
    } else {
      arr.push({ question_uuid: questionUuid, data: answer });
    }
    this.f.rubrics_answers.patchValue(arr);
  }

  /*
  * For each rubric check if every required question have a answer
  */
  findAnswer(questionUuid): any {
    const arr = this.f.rubrics_answers.value ? this.f.rubrics_answers.value : [];
    const index = arr.findIndex(r => r.question_uuid === questionUuid);
    return index !== -1 ? arr[index].data : null;
  }


  getSelectMultiple(options: HTMLCollection) {
    return Array.from(options).map((x: any) => x.value);
  }

  checkboxList(field, value, bool) {
    const arr = this.f[field].value;
    const index = arr.findIndex(c => c === value);
    if (bool) {
      if (index === -1) {
        arr.push(value);
      }
    } else {
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    this.f[field].patchValue(arr);
  }

  onSubmitAndNew() {
    this.submit(() => {
      if (this.policiesService.currentUser.policies.contact.create || this.policiesService.currentUser.policies.contact.update) {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        {
          this.router.navigate(['/meetings/contacts/edit/', 'questionnaire', this.questionnaire.id]);
        });
      } else {
        this.router.navigate(['/meetings/contacts']);
      }
    })
  }

  onSubmitAndOpenModal() {
    this.submit((contact: Contact) => {
      this.router.navigate(['/meetings/contacts/edit/', contact.id , 'questionnaire', this.questionnaire.id, 'send']);
    })
  }

  submit(callback: Function) {
    this.submitted = true;

    if (this.editFormGroup.invalid || !this.checkRubricsValidation) {
      window.scrollTo(0, 0);
      return;
    }


    const contact: Contact = {
      ...this.editFormGroup.value,
      society_fields_answers: [],
      contact_fields_answers: [],
      user_id: this.authService.currentUserValue ? this.authService.currentUserValue.id : null
    };

    // set society_fields_answers && contact_fields_answers
    Object.keys(this.editFormGroup.controls).forEach(key => {
      if (key !== 'rubrics_answers') {
        if (key.startsWith('society_') && this.editFormGroup.get(key).value) {
          contact.society_fields_answers.push({
            slug: key.split('society_')[1],
            value: this.editFormGroup.get(key).value
          });
        }

        if (key.startsWith('contact_') && this.editFormGroup.get(key).value) {
          contact.contact_fields_answers.push({
            slug: key.split('contact_')[1],
            value: this.editFormGroup.get(key).value
          });
        }
      }
    });

    // Edit mode
    if (this.editMode.active) {
      contact.id = this.route.snapshot.params.contact_id;
    }

    this.contactsService.edit(this.questionnaire.id, contact).then((response: Contact) => {
      callback(response);
      this.alertService.success((this.editMode && this.editMode.active) ? 'Modification enregistrée avec succès.' : 'Contact créé avec succès');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

  exportToPDF() {
    const doc = new jsPDF('p', 'pt');
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.getWidth();
    let pageSize = 40;
    doc.setFont('Helvetica');
    function testPdfSize(y) {
      if (y >= (pageHeight - 10)) {
        doc.addPage();
        y = 20;
        pageSize = 20;
      }
      return y;
    }
    function writeBoldText(content, x, y, fontSize= 12, lineSpacing = 12) {
      doc.setFontSize(fontSize);
      let startY = y;
      let startX = x;
      const textMap = doc.splitTextToSize(content, doc.internal.pageSize.width - x - 20);

      textMap.map((text) => {
        startY = testPdfSize(startY);
        const arrayOfNormalAndBoldText = text.split('**');
        arrayOfNormalAndBoldText.map((textItems, i) => {
          doc.setFont(undefined, (i % 2 === 0) ? 'normal' : 'bold');
          doc.text(textItems, startX, startY);
          startX += doc.getStringUnitWidth(textItems) * fontSize;
        });
        pageSize += lineSpacing;
        startY += lineSpacing;
        startX = x;
      });
    }
    // QUESTIONNAIRE
    if (this.editMode.active && this.editMode.data) {

      let image = new Image();
      doc.addImage(
        './assets/img/pdf/header.jpg', 'JPEG', 0, 0,
        doc.internal.pageSize.getWidth(), 110
      );

      doc.setFontSize(18);
      doc.setTextColor(255, 255, 255);
      // @ts-ignore
      doc.text(`${this.editMode.data.questionnaire.name}`, pageWidth / 2, testPdfSize(pageSize), 'center');
      doc.setTextColor(0, 0, 0);
      pageSize += 85;

      // SOCIÉTÉ
      if (this.f.questionnaire_id.value && this.questionnaire && this.questionnaire.society_fields.length > 0) {
        writeBoldText(`**SOCIÉTÉ**`, 10, testPdfSize(pageSize), 16);
        doc.setDrawColor(216, 27, 96);
        doc.setLineWidth(2.5);
        doc.line(10, pageSize - 5, doc.getStringUnitWidth('SOCIÉTÉ') * 16 + 10, pageSize - 5);
        pageSize += 20;
        this.societyFields.filter((a) => this.questionnaire.society_fields.find(t => t.slug === a.slug)).forEach(field => {
          let fieldValue = '';
          if (Array.isArray(this.f['society_' + field.slug].value)) {
            this.f['society_' + field.slug].value.forEach((value, i, arr) => {
              fieldValue += value;
              if (i < arr.length - 1) {
                fieldValue += ', ';
              }
            });
          }
          else {
            fieldValue = this.f['society_' + field.slug].value;
          }
          if (this.f['society_' + field.slug].value === null) {
            fieldValue = 'Non renseigné';
          }
          console.log(fieldValue);
          writeBoldText(
            `**${ field.label }** : ${ fieldValue }`,
            15,
            testPdfSize(pageSize));
          pageSize += 5;
        });
        pageSize += 20;
      }

      // CONTACT
      if (this.f.questionnaire_id.value && this.questionnaire && this.questionnaire.contact_fields.length > 0) {
        writeBoldText(`**CONTACT**`, 10, testPdfSize(pageSize), 16);
        doc.setDrawColor(216, 27, 96);
        doc.setLineWidth(2.5);
        doc.line(10, pageSize - 5, doc.getStringUnitWidth('CONTACT') * 16 + 10, pageSize - 5);
        pageSize += 20;
        this.contactFields.filter((a) => this.questionnaire.contact_fields.find(t => t.slug === a.slug)).forEach(field => {
          let fieldValue = '';
          if (Array.isArray(this.f['contact_' + field.slug].value)) {
            this.f['contact_' + field.slug].value.forEach((value, i, arr) => {
              fieldValue += value;
              if (i < arr.length - 1) {
                fieldValue += ', ';
              }
            });
          }
          else {
            fieldValue = this.f['contact_' + field.slug].value;
          }
          if (this.f['contact_' + field.slug].value === null) {
            fieldValue = 'Non renseigné';
          }
          writeBoldText(
            `**${ field.label }** : ${ fieldValue }`,
            15,
            testPdfSize(pageSize));
          pageSize += 5;
        });
        pageSize += 20;
      }

      // RUBRIQUES ET QUESTIONS
      if (this.f.questionnaire_id.value && this.questionnaire && this.questionnaire.rubrics.length > 0) {
        writeBoldText(`**RUBRIQUES & QUESTIONS**`, 10, testPdfSize(pageSize), 16);
        doc.setDrawColor(216, 27, 96);
        doc.setLineWidth(2.5);
        doc.line(10, pageSize - 5, doc.getStringUnitWidth('RUBRIQUES & QUESTIONS') * 16 + 10, pageSize - 5);
        pageSize += 20;
        this.questionnaire.rubrics.forEach(rubric => {
          writeBoldText(`**${ rubric.name.toUpperCase() }**`, 15, testPdfSize(pageSize), 14);
          if (rubric.questions.length > 0) {
            pageSize += 10;
            rubric.questions.forEach(question => {
              let fieldValue = '';
              if (Array.isArray(this.findAnswer(question.uuid))) {
                this.findAnswer(question.uuid).forEach((value, i, arr) => {
                  fieldValue += value;
                  if (i < arr.length - 1) {
                    fieldValue += ', ';
                  }
                });
              }
              else {
                fieldValue = this.findAnswer(question.uuid);
              }
              if (this.findAnswer(question.uuid) === null) {
                fieldValue = 'Non renseigné';
              }
              if (question.question_type === 'checkbox') {
                this.findAnswer(question.uuid) ? fieldValue = 'Oui' : fieldValue = 'Non';
              }
              writeBoldText(
                `**${ question.name }** : ${ fieldValue }`,
                20,
                testPdfSize(pageSize));
              pageSize += 5;
            });
          }
          pageSize += 20;
        });
      }
      doc.save(`${ slugify(this.editMode.data.questionnaire.name ) }-contact-${ this.route.snapshot.params.contact_id }.pdf`);
    }
  }

  onFileSelected(files) {
    this.editFormGroupModal.get('files').setValue(files);
  }

  sendDocumentary() {
    this.editFormGroupModal = this.formBuilder.group({
      email: [this.f.contact_email.value ? this.f.contact_email.value : '', Validators.required],
      other: [this.editMode.data.other_recipients ? this.editMode.data.other_recipients : '', null],
      files: [[], [Validators.required, Validators.minLength(1)]],
    });
  }

  onBlurEmail() {
    if (this.editFormGroupModal.get('email').value
      && this.f.contact_email.value == '') {
      this.f.contact_email.setValue(
        this.editFormGroupModal.get('email').value
      );
    }
  }

  onSubmitModal(redirect = false) {
    this.contactsService.sendDocumentary(this.editFormGroupModal.value, this.editMode.data.id)
      .then(() => {
        this.alertService.success('Document(s) envoyé(s) avec succès.');
        this.editFormGroupModal = null;
        if (redirect) {
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
          {
            this.router.navigate(['/meetings/contacts/edit/', 'questionnaire', this.questionnaire.id]);
          });
        }
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

  downloadQuestionnairePDF(questionnaireId) {
    const elements: any = document.querySelectorAll('.topdf');
    const opt = {
      margin: [10, 0, 10, 0],
      filename: `${this.questionnaire.name}.pdf`,
      pagebreak: { after: '.break-page', avoid: '.break-avoid'},
    };
    const content = document.createElement('page');

    elements.forEach((e, i) => {
      const newElement = e.cloneNode(true);
      newElement.style.maxWidth = '100%';
      newElement.style.minWidth = '100%';
      newElement.style.boxShadow = 'none';
      newElement.classList.remove('showOnlyPdf');
      const checkboxs = newElement.querySelectorAll('.form-check-sign');
      checkboxs.forEach((checkbox) => {
        checkbox.classList.add('removeForPdf');
      });

      const  imputsList = newElement.querySelectorAll('input');
      imputsList.forEach((input) => {
        const space = document.createElement('div');
        space.style.height = '20px';
        space.style.display = 'block';
        input.insertAdjacentElement('beforebegin', space);
      });

      const formGroups = newElement.querySelectorAll('.form-group');
      formGroups.forEach((formGroup) => {
        formGroup.classList.add('break-avoid')
      })

      const breaks = newElement.querySelectorAll('.toBreakPdf');
      breaks.forEach((b, index) => {
        if (index + 1 !== breaks.length && index !== 1 ) {
          const span = document.createElement('span');
          span.classList.add('break-page');
          b.insertAdjacentElement('afterend', span);
        }
      });

      content.appendChild(newElement);
      if (i + 1 !== elements.length && i !== 0) {
        const span = document.createElement('span');
        span.classList.add('break-page');
        content.appendChild(span);
      }
    });

    const excludeElement = content.querySelectorAll('.excludeToPdf');
    excludeElement.forEach((e) => e.remove());

    const selectList = content.querySelectorAll('select');
    selectList.forEach((s) => {
      const select = document.createElement('div');
      const options = s.querySelectorAll('option');
      options.forEach((o) => {
        const p = document.createElement('p');
        p.innerHTML = '<input type="checkbox"> ' + ' ' + o.innerHTML;
        select.appendChild(p);
      });
      s.parentNode.replaceChild(select, s);
    });
    //html2pdf().set(opt).from(content).save();

    html2pdf()
      .from(content)
      .set(opt)
      .toPdf()
      .get('pdf').then(function (pdf) {
        var img = new Image()
        img.src = 'assets/img/pdf/header.jpg'
        pdf.setPage(1);
        pdf.setFontSize(8);
        pdf.setTextColor(150);
        pdf.addImage(img, 'jpg', 0, 0, 0, 0)
      })
      .save();
  }

  onScan(file, submit = false) {
    this.loadingScan = true;

    // reset first part of the form
    Object.keys(this.editFormGroup.controls).forEach(key => {
      if (key.includes('society_') || key.includes('contact_') ) {
        this.editFormGroup.get(key).setValue('')
      }
    });

    // prepare queries array
    let queries = [];

    this.societyFields.forEach(sf => {
      if (this.getFieldFromQuestionnaire(sf.slug, "society_fields") && sf.queries) {
        sf.queries.forEach(q => {
          queries.push({Text: q, Alias: `society_${sf.slug}`})
        })
      }
    })

    this.contactFields.forEach(cf => {
      if (this.getFieldFromQuestionnaire(cf.slug, "contact_fields") && cf.queries) {
        cf.queries.forEach(q => {
          queries.push({Text: q, Alias: `contact_${cf.slug}`})
        })
      }
    })

    /**
     * SEND SCAN
     */
    this.contactsService.scan(file, queries)
      .then((
      response: {
        AnalyzeDocumentModelVersion: string,
        DocumentMetadata: any,
        Blocks: { BlockType: 'QUERY' | 'QUERY_RESULT', Id: number, Text: string, Relationships: any[],  Query: {Alias: string, Text: string }, Confidence: number}[]
      }) => {

        /**
         * TAKE RESULT FROM TEXTRACT AND SET FIELD
         */

        // 1. parse array
        const response_parsed: {slug: string, query: string, answer: string, score: number}[] = [];

        // GET RESPONSE PARSED WITH IDS
        queries.forEach(query => {
          let blocks_for_query = response.Blocks.filter(b => b.BlockType === 'QUERY' && b.Query.Alias === query.Alias)
          blocks_for_query.forEach(bq => {
            // there is a query_result with the id ?
            if (bq.Relationships) {
              const result_id = bq.Relationships[0].Ids[0]
              const result = response.Blocks.find(b => b.BlockType === 'QUERY_RESULT' && b.Id == result_id)

              if(result) {
                const new_value = {
                  slug: bq.Query.Alias,
                  query: bq.Query.Text,
                  answer: result.Text,
                  score: result.Confidence
                }

                // Already present ? check score
                const exist = response_parsed.findIndex(rp => rp.slug == bq.Query.Alias)

                if(exist != -1) {
                  // check score
                  if (response_parsed[exist].score < result.Confidence) {
                    // Remove and use the higher score
                    response_parsed.splice(exist, 1)
                    response_parsed.push(new_value)
                  }
                } else {
                  response_parsed.push(new_value)
                }
              }
            }
          })
        });

        // 2. use parsed array
        // console.log('pre parsed result', response_parsed)
        response_parsed.forEach(rp => {
          if (this.editFormGroup.controls[rp.slug]) {
            let value = ""
            // TRANSFORM RESULT TO MATCH WITH FORM SPECS
            switch (rp.slug) {
              case "contact_phone":
                value = rp.answer.replace(/[A-Za-z]/g, "").replace(/\./g, "").replace(/ /g, '')
                if (value.includes('+')) { // + 33; +377...
                  value = value.replace('(0)', '').substring(0, 12)
                } else { // Others
                  value = value.substring(0, 10)
                }
                break;
              case "contact_first_name": // use first_name to get last name if it was not found
                value = rp.answer
                let lastNameResult = response_parsed.find(rp=> rp.slug == 'contact_last_name')
                if (!lastNameResult) {
                  response_parsed.push({
                    slug: "contact_last_name",
                    query: 'LASTNAME DEDUCTED FROM FIRSTNAME',
                    answer: value.split(' ')[value.split(' ').length -1],
                    score: 0
                  })
                  value = value.split(' ')[0]
                }
                break;
              case "contact_last_name": // use last_name to get first name if it was not found
                value = rp.answer
                let firstNameResult = response_parsed.find(rp=> rp.slug == 'contact_first_name')
                if (!firstNameResult) {
                  response_parsed.push({
                    slug: "contact_first_name",
                    query: 'FIRSTNAME DEDUCTED FROM LASTNAME',
                    answer: value.split(' ')[0],
                    score: 0
                  })
                  value = value.split(' ')[value.split(' ').length -1]
                }
                break;
              default:
                value = rp.answer
                break;
            }
            rp.answer = value;
          }
        })


        // FINAL
        // console.log("parsed result", response_parsed)
        response_parsed.forEach(rp => {
          this.editFormGroup.controls[rp.slug].setValue(rp.answer);
        })

        if (submit) {
          this.onSubmitAndNew();
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        this.loadingScan = false;
      })
  }
}
