import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CustomerReviewsService } from 'src/app/services/customer-reviews.service';

import { CustomerReview } from 'src/app/models/CustomerReview';
import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';

import { AuthService } from 'src/app/services/auth.service';
import { PoliciesService } from 'src/app/services/policies.service';
import { WorkersService } from 'src/app/services/workers.service';

@Component({
  selector: 'app-customers-reviews',
  templateUrl: './customers-reviews.component.html',
  styleUrls: ['./customers-reviews.component.scss']
})
export class CustomersReviewsComponent implements OnInit {
  items: Array<CustomerReview> = null;

  public loading: boolean = false;
  public loadingList: boolean = false;

  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [
    {
      name: 'Utilisateur',
      slug: 'reviewer_name',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Email',
      slug: 'email',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Contenu',
      slug: 'content',
      type: 'text',
      class: 'col-2',
      value: null
    },
    {
      name: 'Note',
      slug: 'rating',
      type: 'select',
      class: 'col-1',
      options: [0, 1, 2, 3, 4, 5]
    },
    {
      name: 'Statut',
      slug: 'status',
      selected_emit_property: 'value',
      property: 'name',
      type: 'select',
      class: 'col-1',
      options: [
        {name: "Non modéré", value: 0},
        {name: "Modéré", value: 1},
        {name: "Archivé", value: 2}
      ]
    },
    {
      name: 'Date de début',
      slug: 'start_at',
      type: 'date',
      class: 'col-2',
      value: null
    },
    {
      name: 'Date de fin',
      slug: 'end_at',
      type: 'date',
      class: 'col-2',
      value: null
    }
  ];
  public optionsFilteredByType: any;

  constructor(
    private cd: ChangeDetectorRef,
    private alertService: AlertService,
    public enumsService: EnumsService,
    public customerReviewsService: CustomerReviewsService,
    public authService: AuthService,
    private workersService: WorkersService,
    public policiesService: PoliciesService) {
      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          this.loading = false;
        }
      });
    }

  ngOnInit(): void {
    this.policiesService.currentUser = this.authService.currentUserValue;
  }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;

    this.customerReviewsService.getAllWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

  export() {
    this.workersService.startNewWorker('export_reviews', this.filtersSelected).then(() => {
      this.alertService.success('Export de la liste des avis clients, veuillez patienter. Vous pouvez retrouver l\'ensemble de vos notifications dans l\'onglet Notifications à gauche.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }
}
