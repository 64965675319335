<app-topbar moduleSlug="meetings">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/meetings']">Salons</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Questionnaires</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>


<div class="row" *ngIf="policiesService.currentUser.policies.questionnaire.create">
  <div class="col-md-9">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round" [routerLink]="['edit']">
      <i class="material-icons">library_add</i>
      Créer un nouveau questionnaire
    </a>
  </div>

  <div  class="col-md-3 text-right">
    <form class="form" [formGroup]="editFormGroup" *ngIf="editFormGroup">
      <app-file-input #FileInput *ngIf="!fileImporting; else templateImporting"
        btnClass="ppp-button ppp-button-black btn btn-primary btn-round"
        (filesSelected)="selectFile($event)" label="Import de contacts"></app-file-input>
      <ng-template #templateImporting>
        <span class="ppp-button ppp-button-black btn btn-primary btn-round" disabled>Import en cours...</span>
      </ng-template>
    </form>
  </div>
</div>
<div class="col-md-12">
  <div class="row">

    <!-- EN COURS ET A VENIR -->
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">schedule</i>
        </div>
        <h4 class="card-title">Liste des questionnaires
          <strong class="description"> en cours et à venir</strong>
        </h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <ngx-datatable #datatable class="material" [rows]="currentItems" [columnMode]="'standard'"
            [rowHeight]="'auto'" [headerHeight]="50" [footerHeight]="50" [externalSorting]="true"
            [loadingIndicator]="loadingList" [reorderable]="false" (sort)="onSort($event)">
            <ngx-datatable-column [resizeable]="false" name="ID" prop="id" [width]="50">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Nom" prop="name" [width]="250">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Salon" prop="show_name" [width]="250">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Année" prop="for_year" [width]="100">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Début" prop="start_at" [width]="100">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date: 'dd/MM/yy' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Fin" prop="end_at" [width]="100">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date: 'dd/MM/yy' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Actions" [width]="220" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="policiesService.currentUser.policies.questionnaire.update"
                  [routerLink]="['/meetings/questionnaires/edit/', row.id]" type="button" class="btn btn-success"
                  title="Modifier les informations">
                  <i class="material-icons">edit</i>
                </a>
                <a *ngIf="policiesService.currentUser.policies.questionnaire.create"
                   [routerLink]="['/meetings/questionnaires/edit/', row.id, 'clone']" type="button" class="btn btn-success"
                   title="Dupliquer les informations">
                  <i class="material-icons">content_copy</i>
                </a>
                <a *ngIf="policiesService.currentUser.policies.contact.create"
                  [routerLink]="['/meetings/contacts/edit/questionnaire/', row.id]" type="button"
                  class="btn btn-success" title="Nouveau contact">
                  <i class="material-icons">person_add</i>
                </a>
                <button href="#" type="button" class="btn btn-success"
                  (click)="downloadImportModel(row.id)"
                  title="Exporter le modèle d'import pour ce questionnaire">
                  <i class="material-icons">file_download</i>
                </button>
                <button href="#" type="button" class="btn btn-success" (click)="downloadCharts(row.id)"
                  title="Télécharger les statistiques">
                  <i class="material-icons">pie_chart</i>
                </button>
                <button *ngIf="policiesService.currentUser.policies.questionnaire.delete" type="button"
                  class="btn btn-danger" (click)="onDelete(row.id)" title="Supprimer">
                  <i class="material-icons">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>

    <!-- ARCHIVES -->
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">archive</i>
        </div>
        <h4 class="card-title">Liste des questionnaires
          <strong class="description">archivés</strong>
        </h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <ngx-datatable #datatable class="material" [rows]="archivedItems" [columnMode]="'standard'"
            [rowHeight]="'auto'" [headerHeight]="50" [footerHeight]="50" [externalSorting]="true"
            [loadingIndicator]="loadingList" [reorderable]="false" (sort)="onSort($event)">
            <ngx-datatable-column [resizeable]="false" name="ID" prop="id" [width]="50">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Nom" prop="name" [width]="250">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Salon" prop="show_name" [width]="250">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Année" prop="for_year" [width]="100">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Début" prop="start_at" [width]="100">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date: 'dd/MM/yy' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Fin" prop="end_at" [width]="100">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date: 'dd/MM/yy' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [resizeable]="false" name="Actions" [width]="150" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="policiesService.currentUser.policies.questionnaire.update"
                  [routerLink]="['/meetings/questionnaires/edit/', row.id]" type="button" class="btn btn-success"
                  title="Modifier les informations">
                  <i class="material-icons">edit</i>
                </a>
                <a *ngIf="policiesService.currentUser.policies.questionnaire.create"
                   [routerLink]="['/meetings/questionnaires/edit/', row.id, 'clone']" type="button" class="btn btn-success"
                   title="Dupliquer les informations">
                  <i class="material-icons">content_copy</i>
                </a>
                <button type="button" class="btn btn-success" title="Télécharger les statistiques"
                  (click)="downloadCharts(row.id)">
                  <i class=" material-icons">pie_chart</i>
                </button>
                <button *ngIf="policiesService.currentUser.policies.questionnaire.delete" type="button"
                  class="btn btn-danger" (click)="onDelete(row.id)" title="Supprimer">
                  <i class="material-icons">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>

  </div>
</div>
