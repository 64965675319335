import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { AlertService } from 'src/app/services/alert.service';
import { LoyaltyCardOrganizationsService } from 'src/app/services/loyalty-card-organizations.service';

import { LoyaltyCardOrganization } from 'src/app/models/LoyaltyCardOrganization';
import { EnumsService } from 'src/app/services/enums.service';

@Component({
  selector: 'app-loyalty-card-organizations',
  templateUrl: './loyalty-card-organizations.component.html',
  styleUrls: ['./loyalty-card-organizations.component.scss']
})
export class LoyaltyCardOrganizationsComponent implements OnInit {
  public items: Array<LoyaltyCardOrganization> = null;

  public loading: boolean = false;
  public loadingList: boolean = false;

  @ViewChild('datatable') datatable: any;
  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [
    {
      name: 'Date de création',
      slug: 'created_at',
      type: 'date',
      class: 'col-3',
      value: null
    }
  ];

  constructor(
    private cd: ChangeDetectorRef,
    private loyaltyCardOrganizationsService: LoyaltyCardOrganizationsService,
    private router: Router,
    public enumsService: EnumsService,
    private alertService: AlertService) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.loading = true;
      this.enumsService.observable.subscribe({
        complete: () => {
          this.loading = false;
          // DYNAMICS FILTERS

          this.filters.push({
            name: 'Adhérent',
            slug: 'adherent_id',
            selected_emit_property: 'id',
            type: 'autocomplete',
            class: 'col-2',
            options: this.enumsService.enums.adherents
          });

          this.filters.push({
            name: 'Nom de la société',
            slug: 'name',
            type: 'text',
            class: 'col-2'
          });
        }
      });
    }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;
    this.loyaltyCardOrganizationsService.getAllWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }
}
