import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { FileManager } from '../models/FileManager';

@Injectable({ providedIn: 'root' })
export class FileManagerService {
  constructor(private apiService: ApiService) {}

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('files_manager')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async formatData(fileManager: FileManager) {
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append('files_manager:file', fileManager.file ? fileManager.file : null);
      resolve(formData);
    });
  }

  create(fileManager) {
    return new Promise((resolve, reject) => {
      this.formatData(fileManager).then((formData) => {
        this.apiService.post('files_manager', formData)
        .then((response: any) => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
      });
    });
  }

  delete(file) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('files_manager/' + file)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
