import { Component, OnInit } from '@angular/core';

import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { BrandInformationsService } from 'src/app/services/brand-informations.service';
import { EnumsService } from 'src/app/services/enums.service';
import { PoliciesService } from 'src/app/services/policies.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-brand-informations',
  templateUrl: './brand-informations.component.html',
  styleUrls: ['./brand-informations.component.scss']
})
export class BrandInformationsComponent implements OnInit {
  items: Array<any> = null;
  public loading: boolean = false;

  constructor(
    private alertService: AlertService,
    public enumsService: EnumsService,
    public brandInformationsService: BrandInformationsService,
    public authService: AuthService,
    public policiesService: PoliciesService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
    }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.brandInformationsService.getAll().then((response: any) => {
          this.items = response;
          this.loading = false;
        })
        .catch((err) => {
          this.alertService.error(err);
          this.loading = false;
        });
      }
    });
  }

  getBrand(id) {
    const index = this.enumsService.enums.brands.findIndex(b => b.id === id)
    return (index !== -1) ? this.enumsService.enums.brands[index] : null
  }

  onDelete(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ces informations de marque ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.brandInformationsService.delete(id)
        .then(() => {
          const index = this.items.findIndex(e => e.id === id);
          if (index !== -1) {
            this.items.splice(index, 1);
            this.items = [...this.items];
            this.alertService.success('Informations de marque supprimées avec succès.');
          }
        }).catch((err) => {
          this.alertService.error(err);
        });
      }
    });
  }

}
