import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';
import Slideshow from '../models/Slideshow';

@Injectable({ providedIn: 'root' })
export class SlideshowsService {
  constructor(private apiService: ApiService) {

  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('slideshows')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllWithPagination(page: string | number, sortBy: { field: string, order: string } = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('slideshows/' + '?page=' + page
        + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '')
        + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id): Promise<Slideshow> {
    return new Promise((resolve, reject) => {
      this.apiService.get('slideshows/' + id)
        .then((response: Slideshow) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('slideshows/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(slideshow: Slideshow) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('slideshow:name', slideshow.name ? slideshow.name : '');
      formData.append('slideshow:description', slideshow.description ? slideshow.description : '');
      formData.append('slideshow:content', slideshow.content ? slideshow.content : '');
      formData.append('slideshow:universe', slideshow.universe ? slideshow.universe : '');
      formData.append('slideshow:special_offer_id', slideshow.special_offer_id ? slideshow.special_offer_id.toString() : '');
      formData.append('slideshow:external_link', slideshow.external_link ? slideshow.external_link : '');
      formData.append('slideshow:published', slideshow.published ? slideshow.published.toString() : '');
      formData.append('slideshow:margin_left', slideshow.margin_left ? slideshow.margin_left.toString() : '');
      formData.append('slideshow:margin_top', slideshow.margin_top ? slideshow.margin_top.toString() : '');

      formData.append('slideshow:brand_id', slideshow.brand_id ? slideshow.brand_id.toString() : '');

      if (slideshow.image !== null) {
        formData.append('slideshow:image', slideshow.image ? slideshow.image : '');
      }

      if (slideshow.id) {
        this.apiService.put('slideshows/' + slideshow.id, formData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      } else {
        this.apiService.post('slideshows', formData)
        .then((response: any) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }
}
