<app-topbar moduleSlug="users-management">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/users-management']">Liste des utilisateurs</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/users-management/edit/', user.id]"  *ngIf="user">{{user.first_name}} {{user.last_name}}</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Gestion des droits d'accès</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>
<div class="row">
  <div class="col-md-12">
    <table class="table table-striped ppp-datatable">
      <thead>
        <th></th>
        <th>
          Lecture / Consultation
          <div class="form-check" (click)="changeColumn('read', $event.target.checked)">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox">
              <span class="form-check-sign">
                <span class="check"></span>
              </span>
            </label>
          </div>
        </th>
        <th>
          Création
          <div class="form-check" (click)="changeColumn('create', $event.target.checked)">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox">
              <span class="form-check-sign">
                <span class="check"></span>
              </span>
            </label>
          </div>
        </th>
        <th>
          Édition / Mise à jour
          <div class="form-check" (click)="changeColumn('update', $event.target.checked)">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox">
              <span class="form-check-sign">
                <span class="check"></span>
              </span>
            </label>
          </div>
        </th>
        <th>
          Suppression
          <div class="form-check" (click)="changeColumn('delete', $event.target.checked)">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox">
              <span class="form-check-sign">
                <span class="check"></span>
              </span>
            </label>
          </div>
        </th>
      </thead>
      <tbody>
        <ng-container *ngFor="let module of modulesList">
          <!-- MODULE NAME -->
          <tr>
            <td colspan="6" class="info-title">
              <div class="icon icon-primary">
                <i class="material-icons">{{(modules[module].icon) ? modules[module].icon : 'add_box'}}</i>
              </div>
              <strong>Module "{{modules[module].name}}"</strong>
            </td>
          </tr>
          <!-- MODULE MODELS -->
          <ng-container *ngFor="let model of modules[module].models">
            <tr>
              <td class="ppp-crud-subitem">
                <div class="form-check" (click)="changeLine(model, $event.target.checked)">
                  <label class="form-check-label">
                    <input class="form-check-input" type="checkbox">
                    <span class="form-check-sign">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
                {{modelLabels[model] ? modelLabels[model].label : model}}
              </td>
              <ng-container *ngFor="let rule of ['read', 'create', 'update', 'delete']">
                <td width="20%" style="text-align: center;">
                  <div class="form-check">
                    <label class="form-check-label" *ngIf="modelLabels[model] && modelLabels[model].checkboxes.includes(rule) || !modelLabels[model]">
                      <input [name]="module + '-' + model + '-' + rule"
                        [attr.data-model]="model"
                        [checked]="policiesService.userHaveRule(model, rule)"
                        (change)="policiesService.updateRule(model, rule, $event.target.checked)"
                        class="form-check-input" type="checkbox">
                      <span class="form-check-sign">
                        <span class="check"></span>
                      </span>
                    </label>
                  </div>
                </td>
              </ng-container>
              <!--END CHECKBOXES-->
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="col-md-12">
    <button class="ppp-button ppp-button-black btn btn-primary btn-round"
      (click)="save()">Enregistrer
    </button>
  </div>
</div>
