<app-topbar moduleSlug="myprofilplus">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a>Tableau de bord</a></li>
        <li class="breadcrumb-item"><a>MyProfil+</a></li>
        <li class="breadcrumb-item active"><a>Clients MyProfil+</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row" *ngIf="policiesService.currentUser.policies['myprofilplus-customer'].create">
  <div class="col-md-12">
    <a [routerLink]="['/myprofilplus/customers/edit']" class="ppp-button ppp-button-black btn btn-primary btn-round">
      <i class="material-icons">library_add</i>
      Créer un nouveau client MyProfil+
    </a>
    <a *ngIf="authService.currentUserValue.is_admin || authService.currentUserValue.adherents.length > 0" class="ppp-button ppp-button-black btn btn-primary btn-round"
      (click)="$event.preventDefault(); exportCards()">
      <i class="material-icons">pie_chart</i>
      Export .CSV
    </a>
  </div>
</div>

<div class="col-md-12">
  <div class="row">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">supervisor_account</i>
        </div>
        <h4 class="card-title">Liste des clients MyProfil+</h4>
      </div>
        <div class="card-body">
          <app-filter-bar [filters]="filters"
            (changed)="setPage({offset: 0}, null, $event)">
            <p *ngIf="loadingList">chargement...</p>
          </app-filter-bar>
          <p *ngIf="remaining">Votre recherche comporte {{datatable.count}} résultats mais seulement les <strong>{{items.length}}</strong> premiers sont affichés</p>
          <div class="table-responsive">
            <ngx-datatable
              #datatable
              class="material"
              [rows]="items"
              [columnMode]="'standard'"
              [rowHeight]="'auto'"
              [headerHeight]="50"
              [footerHeight]="50"
              [externalPaging]="true"
              [externalSorting]="true"
              [loadingIndicator]="loadingList"
              [reorderable]="false"
              (page)="setPage($event)"
              (sort)="onSort($event)"
            >
              <ngx-datatable-column [resizeable]="false" name="N° carte" prop="number" [width]="110">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{value}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Points" prop="total_earned_points" [width]="80">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{value}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Encours" prop="total_earned_amount" [width]="80">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{value}} €
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Agence" prop="agency" [width]="210">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a title="Voir l'agence" class="ppp-link" target="_blank"
                    [routerLink]="['/profilplus-agences/edit/', value.id]"
                    *ngIf="policiesService.currentUser.policies['open-agency'] && policiesService.currentUser.policies['open-agency'].update; else agencyOnlyData">
                    {{value.name}}
                  </a>
                  <ng-template #agencyOnlyData>
                    {{value.name}}
                  </ng-template>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Nom" prop="last_name" [width]="125">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ row.customer ? row.customer.last_name : '' }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Prénom" prop="first_name" [width]="125">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ row.customer ? row.customer.first_name : ''}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Email" prop="email" [width]="120">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ row.customer && row.customer.email ? row.customer.email : '' }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="CP" prop="zipcode" [width]="90">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ (row.customer && row.customer.shipping_address && row.customer.shipping_address.zipcode) ?  row.customer.shipping_address.zipcode : ''}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Ville" prop="city" [width]="120">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{  (row.customer && row.customer.shipping_address && row.customer.shipping_address.city) ?  row.customer.shipping_address.city : '' }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Tél." prop="customer" [width]="100" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value.mob_phone ? value.mob_phone : '' }}<br>
                  {{ value.phone ? value.phone : '' }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column [resizeable]="false" name="Date création" prop="created_at" [width]="150">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value | date: 'dd/MM/yy' }}
                </ng-template>
              </ngx-datatable-column>

            <ngx-datatable-column [resizeable]="false" name="Actions" [width]="100" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="policiesService.currentUser.policies['myprofilplus-customer'].update"
                    [routerLink]="['/myprofilplus/customers/edit/', row.id]"
                    type="button" class="btn btn-success" title="Modifier les informations">
                    <i class="material-icons">edit</i>
                </a>
                <button *ngIf="policiesService.currentUser.is_admin"
                  type="button" class="btn btn-danger"
                  (click)="onDelete(row.id)" title="Supprimer">
                  <i class="material-icons">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
