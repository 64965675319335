<app-topbar moduleSlug="commercial-actions">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/commercial-actions']">Actions commerciales</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Vue maître</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<div class="row" *ngIf="policiesService.currentUser.policies['commercial-action'].create">
  <div class="col-md-12">
    <a class="ppp-button ppp-button-black btn btn-primary btn-round"
     [routerLink]="['/commercial-actions/edit']">
      <i class="material-icons">library_add</i>
      Créer une nouvelle action commerciale
    </a>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">schedule</i>
        </div>
        <h4 class="card-title">Actions commerciales
          <strong class="description"> en cours et à venir</strong>
        </h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <ngx-datatable
            class="material"
            [rows]="commercialActions"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [loadingIndicator]="loading"
          >
            <ngx-datatable-column name="Bannière" prop="banner_url" [width]="120">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <img [src]="value" alt="" width="100" *ngIf="row.banner_url">
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Titre" prop="name" [width]="260">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Type" prop="universe" [width]="150">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <ng-container *ngIf="(row.action_type === 0 || row.action_type === 2 ); else withCodePromo">
                  <div [ngSwitch]="value.id">
                    <div *ngSwitchCase="0">B2C VL</div>
                    <div *ngSwitchCase="1">B2B VL</div>
                    <div *ngSwitchCase="2">B2B PL</div>
                    <div *ngSwitchCase="3">B2B AG</div>
                    <div *ngSwitchDefault>--</div>
                  </div>
                </ng-container>
                <ng-template #withCodePromo>
                  Avec codes promotionnels
                </ng-template>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Début" prop="start_at" [width]="80">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date: 'dd/MM/yy' }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Fin" prop="end_at" [width]="80">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value | date: 'dd/MM/yy' }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Portée" prop="adherent_id" [width]="100">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{value ? 'Locale' : 'Nationale'}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Budget" prop="budget" [width]="120">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span *ngIf="(row.action_type === 0 || row.action_type === 2 )">{{row.budget}} € {{row.universe.id === 0 ? 'TTC' : 'HT'}}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a *ngIf="policiesService.currentUser.policies['commercial-action'].update && editable(row.id)" [routerLink]="['/commercial-actions/edit/', row.id]" type="button" class="btn btn-success" title="Modifier les informations">
                    <i class="material-icons">edit</i>
                  </a>
                  <a *ngIf="
                    policiesService.currentUser.policies.agreement.read
                    && policiesService.currentUser.policies.agreement.create
                    && editAgreementChecking(row)" [routerLink]="['/commercial-actions', 'agreements', 'edit', 'action', row.id, 'agreement' ]" class="btn btn-success" title="Nouvel accord">
                    <i class="material-icons">gavel</i>
                  </a>
                  <button
                    *ngIf="policiesService.currentUser.is_admin
                          ||
                          (policiesService.currentUser.adherents && policiesService.currentUser.adherents.length > 0)"
                    class="btn btn-success"
                    type="button"
                    (click)="downloadCharts(row.id)"
                    title="Télécharger les statistiques">
                    <i class="material-icons">pie_chart</i>
                  </button>
                  <button *ngIf="policiesService.currentUser.policies['commercial-action'].delete && editable(row.id)" type="button" class="btn btn-danger" (click)="onDelete(row.id)" title="Supprimer">
                    <i class="material-icons">close</i>
                  </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">archive</i>
        </div>
        <h4 class="card-title">Actions commerciales
          <strong class="description">archivées</strong>
        </h4>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <ngx-datatable
            class="material"
            [rows]="commercialActionsArchived"
            [columnMode]="'standard'"
            [rowHeight]="'auto'"
            [headerHeight]="50"
            [footerHeight]="50"
            [loadingIndicator]="loading"
          >
            <ngx-datatable-column name="Bannière" prop="banner_url" [width]="120">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <img [src]="value" alt="" width="100" *ngIf="row.banner_url">
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Titre" prop="name" [width]="260">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Type" prop="universe" [width]="150">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div [ngSwitch]="value.id">
                  <div *ngSwitchCase="0">B2C VL</div>
                  <div *ngSwitchCase="1">B2B VL</div>
                  <div *ngSwitchCase="2">B2B PL</div>
                  <div *ngSwitchCase="3">B2B AG</div>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Début" prop="start_at" [width]="80">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value  | date: 'dd/MM/yy' }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Fin" prop="end_at" [width]="80">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value  | date: 'dd/MM/yy' }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Portée" prop="adherent_id" [width]="100">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{value ? 'Locale' : 'Nationale'}}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Budget" prop="budget" [width]="120">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span *ngIf="(row.action_type === 0 || row.action_type === 2 )">{{row.budget}} € {{row.universe.id === 0 ? 'TTC' : 'HT'}}</span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Actions" [sortable]="false">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="policiesService.currentUser.policies['commercial-action'].update && editableArchive(row.id)" [routerLink]="['/commercial-actions/edit/', row.id]" type="button" class="btn btn-success" title="Modifier les informations">
                  <i class="material-icons">edit</i>
                </a>
                <a *ngIf="(authService.currentUserValue.is_admin || row.users.includes(authService.currentUserValue.id)) &&
                  policiesService.currentUser.policies.agreement.read &&
                  policiesService.currentUser.policies.agreement.create" [routerLink]="['/commercial-actions', 'agreements', 'edit', 'action', row.id, 'agreement' ]" class="btn btn-success" title="Nouvel accord">
                  <i class="material-icons">gavel</i>
                </a>
                <button
                *ngIf="policiesService.currentUser.is_admin ||
                       (policiesService.currentUser.adherents && policiesService.currentUser.adherents.length > 0)"
                  class="btn btn-success"
                  type="button"
                  (click)="downloadCharts(row.id)"
                  title="Télécharger les statistiques">
                  <i class="material-icons">pie_chart</i>
                </button>
                <button *ngIf="policiesService.currentUser.policies['commercial-action'].delete && editableArchive(row.id)" type="button" class="btn btn-danger" (click)="onDelete(row.id, true)" title="Supprimer">
                  <i class="material-icons">close</i>
                </button>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>

      </div>
    </div>
  </div>
</div>
