import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpComponent } from '../help/help.component';
import { AuthGuard } from 'src/app/helpers/auth.guard';

import { ProfilplusSiteComponent } from './profilplus-site.component';
import { IndexContentComponent } from 'src/app/components/index-content/index-content.component';

import { AppSettingsComponent } from './app-settings/app-settings.component';
import { AppSettingsEditComponent } from './app-settings/app-settings-edit/app-settings-edit.component';

import { AppointmentConfigurationsComponent } from './appointment-configurations/appointment-configurations.component';
import { AppointmentConfigurationsEditComponent } from './appointment-configurations/appointment-configurations-edit/appointment-configurations-edit.component';

import { AppointmentTypesComponent } from './appointment-types/appointment-types.component';
import { AppointmentTypesEditComponent } from './appointment-types/appointment-types-edit/appointment-types-edit.component';

import { PageBackgroundsComponent } from '../common/page-backgrounds/page-backgrounds.component';
import { PageBackgroundsEditComponent } from '../common/page-backgrounds/page-backgrounds-edit/page-backgrounds-edit.component';

import { CustomersComponent } from './customers/customers.component';

import { QuoteRequestsComponent } from './quote-requests/quote-requests.component';

import { ShowComponent } from './show/show.component';

import { CmsComponent } from '../common/cms/cms.component';
import { EditComponent as CmsEdit } from '../common/cms/edit/edit.component';

import { PageAttachmentsComponent } from '../common/cms/page-attachments/page-attachments.component';
import { PageAnswersComponent } from '../common/cms/page-answers/page-answers.component';

import { SpecialOffersComponent } from './special-offers/special-offers.component';
import { EditComponent as SpecialOffersEdit } from './special-offers/edit/edit.component';

import { FileMappingComponent } from './file-mapping/file-mapping.component';
import { EditComponent as FileMappingEditComponent} from './file-mapping/edit/edit.component';

import { VehiculesComponent } from './vehicules/vehicules.component';
import { EditComponent as VehiculesEditComponent } from './vehicules/edit/edit.component';

import { CustomerEntreprisesComponent } from './customer-entreprises/customer-entreprises.component';
import { EditComponent as CustomerEntreprisesEditComponent } from './customer-entreprises/edit/edit.component';

import { TiresComponent } from './tires/tires.component';
import { EditComponent as EditTireComponent } from './tires/edit/edit.component';

import { BrandsComponent } from './tires/brands/brands.component';
import { EditComponent as BrandEditComponent } from './tires/brands/edit/edit.component';

import { BrandInformationsComponent } from './tires/brand-informations/brand-informations.component';
import { EditComponent as BrandInformationsEditComponent } from './tires/brand-informations/edit/edit.component';

import { TireModelsComponent } from './tires/tire-models/tire-models.component';
import { EditComponent as TireModelEditComponent } from './tires/tire-models/edit/edit.component';

import { HighlightRulesComponent } from './tires/highlight-rules/highlight-rules.component';
import { EditComponent as HighlightRulesEditComponent } from './tires/highlight-rules/edit/edit.component';

import { HighlightCategoriesComponent } from './tires/highlight-categories/highlight-categories.component';
import { EditComponent as HighlightCategoryEditComponent } from './tires/highlight-categories/edit/edit.component';

import { CustomersReviewsComponent } from './customers-reviews/customers-reviews.component';
import { EditComponent as CustomersReviewsEditComponent } from './customers-reviews/edit/edit.component';

import { OrdersComponent } from './orders/orders.component';
import { EditComponent as CustomerEditComponent } from './customers/edit/edit.component';

import { FileManagerComponent } from './file-manager/file-manager.component';
import { SlideshowsComponent } from './slideshows/slideshows.component';

import { EditComponent as SlideshowsEdit } from './slideshows/edit/edit.component';
import { QuestionsComponent } from './tires/questions/questions.component';
import { EditComponent as QuestionsEdit } from './tires/questions/edit/edit.component';

import { ProfilplusFormsComponent } from './profilplus-forms/profilplus-forms.component';
import { EditComponent as ProfilplusFormsEdit } from './profilplus-forms/edit/edit.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { DeletedLoyaltyCardsComponent } from './deleted-loyalty-cards/deleted-loyalty-cards.component';

const routes: Routes = [
    {
        path: 'website',
        component: ProfilplusSiteComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: IndexContentComponent,
            canActivate: [AuthGuard],
          },

          /* CONFIGURATIONS */
          {
            path: 'settings/app-settings',
            component: AppSettingsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'settings/app-settings/edit/:app_setting_id',
            component: AppSettingsEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'settings/app-settings/edit',
            component: AppSettingsEditComponent,
            canActivate: [AuthGuard]
          },

          /* CONFIGURATIONS DE RENDEZ-VOUS */
          {
            path: 'settings/appointment-configurations',
            component: AppointmentConfigurationsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'settings/appointment-configurations/edit/:appointment_configuration_id',
            component: AppointmentConfigurationsEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'settings/appointment-configurations/edit',
            component: AppointmentConfigurationsEditComponent,
            canActivate: [AuthGuard]
          },

          /* TYPES DE RENDEZ-VOUS */
          {
            path: 'settings/appointment-types',
            component: AppointmentTypesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'settings/appointment-types/edit/:appointment_type_id',
            component: AppointmentTypesEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'settings/appointment-types/edit',
            component: AppointmentTypesEditComponent,
            canActivate: [AuthGuard]
          },

          /* HABILLAGES DE PAGE */
          {
            path: 'settings/page-backgrounds',
            component: PageBackgroundsComponent,
            canActivate: [AuthGuard],
            data: {moduleSlug: 'website', moduleName: 'Site Profil Plus'}
          },
          {
            path: 'settings/page-backgrounds/edit/:page_background_id',
            component: PageBackgroundsEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'settings/page-backgrounds/edit',
            component: PageBackgroundsEditComponent,
            canActivate: [AuthGuard]
          },

          /* SLIDESHOWS*/
          {
            path: 'settings/slideshows',
            component: SlideshowsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'settings/slideshows/edit',
            component: SlideshowsEdit,
            canActivate: [AuthGuard]
          },
          {
            path: 'settings/slideshows/edit/:id',
            component: SlideshowsEdit,
            canActivate: [AuthGuard]
          },

          /* DELETED LOYALTY CARDS */
          {
            path: 'deleted-loyalty-cards',
            component: DeletedLoyaltyCardsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'deleted-loyalty-cards/:id',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },
          /* CLIENTS */
          {
            path: 'customers',
            component: CustomersComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'customers/customers-list', // For sidebar link
            component: CustomersComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'customers/edit',
            component: CustomerEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'customers/edit/:id',
            component: CustomerEditComponent,
            canActivate: [AuthGuard]
          },

          /* VÉHICULES */
          {
            path: 'customers/customer-vehicles',
            component: VehiculesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'customers/vehicules/edit/:id',
            component: VehiculesEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'customers/vehicules/edit',
            component: VehiculesEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'customers/vehicules/:id',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },

          /* ENTREPRISES */
          {
            path: 'customers/customer-entreprises',
            component: CustomerEntreprisesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'customers/customer-entreprises/:id',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'customers/customer-entreprises/edit',
            component: CustomerEntreprisesEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'customers/customer-entreprises/edit/:id',
            component: CustomerEntreprisesEditComponent,
            canActivate: [AuthGuard]
          },

          {
            path: 'customers/:id',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },

          /* PAGES CMS */
          {
            path: 'pages',
            component: CmsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'pages/list',
            component: CmsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'pages/list/page/:page',
            component: CmsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'pages/edit',
            component: CmsEdit,
            canActivate: [AuthGuard]
          },
          {
            path: 'pages/edit/:id',
            component: CmsEdit,
            canActivate: [AuthGuard],
          },

          /* PAGES CMS - PIECES JOINTES */
          {
            path: 'pages/attachments',
            component: PageAttachmentsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'pages/attachments/:id',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },

          /* PAGES CMS - RÉPONSES */
          {
            path: 'pages/answers',
            component: PageAnswersComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'pages/answers/:id',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },

          /* DEVIS && RDV*/
          {
            path: 'quote-requests',
            component: QuoteRequestsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'quote-requests/page/:page',
            component: QuoteRequestsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'quote-requests/:id/consultation',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },

          {
            path: 'appointments',
            component: AppointmentsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'appointments/page/:page',
            component: AppointmentsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'appointments/:id/consultation',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },

          /* OFFRES SPÉCIALES */
          {
            path: 'special-offers',
            component: SpecialOffersComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'special-offers/edit',
            component: SpecialOffersEdit,
            canActivate: [AuthGuard]
          },
          {
            path: 'special-offers/edit/:id',
            component: SpecialOffersEdit,
            canActivate: [AuthGuard]
          },
          /* Formulaures Profil Plus */
          {
            path: 'profilplus-forms',
            component: ProfilplusFormsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'profilplus-forms/edit',
            component: ProfilplusFormsEdit,
            canActivate: [AuthGuard]
          },
          {
            path: 'profilplus-forms/edit/:id',
            component: ProfilplusFormsEdit,
            canActivate: [AuthGuard]
          },
          {
            path: 'profilplus-forms/edit/:id/clone',
            component: ProfilplusFormsEdit,
            canActivate: [AuthGuard]
          },
          /* MAPPING DE FICHIER */
          {
            path: 'file-mapping',
            component: FileMappingComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'file-mapping/edit',
            component: FileMappingEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'file-mapping/edit/:id',
            component: FileMappingEditComponent,
            canActivate: [AuthGuard]
          },

          /* MARQUES */
          {
            path: 'tires/brands',
            component: BrandsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/brands/edit/:id',
            component: BrandEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/brands/edit',
            component: BrandEditComponent,
            canActivate: [AuthGuard]
          },

          /* INFORMATIONS DE MARQUE */
          {
            path: 'tires/brand-informations',
            component: BrandInformationsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/brand-informations/edit/:id',
            component: BrandInformationsEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/brand-informations/edit',
            component: BrandInformationsEditComponent,
            canActivate: [AuthGuard]
          },

          /* MODELES DE PNEUS */
          {
            path: 'tires/tire-models',
            component: TireModelsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/tire-models/edit/:id',
            component: TireModelEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/tire-models/edit',
            component: TireModelEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/tire-models/:id',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },

          /* RÈGLES DE MISE EN AVANT */
          {
            path: 'tires/highlight-rules',
            component: HighlightRulesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/highlight-rules/edit/:id',
            component: HighlightRulesEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/highlight-rules/edit',
            component: HighlightRulesEditComponent,
            canActivate: [AuthGuard]
          },

          /* CATÉGORIES DE MISE EN AVANT */
          {
            path: 'tires/highlight-categories',
            component: HighlightCategoriesComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/highlight-categories/edit/:id',
            component: HighlightCategoryEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/highlight-categories/edit',
            component: HighlightCategoryEditComponent,
            canActivate: [AuthGuard]
          },

          /* CATÉGORIES DE MISE EN AVANT */
          {
            path: 'tires/questions',
            component: QuestionsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/questions/edit/:id',
            component: QuestionsEdit,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/questions/edit',
            component: QuestionsEdit,
            canActivate: [AuthGuard]
          },

          /* PNEUS */
          {
            path: 'tires/:type',
            component: TiresComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/:type/edit',
            component: EditTireComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/:type/edit/:id',
            component: EditTireComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'tires/:type/:id',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },

          /* COMMANDES ET FACTURES */
          {
            path: 'orders',
            component: OrdersComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'orders/by-customer-id/:id',
            component: OrdersComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'orders/:id',
            component: ShowComponent,
            canActivate: [AuthGuard]
          },

          /* AIDE */
          {
            path: 'help',
            component: HelpComponent,
            canActivate: [AuthGuard],
          },

          /* GESTIONNAIRE DE FICHIERS */
          {
            path: 'file-manager',
            component: FileManagerComponent,
            canActivate: [AuthGuard]
          },

          /* CUSTOMERS REVIEWS*/
          {
            path: 'customer-reviews',
            component: CustomersReviewsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'customer-reviews/edit/:id',
            component: CustomersReviewsEditComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'customer-reviews/:id',
            component: ShowComponent,
            canActivate: [AuthGuard]
          }
        ]
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfilplusSiteRoutingModule {}
