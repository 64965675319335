<app-topbar moduleSlug="profilplus-agences">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences']">Profil Plus Agences</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/profilplus-agences/skills']">Liste des métiers</a></li>
        <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
          <li class="breadcrumb-item active"><a href="javascript:;">Éditer un métier</a></li>
        </ng-container>
        <ng-template #templateElse>
          <li class="breadcrumb-item active"><a href="javascript:;">Créer un nouveau métier</a></li>
        </ng-template>
      </ol>
    </nav>
  </ng-container>
</app-topbar>

<ng-container *ngIf="editFormGroup">
  <div class="row" *ngIf="!editMode.active || (editMode.active && editMode.data)">
    <div class="col-lg-12">
      <form class="form" [formGroup]="editFormGroup">
        <div class="card">
          <!-- HEADER -->
          <div class="card-header card-header-rose card-header-icon">
            <div class="card-icon">
              <i class="material-icons">build</i>
            </div>
            <ng-container *ngIf="(editMode.active && editMode.data); else templateElse">
              <h4 class="card-title">Édition du métier : <strong class="description">{{editMode.data.name}}</strong></h4>
            </ng-container>
            <ng-template #templateElse>
              <h4 class="card-title">Création d'un nouveau métier</h4>
            </ng-template>
          </div>
          <!-- MAIN CONTENT -->
          <div class="card-body">
            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group is-required" [ngClass]="{ 'has-danger': submitted && f.name.errors }">
                <label for="skill_name" class="bmd-label-floating">Dénomination</label>
                <input type="text" class="form-control" id="skill_name" formControlName="name">
              </p>
            </div>
            <div class="col-lg-4 col-md-12" style="padding:0;">
              <p class="form-group">
                <label for="skill_rank" class="bmd-label-floating">Ordre</label>
                <input type="number" class="form-control" id="skill_rank" formControlName="rank">
              </p>
            </div>
            <hr style="border-color:transparent;">

            <!-- NAVIGATION LINKS -->
            <a [routerLink]="['/profilplus-agences/skills']" type="button" class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
              Retour
            </a>

            <button [disabled]="editFormGroup.invalid" *ngIf="!loadingService.loading; else templateSubmit"
              class="ppp-button ppp-button-black btn btn-primary btn-round"
              (click)="onSubmit()">
              <ng-container *ngIf="(editMode.active && editMode.data); else creationTemplateText">Mettre à jour</ng-container>
              <ng-template #creationTemplateText>Créer</ng-template>
            </button>
            <ng-template #templateSubmit>
              <button
                disabled="true"
                class="ppp-button ppp-button-black btn btn-primary btn-round" (click)="$event.preventDefault()">
                ...
              </button>
            </ng-template>

          </div>

        </div>

      </form>
    </div>
  </div>
</ng-container>
