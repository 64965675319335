import { Injectable } from '@angular/core';
import { BrandInformation } from '../models/BrandInformation';
import { ApiService } from './api.service';

@Injectable({providedIn: 'root'})
export class BrandInformationsService {

  constructor(private apiService: ApiService) { }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('brand_informations')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id) {
    return new Promise((resolve, reject) => {
      this.apiService.get('brand_informations/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      this.apiService.delete('brand_informations/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  edit(brandInformation: BrandInformation) {
    return new Promise((resolve, reject) => {

      const formData = new FormData();
      formData.append('brand_information:brand_id', brandInformation.brand_id.toString());
      formData.append('brand_information:universe', brandInformation.universe.toString());
      formData.append('brand_information:video', brandInformation.video ? brandInformation.video : '');
      formData.append('brand_information:description', brandInformation.description ? brandInformation.description : '');
      formData.append('brand_information:is_default', brandInformation.is_default.toString() ? brandInformation.is_default.toString() : 'false');
      formData.append('brand_information:content', brandInformation.content ? brandInformation.content : '');

      formData.append('brand_information:edito_content', brandInformation.edito_content ? brandInformation.edito_content : '');
      formData.append('brand_information:edito_start_at', brandInformation.edito_start_at ? brandInformation.edito_start_at : '');
      formData.append('brand_information:edito_end_at', brandInformation.edito_end_at ? brandInformation.edito_end_at : '');

      if (brandInformation.edito_image !== null) {
        formData.append('brand_information:edito_image', brandInformation.edito_image);
      }

      if (brandInformation.id) { // UPDATE
        this.apiService.put('brand_informations/' + brandInformation.id, formData)
        .then((response: BrandInformation) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      } else { // CREATE
        this.apiService.post('brand_informations', formData)
        .then((response: BrandInformation) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
      }
    });
  }
}
