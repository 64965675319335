import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { PoliciesService } from 'src/app/services/policies.service';

import Swal from 'sweetalert2';
import { ContactsService } from 'src/app/services/contacts.service';
import { Contact } from 'src/app/models/Contact';
import { QuestionnairesService } from 'src/app/services/questionnaires.service';
import { Questionnaire } from 'src/app/models/Questionnaire';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  public items: Contact[] = null;
  public loading: boolean = false;
  public loadingList: boolean = false;
  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [{
      name: 'Raison sociale',
      slug: 'social_reason',
      type: 'text',
      value: null
    }, {
      name: 'Nom',
      slug: 'last_name',
      type: 'text',
      value: null
    }, {
      name: 'Prénom',
      slug: 'first_name',
      type: 'text',
      value: null
    }, {
      name: 'Email',
      slug: 'email',
      type: 'text',
      value: null
    }, {
      name: 'Année du Salon',
      slug: 'for_year',
      type: 'year',
      value: null
    }
  ];

  constructor(
    private cd: ChangeDetectorRef,
    private alertService: AlertService,
    public enumsService: EnumsService,
    public policiesService: PoliciesService,
    public authService: AuthService,
    private questionnairesService: QuestionnairesService,
    private contactsService: ContactsService) {
    this.policiesService.currentUser = this.authService.currentUserValue;
  }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.loading = false;
        this.questionnairesService.getAll()
          .then((response: Array<Questionnaire>) => {
            this.filters.push({
              name: 'Questionnaire',
              slug: 'questionnaire',
              type: 'autocomplete',
              options: response
            });
          });
      }
    });
  }

  ngAfterViewInit() {
    this.setPage({ offset: 0 });
    this.cd.detectChanges();
  }

  getFieldFromAnswer(field: String, answersArray: Array<any>) {
    const answ: any = answersArray.find(a => a.slug === field);
    return answ ? answ.value : null;
  }

  setPage(pageInfo: { count?: number, limit?: number, offset: number }, sortBy: { field: string, order: string } = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected;
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;
    this.contactsService.getAllWithPagination(this.pageOffsetSelected + 1,
      this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 20;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: { column: any, sorts: any[] }) {
    this.setPage({ offset: this.pageOffsetSelected }, { field: $event.column.prop, order: $event.sorts[0].dir });
  }


  onDelete(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce contact ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.contactsService.delete(id).then(() => {
          const index = this.items.findIndex(e => e.id === id);
          if (index !== -1) {
            this.items.splice(index, 1);
            this.items = [...this.items];
            this.alertService.success('Contact supprimé avec succès.');
          }
        }).catch((err) => {
          this.alertService.error(err);
        });
      }
    });
  }

}
