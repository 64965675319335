import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AdminsService } from 'src/app/services/admins.service';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { PoliciesService } from 'src/app/services/policies.service';

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit {
  public loading = false;
  public loadingList: boolean = false;

  public items: Array<any> = [];

  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [
    {
      name: 'Type',
      slug: 'type',
      type: 'select',
      class: 'col-2',
      selected_emit_property: 'value',
      property: 'name',
      options: [
        { name: "Adhérent", value: "AdherentAdmin" },
        { name: "Agence", value: "AgencyAdmin"  },
        { name: "Partoo", value: "PartooAdmin"  },
        { name: "POP3P", value: "DirectCustomerAdmin"  },
        { name: "SEO", value: "SeoAdmin"  },
        { name: "Société", value: "CompanyAdmin"  },
      ]
    }
  ]

  constructor(
    private cd: ChangeDetectorRef,
    private adminsService: AdminsService,
    private alertService: AlertService,
    private authService: AuthService,
    public policiesService: PoliciesService,
    public enumsService: EnumsService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
    }


  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;

    this.adminsService.getAllWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }
}
