<app-topbar moduleSlug="profilplus-site">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active">Tableau de bord</a>
        </li>
        <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/website/customer-reviews']">Avis clients</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Édition d'un avis client</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header card-header-rose card-header-icon">
        <div class="card-icon">
          <i class="material-icons">star</i>
        </div>
        <h4 class="card-title" *ngIf="editMode.active">Édition d'un avis client : <span class="description"
            *ngIf="editMode.data && editMode.data.reviewer_name">{{editMode.data.reviewer_name}}</span></h4>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <form *ngIf="editFormGroup" class="form" [formGroup]="editFormGroup" (submit)="onSubmit()">
              <fieldset>
                <p><strong>Utilisateur</strong> : {{editMode.data.reviewer_name}}</p>
                <p><strong>Email</strong> : {{editMode.data.email}}</p>
                <p>
                  <strong>Contenu</strong> :<br>
                  {{editMode.data.content}}
                </p>
                <p><strong>Note</strong>: {{editMode.data.rating}} / 5</p>
                <p><strong>Porte sur</strong> :
                  <ng-container *ngIf="editMode.data.reviewable_type === 'Tire'">
                    <ng-container [ngSwitch]="editMode.data.reviewable_tire_universe">
                      <p *ngSwitchCase="'VlTire'">
                        <a target="_blank" class="ppp-link"
                          [routerLink]="['/website/tires/vl', editMode.data.reviewable_id]"
                          *ngIf="policiesService.currentUser.policies['vl-tire'].read">Page du pneu VL</a>
                      </p>
                      <p *ngSwitchCase="'AgTire'">
                        <a target="_blank" class="ppp-link"
                          [routerLink]="['/website/tires/ag', editMode.data.reviewable_id]"
                          *ngIf="policiesService.currentUser.policies['ag-tire'].read">Page du pneu AG</a>
                      </p>
                      <p *ngSwitchCase="'AgUsedTire'">
                        <a target="_blank" class="ppp-link"
                          [routerLink]="['/website/tires/ag_used', editMode.data.reviewable_id]"
                          *ngIf="policiesService.currentUser.policies['ag_used-tire'].read">Page du pneu d'occasion
                          AG</a>
                      </p>
                      <p *ngSwitchCase="'PlTire'">
                        <a target="_blank" class="ppp-link"
                          [routerLink]="['/website/tires/pl', editMode.data.reviewable_id]"
                          *ngIf="policiesService.currentUser.policies['pl-tire'].read">Page du pneu PL</a>
                      </p>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="editMode.data.reviewable_type  === 'Agency'">
                    <a target="_blank" class="ppp-link"
                      [routerLink]="['/profilplus-agences/edit/', editMode.data.reviewable_id]" *ngIf="policiesService.currentUser.policies['open-agency']
                             && policiesService.currentUser.policies['open-agency'].update">
                      Agence #{{editMode.data.reviewable_id}}</a>
                  </ng-container>
                </p>
              </fieldset>

              <fieldset>
                <div class="form-group">
                  <label>Statut :</label>
                  <div>
                    <input type="radio" [value]="0" id="status_unmoderated" name="status" formControlName="status">
                    <label for="status_unmoderated"> Non modéré</label><br>
                    <input type="radio" [value]="1" id="status_moderated" name="status" formControlName="status"> <label
                      for="status_moderated"> Modéré</label><br>
                    <input type="radio" [value]="2" id="status_archived" name="status" formControlName="status"> <label
                      for="status_archived"> Archivé</label><br>
                  </div>
                </div>
                <div class="form-group">
                  <label for="status">Réponse de Profil Plus :</label><br>
                  <textarea class="form-control" style="height: 100px;" placeholder="Tapez ici votre réponse..."
                    formControlName="answer"></textarea>
                </div>
              </fieldset>

              <br>

              <a [routerLink]="['/website/customer-reviews']" type="button"
                class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
                Retour
              </a>
              <button type="submit" [disabled]="editFormGroup.invalid"
                *ngIf="!loadingService.loading; else templateSubmit"
                class="ppp-button ppp-button-black btn btn-primary btn-round">
                Mettre à jour
              </button>
              <ng-template #templateSubmit>
                <button disabled="true" class="ppp-button ppp-button-black btn btn-primary btn-round"
                  (click)="$event.preventDefault()">
                  ...
                </button>
              </ng-template>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>