import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Adherent } from 'src/app/models/Adherent';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LoyaltyCardsService } from 'src/app/services/loyalty-cards.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public editFormGroup: UntypedFormGroup;
  public submitted: Boolean = false;
  public adherentsList: Adherent[] = [];
  public preselectAdherent;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private enumsService: EnumsService,
    private alertService: AlertService,
    public authService: AuthService,
    public loadingService: LoadingService,
    private loyaltyCardsService: LoyaltyCardsService) {
      this.enumsService.observable.subscribe({
        complete: () => {
          // INIT FORM
          this.editFormGroup = this.formBuilder.group({
            card_type: [null, Validators.required],
            adherent_id: [null, null],
            organization_name: ['', null],
            number_of_cards: ['', Validators.required]
          })

          // LOAD ADHERENTS
          if (this.authService.currentUserValue.adherents
            && this.authService.currentUserValue.adherents.length > 0) {
              this.adherentsList = this.authService.currentUserValue.adherents;
              // set adherents association
              if (this.authService.currentUserValue.adherents.length === 1) {
                this.editFormGroup.patchValue({adherent_id: this.authService.currentUserValue.adherents[0].id});
              }
          }
          else if (this.authService.currentUserValue.is_admin) {
            this.adherentsList = this.enumsService.enums.adherents;
          }

          // Observe form
          this.editFormGroup.valueChanges.subscribe(fields => {
            if (fields.card_type && fields.card_type === 'VI') {
              this.editFormGroup.controls.adherent_id.setValidators(Validators.required)
            } else {
              this.editFormGroup.controls.adherent_id.setValidators(null)
            }
            this.editFormGroup.controls['adherent_id'].updateValueAndValidity({emitEvent: false});
          });

        }
      })
    }

  ngOnInit(): void {
  }

  get f() {
    return this.editFormGroup.controls;
  }

  onSelectAdherent($event) {
    if ($event.id) {
      this.editFormGroup.patchValue({adherent_id: $event.id})
    }
  }

  /* FORM */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    this.loyaltyCardsService.edit(this.editFormGroup.value).then(() => {
      this.router.navigate(['/myprofilplus/ce-cards', this.editFormGroup.value.card_type.toString()]);
      this.alertService.success('Série de cartes enregistrée avec succès.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }
}
