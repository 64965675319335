import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import Swal from 'sweetalert2';

import modules from '../../../../assets/modules.json';

import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { AgreementsService } from 'src/app/services/agreements.service';
import { PoliciesService } from 'src/app/services/policies.service';

import { User } from 'src/app/models/User';
import { EnumsService } from 'src/app/services/enums.service';
import { CommercialActionsService } from 'src/app/services/commercial-actions.service';
import { CommercialAction } from 'src/app/models/CommercialAction';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss'],
  providers: [PoliciesService]
})
export class AgreementsComponent implements OnInit {
  public modules;
  public modulesStringList;
  public user: User;
  public items: Array<any> = null;

  public loading: boolean = false;
  public loadingList: boolean = false;
  @ViewChild('datatable') datatable: any;

  public agenciesList = [];

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: Array<any> = [];

  constructor(
    private cd: ChangeDetectorRef,
    private commercialActionsService: CommercialActionsService,
    private agreementsService: AgreementsService,
    private alertService: AlertService,
    private authService: AuthService,
    private enumsService: EnumsService,
    public policiesService: PoliciesService) {
      this.policiesService.currentUser = this.authService.currentUserValue;
      if (this.authService.currentUserValue) {
        this.modules = modules;
        this.modulesStringList = Object.keys(modules);
        this.user = this.authService.currentUserValue;
      }
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadingList = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.agenciesList = this.enumsService.enums.agencies;
        this.filters.push({
          name: 'N° accord',
          slug: 'id',
          type: 'text',
          class: 'col-2',
          value: null
        });
        this.filters.push({
          name: 'Nom',
          slug: 'customer_last_name',
          type: 'text',
          class: 'col-2',
          value: null
        });
        this.filters.push({
          name: 'Prénom',
          slug: 'customer_first_name',
          type: 'text',
          class: 'col-2',
          value: null
        });
        this.filters.push({
          name: 'Agence',
          slug: 'agency_name',
          type: 'autocomplete',
          class: 'col-2',
          options: this.enumsService.enums.agencies
        });

        this.commercialActionsService.getAll()
          .then((response : CommercialAction[]) => {
            this.filters.push({
              name: 'Action commerciale',
              slug: 'commercial_action_id',
              type: 'autocomplete',
              class: 'col-2',
              on_select_only: true,
              selected_emit_property: 'id',
              property: "name",
              options: response
            });
            this.loading = false;
          })
      }
    });
  }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }


  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;

    this.agreementsService.getAllWithPagination(this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

  compareDate(date, date2) {
    return new Date(date) <= new Date(date2)
  }

  getCouponAmount(agreement, ttc = false) {
    const calculatedCouponAmount = agreement.amount

    if (ttc) {
      return (calculatedCouponAmount).toFixed(2);
    } else {
      return (calculatedCouponAmount / 1.2).toFixed(2);
    }
  }

  onDelete(actionId, agreementId) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cet accord ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.agreementsService.delete(actionId, agreementId)
          .then(() => {
            const index = this.items.findIndex(e => e.id === agreementId);
            if (index !== -1) {
              this.items.splice(index, 1);
              this.items = [...this.items];
              this.alertService.success('Accord supprimé avec succès.');
            }
          })
          .catch((err) => {
            this.alertService.error(err);
          });
      }
    });

  }

}
