import { Component, OnInit } from '@angular/core';
import { CustomerReviewsService } from 'src/app/services/customer-reviews.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { CustomerReview } from 'src/app/models/CustomerReview';
import { AuthService } from 'src/app/services/auth.service';
import { PoliciesService } from 'src/app/services/policies.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public loading: boolean = false;
  public editMode: {
    active: boolean,
    data: any
  } = {active: false, data: null};
  public submitted: boolean = false;
  public editFormGroup: UntypedFormGroup;

  constructor(
    private enumsService: EnumsService,
    private router: Router,
    public customerReviewsService: CustomerReviewsService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private alertService: AlertService,
    public loadingService: LoadingService,
    public authService: AuthService,
    public policiesService: PoliciesService) {
      this.policiesService.currentUser = this.authService.currentUserValue;

      this.enumsService.observable.subscribe({
        complete: () => {
          if (this.route.snapshot.params.id) { // EDIT
            this.editMode.active = true;
            this.loading = true;
            this.customerReviewsService.getById(this.route.snapshot.params.id)
              .then((response: any) => {
                this.editMode.data = response;
                this.loading = false;
                this.editFormGroup = this.formBuilder.group({
                  status: [response.status.id, null],
                  answer: [response.answer, null]
                });
              })
              .catch((err) => {
                this.loading = false;
                this.alertService.error(err);
              });
          } else {
            this.editFormGroup = this.formBuilder.group({
              id: ['response.id', null],
              created_at: ['', null],
              updated_at: ['', null]
            });

          }
        }
      });
    }

  ngOnInit(): void {
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    const customereview: CustomerReview = {
      ...this.editFormGroup.value
    };

    // Edit mode
    if (this.editMode.active) {
      customereview.id = this.route.snapshot.params.id;
    }

    this.customerReviewsService.edit(customereview).then(() => {
      this.router.navigate(['/website/customer-reviews']);
      this.alertService.success('Modifications enregistrée');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }

}
