<ng-container *ngIf="!loading; else templateElse">
  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
          <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
          <li class="breadcrumb-item"><a href="javascript:;">Gestion des pneus</a></li>
          <li class="breadcrumb-item active"><a href="javascript:;">Règles de mise en avant</a></li>
        </ol>
      </nav>
    </ng-container>
  </app-topbar>

  <div class="row" *ngIf="policiesService.currentUser.policies['highlight-rule'].create">
    <div class="col-md-12">
      <a class="ppp-button ppp-button-black btn btn-primary btn-round"
       [routerLink]="['/website/tires/highlight-rules/edit']">
        <i class="material-icons">library_add</i>
        Créer une nouvelle règle de mise en avant
      </a>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">directions_car</i>
          </div>
          <h4 class="card-title">Liste des règles de mise en avant</h4>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <ngx-datatable
              class="material"
              [rows]="items"
              [columnMode]="'standard'"
              [rowHeight]="'auto'"
              [headerHeight]="50"
              [footerHeight]="50"
              [loadingIndicator]="loadingList"
            >
              <ngx-datatable-column name="Catégorie mise en avant" prop="highlight_category_id" [width]="250">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ getHighLightCategory(value).name }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Marque" prop="brand" [width]="150">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a title="Voir la marque" class="ppp-link" target="_blank" [routerLink]="['/website/tires/brands/edit/', value.id]"
                    *ngIf="value && policiesService.currentUser.policies.brand.update; else brandOnlyData">
                    {{ value.name }}
                  </a>
                  <ng-template #brandOnlyData>
                    {{ value.name }}
                  </ng-template>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Ordre" prop="rank" [width]="120">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Ordre de prix" prop="price_order" [width]="150">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <span *ngIf="value === 0">DESC</span>
                  <span *ngIf="value === 1">ASC</span>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Résultat max" prop="max_result" [width]="150">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Marque exclue" prop="exclude_brand" [width]="160">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value.name }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Actions" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a *ngIf="policiesService.currentUser.policies['highlight-rule'].update" [routerLink]="['/website/tires/highlight-rules/edit', row.id]"
                  type="button" class="btn btn-success"
                  title="Modifier la règle de mise en avant">
                    <i class="material-icons">edit</i>
                  </a>
                  <button *ngIf="policiesService.currentUser.policies['highlight-rule'].delete"
                    type="button" class="btn btn-danger"
                    (click)="onDelete(row.id)" title="Supprimer">
                    <i class="material-icons">close</i>
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #templateElse>
  <app-loading></app-loading>
</ng-template>
