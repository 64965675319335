<app-topbar moduleSlug="users-management">
  <ng-container title>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/']">Tableau de bord</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/users-management/list']">Liste des utilisateurs</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/users-management/edit/', user.id]"  *ngIf="user">{{user.first_name}} {{user.last_name}}</a></li>
        <li class="breadcrumb-item active"><a href="javascript:;">Adhérents / Agences</a></li>
      </ol>
    </nav>
  </ng-container>
</app-topbar>
<div class="row">
  <div class="col-md-12">

    <div class="row">
      <div class="col-md-12">
        <form class="card">
          <header class="card-header card-header-rose card-header-icon">
            <div class="card-icon">
              <i class="material-icons">domain</i>
            </div>
            <h4 class="card-title">Adhérents / Agences</h4>
          </header>
          <section class="card-body" *ngIf="user">

            <!-- Adherents -->
            <h1>Adhérents attribués</h1>
            <article class="row">
              <div class="col-lg-6">
                <div class="bootstrap-tagsinput info-badge">
                  <ng-container *ngFor="let adherent of user.adherents; let j = index">
                    <span class="tag badge">{{adherent.name}}<span data-role="remove" (click)="removeAdherent(adherent)"></span></span>
                  </ng-container>
                </div>
              </div>
              <div class="col-lg-6">
                <p>Ajouter un adhérent :</p>
                <div class="custom-datalist" *ngIf="adherentsList && adherentsList.length > 0; else elseBlock1">
                  <div class="form-group">
                    <input type="text" class="form-control" id="adherentsSearch" name="adherentsSearch"
                    clickOutside (clickOutside)="showListAdherents = false" (click)="showListAdherents = true" [(ngModel)]="adherentsSearch">
                    <ul *ngIf="showListAdherents">
                      <li *ngFor="let adherent of adherentsList  | search:'id,name':adherentsSearch" (click)="addAdherent(adherent)">
                        {{adherent.name}}
                      </li>
                    </ul>
                  </div>
                </div>
                <ng-template #elseBlock1>
                  <app-loading></app-loading>
                </ng-template>
              </div>
            </article>

            <!-- Agencies -->
            <h1 class=" mt-4">Agences attribuées</h1>
            <article class="row">
              <div class="col-lg-6">
                <div class="bootstrap-tagsinput info-badge">
                  <ng-container *ngFor="let agency of user.agencies; let j = index">
                    <span class="tag badge">{{agency.name}}<span data-role="remove" (click)="removeAgency(agency)"></span></span>
                  </ng-container>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <p>Filtrer par adhérent :</p>
                  <select (change)="onChangeAdherentFilter($event)" class="selectpicker select-adherents" [(ngModel)]="adherentFilter" [ngModelOptions]="{standalone: true}" data-style="select-with-transition" multiple="" data-size="7" tabindex="-98">
                    <option disabled value="null">Sélectionnez un adhérent</option>
                    <option *ngFor="let adherent of adherentsList" [value]="adherent.id">{{adherent.name}}</option>
                  </select>
                </div>
                <br>
                <p>Ajouter une agence :</p>
                <app-datalist [items]="agenciesList" property="name" [restOnSelect]="true" [adherentsagenciespage]="true" (selected)="addAgency($event)"></app-datalist>
                <ng-template #elseBlock2>
                  <app-loading></app-loading>
                </ng-template>
              </div>
            </article>

            <a [routerLink]="['/users-management/edit/', user.id]"
                type="button"
                class="ppp-button ppp-button-gray btn btn-primary btn-round mr-2">
              Retour
            </a>
          </section>
        </form>
      </div>
    </div>

  </div>
</div>
