import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

import { MustMatch } from '../../../../helpers/mustmach.validator';

import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';

import { User } from 'src/app/models/User';
import { LoadingService } from 'src/app/services/loading.service';
import { AdminsService } from 'src/app/services/admins.service';
import { Admin } from 'src/app/models/Admin';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public edit: boolean = false;
  public editFormGroup: UntypedFormGroup;
  public user: Admin = null;
  public submitted: boolean = false;
  public policyListRules: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private authService: AuthService,
    private adminsService: AdminsService,
    public loadingService: LoadingService) {
    if (this.route.snapshot.params.id) {
      this.edit = true;
      // Get user info
      this.adminsService.getById(this.route.snapshot.params.id)
        .then((user: Admin) => {
          this.user = user;
          this.editFormGroup = this.formBuilder.group(this.getNormalForm(user), {});
        })
        .catch((err) => {
          this.alertService.error(err.message);
          this.router.navigate(['/users-management']);
        });
    }
  }

  get f() { return this.editFormGroup.controls; }

  ngOnInit(): void {
    if (this.authService.currentUserValue) {
    }
  }

  getNormalForm(data?: Admin ) {
    return {
      first_name: [data ? data.first_name: '', Validators.required],
      last_name: [data ? data.last_name: '', Validators.required],
      email: [data ? data.email : '', [Validators.required, Validators.email]],
      profil_plus_direct_admin: [data ? data.profil_plus_direct_admin : false, []],
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    const user = {
      ...this.editFormGroup.value
    }

    if (this.edit) { // EDIT
      user.id = this.route.snapshot.params.id
    }

    this.adminsService.edit(user)
      .then(() => {
        this.alertService.success(`Utilisateur ${(this.edit) ? ' modifié' : ' ajouté' }.`);
        this.router.navigate(['/users-management/admins']);
      })
      .catch((err) => {
        this.alertService.error(err, false);
      });

  }

}
