import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { WorkersService } from 'src/app/services/workers.service';
import { ApiService } from 'src/app/services/api.service';
import analytics from '../../../assets/analytics.json'
import { EnumsService } from 'src/app/services/enums.service';
import { CommercialActionsService } from 'src/app/services/commercial-actions.service';
import { CommercialAction } from 'src/app/models/CommercialAction';

@Component({
  selector: 'app-notificiations',
  templateUrl: './notificiations.component.html',
  styleUrls: ['./notificiations.component.scss']
})
export class NotificiationsComponent implements OnInit {
  public items: any[] = null;
  public loading: boolean = false;
  public loadingList: boolean = false;
  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public filters: Array<any> = [];
  public pageOffsetSelected = null;

  public deleting: boolean = false;

  public analytics = analytics;
  public commercialActions: CommercialAction[] = [];
  public columns = [
    {name: 'ID', slug: 'id'},
    {name: 'Description', slug: 'request_type', template: true},
    {name: 'Rapport', slug: 'file_reporting', template: true},
    {name: 'Fichier', slug: 'file', template: true},
    {name: 'Traitée le', slug: 'treated_at', template: true},
    {name: 'Utilisateur', slug: 'user', template: true}
  ];

  constructor(
              private cd: ChangeDetectorRef,
              private workersService: WorkersService,
              private alertService: AlertService,
              private enumsService: EnumsService,
              private commercialActionsService: CommercialActionsService,
              private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.loading = false;
        this.commercialActionsService.getAll()
          .then((resp: CommercialAction[]) => {
              this.commercialActions = resp;
          })
      }
    });
  }

  ngAfterViewInit() {
    this.setPage({ offset: 0 });
    this.cd.detectChanges();
  }


  setPage(pageInfo: { count?: number, limit?: number, offset: number }, sortBy: { field: string, order: string } = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected;
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;
    this.workersService.getNotificationsPagination(this.pageOffsetSelected + 1,
      this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }


  onSort($event: { column: any, sorts: any[] }) {
    this.setPage({ offset: this.pageOffsetSelected }, { field: $event.column.prop, order: $event.sorts[0].dir });
  }

  clickDownload(url, filename) {
    this.apiService.getBlob(url)
      .then((blob: Blob) => {
        const href = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = href;
        a.download = filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
      })
      .catch((err) => {
        this.alertService.error(err);
      })
  }

  getTypeAnalytic(type) {
    const analytic = this.analytics.find(a => a.value == type)
    return analytic ? analytic.label : '';
  }

  getAdherent(id) {
    const adherent = this.enumsService.enums.adherents ? this.enumsService.enums.adherents.find(a => a.id == id) : null;
    return adherent ? adherent.name : ''
  }

  getAgencies(array: Array<number>, slice = false) {
    const agencies = this.enumsService.enums.agencies ? this.enumsService.enums.agencies.filter(a => array.includes(a.id)).map(x => x.name) : [];
    return slice ? agencies.slice(0, 3) : agencies;
  }

  getCommercialAction(id) {
    const ca = this.commercialActions ? this.commercialActions.find(a => a.id == id) : null
    return ca ? ca.name : ''
  }

  onDelete(id) {
    this.workersService.delete(id)
      .then(() => {
        this.alertService.success('Notification supprimée avec succès.');
        this.items = this.items.filter(n => n.id !== id);
      })
      .catch((err) => {
        this.alertService.error(err);
      });
  }

}
