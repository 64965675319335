import { Component } from '@angular/core';
import routeChanger from 'src/app/helpers/routeChanger';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'profilplus-portal';

  ngAfterViewChecked() {
    routeChanger.viewChecked();
  }

  changeOfRoutes() {
    routeChanger.afterChange();
  }
}
