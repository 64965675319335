import { Injectable } from '@angular/core';

import 'bootstrap-notify';
import { ApiService } from './api.service';
import parseFiltersForUrl from '../helpers/parseFiltersForUrl';

@Injectable({ providedIn: 'root' })
export class SitesService {
  constructor(private apiService: ApiService) {

  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.apiService.get('sites/all')
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAllWithPagination(page: string | number, sortBy: {field: string, order: string} = null, filters = null) {
    return new Promise((resolve, reject) => {
      this.apiService.get('sites?page=' + page
      + (sortBy ? `&sort_by:${sortBy.field}=${sortBy.order}` : '' )
      + (filters ? '&' + parseFiltersForUrl(filters) : ''))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getById(id: number) {
    return new Promise((resolve, reject) => {
      this.apiService.get('sites/' + id)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
