import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

import { MustMatch } from '../../../helpers/mustmach.validator';
import modules from '../../../../assets/modules.json';

import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

import { User } from 'src/app/models/User';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public edit: boolean = false;
  public editFormGroup: UntypedFormGroup;
  public modules;
  public modulesStringList;
  public currentUser: User;
  public user: User = null;
  public submitted: boolean = false;
  public policyListRules: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private authService: AuthService,
    private userService: UserService,
    public loadingService: LoadingService) {
    if (this.route.snapshot.params.id) {
      this.edit = true;
      // Get user info
      this.userService.getById(this.route.snapshot.params.id)
        .then((user: User) => {
          this.user = user;
          this.editFormGroup = this.formBuilder.group(this.getNormalForm(user), {
            validator: MustMatch('password', 'password_confirmation')
          });
        })
        .catch((err) => {
          this.alertService.error(err.message);
          this.router.navigate(['/users-management']);
        });
    } else {
      this.editFormGroup = this.formBuilder.group(this.getNormalForm(), {
        validator: MustMatch('password', 'password_confirmation')
      });
    }
  }

  get f() { return this.editFormGroup.controls; }

  ngOnInit(): void {
    if (this.authService.currentUserValue) {
      this.modules = modules;
      this.modulesStringList = Object.keys(modules);
      this.currentUser = this.authService.currentUserValue;
      if (this.currentUser.policies['users-management']) {
        const policyUsersList = this.currentUser.policies['users-management'].filter(a => a.name === 'list')[0];

        if (policyUsersList) {
          this.policyListRules = policyUsersList.rules;
        }
      }
    }
  }

  getNormalForm(data?: User ) {
    let password_validation_array = [Validators.minLength(6)]
    let password_confirmation_validation_array = [Validators.minLength(6)]

    if(!data) { // New user password validation
      password_validation_array.push(Validators.required)
      password_confirmation_validation_array.push(Validators.required)
    }

    return {
      first_name: [data ? data.first_name: '', Validators.required],
      last_name: [data ? data.last_name: '', Validators.required],
      is_admin: [data ? data.is_admin: false, null],
      receives_ac_stats: [data ? data.receives_ac_stats : true, null],
      email: [data ? data.email : '', [Validators.required, Validators.email]],
      password: ['', password_validation_array],
      password_confirmation: ['',  password_confirmation_validation_array]
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }

    const user = {
      ...this.editFormGroup.value
    }

    if (this.edit) { // EDIT
      user.id = this.route.snapshot.params.id
    }

    this.userService.edit(user)
      .then(() => {
        this.alertService.success(`Utilisateur ${(this.edit) ? ' modifié' : ' ajouté' }.`);
        this.router.navigate(['/users-management/list']);
      })
      .catch((err) => {
        this.alertService.error(err, false);
      });

  }

}
