import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Page } from 'src/app/models/Page';
import { User } from 'src/app/models/User';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { EnumsService } from 'src/app/services/enums.service';
import { PagesService } from 'src/app/services/pages.service';
import { PoliciesService } from 'src/app/services/policies.service';
import modules from '../../../../assets/modules.json';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {
  public items: Array<Page> = [];
  public modules;
  public modulesStringList;
  public user: User;

  public loading: boolean = false;
  public loadingList: boolean = false;

  @ViewChild('datatable') datatable: any;

  public filtersSelected = null;
  public sortSelected = null;
  public pageOffsetSelected = null;
  public filters: {}[] = [{
    name: 'Titre',
    slug: 'title',
    type: 'text',
    class: 'col-md-6',
    value: null
  },
  {
    name: 'Date de création (à partir du)',
    slug: 'start_at',
    type: 'date',
    class: 'col-2',
    value: null
  },
  {
    name: "Date de création (jusqu'au)",
    slug: 'end_at',
    type: 'date',
    class: 'col-2',
    value: null
  },
  {
    name: 'Url',
    slug: 'url',
    type: 'text',
    class: 'col-md-6',
    value: null
  }]
  public optionsFilteredByType: any;
  public currentModule: {
    slug: string,
    name: string
  } = {
    slug: 'website',
    name: 'Site Profil Plus'
  }

  constructor(
    private cd: ChangeDetectorRef,
    public policiesService: PoliciesService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private pagesService: PagesService,
    private enumsService: EnumsService) {
    this.modules = modules;
    this.policiesService.currentUser = this.authService.currentUserValue;
    const page = (this.route.snapshot.params.page) ? parseInt(this.route.snapshot.params.page) : 1;
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        this.loading = false;
        this.filters.push(
          {
            name: 'Univers',
            slug: 'universe',
            type: 'select',
            class: 'col-2',
            selected_emit_property: 'id',
            property: 'label',
            options: this.enumsService.enums.universes,
          })
      }
    });

    this.route.data.subscribe((data) => {
      if(data.moduleSlug && data.moduleName) {
        this.currentModule.slug = data.moduleSlug
        this.currentModule.name = data.moduleName
      }
    })
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.setPage({offset: 0});
    this.cd.detectChanges();
  }

  setPage(pageInfo: {count?: number, limit?: number, offset: number}, sortBy: {field: string, order: string} = null, filters = null) {
    this.filtersSelected = filters !== null ? filters : this.filtersSelected;
    this.sortSelected = sortBy !== null ? sortBy : this.sortSelected
    this.pageOffsetSelected = pageInfo.offset !== null ?  pageInfo.offset : this.pageOffsetSelected;
    this.datatable.offset = this.pageOffsetSelected;
    this.loadingList = true;

    const website = this.currentModule.slug == 'job' ? 1 : 0

    this.pagesService.getAllWithPagination(website, this.pageOffsetSelected + 1, this.sortSelected, this.filtersSelected)
      .then((response: any) => {
        this.datatable.limit = 25;
        this.datatable.count = response.pagination.total_items;
        this.items = response.items;
        this.loadingList = false;
      })
      .catch((err) => {
        this.alertService.error(err);
        this.loadingList = false;
      });
  }

  onSort($event: {column: any, sorts: any[]}) {
    this.setPage({offset: this.pageOffsetSelected}, {field: $event.column.prop, order: $event.sorts[0].dir});
  }

  onDelete(id) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-info'
      },
      buttonsStyling: false,
      reverseButtons: true,
      focusConfirm: false
    });

    swalWithBootstrapButtons.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cette page ?',
      text: 'Suppression irréversible.',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Confirmer'
    }).then((result) => {
      if (result.value) {
        this.pagesService.delete(id)
        .then(() => {
          const index = this.items.findIndex(e => e.id === id);
          if (index !== -1) {
            this.items.splice(index, 1);
            this.items = [...this.items];
            this.alertService.success('Page supprimée avec succès.');
          }
        })
        .catch((err) => {
          this.alertService.error(err);
        });
      }
    });
  }
}
