import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HelpComponent } from '../help/help.component';
import { AuthGuard } from 'src/app/helpers/auth.guard';
import { IndexContentComponent } from 'src/app/components/index-content/index-content.component';

/* HOMEPAGE */
import { JobComponent } from './job.component';
import { CmsComponent } from '../common/cms/cms.component';
import { EditComponent as CmsEdit } from '../common/cms/edit/edit.component';

import { PageAttachmentsComponent } from '../common/cms/page-attachments/page-attachments.component';
import { PageAnswersComponent } from '../common/cms/page-answers/page-answers.component';
import { ShowComponent } from '../profilplus-site/show/show.component';


import { PageBackgroundsComponent } from '../common/page-backgrounds/page-backgrounds.component';
import { PageBackgroundsEditComponent } from '../common/page-backgrounds/page-backgrounds-edit/page-backgrounds-edit.component';
import { CandidaturesComponent } from './candidatures/candidatures.component';
import { ShowComponent as ShowCandidatureComponent } from './candidatures/show/show.component';
import {HistoriqueComponent} from './candidatures/historique/historique.component';

const routes: Routes = [
  {
    path: 'job',
    component: JobComponent,
    canActivate: [AuthGuard],
    children: [
      /* GENERAL */
      {
        path: '',
        component: IndexContentComponent,
      },
      {
        path: 'help',
        component: HelpComponent,
        canActivate: [AuthGuard],
      },
      /* PAGES CMS */
      {
        path: 'pages',
        component: CmsComponent,
        canActivate: [AuthGuard],
        data: {moduleSlug: 'job', moduleName: 'Site Emploi'}
      },
      {
        path: 'pages/list',
        component: CmsComponent,
        canActivate: [AuthGuard],
        data: {moduleSlug: 'job', moduleName: 'Site Emploi'}
      },
      {
        path: 'pages/list/page/:page',
        component: CmsComponent,
        canActivate: [AuthGuard],
        data: {moduleSlug: 'job', moduleName: 'Site Emploi'}
      },
      {
        path: 'pages/edit',
        component: CmsEdit,
        canActivate: [AuthGuard],
        data: {moduleSlug: 'job', moduleName: 'Site Emploi'}
      },
      {
        path: 'pages/edit/:id',
        component: CmsEdit,
        canActivate: [AuthGuard],
        data: {moduleSlug: 'job', moduleName: 'Site Emploi'}
      },

      /* PAGES CMS - PIECES JOINTES */
      {
        path: 'pages/attachments',
        component: PageAttachmentsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'pages/attachments/:id',
        component: ShowComponent,
        canActivate: [AuthGuard]
      },

      /* PAGES CMS - RÉPONSES */
      {
        path: 'pages/answers',
        component: PageAnswersComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'pages/answers/:id',
        component: ShowComponent,
        canActivate: [AuthGuard]
      },

      /* HABILLAGES DE PAGE */
      {
        path: 'page-backgrounds',
        component: PageBackgroundsComponent,
        canActivate: [AuthGuard],
        data: {moduleSlug: 'job', moduleName: 'Site Emploi'}
      },
      {
        path: 'page-backgrounds/edit/:page_background_id',
        component: PageBackgroundsEditComponent,
        canActivate: [AuthGuard],
        data: {moduleSlug: 'job', moduleName: 'Site Emploi'}
      },
      {
        path: 'page-backgrounds/edit',
        component: PageBackgroundsEditComponent,
        canActivate: [AuthGuard],
        data: {moduleSlug: 'job', moduleName: 'Site Emploi'}
      },
      {
        path: 'candidatures-spontanees',
        component: CandidaturesComponent,
        canActivate: [AuthGuard],
        data: {type: 'spontanee'}
      },
      {
        path: 'candidatures-flash',
        component: CandidaturesComponent,
        canActivate: [AuthGuard],
        data: {type: 'flash'}
      },
      {
        path: 'candidature/:id',
        component: ShowCandidatureComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'candidature/:type/:id/historique',
        component: HistoriqueComponent,
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JobRoutingModule {}
