<ng-container *ngIf="!loading; else templateElse">
  <app-topbar moduleSlug="profilplus-site">
    <ng-container title>
        <nav aria-label="breadcrumb" role="navigation">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="['/']" routerLinkActive="router-link-active" >Tableau de bord</a></li>
                <li class="breadcrumb-item"><a [routerLink]="['/website']">Site Profil Plus</a></li>
                <li class="breadcrumb-item active"><a [routerLink]="['/website/pages']">Pages CMS</a></li>
                <li class="breadcrumb-item active"><a href="javascript:;">Réponses</a></li>
            </ol>
        </nav>
    </ng-container>
  </app-topbar>


  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header card-header-rose card-header-icon">
          <div class="card-icon">
            <i class="material-icons">supervisor_account</i>
          </div>
          <h4 class="card-title">Liste des réponses</h4>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <ngx-datatable
              class="material"
              [rows]="items"
              [columnMode]="'standard'"
              [rowHeight]="'auto'"
              [headerHeight]="50"
              [footerHeight]="50"
              [loadingIndicator]="loadingList"
            >
              <ngx-datatable-column name="ID" prop="id" [width]="75">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Actions" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <a type="button" class="btn btn-success" title="Voir la réponse">
                    <i class="material-icons">eye</i>
                  </a>
                  <button type="button" class="btn btn-danger" title="Supprimer">
                    <i class="material-icons" (click)="onDelete(row.id)">close</i>
                  </button>
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #templateElse>
  <app-loading></app-loading>
</ng-template>
