import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumsService } from 'src/app/services/enums.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SlideshowsService } from 'src/app/services/slideshows.service';
import Slideshow from 'src/app/models/Slideshow';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public editFormGroup: UntypedFormGroup;
  public editMode: any = {
    active: false,
    data: null
  };
  public loading: Boolean = false;
  public submitted: Boolean = false;
  preselectBrand: number;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private slideshowsService: SlideshowsService,
              private formBuilder: UntypedFormBuilder,
              public loadingService:LoadingService,
              public enumsService: EnumsService,
              private alertService: AlertService) {
    this.loading = true;
    this.enumsService.observable.subscribe({
      complete: () => {
        if (this.route.snapshot.params.id) { // EDIT
          this.editMode.active = true;
          this.slideshowsService.getById(this.route.snapshot.params.id)
            .then((response: Slideshow) => {
              this.editMode.data = response;

              this.editMode.data.image = (response.image) ? [{name: 'image', link: response.image}] : null;

              if (response.brand) {
                this.preselectBrand = this.enumsService.enums.brands.findIndex(b => b.id === response.brand.id)
              }

              this.loading = false;
              this.editFormGroup = this.formBuilder.group(this.getNormalForm(response));
            })
            .catch((err) => {
              this.alertService.error(err);
            });
        } else {
          this.loading = false;
          this.editFormGroup = this.formBuilder.group(this.getNormalForm());
        }
      }
    });
  }

  ngOnInit(): void {
  }

  get f() { return this.editFormGroup.controls; }

  private getNormalForm(data?: Slideshow) {
    return {
      name: [data ? data.name : '', null],
      description: [data ? data.description : '', null],
      content: [data ? data.content : '', null],
      brand_id: [data && data.brand ? data.brand.id : '', null],
      universe: [data  ? data.universe : '', null],
      published: [data ? data.published : '', null],
      margin_left: [data ? data.margin_left : '', null],
      margin_top: [data ? data.margin_top : '', null],
      special_offer_id: [data ? data.special_offer_id : '', null],
      image: [null, null],
      external_link: [data ? data.external_link : '', null]
    };
  }

  onSelectedBrand(brand) {
    this.editFormGroup.patchValue({brand_id: brand.id});
  }

  onFileSelected(files: Array<File>) {
    this.editFormGroup.patchValue({image: files ? files[0] : ''});
    if (!files && this.editMode.data) {
      this.editMode.data.image = null
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.editFormGroup.invalid) {
      window.scrollTo(0, 0);
      return;
    }
    const slideshow = {
      ...this.editFormGroup.value,
    };

    if (this.editMode) {
      slideshow.id = this.route.snapshot.params.id;
    }
    this.slideshowsService.edit(slideshow).then((response) => {
      this.router.navigate(['/website/settings/slideshows']);
      this.alertService.success((this.editMode && this.editMode) ? 'Modification enregistrée avec succès.' : 'Slideshow enregistré avec succès.');
    })
    .catch((err) => {
      this.alertService.error(err);
    });
  }
}
